import React from 'react'
import { useNavigate } from 'react-router-dom'

const AboutUs = () => {
    const navigate = useNavigate();
    return (
        <div>
        <div className="row addspace3">
            <div className="col-md-1">
            </div>
            <div className="col-md-10">
                <hr />
            </div>
            <div className="col-md-1">
            </div>
            </div>       
               <div className="whyBicy">
        
        <div className="whybicyContainer">
            <div className="row">
                <div className="col-md-1"></div>
                   <h2 className="mb-3">About Us</h2>
                <div className="row col-sm-12">
                 <h5>What motivated us to develop this website?</h5> 
                 <div>
            <p>Parents play a vital role in a child's developing years, our experience was no
        different. For us it was our mother, Uma Gomathi Chandrasekaran (née Venkatasubramanian), she got opportunities to help our guru
        Smt Rajarajeshwary Bhat with content research for various thematic music
        programs</p>
        <span>To name a few such opportunities -</span>
        <ul>
            <li>      PadmaragaMala program as a part of 40th anniversary celebration of
        Houston's Classical Arts Society’s Thyagaraja Aradhana highlighting
        various works of Saint Tyagaraja </li>
        <li> Guru Samarpanam program at Cleveland Aradhana highlighting
        compositions made by disciples on their gurus,</li>
        <li> Ninda Sthuthi compositions.</li>
        <li>Mylai to Kanyakumari kshetra compositions</li>
        </ul>
        <br/>
        <p>
        During her quest for quality content research, she would come across rare
        compositions, may find their lyrics in the internet, but details on book
        sources that have the music notation was very hard to find.</p>
        <p>As we (Pavithra and Bhargavi) started performing our solo and duet concerts
        from 2020 onwards, we experienced a long arduous preparation time for
        songs we had to learn new from renditions with just the lyrics, but no
        notations. We had to listen to the renditions of the songs, create music
        notation and it was truly time consuming. It was even more difficult when
        we were preparing to perform thematic concerts (Examples – Compositions
        of Travancore Kingdom’s Court Musicians). Search for compositions on the
        internet using google would yield the lyrics if we are lucky but not the music
        notation (or) reference to music books that may have the notation. Having a
        base version of notation really helped us to get prepared in much lesser
        time.Even when you get the book with notation knowing the language in which
        the book is written is paramount for effectively understanding the contents
        of the book. Our Parents encouraged us to be proficient in reading, writing,
        and speaking Tamil. This really helped us to read music books written in
        Tamil language, comprehend the details.The above experiences helped us to realize the value of organizing book
        indexes and make it available to all the Carnatic music fraternity and
        enthusiasts within a click. We have been collecting music books mostly Tamil
        and English, extract its index. We now have a collection of about 800 books,
        index extracted for about 100 books, it has yielded us close to 8000 songs.
        We continue to collect more books and make its index available to all the Carnatic music fraternity.</p>
        
        </div>
                </div><br/><br/>             
                  
                   
                    <div className="row col-sm-12">
                    <h5>Pavithra Chandrasekaran</h5> <br /> <br />
                    <div className="col-sm-5 ">
                        <img src="/images/Pavithra-Photo.jpg" title="Cleveland Thyagaraja Festival" alt="Cleveland Thyagaraja Festival" width="450" height="400" />
                        </div>
                    <div className="col-sm-7">
                        <p>Pavithra Chandrasekaran is a junior attending high school in Katy, TX. She
                        has been taking Carnatic music vocal lessons since age 7 from Vidushi Smt.
                        Rajarajeshwary Bhat and Vidushi Smt. Kruthi Bhat. She was initiated into
                        Bharathanatyam at the age of 3 and in the year 2009 by Smt Kripa Baskaran
                        of Natyarpana Dance Company - Wisconsin. After moving to Houston at age
                        7 and in the year 2013, she continued to learn Bharathanatyam from Smt.
                        Sandhya Raghuraman until 2015. Since 2015, she is receiving advanced
                        training in Bharathanatyam from Smt. Dr. Rathna Kumar of Anjali
                        Performing Arts. Pavithra gave her solo vocal debut concert (also known as)
                        Gruha Sangeetham in 2018 at the age of 12. </p>
        
                        <p>She had participated and won
                        various prizes in music competitions conducted by the following
                        organizations 
        
                    <p>
                    Cleveland Tyagaraja Aradhana, The Artiste, SAPTAMI-Dallas
                    MYICMF-Malaysia International Music & Dance Festival organized by Sugam
                    Culture & Heritage Foundation partnering with Carnatica Global, IMPL-
                    International Music Premier League and the Ministry of Education Malaysia.</p>
        
                    She has performed concerts at various venues in Tirunelveli, Dallas-Houston
                    area, and many online platforms.</p>
                          </div>
                       
        
        
                       
                    </div>  <br /> <br /> <br />
                    </div>
                    <br /> <br /> <br />
                    <div className="row col-sm-12">
                    <h5>Bhargavi Chandrasekaran</h5> <br /> <br />
                        <div className="col-sm-8"><div>
                            <p>Bhargavi Chandrasekaran is in 7th grade attending middle school in Katy, TX.
                                She has been taking Carnatic music vocal lessons since age 5 from Vidushi
                                Smt. Rajarajeshwary Bhat and Vidushi Smt. Kruthi Bhat. Bhargavi gave her
                                solo debut concert in 2019 at the age of 9. </p>
        
                                <p>She had participated and won
                                various prizes in music competitions conducted by the following
                                organizations - </p>
                                <p>Cleveland Tyagaraja Aradhana, Vidwan VVS Murari sir's VVS Foundation-SaMaa Arts, The Artiste, SAPTAMI-Dallas,
                                    MYICMF-Malaysia International Music & Dance Festival organized by Sugam Culture & Heritage Foundation partnering with Carnatica Global,
                                    ,IMPL-International Music Premier League and the Ministry of Education Malaysia.</p>
        
                                <p>She debuted in Chennai's December Music Season in the year 2022 and
                                performed concerts at many prestigious venues. Additionally, she has
                                performed concerts at various venues in Tirunelveli, Dallas-Houston area
                                and many online platforms.</p></div>
                        </div>
        
                        <div className="col-sm-4">
                       <img src="/images/Bhargavi-Photo.jpg" title="Bhargavi ChandraSekharan" alt="Bhargavi ChandraSekharan" width="450" height="400" />
                        </div>
        
        
                        <div className="col-md-5 col-sm-6">
                            
                          </div>
                    </div>
                   <br /> <br /> <br />
               </div>
               </div>
               </div>
          
    )}


        
    

export default AboutUs
