import React from 'react'
import { useState } from 'react';
import { BrowserRouter, Routes, Route , NavLink } from "react-router-dom";

export default function Nav(){

    const [click, setClick] = useState(false);

const handleClick = () => setClick(!click);
const Close = () => setClick(false);

    return(
       <>
      

  <div className='header'>
   <div className={click ? "main-container" : ""}  onClick={()=>Close()} />
    <nav className="navbar" onClick={e => e.stopPropagation()}>
      <div className="nav-container">
        <NavLink  to="/" className="nav-logo">
        <img src="/images/slices/logo.png" id="logo"  alt="Music Notations" align="left"  width="200" height="80"  /> 

        </NavLink>
        <h1  className="title">Carnatic Music Books Index</h1>
        <ul className={click ? "nav-menu active rightalign" : "nav-menu"}>
          <li className="nav-item">
            <NavLink
              
              to="/"
              activeclassname="active"
              className="nav-links"
              onClick={click ? handleClick : null}
            >
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              
              to="/aboutus.html"
              activeclassname="active"
              className="nav-links"
              onClick={click ? handleClick : null}
            >
              About Us
            </NavLink>
          </li>
        
          <li className="nav-item">
            <NavLink
              
              to="/ContactForm.html"
              activeclassname="active"
              className="nav-links"
             onClick={click ? handleClick : null}
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
        <div className="nav-icon" onClick={handleClick}>
          <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
        </div>
      </div>
    </nav>
  </ div>

       </>
         


)}