import React from 'react';
import nodemailer from 'nodemailer';
import { useTable, UseFiltersState , usef, useGlobalFilter ,useSortBy} from 'react-table'
// import  '../tablestyle.css';
import { useState, useRef } from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import FormLabel from 'react-bootstrap/FormLabel';
import Pagination  from './Pagination';
import Form from 'react-bootstrap/Form';
import { Table } from './Table';





function DataTableComponent() {
    const data = [       
           
              {
                "Serial #": 1,
                "Name": "Devi Meenalochani",
                "Type": "Varnam-Tana",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2,
                "Name": "Sri Saara Devarathae",
                "Type": "Varnam-Tana",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3,
                "Name": "Valachi yunnanaa",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4,
                "Name": "Manasu telisi",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5,
                "Name": "Neerajakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6,
                "Name": "Neeraja lochani",
                "Type": "Varnam-Tana",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7,
                "Name": "Ninne Namminaa",
                "Type": "Varnam-Tana",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8,
                "Name": "Paradevathae varadae",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 9,
                "Name": "Ninne Namminaa",
                "Type": "Varnam-Tana",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 10,
                "Name": "Kanjadalayathakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 11,
                "Name": "Jagadeesha Paatali pura",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 12,
                "Name": "Ninnukori",
                "Type": "Varnam-Tana",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 13,
                "Name": "Sarasiruhasana priyae",
                "Type": "Varnam-Tana",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 14,
                "Name": "Devi Dayaapari",
                "Type": "Varnam-Tana",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 15,
                "Name": "Yela nee daya raadhu",
                "Type": "Varnam-Tana",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 16,
                "Name": "Devi Gowri Manohari",
                "Type": "Varnam-Tana",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 17,
                "Name": "Needhu charana mulae",
                "Type": "Varnam-Tana",
                "Ragam": "Vijayanaagari",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 18,
                "Name": "Yela nee daya raadhu",
                "Type": "Varnam-Tana",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 19,
                "Name": "Daya Jooda",
                "Type": "Varnam-Tana",
                "Ragam": "Sree",
                "Thalam": "Tisra Jathi Ata thalam",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 20,
                "Name": "Paarvathi priya kumaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 21,
                "Name": "Paalae Paripaalayamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha Chandrika",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 22,
                "Name": "Saara Saakshi Chamundeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsavinodini",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 23,
                "Name": "Govardhana gireesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naagavalli",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 24,
                "Name": "Mahatripura Sundari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maddhima ravali",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 25,
                "Name": "Raamam Bhajae Shyamalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rishabhapriya",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 26,
                "Name": "Paahi Raamachandra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijaya Saarangam",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 27,
                "Name": "Pankajanabha sahodari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Laavanya",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 28,
                "Name": "Maduraakshari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowrilalithaa",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 29,
                "Name": "Niraamayae Nirmalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Iraavathi",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 30,
                "Name": "Neelakantam upaasmahae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dheera Ranjani",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 31,
                "Name": "Chidaananda vilaasini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Chandrika",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 32,
                "Name": "Bhajarae Sri Krishnam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kousthubam",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 33,
                "Name": "Devasenapathim bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chithroopi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 34,
                "Name": "Maamava Sri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manoham",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 35,
                "Name": "Sri Raajarajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "Rajarajeshwari Pancharatnam-1",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 36,
                "Name": "Sri Raajarajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "Rajarajeshwari Pancharatnam-3",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 37,
                "Name": "Sri Raajarajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "Rajarajeshwari Pancharatnam-2",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 38,
                "Name": "Sri Raajarajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "Rajarajeshwari Pancharatnam-4",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 39,
                "Name": "Sri Raajarajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "Rajarajeshwari Pancharatnam-5",
                "BookTitle": "Varnams, Keerthanams in Apoorva Ragas, Rajarajeshwari Pancharatnam",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 40,
                "Name": "Shri Meenakshi maampahi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Vedanta Bhagavathar",
                "BookTitle": "Vedaanta Bhaagavathar Keerthanaigal",
                "BookAuthor": "Vedanta Bhagavathar",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 41,
                "Name": "Karunakara paratpara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GambiraNattai",
                "Thalam": "Adi",
                "Composer": "Vedanta Bhagavathar",
                "BookTitle": "Vedaanta Bhaagavathar Keerthanaigal",
                "BookAuthor": "Vedanta Bhagavathar",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 42,
                "Name": "Velae Kandan Thirukkaiyalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Vedanta Bhagavathar",
                "BookTitle": "Vedaanta Bhaagavathar Keerthanaigal",
                "BookAuthor": "Vedanta Bhagavathar",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 43,
                "Name": "Dhaaraka Samhaaruda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Vedanta Bhagavathar",
                "BookTitle": "Vedaanta Bhaagavathar Keerthanaigal",
                "BookAuthor": "Vedanta Bhagavathar",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 44,
                "Name": "Inyochana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Vedanta Bhagavathar",
                "BookTitle": "Vedaanta Bhaagavathar Keerthanaigal",
                "BookAuthor": "Vedanta Bhagavathar",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 45,
                "Name": "Vasantharudu poojitha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Vedanta Bhagavathar",
                "BookTitle": "Vedaanta Bhaagavathar Keerthanaigal",
                "BookAuthor": "Vedanta Bhagavathar",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 46,
                "Name": "Saadara mukha guha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Vedanta Bhagavathar",
                "BookTitle": "Vedaanta Bhaagavathar Keerthanaigal",
                "BookAuthor": "Vedanta Bhagavathar",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 47,
                "Name": "Shiki vaahana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Vedanta Bhagavathar",
                "BookTitle": "Vedaanta Bhaagavathar Keerthanaigal",
                "BookAuthor": "Vedanta Bhagavathar",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 48,
                "Name": "Needarshana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Vedanta Bhagavathar",
                "BookTitle": "Vedaanta Bhaagavathar Keerthanaigal",
                "BookAuthor": "Vedanta Bhagavathar",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 49,
                "Name": "Samaya muraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Vedanta Bhagavathar",
                "BookTitle": "Vedaanta Bhaagavathar Keerthanaigal",
                "BookAuthor": "Vedanta Bhagavathar",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 50,
                "Name": "Jagadambhika",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Vedanta Bhagavathar",
                "BookTitle": "Vedaanta Bhaagavathar Keerthanaigal",
                "BookAuthor": "Vedanta Bhagavathar",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 51,
                "Name": "Sanchalamenduru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Vedanta Bhagavathar",
                "BookTitle": "Vedaanta Bhaagavathar Keerthanaigal",
                "BookAuthor": "Vedanta Bhagavathar",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 52,
                "Name": "Swami nee paada mula",
                "Type": "Varnam-Tana",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 53,
                "Name": "Sree Ramachandra naadhu",
                "Type": "Varnam-Tana",
                "Ragam": "KamalaManohari",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 54,
                "Name": "Sarojaaksha Rama Ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 55,
                "Name": "Nenarunchi",
                "Type": "Varnam-Tana",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 56,
                "Name": "Ninnendho vaedi",
                "Type": "Varnam-Pada",
                "Ragam": "Devamanohari",
                "Thalam": "Khanda Triputa",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 57,
                "Name": "Sree Rajagopala",
                "Type": "Varnam-Pada",
                "Ragam": "Abhogi",
                "Thalam": "Tisra Triputa",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 58,
                "Name": "Raama Naamoraalincharaa",
                "Type": "Swarajathi",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 59,
                "Name": "Raamaa ravikula",
                "Type": "Swarajathi",
                "Ragam": "KedaraGowlai",
                "Thalam": "Roopakam",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 60,
                "Name": "Raama Manavivinumaa",
                "Type": "Swarajathi",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 61,
                "Name": "Saami nenaruna",
                "Type": "Swarajathi",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 62,
                "Name": "Guru charanam bhajarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 63,
                "Name": "Guruvara mahimala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 64,
                "Name": "Chedi pogae Oh Manasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalitha",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 65,
                "Name": "Kannulaara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Roopakam",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 66,
                "Name": "Sri Raama Brahmamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 67,
                "Name": "Raama Bhakthi jesina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 68,
                "Name": "Ra Ra Vasudeva Vara kumaara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 69,
                "Name": "Kodanda Deeksha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra chapu",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 70,
                "Name": "Ninnuvinaaga Devvvaro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Roopakam",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 71,
                "Name": "Sri Raghu Raamam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 72,
                "Name": "Ra Ra Dasaratha Raajakumara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 73,
                "Name": "Hari Hari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 74,
                "Name": "Paramapurusha Jagadeesha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 75,
                "Name": "Thagaduraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 76,
                "Name": "Paravasa maayanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 77,
                "Name": "Vidavanuraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KamalaManohari",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 78,
                "Name": "Naa Thappulanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra chapu",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 79,
                "Name": "Anandamaya maanavanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jyothiswaroopini",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 80,
                "Name": "Manasaa Raamuni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 81,
                "Name": "Yamuni Paadalaku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 82,
                "Name": "Raadha Ramana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 83,
                "Name": "Sabalamu maani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 84,
                "Name": "Naarada Gaana Vishaarada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 85,
                "Name": "Karuninchara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 86,
                "Name": "Bhajarae Manasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 87,
                "Name": "Kuchela Paripaala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 88,
                "Name": "Dikulu maanparaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Roopakam",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 89,
                "Name": "Vinaraadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 90,
                "Name": "Vadarasanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 91,
                "Name": "Yevaa ratha rinthu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Mishra chapu",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 92,
                "Name": "Ra Ra Shri Raghu Veera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 93,
                "Name": "Neekae Daya ",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 94,
                "Name": "Narayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 95,
                "Name": "Paalayamaam shree",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 96,
                "Name": "Thathvamu theliyaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra chapu",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 97,
                "Name": "Prathyaksha mukha ra ra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 98,
                "Name": "Yemani Palukulu ra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 99,
                "Name": "Neramulennaga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 100,
                "Name": "Paalayamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suvarnaangi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 101,
                "Name": "Inthamarmivanuchu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Mishra chapu",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 102,
                "Name": "Aparaadhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Khanda Chapu",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 103,
                "Name": "Gnaana swaroopamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 104,
                "Name": "Idhigadhayaanandhamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 105,
                "Name": "Raamachandra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 106,
                "Name": "Bhajarae Shree Raama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malavi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 107,
                "Name": "Raakshasa kula mardana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 108,
                "Name": "Charananu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijayanaagari",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 109,
                "Name": "Dhaariteliyaga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Mishra chapu",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 110,
                "Name": "Tirupathi venkataramana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "Song Language": "Sowrashtram",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 111,
                "Name": "Balagopala krishna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 112,
                "Name": "Khandavahaathmaja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 113,
                "Name": "Naama mulanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 114,
                "Name": "Dasaradha Nandana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 115,
                "Name": "Hari Hari nee narahari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 116,
                "Name": "Krishnaa yanusu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 117,
                "Name": "Karunaanidhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 118,
                "Name": "Sridhara vaikuntadhipa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 119,
                "Name": "Thillana-KedaraGowlai",
                "Type": "Thillana",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 120,
                "Name": "Neevae nannu brova",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Khanda Chapu",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 121,
                "Name": "Bhagaseya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rama Narayani",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "BookTitle": "Walajapet Venkatramana Bhagavathar Compositions & History",
                "BookAuthor": "Waljapet Venkatramana Bhagavathar",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 122,
                "Name": "Sri Gananatha",
                "Type": "Geetham",
                "Ragam": "Malahari",
                "Thalam": "Roopakam",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 123,
                "Name": "Kundagowra",
                "Type": "Geetham",
                "Ragam": "Malahari",
                "Thalam": "Roopakam",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 124,
                "Name": "Keraya neeranu",
                "Type": "Geetham",
                "Ragam": "Malahari",
                "Thalam": "Triputa",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 125,
                "Name": "Padumanabha",
                "Type": "Geetham",
                "Ragam": "Malahari",
                "Thalam": "Triputa",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 126,
                "Name": "Vibhishana Paalaka",
                "Type": "Geetham",
                "Ragam": "Malahari",
                "Thalam": "Triputa",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 127,
                "Name": "Analekara",
                "Type": "Geetham",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Triputa",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 128,
                "Name": "Sri Gopala",
                "Type": "Geetham",
                "Ragam": "Naatai",
                "Thalam": "Dhruva ",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 129,
                "Name": "Duma kumaa",
                "Type": "Geetham",
                "Ragam": "Gowlai",
                "Thalam": "Triputa",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 130,
                "Name": "Devaki Devaki",
                "Type": "Lakshana Geetham",
                "Ragam": "Arabhi",
                "Thalam": "Triputa",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 131,
                "Name": "Jagadabhi nayaki",
                "Type": "Geetham",
                "Ragam": "Sree",
                "Thalam": "Ekam",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 132,
                "Name": "Shanka chakram",
                "Type": "Geetham",
                "Ragam": "ReethiGowlai",
                "Thalam": "Jhampa",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 133,
                "Name": "Vidubimba",
                "Type": "Geetham",
                "Ragam": "Bhowli",
                "Thalam": "Dhruva ",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 134,
                "Name": "Arae Arae Naadhas",
                "Type": "Geetham",
                "Ragam": "Saranga naatai",
                "Thalam": "Dhruva Roopakam",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 135,
                "Name": "Manmadha",
                "Type": "Geetham",
                "Ragam": "Maalavasri",
                "Thalam": "Dhruva",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 136,
                "Name": "Arae Arae Saarva",
                "Type": "Geetham",
                "Ragam": "NarayanaGowlai",
                "Thalam": "Matya",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 137,
                "Name": "Rae Rae sudha",
                "Type": "Geetham",
                "Ragam": "Kalyani",
                "Thalam": "Dhruva",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 138,
                "Name": "Dhara Dhalitha champaka",
                "Type": "Geetham",
                "Ragam": "Shankarabharanam",
                "Thalam": "Triputa",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 139,
                "Name": "Arae dheena paarijaathu",
                "Type": "Geetham",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Dhruva Roopakam",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 140,
                "Name": "Saami Ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 141,
                "Name": "Saara saakshi Ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 142,
                "Name": "Sri Naathaa",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Ata",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 143,
                "Name": "Palumaru",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Ata",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 144,
                "Name": "Janani",
                "Type": "Varnam-Tana",
                "Ragam": "Bhowli",
                "Thalam": "Ata",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 145,
                "Name": "Sami Daya juda",
                "Type": "Varnam-Tana",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Tiruvottiyur Tyagayyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 146,
                "Name": "Cheliya",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Ata",
                "Composer": "Tiruvottiyur Tyagayyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 147,
                "Name": "Shree Karambuga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Chapu",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 148,
                "Name": "Kori Kori",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Chapu",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 149,
                "Name": "Kalikedhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Purvi",
                "Thalam": "Chapu",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 150,
                "Name": "Paramaathmuni maansa (Maansa Pooja)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 151,
                "Name": "Gopamelara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 152,
                "Name": "Kanikaramu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 153,
                "Name": "Naraayana nee naamamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "YamunaKalyani",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 154,
                "Name": "Paramadayanidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 155,
                "Name": "Kannadhantri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 156,
                "Name": "Jagadabhiraama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 157,
                "Name": "Nannu brova",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 158,
                "Name": "Maattaada radha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 159,
                "Name": "Neevae dikkani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "Group": "Sri Venkatesa Pancharatnam-4",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 160,
                "Name": "Paradevi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 161,
                "Name": "Koniyaatina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "Group": "Sri Kalahasthi Pancharatnam-1",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 162,
                "Name": "Sri Krishna Leela Tarangini (Tuned by Veena Kuppayyar)",
                "Type": "Tarangam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Narayana Teerthar, Veena Kuppaiyyar",
                "Group": "Krishna Leela Tarangini",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 163,
                "Name": "Gaana Lola Karunaala Vaala",
                "Type": "Pallavi",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 164,
                "Name": "Intha sowkha seya",
                "Type": "Varnam-Tana",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 165,
                "Name": "Ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 166,
                "Name": "Vanajaaksha",
                "Type": "Varnam-Tana",
                "Ragam": "ReethiGowlai",
                "Thalam": "Ata",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 167,
                "Name": "Maguva ninne",
                "Type": "Varnam-Tana",
                "Ragam": "NarayanaGowlai",
                "Thalam": "Ata",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 168,
                "Name": "Vanajaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Ata",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 169,
                "Name": "Karunimpa",
                "Type": "Varnam-Tana",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Tiruvottiyur Tyagayyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 170,
                "Name": "Chalamela",
                "Type": "Varnam-Tana",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Tiruvottiyur Tyagayyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 171,
                "Name": "Intha parakela",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Ata",
                "Composer": "Tiruvottiyur Tyagayyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 172,
                "Name": "Kaligi ninnae",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Ata",
                "Composer": "Tiruvottiyur Tyagayyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 173,
                "Name": "Paalinchu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Chapu",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 174,
                "Name": "Sri pathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Chapu",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 175,
                "Name": "Maapathi naamamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapijingala",
                "Thalam": "Chapu",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 176,
                "Name": "Paraakruda janula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Chapu",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 177,
                "Name": "Mosa pogavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 178,
                "Name": "Ituvanti samayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Jhampa",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 179,
                "Name": "Neranemiraa nera Jesina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 180,
                "Name": "Telisi teliya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 181,
                "Name": "Maaramanu nee varamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 182,
                "Name": "Nannu brova Raadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 183,
                "Name": "Chalamela Jesavura",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desiya Thodi",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 184,
                "Name": "Brovanu Ra ra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 185,
                "Name": "Intha paaraamuga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 186,
                "Name": "Ninnu nera nammithi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 187,
                "Name": "Momu joopaka raadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowri",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 188,
                "Name": "Neranammidhiraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 189,
                "Name": "Paalimpavae nannu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 190,
                "Name": "Kaththulae thani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaya naarayani",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 191,
                "Name": "Elara naatho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 192,
                "Name": "Nannubrocheva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NarayanaGowlai",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 193,
                "Name": "Mahima Teliya ",
                "Type": "Pallavi",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 194,
                "Name": "Tripura Sundari Gowri",
                "Type": "Pallavi",
                "Ragam": "Kalyani",
                "Thalam": "Triputa",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 195,
                "Name": "Intha Chalamu",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 196,
                "Name": "Entho premato",
                "Type": "Varnam-Tana",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Tiruvottiyur Tyagayyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 197,
                "Name": "Sarasijaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Ata",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 198,
                "Name": "Saami neepai",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Ata",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 199,
                "Name": "Nenarunchi",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Ata",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 200,
                "Name": "Ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "Tiruvottiyur Tyagayyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 201,
                "Name": "Saraguna nannela",
                "Type": "Varnam-Tana",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Tiruvottiyur Tyagayyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 202,
                "Name": "Mithiraakshi neepai",
                "Type": "Varnam-Tana",
                "Ragam": "Mukhari",
                "Thalam": "Ata",
                "Composer": "Tiruvottiyur Tyagayyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 203,
                "Name": "Kamalakshi ",
                "Type": "Varnam-Tana",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Ata",
                "Composer": "Tiruvottiyur Tyagayyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 204,
                "Name": "Ee thanuvunu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desiya Thodi",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 205,
                "Name": "Pattapaadu saaladha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 206,
                "Name": "Manavyala kimpa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 207,
                "Name": "Naamorala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 208,
                "Name": "Ninne nera nammithi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 209,
                "Name": "Talli naameedha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 210,
                "Name": "Nee sahaayamu leni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhaari",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 211,
                "Name": "Jo Jo Jo Devi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 212,
                "Name": "Intha parakela namma (on Mysore Chamundeshwari)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 213,
                "Name": "Nannu brova Raadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "Group": "Sri Kalahasthi Pancharatnam-2",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 214,
                "Name": "Birana Nannu brova",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "Group": "Sri Kalahasthi Pancharatnam-3",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 215,
                "Name": "Sama Gaana lola",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SaalagaBhairavi",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "Group": "Sri Kalahasthi Pancharatnam-4",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 216,
                "Name": "Sevindhamuraaramma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "Group": "Sri Kalahasthi Pancharatnam-5",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 217,
                "Name": "Baahumeeraganu naatho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "Group": "Sri Venkatesa Pancharatnam-5",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 218,
                "Name": "Mammu brochu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "Group": "Sri Venkatesa Pancharatnam-1",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 219,
                "Name": "Nannu brova",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "Group": "Sri Venkatesa Pancharatnam-2",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 220,
                "Name": "Sarojaakshani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "Group": "Sri Venkatesa Pancharatnam-3",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 221,
                "Name": "Taamasa mela",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 222,
                "Name": "Ninnunera nammidhiraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 223,
                "Name": "Ituvanti",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 224,
                "Name": "Sree Ramana padma nayana",
                "Type": "Swara Sahithya Ragamalika",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Tiruvottiyur Tyagayyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 225,
                "Name": "Venugaana loluni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Thyagaraja Swamy",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 226,
                "Name": "Premathone nundaga",
                "Type": "Pallavi",
                "Ragam": "Khambhoji",
                "Thalam": "Jhampa",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 227,
                "Name": "A eka maina manasu thonu",
                "Type": "Pallavi",
                "Ragam": "Bhairavi",
                "Thalam": "Ata",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 228,
                "Name": "Nee divya paadamulaku (Mangalam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Veena Kuppaiyyar",
                "BookTitle": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
                "BookAuthor": "Veena Kuppayyar, Tiruvottiyur Tyagayyar",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 229,
                "Name": "Sri Ganapathiyae Thunai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Vinayagar Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 230,
                "Name": "Siddhi arul sivasakthi baalakanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Vinayagar Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 231,
                "Name": "Gajaanana ganeshwaranae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Vinayagar Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 232,
                "Name": "Vandenakarul thandhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HindusthaniKapi",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Vinayagar Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 233,
                "Name": "Guruparanae ",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sadguru Keerthanam",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 234,
                "Name": "Vaa Vaa kalai maathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Saraswathi Keerthanam",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 235,
                "Name": "Jeya Jeya (Thodayam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Jhampa",
                "Composer": "Neelakanta Sivan",
                "Group": "Subramanya Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 236,
                "Name": "Hara Hara Shanmuga Namo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Subramanya Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 237,
                "Name": "Karunaakara Guhanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Subramanya Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 238,
                "Name": "Saravana Bhava Sivaananda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Subramanya Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 239,
                "Name": "Kadaikan paarayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Subramanya Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 240,
                "Name": "Shanmuganae Siva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HindusthaniKapi",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Subramanya Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 241,
                "Name": "Yaenakundriru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Subramanya Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 242,
                "Name": "Vela charanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Subramanya Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 243,
                "Name": "Indha thiru vadivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Subramanya Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 244,
                "Name": "Kandha Vandarul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Subramanya Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 245,
                "Name": "Kannarak kandaenae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Subramanya Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 246,
                "Name": "Saravana Bhava soora",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Subramanya Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 247,
                "Name": "Velayudhanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Subramanya Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 248,
                "Name": "Oraaru muganae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Subramanya Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 249,
                "Name": "Mangalam kumara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Subramanya Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 250,
                "Name": "Sankara Mahadeva",
                "Type": "Virutham",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 251,
                "Name": "Jaya Sankara (Thodayam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Jhampa",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 252,
                "Name": "Jagamellam (Manga sindhu)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mangala Kaushika",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 253,
                "Name": "Mahadeva namo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 254,
                "Name": "Neelakanda nithyananda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 255,
                "Name": "Karunaa saagara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 256,
                "Name": "Enna Vandhalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 257,
                "Name": "Paramaananda sundara sankara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 258,
                "Name": "Unnai thavira",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 259,
                "Name": "Unai nambinaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 260,
                "Name": "Chandrakalaadharanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 261,
                "Name": "Innum irangatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HindusthaniKapi",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 262,
                "Name": "Paarum Paarum Kadai kann",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 263,
                "Name": "Kaalakooda vishamunda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 264,
                "Name": "Sharanam shiva ",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 265,
                "Name": "Manakkavalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 266,
                "Name": "Innum irakkamillaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 267,
                "Name": "Hara hara shanmbho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopaka Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 268,
                "Name": "Shankara nin karunai purivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 269,
                "Name": "Chandrasekaranae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 270,
                "Name": "Mahaa prabho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "YamunaKalyani",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 271,
                "Name": "Nambinae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 272,
                "Name": "Unmel baaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 273,
                "Name": "Chitham kalangaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 274,
                "Name": "Endraikku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 275,
                "Name": "Sambu krupai varumo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 276,
                "Name": "Unn paadamae gathi yenak keesaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 277,
                "Name": "Innamum endhan meedhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 278,
                "Name": "Dayavu seiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 279,
                "Name": "Unn pola deiva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 280,
                "Name": "Enn neramum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 281,
                "Name": "Paramadayakara shankara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Ekam",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 282,
                "Name": "Sodhippadenna jnyaayamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 283,
                "Name": "Eeaswaranae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 284,
                "Name": "Eesanae nin paada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naganandhini",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 285,
                "Name": "Jeya Jeya kailasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 286,
                "Name": "Ini naan marakkenae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 287,
                "Name": "Sivaanandam Brahmaanandam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 288,
                "Name": "Naadanai (with raga mudra)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 289,
                "Name": "Darisikka venum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 290,
                "Name": "Bhajanai seivai manamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 291,
                "Name": "Malayadhae manamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 292,
                "Name": "Dinamum ninai manamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 293,
                "Name": "Sivanai ninai manamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 294,
                "Name": "Sivanai ninaithu sthuthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 295,
                "Name": "Sankaranai dinam dinamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "YamunaKalyani",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 296,
                "Name": "Eesanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 297,
                "Name": "Nambikkol nambikkol sivanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 298,
                "Name": "Manamae unnakuoru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 299,
                "Name": "Sindhanai seivadheppodhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 300,
                "Name": "Theruvadheppo nenje",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 301,
                "Name": "Mosampogaadhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HindusthaniKapi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 302,
                "Name": "Devanai potri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 303,
                "Name": "Adi sivanarul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 304,
                "Name": "Igaparam tharum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 305,
                "Name": "Siva naamam vegu kshemama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 306,
                "Name": "Yezhai nenjae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 307,
                "Name": "Periyor padam pani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 308,
                "Name": "Patrikondaar podhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 309,
                "Name": "Ekaantha nishtayil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 310,
                "Name": "Unnippaaradi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 311,
                "Name": "Yaaraanaalenna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 312,
                "Name": "Yethanai thaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 313,
                "Name": "Aankaaraveriyarkku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 314,
                "Name": "Kelviyilla",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 315,
                "Name": "Sevikkavenum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 316,
                "Name": "Darisikka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 317,
                "Name": "Orunaal orupozhudhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 318,
                "Name": "Pannindhaar piravi noi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 319,
                "Name": "Paramadayakaranai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 320,
                "Name": "Aaruvaruvaar kooda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 321,
                "Name": "Ulagaththukkaaga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 322,
                "Name": "Enna vidham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Ekam",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 323,
                "Name": "aagaadhaagaathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 324,
                "Name": "Nambaadhae Nambaadhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 325,
                "Name": "Aagumaagu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopaka Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 326,
                "Name": "Sivan varuaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 327,
                "Name": "pirattai kandu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 328,
                "Name": "Yaen indha padiyaana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 329,
                "Name": "Marundhirukkudhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 330,
                "Name": "Nan muthirukkudhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HindusthaniKapi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 331,
                "Name": "Chithaththai sivanpaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 332,
                "Name": "Dinamum nee pani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Sivan Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 333,
                "Name": "Parama nanda natanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Nataraja Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 334,
                "Name": "Ananda natam aaduvar thillai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Nataraja Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 335,
                "Name": "Kaanavenum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HindusthaniKapi",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Nataraja Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 336,
                "Name": "Darisanamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Nataraja Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 337,
                "Name": "Aadum chidambaraeshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Nataraja Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 338,
                "Name": "Raajanai thozhuvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Nataraja Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 339,
                "Name": "Aadum paraman paadam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Nataraja Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 340,
                "Name": "Aananda mandru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Nataraja Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 341,
                "Name": "Kattai vittu pogum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Roopaka Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Nataraja Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 342,
                "Name": "Deivam neeyae nalla",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Mahavishnu Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 343,
                "Name": "Vandarul samayamidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Mahavishnu Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 344,
                "Name": "Gopalakrishna dayanidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Mahavishnu Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 345,
                "Name": "Srikanteshwaranai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Mahavishnu Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 346,
                "Name": "Paradevi thirupaarkadal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Mahalakshmi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 347,
                "Name": "Ambikai Gowri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 348,
                "Name": "Karunaakataakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 349,
                "Name": "Thaayae Gathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 350,
                "Name": "Andari Sundari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 351,
                "Name": "Ennidathir krupai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 352,
                "Name": "Aavidaiambiyai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 353,
                "Name": "Charanam Charanam Gomathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 354,
                "Name": "Ambikaiyae arulsei",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 355,
                "Name": "Ambikae unpaada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 356,
                "Name": "Varasugamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 357,
                "Name": "Karunaakari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 358,
                "Name": "Akilaloka janani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Triputa",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 359,
                "Name": "Karunaipuri idhu tharunam meenakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 360,
                "Name": "Nithyakalyani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 361,
                "Name": "Ambikaeunpadamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dwijaavanthi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 362,
                "Name": "Karunai seivaayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 363,
                "Name": "Yaenindha thaamasam Meenakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 364,
                "Name": "Arul puriya idhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Ata Chapu",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 365,
                "Name": "Chindhai irangu neeyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 366,
                "Name": "Nee dayavai aadharippai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 367,
                "Name": "Shakthi gowri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Ekam",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 368,
                "Name": "Rajarajeshwari giriraja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Group": "Devi Keerthanams",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 369,
                "Name": "Manonmanikkummi",
                "Type": "Kummi",
                "Composer": "Neelakanta Sivan",
                "Group": "Kummi paatu",
                "Song Language": "Tamil",
                "BookTitle": "Bhajanai Keerthanangal",
                "BookAuthor": "Neelakanda dasar (Neelakanta Sivan)",
                "Year of Publication": 1929,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 370,
                "Name": "Ashir Abhinandana Padya Maalika",
                "Type": "Sloka",
                "Composer": "Shankaracharya of Govardanah Peetah",
                "Song Language": "Sanskrit",
                "BookTitle": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 371,
                "Name": "Maha Ganapathim Vande",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 372,
                "Name": "Subramanyena Rakshitoham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 373,
                "Name": "Sadaa Shrayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chaamaram/Shanmugapriya",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 374,
                "Name": "Abhayaambhaa Jagadambhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 375,
                "Name": "Aaryaam Abhayaambhaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Triputa",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 376,
                "Name": "Girijayaa Ajayaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 377,
                "Name": "Abhayaambhikaayai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 378,
                "Name": "Abhayaambikaayaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Mishra Jhampai",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 379,
                "Name": "Ambikaayaa Abhayaambikaayaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 380,
                "Name": "Abhayaambhaayaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Triputa",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 381,
                "Name": "Daakshaayani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 382,
                "Name": "Abhayaambaa naayaka vara daayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 383,
                "Name": "Abhayaambhaa naayaka hari saayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 384,
                "Name": "Sri Abhayaambha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 385,
                "Name": "Kari Kalabha mukham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Navagraha Kritis (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 386,
                "Name": "Kumaarasvaaminam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Navagraha Kritis (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 387,
                "Name": "Sooryamoorthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Chatusra Jathi Dhruva Thalam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Navagraha Kritis (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 388,
                "Name": "Chandram Bhaja Maansa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Matya Thalam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Navagraha Kritis (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 389,
                "Name": "Angaarakam Ashrayaamyaham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Navagraha Kritis (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 390,
                "Name": "Bhudamaashrayami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Mishra Jhampai",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Navagraha Kritis (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 391,
                "Name": "Bruhaspathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Triputa",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Navagraha Kritis (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 392,
                "Name": "Shri Sukra Bhagavantham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Khanda Jathi Ata thalam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Navagraha Kritis (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 393,
                "Name": "Divaakara Tanujam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Ekam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Navagraha Kritis (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 394,
                "Name": "Samraamyaham sadaa raahum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "RamaManohari",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Navagraha Kritis (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 395,
                "Name": "Mahaasuram Kedu Maham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chaamaram/Shanmugapriya",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Navagraha Kritis (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 396,
                "Name": "Raktha Ganapathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 397,
                "Name": "Gurumoorthae Bahukeerthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 398,
                "Name": "Thyaagaraajo viraajathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 399,
                "Name": "Thaayagaraaja Mahathva jaaroha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 400,
                "Name": "Thyaagaraaja Yoga Vaibhavam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 401,
                "Name": "Thyaagesham Bhajarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rudrapriya",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 402,
                "Name": "Thyaagaraajam Bhajarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Mishra chapu",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 403,
                "Name": "Thyaagaraajam Bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 404,
                "Name": "Thyaagaraajena Samrakshithoham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SaalagaBhairavi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 405,
                "Name": "Thyaagaraajaaya Namasthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 406,
                "Name": "Thyaagaraajaa Dhanyam na jaanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 407,
                "Name": "Sree Thyaagaraajasya Bhaktobhavaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rudrapriya",
                "Thalam": "Mishra chapu",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 408,
                "Name": "Thyaagaraajae Kruthyaa Kruth",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Mishra Jhampai",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 409,
                "Name": "Veera vasantha thyaagaraja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Veera Vasantha",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 410,
                "Name": "Thyaagaraaja Paalayaashumaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 411,
                "Name": "Anandeshwarena Samrakshithoham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Mishra chapu",
                "Composer": "Muthuswamy Dikshitar",
                "Group": "Tiruvarur Kshetra Panchalinga Krithi",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 412,
                "Name": "Sideshwaraaya Namasthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Mishra chapu",
                "Composer": "Muthuswamy Dikshitar",
                "Group": "Tiruvarur Kshetra Panchalinga Krithi",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 413,
                "Name": "Sadaachaleshwaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Group": "Tiruvarur Kshetra Panchalinga Krithi",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 414,
                "Name": "Haatakeshwara Samrakshamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Group": "Tiruvarur Kshetra Panchalinga Krithi",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 415,
                "Name": "Sree Valmeeka lingam Chinthayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra chapu",
                "Composer": "Muthuswamy Dikshitar",
                "Group": "Tiruvarur Kshetra Panchalinga Krithi",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 416,
                "Name": "Chintayamaam kanda moola",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Group": "Bhuloka Panchalinga Kshetra/Panchabhutha Kshetra Kriti-Kanchipuram (Prithvi)",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 417,
                "Name": "Jambhupathae maampahi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "YamunaKalyani",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Group": "Bhuloka Panchalinga Kshetra/Panchabhutha Kshetra Kriti-Thiruvanaikaval (Appu)",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 418,
                "Name": "Arunaachalanaatham smaraami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Group": "Bhuloka Panchalinga Kshetra/Panchabhutha Kshetra Kriti-Thiruvanamalai (Tejo)",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 419,
                "Name": "Sree Kaalahastheesha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Khanda Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "Group": "Bhuloka Panchalinga Kshetra/Panchabhutha Kshetra Kriti-Kalahasthi (Vayu)",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 420,
                "Name": "Ananda Natana prakaasham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Mishra Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "Group": "Bhuloka Panchalinga Kshetra/Panchabhutha Kshetra Kriti-Chidambaram (Aakaasha)",
                "Song Language": "Sanskrit",
                "BookTitle": "Shiva Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 421,
                "Name": "Sree Gananaatham Bhajare",
                "Type": "Kriti/Keerthanam",
                "Ragam": "EshaManohari",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 422,
                "Name": "Swaminaatha Paripaalayaashumaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 423,
                "Name": "Sree Krishno Maam Rakshathu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nasamani",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 424,
                "Name": "Sree Krishnam Bhajarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Roopavathi",
                "Thalam": "Tisra Ekam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 425,
                "Name": "Vaasudevam Upaasmahae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MaalavaPanchamam",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 426,
                "Name": "Narahari maashrayami sathatham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaya Shuddha Maalavi",
                "Thalam": "Triputa",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 427,
                "Name": "Govardhana gireesham Smarami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 428,
                "Name": "Balakrishnam Bhaavayami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gopikavasantham",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 429,
                "Name": "Chetah Sri Balakrishnam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dwijaavanthi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 430,
                "Name": "Neelaangam Harim Nithyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 431,
                "Name": "Rajagopalam Bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 432,
                "Name": "Parimala Ranganatham Bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 433,
                "Name": "Ramakrishnena Samrakshitoham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 434,
                "Name": "Gopalakrishnaya Namasthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 435,
                "Name": "Krishnananda mukunda murarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlipanthu",
                "Thalam": "Mishra chapu",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 436,
                "Name": "Rangapuravihara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BrindavanaSaranga",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 437,
                "Name": "Ananta balakrishnamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "EshaManohari",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 438,
                "Name": "Sree Venugopala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurinji",
                "Thalam": "Khanda Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 439,
                "Name": "Santhana Sowbhagya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Tisra Ekam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 440,
                "Name": "Shanka Chakra Gada paanim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 441,
                "Name": "Govindaraajam Upasmahae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Mishra chapu",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 442,
                "Name": "Sowndararajam Ashrayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BrindavanaSaranga",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 443,
                "Name": "Sree Sundararajam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kasi Ramakriya",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 444,
                "Name": "Varadarajam Upasmahae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 445,
                "Name": "Maadhavo Maampaathu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Group": "Dasavathara Ragamalika",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 446,
                "Name": "Vinaayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thoyavegavaahini",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 447,
                "Name": "Sowra senaesam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrasenam",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 448,
                "Name": "Sree Raamachandro Rakshathu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Mishra chapu",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 449,
                "Name": "Raamachandram Bhaavayami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 450,
                "Name": "Sree Raamam ravikulaapthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NarayanaGowlai",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 451,
                "Name": "Kodandaraamam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokilaaravam",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 452,
                "Name": "Kshithijaa Ramanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhaari",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 453,
                "Name": "SanthaanaRaamaswaminam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HindolaVasantham",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 454,
                "Name": "Raamachandrena",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maanji",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 455,
                "Name": "Raamachandraaya Namasthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 456,
                "Name": "Raamachandraa Dhanyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 457,
                "Name": "Raamachandrasya Daasoham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhaamavathi/Dharmavathi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 458,
                "Name": "Raamae Bharatha paalitha Raajyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jyothi",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 459,
                "Name": "Maamavapattabhiraama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Mishra Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 460,
                "Name": "Maamava Raghuveera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maahuri",
                "Thalam": "Mishra chapu",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 461,
                "Name": "Raama Raama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Raamakali",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 462,
                "Name": "Bhavanaathmajaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chalanaatai",
                "Thalam": "Khanda Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 463,
                "Name": "Raamachandra Bhaktham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keyahejjajji",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 464,
                "Name": "Veera hanumathae namo namo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 465,
                "Name": "Raamajannardhana",
                "Type": "Nottuswaram",
                "Ragam": "Shankarabharanam",
                "Thalam": "Tisra Jathi Eka Thalam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 466,
                "Name": "Aanjaneyam sadaa Bhaavayaami",
                "Type": "Nottuswaram",
                "Ragam": "Shankarabharanam",
                "Thalam": "Tisra Jathi Eka Thalam",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
                "BookAuthor": "Muthuswamy Dikshitar",
                "Year of Publication": 1957,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 467,
                "Name": "Sree Raajamaathangi",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 468,
                "Name": "Sree Mahaa",
                "Type": "Varnam-Tana",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 469,
                "Name": "Sree Mahishaasura",
                "Type": "Varnam-Tana",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 470,
                "Name": "Sree Mahaaganapathae",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Ata",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 471,
                "Name": "Sree Sugahamsa gamanaa",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Ata",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 472,
                "Name": "Mana mohanaa",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Ata",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 473,
                "Name": "Ganapathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janaranjani",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 474,
                "Name": "Gam Ganapathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 475,
                "Name": "Vaageeswari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 476,
                "Name": "Ambavaani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 477,
                "Name": "Vaani Nithyakalyaani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi Manohari",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 478,
                "Name": "Sharanam Vijaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijaya Saraswathi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 479,
                "Name": "Sree Mahishaasura",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsagamani",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 480,
                "Name": "Vaancha thonunaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KarnaRanjani",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 481,
                "Name": "Meenalochani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 482,
                "Name": "Raajaraajeshwary",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Garudadhwani",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 483,
                "Name": "Maanamukhaavalanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 484,
                "Name": "Ninnu nammi Naanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Mishra Jathi Eka Thalam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 485,
                "Name": "Nee Paada mulanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NataBhairavi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 486,
                "Name": "Pashupathi priya sathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pasupathipriya",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 487,
                "Name": "Thappulanniyu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 488,
                "Name": "Amba Alankaari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Tisra Jathi Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 489,
                "Name": "Jeya niyaashrutha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rathnangi",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 490,
                "Name": "Saarasadalanayaae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saaraamathi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 491,
                "Name": "Samayamidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BudhaManohari",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 492,
                "Name": "Ika nae thaala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Guha Ranjani",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 493,
                "Name": "Sharavana Bhava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pasupathipriya",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 494,
                "Name": "Shree Saravana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sumana priya",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 495,
                "Name": "Siki vaahana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadeepakam",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 496,
                "Name": "Nee vaeyidu paraagu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 497,
                "Name": "Teliyakanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 498,
                "Name": "Valli Naayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 499,
                "Name": "Manasukaraki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MeghaRanjani",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 500,
                "Name": "Ithi Manji samayamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 501,
                "Name": "Ennaalu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mandari",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 502,
                "Name": "Saravana bhavaananda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali/Kamavardini",
                "Thalam": "Adi (Kanda Gathi)",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 503,
                "Name": "Ithivaela",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokilabhaashini",
                "Thalam": "Sankeerna Jathi Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 504,
                "Name": "Naenu jaesina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 505,
                "Name": "Ninnu Nera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 506,
                "Name": "Needu mahima",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 507,
                "Name": "Bhoopaara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 508,
                "Name": "Neepaadhamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 509,
                "Name": "Dheera sikhaamani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 510,
                "Name": "Thaarakanaama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 511,
                "Name": "Sharanaagatha vatsala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 512,
                "Name": "Sree Raghuvara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naayaki",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 513,
                "Name": "Sahajaguna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 514,
                "Name": "Pasupathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 515,
                "Name": "Kalilo hari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 516,
                "Name": "Sree Raama Kodandaraama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 517,
                "Name": "Dinamani vamsa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Tisra Jathi Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 518,
                "Name": "Bandamujaeyaraadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 519,
                "Name": "Thudhikaikuriya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Mishra chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 520,
                "Name": "Muruganukkoru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 521,
                "Name": "Yaarukkum Adangaatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 522,
                "Name": "Moovaasaikonda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1934,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 523,
                "Name": "Pidiathanuruvumai",
                "Type": "Thevaram",
                "Ragam": "Viyaazhakkurinji/Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 524,
                "Name": "Thodidaiya",
                "Type": "Thevaram",
                "Ragam": "Nattapaadai/Shuddha Naatai",
                "Thalam": "Adi",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 525,
                "Name": "Unnaamulai",
                "Type": "Thevaram",
                "Ragam": "Nattapaadai/Shuddha Naatai",
                "Thalam": "Chatusra Jathi Roopaka Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 526,
                "Name": "Silaidhanai",
                "Type": "Thevaram",
                "Ragam": "Nattapaadai/Shuddha Naatai",
                "Thalam": "Chatusra Jathi Jhampa Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 527,
                "Name": "Madaiyil vaLai",
                "Type": "Thevaram",
                "Ragam": "Thakkaraagam/Khambhoji",
                "Thalam": "Chatusra Jathi Roopaka Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 528,
                "Name": "Bhogamaartha",
                "Type": "Thevaram",
                "Ragam": "Pazhanthakkaraagam/Arabhi",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 529,
                "Name": "Vandaanga",
                "Type": "Thevaram",
                "Ragam": "Pazhanthakkaraagam/Arabhi",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 530,
                "Name": "Katrangeri",
                "Type": "Thevaram",
                "Ragam": "Kurinji/Yadukula Khambhoji",
                "Thalam": "Chatusra Jathi Roopaka Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 531,
                "Name": "vambaarkundra",
                "Type": "Thevaram",
                "Ragam": "Kurinji/Yadukula Khambhoji",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 532,
                "Name": "Bandaththaal",
                "Type": "Thevaram",
                "Ragam": "Viyaazhakkurinji/Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 533,
                "Name": "Neerusaervathor",
                "Type": "Thevaram",
                "Ragam": "Megharaagakurinji/Neelaambari",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 534,
                "Name": "Thondelaamalar",
                "Type": "Thevaram",
                "Ragam": "Indhalam/Nadanamakriya",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 535,
                "Name": "Mandiramaavathu",
                "Type": "Thevaram",
                "Ragam": "Gaandhaaram/Navaroj",
                "Thalam": "Chatusra Jathi Roopaka Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 536,
                "Name": "Vaeyurutholi bhangan",
                "Type": "Thevaram",
                "Ragam": "Piyandhaigaandhaaram/Navaroj",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 537,
                "Name": "Thondaranju",
                "Type": "Thevaram",
                "Ragam": "Sevvazhi/Yadukula Khambhoji",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 538,
                "Name": "Maaninaervizhi",
                "Type": "Thevaram",
                "Ragam": "Kolli/Navaroj",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 539,
                "Name": "Kaadhallagi",
                "Type": "Thevaram",
                "Ragam": "Kowsigam/Bhairavi",
                "Thalam": "Adi",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 540,
                "Name": "Veedalaala",
                "Type": "Thevaram",
                "Ragam": "Kowsigam/Bhairavi",
                "Thalam": "Chatusra Jathi Roopaka Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 541,
                "Name": "Sangamaru",
                "Type": "Thevaram",
                "Ragam": "Saadhaari/Pantuvarali",
                "Thalam": "Chatusra Jathi Roopaka Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 542,
                "Name": "Marundhavai",
                "Type": "Thevaram",
                "Ragam": "Saadhaari/Pantuvarali",
                "Thalam": "Tisra Jathi Roopakam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 543,
                "Name": "Vedhavaelviyai",
                "Type": "Thevaram",
                "Ragam": "Pazhampanchuram/Shankarabharanam",
                "Thalam": "Mishra Jathi Eka Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 544,
                "Name": "Maadharmadappidi",
                "Type": "Thevaram",
                "Ragam": "Yaazhmoori/Atana",
                "Thalam": "Chatusra Jathi Roopaka Thalam",
                "Composer": "Thirugnanasambandar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 545,
                "Name": "Kootraayina",
                "Type": "Thevaram",
                "Ragam": "Kolli/Navaroj",
                "Thalam": "Adi",
                "Composer": "Thirunaavukarasar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 546,
                "Name": "Maadarpiraikanni",
                "Type": "Thevaram",
                "Ragam": "Gaandhaaram/Navaroj",
                "Thalam": "Chatusra Jathi Roopaka Thalam",
                "Composer": "Thirunaavukarasar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 547,
                "Name": "Sonmaalai",
                "Type": "Thevaram",
                "Ragam": "Pazhanthakkaraagam/Arabhi",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirunaavukarasar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 548,
                "Name": "Saambalaipoosi",
                "Type": "Thevaram",
                "Ragam": "Bhairavi",
                "Composer": "Thirunaavukarasar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 549,
                "Name": "Karuvaaikidandhu",
                "Type": "Thevaram",
                "Composer": "Thirunaavukarasar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 550,
                "Name": "Maasiveenaiyum",
                "Type": "Thevaram",
                "Ragam": "Poorvikalyani",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirunaavukarasar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 551,
                "Name": "Chandiranai maagangai",
                "Type": "Thevaram",
                "Ragam": "HariKhambhoji",
                "Composer": "Thirunaavukarasar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 552,
                "Name": "Narpadhaththaar",
                "Type": "Thevaram",
                "Composer": "Thirunaavukarasar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 553,
                "Name": "Pithaapirai soodi",
                "Type": "Thevaram",
                "Ragam": "Indhalam/Nadanamakriya",
                "Thalam": "Chatusra Jathi Roopaka Thalam",
                "Composer": "Sundaramoorthy naayanaar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 554,
                "Name": "Ponnaar meniyanae",
                "Type": "Thevaram",
                "Ragam": "Nattaraagam/Pantuvarali",
                "Thalam": "Chatusra Jathi Roopaka Thalam",
                "Composer": "Sundaramoorthy naayanaar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 555,
                "Name": "Thillai vaazh andanar tham",
                "Type": "Thevaram",
                "Ragam": "KollikowvaaNam/Navaroj",
                "Thalam": "Tisra Jathi Roopakam",
                "Composer": "Sundaramoorthy naayanaar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 556,
                "Name": "Vadivudai mazhuvaendhi",
                "Type": "Thevaram",
                "Ragam": "Puraneermai/Bhupaalam",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Sundaramoorthy naayanaar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 557,
                "Name": "Aadukindralai",
                "Type": "Thiruvaasagam",
                "Ragam": "Mohanam",
                "Composer": "Maanikkavaasagar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 558,
                "Name": "Ammaiyae appaa",
                "Type": "Thiruvaasagam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Maanikkavaasagar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 559,
                "Name": "Kangalirandum",
                "Type": "Thiruvaasagam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Maanikkavaasagar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 560,
                "Name": "Vaiyamneeduga",
                "Type": "Periyapuranam",
                "Ragam": "Saveri",
                "Thalam": "Mishra Jathi Eka Thalam",
                "Composer": "Sekkizhaar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 561,
                "Name": "MunduThamizhmaalai",
                "Type": "Thiruppugazh",
                "Ragam": "Navaroj",
                "Thalam": "Adi",
                "Composer": "Arunagirinaathar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 562,
                "Name": "Neiththasurikuzhal",
                "Type": "Thiruppugazh",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Arunagirinaathar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 563,
                "Name": "Pattupadaadha",
                "Type": "Thiruppugazh",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Arunagirinaathar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 564,
                "Name": "Paadhi Madhi nadhi",
                "Type": "Thiruppugazh",
                "Ragam": "Ragamalika",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Arunagirinaathar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 565,
                "Name": "Seeruyarndha",
                "Type": "Chindu",
                "Ragam": "Kharaharapriya",
                "Thalam": "Mishra Jathi Eka Thalam",
                "Composer": "Madurai Govindasamy Iyer",
                "Group": "Thirugnaanasambandha Naayanaar Sindhu",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 566,
                "Name": "Thandayuneeraadi",
                "Type": "Chindu",
                "Ragam": "Kharaharapriya",
                "Thalam": "Chatusra Jathi Roopaka Thalam",
                "Composer": "Madurai Govindasamy Iyer",
                "Group": "Thirugnaanasambandha Naayanaar Sindhu",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 567,
                "Name": "TheLLu shiva",
                "Type": "Chindu",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Madurai Govindasamy Iyer",
                "Group": "Thirugnaanasambandha Naayanaar Sindhu",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 568,
                "Name": "Aariru",
                "Type": "Kandapuranam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Kachiappa Sivacharyar",
                "BookTitle": "Thevara Pann Swara Amaippu",
                "BookAuthor": "Appar, Sundarar, Gnanasambar, Manikavasagar",
                "Year of Publication": 1928,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 569,
                "Name": "Kandaen Kandaen Kandaen Seethayai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavi",
                "Group": "Arunachala Kavi Rama Nataka Keerthanam",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 570,
                "Name": "Sami Raghu Raamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Arunachala Kavi",
                "Group": "Arunachala Kavi Rama Nataka Keerthanam",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 571,
                "Name": "Varugiraano",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavi",
                "Group": "Arunachala Kavi Rama Nataka Keerthanam",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 572,
                "Name": "Ramaswami Konda Kolam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Roopakam",
                "Composer": "Arunachala Kavi",
                "Group": "Arunachala Kavi Rama Nataka Keerthanam",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 573,
                "Name": "Vandaan Vandaan Bharathaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavi",
                "Group": "Arunachala Kavi Rama Nataka Keerthanam",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 574,
                "Name": "Raaman Yezhundarulinaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavi",
                "Group": "Arunachala Kavi Rama Nataka Keerthanam",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 575,
                "Name": "Ennayum vara sonnaro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavi",
                "Group": "Arunachala Kavi Rama Nataka Keerthanam",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 576,
                "Name": "Aadi Devanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavi",
                "Group": "Arunachala Kavi Rama Nataka Keerthanam",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 577,
                "Name": "Charanam Charanam Enndraanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Mishra Chapu",
                "Composer": "Arunachala Kavi",
                "Group": "Arunachala Kavi Rama Nataka Keerthanam",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 578,
                "Name": "Avadaaram Seidhiduveerae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jonpuri",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavi",
                "Group": "Arunachala Kavi Rama Nataka Keerthanam",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 579,
                "Name": "Unnadha mahimayai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavi",
                "Group": "Arunachala Kavi Rama Nataka Keerthanam",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 580,
                "Name": "Iyanae un mahimayai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavi",
                "Group": "Arunachala Kavi Rama Nataka Keerthanam",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 581,
                "Name": "Nee Kaela naayada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 582,
                "Name": "Paripaalayamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 583,
                "Name": "Sri Raghukula Nidhim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 584,
                "Name": "Parama Paavana Raamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 585,
                "Name": "Neranammiti",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanadaa",
                "Thalam": "Khanda Jathi Ata thalam",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 586,
                "Name": "Saamaja vara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Roopakam",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 587,
                "Name": "Saraguna paalimpa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 588,
                "Name": "Nee Paadhamulae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NavarasaKannadaa",
                "Thalam": "Adi",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 589,
                "Name": "Sath Bhakthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 590,
                "Name": "Vega neevu",
                "Type": "Javali",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 591,
                "Name": "Nirupamaana",
                "Type": "Javali",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 592,
                "Name": "Sarasamulaa",
                "Type": "Javali",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 593,
                "Name": "Marulukonnadira",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 594,
                "Name": "Anudinamu",
                "Type": "Javali",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 595,
                "Name": "Thillana-Yadukula Khambhoji",
                "Type": "Thillana",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 596,
                "Name": "Thillana-Thodi",
                "Type": "Thillana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 597,
                "Name": "Sree Venkatesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Tisra Jathi Eka Thalam",
                "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar, Sadashiva Brahmendra",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 598,
                "Name": "Thiruvutrilagu gangai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Chapu",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 599,
                "Name": "SeervaLar pasunthogai (Murugan thudhi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Chapu",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 600,
                "Name": "Thelluthamizhukku (Kazhugumalai nagar vaLam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 601,
                "Name": "Chenni kuLa nagar (Koyil vaLam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 602,
                "Name": "ponnulavu (Kazhugumalai vaLam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HindusthaniKapi",
                "Thalam": "Chapu",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 603,
                "Name": "puLLikkalaabam (vaavi vaLam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Ata Thalam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 604,
                "Name": "Maragatha vikachitha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 605,
                "Name": "pavanagiri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Chapu",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 606,
                "Name": "chendina maranagar vaazh",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Roopakam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 607,
                "Name": "Paadhi raathiri (Surampokku Natrrayirangal)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Ata Thalam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 608,
                "Name": "nevamaayappani (Thalaivi yirangal)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Ata Thalam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 609,
                "Name": "Aarumugavadivelavanae (thalaivi oodal)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desiya Thodi (Bhairavi)",
                "Thalam": "Roopakam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 610,
                "Name": "KaNNayiram paDaiththa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 611,
                "Name": "Bhumi mechchida",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desika Mukhari",
                "Thalam": "Ata",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 612,
                "Name": "Chandavarai vandhaguga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Chapu",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 613,
                "Name": "Vannaththinai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 614,
                "Name": "Chendhin maanagara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Chapu",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 615,
                "Name": "kannal soozh (vaavi vaLam)",
                "Type": "Kriti/Keerthanam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 616,
                "Name": "Chendhoor vaLar (Thalaiviyin oodaL)",
                "Type": "Kriti/Keerthanam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 617,
                "Name": "PaaLaivaai kamugil (Thalaivi varundhudal)",
                "Type": "Kriti/Keerthanam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 618,
                "Name": "EnnaDi naan paetra",
                "Type": "Kriti/Keerthanam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 619,
                "Name": "ManjuniGar",
                "Type": "Kriti/Keerthanam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 620,
                "Name": "Vangaara Maarbilani (Thirupugazh)",
                "Type": "Kriti/Keerthanam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 621,
                "Name": "Pancharatna Thirupugazh",
                "Type": "Kriti/Keerthanam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 622,
                "Name": "Isaindha soolam (Thirupugazh)",
                "Type": "Kriti/Keerthanam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 623,
                "Name": "Karuvinaaru",
                "Type": "Kriti/Keerthanam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Subramanya Swami Peril Kaavadai Chindhu",
                "BookAuthor": "Chinnikulam Annamalai Reddiyar",
                "Year of Publication": 1955,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 624,
                "Name": "Vande Maataram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Subramanya Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 625,
                "Name": "Jayamundu bhayamillai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Deshadi",
                "Composer": "Subramanya Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 626,
                "Name": "Aadum chidambaramo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 627,
                "Name": "Eppo varuvaaro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 628,
                "Name": "Charanam Charanam Raghurama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Mishra Chapu",
                "Composer": "Arunachala Kavi",
                "Group": "Rama Nataka Keerthanam",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 629,
                "Name": "Indhaa vibheeshana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavi",
                "Group": "Rama Nataka Keerthanam",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 630,
                "Name": "Anta Raama Soundaryam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavi",
                "Group": "Rama Nataka Keerthanam",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 631,
                "Name": "Yaen Palli kondeerayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavi",
                "Group": "Rama Nataka Keerthanam",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 632,
                "Name": "Yaar poi sollvaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Subbaraamaiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 633,
                "Name": "Muthukumarayyanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Tisra Ekam",
                "Composer": "Ramaswamy Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 634,
                "Name": "Athalasedanaaraada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Tisra Dhuruvam",
                "Composer": "Arunagirinaathar",
                "Group": "Thirupugazh",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 635,
                "Name": "Unai dinam thozha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Arunagirinaathar",
                "Group": "Thirupugazh",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 636,
                "Name": "Thillai vaazh andanar tham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Jhampai",
                "Composer": "Sundaramoorthy naayanaar",
                "Group": "Thevaram",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 637,
                "Name": "Siraiyaaru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Triputa",
                "Group": "Thevaram",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 638,
                "Name": "Neerusaervathor",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Thirugnanasambandar",
                "Group": "Thevaram",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 639,
                "Name": "Thondaranju",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Triputa",
                "Group": "Thevaram",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 640,
                "Name": "Enneramum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Muthu thandavar",
                "Group": "Ninda Stuti",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 641,
                "Name": "Jaanaki Ramana Dasharatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Vaanamaamalai Jeeyar Swamigal",
                "Song Language": "Sanskrit",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 642,
                "Name": "Dheema thathara",
                "Type": "Thillana",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Ariyakudi Ramanuja Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 643,
                "Name": "Dasharatha Sutha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jonpuri",
                "Thalam": "Roopakam",
                "Composer": "Vaanamaamalai Jeeyar Swamigal",
                "Song Language": "Sanskrit",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 644,
                "Name": "Raamaayika",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 645,
                "Name": "Sadhguru swamiki Satileni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Adi",
                "Praise of": "On Tyagaraja swami",
                "Composer": "Poochi Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 646,
                "Name": "Samaja Varada neeku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Roopakam",
                "Composer": "Poochi Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 647,
                "Name": "Pari paalayamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Poochi Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 648,
                "Name": "Neekelanaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Poochi Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 649,
                "Name": "Govinda katayaparamaananda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Jhampai",
                "Composer": "Narayana Teerthar",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 650,
                "Name": "Vishekamhathaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahiri",
                "Thalam": "Chapu",
                "Composer": "Narayana Teerthar",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 651,
                "Name": "Pooraya mamakaamam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Mishra Chapu",
                "Composer": "Narayana Teerthar",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 652,
                "Name": "Uyyalalukavvayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Jhampai",
                "Composer": "Tyagarajar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 653,
                "Name": "Intha Saukya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Tyagarajar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 654,
                "Name": "Naa moraala kimpa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhaari",
                "Thalam": "Roopakam",
                "Composer": "Tyagarajar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 655,
                "Name": "Vedalanu Kodanda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Tyagarajar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 656,
                "Name": "Kotinadulu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Tyagarajar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 657,
                "Name": "Manasuloni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Tyagarajar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 658,
                "Name": "PalukuKanDa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NavarasaKannadaa",
                "Thalam": "Adi",
                "Composer": "Tyagarajar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 659,
                "Name": "Patali Gathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Adi",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 660,
                "Name": "Tanayuni Brova",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Tyagarajar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 661,
                "Name": "Thathva meruga tharama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Garudadhwani",
                "Thalam": "Adi",
                "Composer": "Tyagarajar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 662,
                "Name": "Raamaa ninne nammi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Tyagarajar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 663,
                "Name": "Venkatesaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Tyagarajar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 664,
                "Name": "Vinayakuni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Tyagarajar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 665,
                "Name": "Entharo mahanubhavulu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Tyagarajar",
                "Group": "Ghana Raga Pancharatnam",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 666,
                "Name": "Pathitha pavanudaina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Tyagarajar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 667,
                "Name": "Parama Bhagavatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Tyagarajar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 668,
                "Name": "Bhagavatha Ramayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Tyagarajar",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi, Gopalakrishna Bharathi",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 669,
                "Name": "Simhaadipathiyae Kadhinidhiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "N S Chidambaram",
                "Group": "Navagraha Nallanjali-Suriyan",
                "Song Language": "Tamil",
                "BookTitle": "Navagraha Nallanjali (Tamil songs on Navagrahas)",
                "BookAuthor": "N. S. Chidambaram, S. Balasubramaniam",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 670,
                "Name": "Katakaadhipathiyae kulir madhiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Composer": "N S Chidambaram",
                "Group": "Navagraha Nallanjali-Chandiran",
                "Song Language": "Tamil",
                "BookTitle": "Navagraha Nallanjali (Tamil songs on Navagrahas)",
                "BookAuthor": "N. S. Chidambaram, S. Balasubramaniam",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 671,
                "Name": "Sevvai endridum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "N S Chidambaram",
                "Group": "Navagraha Nallanjali-Sevvaai",
                "Song Language": "Tamil",
                "BookTitle": "Navagraha Nallanjali (Tamil songs on Navagrahas)",
                "BookAuthor": "N. S. Chidambaram, S. Balasubramaniam",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 672,
                "Name": "Madhitharum Sudhanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "N S Chidambaram",
                "Group": "Navagraha Nallanjali-Budhan",
                "Song Language": "Tamil",
                "BookTitle": "Navagraha Nallanjali (Tamil songs on Navagrahas)",
                "BookAuthor": "N. S. Chidambaram, S. Balasubramaniam",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 673,
                "Name": "Vaanavar tham guruvae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "N S Chidambaram",
                "Group": "Navagraha Nallanjali-Viyaazhan",
                "Song Language": "Tamil",
                "BookTitle": "Navagraha Nallanjali (Tamil songs on Navagrahas)",
                "BookAuthor": "N. S. Chidambaram, S. Balasubramaniam",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 674,
                "Name": "Vaan mazhayai pozhindhida",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Jhampai",
                "Composer": "N S Chidambaram",
                "Group": "Navagraha Nallanjali-Sukran",
                "Song Language": "Tamil",
                "BookTitle": "Navagraha Nallanjali (Tamil songs on Navagrahas)",
                "BookAuthor": "N. S. Chidambaram, S. Balasubramaniam",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 675,
                "Name": "Kaaka vaahananae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Roopakam",
                "Composer": "N S Chidambaram",
                "Group": "Navagraha Nallanjali-Shani",
                "Song Language": "Tamil",
                "BookTitle": "Navagraha Nallanjali (Tamil songs on Navagrahas)",
                "BookAuthor": "N. S. Chidambaram, S. Balasubramaniam",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 676,
                "Name": "Vallamai migundha raahuvae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Roopakam",
                "Composer": "N S Chidambaram",
                "Group": "Navagraha Nallanjali-Raahu",
                "Song Language": "Tamil",
                "BookTitle": "Navagraha Nallanjali (Tamil songs on Navagrahas)",
                "BookAuthor": "N. S. Chidambaram, S. Balasubramaniam",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 677,
                "Name": "Oodhidum pugazh saer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "N S Chidambaram",
                "Group": "Navagraha Nallanjali-Kedhu",
                "Song Language": "Tamil",
                "BookTitle": "Navagraha Nallanjali (Tamil songs on Navagrahas)",
                "BookAuthor": "N. S. Chidambaram, S. Balasubramaniam",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 678,
                "Name": "Anai mugathonae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 679,
                "Name": "Vallal annamalaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 680,
                "Name": "Annamalai annalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Khanda Chapu",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 681,
                "Name": "Ethisayum potrum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 682,
                "Name": "Kalaimagalae ennai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 683,
                "Name": "Muruganai nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Visharada",
                "Thalam": "Roopakam",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 684,
                "Name": "Unnaiyandri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavani",
                "Thalam": "Roopakam",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 685,
                "Name": "Velanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Andolika",
                "Thalam": "Roopakam",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 686,
                "Name": "Pannirukai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Roopakam",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 687,
                "Name": "Ennai aandarul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 688,
                "Name": "Aindhezhuthinai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragavinodini",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 689,
                "Name": "Ellamsei",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 690,
                "Name": "Kadavul Arulai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Roopakam",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 691,
                "Name": "Kadavulai maravathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thandavam",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 692,
                "Name": "Iraivanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Narayani",
                "Thalam": "Roopakam",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 693,
                "Name": "Aandarulvai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 694,
                "Name": "Arulvai angayar kanniyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Khanda Chapu",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 695,
                "Name": "Arula vaendum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saaraamathi",
                "Thalam": "Roopakam",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 696,
                "Name": "Anbukondennai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Mishra Chapu",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 697,
                "Name": "Adiyenai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Tisra Triputa",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 698,
                "Name": "Enai nee maravathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amrithavarshini",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 699,
                "Name": "Varuvai Angayar kanniyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Chapu",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 700,
                "Name": "Maatchi migundha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Roopakam",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 701,
                "Name": "Aaruyiraam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 702,
                "Name": "Thamizh Mozhiyai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 703,
                "Name": "Yazhin Inimai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 704,
                "Name": "Isaiyin ellayai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 705,
                "Name": "Yezhisaiyaagiya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 706,
                "Name": "Paadavaendumae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanadam",
                "Thalam": "Roopakam",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 707,
                "Name": "Kutramillamalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 708,
                "Name": "Aakamumae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 709,
                "Name": "Pugazhai thedi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Roopakam",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 710,
                "Name": "Sinamadaiyaathae seeri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 711,
                "Name": "Vaazhthiduvom vaareer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 712,
                "Name": "Nal vaazhkaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokilam",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 713,
                "Name": "Oruvaraiyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaarmukhavathi",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 714,
                "Name": "En thaayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Revagupti",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 715,
                "Name": "Naavukarasanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 716,
                "Name": "Tirupathi malaiyurai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 717,
                "Name": "Engum niraindha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Khanda Chapu",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 718,
                "Name": "Kalladhavaridam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 719,
                "Name": "Katraar vazhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayantasena",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 720,
                "Name": "Avaiyarindhu pesa vaendum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "VandanaDharini",
                "Thalam": "Roopakam",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 721,
                "Name": "Amaidhi adaindhal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 722,
                "Name": "Vanjam Nirai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Urmika",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 723,
                "Name": "Unmai pesuvom",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 724,
                "Name": "Aruluru vaanavan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyanadayini",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 725,
                "Name": "Osai oliellam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Mishra Jhampai",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 726,
                "Name": "Innuruvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 727,
                "Name": "Pann porundhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 728,
                "Name": "Paadi maghindhiduvom",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jeevanthika",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 729,
                "Name": "Vaazhiya yezhisaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Isai Tamizh Paamaalai",
                "BookAuthor": "MM Dandapani Desikar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 730,
                "Name": "Sri Mahaganapathae Dehi Shivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malahari",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 731,
                "Name": "Saraswathi Devi Thava Charanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi Manohari",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 732,
                "Name": "Shri Sambashiva Sadhguro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suryakantham",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 733,
                "Name": "Balasubramanyam Bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 734,
                "Name": "Jaya Narayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 735,
                "Name": "Hemagirisha manonmani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "EshaManohari",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 736,
                "Name": "Nandikeshawaram Namamyaham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 737,
                "Name": "Shivaya Namaha - Shiva Shiva Shambho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-1",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 738,
                "Name": "Maheshwaraya Namaha - Hara Maheshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-2",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 739,
                "Name": "Shambhavae Namaha - Paramapurusham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Padi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-3",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 740,
                "Name": "Pinaakinae namaha - Pinakinae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BrindavanaSaranga",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-4",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 741,
                "Name": "Sashishekaraya Namaha - Sashishekara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-5",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 742,
                "Name": "Vamadevaya Namaha - Vamadeva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-6",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 743,
                "Name": "Viroopaakshaaya namaha - Sree Virupaakshaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowri",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-7",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 744,
                "Name": "Kaparthinae namaha - Krupa sagaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhava manohari",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-8",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 745,
                "Name": "Neelalohithaya Namaha - neelalohitha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malavi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-9",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 746,
                "Name": "Sankaraya Namaha - Sankara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-10",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 747,
                "Name": "Shulappanayae namaha - Baalalochana soola",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-11",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 748,
                "Name": "Katvaanginae namaha - Kaalatheetha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-12",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 749,
                "Name": "Vishwavallabhaya namaha - Vishwavallabha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-13",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 750,
                "Name": "Sibhivishtaya namaha - Sibhivishtam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-14",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 751,
                "Name": "Ambikanaathaaya namaha - Ambika natham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-15",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 752,
                "Name": "Sree Kantaya namaha - Sree Kanta dhayanidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SaalagaBhairavi",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-16",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 753,
                "Name": "Bhakthavatsalaya namaha - bhakthavatsalabha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Karnataka Behag",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-17",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 754,
                "Name": "Bhavaaya namaha - Sree Bhavam chinmayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Garudadhwani",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-18",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 755,
                "Name": "Sarvaaya namaha - Sarva swaroopam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Karnataka Kapi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-19",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 756,
                "Name": "Trilokeshaya namaha - Sree trilokesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-20",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 757,
                "Name": "Chithikantaya namaha - Hara hara Chithikantaa shiva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-21",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 758,
                "Name": "Shivapriyaya namaha - Shivaapriya jagatheesa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-22",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 759,
                "Name": "Ugraaya namaha - Pashupathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-23",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 760,
                "Name": "Kapalinae namaha - kapalinam vandhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HindusthaniKapi",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-24",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 761,
                "Name": "Kaamaarayae namaha - Kaamaarim bhajamanasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-25",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 762,
                "Name": "Andhakaasurasoothanaaya namaha - Anthakaasura",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-26",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 763,
                "Name": "Gangaadharaaya namaha - Gangadhara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-27",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 764,
                "Name": "Lalaataakshaaya namaha - lalaataaksha namosthute",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-28",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 765,
                "Name": "Kaalakaalaaya namaha - Kaalakaalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Triputa",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-29",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 766,
                "Name": "Krupaanidhayae namaha - Krupaanidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanadam",
                "Thalam": "Triputa",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-30",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 767,
                "Name": "Bheemaaya namaha - Bheemam bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhaari",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-31",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 768,
                "Name": "Parasuhastaaya namaha - Parasuhastaaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NavarasaKannadaa",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-32",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 769,
                "Name": "Mrugapaanayae namaha - Mrugapaanae mruthyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-33",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 770,
                "Name": "Jataadharaaya namaha - Sree Jataadharaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sindhu mandaari",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-34",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 771,
                "Name": "Kailasavaasinae namaha - Kailasavaasam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sindhuramakriya",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-35",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 772,
                "Name": "Kavasinae namaha - Devamkavasinam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bangala",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-36",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 773,
                "Name": "Katoraaya namaha - Sambhum katoram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhushavali",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-37",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 774,
                "Name": "Tripuraandhakaya namaha - tripuranthaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-38",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 775,
                "Name": "Vrushaangaaya namaha - Vrushaangam bhajarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-39",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 776,
                "Name": "Vrushabhaaroodaaya namaha - Shivam Vrushabhaaroodam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanakalyani",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-40",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 777,
                "Name": "BhasmondhooLithavigrahaaya namaha - Jayajaya Bhasmo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Seemandhini",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-41",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 778,
                "Name": "Saamapriyaaya namaha - Saamapriya Shivashankara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-42",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 779,
                "Name": "Swaramayaaya namaha - Swaramayaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-43",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 780,
                "Name": "Trayee moorthayae namaha - Sree Trayee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayanarayani",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-44",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 781,
                "Name": "Aneeshwaraaya namaha - Aneeshwaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-45",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 782,
                "Name": "Sarvagnaaya namaha - Sarvagnyaasaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ravichandrika",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-46",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 783,
                "Name": "Paramaathmanae namaha - Paramaathmanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamsaBrahmari",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-47",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 784,
                "Name": "Somasooryaagnilochanaaya namaha - Somasoorya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-48",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 785,
                "Name": "Haveeshae namaha - Devaaya havishae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gundakkriya",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-49",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 786,
                "Name": "Yagnamayaaya namaha - Yagnamayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavapriya",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-50",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 787,
                "Name": "Somaaya namaha - Somasundari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-51",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 788,
                "Name": "Panchavakthraaya namaha - Panchavakthrashrayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadeepakam",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-52",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 789,
                "Name": "Sadashivaaya namaha - Sadashivam sadashrayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsagamani",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-53",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 790,
                "Name": "Vishweshwaraya namaha - Sree Vishweshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-54",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 791,
                "Name": "Veerabhadraaya namaha - Sree Veerabhadram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-55",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 792,
                "Name": "Gananaathaaya namaha - Gananaatham bhajae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Panchamam",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-56",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 793,
                "Name": "Prajaapathayae namaha - Paripoorna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-57",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 794,
                "Name": "Hiranyaraethasae namaha - Namo hiranya thejo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mangalakaishiki",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-58",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 795,
                "Name": "Durdharshaaya namaha - Anandha roopam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijayasree",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-59",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 796,
                "Name": "Gireeshaaya namaha - Mahaabala gireesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-60",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 797,
                "Name": "Gireeshaaya namha - Gireesham mahesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-61",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 798,
                "Name": "Anaghaaya namaha - Anagham Akilaanda naayakam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mandari",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-62",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 799,
                "Name": "Bhujangabhushanaya namaha - Bhujanga bhushana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ChenjuKhambhoji",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-63",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 800,
                "Name": "Bhargaaya namaha - Bhargam pashupathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jingala",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-64",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 801,
                "Name": "Girithanvinae namaha - Girithanvinam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokiladhwani",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-65",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 802,
                "Name": "Giripriyaaya namaha - Giripriyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kathanakuthuhalam",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-66",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 803,
                "Name": "Kruthivaasasae namaha - Kruthivaasasae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-67",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 804,
                "Name": "Puraaraadhayae namaha - Puraaradhayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-68",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 805,
                "Name": "Bhagavathae namaha - Bhagavathae rudraaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurinji",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-69",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 806,
                "Name": "Brahmadhaadhipaaya namaha - Brahmadhaadhipam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-70",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 807,
                "Name": "Mrutyunjayaaya namaha - Hara Mrutyunjaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-71",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 808,
                "Name": "Sookshmathanavae namaha - Shankaram Sookshmathanum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HindolaVasantham",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-72",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 809,
                "Name": "Jagatvyaapinae namaha - Sarva jagatvyaapinae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manoramaa",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-73",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 810,
                "Name": "Jagatguravae namaha - Jagatguro dheena",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gopikavasantham",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-74",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 811,
                "Name": "Vyomakeshaaya namha - Vyomakesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Andolika",
                "Thalam": "Triputa",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-75",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 812,
                "Name": "Mahaasena jankaaya namaha - Shiva Mahaasena",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-76",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 813,
                "Name": "Chaaruvikramaaya namaha - Chaaruvikrama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-77",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 814,
                "Name": "Rudraaya namaha - Rudram mahesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-78",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 815,
                "Name": "Bhuthapathayae namaha - Bhoothapathayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-79",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 816,
                "Name": "SthaaNavae namaha - SthaaNumbhajaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KarnaRanjani",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-80",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 817,
                "Name": "Ahirputhniyaaya namaha - Purushaaya ahir",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-81",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 818,
                "Name": "Digambaraaya namaha - Digambaram shivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balahamsa",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-82",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 819,
                "Name": "Ashtamoorthayae namaha - Ashtamoorthim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlipanthu",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-83",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 820,
                "Name": "Anaekaathmanae namaha - Anakaathmanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijayanaagari",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-84",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 821,
                "Name": "Saadhvikaaya namaha - Saadhvikam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amrithavarshini",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-85",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 822,
                "Name": "Shuddhavigrahaaya namaha - Paapanaasanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-86",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 823,
                "Name": "Shaashvathaaya namaha - Maanasa Shaashvatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-87",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 824,
                "Name": "Kandaparasavae namaha - Kandaparasavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-88",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 825,
                "Name": "Ajaaya namaha - Ajam haram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-89",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 826,
                "Name": "Paasavimochakaaya namaha - Bhava paapa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pushpalatha",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-90",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 827,
                "Name": "Mrudaaya namaha - Mrutyunjayam mrudam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-91",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 828,
                "Name": "Pashupathayae namaha - Pasupathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saindhavi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-92",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 829,
                "Name": "Devaaya namaha - Devam Charanyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaroj",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-93",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 830,
                "Name": "Mahaadevaaya namaha - Mahaadevam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Narayani",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-94",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 831,
                "Name": "Avyayaaya namaha - Bhajaamyaham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagagandhari",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-95",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 832,
                "Name": "Harayae namaha - Shivamharim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-96",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 833,
                "Name": "Purushathanthapithae namaha - Purushadantha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-97",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 834,
                "Name": "Avyakraaya namaha - Ugraaya Avyakraaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-98",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 835,
                "Name": "Dakshaathvaraharaya namaha - Dakshaath",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-99",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 836,
                "Name": "Haraaya namaha - hara haraa maha deva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-100",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 837,
                "Name": "Bhaganethrapithae namaha - Baalalochanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-101",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 838,
                "Name": "Avyakthaaya namaha - Athi sundaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-102",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 839,
                "Name": "Sahasraakshaaya namaha - Sahasraaksham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naayaki",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-103",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 840,
                "Name": "Sahasrapaathae namaha - Sahasrapaadham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-104",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 841,
                "Name": "Abhavarga pradaaya namaha - Abhavarga pradham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pasupathipriya",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-105",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 842,
                "Name": "Anantaaya namaha - Anataaya sarvaaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-106",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 843,
                "Name": "Taarakaaya namaha - Taarakam Shivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-107",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 844,
                "Name": "Parameshwaraya namaha - Sree Parameshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-108",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 845,
                "Name": "Mangalam - Mangalam Bhavathuthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Shivarchanai/Shivashtothra Keerthanai-Mangalam",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 846,
                "Name": "Suryan - Aadhithyam Devaadi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Navagraha Keerthanai-1",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 847,
                "Name": "Chandran - Chandram Bhajae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Navagraha Keerthanai-2",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 848,
                "Name": "Sevvaai - Angaarakam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Navagraha Keerthanai-3",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 849,
                "Name": "Bhuthan - Rohinae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Navagraha Keerthanai-4",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 850,
                "Name": "Bruhaspathi - Vaachasthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Navagraha Keerthanai-5",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 851,
                "Name": "Sukran - Sree Bhaargavam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Navagraha Keerthanai-6",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 852,
                "Name": "Saneeshwaran - Chaayaadevi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Group": "Navagraha Keerthanai-7",
                "Song Language": "Sanskrit",
                "BookTitle": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 853,
                "Name": "Bharathadesa mendru ",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Subramanya Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Subramanya Bharathi",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 854,
                "Name": "Ninnuvinaaga mari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Ekam",
                "Composer": "Shyama Shastry",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Shyama Shastry",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 855,
                "Name": "Ninne Namminaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Shyama Shastry",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Shyama Shastry",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 856,
                "Name": "Talli ninnu nera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Shyama Shastry",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Shyama Shastry",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 857,
                "Name": "O jagadambaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Shyama Shastry",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Shyama Shastry",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 858,
                "Name": "Brova vamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manji",
                "Thalam": "Chapu",
                "Composer": "Shyama Shastry",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Shyama Shastry",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 859,
                "Name": "Yema ninne",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Shyama Shastry",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Shyama Shastry",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 860,
                "Name": "Biranavara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Shyama Shastry",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Shyama Shastry",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 861,
                "Name": "Kaamakshi nee padayuga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Chapu",
                "Composer": "Shyama Shastry",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Shyama Shastry",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 862,
                "Name": "Kamakshi Amba",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Chapu",
                "Composer": "Shyama Shastry",
                "Song Language": "Telugu",
                "BookTitle": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
                "BookAuthor": "Shyama Shastry",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 863,
                "Name": "Kanakaanga kaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kanakangi",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-1",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 864,
                "Name": "Tharunam Idhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rathnangi",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-2",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 865,
                "Name": "Maa Madhura Saa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ganamurti",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-3",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 866,
                "Name": "Daasa nesa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vanaspathi",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-4",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 867,
                "Name": "Nija Bhakthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manavathi",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-5",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 868,
                "Name": "Vaa Velavaa Vaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tanarupi",
                "Thalam": "Jhampai",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-6",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 869,
                "Name": "Vandharul nee dhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Senavathi",
                "Thalam": "Mishra Chapu",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-7",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 870,
                "Name": "Kali theera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-8",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 871,
                "Name": "Karunaikkadalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhenuka",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-9",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 872,
                "Name": "Irangaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natakapriya",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-10",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 873,
                "Name": "Sukha Vaazhvadai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokilapriya",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-11",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 874,
                "Name": "Naalaagudhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Roopavathi",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-12",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 875,
                "Name": "Naadha nilai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gayakapriya",
                "Thalam": "Jhampai",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-13",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 876,
                "Name": "Nambinaen Ayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-14",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 877,
                "Name": "Naan enn seivaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-15",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 878,
                "Name": "Kaana kan kodi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-16",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 879,
                "Name": "Kanjam Konjam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suryakantham",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-17",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 880,
                "Name": "AaLalaagaadhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hatakambheri",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-18",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 881,
                "Name": "Varam thaarum saami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jhankaradhwani",
                "Thalam": "Mishra Chapu",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-19",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 882,
                "Name": "Amboruha Paadamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NataBhairavi",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-20",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 883,
                "Name": "Velavaa vaa vinai theera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Jhampai",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-21",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 884,
                "Name": "Kanpaaraiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-22",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 885,
                "Name": "Paaraai arul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-23",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 886,
                "Name": "Singaara kumaara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varunapriya",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-24",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 887,
                "Name": "Maalaakinaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MaaraRanjani",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-25",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 888,
                "Name": "Needhaan Appa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-26",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 889,
                "Name": "Malayaadhae manamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-27",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 890,
                "Name": "Neeyae Gathi Endhaayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-28",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 891,
                "Name": "Enai Aalaiyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-29",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 892,
                "Name": "Naayaen unnaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naganandhini",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-30",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 893,
                "Name": "Shambu sadaa Shiva sutha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yagapriya",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-31",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 894,
                "Name": "Kalangaadhae manamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragavardhini",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-32",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 895,
                "Name": "Ninai manamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gangeyabhushani",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-33",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 896,
                "Name": "Naadhaanu santhaana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vagadeeshwari",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-34",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 897,
                "Name": "Paaraamugam thaenae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Soolini",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-35",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 898,
                "Name": "Yaedhaiyaa gathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chalanaatai",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-36",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 899,
                "Name": "Vaarana muga vaa thunai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 900,
                "Name": "Aiyanae AatkoL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Triputa",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 901,
                "Name": "Varavaenum vaani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 902,
                "Name": "Arulvaai Sree meenalochani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 903,
                "Name": "Aimpon anaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 904,
                "Name": "Ini Nammakoru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Praise of": "On Tyagaraja swami",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 905,
                "Name": "Ranjitha kavi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Roopakam",
                "Praise of": "On Kavi Kunjara Bharathi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 1",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1932,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 906,
                "Name": "Paripaahi Ganadhipa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 907,
                "Name": "Paripaalayamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 908,
                "Name": "Parvathi naayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 909,
                "Name": "Rama Rama Guna Seema",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 910,
                "Name": "Paramapurusha Jagadeesha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 911,
                "Name": "Vandae Sadaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NavarasaKannadaa",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 912,
                "Name": "Vande Deva Deva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 913,
                "Name": "Sarasa savadana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 914,
                "Name": "Sarasijanabha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 915,
                "Name": "Sri Ramachandra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 916,
                "Name": "Jagadeesha Sada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nattakurinji",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 917,
                "Name": "Mamava sada janani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 918,
                "Name": "Mamava Padmanabha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 919,
                "Name": "Padmanabha Pahi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 920,
                "Name": "Sarasa sama mrudu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 921,
                "Name": "Jalajanaabha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 922,
                "Name": "Pahi Jagajjanani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 923,
                "Name": "Sathatham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 924,
                "Name": "Deva Deva Jagadeeshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 925,
                "Name": "Pari paahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 926,
                "Name": "Bhaavayae Sree",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 927,
                "Name": "Vandae Sadaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 928,
                "Name": "Gopalam Sevaeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 929,
                "Name": "Mohanamayi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 930,
                "Name": "Mamava Karunaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 931,
                "Name": "Pahi Jagajjanani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 932,
                "Name": "Mamava sada varadae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nattakurinji",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 933,
                "Name": "Chinthayami thae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 934,
                "Name": "Krupaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 935,
                "Name": "Viharamanasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 936,
                "Name": "Kosalendra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 937,
                "Name": "Rama Rama Pahi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhaari",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 938,
                "Name": "Gaangeya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 939,
                "Name": "Paahi Sada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 940,
                "Name": "Jaya Jaya Padmanabha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 941,
                "Name": "Saamodham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 942,
                "Name": "Paalaya Raghu Naayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 943,
                "Name": "Viharamanasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Bhairavi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 944,
                "Name": "Mamava Jagadeeshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi Manohari",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 945,
                "Name": "Mamava Shrutha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavapriya",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 946,
                "Name": "Paahi Padmanabha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 947,
                "Name": "Sarojanabha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 948,
                "Name": "Bhavayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pushpalathika",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 949,
                "Name": "Paalaya Madhava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 950,
                "Name": "Rama Rama Pahi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 951,
                "Name": "Pankajaaksha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 952,
                "Name": "Paahi Daaksha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 953,
                "Name": "Smarajanaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 954,
                "Name": "Kalayae Sree Kamalanayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 955,
                "Name": "Anjaneya Raghurama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 956,
                "Name": "Samajendra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Revagupti",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 957,
                "Name": "Paripaahi maamayi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 958,
                "Name": "Paahi maamaneesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saindhavi",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 959,
                "Name": "Pannagendra shayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahiri",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 960,
                "Name": "Saamodham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Udayaravichandrika",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 961,
                "Name": "Paahi maam Sri Padmanabha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 962,
                "Name": "Jaya Jaya Padmanabha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 963,
                "Name": "Paramapurusham Hrudaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalithapanchamam",
                "Thalam": "Jhampai",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 964,
                "Name": "Reena Madha nutha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 965,
                "Name": "Sadharamava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 966,
                "Name": "Jagadheesha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 967,
                "Name": "Deva Deva maam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 968,
                "Name": "Jagadeesha Sree Ramana maamava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagagandhari",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 969,
                "Name": "Mohanam thava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 970,
                "Name": "Bhogeendra saayinam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Jhampai",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 971,
                "Name": "Sevae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 972,
                "Name": "Smara maanasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 973,
                "Name": "Paahi daaksha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 974,
                "Name": "Pannagashayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 975,
                "Name": "Saarasaaksha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 976,
                "Name": "Sri Kumaara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 977,
                "Name": "Nanda sutha thava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurinji",
                "Thalam": "Mishra Jhampai",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 978,
                "Name": "Santhatham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 979,
                "Name": "Vimalakamala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 980,
                "Name": "Jaya Jaya Raghurama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 981,
                "Name": "Bhakthaparaayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 982,
                "Name": "Raghukula thilaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 983,
                "Name": "KaLakanti",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 984,
                "Name": "Paahisreepathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 985,
                "Name": "Kaaranam vinaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 986,
                "Name": "Saadharamava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 987,
                "Name": "Devi Jagaj janani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Navarathiri Krithi-1",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 988,
                "Name": "Paahi maam Sri vageeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Navarathiri Krithi-2",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 989,
                "Name": "Devi Paavanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Navarathiri Krithi-3",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 990,
                "Name": "Bharathi maamava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Navarathiri Krithi-4",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 991,
                "Name": "Janani Mamavameya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Group": "Navarathiri Krithi-5",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 992,
                "Name": "Saroruhaasana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Navarathiri Krithi-6",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 993,
                "Name": "Janani Paahi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Group": "Navarathiri Krithi-7",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 994,
                "Name": "Paahi janani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Group": "Navarathiri Krithi-8",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 995,
                "Name": "Paahi parvathanandini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Navarathiri Krithi-9",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 996,
                "Name": "Ramachandra paahi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 997,
                "Name": "Paramananda natanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 998,
                "Name": "Jaya Jaya Jagadeesha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "YamunaKalyani",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 999,
                "Name": "Shankara Sree Giri naatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1000,
                "Name": "Kaantha thava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Manipravalam",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1001,
                "Name": "Alarsara paritapam (Jalajabandhu)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Manipravalam",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1002,
                "Name": "TheLiviyalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Manipravalam",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1003,
                "Name": "ValaputhaaLa",
                "Type": "Javali",
                "Ragam": "Atana",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Telugu",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1004,
                "Name": "Idusaahasamu",
                "Type": "Javali",
                "Ragam": "Saindhavi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Telugu",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1005,
                "Name": "Pannagendra shayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1006,
                "Name": "Mangalam-Bhujagashaayino",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Swathi Thirunal Keerthanamala",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1954,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1007,
                "Name": "Gananathanae Gunabhodhanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1008,
                "Name": "Pazham nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Triputa",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1009,
                "Name": "Kundruva vel vaangi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1010,
                "Name": "Kaalakaalan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1011,
                "Name": "Muruga muruga endral",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Mishra Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1012,
                "Name": "Konjik Konji",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1013,
                "Name": "VeNNai unnumen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1014,
                "Name": "Thayae tripura",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Khanda Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1015,
                "Name": "Ethanai tharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1016,
                "Name": "Veledutha kaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1017,
                "Name": "Aaruyir",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1018,
                "Name": "Kazhanigal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1019,
                "Name": "Sathya sevaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhogavasantham",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1020,
                "Name": "Thaayinai KaaNaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1021,
                "Name": "Indha varamarul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1022,
                "Name": "Engae thedugirai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1023,
                "Name": "KaruthilenaaLum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1024,
                "Name": "Unaiyandri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1025,
                "Name": "Pazhani malaithanil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1026,
                "Name": "Gurupara guhanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1027,
                "Name": "Yedhaedho enniya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1028,
                "Name": "Appa unaimaravaenae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1029,
                "Name": "Enmanam polae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1030,
                "Name": "Saradhiyaai varuvaayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "VandanaDharini",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1031,
                "Name": "Iniyaedhu kavalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GambiraNattai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1032,
                "Name": "Muruga enadharuyirae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1033,
                "Name": "Dheem Dheem Dheem",
                "Type": "Thillana",
                "Ragam": "Udayaravichandrika",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1034,
                "Name": "Indha veedu undran",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhaari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1035,
                "Name": "Kalaiyinbhamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1036,
                "Name": "Pizhai ninaindhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1037,
                "Name": "Shanthamillamal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1038,
                "Name": "Aadum perumaanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1039,
                "Name": "Kai koduppayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1040,
                "Name": "Manamirangaadhadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1041,
                "Name": "Endro nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1042,
                "Name": "Kailaayamgirithanail",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Khanda Jathi Matya thalam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1043,
                "Name": "Nammkoru kurai yaedhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1044,
                "Name": "Enna Punniyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desiya Ranjani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1045,
                "Name": "Appan irukkum bodhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1046,
                "Name": "Endraenai naadiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vilasini",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1047,
                "Name": "Samagana priyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1048,
                "Name": "Yaaro avan yaaro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1049,
                "Name": "Paarellam Arulaalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1050,
                "Name": "Arangaa aravaNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1051,
                "Name": "Kaanbhathendro naam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pilu",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1052,
                "Name": "Sahiyae inni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1053,
                "Name": "Urangiduvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1054,
                "Name": "Kannan Kuzhalosai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sindhubhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1055,
                "Name": "Kandaen kanavinilae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1056,
                "Name": "Thanjamendru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Isai Manjari (Periyasamy Thooran)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1057,
                "Name": "Gana naatha",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1058,
                "Name": "Ninne Nammi",
                "Type": "Varnam-Tana",
                "Ragam": "ReethiGowlai",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Telugu",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1059,
                "Name": "Saarasadala",
                "Type": "Swarajathi",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1060,
                "Name": "SreeMahaGanapathae (Thripadam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Group": "Tripadam",
                "Song Language": "Sanskrit",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1061,
                "Name": "Vidhya dhaarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Group": "Tripadam",
                "Song Language": "Sanskrit",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1062,
                "Name": "Sree Kamalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Haripriya",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Group": "Tripadam",
                "Song Language": "Sanskrit",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1063,
                "Name": "Sivanukkilaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Roopakam",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1064,
                "Name": "Mayil vaahana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amrithavarshini",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1065,
                "Name": "Kaavaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1066,
                "Name": "Kaarthikeyanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Roopakam",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1067,
                "Name": "Kandaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1068,
                "Name": "Yaenaiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Triputa",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1069,
                "Name": "Unnai ninaindhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayantasree",
                "Thalam": "Roopakam",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1070,
                "Name": "Vadivel",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rasavinodini",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1071,
                "Name": "Sharavana bhava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malkauns",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1072,
                "Name": "Anjael Anjael",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1073,
                "Name": "Gaandhamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desiya Thodi",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1074,
                "Name": "Vaa Vaa Vel Murugaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desiya Thodi",
                "Thalam": "Roopakam",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1075,
                "Name": "Ullathinidhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sindhubhairavi",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1076,
                "Name": "Sirparanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1077,
                "Name": "Sivaananda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1078,
                "Name": "Amba meenalochani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nayaki",
                "Thalam": "Roopakam",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Sanskrit",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1079,
                "Name": "Maathaa sadhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Roopakam",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Sanskrit",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1080,
                "Name": "Amba meenakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Sanskrit",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1081,
                "Name": "Paramapaavani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sindhu Gowri",
                "Thalam": "Roopakam",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Sanskrit",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1082,
                "Name": "Thaayae Tharunam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1083,
                "Name": "Maamava harae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Karnataka Behag",
                "Thalam": "Roopakam",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Sanskrit",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1084,
                "Name": "Madhusoodhana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasanana",
                "Thalam": "Triputa",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Sanskrit",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1085,
                "Name": "Kaarmughil vaNNa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1086,
                "Name": "Devaadi devar pugazh",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Group": "Odappattu",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1087,
                "Name": "Bhaaratha janani",
                "Type": "Notes",
                "Ragam": "Shankarabharanam",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Group": "Bharat Bhajan",
                "Song Language": "Sanskrit",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1088,
                "Name": "Smarasa paavana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhimplas",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Group": "Bharat Bhajan",
                "Song Language": "Sanskrit",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1089,
                "Name": "Jayathi Jayathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Group": "Bharat Bhajan",
                "Song Language": "Sanskrit",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1090,
                "Name": "Shantha Ahimsa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi (Hindusthani)",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Group": "Mahatma Gandhi Sankeerthanam",
                "Song Language": "Sanskrit",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1091,
                "Name": "Bhagavaan mudatre ulagu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Group": "Tirukural Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1092,
                "Name": "Neri nindraar needu vaazhwar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Mayuram T R Vishwanatha Shastry",
                "Group": "Tirukural Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Vishwa Madura Keerthanai",
                "BookAuthor": "Mayuram T R Vishwanatha Shastry",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1093,
                "Name": "Mayaatheetha swaroopini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Group": "Guru Stuti",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1094,
                "Name": "Thodi Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Thodi",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1095,
                "Name": "Shankarabharanam Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Shankarabharanam",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1096,
                "Name": "Atana Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Atana",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1097,
                "Name": "Bhairavi Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Bhairavi",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1098,
                "Name": "Kalyani Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Kalyani",
                "Thalam": "Tisra Triputa",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1099,
                "Name": "Thodi Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1100,
                "Name": "Khamas Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Khamas",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1101,
                "Name": "Ragamalika Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Ragamalika",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1102,
                "Name": "Shankarabharanam Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1103,
                "Name": "Saveri Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Saveri",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1104,
                "Name": "Chakravaham Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Chakravaham",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1105,
                "Name": "Kalyani Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Kalyani",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1106,
                "Name": "Vasantha Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Vasantha",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1107,
                "Name": "Hemavathi Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Hemavathi",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1108,
                "Name": "Poorvikalyani Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi (Sankeerna nadai)",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "NA",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1109,
                "Name": "Khambhoji Shabdam (Sree karaa Sugunaa)",
                "Type": "Shabdam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1110,
                "Name": "Khambhoji Shabdam (Sadayudaanae)",
                "Type": "Shabdam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1111,
                "Name": "Khambhoji Shabdam (Sami ninne)",
                "Type": "Shabdam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1112,
                "Name": "Khambhoji Shabdam (Sarasijaakshulu)",
                "Type": "Shabdam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1113,
                "Name": "Khambhoji Shabdam (Niratha munanee)",
                "Type": "Shabdam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1114,
                "Name": "Khambhoji Shabdam (Gokulaambhudhi)",
                "Type": "Shabdam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1115,
                "Name": "Khambhoji Shabdam (VeNyudaa)",
                "Type": "Shabdam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1116,
                "Name": "Khambhoji Shabdam (Saaryagu naera)",
                "Type": "Shabdam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1117,
                "Name": "Dhanyasi Swarajathi (Gopala Lola)",
                "Type": "Swarajathi",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1118,
                "Name": "Atana Swarajathi (Saami Nee ram)",
                "Type": "Swarajathi",
                "Ragam": "Atana",
                "Thalam": "Tisra Ekam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1119,
                "Name": "Thodi Swarajathi (Samiki jaalamae)",
                "Type": "Swarajathi",
                "Ragam": "Thodi",
                "Thalam": "Tisra Ekam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1120,
                "Name": "YadukulaKhambhoji Swarajathi (Sarojaakshiro)",
                "Type": "Swarajathi",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1121,
                "Name": "Chakravaham Swarathi (Sadayaayika)",
                "Type": "Swarajathi",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1122,
                "Name": "Huseni Swarajathi (Yaemandhi yaanaraa)",
                "Type": "Swarajathi",
                "Ragam": "Huseni",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1123,
                "Name": "Sahana Swarajathi (Naetiki Dhayavachchaena)",
                "Type": "Swarajathi",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1124,
                "Name": "Huseni Swarajathi (Endha maayakkaariyo)",
                "Type": "Swarajathi",
                "Ragam": "Huseni",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1125,
                "Name": "Chakravaham Swarathi (Saami Mael)",
                "Type": "Swarajathi",
                "Ragam": "Chakravaham",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1126,
                "Name": "Khambhoji Swarajathi (Kaana Aavalaanaen)",
                "Type": "Swarajathi",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1127,
                "Name": "Manavikai",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1128,
                "Name": "Adi mohamkondaen",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1129,
                "Name": "Samini rammanavae",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1130,
                "Name": "Saamiyai Azhaithu vaadi",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1131,
                "Name": "Saami rammanavae",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1132,
                "Name": "Sarasaa ninu",
                "Ragam": "Kapi",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1133,
                "Name": "Sarasaa ninai maravael",
                "Ragam": "Kapi",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1134,
                "Name": "Nee Saati Thora",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1135,
                "Name": "Mohamaana",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1136,
                "Name": "Sahiyae Indha Vaelayil",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1137,
                "Name": "Samiyai azhaithu vaadi",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1138,
                "Name": "Mohalaahiri",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1139,
                "Name": "Saami kaa naa dho",
                "Ragam": "Manohari",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1140,
                "Name": "Saami naapai",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1141,
                "Name": "Sarasa Shikaamani",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1142,
                "Name": "Saami naepalu kaami",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1143,
                "Name": "Thaanikae thagu",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1144,
                "Name": "Thaani saati podi",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1145,
                "Name": "Saami unnaiyae",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1146,
                "Name": "Saro jaakshiro",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1147,
                "Name": "Saami ninne",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1148,
                "Name": "Saami nee vaeyani",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1149,
                "Name": "Salamu jaesithae",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1150,
                "Name": "Yae Maguva bhodinchara",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1151,
                "Name": "Sami ninne kori",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1152,
                "Name": "Sarasooda nee vaeyani",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "Pandanallur Meenakshi Sundaram Pillai",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1153,
                "Name": "Intha kopamelara",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Pandanallur Meenakshi Sundaram Pillai",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1154,
                "Name": "Chalamela",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1155,
                "Name": "Sarasija naabha",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1156,
                "Name": "Madhana bilahari",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Group": "Chatur Raga sloka malika",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1157,
                "Name": "Saami ninnu vaegamae",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Group": "Twele Raga ragamallika",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1158,
                "Name": "Pannagaa theercha",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1159,
                "Name": "Kaar viriyum",
                "Ragam": "Ragamalika",
                "Thalam": "Khanda chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Group": "Desiya Ashta raga maalika",
                "Song Language": "Tamil",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1160,
                "Name": "Thodi Surapadam (Daani sarisamaanee)",
                "Type": "Surapadam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1161,
                "Name": "ValaputhaaLa",
                "Type": "Padam",
                "Ragam": "Atana",
                "Thalam": "Mishra Chapu",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1162,
                "Name": "Thaani pothana",
                "Type": "Javali",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1163,
                "Name": "Idu saahasa",
                "Type": "Javali",
                "Ragam": "Saindhavi",
                "Thalam": "Adi",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1164,
                "Name": "Thodi Thillana",
                "Type": "Thillana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1165,
                "Name": "Thodi Thillana",
                "Type": "Thillana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1166,
                "Name": "Kaanadaa Thillana",
                "Type": "Thillana",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1167,
                "Name": "Kapi Thillana",
                "Type": "Thillana",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1168,
                "Name": "Bilahari Thillana",
                "Type": "Thillana",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1169,
                "Name": "Shankarabharanam Thillana",
                "Type": "Thillana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1170,
                "Name": "Atana Thillana",
                "Type": "Thillana",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1171,
                "Name": "Behag Thillana",
                "Type": "Thillana",
                "Ragam": "Behag",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1172,
                "Name": "Kalyani Thillana",
                "Type": "Thillana",
                "Ragam": "Kalyani",
                "Thalam": "Sankeerna laghu deshadi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1173,
                "Name": "Hamsanandi Thillana",
                "Type": "Thillana",
                "Ragam": "Hamsanandi",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Sanskrit",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1174,
                "Name": "Mandari Thillana",
                "Type": "Thillana",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1175,
                "Name": "Begada Thillana",
                "Type": "Thillana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1176,
                "Name": "Vinayagar Kavuthuvam",
                "Type": "Kavuthuvam",
                "Ragam": "Nattai",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Group": "Panchamurthy Kavuthuvam",
                "Song Language": "Tamil",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1177,
                "Name": "Murugan Kavuthuvam",
                "Type": "Kavuthuvam",
                "Ragam": "Gowlai",
                "Thalam": "Chatusra Ekam",
                "Composer": "Tanjore Quartet",
                "Group": "Panchamurthy Kavuthuvam",
                "Song Language": "Tamil",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1178,
                "Name": "Sambhandar Kavuthuvam",
                "Type": "Kavuthuvam",
                "Ragam": "Arabhi",
                "Thalam": "Sarva Laghu",
                "Composer": "Tanjore Quartet",
                "Group": "Panchamurthy Kavuthuvam",
                "Song Language": "Tamil",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1179,
                "Name": "Chandekeshwarar Kavuthuvam",
                "Type": "Kavuthuvam",
                "Ragam": "Sree",
                "Thalam": "Chatusra Ekam",
                "Composer": "Tanjore Quartet",
                "Group": "Panchamurthy Kavuthuvam",
                "Song Language": "Tamil",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1180,
                "Name": "Natarajar Kavuthuvam",
                "Type": "Kavuthuvam",
                "Ragam": "Varali",
                "Thalam": "Sarva Laghu",
                "Composer": "Tanjore Quartet",
                "Group": "Panchamurthy Kavuthuvam",
                "Song Language": "Sanskrit",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1181,
                "Name": "Brahma sandhi Kavuthuvam",
                "Type": "Kavuthuvam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Group": "Nava Sandhi Kavuthuvam",
                "Song Language": "Tamil",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1182,
                "Name": "Indra Sandhi Kavuthuvam",
                "Type": "Kavuthuvam",
                "Ragam": "Gurjari",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Group": "Nava Sandhi Kavuthuvam",
                "Song Language": "Tamil",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1183,
                "Name": "Agni Sandhi Kavuthuvam",
                "Type": "Kavuthuvam",
                "Ragam": "Nattai",
                "Thalam": "Chatusra Jhampai",
                "Composer": "Tanjore Quartet",
                "Group": "Nava Sandhi Kavuthuvam",
                "Song Language": "Tamil",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1184,
                "Name": "Yama Sandhi Kavuthuvam",
                "Type": "Kavuthuvam",
                "Ragam": "Desakshi",
                "Thalam": "Chatusra Ekam",
                "Composer": "Tanjore Quartet",
                "Group": "Nava Sandhi Kavuthuvam",
                "Song Language": "Tamil",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1185,
                "Name": "Niruthi Sandhi Kavuthuvam",
                "Type": "Kavuthuvam",
                "Ragam": "Kuntalam",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Group": "Nava Sandhi Kavuthuvam",
                "Song Language": "Tamil",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1186,
                "Name": "Varuna Sandhi Kavuthuvam",
                "Type": "Kavuthuvam",
                "Ragam": "Varali",
                "Thalam": "Chatusra Ekam",
                "Composer": "Tanjore Quartet",
                "Group": "Nava Sandhi Kavuthuvam",
                "Song Language": "Tamil",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1187,
                "Name": "Vayu Sandhi Kavuthuvam",
                "Type": "Kavuthuvam",
                "Ragam": "Makuta Ramagiri",
                "Thalam": "Chatusra Ekam",
                "Composer": "Tanjore Quartet",
                "Group": "Nava Sandhi Kavuthuvam",
                "Song Language": "Tamil",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1188,
                "Name": "Kubera Sandhi Kavuthuvam",
                "Type": "Kavuthuvam",
                "Ragam": "Maalavasri",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Group": "Nava Sandhi Kavuthuvam",
                "Song Language": "Tamil",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1189,
                "Name": "Eesaanya Sandhi Kavuthuvam",
                "Type": "Kavuthuvam",
                "Ragam": "Malahari",
                "Thalam": "Khanda Ekam",
                "Composer": "Tanjore Quartet",
                "Group": "Nava Sandhi Kavuthuvam",
                "Song Language": "Tamil",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1190,
                "Name": "Geetham raerae Sree raa",
                "Type": "Geetham",
                "Ragam": "Arabhi",
                "Thalam": "Tisra Triputa",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1191,
                "Name": "Soolathi Narayana",
                "Type": "Sulathi",
                "Ragam": "Mohanam",
                "Thalam": "Tisra Triputa",
                "Composer": "Tanjore Quartet",
                "Song Language": "Sanskrit",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1192,
                "Name": "Prabandham Chandasow",
                "Type": "Prabandam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Chatusra Matyam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Sanskrit",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1193,
                "Name": "Kaivaara Prabandham Thothika",
                "Type": "Prabandam",
                "Ragam": "NarayanaGowlai",
                "Thalam": "Chatusra Matyam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Sanskrit",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1194,
                "Name": "Nattai Taayam",
                "Type": "Taayam",
                "Ragam": "Nattai",
                "Song Language": "NA",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1195,
                "Name": "Gowlai Taayam",
                "Type": "Taayam",
                "Ragam": "Gowlai",
                "Song Language": "NA",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1196,
                "Name": "Varali Taayam",
                "Type": "Taayam",
                "Ragam": "Varali",
                "Song Language": "NA",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1197,
                "Name": "Varali Taayam",
                "Type": "Taayam",
                "Ragam": "Varali",
                "Song Language": "NA",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1198,
                "Name": "Gowlai Taayam",
                "Type": "Taayam",
                "Ragam": "Gowlai",
                "Song Language": "NA",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1199,
                "Name": "Bowli Taayam",
                "Type": "Taayam",
                "Ragam": "Bhowli",
                "Song Language": "NA",
                "BookTitle": "Adi Bharatha Kala Manjari",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1200,
                "Name": "Kanakangi",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1201,
                "Name": "Vanajakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Lalitha",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1202,
                "Name": "Panthamela naasaami",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1203,
                "Name": "Nee vamti saami",
                "Type": "Varnam-Tana",
                "Ragam": "KamalaManohari",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1204,
                "Name": "Saamikara",
                "Type": "Varnam-Tana",
                "Ragam": "Nattakurinji",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1205,
                "Name": "Kanakangi",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1206,
                "Name": "Naameetha dhayanumchi",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1207,
                "Name": "Soothathi ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1208,
                "Name": "Valachivachi",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1209,
                "Name": "Saami naapai",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1210,
                "Name": "Chalamu jesethi",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1211,
                "Name": "Chalamela",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1212,
                "Name": "Sarasija nabha ",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1213,
                "Name": "Vanajaksha",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1214,
                "Name": "Samukhamu",
                "Type": "Varnam-Tana",
                "Ragam": "Nattai",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1215,
                "Name": "Intha chalamu seya",
                "Type": "Varnam-Tana",
                "Ragam": "Surutti",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1216,
                "Name": "Karunaa karuda vanu",
                "Type": "Varnam-Tana",
                "Ragam": "Atana",
                "Thalam": "Tisra Jathi Ata thalam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1217,
                "Name": "Manasu telisi",
                "Type": "Varnam-Tana",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1218,
                "Name": "Saranga nayanaro",
                "Type": "Varnam-Tana",
                "Ragam": "Saranga",
                "Thalam": "Roopakam",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1219,
                "Name": "Saarasaakshiro",
                "Type": "Varnam-Tana",
                "Ragam": "Nattakurinji",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1220,
                "Name": "Maayaatheetha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Group": "Guru Stuti-Navarathna mala",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1221,
                "Name": "Sree Guruguha murti",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PinnaShadjam/Dhenuka",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Group": "Guru Stuti-Navarathna mala",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1222,
                "Name": "Sree karambu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda chapu",
                "Composer": "Tanjore Quartet",
                "Group": "Guru Stuti-Navarathna mala",
                "Song Language": "Sanskrit",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1223,
                "Name": "Saarasaakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shailadesaakshi",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Group": "Guru Stuti-Navarathna mala",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1224,
                "Name": "Paramapavani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Group": "Guru Stuti-Navarathna mala",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1225,
                "Name": "Neethu paadhamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Group": "Guru Stuti-Navarathna mala",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1226,
                "Name": "Sree Rajarajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Group": "Guru Stuti-Navarathna mala",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1227,
                "Name": "Satileni guruguha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Group": "Guru Stuti-Navarathna mala",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1228,
                "Name": "Saaregu nee paadamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Group": "Guru Stuti-Navarathna mala",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1229,
                "Name": "Dheenarakshaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahiri",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1230,
                "Name": "Ranganaathudae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1231,
                "Name": "Sabhalasiththu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1232,
                "Name": "Bruhadeeshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1233,
                "Name": "Ninnupaasi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1234,
                "Name": "Kaamithaphaladayaki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Jhampai",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1235,
                "Name": "Parama Kalyani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1236,
                "Name": "Adi Parameshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1237,
                "Name": "Bhakta paalana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1238,
                "Name": "Sree Mahadevyni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1239,
                "Name": "Ninnusaareku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1240,
                "Name": "Mahadeva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1241,
                "Name": "Bruhadamba",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Jhampai",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1242,
                "Name": "Ambayaninae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1243,
                "Name": "Nee Paadhamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1244,
                "Name": "Amba Neelambari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1245,
                "Name": "Nee Sari Saati",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1246,
                "Name": "Mangalapradhapulichu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Praise of": "On Madurai Meenakshi Amman",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1247,
                "Name": "Krupajoodavamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "On Kanchipuram Kamakshi amman",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1248,
                "Name": "Kaliyugamuna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Mishra Chapu",
                "Praise of": "On Nagapatnam Nilaayatakshi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1249,
                "Name": "Amba Sowrambha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Praise of": "On Mysore Chamundeshwari",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1250,
                "Name": "Karunaseyavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Pandanallur Meenakshi Sundaram Pillai",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1251,
                "Name": "Gandhuka nagaramuna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Pandanallur Meenakshi Sundaram Pillai",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1252,
                "Name": "Tharunam eedhamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlipanthu",
                "Thalam": "Adi",
                "Composer": "Shyama Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1253,
                "Name": "Enna Punniyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Mishra Chapu",
                "Composer": "Shiva nama yogi",
                "Song Language": "Tamil",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1254,
                "Name": "Perungoil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Mishra Chapu",
                "Composer": "K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1255,
                "Name": "Ippadi En manam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Mishra Chapu",
                "Composer": "Thiruveezhi malai swaminatha Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1256,
                "Name": "Jagadeeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Tiruvarur Ramaswamy Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1257,
                "Name": "Ekkalathilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Tiruvarur Ramaswamy Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1258,
                "Name": "Idhu nalla samayamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Tiruvarur Ramaswamy Pillai",
                "Group": "Chatur Bhasha Malika (Tamil/Kannada/Hindi/Sanskrit)",
                "Song Language": "NA",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1259,
                "Name": "Kalyani kaluyath katha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal Lyrics/Vadivelu (Tanjore Quartet) Music",
                "Group": "Chatur Raga Malika",
                "Song Language": "Sanskrit",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1260,
                "Name": "Saanamtham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal Lyrics/Vadivelu (Tanjore Quartet) Music",
                "Group": "Chatur Raga Malika",
                "Song Language": "Sanskrit",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1261,
                "Name": "Gowri Naayaka",
                "Type": "Thillana",
                "Ragam": "Kaanadaa",
                "Thalam": "Simhanandanam",
                "Composer": "Maha Vaidyanatha Iyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1262,
                "Name": "Mandari Thillana",
                "Type": "Thillana",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1263,
                "Name": "Begada Thillana",
                "Type": "Thillana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1264,
                "Name": "Kapi Thillana",
                "Type": "Thillana",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Sanskrit",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1265,
                "Name": "Bilahari Thillana",
                "Type": "Thillana",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1266,
                "Name": "Atana Thillana",
                "Type": "Thillana",
                "Ragam": "Atana",
                "Thalam": "Deshadi",
                "Praise of": "On ThiruvetkaLam ambal",
                "Composer": "K Ponniah Pillai",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1267,
                "Name": "Behag Thillana",
                "Type": "Thillana",
                "Ragam": "Behag",
                "Thalam": "Mishra Chapu",
                "Composer": "K Ponniah Pillai",
                "Song Language": "Telugu",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1268,
                "Name": "Thodi Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Thodi",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1269,
                "Name": "Shankarabharanam Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Shankarabharanam",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1270,
                "Name": "Atana Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Atana",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1271,
                "Name": "Bhairavi Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Bhairavi",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1272,
                "Name": "Kalyani Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Kalyani",
                "Thalam": "Tisra Triputa",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1273,
                "Name": "Thodi Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1274,
                "Name": "Khamas Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Khamas",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1275,
                "Name": "Ragamalika Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Ragamalika",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1276,
                "Name": "Shankarabharanam Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1277,
                "Name": "Saveri Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Saveri",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1278,
                "Name": "Chakravaham Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Chakravaham",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1279,
                "Name": "Kalyani Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Kalyani",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1280,
                "Name": "Vasantha Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Vasantha",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1281,
                "Name": "Hemavathi Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Hemavathi",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1282,
                "Name": "Poorvikalyani Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1283,
                "Name": "Sree Karasuku",
                "Type": "Shabdam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1284,
                "Name": "Sadayudaanae",
                "Type": "Shabdam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1285,
                "Name": "Sami ninne",
                "Type": "Shabdam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1286,
                "Name": "Sarasi Jaakshi",
                "Type": "Shabdam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1287,
                "Name": "Niradha munani",
                "Type": "Shabdam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1288,
                "Name": "Gokulaambhudhi",
                "Type": "Shabdam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1289,
                "Name": "VeNyudaa",
                "Type": "Shabdam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1290,
                "Name": "Gopalaa",
                "Type": "Swarajathi",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1291,
                "Name": "Sami nirammanavae",
                "Type": "Swarajathi",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1292,
                "Name": "Saamiki",
                "Type": "Swarajathi",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1293,
                "Name": "Sarojaakshi",
                "Type": "Swarajathi",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1294,
                "Name": "Sadayaa Ika",
                "Type": "Swarajathi",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1295,
                "Name": "Yaemanthayaanaraa",
                "Type": "Swarajathi",
                "Ragam": "Huseni",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1296,
                "Name": "Nae tiki",
                "Type": "Swarajathi",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1297,
                "Name": "Saami mael",
                "Type": "Swarajathi",
                "Ragam": "Chakravaham",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1298,
                "Name": "Kaana Aavalaanaen",
                "Type": "Swarajathi",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1299,
                "Name": "Manavigai",
                "Type": "Varnam-Pada",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1300,
                "Name": "Sami neerammanavae",
                "Type": "Varnam-Pada",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1301,
                "Name": "Sami neerammanavae",
                "Type": "Varnam-Pada",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1302,
                "Name": "Sarasaa ninu",
                "Type": "Varnam-Pada",
                "Ragam": "Kapi",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1303,
                "Name": "Saaminaa kaatho",
                "Type": "Varnam-Pada",
                "Ragam": "Manohari",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1304,
                "Name": "Saami naapai",
                "Type": "Varnam-Pada",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1305,
                "Name": "Nee Saati thora",
                "Type": "Varnam-Pada",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1306,
                "Name": "Sarasa Shikaamani",
                "Type": "Varnam-Pada",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1307,
                "Name": "Saami naepalu",
                "Type": "Varnam-Pada",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1308,
                "Name": "Thaani kae",
                "Type": "Varnam-Pada",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1309,
                "Name": "Thaa nee saati",
                "Type": "Varnam-Pada",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1310,
                "Name": "Saarojaakshi",
                "Type": "Varnam-Pada",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1311,
                "Name": "Saami ninne",
                "Type": "Varnam-Pada",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1312,
                "Name": "Saami neevaeyana",
                "Type": "Varnam-Pada",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1313,
                "Name": "Chalamu jaesithae",
                "Type": "Varnam-Pada",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1314,
                "Name": "Yaemakuva Bhodinchae",
                "Type": "Varnam-Pada",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1315,
                "Name": "Sami ninne",
                "Type": "Varnam-Pada",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Group": "Navaratna Malika",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1316,
                "Name": "Mohamaana",
                "Type": "Varnam-Pada",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1317,
                "Name": "Sakhiyae",
                "Type": "Varnam-Pada",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1318,
                "Name": "Saamiyai",
                "Type": "Varnam-Pada",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1319,
                "Name": "Adimoham",
                "Type": "Varnam-Pada",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1320,
                "Name": "Mohalaahiri",
                "Type": "Varnam-Pada",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Tamil",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1321,
                "Name": "Sarasuda",
                "Type": "Varnam-Pada",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "Pandanallur Meenakshi Sundaram Pillai",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1322,
                "Name": "Inthakopa",
                "Type": "Varnam-Pada",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Pandanallur Meenakshi Sundaram Pillai",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1323,
                "Name": "Chalamela",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1324,
                "Name": "Sarasija",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1325,
                "Name": "Madana Bilahari",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Group": "Chatur raga sloka malikai",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1326,
                "Name": "Samininu",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Group": "Ragamalika with 12 ragas",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1327,
                "Name": "Pannagaa theercha",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Vadivelu (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1328,
                "Name": "Kaarviriyum",
                "Ragam": "Ragamalika",
                "Thalam": "Khanda chapu",
                "Composer": "K Ponniah Pillai",
                "Group": "Desiya ashta Ragamalika",
                "Song Language": "Tamil",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1329,
                "Name": "Kalyanikalu",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Group": "Chatur Raga Sloka malikai",
                "Song Language": "Sanskrit",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1330,
                "Name": "Saa nam tham",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Chatur Raga Sloka malikai",
                "Song Language": "Sanskrit",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1331,
                "Name": "Daa ni sari",
                "Type": "Swarapadam",
                "Ragam": "Thodi",
                "Thalam": "Khanda chapu",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1332,
                "Name": "Thani Bhodana",
                "Type": "Javali",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1333,
                "Name": "Yaemovalapaaya",
                "Type": "Javali",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1334,
                "Name": "Indhu Nila",
                "Type": "Javali",
                "Ragam": "Behag",
                "Thalam": "Mishra Chapu",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1335,
                "Name": "Elara naapai",
                "Type": "Javali",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1336,
                "Name": "Mutta vaththu",
                "Type": "Javali",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1337,
                "Name": "Mosajaesae",
                "Type": "Javali",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1338,
                "Name": "Thodi Thillana (Thaani Dhiru Dhiru)",
                "Type": "Thillana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1339,
                "Name": "Thodi Thillana (Tha Thaani Thillana)",
                "Type": "Thillana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1340,
                "Name": "Kaanadaa Thillana",
                "Type": "Thillana",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "Sivanandam (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1341,
                "Name": "Kapi Thillana",
                "Type": "Thillana",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1342,
                "Name": "Bilahari Thillana",
                "Type": "Thillana",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1343,
                "Name": "Shankarabharanam Thillana",
                "Type": "Thillana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1344,
                "Name": "Atana Thillana",
                "Type": "Thillana",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "K Ponniah Pillai",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1345,
                "Name": "Behag Thillana",
                "Type": "Thillana",
                "Ragam": "Behag",
                "Thalam": "Mishra Chapu",
                "Composer": "K Ponniah Pillai",
                "Song Language": "Telugu",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1346,
                "Name": "Hamsanandi Thillana",
                "Type": "Thillana",
                "Ragam": "Hamsanandi",
                "Thalam": "Roopakam",
                "Composer": "Ponniah pillai (Tanjore Quartet)",
                "Song Language": "Sanskrit",
                "BookTitle": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
                "BookAuthor": "Tanjore Quartet (Ponniah, Chinnaiah, Sivanandam, Vadivel)",
                "Year of Publication": 1992,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1347,
                "Name": "35 Alankarams (7 * 5 = 35)",
                "Composer": "NA",
                "Song Language": "NA",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1348,
                "Name": "Gananaatha - Sanchari Geetham",
                "Type": "Geetham",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1349,
                "Name": "Vaa Saravananae - Sanchari Geetham",
                "Type": "Geetham",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1350,
                "Name": "Umai Padhiyidam - Sanchari Geetham",
                "Type": "Geetham",
                "Ragam": "Bhairavi",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1351,
                "Name": "Vaamathi dhara - Sanchari Geetham",
                "Type": "Geetham",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1352,
                "Name": "Thirumagal - Sanchari Geetham",
                "Type": "Geetham",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Matya thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1353,
                "Name": "Enaiyaal - Sanchari Geetham",
                "Type": "Geetham",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1354,
                "Name": "Naanmalariniluraivaai - Sanchari Geetham",
                "Type": "Geetham",
                "Ragam": "Kalyani",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1355,
                "Name": "Kamalamalarinil - Sanchari Geetham",
                "Type": "Geetham",
                "Ragam": "Dhanyasi",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1356,
                "Name": "Sooryabhagavaanai",
                "Type": "Geetham",
                "Ragam": "Sowrashtram",
                "Thalam": "Chatusra Jathi Dhruva Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Vaara Geetham (Days of the week Geetham)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1357,
                "Name": "Valarilam madhiyavanae",
                "Type": "Geetham",
                "Ragam": "Asaveri",
                "Thalam": "Chatusra Jathi Matya Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Vaara Geetham (Days of the week Geetham)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1358,
                "Name": "Poovulagathipa puvitharumaganae",
                "Type": "Geetham",
                "Ragam": "Surutti",
                "Thalam": "Khanda jathi Rooopaka Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Vaara Geetham (Days of the week Geetham)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1359,
                "Name": "Madhitharu maganae",
                "Type": "Geetham",
                "Ragam": "Naatakurinji",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Vaara Geetham (Days of the week Geetham)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1360,
                "Name": "Guru Mahaan",
                "Type": "Geetham",
                "Ragam": "Atana",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Vaara Geetham (Days of the week Geetham)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1361,
                "Name": "Paarkavanae",
                "Type": "Geetham",
                "Ragam": "Paras",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Vaara Geetham (Days of the week Geetham)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1362,
                "Name": "Chanibhagavaan",
                "Type": "Geetham",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Khanda Jathi Matya thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Vaara Geetham (Days of the week Geetham)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1363,
                "Name": "Paraaparan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Naalvar Thudhi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1364,
                "Name": "Saalach chirandha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Ulagiyal (Kalvi)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1365,
                "Name": "Olukamadhuvae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Ulagiyal (Ozhukkam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1366,
                "Name": "Koodiyae Vaazhvadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Ulagiyal (Otrumai)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1367,
                "Name": "Vaanamudanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Chatusra Jathi Matya Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Ulagiyal (Iyarkai)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1368,
                "Name": "Paarinil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Ulagiyal (Iyarkai)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1369,
                "Name": "Ulagamadhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Khanda Jathi Matya thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Ulagiyal (Vivasayam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1370,
                "Name": "Mayamalawa",
                "Type": "Geetham",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Group": "Ragalakshna Geetham",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1371,
                "Name": "Aarosaiyinil",
                "Type": "Geetham",
                "Ragam": "Saveri",
                "Thalam": "Khanda Chapu",
                "Composer": "Tanjore Quartet",
                "Group": "Ragalakshna Geetham",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1372,
                "Name": "Kaelvikkiniya",
                "Type": "Geetham",
                "Ragam": "Sree",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Ragalakshna Geetham",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1373,
                "Name": "Mukhari ragam",
                "Type": "Geetham",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Group": "Ragalakshna Geetham",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1374,
                "Name": "Harikhambhodhi",
                "Type": "Geetham",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Khanda chapu",
                "Composer": "Tanjore Quartet",
                "Group": "Ragalakshna Geetham",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1375,
                "Name": "Paarinil yevarum",
                "Type": "Geetham",
                "Ragam": "Mohanam",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Ragalakshna Geetham",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1376,
                "Name": "Arabhi pannin",
                "Type": "Geetham",
                "Ragam": "Arabhi",
                "Thalam": "Khanda chapu",
                "Composer": "Tanjore Quartet",
                "Group": "Ragalakshna Geetham",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1377,
                "Name": "Bilahari raaga",
                "Type": "Geetham",
                "Ragam": "Bilahari",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Ragalakshna Geetham",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1378,
                "Name": "Chakravaham Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Chakravaham",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1379,
                "Name": "Kalyani Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Kalyani",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1380,
                "Name": "Thodi Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1381,
                "Name": "Shankarabharanam Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1382,
                "Name": "Poorvikalyani Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi (Sankeerna nadai)",
                "Composer": "Tanjore Quartet",
                "Song Language": "NA",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1383,
                "Name": "Thaamadhamaeno",
                "Type": "Swarajathi",
                "Ragam": "Bilahari",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1384,
                "Name": "Nee Arulayo",
                "Type": "Swarajathi",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1385,
                "Name": "MaadhevaKaruNa",
                "Type": "Swarajathi",
                "Ragam": "Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1386,
                "Name": "Kaana Aavalaanaen",
                "Type": "Swarajathi",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1387,
                "Name": "Kadhiravan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Pancha Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Pancha Thala Sulathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1388,
                "Name": "Daamodhara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Pancha Jathi",
                "Composer": "Tanjore Quartet",
                "Group": "Aindhu Jaathi Sulathi (Pancha Jathi Sulathi)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1389,
                "Name": "Thaayae Nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Trikaala TriThala Sulathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1390,
                "Name": "Tha dhiku thaam",
                "Type": "Prabandam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Group": "Kaivaara Prabandam",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1391,
                "Name": "Kobham",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Tisra Ekam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1392,
                "Name": "Sogamaanaen",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1393,
                "Name": "Naadhaa naavalar",
                "Type": "Varnam-Tana",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1394,
                "Name": "Paavaai",
                "Type": "Varnam-Tana",
                "Ragam": "Varali",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1395,
                "Name": "Thogaimaamayil",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1396,
                "Name": "Endha naeramum",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1397,
                "Name": "Varuvaaro",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1398,
                "Name": "Saamiyidam",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1399,
                "Name": "Mohamaana",
                "Type": "Varnam-Pada",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1400,
                "Name": "Saamiyai Azhaithu vaadi",
                "Type": "Varnam-Pada",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1401,
                "Name": "Thiruvarul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1402,
                "Name": "Sangath thamizh",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1403,
                "Name": "Perungoil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1404,
                "Name": "Thandhai thaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1405,
                "Name": "Paadiyaadak KaaNbom",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1406,
                "Name": "Enaa Seiguvaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Velavali",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1407,
                "Name": "Jagadeesha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Khanda chapu",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1408,
                "Name": "Yaen padhaithaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1409,
                "Name": "Piravaavaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1410,
                "Name": "Vaa Vaa Endrunnai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1411,
                "Name": "AarugaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Group": "AindhiNai padhangaL",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1412,
                "Name": "MaalaisooDiyavar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Group": "AindhiNai padhangaL",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1413,
                "Name": "KaaNamanoharamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahiri",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Group": "AindhiNai padhangaL",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1414,
                "Name": "Marandhuvidudalundho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Group": "AindhiNai padhangaL",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1415,
                "Name": "Kaalam Idhuvallavo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "AindhiNai padhangaL",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1416,
                "Name": "Thaerseluthuvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Group": "AindhiNai padhangaL",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1417,
                "Name": "Yedho Theriyavillayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Group": "AindhiNai padhangaL",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1418,
                "Name": "Paalai nilam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Group": "AindhiNai padhangaL",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1419,
                "Name": "NeeLkadalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Group": "AindhiNai padhangaL",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1420,
                "Name": "Pookamazhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Mishra Jhampai",
                "Composer": "Tanjore Quartet",
                "Group": "AindhiNai padhangaL",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1421,
                "Name": "Kaarviriyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Khanda chapu",
                "Composer": "Tanjore Quartet",
                "Group": "Desiya Ashta Ragamalika",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1422,
                "Name": "Ninaindhodi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Sapta Thalam",
                "Composer": "Tanjore Quartet",
                "Group": "Sapta Thala Ragamalika",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1423,
                "Name": "Bilahari Thillana",
                "Type": "Thillana",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1424,
                "Name": "Atana Thillana",
                "Type": "Thillana",
                "Ragam": "Atana",
                "Thalam": "Deshadi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1425,
                "Name": "Behag Thillana",
                "Type": "Thillana",
                "Ragam": "Behag",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1426,
                "Name": "Shankarabharanam",
                "Type": "Thillana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Tanjore Quartet",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
                "BookAuthor": "Tanjore Quartet",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1427,
                "Name": "Anjudhal Ozhithae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1428,
                "Name": "Anjaelendrae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1429,
                "Name": "Andhaya kaalam vandhidum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1430,
                "Name": "Ardhanaareershwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1431,
                "Name": "Anbu seiyum vaazhkaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1432,
                "Name": "Anbu madhandhanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "On Bharath matha",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1433,
                "Name": "Annai Sharadhamani devi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1434,
                "Name": "Aaridam murai Solluvaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1435,
                "Name": "Idhayam padaithadhai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1436,
                "Name": "Indha oru udhaviyaagilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1437,
                "Name": "Indha yaakai veeN ponaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1438,
                "Name": "Ivan perum paaviyendrae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1439,
                "Name": "Iraivaa nin karuNaiyai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1440,
                "Name": "Inbam Nalamo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1441,
                "Name": "Indrae Arul puriveer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalitha",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1442,
                "Name": "Innamum avar manam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1443,
                "Name": "Innum yeththanai kaalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1444,
                "Name": "Ulagariyaadhendru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1445,
                "Name": "Ulladhaikkondae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1446,
                "Name": "Unnaiyandri vaeroru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1447,
                "Name": "Unnaamam Un paechu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1448,
                "Name": "ENNidum podhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1449,
                "Name": "Yethanai dharam sonnalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1450,
                "Name": "Ethanai piravigal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1451,
                "Name": "Yenakkoru vazhiyumae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1452,
                "Name": "En manam polae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1453,
                "Name": "Yaezhai ivan allavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1454,
                "Name": "Aingaranillamal yaarun thunai illai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1455,
                "Name": "Orudharam Dharisithaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1456,
                "Name": "Karunaiyin avadharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1457,
                "Name": "Kazhanigal Soozh",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1458,
                "Name": "Kaalanai veezhthiya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1459,
                "Name": "KaaLi karuneeli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Mishra Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1460,
                "Name": "Kundruva vel vaangi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1461,
                "Name": "Sakala kala naayaki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1462,
                "Name": "Saththi vadivel paraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1463,
                "Name": "Sirithu purameriththa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1464,
                "Name": "Senthil Vadivelanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1465,
                "Name": "Senthil Valar Guhanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1466,
                "Name": "Chaepidu Vidhdhai sagamum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1467,
                "Name": "Sollivaa sakhiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1468,
                "Name": "Sodhanai seidhadhu podhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1469,
                "Name": "Thillayil aadum thiru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1470,
                "Name": "ThoNi midhakkavittu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1471,
                "Name": "Nammakoru kurai yaedhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1472,
                "Name": "Nandru thuyilkoLveer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1473,
                "Name": "NaatkaLai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1474,
                "Name": "Thedi Thedi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1475,
                "Name": "Bhavakkadal thaaNdida",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1476,
                "Name": "Pazhani nindra paraman",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1477,
                "Name": "Pazhanip padhi sendraal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1478,
                "Name": "Pazhani malaithanil vaazhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1479,
                "Name": "Paarellam Arulaalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1480,
                "Name": "Pithaa Unmel",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1481,
                "Name": "Pizhai ninaithu nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1482,
                "Name": "Bhuddhan thuravukkum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1483,
                "Name": "PonkaravaNindha Naadhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1484,
                "Name": "Pongu thaamarai pootha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1485,
                "Name": "Ponaal pogiraar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1486,
                "Name": "Manadhu kalangaadhae nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1487,
                "Name": "Manamirangaadhadhu yaeno",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1488,
                "Name": "Muruganadi saerndhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1489,
                "Name": "Muruganadi manamuruga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1490,
                "Name": "Muruganendru varuvaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1491,
                "Name": "Muruganai yenai aanda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1492,
                "Name": "Muruganai koovi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Udayaravichandrika",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1493,
                "Name": "Murugaa thanjam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1494,
                "Name": "Vaazhvinil Thunbam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1495,
                "Name": "VeeNN pazhi sumatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1496,
                "Name": "VeeN vaadham Vaendhaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1497,
                "Name": "Veeravael velan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1498,
                "Name": "Vaenugaana amudhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1499,
                "Name": "Vaelavaa surakula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1500,
                "Name": "Vel muruganai ninaivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1501,
                "Name": "Sree Ramakrishna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1502,
                "Name": "Kazhanigal Soozh",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1503,
                "Name": "Aingaranillamal yaarun thunai illai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1504,
                "Name": "Pazhani malaithanil vaazhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1505,
                "Name": "Sakala kala naayaki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1506,
                "Name": "Pongu thaamarai pootha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1507,
                "Name": "Pithaa Unmel",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1508,
                "Name": "Yaezhai ivan allavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1509,
                "Name": "Anjaelendrae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1510,
                "Name": "Veeravael velan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1511,
                "Name": "Senthil Valar Guhanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1512,
                "Name": "ENNidum podhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1513,
                "Name": "Vaazhvinil Thunbam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1514,
                "Name": "Kundruva vel vaangi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1515,
                "Name": "Thillayil aadum thiru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1516,
                "Name": "Indha yaakai veeN ponaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1517,
                "Name": "Manadhu kalangaadhae nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1518,
                "Name": "Vaelavaa surakula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1519,
                "Name": "Unnaamam Un paechu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1520,
                "Name": "Senthil Vadivelanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1521,
                "Name": "Ethanai piravigal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1522,
                "Name": "Paarellam Arulaalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1523,
                "Name": "Muruganadi manamuruga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1524,
                "Name": "Yenakkoru vazhiyumae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1525,
                "Name": "Sodhanai seidhadhu podhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1526,
                "Name": "Manamirangaadhadhu yaeno",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1527,
                "Name": "Iraivaa nin karuNaiyai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1528,
                "Name": "Inbam Nalamo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1529,
                "Name": "Ulladhaikkondae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1530,
                "Name": "NaatkaLai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1531,
                "Name": "Karunaiyin avadharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1532,
                "Name": "Muruganadi saerndhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1533,
                "Name": "Pazhani nindra paraman",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1534,
                "Name": "Nammakoru kurai yaedhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1535,
                "Name": "Ardhanaareershwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1536,
                "Name": "Bhavakkadal thaaNdida",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1537,
                "Name": "Indrae Arul puriveer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalitha",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1538,
                "Name": "Kaalanai veezhthiya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1539,
                "Name": "Andhaya kaalam vandhidum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1540,
                "Name": "Sirithu purameriththa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1541,
                "Name": "Sree Ramakrishna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1542,
                "Name": "Ivan perum paaviyendrae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1543,
                "Name": "Chaepidu Vidhdhai sagamum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1544,
                "Name": "Pazhanip padhi sendraal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1545,
                "Name": "Yethanai dharam sonnalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1546,
                "Name": "Nandru thuyilkoLveer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1547,
                "Name": "Ulagariyaadhendru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1548,
                "Name": "En manam polae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1549,
                "Name": "Muruganai yenai aanda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1550,
                "Name": "KaaLi karuneeli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Mishra Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1551,
                "Name": "ThoNi midhakkavittu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1552,
                "Name": "Murugaa thanjam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1553,
                "Name": "Pizhai ninaithu nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1554,
                "Name": "Orudharam Dharisithaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1555,
                "Name": "Vel muruganai ninaivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1556,
                "Name": "VeeN vaadham Vaendhaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1557,
                "Name": "PonkaravaNindha Naadhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1558,
                "Name": "Unnaiyandri vaeroru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1559,
                "Name": "Anbu madhandhanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "On Bharath matha",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1560,
                "Name": "Saththi vadivel paraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1561,
                "Name": "Anbu seiyum vaazhkaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1562,
                "Name": "Anjudhal Ozhithae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1563,
                "Name": "Bhuddhan thuravukkum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1564,
                "Name": "Annai Sharadhamani devi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1565,
                "Name": "Aaridam murai Solluvaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1566,
                "Name": "Idhayam padaithadhai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1567,
                "Name": "Innum yeththanai kaalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1568,
                "Name": "Innamum avar manam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1569,
                "Name": "Sollivaa sakhiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1570,
                "Name": "Thedi Thedi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1571,
                "Name": "Muruganai koovi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Udayaravichandrika",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1572,
                "Name": "Vaenugaana amudhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1573,
                "Name": "Muruganendru varuvaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1574,
                "Name": "Indha oru udhaviyaagilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1575,
                "Name": "Ponaal pogiraar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1576,
                "Name": "VeeNN pazhi sumatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1943,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1577,
                "Name": "Bhakthi minsaaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1578,
                "Name": "Chidambaramaena ninaivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bangala",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1579,
                "Name": "Gnanappaal arulvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saaraamathi",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1580,
                "Name": "Mona nisiyinilae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1581,
                "Name": "Aruviyaippolum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Chapu",
                "Composer": "Shuddhananda Bharathi/Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1582,
                "Name": "Mohana valai veesinaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1583,
                "Name": "Ellam Sivan Seyal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Chapu",
                "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1584,
                "Name": "Kottam adangi chumma iruppayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1585,
                "Name": "Saadhanam Seiyya vaareerae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Triputa",
                "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1586,
                "Name": "Anbirundhalae podhum aandavanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Chapu",
                "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1587,
                "Name": "Paramesanai paarvathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Udayaravichandrika",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1588,
                "Name": "Unakku samaanamaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Chapu",
                "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1589,
                "Name": "Anjalaamo Nenjae endrum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1590,
                "Name": "Abhayakaraththai paartha podhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1591,
                "Name": "Anandamae paramaanandhamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jujahuli",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1592,
                "Name": "KaNNaalae unnai naan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Roopakam",
                "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1593,
                "Name": "Kavalaip padhaadhae manamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1594,
                "Name": "Pudhuyugam varavaendum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1595,
                "Name": "Varuvaano vanakkuyilae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Deshadi",
                "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1596,
                "Name": "Ananda natana maadinaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gopikathilakam",
                "Thalam": "Roopakam",
                "Composer": "Shuddhananda Bharathi/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1597,
                "Name": "Illai enbaan yaaradaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Chapu",
                "Composer": "Shuddhananda Bharathi/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1598,
                "Name": "Sharadaa deviyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1599,
                "Name": "Thaayae sivakaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Chapu",
                "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1600,
                "Name": "Podhum Podhum Podhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Roopakam",
                "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1601,
                "Name": "Nambithaan irukindraen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malavi",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1602,
                "Name": "Aaseervaadham tharuvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1603,
                "Name": "Yethanai inbangal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Jhampai",
                "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1604,
                "Name": "Shakthiyai vanangiduvom",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1605,
                "Name": "Alaiyaamal paarumaiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Chapu",
                "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1606,
                "Name": "Veera urudhi varuvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1607,
                "Name": "Govindaraaja perumaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1608,
                "Name": "Seevapadagai ootum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1609,
                "Name": "Koyilulagellam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1610,
                "Name": "KaNNarakk kaNdu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1611,
                "Name": "Annaamalai appanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1612,
                "Name": "Vande Maatram Vande Maatram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1613,
                "Name": "Endhaai vaazhgavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1614,
                "Name": "Thaayae Charanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1615,
                "Name": "Paripoorna Sudhandhara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Jhampai",
                "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1616,
                "Name": "Eppodhu varuvaayo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1617,
                "Name": "Vazhikaati nadhathinaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlipanthu",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1618,
                "Name": "Nataraja Charanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1619,
                "Name": "Shanthamaaga unn sannidhiyil irundhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1620,
                "Name": "Yezhundhiru Kalyani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Chapu",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1621,
                "Name": "Appa unn ponnadi thurappaeno",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1622,
                "Name": "Devaseevanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1623,
                "Name": "Idhayakkadhavai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1624,
                "Name": "Unayae kaadhalithaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1625,
                "Name": "Bhaaramellam Vaithaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Chapu",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1626,
                "Name": "Poorana gnaani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhaari",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1627,
                "Name": "Eppodhu Viduthalaiyo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Chapu",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1628,
                "Name": "Paramanai sindhai seivom",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1629,
                "Name": "Iravum pagalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1630,
                "Name": "Maanida Janmam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1631,
                "Name": "Thillai natarajanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1632,
                "Name": "PaNNuDan paNiyuDan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1633,
                "Name": "Mangalamaai vaazhga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1634,
                "Name": "Ammaa Unnaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Jhampai",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1635,
                "Name": "Anbu varam tharuvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Jhampai",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1636,
                "Name": "Gurunaathan avanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1637,
                "Name": "Kaividaadhae Duraiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1638,
                "Name": "Pazhaniappan thunaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Chapu",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1639,
                "Name": "Yaaro Vandhennai aasai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Bangala",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1640,
                "Name": "OriDam Thaaraayo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1641,
                "Name": "Unnai TheLindhiduvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaroj",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1642,
                "Name": "Ennai kaividuvaayo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Sivavadivelu Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1643,
                "Name": "Kaathirukkindraen En ayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Sivavadivelu Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1644,
                "Name": "Kanavilaagilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Triputa",
                "Composer": "Shuddhananda Bharathi/KS Sivavadivelu Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1645,
                "Name": "Dheena Dhaya paranae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Sivavadivelu Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1646,
                "Name": "En Mugam paar Aiyanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Sivavadivelu Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1647,
                "Name": "Aththaa AruLilayo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Triputa",
                "Composer": "Shuddhananda Bharathi/KS Sivavadivelu Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1648,
                "Name": "Eppadi Paadinaroo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Karnataka Devagandhari",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/KS Sivavadivelu Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
                "BookAuthor": "Shuddhaananda Bhaarathi",
                "Year of Publication": 1959,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1649,
                "Name": "Sivanai ninaindhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1650,
                "Name": "Unmel Bhaaram Vaithu unnaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1651,
                "Name": "Oraaru muganae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1652,
                "Name": "Sivanai ninai manamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1653,
                "Name": "PizhaigaL ellam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nayaki",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1654,
                "Name": "Chidambaramae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balahamsa",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1655,
                "Name": "Ichchanmamidhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1656,
                "Name": "Sivan varuvaar thunai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Neelakanta Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1657,
                "Name": "Kandean kali theerndhaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1658,
                "Name": "Manamae unakkoru marundhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Chapu",
                "Composer": "Neelakanta Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1659,
                "Name": "Sugamaa iru manamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1660,
                "Name": "Seikadan Seigavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1661,
                "Name": "Appanum ammayum nee allavo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Chapu",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1662,
                "Name": "Ondrae Uyar samayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1663,
                "Name": "Thondu seidhalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Adi",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1664,
                "Name": "Neeyandri ennuLam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Chapu",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1665,
                "Name": "Kavalai yedharku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1666,
                "Name": "Anbu seidhalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1667,
                "Name": "PoruLae perugida",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1668,
                "Name": "Unaiyandri matrondu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1669,
                "Name": "Idhuvo KaruNai en meedhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1670,
                "Name": "Yaaraedhu seiyinum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1671,
                "Name": "Shakthi yaenakkun",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1672,
                "Name": "Bhakthi seivadhaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1673,
                "Name": "Vaazhvu enbhadhu enna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Chapu",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1674,
                "Name": "Idhu endru vazhi kaatugavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1675,
                "Name": "Arul surandhu arul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Adi",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1676,
                "Name": "Kaividalaamo Kaathu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1677,
                "Name": "Maiyal Kondaen",
                "Type": "Padam",
                "Ragam": "Kaanadaa",
                "Thalam": "Roopakam",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1678,
                "Name": "PaLLi yezhundharul vaai",
                "Type": "Padam",
                "Ragam": "Bhupalam",
                "Thalam": "Chapu",
                "Composer": "Trivandram Lakshmanapillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1679,
                "Name": "Sharanaa gathi endru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1680,
                "Name": "Thiruvaadhirai ",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1681,
                "Name": "Aadiya paadhathai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1682,
                "Name": "Yaedhukku indha sadalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1683,
                "Name": "Sivakaama Sundari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1684,
                "Name": "Maravaamal eppadiyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1685,
                "Name": "Innamum sandheha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1686,
                "Name": "Yaarukku thaan theriyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1687,
                "Name": "Yaarukku ponnambalan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1688,
                "Name": "Sabhapathiku veru deivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1689,
                "Name": "VeeNaai kaalam kazhikaadhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Achuta Dasar/SS Krishnaiyengar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1690,
                "Name": "Paar Paar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Achuta Dasar/SS Krishnaiyengar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1691,
                "Name": "Vandu en Aanduarulae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Achuta Dasar/SS Krishnaiyengar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1692,
                "Name": "Shuddha Nirvikara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Chapu",
                "Composer": "Achuta Dasar/SS Krishnaiyengar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1693,
                "Name": "Paramaatma jyothiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Jhampai",
                "Composer": "Achuta Dasar/SS Krishnaiyengar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1694,
                "Name": "KaaN bhaenaa Kanmaniyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Roopakam",
                "Composer": "Achuta Dasar/SS Krishnaiyengar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1695,
                "Name": "Anubhavikaa agalaadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Chapu",
                "Composer": "Achuta Dasar/Mayuram TR Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1696,
                "Name": "Aadhaaram vaeraedhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Achuta Dasar/Mayuram TR Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1697,
                "Name": "Arunilai perambala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Achuta Dasar/Mayuram TR Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1698,
                "Name": "Undran ullam ariyaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Chapu",
                "Composer": "Achuta Dasar/Mayuram TR Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1699,
                "Name": "Chitham uvandhuarul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1700,
                "Name": "Tharunam Tharunam Dayai seivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Roopakam",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1701,
                "Name": "Iyanae Uyar Meiyanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1702,
                "Name": "Chitham Irangi nal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Chapu",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1703,
                "Name": "Thanjam neeyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1704,
                "Name": "Tharunam Karunai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1705,
                "Name": "Nithyaanandhathai naadu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1706,
                "Name": "KaruNaa kara murthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Roopakam",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1707,
                "Name": "Yedho Sodhanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1708,
                "Name": "Manamae Nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1709,
                "Name": "Yenai Aadharippaayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1710,
                "Name": "Innam thamadham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalitha",
                "Thalam": "Chapu",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1711,
                "Name": "Gnana maargam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balahamsa",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1712,
                "Name": "Udhavi seiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1713,
                "Name": "Naanae unnai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1714,
                "Name": "Nidham Nidham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1715,
                "Name": "Parabrahmamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Roopakam",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1716,
                "Name": "Karunaa Sagara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1717,
                "Name": "paNamae unnaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1718,
                "Name": "Devaadhi Devaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1719,
                "Name": "Andha raama Sowndharyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavirayar",
                "Group": "Ramanataka Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1720,
                "Name": "AarendraNNaamalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavirayar",
                "Group": "Ramanataka Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1721,
                "Name": "MahathuvamuLLa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Chapu",
                "Composer": "Arunachala Kavirayar",
                "Group": "Ramanataka Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1722,
                "Name": "PiZhai porukka vaeNum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavirayar",
                "Group": "Ramanataka Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1723,
                "Name": "Paaraai peNNae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Chapu",
                "Composer": "Arunachala Kavirayar",
                "Group": "Ramanataka Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
                "BookAuthor": "Neelakanta Sivan",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1724,
                "Name": "Siddhi Vinayakanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "KaviKunjaraBharathi/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1725,
                "Name": "Nichayam Idhudhaanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NavaManohari",
                "Thalam": "Roopakam",
                "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1726,
                "Name": "Pathini polavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Samkrandhanapriya",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1727,
                "Name": "Aiyaa yenai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalitha",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1728,
                "Name": "Innam paaraamugamo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1729,
                "Name": "Iniyaagilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sivanandi",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1730,
                "Name": "Eppadiyum paramae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1731,
                "Name": "Oho kaalamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1732,
                "Name": "Karunaakara paramporulae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1733,
                "Name": "Yaedhu paramugam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1734,
                "Name": "Jaalam seivadhedho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Roopakam",
                "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1735,
                "Name": "Eppo kaanbhaeno",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rathinambari",
                "Thalam": "Adi",
                "Composer": "Achutadasar/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1736,
                "Name": "Maraimudi porulaana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Roopakam",
                "Composer": "Achutadasar/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1737,
                "Name": "Thiru uLach seiyal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Achutadasar/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1738,
                "Name": "Thoodhu nee solli vaaraai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Thirikoodaraasappakaviraayar/MM Dandapani Desikar",
                "Group": "Kutrala Kuravanji",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1739,
                "Name": "Sugavazhithanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1740,
                "Name": "Putham puthu vasantham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Roopakam",
                "Composer": "Shuddhananda Bharathi/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1741,
                "Name": "Vaazhthavaendumae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Roopakam",
                "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1742,
                "Name": "Arasae perumai annamalaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Adi",
                "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1743,
                "Name": "Vaazhiya Senthamizh naadu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Roopakam",
                "Praise of": "On Tamilnadu",
                "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1744,
                "Name": "Veeraththaayar Vaazhndanadu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Praise of": "On Tamilnadu",
                "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1745,
                "Name": "Thamizhae Thamizhargal selvam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhaathri",
                "Thalam": "Adi",
                "Praise of": "On Tamil Language",
                "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1746,
                "Name": "Maathae nee thoodhu selladi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1747,
                "Name": "Pazhanthamizh veeran",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1748,
                "Name": "Ennakoru varam tharuvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1749,
                "Name": "Parangiri perumaanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Visharada",
                "Thalam": "Adi",
                "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1750,
                "Name": "Ini nalamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rathipathipriya",
                "Thalam": "Adi",
                "Composer": "Ouvai Duraisamy Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1751,
                "Name": "Vennilaavum vaanum polae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayantasena",
                "Thalam": "Roopakam",
                "Composer": "Bharathidasan/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1752,
                "Name": "Oviyam Varaindhan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Alankarapriya",
                "Thalam": "Roopakam",
                "Composer": "Bharathidasan/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1753,
                "Name": "Aduvome Pallu paduvome Thamizh aatchiyil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarangatharangini",
                "Thalam": "Adi",
                "Composer": "Bharathidasan/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1754,
                "Name": "Naaraayananai thudhippai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Komalangi",
                "Thalam": "Adi",
                "Composer": "Raghava Ramanuja Dasar/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1755,
                "Name": "PaamaNam kamazhavaendum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalithamanohari",
                "Thalam": "Adi",
                "Composer": "Nerkuppai Rama Subramanya Chettiar/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1756,
                "Name": "KadavuLai maravathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thandavam",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1757,
                "Name": "Naavukkarasanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1758,
                "Name": "Aakamumae tharum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1759,
                "Name": "En thaayae En annai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Revagupti",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1760,
                "Name": "Thamizh mozhiyai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Praise of": "On Tamil Language",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1761,
                "Name": "Aindhezhuthinai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragavinodini",
                "Thalam": "Adi",
                "Composer": "MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1762,
                "Name": "Thaayai nee maravaathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Chapu",
                "Praise of": "On Mothers",
                "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1763,
                "Name": "Yaadhum engal oorae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Khanda chapu",
                "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1764,
                "Name": "Arul purivaai paramporulae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijayanaagari",
                "Thalam": "Adi",
                "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1765,
                "Name": "Engae thedukindraai iraivanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1766,
                "Name": "Ethanai paerthunburuvor",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelamani",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1767,
                "Name": "Perumai KoLVaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Praise of": "On Tamilnadu",
                "Composer": "Pudukkotai Krishnamurthy/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1768,
                "Name": "ALLi undidalaam vaareer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "On Tamil",
                "Composer": "Pudukkotai Krishnamurthy/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1769,
                "Name": "VaravaeNum vadivelanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagavalli",
                "Thalam": "Khanda chapu",
                "Composer": "Pudukkotai Krishnamurthy/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1770,
                "Name": "Unnai Vaendinaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavapriya",
                "Thalam": "Adi",
                "Composer": "Pudukkotai Krishnamurthy/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1771,
                "Name": "Inbakkanaa ondru kandaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Pudukkotai Krishnamurthy/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1772,
                "Name": "Aadum kalabha mayil paagan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Udumalaipettai Narayanakavi/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1773,
                "Name": "Ellorum inbura kaNN kaaNa mangalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Udumalaipettai Narayanakavi/MM Dandapani Desikar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
                "BookAuthor": "Various",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1774,
                "Name": "Sri Ganapathy",
                "Type": "Geetham",
                "Ragam": "GambiraNattai",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1775,
                "Name": "Aadiya paadhanai",
                "Type": "Geetham",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda jathi Jhampa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1776,
                "Name": "Vaanavar potridum",
                "Type": "Geetham",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1777,
                "Name": "Malai thanil ulava",
                "Type": "Geetham",
                "Ragam": "Saveri",
                "Thalam": "Tisra Jathi Roopakam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1778,
                "Name": "Vedha naavil",
                "Type": "Geetham",
                "Ragam": "Kalyani",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1779,
                "Name": "ThiruvuLa seyal yedhuvo",
                "Type": "Geetham",
                "Ragam": "Hindolam",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1780,
                "Name": "KalaivaaNi thaayae",
                "Type": "Geetham",
                "Ragam": "Saraswathi",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1781,
                "Name": "TharuNa midhae",
                "Type": "Geetham",
                "Ragam": "Mohanam",
                "Thalam": "Chatusra Jathi Dhuruva Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1782,
                "Name": "Ganapathi endrae Karudhida",
                "Type": "Geetham",
                "Ragam": "Kalyani",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1783,
                "Name": "Arul tharuvar Engal",
                "Type": "Geetham",
                "Ragam": "Malayamarutham",
                "Thalam": "Chatusra Jathi Jhampa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1784,
                "Name": "Anaithu uyirkum",
                "Type": "Geetham",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Jathi Triputa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1785,
                "Name": "Kandadhum kaetadhum",
                "Type": "Geetham",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Mishra Jathi Triputa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1786,
                "Name": "Ayan yenai padaithan",
                "Type": "Geetham",
                "Ragam": "Poorvikalyani",
                "Thalam": "Chatusra Jathi Dhuruva Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1787,
                "Name": "Poraamaiyai Kondeer",
                "Type": "Geetham",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1788,
                "Name": "Adimai Vaazhvai agatruvaayae",
                "Type": "Geetham",
                "Ragam": "Varali",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1789,
                "Name": "Ara neri vazhuvaa",
                "Type": "Geetham",
                "Ragam": "Vachaspathi",
                "Thalam": "Tisra Jathi Dhuruva Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1790,
                "Name": "Mariyaadhai theriyaadha",
                "Type": "Geetham",
                "Ragam": "Huseni",
                "Thalam": "Sankeerna Jathi Triputa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1791,
                "Name": "VaLamigum nannaadu",
                "Type": "Geetham",
                "Ragam": "KedaraGowlai",
                "Thalam": "Sankeerna Jathi Triputa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1792,
                "Name": "Padhiyae deivam endru odhiya",
                "Type": "Geetham",
                "Ragam": "Saveri",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1793,
                "Name": "Anavaradhamum",
                "Type": "Geetham",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1794,
                "Name": "Paraman unakku",
                "Type": "Geetham",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Jathi Triputa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1795,
                "Name": "Ananda Thaandava moorthy",
                "Type": "Geetham",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1796,
                "Name": "Aativaikum Bhommaiyinaal",
                "Type": "Geetham",
                "Ragam": "Gowlai",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1797,
                "Name": "Arivudaiyor",
                "Type": "Geetham",
                "Ragam": "GambiraNattai",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1798,
                "Name": "Yedhuvum sadhamalla",
                "Type": "Geetham",
                "Ragam": "Abhogi",
                "Thalam": "Tisra Jathi Dhuruva Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1799,
                "Name": "Bhakthi vahithavar",
                "Type": "Geetham",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Chatusra Jathi Matya Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1800,
                "Name": "Paramae piravaa varamae tharumae",
                "Type": "Geetham",
                "Ragam": "Devamanohari",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1801,
                "Name": "Aram seya virumbu",
                "Type": "Geetham",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1802,
                "Name": "Om Ganapathi",
                "Type": "Varnam-Tana",
                "Ragam": "Jaganmohini",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1803,
                "Name": "Vaa neelaambaa",
                "Type": "Varnam-Tana",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1804,
                "Name": "Athi roopamaana ivar yaar sakhiyae",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1805,
                "Name": "Arivaenadi",
                "Type": "Varnam-Tana",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1806,
                "Name": "Arunachalam thanail urai",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1807,
                "Name": "Venthamarai selvi",
                "Type": "Varnam-Tana",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1808,
                "Name": "Ivar yaaradi sakhiyae",
                "Type": "Varnam-Tana",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1809,
                "Name": "Chaeti nee poi",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1810,
                "Name": "Kalaivaani kamalachani",
                "Type": "Varnam-Tana",
                "Ragam": "Abhogi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1811,
                "Name": "Pazhamudhir solai",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1812,
                "Name": "Maaranai vellum",
                "Type": "Varnam-Tana",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1813,
                "Name": "Tharuvaar dhayavirundhaal",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1814,
                "Name": "Thanga avarku ingu",
                "Type": "Varnam-Tana",
                "Ragam": "Kokilapriya",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1815,
                "Name": "Kaarum Kavalai",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1816,
                "Name": "Yaadhuseivaen",
                "Type": "Varnam-Tana",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1817,
                "Name": "ParimaLa solai thanilae",
                "Type": "Varnam-Tana",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1818,
                "Name": "TharuNam Idhuvae",
                "Type": "Varnam-Tana",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1819,
                "Name": "Kanindhodi vandhaen",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1820,
                "Name": "Ananda Thandava",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1821,
                "Name": "Vadivela Vandharul",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Tisra Jathi Ata thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1822,
                "Name": "Om Ganapathy unai dinam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1823,
                "Name": "Varamarulavaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1824,
                "Name": "Emmadhathorukum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1825,
                "Name": "Amba Jagadamba",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Tisra Jathi Eka Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1826,
                "Name": "Yaagamum Yogamum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1827,
                "Name": "KaruNaakaran",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1828,
                "Name": "Parashakthi padam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1829,
                "Name": "Naadum Adiyaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1830,
                "Name": "Manamae nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1831,
                "Name": "Geetham Sangeetham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1832,
                "Name": "Arindhavar yaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1833,
                "Name": "Vanjaga seikai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1834,
                "Name": "Shivaloka naadhanai dinamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1835,
                "Name": "KaNNa nin manam kaniyadho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1836,
                "Name": "Jaathi madhangalaedhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1837,
                "Name": "Pathithan naan yaen paarinil pirandhaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1838,
                "Name": "Saravana Bhava nama om",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1839,
                "Name": "Vaadhum soodhum vedhanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1840,
                "Name": "Ulagir pirandhu uzhandraen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1841,
                "Name": "Andhi pozhudinil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1842,
                "Name": "Thaayinum dhayai udaiyaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1843,
                "Name": "Paandiya naayakan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1844,
                "Name": "Paridhana pazhakamundo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1845,
                "Name": "Maha Deso mandala thalaivaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1846,
                "Name": "Thanaiyan yenadhu vinai agala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1847,
                "Name": "Andavan ananda thandavan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1848,
                "Name": "Maanidap piraviyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1849,
                "Name": "Sagameedhinilae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1850,
                "Name": "Malaimaevum kumaresanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1851,
                "Name": "Thaamadhamaeno",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1852,
                "Name": "Kaapadhu unn kadan andro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Mishra Chapu",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1853,
                "Name": "Ninadhadimaiyaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1854,
                "Name": "Unnai andri deivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Tisra Jathi Eka Thalam",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1855,
                "Name": "Theruvinil Odi Siruvarudan aadi",
                "Type": "Padam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1856,
                "Name": "Subhadina manadhaalae",
                "Type": "Padam",
                "Ragam": "NataBhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1857,
                "Name": "Paandiya naayakanthaandi thozhi",
                "Type": "Padam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1858,
                "Name": "Naadhanai azhaithu vaadi",
                "Type": "Padam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Mishra Chapu",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1859,
                "Name": "Andravar thandha inbathai",
                "Type": "Padam",
                "Ragam": "Kokilapriya",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
                "BookAuthor": "CS Nataraja Pillai",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1860,
                "Name": "Agaramumaahi",
                "Type": "Thirupugazh",
                "Ragam": "HariKhambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1861,
                "Name": "Adala saedanaaraada",
                "Type": "Thirupugazh",
                "Ragam": "Manirangu",
                "Thalam": "Tisra Jathi Dhuruva Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1862,
                "Name": "Adirumkazhal",
                "Type": "Thirupugazh",
                "Ragam": "Revagupti",
                "Thalam": "Tisra Jathi Matya Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1863,
                "Name": "Abhahaara nindhai",
                "Type": "Thirupugazh",
                "Ragam": "Chakravaham",
                "Thalam": "Chatusra Jathi Jhampa Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1864,
                "Name": "Aruna manimaevu",
                "Type": "Thirupugazh",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Somadhi Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1865,
                "Name": "Arundhi vaazhvodu",
                "Type": "Thirupugazh",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1866,
                "Name": "Allivizhiyaalum",
                "Type": "Thirupugazh",
                "Ragam": "Thodi",
                "Thalam": "Drithiya thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1867,
                "Name": "Adimagamaayi",
                "Type": "Thirupugazh",
                "Ragam": "Shankarabharanam",
                "Thalam": "Tisra Jathi Dhuruva Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1868,
                "Name": "Aaramanivaarai",
                "Type": "Thirupugazh",
                "Ragam": "Atana",
                "Thalam": "Chachathputam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1869,
                "Name": "Arumugam",
                "Type": "Thirupugazh",
                "Ragam": "Revagupti",
                "Thalam": "Khanda chapu",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1870,
                "Name": "Imraajani",
                "Type": "Thirupugazh",
                "Ragam": "Malayamarutham",
                "Thalam": "Mishra Jhampai",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1871,
                "Name": "Iyalisai",
                "Type": "Thirupugazh",
                "Ragam": "Huseni",
                "Thalam": "Vishama thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1872,
                "Name": "Iruppavar",
                "Type": "Thirupugazh",
                "Ragam": "Vasantha",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1873,
                "Name": "Iruvar mayalo",
                "Type": "Thirupugazh",
                "Ragam": "Deepakam",
                "Thalam": "Mishra Chapu",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1874,
                "Name": "Ulaga pasupaasa",
                "Type": "Thirupugazh",
                "Ragam": "Begada",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1875,
                "Name": "Iyangaranai",
                "Type": "Thirupugazh",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1876,
                "Name": "Aindhu bhuthamum",
                "Type": "Thirupugazh",
                "Ragam": "Pantuvarali",
                "Thalam": "Mishra Chapu",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1877,
                "Name": "Orupadhu mirupadham",
                "Type": "Thirupugazh",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1878,
                "Name": "Oru pozhudhu MirucharaNa",
                "Type": "Thirupugazh",
                "Ragam": "Chakravaham",
                "Thalam": "Sankeerna Jathi Jhampa Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1879,
                "Name": "Kaadhi modhi vaadhaadu",
                "Type": "Thirupugazh",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Jathi Eka Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1880,
                "Name": "Kaavippoovai",
                "Type": "Thirupugazh",
                "Ragam": "Hindolam",
                "Thalam": "Khanda jathi Rooopaka Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1881,
                "Name": "Kudathai thagarthu",
                "Type": "Thirupugazh",
                "Ragam": "Pushpalathika",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1882,
                "Name": "Kumara gurupara",
                "Type": "Thirupugazh",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1883,
                "Name": "Kumara gurupara Muruga",
                "Type": "Thirupugazh",
                "Ragam": "Kharaharapriya",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1884,
                "Name": "Koorvel",
                "Type": "Thirupugazh",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1885,
                "Name": "Kombanaiyaar",
                "Type": "Thirupugazh",
                "Ragam": "Kharaharapriya",
                "Thalam": "Sankeerna Jathi Triputa Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1886,
                "Name": "Saandhudanae",
                "Type": "Thirupugazh",
                "Ragam": "YamunaKalyani",
                "Thalam": "Mishra Jathi Roopaka Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1887,
                "Name": "Siva Gnana",
                "Type": "Thirupugazh",
                "Ragam": "Sama",
                "Thalam": "Khanda chapu",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1888,
                "Name": "Sivanaar manamkuLira",
                "Type": "Thirupugazh",
                "Ragam": "Hamsanadam",
                "Thalam": "Khanda chapu",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1889,
                "Name": "Seer sirakku maeni",
                "Type": "Thirupugazh",
                "Ragam": "Nalinakanthi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1890,
                "Name": "Tharayin maanuda",
                "Type": "Thirupugazh",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1891,
                "Name": "Thirunila maruvikkaalin",
                "Type": "Thirupugazh",
                "Ragam": "Hamsanandi",
                "Thalam": "Chala Matyam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1892,
                "Name": "ThuiLLumadha",
                "Type": "Thirupugazh",
                "Ragam": "Hamsanandi",
                "Thalam": "Khanda jathi Jhampa Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1893,
                "Name": "Theruvinil nadavaa",
                "Type": "Thirupugazh",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Chatusra Jathi Dhuruva Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1894,
                "Name": "Naasarthang",
                "Type": "Thirupugazh",
                "Ragam": "Bilahari",
                "Thalam": "Sankeerna Jathi Dhuruva Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1895,
                "Name": "Naalumaindhuvaasal",
                "Type": "Thirupugazh",
                "Ragam": "Thodi",
                "Thalam": "Tisram",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1896,
                "Name": "Niraamaya",
                "Type": "Thirupugazh",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1897,
                "Name": "Nilavinilae",
                "Type": "Thirupugazh",
                "Ragam": "Kaanadaa",
                "Thalam": "Tisra Jathi Roopakam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1898,
                "Name": "Niraimadhi",
                "Type": "Thirupugazh",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1899,
                "Name": "Ninaithadhethanai",
                "Type": "Thirupugazh",
                "Ragam": "Abhogi",
                "Thalam": "Khanda jathi Jhampa Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1900,
                "Name": "Neethaan",
                "Type": "Thirupugazh",
                "Ragam": "HamirKalyani",
                "Thalam": "Tisra Jathi Jhampa Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1901,
                "Name": "Pagaliravinil",
                "Type": "Thirupugazh",
                "Ragam": "Pantuvarali",
                "Thalam": "Antarakreedaa",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1902,
                "Name": "Padar puviyin",
                "Type": "Thirupugazh",
                "Ragam": "Neelambari",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1903,
                "Name": "Parimala kalabha",
                "Type": "Thirupugazh",
                "Ragam": "Naatakurinji",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1904,
                "Name": "Paniyin Vindhuli",
                "Type": "Thirupugazh",
                "Ragam": "HariKhambhoji",
                "Thalam": "Khanda Jathi Dhuruva Thalam",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1905,
                "Name": "Paalaai noolaai",
                "Type": "Thirupugazh",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1906,
                "Name": "Mundu Thamizh Maalai",
                "Type": "Thirupugazh",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1907,
                "Name": "Variyaar karungaN",
                "Type": "Thirupugazh",
                "Ragam": "Kamalaapthapriya",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1908,
                "Name": "Varuga veetenum",
                "Type": "Thirupugazh",
                "Ragam": "Thodi",
                "Thalam": "Rangathyodham",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1909,
                "Name": "Vindhadhinoori",
                "Type": "Thirupugazh",
                "Ragam": "Kaanadaa",
                "Thalam": "Mishra Chapu",
                "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1910,
                "Name": "Ambuli Neerai",
                "Type": "Thirupugazh",
                "Ragam": "Gowlai",
                "Thalam": "MaGanamatyam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1911,
                "Name": "Avasiyamun",
                "Type": "Thirupugazh",
                "Ragam": "Abhogi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1912,
                "Name": "Aramilaavathi",
                "Type": "Thirupugazh",
                "Ragam": "Swarnamalli",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1913,
                "Name": "Atraikiraithaedi",
                "Type": "Thirupugazh",
                "Ragam": "Saranga",
                "Thalam": "Khanda chapu",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1914,
                "Name": "AathaalikaLpuri",
                "Type": "Thirupugazh",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1915,
                "Name": "Aarumaaru",
                "Type": "Thirupugazh",
                "Ragam": "Hamsanadam",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1916,
                "Name": "Iravupagal",
                "Type": "Thirupugazh",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Tisra Jathi Jhampa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1917,
                "Name": "Iruvinayin",
                "Type": "Thirupugazh",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1918,
                "Name": "Iravaamar",
                "Type": "Thirupugazh",
                "Ragam": "Thodi",
                "Thalam": "Tisram",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1919,
                "Name": "Umbartharu",
                "Type": "Thirupugazh",
                "Ragam": "Kalyani",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1920,
                "Name": "Yedhirillaadha",
                "Type": "Thirupugazh",
                "Ragam": "HindusthaniKapi",
                "Thalam": "Tisra Jathi Matya Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1921,
                "Name": "Kaththuriyakaru",
                "Type": "Thirupugazh",
                "Ragam": "Saveri",
                "Thalam": "Nichangaleela",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1922,
                "Name": "Kaanaadha Dhoora",
                "Type": "Thirupugazh",
                "Ragam": "Jaalakesari",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1923,
                "Name": "Kaanonaadhadhu",
                "Type": "Thirupugazh",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1924,
                "Name": "Kaamiyathazhundhi",
                "Type": "Thirupugazh",
                "Ragam": "Mukhari",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1925,
                "Name": "Kaalanida",
                "Type": "Thirupugazh",
                "Ragam": "Hindolam",
                "Thalam": "Tisra Jathi Jhampa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1926,
                "Name": "Guhanae Guruparanae",
                "Type": "Thirupugazh",
                "Ragam": "Keeravani",
                "Thalam": "Chatusra Jathi Jhampa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1927,
                "Name": "Guhayil Navanaadharun",
                "Type": "Thirupugazh",
                "Ragam": "KedaraGowlai",
                "Thalam": "Chatusra Jathi Jhampa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1928,
                "Name": "Kudi Vaazhkai",
                "Type": "Thirupugazh",
                "Ragam": "Kokilathvani",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1929,
                "Name": "Kuyilondru Mozhi",
                "Type": "Thirupugazh",
                "Ragam": "Amrithavahini",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1930,
                "Name": "Kuyilomozhi",
                "Type": "Thirupugazh",
                "Ragam": "NavarasaKannadaa",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1931,
                "Name": "Kodiyana",
                "Type": "Thirupugazh",
                "Ragam": "Kalyani",
                "Thalam": "Simhaleela",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1932,
                "Name": "Kolaimadha",
                "Type": "Thirupugazh",
                "Ragam": "Dhanyasi",
                "Thalam": "Chatusra Jathi Dhuruva Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1933,
                "Name": "Chadhuratharai",
                "Type": "Thirupugazh",
                "Ragam": "Khambhoji",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1934,
                "Name": "Santhatham",
                "Type": "Thirupugazh",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1935,
                "Name": "Sigaramarundha",
                "Type": "Thirupugazh",
                "Ragam": "Bilahari",
                "Thalam": "Chatusra Jathi Matya Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1936,
                "Name": "Sindhutreluzh",
                "Type": "Thirupugazh",
                "Ragam": "Sowrashtram",
                "Thalam": "Mishra Jathi Triputa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1937,
                "Name": "Seethamadhiya",
                "Type": "Thirupugazh",
                "Ragam": "Bhairavi",
                "Thalam": "Chatusra Jathi Matya Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1938,
                "Name": "SeethaLavaari",
                "Type": "Thirupugazh",
                "Ragam": "Varali",
                "Thalam": "Khanda Jathi Dhuruva Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1939,
                "Name": "Seerulaaviya",
                "Type": "Thirupugazh",
                "Ragam": "Vasantha",
                "Thalam": "Chatusra Jathi Roopaka Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1940,
                "Name": "SruthimaraigaLirunaalu",
                "Type": "Thirupugazh",
                "Ragam": "Janaranjani",
                "Thalam": "Tisra Jathi Dhuruva Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1941,
                "Name": "SaemakkomaLa",
                "Type": "Thirupugazh",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1942,
                "Name": "Gnyaalamengum",
                "Type": "Thirupugazh",
                "Ragam": "ReethiGowlai",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1943,
                "Name": "Thandha pasithanai",
                "Type": "Thirupugazh",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1944,
                "Name": "Thamizhodhiya",
                "Type": "Thirupugazh",
                "Ragam": "Kannadaa",
                "Thalam": "Tisra Jathi Dhuruva Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1945,
                "Name": "Timiramaamana",
                "Type": "Thirupugazh",
                "Ragam": "Dhanyasi",
                "Thalam": "Chatusra Jathi Roopaka Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1946,
                "Name": "Thimiravudhadhi",
                "Type": "Thirupugazh",
                "Ragam": "Kolakalam",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1947,
                "Name": "Thol yelumbu",
                "Type": "Thirupugazh",
                "Ragam": "ShivaKhambhoji",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1948,
                "Name": "Nachchu aravamendru",
                "Type": "Thirupugazh",
                "Ragam": "Karnataka Khamas",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1949,
                "Name": "Nilayaaporulai",
                "Type": "Thirupugazh",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1950,
                "Name": "Pathiyaal naanunnai",
                "Type": "Thirupugazh",
                "Ragam": "Begada",
                "Thalam": "Khanda jathi Jhampa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1951,
                "Name": "Paalo thaeno",
                "Type": "Thirupugazh",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1952,
                "Name": "Bhumiadanir",
                "Type": "Thirupugazh",
                "Ragam": "Garudadhwani",
                "Thalam": "Tisra Jathi Jhampa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1953,
                "Name": "Pothilirundhu",
                "Type": "Thirupugazh",
                "Ragam": "Bhavapriya",
                "Thalam": "AnangaThaalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1954,
                "Name": "Madhiyaal vithaganaagi",
                "Type": "Thirupugazh",
                "Ragam": "Chakravaham",
                "Thalam": "Chatusra Jathi Roopaka Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1955,
                "Name": "Marukkulaaviya",
                "Type": "Thirupugazh",
                "Ragam": "Kuntalavarali",
                "Thalam": "Chatusra Jathi Matya Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1956,
                "Name": "Muthaitharu",
                "Type": "Thirupugazh",
                "Ragam": "Hamsadhwani",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1957,
                "Name": "Mogu mogena",
                "Type": "Thirupugazh",
                "Ragam": "Kusuma Vichithra",
                "Thalam": "Khanda chapu",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1958,
                "Name": "Vasanamiga",
                "Type": "Thirupugazh",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1959,
                "Name": "VanjamkoN",
                "Type": "Thirupugazh",
                "Ragam": "Bilahari",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1960,
                "Name": "Varada maNi nee",
                "Type": "Thirupugazh",
                "Ragam": "Devamanohari",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
                "BookAuthor": "Arunagirinathar",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1961,
                "Name": "Kunjara Mukhanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Chatusra Jathi Matya Thalam",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Kanda Purana Churukka paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1962,
                "Name": "Velanae Arul anukoolanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Kanda Purana Churukka paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1963,
                "Name": "Kuzhandhai aagi irundhal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1964,
                "Name": "Himavaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1965,
                "Name": "Kavasi pola vadaindhar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KamalaManohari",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1966,
                "Name": "Indha poruLgaLukku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Chapu",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1967,
                "Name": "Himachalath thinuk",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1968,
                "Name": "Paanik grahanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Chapu",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1969,
                "Name": "Avadhariththaanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1970,
                "Name": "ThiruviLaiyaadal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1971,
                "Name": "Baala leelai seidhan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Chapu",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1972,
                "Name": "Vedhanai sirai seidhaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1973,
                "Name": "Vidai Koduththaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurunji",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1974,
                "Name": "Theril yaerinaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Roopakam",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1975,
                "Name": "Chendhuril vandhirundthaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Roopakam",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1976,
                "Name": "Avadharith thaarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Chapu",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1977,
                "Name": "Poosanai Seidhaarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1978,
                "Name": "Siva poosanai seidhan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1979,
                "Name": "Aanai muganae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Chapu",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1980,
                "Name": "Leelaigal purindhitaanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1981,
                "Name": "Devar siraiyai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1982,
                "Name": "Baalano vandhennai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1983,
                "Name": "Avanae Baalan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Chapu",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1984,
                "Name": "Unn Karuthu edhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1985,
                "Name": "Unn Paadha Pathiyai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Jhampai",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1986,
                "Name": "Indhiraaniyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chapu",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1987,
                "Name": "AruL puri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1988,
                "Name": "ThirumaNa kolam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1989,
                "Name": "KalyaNa mantapam thanail",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Mishra Jathi Triputa Thalam",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1990,
                "Name": "Singaadhana thirundhan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1991,
                "Name": "AavaanO paramporuL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1992,
                "Name": "IgazhchiyO pugazhchiyO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1993,
                "Name": "moham niraindhanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1994,
                "Name": "avadharithaalaE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1995,
                "Name": "Thirumagal valarndhalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1996,
                "Name": "thiNai punadhndhanil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1997,
                "Name": "Ennakkirangi aruLa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1998,
                "Name": "AruL seivadhan thiru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 1999,
                "Name": "KaNgaLaarakkanDu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2000,
                "Name": "paaNikgrahaNam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2001,
                "Name": "Kayamugan neeyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2002,
                "Name": "Kandanai nee vandanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Mishra Jathi Triputa Thalam",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2003,
                "Name": "Charanam charanam Aiyanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2004,
                "Name": "Un padhathai nambinaenae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2005,
                "Name": "Sharada deviyai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2006,
                "Name": "Indiran PaNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Jhampai",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2007,
                "Name": "Guruparanai yendha naalil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2008,
                "Name": "Seevigaiyin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Jhampai",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2009,
                "Name": "Thirukkurundha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2010,
                "Name": "Upadesithu AruLinaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2011,
                "Name": "Namakkor",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2012,
                "Name": "Dhandanam seidharae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2013,
                "Name": "AruLaadhadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2014,
                "Name": "Vandhiyum varundhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Mishra Jathi Triputa Thalam",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2015,
                "Name": "KooliyaaLaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2016,
                "Name": "Enna vadhisayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2017,
                "Name": "Adithaanae paandiya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2018,
                "Name": "Thazhumbhu pattadhuvae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2019,
                "Name": "Manna nee kaeLaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Chatusra Jathi Matya Thalam",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2020,
                "Name": "Uththamanaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2021,
                "Name": "Sundaresaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2022,
                "Name": "Thillai chidambara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2023,
                "Name": "Aananda Paravasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Udayaravichandrika",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2024,
                "Name": "Vaadhavoorarum yezhundharulinaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Jhampai",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2025,
                "Name": "Naayakar aruL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
                "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
                "BookAuthor": "Vaiyacheri Ramaswami Iyer (Brother of MahaVaidhyanatha Iyer)",
                "Year of Publication": 1948,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2026,
                "Name": "Murugan ThuNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PoorviKalyani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2027,
                "Name": "Ini indha vaazhvil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2028,
                "Name": "Kaiyurai koNdu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2029,
                "Name": "Ekambara nadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2030,
                "Name": "vaEzha muganae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2031,
                "Name": "Thaayinaik kaaNaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2032,
                "Name": "Yedhedho eNNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2033,
                "Name": "Anji Anjiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2034,
                "Name": "Jaya Sree Ramakrishna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2035,
                "Name": "Kai Koduppaayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2036,
                "Name": "Murugaa Un",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2037,
                "Name": "Yaen Padaiththaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadabrahmam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2038,
                "Name": "Thiruvae Undharul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2039,
                "Name": "Oru Naazhi Vaazhvaeninum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2040,
                "Name": "KaariruL serindhadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Raviswaroopini",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2041,
                "Name": "Vidhai polavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2042,
                "Name": "Ambalathil aadum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2043,
                "Name": "Pirar sevaiyaE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2044,
                "Name": "Idhuvon nee karuNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malahari",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2045,
                "Name": "Hari inba naamam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2046,
                "Name": "Vetri aruLum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2047,
                "Name": "Mandira vadivanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2048,
                "Name": "Kadhir vaelavaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2049,
                "Name": "Kalai inbamaE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2050,
                "Name": "vazhikaatum bhavasalathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2051,
                "Name": "Neeyae ThuNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Manohari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2052,
                "Name": "Hari madhavaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2053,
                "Name": "yezhai azhudha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2054,
                "Name": "Iraivan uraikovil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayamanohari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2055,
                "Name": "Amudhooru natramizh",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2056,
                "Name": "Neengaadhendranai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2057,
                "Name": "Varuvaai endrae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2058,
                "Name": "Aaroo nee yena ",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2059,
                "Name": "Aiyaa naanundran",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2060,
                "Name": "Azhindhidum inbathil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2061,
                "Name": "Kooriyondru kooriduvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2062,
                "Name": "Somanai aNipera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2063,
                "Name": "Indha vaedam kanDu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2064,
                "Name": "Evvuyirkum anbu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragavinodini",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2065,
                "Name": "PaambaNi piththanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhushavali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2066,
                "Name": "Gnaana paNdithaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janasammodhini",
                "Thalam": "Mishra Jathi Roopaka Thalam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2067,
                "Name": "Bhuvana Mohana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pushpalathika",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2068,
                "Name": "Ennai un adiyanaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "AmrithaDhanyasi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2069,
                "Name": "Unnai ninaindhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalika",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2070,
                "Name": "UnnaruL Illamal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2071,
                "Name": "Sumaiperidhaendrae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NavarasaKannadaa",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2072,
                "Name": "Sirumalarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2073,
                "Name": "Natana maadinaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2074,
                "Name": "Eththanai piravigal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thatilathika",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2075,
                "Name": "Annai vaaNiyin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Seelaangi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2076,
                "Name": "Oruvarum Ariyaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saamandhadheeparam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2077,
                "Name": "Enthan padaginilor",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayantasree",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2078,
                "Name": "Ellam tharuvan bhayamillamal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2079,
                "Name": "Darisanam tharuvaayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2080,
                "Name": "UnnaruLillamal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijayanaagari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2081,
                "Name": "Unmai indrarindhaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Bangala",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2082,
                "Name": "Vael vaangu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janaranjani",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2083,
                "Name": "Anjael endru arulvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Swarnadeepakam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2084,
                "Name": "ThiruvuLLak karuthaenna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2085,
                "Name": "Sinamae Serunganalaagumae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2086,
                "Name": "Veera vaazhvae vaazha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2087,
                "Name": "TharuNam idhu thaanayaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2088,
                "Name": "Saevadi malar vaada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ravichandrika",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2089,
                "Name": "Endru nin thaer varum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vilasini",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2090,
                "Name": "Sevadi malar vaada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ravichandrika",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2091,
                "Name": "VaeNNai thiruDi uNDa",
                "Type": "Padam",
                "Ragam": "Bhavapriya",
                "Thalam": "Triputa",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2092,
                "Name": "Indha vaeLaiyil",
                "Type": "Padam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2093,
                "Name": "Thinai maeyum",
                "Type": "Padam",
                "Ragam": "Mandari",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2094,
                "Name": "Innamum umadhu nenjam",
                "Type": "Padam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2095,
                "Name": "Varuvaar Varuvaar",
                "Type": "Padam",
                "Ragam": "Kanaka Saveri",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2096,
                "Name": "Seriyirut kaanil",
                "Type": "Padam",
                "Ragam": "Bhagya sabaree",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2097,
                "Name": "Kandhanai kaaNaamal",
                "Type": "Padam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2098,
                "Name": "Indha veedundran",
                "Type": "Padam",
                "Ragam": "Devagandhaari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2099,
                "Name": "Vaazhiya Ulagam vaazhiya karuNai",
                "Type": "Padam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2100,
                "Name": "Mangalamae Shubha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Group": "Mangalam",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
                "BookAuthor": "Periyasamy Turan/N Sivaramakrishna Bhagavathar",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2101,
                "Name": "Aanai maamugaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2102,
                "Name": "NaaDorum vazhipaduvomae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2103,
                "Name": "Sindhai seidhiduveer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MaargaHindolam",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2104,
                "Name": "Nee ennai aandarul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2105,
                "Name": "Jothiyae oLi sudar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2106,
                "Name": "Maraa malar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2107,
                "Name": "Kaaraikaar paeyaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "On Karaikal Ammaiyaar",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2108,
                "Name": "Thiruvaamoor pugazhanaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mandari",
                "Thalam": "Chapu",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2109,
                "Name": "ThoNdar seer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Agni Kobam",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2110,
                "Name": "VeNNai nallur vandhaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2111,
                "Name": "aruNandhi sivappae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janaranjani",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2112,
                "Name": "marai gnaana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Piramodhini",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2113,
                "Name": "chaNdesar padam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2114,
                "Name": "Vithaganaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NataBhairavi",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2115,
                "Name": "Naavalooril",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Roopakam",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2116,
                "Name": "Thiruvadi dinam thozhuvom",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2117,
                "Name": "Muthamizh mannavanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sunadavinodini",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2118,
                "Name": "aruL seidhidaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2119,
                "Name": "Nenjamae naadorum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vajragandhi",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2120,
                "Name": "mandra vaaNan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2121,
                "Name": "yaeno kavalai unakku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Chapu",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2122,
                "Name": "Anjaadhae nenjae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Narayanadesatchi",
                "Thalam": "Chapu",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2123,
                "Name": "Naanaenum oru poruL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2124,
                "Name": "Thunbam varum pozhuDu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chitramani",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2125,
                "Name": "Aaindhoru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ambhojini",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2126,
                "Name": "pulangaLai adakka vaeNdum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NeelaMani",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2127,
                "Name": "Isai vaaNarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ThomaraDhaariNi",
                "Thalam": "Roopakam",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2128,
                "Name": "Kumara naayakaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PoorviKalyani",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2129,
                "Name": "Vandhu kaathidaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2130,
                "Name": "Unnaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2131,
                "Name": "Devi endra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2132,
                "Name": "Koodal maanagar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2133,
                "Name": "Kaar VaNNanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2134,
                "Name": "kalaimagaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2135,
                "Name": "Vazhi padal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Divyabharanam",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2136,
                "Name": "Tharaadhala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2137,
                "Name": "ThirukkuraLai ariyaadhaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2138,
                "Name": "Maanilamael maanidaraam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Roopakam",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2139,
                "Name": "Oruvar poruLai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PusangiNi",
                "Thalam": "Roopakam",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2140,
                "Name": "Aimperum paavangaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surabhushani",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2141,
                "Name": "Cherukkoru podhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2142,
                "Name": "Nambinorkku nanmai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2143,
                "Name": "Mangaiyor",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2144,
                "Name": "Iravum pagalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagachudamani",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2145,
                "Name": "UllaM KoLLai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2146,
                "Name": "Vaedhanai endru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2147,
                "Name": "thiNai punadhndhanil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharaardhari",
                "Thalam": "Chapu",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2148,
                "Name": "Uyir naayagar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2149,
                "Name": "MaamalarthaaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2150,
                "Name": "Poruththavar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagagandhari",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2151,
                "Name": "Kobathai adaki aaLvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suryakantham",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2152,
                "Name": "kaLvaraayinar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ChaamandhachaaLavi",
                "Thalam": "Roopakam",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2153,
                "Name": "Aasaiyai uraikkindraen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharaardhari",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2154,
                "Name": "Thamizhanukku yaen indhu pithu",
                "Type": "Kriti/Keerthanam",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2155,
                "Name": "Ilamai thaNNeer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Omkari",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2156,
                "Name": "Thaayae karuNai purivAi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Savithri",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2157,
                "Name": "kiLiyae unai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2158,
                "Name": "Inbam viLaivadhaengae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desovadhi",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2159,
                "Name": "Variyavar thamai arindhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhuvanaranjani",
                "Thalam": "Roopakam",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2160,
                "Name": "Soodhai mana ninaiyeer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2161,
                "Name": "Thoodhu sendru vaaraai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Raviswaroopini",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2162,
                "Name": "kaNNaena vaazhudhal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2163,
                "Name": "Kayavarai yaen padaiththai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaratnabhushani",
                "Thalam": "Chapu",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2164,
                "Name": "Mozhiyinai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhavalakamchi",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2165,
                "Name": "EndranuLLam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2166,
                "Name": "Vanjithu oruvarai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chandrika",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2167,
                "Name": "PoruL padaiththal mattum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2168,
                "Name": "Thamizh payiluveer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gothiraari",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2169,
                "Name": "KadavuLai nambungal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuvalayaabharanam",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2170,
                "Name": "Patratra vaazha vanDum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowri",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2171,
                "Name": "chaNpayil varubhavan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaarmukhavathi",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2172,
                "Name": "Otrumai mannugavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadagaththodi",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2173,
                "Name": "Sri Ganapathy unai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2174,
                "Name": "Surusuruppodu uzhaippom",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chayavathi",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2175,
                "Name": "VaanorgaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
                "BookAuthor": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2176,
                "Name": "KaDavuLae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "JeyaNarayani",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2177,
                "Name": "KalaivaaNiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Desi",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2178,
                "Name": "Vandhen neramum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsabrahmari",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2179,
                "Name": "ENNinaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2180,
                "Name": "Kaa Vaa Guhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavapriya",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2181,
                "Name": "Paadhukaathu ennai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2182,
                "Name": "Edhu vaeNdum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SaalagaBhairavi",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2183,
                "Name": "KaruNai Sei",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2184,
                "Name": "Unnakoru kurai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2185,
                "Name": "Vaa Vaa Raghavaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2186,
                "Name": "En Seivomaena",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2187,
                "Name": "Unnakadi maiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2188,
                "Name": "Manamae kanavilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalitha",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2189,
                "Name": "Aaraadhara unai allaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2190,
                "Name": "Naayae nuiyuum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanadam",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2191,
                "Name": "Paerinba naayakanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2192,
                "Name": "Veedu Kidaikkum vazhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2193,
                "Name": "Ennakaar thuNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MaargaHindolam",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2194,
                "Name": "Thaarakam unai alladhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2195,
                "Name": "Innam manamillayaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2196,
                "Name": "Inimael madavaarai ninaiyaadhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2197,
                "Name": "Murugaa krupaakaraa",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2198,
                "Name": "Saatchaath kaari",
                "Type": "Javali",
                "Ragam": "Gurupriya",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2199,
                "Name": "Shankaranae haranae",
                "Type": "Javali",
                "Ragam": "Lalithamanohari",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2200,
                "Name": "Naadhaa karuNai seiguvaai",
                "Type": "Javali",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2201,
                "Name": "Kanda naathaa nee thaa",
                "Type": "Javali",
                "Ragam": "Saaraamathi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2202,
                "Name": "Devi unaiyae",
                "Type": "Javali",
                "Ragam": "Sunadavinodini",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2203,
                "Name": "Samaya mudhavu",
                "Type": "Javali",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2204,
                "Name": "Vaelaayudhaa varadhaa",
                "Type": "Javali",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2205,
                "Name": "Manamae kavalayai",
                "Type": "Javali",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2206,
                "Name": "KaruNaakara DhidavaaraNa",
                "Type": "Javali",
                "Ragam": "Sree",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2207,
                "Name": "Yaedhinamum manaththai",
                "Type": "Javali",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2208,
                "Name": "Ishtathodinamae manamae",
                "Type": "Javali",
                "Ragam": "PoorviKalyani",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2209,
                "Name": "Kandanaathan padam",
                "Type": "Javali",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2210,
                "Name": "Pugazhmaan magaL",
                "Type": "Javali",
                "Ragam": "Kapi",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2211,
                "Name": "Vaelanai aNaindhaal",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2212,
                "Name": "Innum konjanaaL paaraDi",
                "Type": "Javali",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2213,
                "Name": "Enn naeramum en kaNgaL",
                "Type": "Javali",
                "Ragam": "Bhairavi",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2214,
                "Name": "Sammadhamaa vaelarae",
                "Type": "Javali",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2215,
                "Name": "Aasai aanaenae sahiyae",
                "Type": "Javali",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2216,
                "Name": "Adhi kobamaa aahaadhamaa",
                "Type": "Javali",
                "Ragam": "Shuddha Seemandhini",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2217,
                "Name": "Enna seiguvaen ini",
                "Type": "Javali",
                "Ragam": "Darbar",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2218,
                "Name": "Vaadhae Seiyyalaagumaa",
                "Type": "Javali",
                "Ragam": "Mandari",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2219,
                "Name": "Sollak kaeLammaa",
                "Type": "Javali",
                "Ragam": "RasaLi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2220,
                "Name": "IdhunaaL varai naan kooDi",
                "Type": "Javali",
                "Ragam": "Begada",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2221,
                "Name": "Nayamaagavae irundhadhae",
                "Type": "Javali",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2222,
                "Name": "Avalae Nalla Mangaiyo",
                "Type": "Javali",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2223,
                "Name": "Chumma perumaikkaaga",
                "Type": "Javali",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2224,
                "Name": "Maanae adhiga maiyalaanaenae",
                "Type": "Javali",
                "Ragam": "Rudrapriya",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2225,
                "Name": "Varuvaano vaaraano",
                "Type": "Javali",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2226,
                "Name": "Konjam kaelamma",
                "Type": "Javali",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2227,
                "Name": "Anaeka thuthiyam",
                "Type": "Javali",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2228,
                "Name": "Ippo thazhaithu vaa",
                "Type": "Javali",
                "Ragam": "Punnagavarali",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2229,
                "Name": "Soramada vaarudanae",
                "Type": "Javali",
                "Ragam": "Huseni",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2230,
                "Name": "Sarikaamabhaavanai",
                "Type": "Surapadam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2231,
                "Name": "Jaadai paesakoodumo",
                "Type": "Surapadam",
                "Ragam": "MeghaRanjani",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2232,
                "Name": "Maalaanaen aNaivaai",
                "Type": "Surapadam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2233,
                "Name": "Paarthaennadhaan seivadhaam",
                "Type": "Surapadam",
                "Ragam": "Sarasangi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2234,
                "Name": "Kobam aagaadhamma",
                "Type": "Surapadam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2235,
                "Name": "Naanindha maiyal sagiyaenae",
                "Type": "Surapadam",
                "Ragam": "Namanarayani",
                "Thalam": "Chapu",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2236,
                "Name": "Andha minnalae",
                "Type": "Surapadam",
                "Ragam": "PoornakaLanidhi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2237,
                "Name": "KaaNaenae sahiyae",
                "Type": "Surapadam",
                "Ragam": "Kalyanavasantham",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2238,
                "Name": "Ithanai aathiramaen",
                "Type": "Surapadam",
                "Ragam": "Revagupti",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2239,
                "Name": "Kutramirundhaal",
                "Type": "Surapadam",
                "Ragam": "Bhushavali",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2240,
                "Name": "Aniyaayamaa nyaayamaa",
                "Type": "Surapadam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2241,
                "Name": "Ippodhae varacholladi",
                "Type": "Surapadam",
                "Ragam": "KannadaGowlai",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
                "BookAuthor": "Muthusamy Kaviyar",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2242,
                "Name": "Thillai Chidambaramae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KapiNarayani",
                "Thalam": "Adi",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2243,
                "Name": "Dherisitha paerai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GandharvaManohari",
                "Thalam": "Roopakam",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2244,
                "Name": "Deiveega thalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thirumoorthi",
                "Thalam": "Adi",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2245,
                "Name": "Endha thalathaiyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balachandrika",
                "Thalam": "Adi",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2246,
                "Name": "EnnaaLum Vaasamaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Seekandi",
                "Thalam": "Adi",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2247,
                "Name": "pala mandhirathirukum Bhala mandiram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadamaaruvam",
                "Thalam": "Roopakam",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2248,
                "Name": "Parandhanai adaindhida",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gamanashrama",
                "Thalam": "Roopakam",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2249,
                "Name": "Orukaal sivachidambaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2250,
                "Name": "Sivachidambaram endru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PinnaShadjam",
                "Thalam": "Adi",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2251,
                "Name": "Innamum oruthalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsavinodini",
                "Thalam": "Mishra Jhampai",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2252,
                "Name": "Kaalai thooki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2253,
                "Name": "Unmugam paartha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Niranjani",
                "Thalam": "Roopakam",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2254,
                "Name": "Yedhukkudhavi indha kaayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhogavasantham",
                "Thalam": "Roopakam",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2255,
                "Name": "Enna Dunivaai naan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokilanandi",
                "Thalam": "Roopakam",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2256,
                "Name": "VeeDum ambalam aagi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vilasini",
                "Thalam": "Chapu",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2257,
                "Name": "Enn pizhaippu undan pizhaippu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Velaveli",
                "Thalam": "Adi",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2258,
                "Name": "Ummaipol aaTai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BhanukeervaaNi",
                "Thalam": "Adi",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2259,
                "Name": "Innam vayiram unn nenjilae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "RathnaGandhi",
                "Thalam": "Adi",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2260,
                "Name": "Enn naeramum kaalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2261,
                "Name": "Idhaivida gudhithu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MaLavaPanchamam",
                "Thalam": "Chapu",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2262,
                "Name": "Ambalathu aadal nadippu enbhadhai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kowmaari",
                "Thalam": "Adi",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2263,
                "Name": "Yaedhuku ithanai modi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamsaKhambhoji",
                "Thalam": "Chapu",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2264,
                "Name": "PaarungaL vandhu paarungaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasukari",
                "Thalam": "Roopakam",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2265,
                "Name": "Endha kaariyathuku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2266,
                "Name": "Yaen Indha paraku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiChandrika",
                "Thalam": "Adi",
                "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2267,
                "Name": "AruL Seivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2268,
                "Name": "Ennai aaLaiyya pannagaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KapiNarayani",
                "Thalam": "Adi",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2269,
                "Name": "paLLi yezhundharulveer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Chapu",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2270,
                "Name": "Bhuloka Kayilasam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Chapu",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2271,
                "Name": "Vandhaa dharitharul Seivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2272,
                "Name": "Varuvaai varuvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Chapu",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2273,
                "Name": "Ellam therindhirundhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PoorviKalyani",
                "Thalam": "Chapu",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2274,
                "Name": "Dhayai seidhu neeyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2275,
                "Name": "Pathi seimanamae nidham nidhamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Chapu",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2276,
                "Name": "VaaVaa VaaVaa Devaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2277,
                "Name": "Thaa Thaa Thaa Punidhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2278,
                "Name": "Raama Naama Dhyaanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2279,
                "Name": "Varuvaai dhayaaLa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Jhampai",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2280,
                "Name": "Saamiyai nee kootivaa",
                "Type": "Surasadhi",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2281,
                "Name": "Maalaanaen minnaethoodhu",
                "Type": "Surasadhi",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2282,
                "Name": "Seela samuga dhayaLa",
                "Type": "Surasadhi",
                "Ragam": "Kalyani",
                "Thalam": "Chapu",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2283,
                "Name": "Paavaiyar thilaga maanavaL",
                "Type": "Surasadhi",
                "Ragam": "Kapi",
                "Thalam": "Roopakam",
                "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2284,
                "Name": "Aanai Maamuganae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Roopakam",
                "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2285,
                "Name": "Ulagil kaN kaNda deivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhaari",
                "Thalam": "Chapu",
                "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2286,
                "Name": "Ellam valla mona muzhumudhalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nattakurinji",
                "Thalam": "Roopakam",
                "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2287,
                "Name": "Sendhir padhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Chapu",
                "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2288,
                "Name": "Sundaravai vaelan padamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2289,
                "Name": "Kanda naathanai kaaNalaam ippodhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Adi",
                "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2290,
                "Name": "Paadham nambinaen ayyanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2291,
                "Name": "Velaayudhanai maelaam maignaana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Chapu",
                "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2292,
                "Name": "Chitham iranga villaiyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2293,
                "Name": "GuNakkundrae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Roopakam",
                "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2294,
                "Name": "Devae suraloka sikhamaninay",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NavarasaKannadaa",
                "Thalam": "Roopakam",
                "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2295,
                "Name": "Seeralai vaayurai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
                "BookAuthor": "Marimutha Pillai",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2296,
                "Name": "Murugan ThuNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PoorviKalyani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2297,
                "Name": "Ini indha vaazhvil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2298,
                "Name": "Kaiyurai koNdu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2299,
                "Name": "Ekambara nadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2300,
                "Name": "vaEzha muganae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2301,
                "Name": "Thaayinaik kaaNaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2302,
                "Name": "Yedhedho eNNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2303,
                "Name": "Anji Anjiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2304,
                "Name": "Jaya Sree Ramakrishna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2305,
                "Name": "Kai Koduppaayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2306,
                "Name": "Murugaa Un",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2307,
                "Name": "Yaen Padaiththaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadabrahmam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2308,
                "Name": "Thiruvae Undharul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2309,
                "Name": "Oru Naazhi Vaazhvaeninum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2310,
                "Name": "KaariruL serindhadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Raviswaroopini",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2311,
                "Name": "Vidhai polavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2312,
                "Name": "Ambalathil aadum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2313,
                "Name": "Pirar sevaiyaE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2314,
                "Name": "Idhuvon nee karuNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malahari",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2315,
                "Name": "Hari inba naamam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2316,
                "Name": "Vetri aruLum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2317,
                "Name": "Mandira vadivanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2318,
                "Name": "Kadhir vaelavaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2319,
                "Name": "Kalai inbamaE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2320,
                "Name": "vazhikaatum bhavasalathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2321,
                "Name": "Neeyae ThuNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Manohari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2322,
                "Name": "Hari madhavaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2323,
                "Name": "yezhai azhudha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2324,
                "Name": "Iraivan uraikovil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayamanohari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2325,
                "Name": "Amudhooru natramizh",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2326,
                "Name": "Neengaadhendranai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2327,
                "Name": "Varuvaai endrae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2328,
                "Name": "Aaroo nee yena ",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2329,
                "Name": "Aiyaa naanundran",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2330,
                "Name": "Azhindhidum inbathil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2331,
                "Name": "Kooriyondru kooriduvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2332,
                "Name": "Somanai aNipera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2333,
                "Name": "Indha vaedam kanDu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2334,
                "Name": "Evvuyirkum anbu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragavinodini",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2335,
                "Name": "PaambaNi piththanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhushavali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2336,
                "Name": "Gnaana paNdithaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janasammodhini",
                "Thalam": "Mishra Jathi Roopaka Thalam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2337,
                "Name": "Bhuvana Mohana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pushpalathika",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2338,
                "Name": "Ennai un adiyanaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "AmrithaDhanyasi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2339,
                "Name": "Unnai ninaindhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalika",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2340,
                "Name": "UnnaruL Illamal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2341,
                "Name": "Sumaiperidhaendrae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NavarasaKannadaa",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2342,
                "Name": "Sirumalarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2343,
                "Name": "Natana maadinaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2344,
                "Name": "Eththanai piravigal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thatilathika",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2345,
                "Name": "Annai vaaNiyin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Seelaangi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2346,
                "Name": "Oruvarum Ariyaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saamandhadheeparam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2347,
                "Name": "Enthan padaginilor",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayantasree",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2348,
                "Name": "Ellam tharuvan bhayamillamal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2349,
                "Name": "Darisanam tharuvaayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2350,
                "Name": "UnnaruLillamal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijayanaagari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2351,
                "Name": "Unmai indrarindhaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Bangala",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2352,
                "Name": "Vael vaangu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janaranjani",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2353,
                "Name": "Anjael endru arulvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Swarnadeepakam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2354,
                "Name": "ThiruvuLLak karuthaenna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2355,
                "Name": "Sinamae Serunganalaagumae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2356,
                "Name": "Veera vaazhvae vaazha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2357,
                "Name": "TharuNam idhu thaanayaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2358,
                "Name": "Saevadi malar vaada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ravichandrika",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2359,
                "Name": "Endru nin thaer varum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vilasini",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2360,
                "Name": "Sevadi malar vaada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ravichandrika",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2361,
                "Name": "VaeNNai thiruDi uNDa",
                "Type": "Padam",
                "Ragam": "Bhavapriya",
                "Thalam": "Triputa",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2362,
                "Name": "Indha vaeLaiyil",
                "Type": "Padam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2363,
                "Name": "Thinai maeyum",
                "Type": "Padam",
                "Ragam": "Mandari",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2364,
                "Name": "Innamum umadhu nenjam",
                "Type": "Padam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2365,
                "Name": "Varuvaar Varuvaar",
                "Type": "Padam",
                "Ragam": "Kanaka Saveri",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2366,
                "Name": "Seriyirut kaanil",
                "Type": "Padam",
                "Ragam": "Bhagya sabaree",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2367,
                "Name": "Kandhanai kaaNaamal",
                "Type": "Padam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2368,
                "Name": "Indha veedundran",
                "Type": "Padam",
                "Ragam": "Devagandhaari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2369,
                "Name": "Vaazhiya Ulagam vaazhiya karuNai",
                "Type": "Padam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2370,
                "Name": "Mangalamae Shubha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
                "Group": "Mangalam",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 15",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2371,
                "Name": "Bhuloka Kayilasagiri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavapriya",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2372,
                "Name": "Sevika vaendum ayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2373,
                "Name": "Innam oru thalam innam oru kovil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2374,
                "Name": "Theertham mudhal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Chapu",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2375,
                "Name": "Chirsabhai thanilae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Chapu",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2376,
                "Name": "Natanam kanDa podhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KamalaManohari",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2377,
                "Name": "Eesanae koDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Chapu",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2378,
                "Name": "Ambara Chidambara Sadhanandhamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rishabhapriya",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2379,
                "Name": "Nee dhayavaai ennai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2380,
                "Name": "Unai nambinaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2381,
                "Name": "Ennai yenakku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2382,
                "Name": "Unn paadhamae thuNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Garudadhwani",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2383,
                "Name": "Santhatham unn paadhasevai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2384,
                "Name": "Siththar innam Undo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2385,
                "Name": "KaaNaamal Irundhaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2386,
                "Name": "kaNdavar viNdilarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Chapu",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2387,
                "Name": "Paadha dharisanam seiyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayantasena",
                "Thalam": "Chapu",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2388,
                "Name": "AaDinadheppadiyo natanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2389,
                "Name": "AaDikkonDaar andha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2390,
                "Name": "Shambho Shankara Mahadeva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2391,
                "Name": "AaDiya paadhaa iruvar aadiya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2392,
                "Name": "Dherisitha aLavil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2393,
                "Name": "Maaya viththai seigiraanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2394,
                "Name": "Aatu kaalai thaarum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suvarnaangi",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2395,
                "Name": "Saami dharisanam kanDu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhushavali",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2396,
                "Name": "AmbalavaaNar neerae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2397,
                "Name": "Aaraar aasai padaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2398,
                "Name": "Naanae muzhudhum nambinaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2399,
                "Name": "Aiyanae natanam aadiya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NataBhairavi",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2400,
                "Name": "Aadiya vaedikkai paareer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2401,
                "Name": "Nadipavanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shadvidhamaargini",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2402,
                "Name": "Niruthanjseithaarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amrithavahini",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2403,
                "Name": "Ananda thaandavam aadinar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2404,
                "Name": "KaaNaamal veeNilae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Chapu",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2405,
                "Name": "Derisanam seivaenae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2406,
                "Name": "kaNdapin kaNkuLirndhaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2407,
                "Name": "Arumarundhoru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2408,
                "Name": "Endru sendru KaaNbaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2409,
                "Name": "KaaNa mukthi paeralaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Namanarayani",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2410,
                "Name": "HaraHara ShivaShiva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2411,
                "Name": "Yaarukku theriyum ayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natakapriya",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2412,
                "Name": "Sundara kunjitha paadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2413,
                "Name": "Thookiya paadhathin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2414,
                "Name": "Sivandha paadhathai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2415,
                "Name": "Avanae devar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijayanaagari",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2416,
                "Name": "Maravaadhiru nenjamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2417,
                "Name": "Sivanai maravaadhae ninaimanamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Seelaangi",
                "Thalam": "Chapu",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2418,
                "Name": "Paesaadhae nenjamae paesaadhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suryakantham",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2419,
                "Name": "Chidambaramae ninai manamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mandari",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2420,
                "Name": "Derisanamae ninai manamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhenuka",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2421,
                "Name": "Thothiram seimanamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2422,
                "Name": "Nirtham iduiththiru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2423,
                "Name": "Thaedi thiriyaadhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PoorviKalyani",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2424,
                "Name": "KaNdaayo manamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2425,
                "Name": "Sivachidambaramaedherisikka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2426,
                "Name": "Kaettu pogaadhae nenjae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2427,
                "Name": "Ini sumakka mudiyaadhu dhegam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Chapu",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2428,
                "Name": "Innam piravaamalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2429,
                "Name": "KalandhukoL vaenthillai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Chapu",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2430,
                "Name": "Maanikkavaasagar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokilapriya",
                "Thalam": "Roopakam",
                "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 4",
                "BookAuthor": "Muthuthandavar",
                "Year of Publication": 1967,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2431,
                "Name": "Paadhamae Thunai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2432,
                "Name": "Thavamaevum thenpuli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2433,
                "Name": "Piravaadha varam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2434,
                "Name": "Irakkam varamal pona",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2435,
                "Name": "Thillai Thillai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2436,
                "Name": "Thillai thalamaendru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2437,
                "Name": "Paeyaandi thanaik kanDu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2438,
                "Name": "KaNdaen kali theerndhaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2439,
                "Name": "Indha prathaapamum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2440,
                "Name": "Kanakasabhapathikku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2441,
                "Name": "Siva Chidambaramendru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2442,
                "Name": "Naanondrum ariyaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahiri",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2443,
                "Name": "Kanaka sabhai thiru natanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2444,
                "Name": "Pichaikkaara vaesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlipanthu",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2445,
                "Name": "Engae thaedi pidithaayadi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2446,
                "Name": "Guruvai paNindhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2447,
                "Name": "Engal gurunaatharudaiya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2448,
                "Name": "Piravaadha mukthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2449,
                "Name": "Eppo tholaiyum indha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2450,
                "Name": "Mosam vandhadhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2451,
                "Name": "Chitham Chidambaraththae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2452,
                "Name": "Unadhu thiruvadi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2453,
                "Name": "ThaanDava dharisanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2454,
                "Name": "En naeramum undhan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhaari",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2455,
                "Name": "Thaedi alaigiraayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2456,
                "Name": "Kaividalaagaadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nayaki",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2457,
                "Name": "Aadiyapaadhaa iruvargal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2458,
                "Name": "Maaya vaazhkaiyai nambaadhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2459,
                "Name": "Aadiya paadhamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2460,
                "Name": "Thandhaalum tharattum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balahamsa",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2461,
                "Name": "Dheham ippadiyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2462,
                "Name": "Manadhu adanguvathaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlipanthu",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2463,
                "Name": "Periyorae yaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2464,
                "Name": "Sharanaa gathi endru nambivandean",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2465,
                "Name": "Kanaka sabesan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2466,
                "Name": "Aadina thaandavam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2467,
                "Name": "kaNNaalorudharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2468,
                "Name": "Arpudha natanam aadinaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2469,
                "Name": "Araharasiva sankara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2470,
                "Name": "Thiruvadi charanamendru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2471,
                "Name": "Yedho theriyaamal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2472,
                "Name": "Enna saami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2473,
                "Name": "Satrum achamillamal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2474,
                "Name": "Devaa jaganaathaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2475,
                "Name": "Kirubai paaramma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2476,
                "Name": "Thandai thaai irundhaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2477,
                "Name": "Sivaloka enngira",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2478,
                "Name": "Inimaelaagilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2479,
                "Name": "Thaathai thaathai endru aadum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2480,
                "Name": "Ponnambala vaaNanadi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2481,
                "Name": "Orudhara maagilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2482,
                "Name": "Dinam dinam nataraasar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2483,
                "Name": "Pagalaavadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2484,
                "Name": "Aanandha kadalaatum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2485,
                "Name": "kaNNalae kaNdaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2486,
                "Name": "Eppo varuvaaro endhan kalitheera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2487,
                "Name": "Natanamaadinaar iyer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2488,
                "Name": "Chidambaram endru orutharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2489,
                "Name": "Thillai chidambaram endrae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PoorviKalyani",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2490,
                "Name": "Varuvaaro varam tharuvaaro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2491,
                "Name": "Aanandha koothaadinaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2492,
                "Name": "Idhunalla samayamaiyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2493,
                "Name": "Paarka Paarka Thigatumaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2494,
                "Name": "Thaa thai endraadruvaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2495,
                "Name": "Natanam adinaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2496,
                "Name": "Kunchitha paadhathai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2497,
                "Name": "Dherisika vaenum chidambarathai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2498,
                "Name": "Paarthu pizhaiyungaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2499,
                "Name": "Innamum orudharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2500,
                "Name": "Pathi paNNi kondirunthal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2501,
                "Name": "ThonDarai KaaNNgilamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2502,
                "Name": "ThaLLu vaaro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2503,
                "Name": "Aadiya paadhathai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2504,
                "Name": "Indha sadhalam vandhavaaru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2505,
                "Name": "Kanaka sabhapathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2506,
                "Name": "Arivudaiyor paNiNdhethum thillai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Jhampai",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2507,
                "Name": "Manadhae unakk",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2508,
                "Name": "Natana sabesa nadhi punai eesaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2509,
                "Name": "Darisanam kaNdaarku marujanmam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2510,
                "Name": "Podhum podhumaiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KamalaManohari",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2511,
                "Name": "Neesanaai pirandhalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Jhampai",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2512,
                "Name": "Aadum Chidambaramo Ayyan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2513,
                "Name": "Thillai ambalaththaanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2514,
                "Name": "Shambho Gangadhara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "EshaManohari",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2515,
                "Name": "Sopanam kanDukonDean",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2516,
                "Name": "Innam varak kaaNaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2517,
                "Name": "Aadiya paadham darisanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2518,
                "Name": "Thaa Thaa Thaa Nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2519,
                "Name": "Thenpuliyur",
                "Type": "KaNNi",
                "Ragam": "Pantuvarali",
                "Thalam": "Tisra Gathi",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2520,
                "Name": "KaaNaamal irukka laagaadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Chapu",
                "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1944,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2521,
                "Name": "Vinayaka Charanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Paayiram)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2522,
                "Name": "Yaen Palli kondeerayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Paayiram)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2523,
                "Name": "Raja Raajarku raajan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Chapu",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Balakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2524,
                "Name": "PiLLai illadha bhaagyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dwijaavanthi",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Balakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2525,
                "Name": "Parabrahma swaroopamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlipanthu",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Balakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2526,
                "Name": "Raamanai tharuvaai jaga rakshaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Roopakam",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Balakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2527,
                "Name": "Raaman yaedhukku idharko",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Triputa",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Balakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2528,
                "Name": "Mosam varadhaiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Balakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2529,
                "Name": "Yaaro ivar yaaro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Balakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2530,
                "Name": "Annai jaanaki vandhalae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Balakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2531,
                "Name": "Indha manu thara vaENum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2532,
                "Name": "Raamanukku mannan mudi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Chapu",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2533,
                "Name": "En bhaagyamae bhaagyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Triputa",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2534,
                "Name": "Naanae LakshmaNan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2535,
                "Name": "Ithanai kobamum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2536,
                "Name": "Eppadi manam thuNindhadho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Chapu",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2537,
                "Name": "Ingae irungaaNum darisanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2538,
                "Name": "saErum gadhiyaal saEruvaEn",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2539,
                "Name": "Arivaar yaar unnai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Chapu",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Aaranyakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2540,
                "Name": "Irundhanae ramachandran",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Aaranyakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2541,
                "Name": "KaaNavaENum latcham kaNNgaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Chapu",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Aaranyakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2542,
                "Name": "yaarendru raaghavanai eNNineer amma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Aaranyakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2543,
                "Name": "enna seivaen seethai naan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Aaranyakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2544,
                "Name": "Raamaa nee inda kobam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Aaranyakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2545,
                "Name": "yaenindha madhikuriththaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Kishkindhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2546,
                "Name": "Saami raghuraama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Chapu",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Kishkindhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2547,
                "Name": "Aadi devanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Chapu",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Kishkindhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2548,
                "Name": "Arasu seivadhenna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Kishkindhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2549,
                "Name": "Varugiraano vaaraano",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Chapu",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Kishkindhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2550,
                "Name": "En kanavai keLaaiamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Sundarakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2551,
                "Name": "Agni bhagavaanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Sundarakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2552,
                "Name": "Choodaamani kanDapodhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Chapu",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Sundarakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2553,
                "Name": "Indha vibeeshanan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2554,
                "Name": "CharanaM charaNam endraanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Chapu",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2555,
                "Name": "Raghavanae charaNam raghuraamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Roopakam",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2556,
                "Name": "Indhaa vibeeshaNaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2557,
                "Name": "SonnaaL sree raaman",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2558,
                "Name": "kaeLadaa raavaNanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2559,
                "Name": "Engae vaithirundhaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2560,
                "Name": "Indha yosanai yaen ayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Triputa",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2561,
                "Name": "yaen vandhaai thambhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chapu",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2562,
                "Name": "en vaarthai thanai kaeL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2563,
                "Name": "Ayyanae unnmahimaiyai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2564,
                "Name": "Unadhu mahimai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Chapu",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2565,
                "Name": "yaedhukku varundhukiraai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2566,
                "Name": "yeaLundhaaLae poongodhai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2567,
                "Name": "Vandhaan Vandhaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2568,
                "Name": "Raaman yeazhundharuLinaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2569,
                "Name": "Singaaram seidhaanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2570,
                "Name": "Kaatchi yudanae raaman",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
                "BookAuthor": "Arunachala Kavi",
                "Year of Publication": 1945,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2571,
                "Name": "Giriraja sutha thanayaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BangaLa",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2572,
                "Name": "Ela nee daya (Balakanakamaya)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2573,
                "Name": "Gurulekha Etuvanti",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Khanda Chapu",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2574,
                "Name": "Maanamu laedha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2575,
                "Name": "Nagumomu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2576,
                "Name": "Dharini telusu konti",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2577,
                "Name": "Mokshamu galadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saaraamathi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2578,
                "Name": "Ksheerasagara shayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhaari",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2579,
                "Name": "Manasu nil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2580,
                "Name": "Raminchu vaa raevaruraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Subhoshini",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2581,
                "Name": "RaRaa maa yinti",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2582,
                "Name": "Eduta nilachi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2583,
                "Name": "Entha ninne",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2584,
                "Name": "Jagadhanandakaraka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Group": "Ghana Raga Pancharatnam",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2585,
                "Name": "Duduku gala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Group": "Ghana Raga Pancharatnam",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2586,
                "Name": "Sadhinchanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Group": "Ghana Raga Pancharatnam",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2587,
                "Name": "Kanakanaruchira",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Group": "Ghana Raga Pancharatnam",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2588,
                "Name": "Entharo Mahanubhavulu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Group": "Ghana Raga Pancharatnam",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2589,
                "Name": "Rajuvedala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2590,
                "Name": "Rama nannu brova",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2591,
                "Name": "Kaligiyuntae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2592,
                "Name": "Ganamurthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ganamurti",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2593,
                "Name": "Rama nee samana mevaru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2594,
                "Name": "Bhagayanayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chandrajyothi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2595,
                "Name": "Ramabhi rama ramaneeya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Chapu",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2596,
                "Name": "Nadhoupasana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2597,
                "Name": "Dunmaaraga saraadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ranjani",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2598,
                "Name": "Tera tyaga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlipanthu",
                "Thalam": "Chapu",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2599,
                "Name": "Nidhi chala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2600,
                "Name": "Rama katha sudha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2601,
                "Name": "Anuragamulae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2602,
                "Name": "Meevalla guNadhosha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Jhampai",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2603,
                "Name": "Paramaathmudu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vagadeeshwari",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2604,
                "Name": "Brochaevarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2605,
                "Name": "Brovabarama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2606,
                "Name": "Ninnuvana naama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NavarasaKannadaa",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2607,
                "Name": "Vidajaaladura",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janaranjani",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2608,
                "Name": "Enthavde konduraghava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi Manohari",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2609,
                "Name": "Orajupuju saethi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KannadaGowlai",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2610,
                "Name": "Naenendhu vedhuku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Karnataka Behag",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2611,
                "Name": "Raamaa nee yaeda premarahi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dileepakam",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2612,
                "Name": "Geethaarthamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2613,
                "Name": "Raga sudha rasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2614,
                "Name": "Santhamu lekha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2615,
                "Name": "Rama Ramana raa raa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2616,
                "Name": "Telisi Rama chinthanatho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2617,
                "Name": "Nee naama roopamulaku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Thyagaraja Swamy Krithis with meaning",
                "BookAuthor": "Thyagaraja swamy",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2618,
                "Name": "Thodudaiya (Nattapaadai)",
                "Type": "Thevaram",
                "Ragam": "Naatai",
                "Thalam": "Roopakam",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-Thirubrahmapuram",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2619,
                "Name": "Kootraayina (Kolli)",
                "Type": "Thevaram",
                "Ragam": "Navaroj",
                "Thalam": "Adi",
                "Composer": "Thirunavukkarasar",
                "Group": "Kshetram-Thiruvadhigaiveerataanam",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2620,
                "Name": "Pithapirai soodi (IndhaLam)",
                "Type": "Thevaram",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Roopakam",
                "Composer": "Sundaramoorthy nayanar",
                "Group": "Kshetram-Thiruvennainaloor",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2621,
                "Name": "Vaeyuru thoLi bangan (PiyandhaiGandharam)",
                "Type": "Thevaram",
                "Ragam": "Navaroj",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirugnanasambandar",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2622,
                "Name": "Enna puNNiyam (Nattaraagam)",
                "Type": "Thevaram",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-Thiruvalanchuzhi",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2623,
                "Name": "Bandhaththaal (Viyazhakkurinji)",
                "Type": "Thevaram",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-Thirukkazhumalam",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2624,
                "Name": "Idarinum thaLarinum (Gandharapanchamam)",
                "Type": "Thevaram",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-Thiruvadudurai",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2625,
                "Name": "Poovaar malark kondaDiyaar",
                "Type": "Thevaram",
                "Ragam": "Khambhoji",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-Thiruvannamalai",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2626,
                "Name": "Poothaer thaayana (Pazhanthakkaragam)",
                "Type": "Thevaram",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-Thiruvoththoor",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2627,
                "Name": "Aadinaai naruneiyodu (GandharaPanchamam)",
                "Type": "Thevaram",
                "Ragam": "KedaraGowlai",
                "Thalam": "Roopakam",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-Koil",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2628,
                "Name": "Aala neezhalugandha (Pazhampanchuram)",
                "Type": "Thevaram",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-Thiruvaalavaai",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2629,
                "Name": "Nandrudaiyaanai (Kurinji)",
                "Type": "Thevaram",
                "Ragam": "HariKhambhoji",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-Thiruchirappalli",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2630,
                "Name": "Neerusaervathor (Megharagakurinji)",
                "Type": "Thevaram",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-Thiruparaaithurai",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2631,
                "Name": "Kaadhalaagi kasindhu (Kowsikam)",
                "Type": "Thevaram",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Thirugnanasambandar",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2632,
                "Name": "Utrumai searvadhu (Pazhampanchuram)",
                "Type": "Thevaram",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-Thirubrahmapuram",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2633,
                "Name": "Veedalaala vaayilaai (Kowsikam)",
                "Type": "Thevaram",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-Thiruvalavaai",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2634,
                "Name": "Iththanai yaamaatrai (Nattaraagam)",
                "Type": "Thevaram",
                "Ragam": "Pantuvarali",
                "Thalam": "Khanda Chapu",
                "Composer": "Sundaramoorthy nayanar",
                "Group": "Kshetram-ThirugurukaavoorveLLadai",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2635,
                "Name": "Parasu paaNiyar (Pazhampanchuram)",
                "Type": "Thevaram",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshtram-Thiruppallavanneswaram",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2636,
                "Name": "yaerisaiyum vaDavaalin (Megharagakurinji)",
                "Type": "Thevaram",
                "Ragam": "Neelambari",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-Thiruveezhimizhalai",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2637,
                "Name": "Mangaiyar karasi vaLavarkon (Puraneermai)",
                "Type": "Thevaram",
                "Ragam": "Bhupalam",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-Thiruvalavaai",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2638,
                "Name": "ThonDaranju kaLiru (Sevvazhi)",
                "Type": "Thevaram",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-Thirukkedaram",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2639,
                "Name": "Paththimaiyum aDimaiyum (Pazhampanchuram)",
                "Type": "Thevaram",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Sundaramoorthy nayanar",
                "Group": "Kshetram-Thiruvaroor",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2640,
                "Name": "meeLaa Adimai (Chendhuruthi)",
                "Type": "Thevaram",
                "Ragam": "Madhyamavathi",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Sundaramoorthy nayanar",
                "Group": "Kshetram - Tiruvaroor",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2641,
                "Name": "kaloor perumaNam (andhALikkurinjin)",
                "Type": "Thevaram",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Thirugnanasambandar",
                "Group": "Kshetram-NaloorperumaNam",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2642,
                "Name": "Thaanaenai munpaDaiththaan (Panchamam)",
                "Type": "Thevaram",
                "Ragam": "Ahiri",
                "Thalam": "Roopakam",
                "Composer": "Sundaramoorthy nayanar",
                "Group": "Kshetram-ThirunoDiththaanmalai",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2643,
                "Name": "Vaikal poonkazhivaai (nattaragam)",
                "Type": "DivyaPrabandam-Thiruvaimozhi",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Nammazhvaar",
                "Group": "Kshetram-ThiruvaNvaNdoor",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2644,
                "Name": "seelamilla siriyanaelum (Pazhamthakkaragam)",
                "Type": "DivyaPrabandam-Thiruvaimozhi",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Nammazhvaar",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2645,
                "Name": "vaNDuNU narumala (GandharaPanchamam)",
                "Type": "DivyaPrabandam-PeriyaThirumozhi",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Thirumangaiazhwar",
                "Group": "Kshetram-ThiruviNNagaram",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2646,
                "Name": "Poonilaaya vaindhumaai (Kowshikam)",
                "Type": "DivyaPrabandam-Thiruchchandaviruththam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Thirumazhisaipiraan",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2647,
                "Name": "Maayakkoothaa (Pazhampanchuram)",
                "Type": "DivyaPrabandam-Thiruvaimozhi",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Nammazhvaar",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2648,
                "Name": "Maanaei nokku nalleer (Panchamam)",
                "Ragam": "Ahiri",
                "Thalam": "Roopakam",
                "Composer": "Nammazhvaar",
                "Group": "Kshetram-Thiruvallavaazh",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2649,
                "Name": "Thiruththaai sempothae (Pazhanthakkaragam)",
                "Type": "DivyaPrabandam-Thirumangaiazhwar",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Roopakam",
                "Composer": "Thirumangaiazhwar",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2650,
                "Name": "Ooraellam thunji (puraneermai)",
                "Type": "DivyaPrabandam-Thiruvaimozhi",
                "Ragam": "Bhowli",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Nammazhvaar",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2651,
                "Name": "Kongalarandha (Kolli)",
                "Type": "DivyaPrabandam-PeriyaThirumozhi",
                "Ragam": "Navaroj",
                "Thalam": "Mishra Chapu",
                "Composer": "Thirumangaiazhwar",
                "Group": "Kshetram-Thiruvenkatam",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2652,
                "Name": "Maanikkam katti (Megharagakurinji)",
                "Type": "DivyaPrabandam-PeriyazhwarThirumozhi",
                "Ragam": "Neelambari",
                "Thalam": "Roopakam",
                "Composer": "Periyazhvar",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2653,
                "Name": "Thamaru mamaru",
                "Type": "Thirupugazh",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Arunagirinathar",
                "Group": "Kshetram-Pazhani",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2654,
                "Name": "ParimaLa kaLabha",
                "Type": "Thirupugazh",
                "Ragam": "Khambhoji",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "Arunagirinathar",
                "Group": "Kshetram-Thiruchendur",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2655,
                "Name": "Kaadhi modhi vaadhaadu",
                "Type": "Thirupugazh",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Mishra Chapu",
                "Composer": "Arunagirinathar",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2656,
                "Name": "koDaana maerumalai",
                "Type": "Thirupugazh",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Arunagirinathar",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2657,
                "Name": "vaasiththu kaaNo",
                "Type": "Thirupugazh",
                "Ragam": "Manirangu",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Arunagirinathar",
                "Song Language": "Tamil",
                "BookTitle": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
                "BookAuthor": "Azhwar, Nayanmar, Arunagiri nathar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2658,
                "Name": "Maha Ganapathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2659,
                "Name": "Gaanamutham thaa nee nidham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saalaham",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-37",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2660,
                "Name": "Kanaka mayura",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jalaarnavam",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-38",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2661,
                "Name": "Anaadha Rakshaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jhalavarali",
                "Thalam": "Jhampai",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-39",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2662,
                "Name": "Saami idhae nala samayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaneetham",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-40",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2663,
                "Name": "Anjaadhae nenjamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paavani",
                "Thalam": "Mishra Chapu",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-41",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2664,
                "Name": "Sadaananda Bhakthi thandhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Raghupriya",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-42",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2665,
                "Name": "Viraivaagavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gavambhodhi",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-43",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2666,
                "Name": "Senthiruvaelan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavapriya",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-44",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2667,
                "Name": "Vaelanae Vaerae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Mishra Chapu",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-45",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2668,
                "Name": "Antharanga bhakthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shadvidhamaargini",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-46",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2669,
                "Name": "Ikaparasukha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suvarnaangi",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-47",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2670,
                "Name": "Appa murukaiya nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Divyamani",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-48",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2671,
                "Name": "KaaruNya kandha gaanamudham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhavalambari",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-49",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2672,
                "Name": "Yaen manamae unakkindha bhayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Namanarayani",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-50",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2673,
                "Name": "Kaa murugaiya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-51",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2674,
                "Name": "Sami sadhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-52",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2675,
                "Name": "Ikamae sukham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gamanashrama",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-53",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2676,
                "Name": "Paramaanandha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vishvambari",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-54",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2677,
                "Name": "Kandhaa karpaga tharuvae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShyamaLanghi",
                "Thalam": "Mishra Chapu",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-55",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2678,
                "Name": "Sukhamae Sukham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-56",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2679,
                "Name": "Unnai allaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-57",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2680,
                "Name": "Manadhae maravadhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-58",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2681,
                "Name": "Kandha Bhaktha chinthamani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Mishra Chapu",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-59",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2682,
                "Name": "Mohanakara muthukumara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neethimathi",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-60",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2683,
                "Name": "Nadhasukham tharu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kanthamani",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-61",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2684,
                "Name": "Gananya Desika",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rishabhapriya",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-62",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2685,
                "Name": "Kaikooda VaeNumae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Jhampai",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-63",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2686,
                "Name": "Ikhapara sukha dhaayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-64",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2687,
                "Name": "Sadhaanandhamae tharum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-65",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2688,
                "Name": "Samagana lola",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chithrambari",
                "Thalam": "Mishra Chapu",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-66",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2689,
                "Name": "Velumayilumae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sucharithra",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-67",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2690,
                "Name": "Gaanaamudha paanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jyothiswaroopini",
                "Thalam": "Mishra Chapu",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-68",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2691,
                "Name": "Sukhakarasoma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhathuvardhani",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-69",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2692,
                "Name": "Thandharul Ayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nasikabhushani",
                "Thalam": "Mishra Chapu",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-70",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2693,
                "Name": "Kaa Guhaa Shanmukhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kosalam",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-71",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2694,
                "Name": "AruL seiya vaENum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rasikapriya",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-72",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2695,
                "Name": "Dhanthi maamukha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2696,
                "Name": "Vaa Vaa Shiki vaahanaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2697,
                "Name": "Mona gnana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2698,
                "Name": "Vaelavaa vaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2699,
                "Name": "Maravaa thiru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2700,
                "Name": "Tharuvar nin aruL thiruvae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Mishra Chapu",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2701,
                "Name": "Kanaka vaela karuNaalavaala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2702,
                "Name": "Vaa Sharavana bhavaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2703,
                "Name": "Vaelaiyya dhayavillaiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2704,
                "Name": "Krupaipaaraiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2705,
                "Name": "Sami idhae samayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2706,
                "Name": "Thaa Thaa nin paadham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2707,
                "Name": "Maanae mohanm sahiyaenae",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2708,
                "Name": "VaravaeNum saminaatha",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2709,
                "Name": "Sami neeyae gathi",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2710,
                "Name": "Appanae Kabali",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Praise of": "on Mylapore Kabaleeswarar",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2711,
                "Name": "Eesan kanaka sabaesan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "on Chidambaram Sabanathar",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2712,
                "Name": "Shambho Shankara Sadashivaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Praise of": "On Lord Shiva",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2713,
                "Name": "Rama ravikula somaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "On Lord Rama",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2714,
                "Name": "Sree Venugopala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Jhampai",
                "Praise of": "On Lord Venugopala",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2715,
                "Name": "Sami Deekshitha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Roopakam",
                "Praise of": "on Muthuswamy Dikshitar",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2716,
                "Name": "Yaedhum ariyaenae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Jhampai",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2717,
                "Name": "Naadha thathva vinodha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2718,
                "Name": "Ulam ariyaadhaa Oyaadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2719,
                "Name": "AruL Paaraiyya",
                "Type": "Varnam-Tana",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2720,
                "Name": "MangaLam mayil vaahana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2721,
                "Name": "Geethaa amudhamae thaa sadhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2722,
                "Name": "Chittaiswaram for Melakartha composition",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-23",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2723,
                "Name": "Chittaiswaram for Melakartha composition",
                "Ragam": "Varunapriya",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-24",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2724,
                "Name": "Chittaiswaram for Melakartha composition",
                "Ragam": "MaaraRanjani",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-25",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2725,
                "Name": "Chittaiswaram for Melakartha composition",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-26",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2726,
                "Name": "Chittaiswaram for Melakartha composition",
                "Ragam": "Sarasangi",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-27",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2727,
                "Name": "Chittaiswaram for Melakartha composition",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-28",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2728,
                "Name": "Chittaiswaram for Melakartha composition",
                "Ragam": "Naganandhini",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-30",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2729,
                "Name": "Chittaiswaram for Melakartha composition",
                "Ragam": "Yagapriya",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-31",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2730,
                "Name": "Chittaiswaram for Melakartha composition",
                "Ragam": "Ragavardhini",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-32",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2731,
                "Name": "Chittaiswaram for Melakartha composition",
                "Ragam": "Gangeyabhushani",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-33",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2732,
                "Name": "Chittaiswaram for Melakartha composition",
                "Ragam": "Vagadeeshwari",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-34",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2733,
                "Name": "Chittaiswaram for Melakartha composition",
                "Ragam": "Soolini",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-35",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2734,
                "Name": "Chittaiswaram for Melakartha composition",
                "Ragam": "Chalanaatai",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Group": "Melakartha Ragam-36",
                "Song Language": "Tamil",
                "BookTitle": "Kanda Gana Amudam - Part 2",
                "BookAuthor": "Koteeswara Iyer",
                "Year of Publication": 1938,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2735,
                "Name": "Sama Natakam",
                "Type": "Drama",
                "BookTitle": "Karnataka Sangetham - Part 1",
                "BookAuthor": "SR Kuppuswamy",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2736,
                "Name": "aruL purivAi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Shuddhanandha Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Karnataka Sangetham - Part 1",
                "BookAuthor": "SR Kuppuswamy",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2737,
                "Name": "Niththam unnai vaeNDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "SR Kuppusamy",
                "Song Language": "Tamil",
                "BookTitle": "Karnataka Sangetham - Part 2",
                "BookAuthor": "SR Kuppuswamy",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2738,
                "Name": "Indha sowkya mani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja swamy",
                "Song Language": "Telugu",
                "BookTitle": "Karnataka Sangetham - Part 3",
                "BookAuthor": "SR Kuppuswamy",
                "Year of Publication": 1946,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2739,
                "Name": "Deva Deva Kalayamidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2740,
                "Name": "Saahasigatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2741,
                "Name": "Gopa nandana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhushavali",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2742,
                "Name": "Bhavayae saarasanaabham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2743,
                "Name": "paalaya maamayi bho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2744,
                "Name": "Saarasaayatha lochana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2745,
                "Name": "Raama paripaalaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2746,
                "Name": "Padmanabha paalithaebha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2747,
                "Name": "Narasimha maamava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Jhampai",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2748,
                "Name": "Paahi maamayi mukunda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhaari",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2749,
                "Name": "Sree Padmanabha kalayithum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2750,
                "Name": "Sourae vidhara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Sembada",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2751,
                "Name": "Kama janaka ripu gaNa madha hara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2752,
                "Name": "Sarasa sama mukha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2753,
                "Name": "Kaladhaa nava naashanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2754,
                "Name": "Padasanadhi muni jana manasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2755,
                "Name": "Saevae Sree kaantham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MohanaKalyani",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2756,
                "Name": "Smara hari paadaaravindam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2757,
                "Name": "karuNakara madhava mamava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2758,
                "Name": "Mandhara Dhara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Triputa Thalam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2759,
                "Name": "Smara sada manasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2760,
                "Name": "Raasa vilaasa lola",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2761,
                "Name": "Kanjanabha dhayayaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2762,
                "Name": "Pankaja lochana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2763,
                "Name": "Dhayaayaami Sree raghu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Khanda Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2764,
                "Name": "Gopalaka pahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Mishra Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2765,
                "Name": "Bhava Dheeyakathaabhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Navaratna malika keerthanam (Shravanam)",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2766,
                "Name": "Thavaka naamaani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Jhampai",
                "Composer": "Swathi Thirunal",
                "Group": "Navaratna malika keerthanam (Keerthanam)",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2767,
                "Name": "Sathatham samsmaraaNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Group": "Navaratna malika keerthanam (Smaranam)",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2768,
                "Name": "Pankajaksha thava saevaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Group": "Navaratna malika keerthanam (Padasevanam)",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2769,
                "Name": "Aaraadhayaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Mishra Chapu",
                "Composer": "Swathi Thirunal",
                "Group": "Navaratna malika keerthanam (Archanam)",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2770,
                "Name": "Vandae Deva Deva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Group": "Navaratna malika keerthanam (Vandanam)",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2771,
                "Name": "Paramapursha nannu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahiri",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Group": "Navaratna malika keerthanam (Dasyam)",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2772,
                "Name": "Bhavathi vishvaaso",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Triputa",
                "Composer": "Swathi Thirunal",
                "Group": "Navaratna malika keerthanam (Sakhyam)",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2773,
                "Name": "Deva Deva kalpayaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Group": "Navaratna malika keerthanam (Atmanivedhanam)",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2774,
                "Name": "Kamalajaasya (Mohanam, Bilahari, Dhanyasi, Saranga, Madhyamavathi, Atana, Naatakurinji, Darbar, AnandaBhairavi, Sowrashtram)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Dasavathara Ragamalika",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2775,
                "Name": "Bhavayami Raghuramam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Group": "Ramayana keerthanam",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2776,
                "Name": "Saa nandam (KamalaManohari, Hamsadhwani, Revagupti, Tarangini)",
                "Type": "Padyam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Chatur Ragamalika",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2777,
                "Name": "KalyaaNi kaluyath gathaa (Kalyani, Mohanam, Saranga, Shankarabharanam)",
                "Type": "Padyam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Group": "Chatur Ragamalika",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2778,
                "Name": "Raajeevaaksha bharo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2779,
                "Name": "Saliyae kunjana mo dhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BrindavanaSaranga",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Hindusthani Krithi",
                "Song Language": "Hindi",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2780,
                "Name": "Daevana kae pathi indra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa (Hindusthani)",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Hindusthani Krithi",
                "Song Language": "Hindi",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2781,
                "Name": "Ramachandra prabhu ",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi (Hindusthani)",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Hindusthani Krithi",
                "Song Language": "Hindi",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2782,
                "Name": "Vishweshwara Darshana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi (Hindusthani)",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Group": "Hindusthani Krithi",
                "Song Language": "Hindi",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2783,
                "Name": "Soosa ganga bhasma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanashree",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Group": "Hindusthani Krithi",
                "Song Language": "Hindi",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2784,
                "Name": "Dwiratha vadhana naakum",
                "Type": "Slokam",
                "Composer": "Swathi Thirunal",
                "Group": "Utsava Prabandam",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2785,
                "Name": "Pankaja naabho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Group": "Utsava Prabandam",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2786,
                "Name": "Lokaabhi raama thanuvaam",
                "Type": "Slokam",
                "Composer": "Swathi Thirunal",
                "Group": "Utsava Prabandam",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2787,
                "Name": "Pancha saayaka (Utsavam 1)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Utsava Prabandam",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2788,
                "Name": "Pankajaakshanaam (Utsavam 2)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Group": "Utsava Prabandam",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2789,
                "Name": "Kanaka mayamaayeedum (Utsavam 3)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Group": "Utsava Prabandam",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2790,
                "Name": "Andolika vaahanae (Utsavam 4)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Group": "Utsava Prabandam",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2791,
                "Name": "Syaanandhu raeshvaran (Utsavam 5)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurunji",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Utsava Prabandam",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2792,
                "Name": "Indiraapathi (Utsavam 6)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaroj",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Group": "Utsava Prabandam",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2793,
                "Name": "Shibhi kayilaezhundhu (Utsavam 7)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MangalaKaishiki",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Group": "Utsava Prabandam",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2794,
                "Name": "Neelapuri kuzhalaazho (Utsavam 8)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Group": "Utsava Prabandam",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2795,
                "Name": "Sharadhinthi samamukha (Utsavam 9)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Group": "Utsava Prabandam",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2796,
                "Name": "Naagashayananaam (Utsavam 10)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Group": "Utsava Prabandam",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2797,
                "Name": "Sarasijanaabhanin (Samaapanam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Chapu",
                "Composer": "Swathi Thirunal",
                "Group": "Utsava Prabandam",
                "Song Language": "Sanskrit",
                "BookTitle": "Maharaja Swathi Thirunal Kritis - Part 1",
                "BookAuthor": "Swathi Thirunal",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2798,
                "Name": "Ninnukori",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2799,
                "Name": "Nera nammithi",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanadaa",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2800,
                "Name": "Vanajaakshaa",
                "Type": "Varnam-Tana",
                "Ragam": "Varali",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2801,
                "Name": "Sami ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Narayani",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2802,
                "Name": "Thaani kori kaenu",
                "Type": "Varnam-Pada",
                "Ragam": "Vasantha",
                "Thalam": "Jhampai",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2803,
                "Name": "Saamaja varadhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Roopakam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2804,
                "Name": "Parama paavana raama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PoorviKalyani",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2805,
                "Name": "Saraguna paalimpa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2806,
                "Name": "Nee kaelanaayada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2807,
                "Name": "Nee paadhamulae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NavarasaKannadaa",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2808,
                "Name": "Sath bhakthiyu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2809,
                "Name": "Sree raghukula nithim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2810,
                "Name": "Anudhinamunu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2811,
                "Name": "Sree venkatesam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Tisra Jathi Eka Thalam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2812,
                "Name": "Paarthasaarathi naanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2813,
                "Name": "Paripaalaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2814,
                "Name": "Sathguru swamiki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2815,
                "Name": "Samayamidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Roopakam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2816,
                "Name": "Ninnukolisi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2817,
                "Name": "Rama Ninne",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2818,
                "Name": "Parula seva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NataBhairavi",
                "Thalam": "Roopakam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2819,
                "Name": "Nijamukha raama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2820,
                "Name": "Raghunatha nannu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SuraRanjani",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2821,
                "Name": "KaruNadhO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MeghaRanjani",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2822,
                "Name": "Vaega neevu",
                "Type": "Javali",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2823,
                "Name": "Nirupamaana",
                "Type": "Javali",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2824,
                "Name": "Marulukonnadhira",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2825,
                "Name": "Sarasamu",
                "Type": "Javali",
                "Ragam": "HindusthaniKapi",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2826,
                "Name": "Paras Thillana",
                "Type": "Thillana",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2827,
                "Name": "Poornachandrika Thillana",
                "Type": "Thillana",
                "Ragam": "Poornachandrika",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2828,
                "Name": "Thodi Thillana",
                "Type": "Thillana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2829,
                "Name": "Yadukula Khambhoji Thillana",
                "Type": "Thillana",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2830,
                "Name": "Kaanada Thillana",
                "Type": "Thillana",
                "Ragam": "Kaanadaa",
                "Thalam": "Roopakam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2831,
                "Name": "Kapi Thillana",
                "Type": "Thillana",
                "Ragam": "Kapi",
                "Thalam": "Lakshmeesa Thalam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2832,
                "Name": "Sami nee pai (Sarasangi, Saveri, Navaroj, Lalitha, Varali, Arabhi, Khamas, Bhupalam, Sree)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
                "Group": "Navaratna Malika",
                "BookTitle": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
                "BookAuthor": "Ramnad (Poochi) Srinivasa Iyengar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2833,
                "Name": "Koothaadum Ganapathiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2834,
                "Name": "Karpagambikae kanakangi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kanakangi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-1",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2835,
                "Name": "Ithanai azhago amma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rathnangi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-2",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2836,
                "Name": "VeNu gaana moorthy",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ganamurti",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-3",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2837,
                "Name": "punnai vanaspathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vanaspathi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-4",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2838,
                "Name": "manamirangavillaiyaa manavati",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manavathi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-5",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2839,
                "Name": "Taana roopi neeyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tanarupi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-6",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2840,
                "Name": "Sokkanudan porudhum senavathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Senavathi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-7",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2841,
                "Name": "parindhOdi vandhaen karpagamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-8",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2842,
                "Name": "Kaama Dhaenu karpagamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhenuka",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-9",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2843,
                "Name": "Ambala koothanin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natakapriya",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-10",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2844,
                "Name": "Maangkuyil koovidum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokilapriya",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-11",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2845,
                "Name": "Adhi roopavathi neeyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Roopavathi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-12",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2846,
                "Name": "Gaana gaayakapriya karpagamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gayakapriya",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-13",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2847,
                "Name": "vakuLabharanam aNi vadivaambikae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-14",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2848,
                "Name": "Thaayaana karpagamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-15",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2849,
                "Name": "marandhuyir vaazhvaeno",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-16",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2850,
                "Name": "kodaakodi sooryakaantham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suryakantham",
                "Thalam": "Roopakam",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-17",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2851,
                "Name": "Poompattu ponpaavaadai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hatakambheri",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-18",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2852,
                "Name": "Singaara bhavani vanDhaaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jhankaradhwani",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-19",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2853,
                "Name": "Aananda natanam aadum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NataBhairavi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-20",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2854,
                "Name": "thanjam aDaindhaen thaayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-21",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2855,
                "Name": "arahara mahaadevan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-22",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2856,
                "Name": "MangaLa gowri manohari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-23",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2857,
                "Name": "tharuNamidhuvae Arul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varunapriya",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-24",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2858,
                "Name": "Maararanjani neeyumallavo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MaraRanjani",
                "Thalam": "Roopakam",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-25",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2859,
                "Name": "yaarumilaen charukesi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-26",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2860,
                "Name": "Sarasam puriyum sarasangi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-27",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2861,
                "Name": "karpoorahaarathiyin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-28",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2862,
                "Name": "Thirumayilai vaLarum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-29",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2863,
                "Name": "paagaai manam urugi paadinaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naganandhini",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-30",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2864,
                "Name": "Paadha pankaja malar nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yagapriya",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-31",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2865,
                "Name": "Innisai poo raagam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragavardhini",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-32",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2866,
                "Name": "Kandhanukku vael koduththa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gangeyabhushani",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-33",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2867,
                "Name": "vaakaruLvaai vaagadheeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vagadeeshwari",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-34",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2868,
                "Name": "Thiru mayilai vaLarum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Soolini",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-35",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2869,
                "Name": "parvathaa chalanaaDan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chalanaatai",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-36",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2870,
                "Name": "arumbu malar yaeduthu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Salagam",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-37",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2871,
                "Name": "karuNai kadalae abiraami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jhalarnavam",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-38",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2872,
                "Name": "maayaajhaalavarali",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jhalavarali",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-39",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2873,
                "Name": "veNNai pol manam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaneetham",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-40",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2874,
                "Name": "Natanam aadum navarasa pavani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pavani",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-41",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2875,
                "Name": "sooriyan thudhiththidum maariyamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Raghupriya",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-42",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2876,
                "Name": "Shantha nayakiyae charaNam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gavambhodhi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-43",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2877,
                "Name": "saambhavapriya manohaariNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavapriya",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-44",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2878,
                "Name": "Dheenabandhu neeyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-45",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2879,
                "Name": "thathuvamaanavaLae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shadvidhamaargini",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-46",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2880,
                "Name": "swarnamayamaana swarnaanghi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suvarnaangi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-47",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2881,
                "Name": "thirumayilai kabaali",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Divyamani",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-48",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2882,
                "Name": "thaayae dhavalaambari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhavalambari",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-49",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2883,
                "Name": "maamayilaapuri pooraNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Namanarayani",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-50",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2884,
                "Name": "kamavardhini karpagambikae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-51",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2885,
                "Name": "Sethuramapriya madhu karpagamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-52",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2886,
                "Name": "ennai kaakamanashrama maeno",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gamanashrama",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-53",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2887,
                "Name": "akilaanda kodi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vishvambari",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-54",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2888,
                "Name": "maamayilai shyamaLaanghi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShyamaLanghi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-55",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2889,
                "Name": "kandanukku vel koduththa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-56",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2890,
                "Name": "simhendramadhyama vaahiNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-57",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2891,
                "Name": "imayamalaippadhi arasan pudhalviyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Mishra Chapu",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-58",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2892,
                "Name": "Dharma samvardhini Dharmavathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-59",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2893,
                "Name": "neethimathi maa mayilai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neethimathi",
                "Thalam": "Khanda Chapu",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-60",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2894,
                "Name": "Kaanthaamani hariNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kanthamani",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-61",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2895,
                "Name": "Nandhi Rishapriya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rishabhapriya",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-62",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2896,
                "Name": "Kabaaliyai thaLuvum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-63",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2897,
                "Name": "mayilapuri vachaspathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Roopakam",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-64",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2898,
                "Name": "mayilai kabali",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-65",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2899,
                "Name": "Chithira poompaavadai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chithrambari",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-66",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2900,
                "Name": "Sundari karpaga sucharithra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sucharithra",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-67",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2901,
                "Name": "AruL JyothiswaroopiNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jyothiswaroopini",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-68",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2902,
                "Name": "maadhu karpaga thadhuvardhini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thadhuvardhini",
                "Thalam": "Roopakam",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-69",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2903,
                "Name": "Muthu mookuthi alankari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nasikabhushani",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-70",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2904,
                "Name": "Kosalam aanDiDum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kosalam",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-71",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2905,
                "Name": "Gaana rasikapriya maana karpagamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rasikapriya",
                "Thalam": "Adi",
                "Composer": "VeeraMani Iyer",
                "Group": "72 melakartha krithis on Karpagambal-72",
                "Song Language": "Tamil",
                "BookTitle": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
                "BookAuthor": "VeeramaniAiyar (yazhpanam)",
                "Year of Publication": 2000,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2906,
                "Name": "varuvaar varuvaar endraen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2907,
                "Name": "thaiyalae unai ninaindhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Subbaramayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2908,
                "Name": "YaengaaNum varavara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2909,
                "Name": "ellaam arivaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2910,
                "Name": "Eththai kaNdu nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Subbaramayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2911,
                "Name": "Eththanai sonnalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2912,
                "Name": "Padhari varugudhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2913,
                "Name": "Ivan yaaro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Azhagar Kuravanji",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2914,
                "Name": "Manadhariyaamal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Muthuthandavar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2915,
                "Name": "Nadamaadi thirindha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Jhampai",
                "Composer": "Papavinasa Mudaliar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2916,
                "Name": "Thiruvotriyoor",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Ghanam Krishnayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2917,
                "Name": "Ennaeramum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Marimuthu Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2918,
                "Name": "Thayae Yashoda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "OothukaduVenkataKavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2919,
                "Name": "YaarPoi solluvaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2920,
                "Name": "Varuvaar azhaithuvaadi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Mishra Chapu",
                "Composer": "Ramalinga Swamigal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2921,
                "Name": "Inimael varukkum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2922,
                "Name": "Vaelavarae umaiththedi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2923,
                "Name": "Mugaththai kaatiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Papavinasa Mudaliar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2924,
                "Name": "Paeyaandi thanaik kanDu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Mishra Chapu",
                "Composer": "Gopalakrishna Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2925,
                "Name": "Ini enna paechchu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Subbaramayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2926,
                "Name": "Ennida nadathaikkum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Composer": "Subbaramayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2927,
                "Name": "Varuvaaraanaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Mishra Chapu",
                "Composer": "Subbaramayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2928,
                "Name": "Kaalaith thooki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "MarimuthaPillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2929,
                "Name": "Innamum orudharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2930,
                "Name": "Yaedhukkithanai moDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "MarimuthaPillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2931,
                "Name": "Thaanae varugiraaro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Mishra Chapu",
                "Composer": "Subbaramayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2932,
                "Name": "Un Adhrishtam swami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Mishra Chapu",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2933,
                "Name": "Natanam adinaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2934,
                "Name": "Adhuvum solluvaaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Subbaramayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2935,
                "Name": "Theruvil vaaraano",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi (Tisra Gathi)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2936,
                "Name": "Sevikka vaeNdum aiyyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2937,
                "Name": "Adi kondar andha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi (Khanda Gathi)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2938,
                "Name": "Arumarundhoru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Roopakam",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2939,
                "Name": "Yaen Palli kondeerayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "ArunachalaKavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2940,
                "Name": "Sundara kunjitha paadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2941,
                "Name": "Varuvaai varam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Roopakam",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2942,
                "Name": "Maayavidhai seigiranae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2943,
                "Name": "Ennai aaLaiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Mishra Chapu",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2944,
                "Name": "KaaNaamal Irundhaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Jhampai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2945,
                "Name": "Sabhapathiku vaeru deivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Roopakam",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2946,
                "Name": "Ambara Chidambara parampara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi (Khanda Gathi)",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2947,
                "Name": "Innum paaraamugam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2948,
                "Name": "Chidambaram Arahara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2949,
                "Name": "Saami yezhai naanaiyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Roopakam",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2950,
                "Name": "Bhalamandirathirkkum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Composer": "MarimuthaPillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2951,
                "Name": "AaDinadheppadiyo natanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Composer": "Muthuthandavar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2952,
                "Name": "Sivachidambaramendru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Composer": "MarimuthaPillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2953,
                "Name": "Ninaippadheppodhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Composer": "Vedanayagam Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2954,
                "Name": "KaaNaamal veeNilae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Composer": "Muthuthandavar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2955,
                "Name": "Arut Jyothi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Composer": "Ramalinga Swamigal",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2956,
                "Name": "Ennathaan sonnaaradi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Composer": "Madhurakavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2957,
                "Name": "Kaasirundhal Ingae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2958,
                "Name": "Naetru raathiripona",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Composer": "Subbaramayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2959,
                "Name": "Unnaithoodhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Composer": "Madhurakavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2960,
                "Name": "Idhai vida innumvaerae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Composer": "Subbaramayyar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2961,
                "Name": "Thathai mozhiyaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2962,
                "Name": "Innum en mael",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2963,
                "Name": "Ella ArumaigaLum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2964,
                "Name": "Nidhiraiyil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2965,
                "Name": "Yaarukkaagilum bhayamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Composer": "Madhurakavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Keerthanangal",
                "BookAuthor": "Various",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2966,
                "Name": "swamin gajamukha vibho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Praise of": "On Ganesha",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Sanskrit",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2967,
                "Name": "Swami mayuragiri vadivela",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2968,
                "Name": "AruL Paaraiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "On Vishnu",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2969,
                "Name": "Arul seiyya vaeNum vaaNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "On Saraswathi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2970,
                "Name": "Vaada raajan kumaranae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "On Hanuman",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2971,
                "Name": "KaTiyakaaran vandhaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2972,
                "Name": "Kolamigum senghaN",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2973,
                "Name": "thirubhavani vandha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2974,
                "Name": "mandara dhara maadhava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2975,
                "Name": "sengaNmaalazhagar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2976,
                "Name": "maruthodayu mizhaingar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Chapu",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2977,
                "Name": "Mohana maamayil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2978,
                "Name": "mohanavalli pandadhithanal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2979,
                "Name": "MangaLam saer solai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manji",
                "Thalam": "Roopakam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2980,
                "Name": "Ivan yaaro ariyaenae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2981,
                "Name": "Madhiyai mayakku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Triputa Thalam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2982,
                "Name": "MagaLennamo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Roopakam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2983,
                "Name": "Sandhanak kuzhambheduthani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2984,
                "Name": "Mathae yangae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2985,
                "Name": "moha minjuthaenna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2986,
                "Name": "Mangaiyae nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2987,
                "Name": "Mohanavalli minnaaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manji",
                "Thalam": "Chapu",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2988,
                "Name": "aNaiya vaarum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaroj",
                "Thalam": "Triputa Thalam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2989,
                "Name": "saEdasayanam seidhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2990,
                "Name": "Paavaiyae saami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedara Gowlai",
                "Thalam": "Roopakam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2991,
                "Name": "kadalae endranai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2992,
                "Name": "van koDumai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2993,
                "Name": "madhana unnakkaenindha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2994,
                "Name": "malaik kuravanji",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2995,
                "Name": "Aavin kandrai madimisai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Tisra Nadai",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2996,
                "Name": "Engal saariyondrae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2997,
                "Name": "theertha mahimayai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2998,
                "Name": "Kaarulaaviya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Chapu",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 2999,
                "Name": "ammaiyenkurith thiramai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3000,
                "Name": "Thanikkuri yuraikkirae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Chapu",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3001,
                "Name": "paar mezhughi kolam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Ata thalam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3002,
                "Name": "Ankuliya mitta",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3003,
                "Name": "vaalaiarul thiru maganae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3004,
                "Name": "mukkiyamaayi kuriyai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Eka thalam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3005,
                "Name": "pootaganj seikiraai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Roopakam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3006,
                "Name": "Vandhu seruvaar maanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Chapu",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3007,
                "Name": "thapip poda manmadhaaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Ata thalam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3008,
                "Name": "MaruthuLabha azhagar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Chapu",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3009,
                "Name": "solaimalai singanaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Roopakam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3010,
                "Name": "Thiru maalazhagar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Triputa Thalam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3011,
                "Name": "Ambinai thoduthu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3012,
                "Name": "paravaigal varugudhaiya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Chapu",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3013,
                "Name": "kuruginam pidikkavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Chapu",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3014,
                "Name": "paesaathadaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Ata thalam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3015,
                "Name": "mangaiyar panai kaatum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Chapu",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3016,
                "Name": "parandhodip poyinavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "YamunaKalyani",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3017,
                "Name": "azhaithu vaadaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3018,
                "Name": "thaedich chendraanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3019,
                "Name": "Singhi enganam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Roopakam",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3020,
                "Name": "Chekka sivandha nirathaaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nondhi sindhu",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3021,
                "Name": "thirumaal azhagarukku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Kavi Kunjara Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Azhagar Kuravanji",
                "BookAuthor": "Kavi Kunjara Bharathi",
                "Year of Publication": 1963,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3022,
                "Name": "Sricha puthraya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kanakangi",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3023,
                "Name": "Sri gurum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rathnangi",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3024,
                "Name": "Paahi jagadeeshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ganamurti",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3025,
                "Name": "Eeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vanaspathi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3026,
                "Name": "Sree Hanumantham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manavathi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3027,
                "Name": "Sree Raamam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tanarupi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3028,
                "Name": "Jaalamela",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Senavathi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3029,
                "Name": "Tyagaraja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3030,
                "Name": "Raavaiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhenuka",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3031,
                "Name": "Paripaalayamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natakapriya",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3032,
                "Name": "Vaadha mela",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokilapriya",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3033,
                "Name": "Paalayamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Roopavathi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3034,
                "Name": "Sree Mahavishnum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gayakapriya",
                "Thalam": "Khanda jathi Jhampa Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3035,
                "Name": "Kumaaruni valaenu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3036,
                "Name": "Shyamalambikae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3037,
                "Name": "Girija Pathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3038,
                "Name": "Karuninchara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suryakantham",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3039,
                "Name": "Rakshaasumaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hatakambheri",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3040,
                "Name": "Jashakedhana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jhankaradhwani",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3041,
                "Name": "Nalina nayani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NataBhairavi",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3042,
                "Name": "Sree Dakshinaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3043,
                "Name": "Parameshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3044,
                "Name": "Smararaechitha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3045,
                "Name": "Samaashrayaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varunapriya",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3046,
                "Name": "Ramaapathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MaraRanjani",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3047,
                "Name": "Paradeveem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3048,
                "Name": "Vandeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Mishra Chapu",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3049,
                "Name": "Smara maanasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3050,
                "Name": "Sree Subramanyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3051,
                "Name": "Daakshaayani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naganandhini",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3052,
                "Name": "Yashodeyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yagapriya",
                "Thalam": "Chatusra Jathi Jhampa Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3053,
                "Name": "Gaanasudhaarasamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragavardhini",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3054,
                "Name": "Paalayashumaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gangeyabhushani",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3055,
                "Name": "Pranamaamyaham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vagadeeshwari",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3056,
                "Name": "Paalayaashumaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Soolini",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3057,
                "Name": "Nakaathmaja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chalanaatai",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3058,
                "Name": "Vaarana vadhanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Salagam",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3059,
                "Name": "Maheshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jalaarnavam",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3060,
                "Name": "Maadhava dhayayaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaalavarali",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3061,
                "Name": "Himaathmajae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaneetham",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3062,
                "Name": "Jayadanadasaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pavani",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3063,
                "Name": "Kathilaka vamsa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Raghupriya",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3064,
                "Name": "Vinadhisae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gavambhodhi",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3065,
                "Name": "Maadhava maamava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavapriya",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3066,
                "Name": "Karunanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3067,
                "Name": "Himavatheem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shadvidhamaargini",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3068,
                "Name": "Sree Raghupathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suvarnaangi",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3069,
                "Name": "Nowmithaavakeena",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Divyamani",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3070,
                "Name": "Sree Vaani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhavalambari",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3071,
                "Name": "Mahadeva paahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Namanarayani",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3072,
                "Name": "Charanam thava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kamavardhini/Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3073,
                "Name": "Mahadevam anisham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Khanda Chapu",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3074,
                "Name": "Enni maarulu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gamanashrama",
                "Thalam": "Mishra Chapu",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3075,
                "Name": "Bho Shambho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vishvambari",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3076,
                "Name": "Shyamalangi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShyamaLanghi",
                "Thalam": "Chatusra Jathi Jhampa Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3077,
                "Name": "Sada thava paadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3078,
                "Name": "Maamava maayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3079,
                "Name": "Tharunamidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3080,
                "Name": "Vasamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3081,
                "Name": "Smaranam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neethimathi",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3082,
                "Name": "Bhuvaneshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kanthamani",
                "Thalam": "Tisra Jathi Eka Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3083,
                "Name": "Nandeesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rishabhapriya",
                "Thalam": "Tisra Jathi Eka Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3084,
                "Name": "Taamralochani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3085,
                "Name": "Nudhindhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3086,
                "Name": "Gathi neevae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3087,
                "Name": "Sree Raama Naumi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chithrambari",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3088,
                "Name": "Chinthayami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sucharithra",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3089,
                "Name": "Sree Gayathreem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jyothiswaroopini",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3090,
                "Name": "Mahesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhathuvardhani",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3091,
                "Name": "Ambikaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nasikabhushani",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3092,
                "Name": "O Manasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kosalam",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3093,
                "Name": "Pavana thanaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rasikapriya",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "Group": "72 melakartha ragam songs",
                "BookTitle": "Janaka Raga Kruthi Manjari",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1952,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3094,
                "Name": "Saagara Sadhguna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3095,
                "Name": "Mahaadeva sutam aham pranamami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3096,
                "Name": "Aayakalaikkellam arasiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3097,
                "Name": "Sree Dakshinamurthim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3098,
                "Name": "Nee daya raadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PoorviKalyani",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3099,
                "Name": "Hari nee smaranae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3100,
                "Name": "Daakshaayani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naganandhini",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3101,
                "Name": "Amba maamava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Ranjani, Niranjani, Janaranjani)",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3102,
                "Name": "Kaavavae Kanya kumari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Chapu",
                "Praise of": "On Goddess Kanyakumari",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3103,
                "Name": "Thillana-Brindavani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Brindavani",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3104,
                "Name": "Saraguna kaavu",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3105,
                "Name": "Siddhi naayakaena",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amrithavarshini",
                "Thalam": "Jhampai",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3106,
                "Name": "Kuruni smarimpumo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsavinodini",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3107,
                "Name": "Yelae paalimpa jaala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3108,
                "Name": "Akila deva nutha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MeghaRanjani",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3109,
                "Name": "Vaegamae kaava raavaiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3110,
                "Name": "Varuga Varuga maamayilaeriyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3111,
                "Name": "Bruhadeeshwara Mahadeva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3112,
                "Name": "Pavana thanaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rasikapriya",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3113,
                "Name": "Thillana-Hindolam",
                "Type": "Thillana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3114,
                "Name": "Aa baala gopaalamu",
                "Type": "Varnam-Tana",
                "Ragam": "Amrithavarshini",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3115,
                "Name": "Pirai aniyum perumaan maindhanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3116,
                "Name": "Gaana sudhaa rasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3117,
                "Name": "Vasamaa nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3118,
                "Name": "Shankarabharana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3119,
                "Name": "Nandeesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rishabhapriya",
                "Thalam": "Tisra Jathi Eka Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3120,
                "Name": "Thora vaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3121,
                "Name": "Thaamralochani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3122,
                "Name": "Gaana maalinchi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyanavasantham",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3123,
                "Name": "Thillana-Chakravaham",
                "Type": "Thillana",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3124,
                "Name": "Ninnu nera nammithi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3125,
                "Name": "Mahaneeya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sumukham",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3126,
                "Name": "Mahaneeya Madhoo moorthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mahathi",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3127,
                "Name": "Sadaa thava paada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3128,
                "Name": "Kamaladalaaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3129,
                "Name": "Endha naeramum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3130,
                "Name": "Aayar kula seyaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3131,
                "Name": "Gaana rasikae jagaana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3132,
                "Name": "Bangaru murali shringara ravali",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Jhampai",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3133,
                "Name": "Thillana-Dwijavanthi",
                "Type": "Thillana",
                "Ragam": "Dwijaavanthi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3134,
                "Name": "Omkaara pranava",
                "Type": "Varnam-Tana",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3135,
                "Name": "Paahiprabho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3136,
                "Name": "Saama Gaana Saarva bhowma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amrithavarshini",
                "Thalam": "Roopakam",
                "Praise of": "On Tyagaraja Swami",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3137,
                "Name": "Kannanaenum podhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3138,
                "Name": "Charanam Thava Charanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3139,
                "Name": "Gathi neevae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3140,
                "Name": "Sree Kaarunya roopini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Roopakam",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3141,
                "Name": "Mannavan ennai pirindhae",
                "Type": "Padam",
                "Ragam": "Neelambari",
                "Thalam": "Triputa",
                "Composer": "Dr M Balamurali Krishna",
                "Song Language": "Tamil",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3142,
                "Name": "Endrum thunai neeyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3143,
                "Name": "Mangalam Himathanayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Mishra Chapu",
                "Composer": "Dr M Balamurali Krishna",
                "BookTitle": "Murali Ravali",
                "BookAuthor": "Dr. M. Balamurali krishna",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3144,
                "Name": "Needaya radha neerajakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3145,
                "Name": "Karimukha varada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Praise of": "On Ganesha",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3146,
                "Name": "Saraswathi Namousthuthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Roopakam",
                "Praise of": "On Saraswathi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3147,
                "Name": "Nee Paadhamae Gathi Nalina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nalinakanthi",
                "Thalam": "Roopakam",
                "Praise of": "On Goddess Kanthimathi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3148,
                "Name": "Niradhamukha ninne sharanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Udayaravichandrika",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3149,
                "Name": "Nee charanaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3150,
                "Name": "Sadaa paalaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3151,
                "Name": "Paada Bhakana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3152,
                "Name": "Paaraamukha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3153,
                "Name": "Ninnuvinaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malavi",
                "Thalam": "Roopakam",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3154,
                "Name": "Sivaananda Kaamavardhini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Mishra Chapu",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3155,
                "Name": "Maravanunae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3156,
                "Name": "Nee allal inni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3157,
                "Name": "Naagabhaya vara ",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Roopakam",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3158,
                "Name": "Shankara mahadeva manohari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Praise of": "On Goddess Parvathi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3159,
                "Name": "Maarakoti sundari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3160,
                "Name": "Neesamaana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3161,
                "Name": "Nee daya galkunae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3162,
                "Name": "Ranjani niranjani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3163,
                "Name": "Porumaiyai izhandhaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Praise of": "On Goddess Parvathi-Bhuvaneshwari",
                "Composer": "GN Balasubramanian",
                "Song Language": "Tamil",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3164,
                "Name": "Kamalaasani Sundari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3165,
                "Name": "Taamasa miga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Roopakam",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3166,
                "Name": "Gathiverevaramma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3167,
                "Name": "Thillana-Hamsanandi",
                "Type": "Thillana",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "Gaana Bhaskara Manimaalai",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1956,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3168,
                "Name": "Namaami Sriman mahadevendra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chandrachuda",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Some rare and unpublished keerthanas of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3169,
                "Name": "Sri Ramana Vijitha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NarayanaGowlai",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Some rare and unpublished keerthanas of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3170,
                "Name": "Thappaga Nannu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Some rare and unpublished keerthanas of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3171,
                "Name": "Sthri veshamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Some rare and unpublished keerthanas of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3172,
                "Name": "Saagara kanyaga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Some rare and unpublished keerthanas of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3173,
                "Name": "Sri Raghukula jalathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Some rare and unpublished keerthanas of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3174,
                "Name": "Kamalakaantha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalitha",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Some rare and unpublished keerthanas of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3175,
                "Name": "Devadideva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Roopakam",
                "Praise of": "On Rama",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3176,
                "Name": "Na Tarama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "On Rama",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3177,
                "Name": "Pannagasayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Praise of": "On Rama",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3178,
                "Name": "Endu Dachu-konnavu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Praise of": "On Rama",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3179,
                "Name": "Nami Sree Sathyavijaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "On Sri Sathya Vijayatirthar",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3180,
                "Name": "Samrajya Daya Kesha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "On Kanchipuram Ekambreshwarar",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3181,
                "Name": "Vachamagocha-rudani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "On Triplicane Parthasarathy perumal",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3182,
                "Name": "Ninnu nera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Praise of": "On Rama",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3183,
                "Name": "Ninnu vinaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balahamsa",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3184,
                "Name": "Bhajanaseyavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Roopakam",
                "Praise of": "On Rama",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3185,
                "Name": "Vanajaksha ninne",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GambiraNattai",
                "Thalam": "Adi",
                "Praise of": "On Rama",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3186,
                "Name": "Nikepudu daya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Praise of": "On Rama",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3187,
                "Name": "Narasimhudu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KamalaManohari",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Praise of": "On Narasimha",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3188,
                "Name": "Saketha Nagara Natha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Praise of": "On Rama",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3189,
                "Name": "Sri Parthasarathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Praise of": "On Triplicane Parthasarathy perumal",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3190,
                "Name": "Paramaatbuthamaina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Praise of": "On Sriram Ranganathar",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3191,
                "Name": "Sree Raamabhi raama",
                "Type": "Swarajathi",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Songs of Mysore Sadashiva Rao",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": 1947,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3192,
                "Name": "Thillana-Poorvikalyani",
                "Type": "Thillana",
                "Ragam": "PoorviKalyani",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3193,
                "Name": "Kanugoni",
                "Ragam": "Kalyani",
                "Praise of": "On Kanchipuram Kamakshi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3194,
                "Name": "Kamalaakaanta susvanta",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3195,
                "Name": "Peddadevudani ninnu nammitira",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3196,
                "Name": "Emaguva Bodhinchera",
                "Type": "Varnam-Pada",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "On Krishnarajendra Wodeyar",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3197,
                "Name": "Rajarajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3198,
                "Name": "Sri Ramana neeve gatiyani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PoornaShadjam",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3199,
                "Name": "Dorekanu nedu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhari",
                "Thalam": "Adi",
                "Praise of": "On Lord Krishna",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3200,
                "Name": "Sri Kamakoti Peetasthithe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "On Kanchipuram Kamakshi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3201,
                "Name": "Manamuleda nayada neeku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Praise of": "On Lord Krishna",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3202,
                "Name": "Tyagaraja swami vedalina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Praise of": "On Tyagaraja Swami",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3203,
                "Name": "Sri Lakshmiramana paramaananda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "On Sriram Ranganathar",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3204,
                "Name": "Ramuni nammina janulaku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PoorviKalyani",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3205,
                "Name": "Rama ninnu nammina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Triputa",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3206,
                "Name": "Sri Subramanya Lokasharanya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "On Palani Subramanyar",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3207,
                "Name": "Rama kathaa saagarameede",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3208,
                "Name": "Samayamidae nannu brova",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3209,
                "Name": "Mrokkeda nee padamulaku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3210,
                "Name": "Mangalam Sri Ramanukku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3211,
                "Name": "Bhujallarae pundarikakshinupai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3212,
                "Name": "Dorikenu needu srikrishnudu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3213,
                "Name": "Emani varnintunamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "No Book Reference",
                "BookAuthor": "Mysore Sadashiva Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3214,
                "Name": "Maha Ganapathy",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Sankeerna Chapu",
                "Praise of": "On Ganesha",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3215,
                "Name": "Maal marugan Murugan",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3216,
                "Name": "Natajanapaalini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Praise of": "On Parvathi",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3217,
                "Name": "Pada sarojamula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Praise of": "On Parvathi",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3218,
                "Name": "Sharavana Bhava guha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3219,
                "Name": "Porutharulvaayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Praise of": "On Bhoomaadevi",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3220,
                "Name": "Paripoorana Chandra vadhani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Adi",
                "Praise of": "On Kamakshi",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3221,
                "Name": "Muruga thirumaal marugaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Praise of": "On Muruga",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3222,
                "Name": "Manasaara MadhiyaNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saaraamathi",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3223,
                "Name": "Ezhiludai hamsanadam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanadam",
                "Thalam": "Adi",
                "Praise of": "On Raga Hamsanadam",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3224,
                "Name": "Geetha Vaadya natana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natakapriya",
                "Thalam": "Adi",
                "Praise of": "On Devi",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3225,
                "Name": "Mahadeva Shiva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Revathi",
                "Thalam": "Adi",
                "Praise of": "On Shiva",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3226,
                "Name": "Ranjani Maalaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Ranjani, SriRanjani, Megharanjani, Janaranjani)",
                "Thalam": "Adi",
                "Praise of": "On Devi",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3227,
                "Name": "Raama naamamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desh",
                "Thalam": "Adi",
                "Praise of": "On Rama",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3228,
                "Name": "Mandhirkugandhadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sindhubhairavi",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3229,
                "Name": "Manamalar aLiththu",
                "Type": "Javali",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3230,
                "Name": "Thillana-AnandaBhairavi",
                "Type": "Thillana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Khanda Chapu",
                "Praise of": "On Shiva",
                "Composer": "Tanjavur V Shankara Iyer",
                "BookTitle": "Tanjavur Shankara Iyer compositions",
                "BookAuthor": "Tanjavore V Shankara Iyer",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3231,
                "Name": "Shree Ganeshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "On Ganesha",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Kamakshi Navavarna - Ganesha Sthuthi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3232,
                "Name": "Vaanchhasi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "On Kanchipuram Kamakshi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Kamakshi Navavarna - Dhyana Sthuthi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3233,
                "Name": "Santatam aham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desakshi",
                "Thalam": "Adi",
                "Praise of": "On Kanchipuram Kamakshi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Kamakshi Navavarna - 1st avaranam (trailokya mohana chakram)",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3234,
                "Name": "Bhajasva shree",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Praise of": "On Kanchipuram Kamakshi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Kamakshi Navavarna - 2nd avaranam (sarvaashaa paripooraka chakram)",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3235,
                "Name": "Sarva jeeva dayaakari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Mishra Chapu",
                "Praise of": "On Kanchipuram Kamakshi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Kamakshi Navavarna - 3rd avaranam (Sarvasamkshobhana chakram)",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3236,
                "Name": "Yogayogeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Praise of": "On Kanchipuram Kamakshi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Kamakshi Navavarna - 4th avaranam (sarva sowbhaagya daayaka chakram)",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3237,
                "Name": "neela lohita",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balahamsa",
                "Thalam": "Khanda jathi Dhuruva Thalam",
                "Praise of": "On Kanchipuram Kamakshi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Kamakshi Navavarna - 5th avaranam (sarvaartha saadhaka chakram)",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3238,
                "Name": "sadaanandamayi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Sankeerna Jathi Matya Thalam",
                "Praise of": "On Kanchipuram Kamakshi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Kamakshi Navavarna - 6th avaranam (sarva rakshaakara chakram)",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3239,
                "Name": "sakala loka naayikae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Praise of": "On Kanchipuram Kamakshi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Kamakshi Navavarna - 7th avaranam (sarva rogahara chakram)",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3240,
                "Name": "sankari sree rajarajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "On Kanchipuram Kamakshi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Kamakshi Navavarna - 8th avaranam (sarva siddhi pradaayaka chakram)",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3241,
                "Name": "natajana kalpavalli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Praise of": "On Kanchipuram Kamakshi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Kamakshi Navavarna - 9th avaranam (sarva aanandamaya chakram)",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3242,
                "Name": "haladharaanujam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Praise of": "On Kanchipuram Kamakshi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Kamakshi Navavarna - Phala Sthuthi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3243,
                "Name": "Bhajanaamruta",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Sapta Rathna Kruthi - 1",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3244,
                "Name": "aganita mahimaadbhuta",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Sapta Rathna Kruthi - 2",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3245,
                "Name": "maadhava hrudhi kelini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Sapta Rathna Kruthi - 3",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3246,
                "Name": "baala sarasa muralee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Sapta Rathna Kruthi - 4",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3247,
                "Name": "jataadhara shankara deva deva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Sapta Rathna Kruthi - 5",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3248,
                "Name": "aalaavadhennaalo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Sapta Rathna Kruthi - 6",
                "Song Language": "Tamil",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3249,
                "Name": "sundaranandakumaara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "OothukaduVenkataKavi",
                "Group": "Sapta Rathna Kruthi - 7",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": 2007,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3250,
                "Name": "Vela tenparangirilolaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Lalgudi V R Gopala Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi V R Gopala Iyer",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3251,
                "Name": "Moolaadhara devaaya namasthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Lalgudi V R Gopala Iyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi V R Gopala Iyer",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3252,
                "Name": "Eththanai naaL aagum un",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gandharvamanohari",
                "Thalam": "Roopakam",
                "Composer": "Lalgudi V R Gopala Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi V R Gopala Iyer",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3253,
                "Name": "Kalangaadhiru nenjamae nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Lalgudi V R Gopala Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi V R Gopala Iyer",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3254,
                "Name": "Nandagopa baalanae nata jana paripaalanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Lalgudi V R Gopala Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi V R Gopala Iyer",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3255,
                "Name": "Tunaiyagum engaL kula deyva ganapathy",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Khanda Chapu",
                "Composer": "Lalgudi V R Gopala Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi V R Gopala Iyer",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3256,
                "Name": "Iniyaagilum undan inaiyadi malarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Lalgudi V R Gopala Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi V R Gopala Iyer",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3257,
                "Name": "Aalavaayamar meenamma adimai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manohari",
                "Thalam": "Khanda Chapu",
                "Composer": "Lalgudi V R Gopala Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi V R Gopala Iyer",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3258,
                "Name": "Taamadam tagaadhayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanakalyani",
                "Thalam": "Adi",
                "Composer": "Lalgudi V R Gopala Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi V R Gopala Iyer",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3259,
                "Name": "Aadhi mudalvaa amararkadhibaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Composer": "Lalgudi V R Gopala Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi V R Gopala Iyer",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3260,
                "Name": "Thirumurugaa yena uraiththidu dhinamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Adi",
                "Composer": "Lalgudi V R Gopala Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi V R Gopala Iyer",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3261,
                "Name": "Poovilurai vedan puyamsaer vaani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sharadabharanam",
                "Thalam": "Adi",
                "Composer": "Lalgudi V R Gopala Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi V R Gopala Iyer",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3262,
                "Name": "Saarasa dala nayana shrikrishna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Roopakam",
                "Composer": "Lalgudi V R Gopala Iyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi V R Gopala Iyer",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3263,
                "Name": "Paavanan neeyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Uttari",
                "Thalam": "Adi",
                "Composer": "Lalgudi V R Gopala Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi V R Gopala Iyer",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3264,
                "Name": "pranava mudalvanae vinaayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Roopakam",
                "Composer": "Lalgudi V R Gopala Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi V R Gopala Iyer",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3265,
                "Name": "pugalvaay neeallaal puvithanil",
                "Type": "Varnam-Tana",
                "Ragam": "Amrithavarshini",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3266,
                "Name": "Thirumaal marugaa un thirunaamam",
                "Type": "Varnam-Tana",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3267,
                "Name": "Jalajaaksha nee paadhamae",
                "Type": "Varnam-Tana",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Telugu",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3268,
                "Name": "Raamanai raghu naadanai nidam",
                "Type": "Varnam-Tana",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3269,
                "Name": "Entho premathonu",
                "Type": "Varnam-Tana",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Telugu",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3270,
                "Name": "Arunodayamae anbin",
                "Type": "Varnam-Tana",
                "Ragam": "Bowli",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3271,
                "Name": "Devi un paadamae paNindom",
                "Type": "Varnam-Tana",
                "Ragam": "Devagandhari",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3272,
                "Name": "Parama karunaa saagara",
                "Type": "Varnam-Tana",
                "Ragam": "Garudadhwani",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Telugu",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3273,
                "Name": "Innum taamadameno dayapariyae",
                "Type": "Varnam-Tana",
                "Ragam": "HamirKalyani",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3274,
                "Name": "Engum nirai deivamae",
                "Type": "Varnam-Tana",
                "Ragam": "Hamsavinodini",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3275,
                "Name": "Kannanai manivannanai",
                "Type": "Varnam-Tana",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3276,
                "Name": "Unnaiandri veraar ulaginil",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3277,
                "Name": "Inta Tamasa",
                "Type": "Varnam-Tana",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Telugu",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3278,
                "Name": "Nivegani verevarunnaaru",
                "Type": "Varnam-Tana",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Telugu",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3279,
                "Name": "Vallabhai nayaka vandanam",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanakalyani",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3280,
                "Name": "Karunai puriya vaenum kamakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3281,
                "Name": "Nivae gatiyani niratamu",
                "Type": "Varnam-Tana",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Telugu",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3282,
                "Name": "Tharunam en thaye",
                "Type": "Varnam-Tana",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3283,
                "Name": "Parivudan Bhakthargal",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3284,
                "Name": "Naada swaroopini un paadam panivom",
                "Type": "Varnam-Tana",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3285,
                "Name": "Chalamu seya",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Telugu",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3286,
                "Name": "Nambum anbarkkarulum",
                "Type": "Varnam-Tana",
                "Ragam": "Varamu",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3287,
                "Name": "Innam en manam",
                "Type": "Varnam-Pada",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3288,
                "Name": "Chendhil nagar mevum",
                "Type": "Varnam-Pada",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3289,
                "Name": "Angayarkkanni anandam - Navarasa Varnam",
                "Type": "Varnam-Pada",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Group": "Navarasa varnam",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3290,
                "Name": "Devar munivar thozhum",
                "Type": "Varnam-Pada",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3291,
                "Name": "Sree jagadeeshwari durga maata",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahir Bhairav",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Sanskrit",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3292,
                "Name": "Nee dayai seiyyavidil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3293,
                "Name": "Vinakayunnadela sada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Telugu",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3294,
                "Name": "Thenmadurai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsaroopini",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3295,
                "Name": "Kandan seyal andro yaavum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Roopakam",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3296,
                "Name": "Tharizhaippadhi en iyalbandro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragavardhini",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3297,
                "Name": "Unnaiandru veror pugalum undo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saaraamathi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3298,
                "Name": "Kumaara guruguham bhaavayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Sanskrit",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3299,
                "Name": "Thillana-AnandaBhairavi",
                "Type": "Thillana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Telugu",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3300,
                "Name": "Thillana-Behag",
                "Type": "Thillana",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3301,
                "Name": "Thillana-Bhageshri",
                "Type": "Thillana",
                "Ragam": "Bhageshri",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3302,
                "Name": "Thillana-Bhimplas",
                "Type": "Thillana",
                "Ragam": "Bhimplas",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3303,
                "Name": "Thillana-Bindumalini",
                "Type": "Thillana",
                "Ragam": "Bindumalini",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3304,
                "Name": "Thillana-Brindavani",
                "Type": "Thillana",
                "Ragam": "Brindavani",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3305,
                "Name": "Thillana-Chenchurutti",
                "Type": "Thillana",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3306,
                "Name": "Thillana-DarbariKaanada",
                "Type": "Thillana",
                "Ragam": "DarbariKaanada",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3307,
                "Name": "Thillana-Desh",
                "Type": "Thillana",
                "Ragam": "Desh",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3308,
                "Name": "Thillana-Durga",
                "Type": "Thillana",
                "Ragam": "Durga",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Sanskrit",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3309,
                "Name": "Thillana-Dwijavanthi",
                "Type": "Thillana",
                "Ragam": "Dwijaavanthi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Sanskrit",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3310,
                "Name": "Thillana-Hameer Kalyani",
                "Type": "Thillana",
                "Ragam": "HamirKalyani",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Telugu",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3311,
                "Name": "Thillana-Hamsanandi",
                "Type": "Thillana",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3312,
                "Name": "Thillana-Kathanakuthuhalam",
                "Type": "Thillana",
                "Ragam": "Kathanakuthuhalam",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3313,
                "Name": "Thillana-Kalyanavasantham",
                "Type": "Thillana",
                "Ragam": "Kalyanavasantham",
                "Thalam": "Khanda Chapu",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3314,
                "Name": "Thillana-Khamas",
                "Type": "Thillana",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3315,
                "Name": "Thillana-Kaanadaa",
                "Type": "Thillana",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3316,
                "Name": "Thillana-Kapi",
                "Type": "Thillana",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3317,
                "Name": "Thillana-Karnaranjani",
                "Type": "Thillana",
                "Ragam": "KarnaRanjani",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3318,
                "Name": "Thillana-Kuntalavarali",
                "Type": "Thillana",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3319,
                "Name": "Thillana-Madhuvanthi",
                "Type": "Thillana",
                "Ragam": "Madhuvanthi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3320,
                "Name": "Thillana-Mishra Maand",
                "Type": "Thillana",
                "Ragam": "Mishra Maand",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3321,
                "Name": "Thillana-Mishra Sivaranjani",
                "Type": "Thillana",
                "Ragam": "Mishra Sivaranjani",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3322,
                "Name": "Thillana-Mohanakalyani",
                "Type": "Thillana",
                "Ragam": "Mohanakalyani",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Sanskrit",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3323,
                "Name": "Thillana-Nagaswarali",
                "Type": "Thillana",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3324,
                "Name": "Thillana-Nalinakanthi",
                "Type": "Thillana",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3325,
                "Name": "Thillana-Pahadi",
                "Type": "Thillana",
                "Ragam": "Pahadi",
                "Thalam": "Mishra Chapu",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Sanskrit",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3326,
                "Name": "Thillana-Priyadarshini",
                "Type": "Thillana",
                "Ragam": "Priyadarshini",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3327,
                "Name": "Thillana-Rageshri",
                "Type": "Thillana",
                "Ragam": "Rageshri",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Telugu",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3328,
                "Name": "Thillana-Revathi",
                "Type": "Thillana",
                "Ragam": "Revathi",
                "Thalam": "Mishra Chapu",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3329,
                "Name": "Thillana-Sindhu Bhairavi",
                "Type": "Thillana",
                "Ragam": "Sindhubhairavi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3330,
                "Name": "Thillana-Thilang",
                "Type": "Thillana",
                "Ragam": "Thilang",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3331,
                "Name": "Thillana-Vasantha",
                "Type": "Thillana",
                "Ragam": "Vasantha",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Telugu",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3332,
                "Name": "Thillana-Vaasanthi",
                "Type": "Thillana",
                "Ragam": "Vaasanthi",
                "Thalam": "Mishra Chapu",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3333,
                "Name": "Thillana-YamunaKalyani",
                "Type": "Thillana",
                "Ragam": "YamunaKalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3334,
                "Name": "Jathiswaram-Behag",
                "Type": "Jathiswaram",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3335,
                "Name": "Pushpanjali-Gambhiranaatai",
                "Type": "Pushpanjali",
                "Ragam": "GambiraNattai",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3336,
                "Name": "Jathiswaram-Rasikapriya",
                "Type": "Jathiswaram",
                "Ragam": "Rasikapriya",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3337,
                "Name": "Jathiswaram-Sindhubhairavi",
                "Type": "Jathiswaram",
                "Ragam": "Sindhubhairavi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Lalgudi's Compositions",
                "BookAuthor": "Lalgudi G Jayaraman",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3338,
                "Name": "Nee paadhamae Gathi yani",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Pazhani R Subramanya Bhagavathar",
                "BookTitle": "Compositions of Pazhani R Subramanya Bhagavathar",
                "BookAuthor": "Pazhani R Subramanya Bhagavathar",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3339,
                "Name": "Swami naapai dayayunchi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Pazhani R Subramanya Bhagavathar",
                "BookTitle": "Compositions of Pazhani R Subramanya Bhagavathar",
                "BookAuthor": "Pazhani R Subramanya Bhagavathar",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3340,
                "Name": "Bruhaddeshwara prana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Pazhani R Subramanya Bhagavathar",
                "BookTitle": "Compositions of Pazhani R Subramanya Bhagavathar",
                "BookAuthor": "Pazhani R Subramanya Bhagavathar",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3341,
                "Name": "Sree Subramanya Shrutha jana paala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Pazhani R Subramanya Bhagavathar",
                "BookTitle": "Compositions of Pazhani R Subramanya Bhagavathar",
                "BookAuthor": "Pazhani R Subramanya Bhagavathar",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3342,
                "Name": "Sthala nammidhira shankari thanaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Pazhani R Subramanya Bhagavathar",
                "BookTitle": "Compositions of Pazhani R Subramanya Bhagavathar",
                "BookAuthor": "Pazhani R Subramanya Bhagavathar",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3343,
                "Name": "paalimpa vamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Composer": "Pazhani R Subramanya Bhagavathar",
                "BookTitle": "Compositions of Pazhani R Subramanya Bhagavathar",
                "BookAuthor": "Pazhani R Subramanya Bhagavathar",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3344,
                "Name": "Thillana-Vasantha",
                "Type": "Thillana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Pazhani R Subramanya Bhagavathar",
                "BookTitle": "Compositions of Pazhani R Subramanya Bhagavathar",
                "BookAuthor": "Pazhani R Subramanya Bhagavathar",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3345,
                "Name": "Apadhoorugu",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Thirupanandhaal Pattabhirammaya",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3346,
                "Name": "Adhineepai",
                "Type": "Javali",
                "Ragam": "YamanKalyani",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3347,
                "Name": "Apudumanasu",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3348,
                "Name": "Idhineegu",
                "Type": "Javali",
                "Ragam": "Begada",
                "Thalam": "Chapu",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3349,
                "Name": "Ilaakuna",
                "Type": "Javali",
                "Ragam": "Darbar",
                "Thalam": "Roopakam",
                "Composer": "Thirupanandhaal Pattabhirammaya",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3350,
                "Name": "Oorivaaraemi",
                "Type": "Javali",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3351,
                "Name": "Enthadikulukae",
                "Type": "Javali",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Thirupanandhaal Pattabhirammaya",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3352,
                "Name": "Yaemanthunae",
                "Type": "Javali",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3353,
                "Name": "Yaemimaayamu",
                "Type": "Javali",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Thirupanandhaal Pattabhirammaya",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3354,
                "Name": "yaeraathaku nataraa",
                "Type": "Javali",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3355,
                "Name": "Yaelaraadaayanae",
                "Type": "Javali",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3356,
                "Name": "Kommaro",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3357,
                "Name": "Chelinae netlu",
                "Type": "Javali",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Thirupanandhaal Pattabhirammaya",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3358,
                "Name": "Thaarumaaru",
                "Type": "Javali",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Thirupanandhaal Pattabhirammaya",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3359,
                "Name": "Telisae vaka lella",
                "Type": "Javali",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Thirupanandhaal Pattabhirammaya",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3360,
                "Name": "naareemani",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3361,
                "Name": "Praanasakutidu",
                "Type": "Javali",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3362,
                "Name": "Palimiyaela",
                "Type": "Javali",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Thirupanandhaal Pattabhirammaya",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3363,
                "Name": "Paripovalara",
                "Type": "Javali",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Thirupanandhaal Pattabhirammaya",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3364,
                "Name": "Mariyaada theliyakanae",
                "Type": "Javali",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3365,
                "Name": "Maarubaari thaalalaenura",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3366,
                "Name": "Smara Sunda",
                "Type": "Javali",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3367,
                "Name": "Samayamidae",
                "Type": "Javali",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3368,
                "Name": "Vaani pondu",
                "Type": "Javali",
                "Ragam": "Kaanadaa",
                "Thalam": "Roopakam",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3369,
                "Name": "Vaththaninne",
                "Type": "Javali",
                "Ragam": "Kapi",
                "Thalam": "Roopakam",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3370,
                "Name": "Vagalaadi",
                "Type": "Javali",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Composer": "Tirupati Narayanaswamy",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3371,
                "Name": "Merakaadhu",
                "Type": "Javali",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Tirupati Narayanaswamy",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3372,
                "Name": "Marubaari",
                "Type": "Javali",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3373,
                "Name": "Muttavaththura",
                "Type": "Javali",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Chinniah (Tanjore Quartet)",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3374,
                "Name": "Modi jese",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Thirupanandhaal Pattabhirammaya",
                "BookTitle": "Javalis",
                "BookAuthor": "Patnam Subramanya Iyer, Thiruppanandal Pattabhiraamayya, Dharmapuri Subbrayar, Tirupathi Narayanaswamy and others",
                "Year of Publication": 1960,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3375,
                "Name": "Paahi Paahi bala ganapathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Tisra Jathi Eka Thalam",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3376,
                "Name": "Engae yolitheereesanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3377,
                "Name": "Karunai thandhenai aalamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Mishra Chapu",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3378,
                "Name": "Maamayura meedhil yaeri vaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3379,
                "Name": "Karunai purindhu ratchiamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Mishra Chapu",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3380,
                "Name": "Ambaa krupai thandhu ratchi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Karanataka Kapi",
                "Thalam": "Adi",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3381,
                "Name": "Gathi undan iru thaalamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Mishra Chapu",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3382,
                "Name": "Otriyoorappa ninai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3383,
                "Name": "Indha nara jananam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Mishra Chapu",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3384,
                "Name": "Poomael valarum annaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3385,
                "Name": "Thanjam Thanjamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Mishra Chapu",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3386,
                "Name": "Kanmaniyae solladi",
                "Type": "Padam",
                "Ragam": "HindusthaniKapi",
                "Thalam": "Adi",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3387,
                "Name": "Naanae naevinaen",
                "Type": "Padam",
                "Ragam": "Sindhubhairavi",
                "Thalam": "Adi",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3388,
                "Name": "Sri vidarbhanagar vaasini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PoorviKalyani",
                "Thalam": "Adi",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "Group": "Rukmini Kalyanam",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3389,
                "Name": "Jaathiyai keduppaaro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "Group": "Iyarpagai naayanaar Charithram",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3390,
                "Name": "Madhura Ranjitha",
                "Type": "Kummi",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "On Madurai Meenakshi",
                "Composer": "Mazhavai Chidambhara Bharathi",
                "BookTitle": "Mazhavai Chidambara Bhaarathi Paadalgal",
                "BookAuthor": "Chidambara Bharathi",
                "Year of Publication": 1964,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 3391,
                "Name": "Akhilanda Kti Brahmaanda",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda jathi ata thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3392,
                "Name": "Vaaranaasyam",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3393,
                "Name": "Sree Raamachandra",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3394,
                "Name": "Enduku",
                "Type": "Varnam-Tana",
                "Ragam": "Ramapriya",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3395,
                "Name": "Bhajarae Maanasa",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3396,
                "Name": "Gati Neevani",
                "Type": "Varnam-Tana",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3397,
                "Name": "Ninne nammitinayya",
                "Type": "Varnam-Tana",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3398,
                "Name": "Ninnu Koriyunnanuraa",
                "Type": "Varnam-Tana",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3399,
                "Name": "Dayaledaa",
                "Type": "Varnam-Tana",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3400,
                "Name": "Evarunnaaruraa",
                "Type": "Varnam-Tana",
                "Ragam": "NavarasaKannadaa",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3401,
                "Name": "Palumaaru",
                "Type": "Varnam-Pada",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3402,
                "Name": "Evarunnaaru",
                "Type": "Varnam-Tana",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3403,
                "Name": "Chalamela Jessevuraa",
                "Type": "Varnam-Tana",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3404,
                "Name": "Nive Rakshakudani",
                "Type": "Varnam-Tana",
                "Ragam": "Kathanakuthuhalam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3405,
                "Name": "Yadukula Jalanidhi",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3406,
                "Name": "Vanajaaksha",
                "Type": "Varnam-Tana",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3407,
                "Name": "Vandenishamaham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3408,
                "Name": "Pranamaamyaham shree",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3409,
                "Name": "Panamata Srimahaganapathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3410,
                "Name": "Lambodaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3411,
                "Name": "Sri Saraswathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3412,
                "Name": "Maamavathu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3413,
                "Name": "Namaousthute Devi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3414,
                "Name": "Pranamaamyaham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3415,
                "Name": "Bhajamaanasa Saraswathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3416,
                "Name": "Shaarade Paahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3417,
                "Name": "Gurukrupaaleka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pushpalatha",
                "Thalam": "Tisra Jathi Roopaka Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3418,
                "Name": "Sree Purandara Guruvaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi Manohari",
                "Thalam": "Mishra Jathi Triputa thalam",
                "Praise of": "On Purandaradasar",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3419,
                "Name": "Srimadaadi Tyagaraja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Praise of": "On Tyagaraja Swami",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3420,
                "Name": "Raaghavendra Gurumaanatosmi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3421,
                "Name": "Guru Raaghavendram Anisham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3422,
                "Name": "Namaami Vidyaaratnaakara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3423,
                "Name": "Sree Keshavamaam paalaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3424,
                "Name": "Naaraayanam namata",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3425,
                "Name": "Bhaja maadhavam anisham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3426,
                "Name": "Maamavaashu Govindaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3427,
                "Name": "Satatam Srivishnum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3428,
                "Name": "Mahaasudhanamaham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3429,
                "Name": "Trivikramam aham bhaje",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3430,
                "Name": "Vaamana manisham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naayaki",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3431,
                "Name": "Sreedhara paahi dayaakara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayantasree",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3432,
                "Name": "Paripaahimaam sree Hrushikesha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3433,
                "Name": "Paalayaashu Padmanaabha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3434,
                "Name": "Daamodaramanisham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3435,
                "Name": "Sankarshana maam paalaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3436,
                "Name": "Vaasudevamanisham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanadaa",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3437,
                "Name": "Pranamaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3438,
                "Name": "Aniruddhamaashrayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Tisra Jathi Roopaka Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3439,
                "Name": "Purushottama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3440,
                "Name": "Bhajaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3441,
                "Name": "Manasaa Vachasaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3442,
                "Name": "Bhaavayaachutam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PoorviKalyani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3443,
                "Name": "Janaardanam Samaashrayeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3444,
                "Name": "Upendramaashrayaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3445,
                "Name": "Harae Paripaahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3446,
                "Name": "Paahi Krishna Vaasudeva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3447,
                "Name": "Baalam gopaalamakhila",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3448,
                "Name": "Naaraayana namousthute",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3449,
                "Name": "Enta Nirdaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3450,
                "Name": "Raamaaramana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3451,
                "Name": "Sree Vaasudeva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Tisra Jathi Roopaka Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3452,
                "Name": "Inta paraakelanayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3453,
                "Name": "Ninne Sharanantinayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhenuka",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3454,
                "Name": "Sree Vaasudeva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhari",
                "Thalam": "Tisra Jathi Roopaka Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3455,
                "Name": "BhajaMaanasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mandari",
                "Thalam": "Mishra Chapu",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3456,
                "Name": "Gokulanilaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3457,
                "Name": "Devakitanaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pushpalatha",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3458,
                "Name": "Govindam Bhajarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HindusthaniKapi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3459,
                "Name": "Bhajarae Gopalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3460,
                "Name": "Jaya Jaya Maadhava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayantasree",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3461,
                "Name": "Mama Hrudayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ReethiGowlai",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3462,
                "Name": "Namaamyaham Sree",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3463,
                "Name": "Entani nee varnintuno",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3464,
                "Name": "Nee Paadamulanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naayaki",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3465,
                "Name": "Indiraa Ramana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3466,
                "Name": "Neeve Gatiyani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3467,
                "Name": "Paripaahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3468,
                "Name": "Paalita Bhuvana patitapaavana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3469,
                "Name": "Naa chai vidavakuraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3470,
                "Name": "Naa moralanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3471,
                "Name": "Idi Samayamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natakapriya",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3472,
                "Name": "Shankari Ninne",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Mishra Chapu",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3473,
                "Name": "Devi Ramae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3474,
                "Name": "Sree Chamundeshwari Paalaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3475,
                "Name": "Brovavamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Tisra Jathi Roopaka Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3476,
                "Name": "Sri Hari Vallabhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Udayaravichandrika",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3477,
                "Name": "Kalayae Mama Hrudayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HindusthaniKapi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3478,
                "Name": "Varalakshmi Namousthuthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3479,
                "Name": "Sree Mahaalakshmeem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3480,
                "Name": "Kuru mae kushalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KamalaManohari",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3481,
                "Name": "Devi Kamalaalayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Garudadhwani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3482,
                "Name": "Paahi maam Ksheerasaagara tanayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3483,
                "Name": "Shreeramaadevi maamavatu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3484,
                "Name": "Maamava mrudujaaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3485,
                "Name": "Shailasuthae Shiva sahithae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3486,
                "Name": "Shivae Paahi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3487,
                "Name": "Karunisou Thaayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi Manohari",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3488,
                "Name": "Maanamuto",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3489,
                "Name": "Raamaabhiraama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Mishra Chapu",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3490,
                "Name": "Chintayeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3491,
                "Name": "Devaadideva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sunadavinodini",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3492,
                "Name": "Gaanasudhaarasa Paanamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3493,
                "Name": "Kalinarulaku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3494,
                "Name": "Pari paahi maam paravaasudeva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janaranjani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3495,
                "Name": "Harini Bhajinche",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3496,
                "Name": "Sigguledu naakinchukaina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balahamsa",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3497,
                "Name": "Nee paadamulae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3498,
                "Name": "Palukavademiraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3499,
                "Name": "Brovavayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3500,
                "Name": "Bhaavayeham Raghuveeram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3501,
                "Name": "Paripaahi maam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KamalaManohari",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3502,
                "Name": "Karuninchi naanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3503,
                "Name": "Brochevarevarura",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3504,
                "Name": "Krupatonu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3505,
                "Name": "Raamae Vasatu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3506,
                "Name": "Dayaledemi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3507,
                "Name": "Dayato nannu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3508,
                "Name": "Intaparaangmukhamela",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3509,
                "Name": "Nera nammithi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3510,
                "Name": "Sree Raamachandram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jhalavarali",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3511,
                "Name": "Neekenduku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3512,
                "Name": "Raaraa en pilachite",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3513,
                "Name": "Bhajarae Maanasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3514,
                "Name": "Paripaahi Raama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3515,
                "Name": "Smara bhumi sutaadhi patim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MeghaRanjani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3516,
                "Name": "Ninne nammitinayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Mishra Chapu",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3517,
                "Name": "Idi Niku Nyaayamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gamanashrama",
                "Thalam": "Mishra Chapu",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3518,
                "Name": "Dasharathanandana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3519,
                "Name": "Ninnu Nammiti",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3520,
                "Name": "Neramemi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3521,
                "Name": "Smara Raamachandram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3522,
                "Name": "Nannu Brochutakevarunnaaru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3523,
                "Name": "Dayaleka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3524,
                "Name": "Marachitivemo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PoorviKalyani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3525,
                "Name": "Raaraa raajeva lochana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3526,
                "Name": "Mahaatmule",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rishabhapriya",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3527,
                "Name": "Evaranivedanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3528,
                "Name": "Bhajanseyarada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3529,
                "Name": "Raama nee dayarada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3530,
                "Name": "Paraakelanayyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3531,
                "Name": "Paripaahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3532,
                "Name": "Jaanaki Manoharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3533,
                "Name": "Karuninchi naanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NavarasaKannadaa",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3534,
                "Name": "Nannu Brovarada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3535,
                "Name": "Karuninchi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bangala",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3536,
                "Name": "Marimari vachchuna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3537,
                "Name": "Karunapayonidhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3538,
                "Name": "Paripaahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3539,
                "Name": "Parulanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3540,
                "Name": "Varamulosagi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3541,
                "Name": "Neekela Dayaraadu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kathanakuthuhalam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3542,
                "Name": "Raamam namaami satatam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3543,
                "Name": "Taramugaduraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3544,
                "Name": "Neekabhimaanamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokiladhwani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3545,
                "Name": "Daasharathae Paahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3546,
                "Name": "Nimishamaina Shri Raama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3547,
                "Name": "Nee Daya Etula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3548,
                "Name": "Sree Ranganaatham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhari",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3549,
                "Name": "Brovaraada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varunapriya",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3550,
                "Name": "Bhajarae Re",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanakalyani",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3551,
                "Name": "Paahimaam Parameshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3552,
                "Name": "Bhu kailaasapathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3553,
                "Name": "Paalayamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3554,
                "Name": "Panataartiharamaham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Senchurutti",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3555,
                "Name": "Shambho Shankara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3556,
                "Name": "Girijaa Ramana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GambiraNattai",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3557,
                "Name": "Pranamaamyaham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ranjani",
                "Thalam": "Mishra Jathi Triputa thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3558,
                "Name": "Thillana-ShuddhaSaalavi",
                "Type": "Thillana",
                "Ragam": "ShuddhaSaalavi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3559,
                "Name": "Thillana-Mohanakalyani",
                "Type": "Thillana",
                "Ragam": "Mohanakalyani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3560,
                "Name": "Thillana-Kannadaa",
                "Type": "Thillana",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3561,
                "Name": "Thillana-Charukesi",
                "Type": "Thillana",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3562,
                "Name": "Thillana-Khamas",
                "Type": "Thillana",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3563,
                "Name": "Thillana-Poornachandrika",
                "Type": "Thillana",
                "Ragam": "Poornachandrika",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3564,
                "Name": "Thillana-Kaanadaa",
                "Type": "Thillana",
                "Ragam": "Kaanadaa",
                "Thalam": "Tisra Jathi Roopaka Thalam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3565,
                "Name": "Thillana-Surutti",
                "Type": "Thillana",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3566,
                "Name": "Thillana-Sree",
                "Type": "Thillana",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3567,
                "Name": "Thillana-Kalyani",
                "Type": "Thillana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3568,
                "Name": "Nee pilachite",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3569,
                "Name": "Era naa saami",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3570,
                "Name": "Premato",
                "Type": "Javali",
                "Ragam": "Kaanadaa",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3571,
                "Name": "Ninnentagaa",
                "Type": "Javali",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3572,
                "Name": "Ninnubaasi",
                "Type": "Javali",
                "Ragam": "Senchurutti",
                "Thalam": "Mishra Chapu",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3573,
                "Name": "Ninnubaasi",
                "Type": "Javali",
                "Ragam": "Yadukula Khambhoji",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Mysore Vasudeva Kirthana Manjari",
                "BookAuthor": "Mysore Vasudevacharyar",
                "Year of Publication": 2015,
                "Book Print Language": "English"
              },
              {
                "Serial #": 3574,
                "Name": "Garudadhwani",
                "Type": "Jathiswaram",
                "Ragam": "Garudadhwani",
                "Thalam": "Chatusra Jathi Eka Thalam",
                "Composer": "Neyveli Santhanagopalan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3575,
                "Name": "Hamsadhwani",
                "Type": "Jathiswaram",
                "Ragam": "Hamsadhwani",
                "Thalam": "Roopakam",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3576,
                "Name": "Hemavathi",
                "Type": "Jathiswaram",
                "Ragam": "Hemavathi",
                "Thalam": "Roopakam",
                "Composer": "Tanjore quartet",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3577,
                "Name": "Hindolam",
                "Type": "Jathiswaram",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "KGSMA Students",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3578,
                "Name": "Hindolam",
                "Type": "Jathiswaram",
                "Ragam": "Hindolam",
                "Composer": "Unknown Author",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3579,
                "Name": "Kalyani",
                "Type": "Jathiswaram",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3580,
                "Name": "Kalyani",
                "Type": "Jathiswaram",
                "Ragam": "Kalyani",
                "Thalam": "Thisra Ekam",
                "Composer": "Ponniah Pillai (Thanjavur Quatret)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3581,
                "Name": "Naatakurinji",
                "Type": "Jathiswaram",
                "Ragam": "Naatakurinji",
                "Composer": "KGSMA Students",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3582,
                "Name": "Rasikapriya",
                "Type": "Jathiswaram",
                "Ragam": "Rasikapriya",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3583,
                "Name": "Revathi",
                "Type": "Jathiswaram",
                "Ragam": "Revathi",
                "Thalam": "Adi",
                "Composer": "KGSMA Students",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3584,
                "Name": "Charumathi Upachaaramu",
                "Type": "Javali",
                "Ragam": "Kaanada",
                "Thalam": "Mishra Chapu",
                "Composer": "Pattabhiramiah",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3585,
                "Name": "Cheli Nenetlu (Javali)",
                "Type": "Javali",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Tanjore Chinnaiah",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3586,
                "Name": "Elaradayanae",
                "Type": "Javali",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Chinnaiyaa/Dharmapuri Subbaraya iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3587,
                "Name": "Kopametula",
                "Type": "Javali",
                "Ragam": "KedaraGowlai",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Chinnaiah",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3588,
                "Name": "Maaru baari Oorvalene",
                "Type": "Javali",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Composer": "Dharmapuri Subbaraaya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3589,
                "Name": "Maarubaari",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbaraaya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3590,
                "Name": "Marulukonnadira",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3591,
                "Name": "Mutu Vadura",
                "Type": "Javali",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Chinnaiah",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3592,
                "Name": "Paripovalera",
                "Type": "Javali",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Pattabhiramiah",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3593,
                "Name": "Parulanna mata (Javali)",
                "Type": "Javali",
                "Ragam": "Kapi",
                "Thalam": "Roopakam",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3594,
                "Name": "Smara Sundaraanguni",
                "Type": "Javali",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3595,
                "Name": "Vagaladi Bhodanalaku (Javali)",
                "Type": "Javali",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Composer": "Tirupathi Narayanaswamy",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3596,
                "Name": "Vanipondu",
                "Type": "Javali",
                "Ragam": "Kaanada",
                "Thalam": "Roopakam",
                "Composer": "Dharmapuri Subbaraaya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3597,
                "Name": "Aaduvome Pallu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Composer": "Subramania Bharathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3598,
                "Name": "AaLaavadhennaLo (6th Sapta Rathna Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3599,
                "Name": "Abhimaanamen nadugal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3600,
                "Name": "Abhishtavaradha Sree Maha Ganapathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3601,
                "Name": "Adigo Badradri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Composer": "Bhadrachala Ramadasar/Tumu Narasimhadasa",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3602,
                "Name": "Adiseshan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3603,
                "Name": "Aganita Mahimaatbudha (2nd Sapta Rathna Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3604,
                "Name": "Ainkaranai (Thirupugazh)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Khanda Chapu",
                "Composer": "Arunagirinaathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3605,
                "Name": "Akilandeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dwijaavanthi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3606,
                "Name": "Akshaya Linga Vibho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3607,
                "Name": "Alaipayuthey",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3608,
                "Name": "Amba Neelambari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Ponniah Pillai (Thanjavur Quatret)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3609,
                "Name": "Amba Neelayathakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3610,
                "Name": "Amba Paradevathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rudrapriya",
                "Thalam": "Khanda Chapu",
                "Composer": "Krishnaswami Ayya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3611,
                "Name": "Amma Dharmasamvardhini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3612,
                "Name": "Amma Raavamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Khanda Chapu",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3613,
                "Name": "Amudhamooru - Tirupugazh",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Arunagirinadar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3614,
                "Name": "Ananda Nata aaduvar thillai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Roopakam",
                "Composer": "Neelakanta Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3615,
                "Name": "Ananda Natana prakasham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Mishra Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3616,
                "Name": "Anandaamruthakarshini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amruthavarshini",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3617,
                "Name": "Aneeshvaram Ambikeshvaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3618,
                "Name": "Anjaneya Raghurama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3619,
                "Name": "Annapoornae Visalakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3620,
                "Name": "Anumanai Anudinam ninai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Madhuvanthi / Bhageshri / SindhuBhairavi / Revathi)",
                "Composer": "Guru Surajananda",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3621,
                "Name": "Anuragamule",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswati",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3622,
                "Name": "Aparadhamulan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3623,
                "Name": "Apparama Bhakthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3624,
                "Name": "Arabhimaanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Tarangampadi Panchanadayyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3625,
                "Name": "Aradhayami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Khanda Chapu",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3626,
                "Name": "avan seyalanri Or aNUvum asaiyAdAgaiyAl",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Adi",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3627,
                "Name": "Baala sarasa (4th Sapta Rathna Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3628,
                "Name": "Baalaambikae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manoranjani",
                "Thalam": "Matya Thalam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3629,
                "Name": "Balagopalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3630,
                "Name": "Balakanakamaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3631,
                "Name": "Balasubramanyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3632,
                "Name": "Bhagyada Lakshmi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3633,
                "Name": "Bhairavi Shatkam (Arulsindhum Bhairavi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Bhairavi flavours)",
                "Thalam": "Adi",
                "Composer": "Dr. S. Ghatam Karthick",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3634,
                "Name": "Bhajanaamruta (1st Sapta Rathna Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3635,
                "Name": "Bhajanaseyaradha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3636,
                "Name": "Bhajare Bodhendram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Sri Jayendra Saraswathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3637,
                "Name": "Bhajare Manasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3638,
                "Name": "Bhajasva shree (2nd avarana Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3639,
                "Name": "Bharatha Desha Hithaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desh",
                "Thalam": "Adi",
                "Composer": "Swami Dayananda Saraswathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3640,
                "Name": "Bharo Guru Raghavendra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Mishra Gathi",
                "Composer": "Sri Muralidhara Swamiji",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3641,
                "Name": "Bhavamulona",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Sri Annamacharya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3642,
                "Name": "Bhavayami Raghuramam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3643,
                "Name": "Bho Shambho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Revathi",
                "Thalam": "Eka",
                "Composer": "Swami Dayananda Saraswathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3644,
                "Name": "Bhogeendrasayinam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntala Varali",
                "Thalam": "Khanda Chapu",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3645,
                "Name": "Bhuveneshwarya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanakalyani",
                "Thalam": "Adi",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3646,
                "Name": "Biraanavara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3647,
                "Name": "Brihadeeshwaro Rakshathu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ganasamavarali",
                "Thalam": "Thisra Ekam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3648,
                "Name": "Brocheva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3649,
                "Name": "Brochevarevare",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3650,
                "Name": "Brovabarama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3651,
                "Name": "Bruhadamba Madamba",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhaanumathi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3652,
                "Name": "Bruhadambikayai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Mishra Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3653,
                "Name": "Chalarae Rama (Sompaina)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahiri",
                "Thalam": "Mishra Chapu",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3654,
                "Name": "Chandram Bhajamanasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Matya Thalam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3655,
                "Name": "Chandran Oliyil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Eka (Tisra Gathi)",
                "Composer": "Subramania Bharathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3656,
                "Name": "Chandrasekara Chandrasekahara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yaman Kalyani",
                "Composer": "Ganesh-Kumaresh",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3657,
                "Name": "Chani Thodi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3658,
                "Name": "Chede Budhimaanuraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3659,
                "Name": "Chenthil Aandavan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3660,
                "Name": "Chera raava demira",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3661,
                "Name": "Chesinadella",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3662,
                "Name": "Chetah Sri Balakrishnam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dwijaavanthi",
                "Thalam": "Thisra Ekam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3663,
                "Name": "Chidambara Nataraja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tanukirti",
                "Thalam": "Mishra Jathi Eka Thalam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3664,
                "Name": "Chinnanchiru kiliyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Chatusra Jathi Eka Thalam-Tisra Nadai",
                "Composer": "Subramania Bharathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3665,
                "Name": "Chintayamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3666,
                "Name": "Chinthayami Jagadambha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Mishra Jhampai",
                "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3667,
                "Name": "Chitham Irangadha daenayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Mishra Chapu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3668,
                "Name": "Choodarae chelulara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3669,
                "Name": "Cleveland Aradhanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Composer": "Neyveli Santhanagopalan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3670,
                "Name": "Dakshinamoorthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Jhumpai",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3671,
                "Name": "Danda muppatenura",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balahamsa",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3672,
                "Name": "Dasana Madiko",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3673,
                "Name": "Deva Deva Jagadeeshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3674,
                "Name": "Deva Deva kalayamidae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3675,
                "Name": "Devi Brova samayamidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chintamani",
                "Thalam": "Mishra Chapu",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3676,
                "Name": "Devi Neeye Thunai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3677,
                "Name": "Devi Paavanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3678,
                "Name": "Devi Sri Tulasamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3679,
                "Name": "Dhadhathi Sambatham (Dhaivatham)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Shri Ganapathi Sacchidananda",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3680,
                "Name": "Dharmasamvardhini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3681,
                "Name": "Dina Jana Vana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3682,
                "Name": "Dinamani vamsha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3683,
                "Name": "Dolayam Chala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Composer": "Sri Annamacharya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3684,
                "Name": "Dora kuna (karaku bangaru)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3685,
                "Name": "Dudukugala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3686,
                "Name": "Durga Devi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kaanada",
                "Thalam": "Adi",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3687,
                "Name": "Durga Devi Samrakshamam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3688,
                "Name": "Edutanilachithe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3689,
                "Name": "Ee vasudha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3690,
                "Name": "Eesha Pahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3691,
                "Name": "Ehi Annapoorne",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3692,
                "Name": "Ekkaalathilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Thiruvaarur Ramaswamy Pillai",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3693,
                "Name": "Ekkalathilum maravenae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Roopakam",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3694,
                "Name": "Ela Raadhaayanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Chinnaiah",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3695,
                "Name": "Elara Krishna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3696,
                "Name": "Emani Veginthune",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3697,
                "Name": "Enadhu Ullamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Composer": "Kalki Krishnamurthi/SV Venkatraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3698,
                "Name": "Enatinomu Phalamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3699,
                "Name": "Endu dhagi naado",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3700,
                "Name": "Enduku Peddhala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3701,
                "Name": "Ennaganu Ramabhajana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Bhadrachala Ramadasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3702,
                "Name": "Ennakkun irupadham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Kalyani, Bhairavi, KedaraGowlai, Thodi, Mohanam, Dhanyasi, Sahana, Shanmukhapriya, Madhyamavathi)",
                "Thalam": "Mishra Chapu",
                "Composer": "Arunachala Kavi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3703,
                "Name": "Enta Muddu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bindu Malini",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3704,
                "Name": "Entha Ninne",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3705,
                "Name": "Entha rani thana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3706,
                "Name": "Enthanerchina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3707,
                "Name": "Entharo Mahanubhavulu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3708,
                "Name": "Enthavedukondu Raghava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi Manohari",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3709,
                "Name": "Eppo Varuvaaro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jonpuri",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3710,
                "Name": "Etavunnara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3711,
                "Name": "Eti Janmaamithi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Mishra Chapu",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3712,
                "Name": "Gaganagatha (Gandharam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Roopakam",
                "Composer": "Shri Ganapathi Sacchidananda",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3713,
                "Name": "Gajavadana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Kumara Ettendra",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3714,
                "Name": "Ganamoorthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ganamurthi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3715,
                "Name": "Gandhamu Puyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3716,
                "Name": "Ganga Sthuthi (Devi Sureshwari)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Adi Shankaracharya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3717,
                "Name": "Garuda Gamana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Roopakam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3718,
                "Name": "Giriraja Sutha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bangaala",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3719,
                "Name": "Gnanamosagaradha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3720,
                "Name": "Gopa nandana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhushaavali",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3721,
                "Name": "Gopalaka Pahimam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Revagupti",
                "Thalam": "Mishra Chapu",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3722,
                "Name": "Gopi Ninna Maga ",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi (Thisra Gathi)",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3723,
                "Name": "Gopika Manoharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3724,
                "Name": "Govindanukku aatpatta",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Folk Tune",
                "Thalam": "Adi",
                "Composer": "Sri Muralidhara Swamiji",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3725,
                "Name": "Grahabalamemi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Revagupti",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3726,
                "Name": "Guruguhaya Bhakthaanugrahaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3727,
                "Name": "Gurulekha Etuvanti",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowri Manohari",
                "Thalam": "Khanda Chapu",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3728,
                "Name": "Guruvayoor Ennoro Kshetram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Tisra Gathi",
                "Composer": "Sri Muralidhara Swamiji",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3729,
                "Name": "Guruvina Gulamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3730,
                "Name": "Haladharanujam (Navavarana phala stuthi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3731,
                "Name": "Hanumantha Devanamo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3732,
                "Name": "Hariharaputram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Khanda Ekam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3733,
                "Name": "Hecharikagarara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Khanda Ekam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3734,
                "Name": "Himagiri Thanayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3735,
                "Name": "Hiranmayeem Lakshmeem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalitha",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3736,
                "Name": "Idi Nyaayamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maalavi",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3737,
                "Name": "Indrupol Endrum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswati",
                "Thalam": "Adi",
                "Composer": "Sri Muralidhara Swamiji",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3738,
                "Name": "Innudaya Bharathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyana Vasantham",
                "Thalam": "Khanda Chapu",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3739,
                "Name": "Jagadambike",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3740,
                "Name": "Jagadanandakaraka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3741,
                "Name": "Janaki Ramana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Seemanthini",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3742,
                "Name": "JanakiPathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KharaharaPriya",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3743,
                "Name": "Janani Janani Janani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Revathi",
                "Thalam": "Adi",
                "Composer": "Kadalur Venkatraman/Kadalur Subramanian",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3744,
                "Name": "Janani ninnu vina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Mishra Chapu",
                "Composer": "Subbaraya Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3745,
                "Name": "Janani Pahi sada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Mishra Chapu",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3746,
                "Name": "Jatadhara Sankara (5th Sapta Rathna Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3747,
                "Name": "Jaya Jaya Jaya Jaanaki kaantha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Khanda Chapu",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3748,
                "Name": "Jaya MahishAshwara mardhini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Roopakam",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3749,
                "Name": "Jayathi Jayathi Bharatha Matha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi (Thisra Gathi)",
                "Composer": "Mayavaram Vishwanatha Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3750,
                "Name": "Kaanakankodi Vaendum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3751,
                "Name": "Kadanu variki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3752,
                "Name": "Kaithala Niraikani (Thirupugazh)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gambiranattai",
                "Thalam": "Adi",
                "Composer": "Arunagirinadar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3753,
                "Name": "Kakaichiraginilae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Subramania Bharathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3754,
                "Name": "Kaligiyuntae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3755,
                "Name": "Kalitheera Vandarul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Koteeswara Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3756,
                "Name": "Kallarkum Katravarkum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Composer": "Vallalar (Ramalinga Adigal)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3757,
                "Name": "Kamalaambikaayai (4th Avaranam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3758,
                "Name": "Kamalaambikaya (6th Avaranam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3759,
                "Name": "Kamalambha Bhajarae (2nd Avaranam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3760,
                "Name": "Kamalambha Samrakshathu (1st Avaranam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3761,
                "Name": "Kamalambikae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Khanda Jhampai",
                "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3762,
                "Name": "Kamalambikae (Dhyana krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3763,
                "Name": "Kana Vendamo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3764,
                "Name": "Kanakaambari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kanakambari",
                "Thalam": "Thisra Ekam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3765,
                "Name": "Kanakanaruchira",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3766,
                "Name": "Kanakasaila",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3767,
                "Name": "Kanjadalaya Thakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kamala Manohari",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3768,
                "Name": "Kanthimathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Subbarama Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3769,
                "Name": "Kanukontini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3770,
                "Name": "Kapaleeshwaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vaasanthi",
                "Thalam": "Adi",
                "Composer": "Dr. S. Ghatam Karthick",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3771,
                "Name": "Kapali",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3772,
                "Name": "Karpaga Manohara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Khanda Chapu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3773,
                "Name": "Karpagamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3774,
                "Name": "Karthikeya Kaangeya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3775,
                "Name": "Karu Baru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3776,
                "Name": "Karuna Nidhiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Mishra Chapu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3777,
                "Name": "Karunai Deivamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Madurai Srinivasan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3778,
                "Name": "Kaumaari Gauri vaeLLavaLi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3779,
                "Name": "Kaumari Gowri velavali",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3780,
                "Name": "Kodubega Divya Mathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3781,
                "Name": "Koluvai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3782,
                "Name": "Koluvaiyunnaadae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhari",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3783,
                "Name": "Kondadu Panpadu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Sri Muralidhara Swamiji",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3784,
                "Name": "Koosina Kandeera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3785,
                "Name": "Kopametula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Chinnaiah",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3786,
                "Name": "Korisevimpararae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3787,
                "Name": "Kotina dhulu Dhanushkoti",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3788,
                "Name": "Krishna nee begane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yamunakalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Kanakadasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3789,
                "Name": "kruthi vaasasae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3790,
                "Name": "Ksheenamai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3791,
                "Name": "Ksheerabdi Kanyakaku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurunji",
                "Thalam": "Khanda Chapu",
                "Composer": "Sri Annamacharya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3792,
                "Name": "Ksheerasagara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Dhruva Thalam",
                "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3793,
                "Name": "Kunidado Krishna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3794,
                "Name": "Kurai Ondrum Illai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Shivaranjani, Kapi, Sindhu Bhairavi)",
                "Thalam": "Adi",
                "Composer": "Rajaji",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3795,
                "Name": "Kuvalayadala nayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3796,
                "Name": "Lalithe Sri Pravruddhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3797,
                "Name": "Lambodhara Maha Ganesha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kaanada",
                "Thalam": "Adi",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3798,
                "Name": "Maa Ramanan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3799,
                "Name": "Maadhava Hridi khelini (3rd Sapta Rathna Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3800,
                "Name": "Maadhava Mamava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Narayana Teerthar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3801,
                "Name": "Maakaelara vicharamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ravichandrika",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3802,
                "Name": "Maalmaruga Shanmukha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3803,
                "Name": "Maamava Maamava (Madhyamam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Shri Ganapathi Sacchidananda",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3804,
                "Name": "Maamava Raghurama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3805,
                "Name": "Maamava Raghuveera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mahuri",
                "Thalam": "Mishra Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3806,
                "Name": "Maamava Sada Janani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3807,
                "Name": "Maamava Sada Varadae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3808,
                "Name": "Maamavatu Shri Saraswathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3809,
                "Name": "Maapalaa Velasika",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3810,
                "Name": "Maayamma Nannu Brovamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3811,
                "Name": "Madi Madi Madi Endhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3812,
                "Name": "Maha Ganapathaey Paalayashumaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natanarayani",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3813,
                "Name": "Mahaganapathim manasasswarami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Chatushra Ekam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3814,
                "Name": "Mahaganapathim vande",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3815,
                "Name": "Mahalakshmi Jaganmatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3816,
                "Name": "Mahitha Pravuddha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Tisra Triputa",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3817,
                "Name": "Maitreem Bhajatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Sri Chandrasekhara Saraswathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3818,
                "Name": "Mamahrudayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Khanda Triputa",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3819,
                "Name": "Manasa Etulo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3820,
                "Name": "Manasa Guruguha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3821,
                "Name": "Manasa Satatam Smaraneeyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Valachi, Durga, Sindhu Bhairavi, Darbari Kaanada, Desh)",
                "Thalam": "Eka",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3822,
                "Name": "Manavyalakinchara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3823,
                "Name": "Mangala Aarathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Mishra Nadai",
                "Composer": "Bheemesha Krishna",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3824,
                "Name": "Maravakavae Oh Manasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Roopakam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3825,
                "Name": "Marivere",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3826,
                "Name": "Marugelara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayanthashri",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3827,
                "Name": "Mathangi Srirajarajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3828,
                "Name": "Mayilaapuri Valar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Composer": "Dr. S. Ghatam Karthick",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3829,
                "Name": "Meena Lochana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Mishra Chapu",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3830,
                "Name": "Meenakshi Memudam Dehi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3831,
                "Name": "Meluko Dayanidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3832,
                "Name": "Mohanakara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neethimathi",
                "Thalam": "Roopakam",
                "Composer": "Koteeswara Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3833,
                "Name": "Muddugare Yashoda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurunji",
                "Thalam": "Adi",
                "Composer": "Sri Annamacharya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3834,
                "Name": "Mukatake Mangalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3835,
                "Name": "Naa moralakimpa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhari",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3836,
                "Name": "Naadaadina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janaranjani",
                "Thalam": "Mishra Chapu",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3837,
                "Name": "Naanoru Vilayatu Bhommai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kaanada",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3838,
                "Name": "Nada loludai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyana Vasantham",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3839,
                "Name": "Nagumomu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3840,
                "Name": "Nambi Kettavar Evaraiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3841,
                "Name": "Namo Namo Raghavaya",
                "Type": "Kriti/Keerthanam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3842,
                "Name": "Nandeesham Vande Sada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rishabhapriya",
                "Thalam": "Roopakam",
                "Composer": "Dr. Bala muralikrishna",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3843,
                "Name": "Nandha Gopa Balanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Composer": "Lalgudi Gopala Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3844,
                "Name": "Nannu Brova neekinta",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3845,
                "Name": "Nannu Brovu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalitha",
                "Thalam": "Mishra Chapu",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3846,
                "Name": "Nannu Vidachi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Mishra Chapu",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3847,
                "Name": "Naradhaguruswami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3848,
                "Name": "Naradhamuni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3849,
                "Name": "Natajana Kalpavalli (9th avarana Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3850,
                "Name": "Natanam Adinar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Gopalakrishna Bharathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3851,
                "Name": "Ne pogada kunte",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Khanda Chapu",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3852,
                "Name": "Nee Allal Ini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "G N Balasubramanian",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3853,
                "Name": "Nee Chithamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Mishra Chapu",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3854,
                "Name": "Nee irangayenil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3855,
                "Name": "Nee Sari Saati",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Ponniah Pillai (Thanjavur Quatret)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3856,
                "Name": "Neela Lohita (5th avarana Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balahamsa",
                "Thalam": "Khanda Dhuruva",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3857,
                "Name": "Neelayathakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Mishra Chapu",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3858,
                "Name": "Neenyako Hangyako",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3859,
                "Name": "Neerada Sama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayanthashri",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3860,
                "Name": "Neeraja Maanini (Nishadham)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Shri Ganapathi Sacchidananda",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3861,
                "Name": "Neerajakshi Kaamaakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Tisra Jathi Eka Thalam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3862,
                "Name": "Nenendhu vethu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Karnataka Behag",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3863,
                "Name": "Nigama Nigamantava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Sri Annamacharya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3864,
                "Name": "Nila Nila Satru Nillai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Tisra Gathi",
                "Composer": "Sri Muralidhara Swamiji",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3865,
                "Name": "Ninainthodi vandhaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Thodi, Poorvikalyani, Bhairavi, Kalyani, Khambhoji, Mukhari, Varali)",
                "Thalam": "Saptha Thalam",
                "Composer": "Ponniah Pillai (Thanjavur Quatret)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3866,
                "Name": "Ninne Bhajana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gambiranattai",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3867,
                "Name": "Ninnu vina namadendu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kaanada",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3868,
                "Name": "Ninnu Vinaga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Viloma Chapu",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3869,
                "Name": "Niravdhi Sukadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ravichandrika",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3870,
                "Name": "O Jagadambhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3871,
                "Name": "O Raajeevaksha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3872,
                "Name": "O Ranga Sayee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3873,
                "Name": "Odi Bharayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3874,
                "Name": "Om Shakthi Om Shakthi",
                "Type": "Kriti/Keerthanam",
                "Composer": "Subramania Bharathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3875,
                "Name": "Paahi Paahi Jagan Mohana Krishna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Narayana Teerthar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3876,
                "Name": "Paahi Raama Duta",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shadvida Maargini",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3877,
                "Name": "Paahimaam Ratnachala naayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3878,
                "Name": "Paahimaam Sri Rajarajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janaranjani",
                "Thalam": "Adi",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3879,
                "Name": "Paalaya Sadaa maamayi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3880,
                "Name": "Paalisemma Mudu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3881,
                "Name": "Paapamharae (Panchamam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Composer": "Shri Ganapathi Sacchidananda",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3882,
                "Name": "Paarkadal Alai Melae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "G Ramanatha Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3883,
                "Name": "Padmini Vallabha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3884,
                "Name": "Pahi Janani Santatam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Mishra Chapu",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3885,
                "Name": "Palinchu Kamakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3886,
                "Name": "Palisemma Muddu Sharade",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3887,
                "Name": "Palukavemina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3888,
                "Name": "Panchabootha Kiranavalim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kiranavali",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3889,
                "Name": "Panchamaatanga mukha Ganapathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malahari",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3890,
                "Name": "Panchashat peeta Roopini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Karnataka Devagandhari",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3891,
                "Name": "Pankaja Lochana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3892,
                "Name": "Parakela Nannu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3893,
                "Name": "Paraloka Sadhanamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3894,
                "Name": "Paramapurusham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalitha Panchamam",
                "Thalam": "Khanda Chapu",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3895,
                "Name": "Paramukham Yaenayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3896,
                "Name": "Parashakthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3897,
                "Name": "Parathpara parameshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3898,
                "Name": "Paridhana Michite",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Khanda Chapu",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3899,
                "Name": "Paripaahimaam Sri Daasharathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3900,
                "Name": "Paripaalaya Sarasiruha lochana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3901,
                "Name": "Paruvam Paarka gnyaayamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Roopakam",
                "Composer": "Anaiayya Brothers",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3902,
                "Name": "Parvathi Patim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3903,
                "Name": "Pathiki Harateere",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3904,
                "Name": "Pathiki Mangala Harateere",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3905,
                "Name": "Ra Ra Panisayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3906,
                "Name": "Ra Ra Raajeva Lochana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3907,
                "Name": "Raadhike Radhike",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhageshri",
                "Thalam": "Eka",
                "Composer": "Sri Muralidhara Swamiji",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3908,
                "Name": "Raama Deivama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3909,
                "Name": "Raama katha sutha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3910,
                "Name": "Raamaa dayajudavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3911,
                "Name": "Ragasudharasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3912,
                "Name": "Raghavanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Arunachala Kavi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3913,
                "Name": "Raghunayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3914,
                "Name": "Raghuvamsha Sudha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kathanakudhoohalam",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3915,
                "Name": "Rakshabettarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3916,
                "Name": "Rama Madhura",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Narayana Teerthar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3917,
                "Name": "Rama Nama Bhajarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3918,
                "Name": "Rama Rama Guna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3919,
                "Name": "Ramaa Nannu Brovara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3920,
                "Name": "Ramaa Ramana Raa Raa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3921,
                "Name": "Ramamantrava Japiso",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jonpuri",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3922,
                "Name": "Ramanatham Bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3923,
                "Name": "Ramanukku Mannan mudi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Arunachala Kavi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3924,
                "Name": "Randi Randi Venkatesha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Eka (Khanda Nadai)",
                "Composer": "Sri Muralidhara Swamiji",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3925,
                "Name": "Ranganayakam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nayaki",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3926,
                "Name": "Rangapura Vihara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Brindavana Saranga",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3927,
                "Name": "Ranjani Mala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Tanjore Shankara Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3928,
                "Name": "Re Re Swarodhitham (Rishbham)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Shri Ganapathi Sacchidananda",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3929,
                "Name": "Saarasa Netra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3930,
                "Name": "Saarasaaksha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3931,
                "Name": "Saaraya Saaraya (Shadjam swaradevata - Agni)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Shri Ganapathi Sacchidananda",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3932,
                "Name": "Saatileni Guruguha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Ponniah Pillai (Thanjavur Quatret)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3933,
                "Name": "Sadaa Enna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Composer": "Vijaya Vittala Dasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3934,
                "Name": "Sadaanandamayi (6th avarana Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Sankeerna Matyam",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3935,
                "Name": "Sadaanandamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Sri Annamacharya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3936,
                "Name": "Sadaapalaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "G N Balasubramanian",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3937,
                "Name": "Sadananda Thaandavam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "Achyutha dasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3938,
                "Name": "Sadhguru Swamiku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3939,
                "Name": "Sadhinchanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3940,
                "Name": "Saguna Dhyanam Sukamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Mishra Chapu",
                "Composer": "Sri Muralidhara Swamiji",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3941,
                "Name": "Sakala Loka (7th avarana Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3942,
                "Name": "Saketha Nagaranaatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Composer": "Mysore Sadashiva rao",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3943,
                "Name": "Saketha Nikethana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannada",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3944,
                "Name": "Samaja Vara Gamana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3945,
                "Name": "Samanamevaru Rama nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3946,
                "Name": "samayamidE nannu brOchuTaku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BudhaManohari",
                "Thalam": "Adi",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3947,
                "Name": "Samayamide ra ra na sami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3948,
                "Name": "Samukhana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokilavarali",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3949,
                "Name": "Sangeetha Gnaayanamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3950,
                "Name": "Sangeetha Samrajya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanakalyani",
                "Thalam": "Adi",
                "Composer": "Bangalore Ramamurthy",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3951,
                "Name": "Sangeetha Vandanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Various Thalam",
                "Composer": "Neyveli Santhanagopalan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3952,
                "Name": "Santatam Aham (1st avarana Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Deshakshi",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3953,
                "Name": "Saraguna Palimpa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3954,
                "Name": "Sarasa Saama Dhaana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapinarayani",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3955,
                "Name": "Sarasijanabha sodari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagagandhari",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3956,
                "Name": "Sarasiruhaasanapriyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Puliyur Duraiswami Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3957,
                "Name": "Saraswathi Namoustuthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Roopakam",
                "Composer": "G N Balasubramanian",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3958,
                "Name": "Saraswathi Vidhiyuvathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3959,
                "Name": "Sarievvaramma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda Jathi Jhampa Thalam",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3960,
                "Name": "Sarojadalanetri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3961,
                "Name": "Sarva jeeva (3rd avarana Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3962,
                "Name": "sarva mangaLa rUpADyaE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3963,
                "Name": "Sashivadana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chandrajyothi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3964,
                "Name": "Seethamma Maayamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3965,
                "Name": "Seshaachala Naayakam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3966,
                "Name": "Sevika Vaendum Aiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Composer": "Muthu Thandavar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3967,
                "Name": "Shaaradhae Vishaaradhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Composer": "T. V. Gopalakrishnan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3968,
                "Name": "Shambho Gangadhara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Eesha Manohari",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3969,
                "Name": "Shambho Mahadeva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3970,
                "Name": "Shankari neeve amba",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Subbaraya Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3971,
                "Name": "Shankari Shankuru Chandramukhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3972,
                "Name": "Shankari Shree (8th avarana Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3973,
                "Name": "Shanthi Nilava Vendum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tilang",
                "Thalam": "Adi",
                "Composer": "Sedhumadhava Rao",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3974,
                "Name": "Shara Shara Samarai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntala Varali",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3975,
                "Name": "Sharade Karunanidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Chandrasekhara Bharati",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3976,
                "Name": "Sharanam Bhava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsavinodini",
                "Thalam": "Roopakam",
                "Composer": "Narayana Teerthar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3977,
                "Name": "Sharanu Siddhivinaayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Mishra Chapu",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3978,
                "Name": "Shiva Shiva Shiva Bho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Mishra Jhumpai",
                "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3979,
                "Name": "Shiva Shiva Shiva Enarada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3980,
                "Name": "Shivakaama Sundari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3981,
                "Name": "Shivakaama Sundari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3982,
                "Name": "Shree Raama jayamae jayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Naatai, Varali, Arabhi, Gowlai, Manirangu)",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3983,
                "Name": "Shri Bhargavi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mangala Kaishiki",
                "Thalam": "Mishra Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3984,
                "Name": "Shri Chamundeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannada",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3985,
                "Name": "Shri Dakshinamoorthim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Phenadyuti",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3986,
                "Name": "Shri Ganapathini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3987,
                "Name": "Shri Ganeshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3988,
                "Name": "Shri Guruguha tarayaashumaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3989,
                "Name": "Shri Guruguhasya Dasoham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvi",
                "Thalam": "Mishra Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3990,
                "Name": "Shri Jaalandhara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gambiranattai",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3991,
                "Name": "Shri Kaamakoti Pitasthite",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva rao",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3992,
                "Name": "Shri Kaamakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Tiruvarur Ramaswamy Pillai",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3993,
                "Name": "Shri Kamakshi Katakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Tiruvarur Ramaswamy Pillai",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3994,
                "Name": "Shri Kamalaambikae (Mangalam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Khanda Ekam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3995,
                "Name": "Shri Kamalaambikayam (7th Avaranam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3996,
                "Name": "Shri Kamalambaya param (5th Avaranam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Mishra Jhampai",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3997,
                "Name": "Shri Kamalambha (9th Avaranam)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahiri",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3998,
                "Name": "Shri Kanthimatim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 3999,
                "Name": "Shri Krishnam Bhajamanasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4000,
                "Name": "Shri Kumaara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4001,
                "Name": "Shri Lakshmi Varaham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4002,
                "Name": "Shri Maathrubhootham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannada",
                "Thalam": "Mishra Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4003,
                "Name": "Shri Maha Ganapathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Khanda Chapu",
                "Composer": "N S Ramachandran",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4004,
                "Name": "Shri Mahaganapathi Ravathu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Mishra Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4005,
                "Name": "Shri Mahaganapathim Bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4006,
                "Name": "Shri Moolaadhara Chakra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4007,
                "Name": "Shri Naarada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4008,
                "Name": "Shri Naathadhi Guruguho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4009,
                "Name": "Shri Pathaey",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4010,
                "Name": "Shri Raajagopalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4011,
                "Name": "Shri Raama Paadamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amrutha Vaahini",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4012,
                "Name": "Shri Raghukula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4013,
                "Name": "Shri Ramaa Saraswathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nasamani/Nasikaa Bhooshani",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4014,
                "Name": "Shri Ranganathaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4015,
                "Name": "Shri Saraswathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4016,
                "Name": "Shri Shankara Guruvaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Roopakam",
                "Composer": "Maha Vaidhyanatha Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4017,
                "Name": "Shri Subramanyaya Namousthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Tisra Ekam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4018,
                "Name": "Shri Varalakshmi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4019,
                "Name": "Shri Venkatagireesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4020,
                "Name": "Shri Vigna Rajam Bhajae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gambiranattai",
                "Thalam": "Khanda Nadai",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4021,
                "Name": "Shri Vishwanatham Bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Chaturdasa Ragamalika)",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4022,
                "Name": "Shrimadadi Tyagaraja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalayani",
                "Thalam": "Roopakam",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4023,
                "Name": "Shriman narayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Composer": "Sri Annamacharya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4024,
                "Name": "Shrinivasa tavacharanu chin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4025,
                "Name": "Shrinivasa Thiru Venkatamudayan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4026,
                "Name": "Siddhi Vinayakam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4027,
                "Name": "Siddhim Dehime",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Siddhi",
                "Thalam": "Adi",
                "Composer": "Dr. Bala muralikrishna",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4028,
                "Name": "Smaranae Onde Salade",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4029,
                "Name": "Smarasada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4030,
                "Name": "Sogasugaa Mrudanga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4031,
                "Name": "Sri Narada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4032,
                "Name": "Srinivasa Karavalambam - Shwetha Sagara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Misra Nadai",
                "Composer": "Sri Muralidhara Swamiji",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4033,
                "Name": "Sudhamadhurya Bhashini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sindhuramakriya",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4034,
                "Name": "Sudhamayee Sudhanidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amruthavarshini",
                "Thalam": "Roopakam",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4035,
                "Name": "Sujana Jeevama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4036,
                "Name": "Sukhi Evvaro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4037,
                "Name": "Sundara Nandakumaara (7th Sapta Rathna Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4038,
                "Name": "Swagatam Krishna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4039,
                "Name": "Swamiki Sari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4040,
                "Name": "Swamikku Sari Evvarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhari",
                "Thalam": "Adi",
                "Composer": "Manambuchavadi Venkatasubbaiyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4041,
                "Name": "Swamikku Sari Evvarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Mishra Chapu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4042,
                "Name": "Swaminatha Paripaalayashumaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4043,
                "Name": "Taaraka Bindhige",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tilang",
                "Thalam": "Adi (Thisra Gathi)",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4044,
                "Name": "Talli Ninnunera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4045,
                "Name": "Tamboori Meetidava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4046,
                "Name": "Tanayuni Brova",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4047,
                "Name": "Teliyaleru Rama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhenuka",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4048,
                "Name": "Thaayae Yashoda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4049,
                "Name": "Thathvamariya Tharama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4050,
                "Name": "Theerada Vilayatu Pillai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Subramania Bharathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4051,
                "Name": "Themmaangu - Sundaran chEr peNmayilaE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Adi (Tisra Nadai)",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4052,
                "Name": "Thikku Teriyada Kaatil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Behag, Bowli, Kuntalavarali, Sahana, Kapi, Paras)",
                "Thalam": "Adi",
                "Composer": "Subramania Bharathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4053,
                "Name": "Thirupathi venkata ramana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4054,
                "Name": "Thoogirae Rayara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Composer": "Jaganatha Vittala",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4055,
                "Name": "Thulasi Dalamulache",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4056,
                "Name": "Thwameva Gatimama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Sri Muralidhara Swamiji",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4057,
                "Name": "Thyagaraja Gurum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Roopakam",
                "Composer": "M D Ramanathan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4058,
                "Name": "Tolijanma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Khanda Chapu",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4059,
                "Name": "Tyagaraja Mangalam-Kakarla Vamsha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Mishra Chapu",
                "Composer": "Nedunuri Krishnamurthy",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4060,
                "Name": "Umasutam Namaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Trishakthi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4061,
                "Name": "Unjal Aadinal Radhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mand",
                "Thalam": "Adi",
                "Composer": "Sri Muralidhara Swamiji",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4062,
                "Name": "Unnadiyae Gathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "G N Balasubramanian",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4063,
                "Name": "Unnai Allal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4064,
                "Name": "Vaachaamagocharamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaikavashi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4065,
                "Name": "Vaananai mathisoodiya-Thevaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Mishra Chapu",
                "Composer": "Thirunaavukarasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4066,
                "Name": "Vaanchhasi (Dhyana Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4067,
                "Name": "Vaaranam Ayiram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Andal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4068,
                "Name": "Vaaridhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4069,
                "Name": "Vaarija Nayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4070,
                "Name": "Vaatapi Ganapathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4071,
                "Name": "Vaazhiya Senthamizh",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Subramania Bharathi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4072,
                "Name": "Vadana dhyuthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4073,
                "Name": "Vadavarayai Mathaaki-Silapadhikaaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Ilangoadigal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4074,
                "Name": "Vallabha naayakasya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4075,
                "Name": "Vallikanavan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Etaiyya Ambasamudram Subbarayasaami",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4076,
                "Name": "Vandanamu Raghunandana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4077,
                "Name": "Vandeham Jagat",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Khanda Chapu",
                "Composer": "Sri Annamacharya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4078,
                "Name": "Vara Leela Gana Lola",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4079,
                "Name": "Vara naarada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijayashree",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4080,
                "Name": "Varadacharya Deva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Tisra Nadai",
                "Composer": "Dr S Ramanathan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4081,
                "Name": "Varalakshmi neeye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SalagaBhairavi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4082,
                "Name": "Varavallabha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "G N Balasubramanian",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4083,
                "Name": "Veena pustaka dharini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vegavahini",
                "Thalam": "Mishra Jhumpai",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4084,
                "Name": "Venkatachalamilayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4085,
                "Name": "Venkatachalapathe ninnu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Karnataka Kapi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4086,
                "Name": "Venkataramananae Baro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4087,
                "Name": "Venu gaana loluniganave",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Roopakam",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4088,
                "Name": "Vidajala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janaranjani",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4089,
                "Name": "Vinayaka Ninnu Vina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Ramakrishna Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4090,
                "Name": "Vinayakuni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4091,
                "Name": "Vishveshwara Darshana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4092,
                "Name": "Vittala Salaho Swami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desh",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4093,
                "Name": "Yaakae Nirdaya + Ninnanda swami virutham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Shubhapantuvarali, Valachi, Bhimplas)",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4094,
                "Name": "Yochana kamala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Tyagaraja",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4095,
                "Name": "yOga nidraE yOga mudraE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4096,
                "Name": "YogaYogeshwari (4th avarana Krithi)",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Khanda Triputa",
                "Composer": "Oothukadu Venkatasubbaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4097,
                "Name": "Aarumuga Vadivelavane (Kavadi Chinthu)",
                "Type": "Padam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi (Tisra and Chatusra nadai)",
                "Composer": "Chennikulam Annamalai Reddiyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4098,
                "Name": "Adharkullae",
                "Type": "Padam",
                "Ragam": "Pantuvarali",
                "Thalam": "Mishra Chapu",
                "Composer": "Ghanam Krishnaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4099,
                "Name": "Adinomu palamu",
                "Type": "Padam",
                "Ragam": "Begada",
                "Thalam": "Tisra Triputa",
                "Composer": "Kshetrayya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4100,
                "Name": "Alarsara (Jalajabandhu)",
                "Type": "Padam",
                "Ragam": "Surutti",
                "Thalam": "Mishra Chapu",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4101,
                "Name": "Baayarani",
                "Type": "Padam",
                "Ragam": "Hindustani Kaapi",
                "Thalam": "Roopakam",
                "Composer": "Tirupathi Narayanaswamy",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4102,
                "Name": "Bhaamaro",
                "Type": "Padam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Mishra Chapu",
                "Composer": "Kshetrayya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4103,
                "Name": "Choodarae",
                "Type": "Padam",
                "Ragam": "Sahana",
                "Thalam": "Mishra Chapu",
                "Composer": "Kshetrayya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4104,
                "Name": "Chumma Chumma (Padam)",
                "Type": "Padam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Ghanam Krishnaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4105,
                "Name": "Ella Arumaigalum",
                "Type": "Padam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Ghanam Krishnaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4106,
                "Name": "Ethanai Sonnalum",
                "Type": "Padam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Subbarama Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4107,
                "Name": "Iddari Loni",
                "Type": "Padam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Moovaanalluur Sabhaapathi ayya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4108,
                "Name": "Kaanthanodu Chellumelle (Padam)",
                "Type": "Padam",
                "Ragam": "Neelambari",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4109,
                "Name": "Maanameebhushanamu",
                "Type": "Padam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Composer": "Govindasamayya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4110,
                "Name": "Manamaebhushanamu",
                "Type": "Padam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Composer": "Kshetrayya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4111,
                "Name": "Netru andhi nerathilae",
                "Type": "Padam",
                "Ragam": "Huseni",
                "Thalam": "Roopakam",
                "Composer": "Subbarama Aiyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4112,
                "Name": "Nidirayil Sopanathil",
                "Type": "Padam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Ghanam Krishnaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4113,
                "Name": "Ososi",
                "Type": "Padam",
                "Ragam": "Mukhari",
                "Thalam": "Mishra Chapu",
                "Composer": "Muddu Natesa",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4114,
                "Name": "Thanakku Thaanae (padam)",
                "Type": "Padam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4115,
                "Name": "Theruvil vaaraano",
                "Type": "Padam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Muthu Thandavar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4116,
                "Name": "Thiruvotriyur Thyagarajan",
                "Type": "Padam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Ghanam Krishnaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4117,
                "Name": "Vaddante",
                "Type": "Padam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Kuppusami Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4118,
                "Name": "Velavarae",
                "Type": "Padam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Ghanam Krishnaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4119,
                "Name": "Yaar Poi Solluvar",
                "Type": "Padam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Ghanam Krishnaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4120,
                "Name": "Ganapathae",
                "Type": "RTP",
                "Ragam": "Kalyani",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4121,
                "Name": "Ganapathae Mahamathae",
                "Type": "RTP",
                "Ragam": "Kalyani",
                "Thalam": "Tisra Jathi Triputa thalam (Chatusra Nadai-2Kalai)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4122,
                "Name": "Maal Maruga",
                "Type": "RTP",
                "Ragam": "Bhairavi",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4123,
                "Name": "Maalmaruga Guha",
                "Type": "RTP",
                "Ragam": "Bhairavi",
                "Thalam": "Pancha nadai",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4124,
                "Name": "Mohana Muralidhara Gopala",
                "Type": "RTP",
                "Ragam": "Mohanam",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4125,
                "Name": "Naataikurinji enbaar",
                "Type": "RTP",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi (2 Kalai)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4126,
                "Name": "Naataikurinji Enbar",
                "Type": "RTP",
                "Ragam": "Naatakurinji",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4127,
                "Name": "Pannirukaiyane",
                "Type": "RTP",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi (2 Kalai)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4128,
                "Name": "Subramanyam Bhajami",
                "Type": "RTP",
                "Ragam": "Shanmugapriya",
                "Thalam": "Tisra Jathi Triputa thalam (Chatusra Nadai-2Kalai)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4129,
                "Name": "Amba Kamakshi",
                "Type": "SwaraJathi",
                "Ragam": "Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4130,
                "Name": "Gopala Lola",
                "Type": "SwaraJathi",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Ponniah Pillai (Thanjavur Quatret)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4131,
                "Name": "Nee arulaayo",
                "Type": "SwaraJathi",
                "Ragam": "Bhairavi",
                "Thalam": "Adi (2Kalai)",
                "Composer": "Ponniah Pillai (Thanjavur Quatret)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4132,
                "Name": "Rave Himagiri",
                "Type": "SwaraJathi",
                "Ragam": "Thodi",
                "Thalam": "Adi (2Kalai)",
                "Composer": "Shyama Shastry",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4133,
                "Name": "Ataana",
                "Type": "Thillana",
                "Ragam": "Atana",
                "Thalam": "Adi (Tisra + Chatusra Nadai)",
                "Composer": "Chitravina N. Ravikiran",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4134,
                "Name": "Behag",
                "Type": "Thillana",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4135,
                "Name": "Behag",
                "Type": "Thillana",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Chitravina N. Ravikiran",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4136,
                "Name": "Bhimplas/Abheri",
                "Type": "Thillana",
                "Ragam": "Bhimplas/Abheri",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4137,
                "Name": "Bilahari",
                "Type": "Thillana",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Ariyakudi Ramanuja Iyengar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4138,
                "Name": "Brindavana Saranga",
                "Type": "Thillana",
                "Ragam": "Brindavana Saranga",
                "Thalam": "Adi",
                "Composer": "Dr. Bala muralikrishna",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4139,
                "Name": "Chenchurutti",
                "Type": "Thillana",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Veena Seshanna",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4140,
                "Name": "Desh",
                "Type": "Thillana",
                "Ragam": "Desh",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4141,
                "Name": "Dhanashri",
                "Type": "Thillana",
                "Ragam": "Dhanashri",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4142,
                "Name": "Hamsanandi",
                "Type": "Thillana",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "G N Balasubramanian",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4143,
                "Name": "Kaanada",
                "Type": "Thillana",
                "Ragam": "Kaanada",
                "Thalam": "Roopakam",
                "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4144,
                "Name": "Khamaas",
                "Type": "Thillana",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4145,
                "Name": "Khamaas",
                "Type": "Thillana",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4146,
                "Name": "Khamaas",
                "Type": "Thillana",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4147,
                "Name": "Madhuvanthi",
                "Type": "Thillana",
                "Ragam": "Madhuvanthi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4148,
                "Name": "Mandhari",
                "Type": "Thillana",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Composer": "Ponniah Pillai (Thanjavur Quatret)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4149,
                "Name": "MohanaKalyani",
                "Type": "Thillana",
                "Ragam": "Mohanakalyani",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4150,
                "Name": "Nalina Kaanthi",
                "Type": "Thillana",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4151,
                "Name": "Paras",
                "Type": "Thillana",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4152,
                "Name": "Poornachandrika",
                "Type": "Thillana",
                "Ragam": "Poornachandrika",
                "Thalam": "Adi",
                "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4153,
                "Name": "Revathi",
                "Type": "Thillana",
                "Ragam": "Revathi",
                "Thalam": "Mishra Chapu",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4154,
                "Name": "Shankarabharanam",
                "Type": "Thillana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Ponniah Pillai (Thanjavur Quatret)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4155,
                "Name": "Shankarabharanam",
                "Type": "Thillana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Veenai Seshannah",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4156,
                "Name": "Thillana - Darbari Kaanadaa",
                "Type": "Thillana",
                "Ragam": "Darbari Kaanadaa",
                "Thalam": "Adi",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4157,
                "Name": "Yadukula Kambhoji ",
                "Type": "Thillana",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4158,
                "Name": "Chalamela-Shankarabharanam",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4159,
                "Name": "Kamalaakshi-YadukulaKhambhoji",
                "Type": "Varnam-Tana",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Thiruvotriyur Tyagayyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4160,
                "Name": "Nera Nammithi-Kaanada",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanada",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4161,
                "Name": "Sarasijanabha-Khambhoji",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Vadivelu/Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4162,
                "Name": "Vanajakshi-Kalyani",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Pallavi Gopalaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4163,
                "Name": "Viriboni-Bhairavi",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Pachaimiriam Adiyappa",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4164,
                "Name": "Mathe Malayadwaja-Khamas",
                "Type": "Varnam-Dharu",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4165,
                "Name": "Amma Varnam - Gambiranattai",
                "Type": "Varnam-Pada",
                "Ragam": "Gambiranattai",
                "Thalam": "Adi",
                "Composer": "Dr. Bala muralikrishna",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4166,
                "Name": "AngayarKanni-Navarasa Varnam",
                "Type": "Varnam-Pada",
                "Ragam": "Ragamalika (Bilahari, Huseni, Valachi, Saranga, Sucharitra, Atana, Rasikapriya, Sahana, Nadanamakriya)",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4167,
                "Name": "Chalamela-NattaKurinji",
                "Type": "Varnam-Pada",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Mulai Veedu Rangaswamy Nattuvanar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4168,
                "Name": "Dani samajendra-Thodi",
                "Type": "Varnam-Pada",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4169,
                "Name": "Entha nee naedhalu-Khamas",
                "Type": "Varnam-Pada",
                "Ragam": "Khamas",
                "Thalam": "Thisra Ekam",
                "Composer": "Subbarama Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4170,
                "Name": "Innum En Manam-Charukesi",
                "Type": "Varnam-Pada",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4171,
                "Name": "Mohamana-Bhairavi",
                "Type": "Varnam-Pada",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Ponniah Pillai (Thanjavur Quatret)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4172,
                "Name": "Nee Indha Maayam-Dhanyasi",
                "Type": "Varnam-Pada",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4173,
                "Name": "Roopamu Joochi-Thodi",
                "Type": "Varnam-Pada",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4174,
                "Name": "Sami neeramma-Khamas/Sami Rammanave",
                "Type": "Varnam-Pada",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Ponniah Pillai (Thanjavur Quatret)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4175,
                "Name": "Sami Ninne-Ragamalika",
                "Type": "Varnam-Pada",
                "Ragam": "Ragamalika (Thodi, Shankarabharanam, Pantuvarali, Atana, Bhairavi, Kalyani, Vasantha, Mukhari)",
                "Thalam": "Roopakam",
                "Composer": "Ponniah Pillai (Thanjavur Quatret)",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4176,
                "Name": "Swami Naan Undran-Naataikurinji",
                "Type": "Varnam-Pada",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4177,
                "Name": "Amboruha-Ranjani",
                "Type": "Varnam-Tana",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Composer": "G N Balasubramanian",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4178,
                "Name": "Annamae - Arabhi",
                "Type": "Varnam-Tana",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Tiger Varadachariar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4179,
                "Name": "Arunodayamae-Bhowli",
                "Type": "Varnam-Tana",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4180,
                "Name": "Chalamu-Valachi",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4181,
                "Name": "Enta Prematho-Bahudari",
                "Type": "Varnam-Tana",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4182,
                "Name": "Entho Premato-Surutti",
                "Type": "Varnam-Tana",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Tyagayyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4183,
                "Name": "Era Napai-Thodi",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4184,
                "Name": "Evvari Bodhana-Abhogi",
                "Type": "Varnam-Tana",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4185,
                "Name": "Intha Chalamu-Begada",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4186,
                "Name": "Intha Modi-Saranga",
                "Type": "Varnam-Tana",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Tyagayyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4187,
                "Name": "Intha Tamasa-Kannada",
                "Type": "Varnam-Tana",
                "Ragam": "Kannada",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4188,
                "Name": "Jalajakshi-Hamsadhwani",
                "Type": "Varnam-Tana",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Manambuchavadi Venkatasubbaiyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4189,
                "Name": "Karunimpa-Sahana",
                "Type": "Varnam-Tana",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Tyagayyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4190,
                "Name": "Neevae gati-Nalinakanthi",
                "Type": "Varnam-Tana",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4191,
                "Name": "Neevae Rakshaku-Kathanakuthuhalam",
                "Type": "Varnam-Tana",
                "Ragam": "Kathanakudhoohalam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4192,
                "Name": "Ninnukori-Mohanam",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4193,
                "Name": "Ninnukori-Vasantha",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Tachoor Singarachariar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4194,
                "Name": "Nivegani-Mandari",
                "Type": "Varnam-Tana",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4195,
                "Name": "Sami Daya Juda-KedaraGowlai",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Tyagayyar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4196,
                "Name": "Sami Ninne Kori-Shudda Thodi",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Thodi",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4197,
                "Name": "Sami Ninne-Pantuvarali",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Satkala Narasayya",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4198,
                "Name": "Sami Ninne-Shankarabharanam",
                "Type": "Varnam-Tana",
                "Ragam": "Dheera Sankarabharanam",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4199,
                "Name": "Sami Ninne-Shree",
                "Type": "Varnam-Tana",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4200,
                "Name": "Sarasuda-Saveri",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Kottuvasal Venkata Ramaiyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4201,
                "Name": "Sumasaayaka-Karnataka Kapi",
                "Type": "Varnam-Tana",
                "Ragam": "Karnataka Kapi",
                "Thalam": "Thisra Ekam",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4202,
                "Name": "Tharunam yaen-Sama",
                "Type": "Varnam-Tana",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4203,
                "Name": "Tharuni ninnu-Khambhoji",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Violin Ponnuswamy",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4204,
                "Name": "Thirumal maruga-Andolika",
                "Type": "Varnam-Tana",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4205,
                "Name": "Valachi vacchi-Nava Ragamalika",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4206,
                "Name": "Vallabhai Nayaka-Mohana Kalyani",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanakalyani",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4207,
                "Name": "Vanajakshiro-Kalyani",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4208,
                "Name": "Vanjaksha-Behag",
                "Type": "Varnam-Tana",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "TRS",
                "BookTitle": "Personal Notation",
                "BookAuthor": "Personal Collection",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 4209,
                "Name": "Undu Kuladeiva raaman",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "On Lord Rama",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4210,
                "Name": "GangaiANi senchadaiyanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Ekam",
                "Praise of": "On Lord Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4211,
                "Name": "KadaikkaN nokki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "On Mylapore Kabaleeshwarar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4212,
                "Name": "Kalitheerumo karuNai varumo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4213,
                "Name": "Karthikeya Kaangeya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4214,
                "Name": "Kundram kudi konda velavaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4215,
                "Name": "Taamasam yaen swami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4216,
                "Name": "Devi paadam panindhaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "On Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4217,
                "Name": "Pathitha paavana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "On Lord Rama",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4218,
                "Name": "Raajagopaala devaadhi devanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Praise of": "On Rajagopalaswami",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4219,
                "Name": "Thiruvazhundhoor maadhavaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Triputa",
                "Praise of": "On Thiruvazhundur Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4220,
                "Name": "Mariyaadhai thaanae ayyanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4221,
                "Name": "Unpaadhamae charanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Praise of": "On Thirukudandhai Sarangapani",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4222,
                "Name": "Shiva ganga nagara nivasini Sri rajarajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4223,
                "Name": "Padamalarae gathiyena",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4224,
                "Name": "adi malar iNai illaamal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4225,
                "Name": "kalladha vezhai allavo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4226,
                "Name": "Dinameedhae natrinamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4227,
                "Name": "Marundhalitharulvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Praise of": "On Lord Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4228,
                "Name": "Eesanae indha vezhaikku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Roopakam",
                "Praise of": "On Lord Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4229,
                "Name": "Thaayae ezhaipaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "On Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4230,
                "Name": "Nee gathiyalladhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4231,
                "Name": "Sree Shanmukham sumukham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4232,
                "Name": "aLavillaiyae nilaayathaakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4233,
                "Name": "endha vidha munnarul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Jhampai",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4234,
                "Name": "Sollu paapaa sollu paapaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4235,
                "Name": "Singaaravelavan vandhaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4236,
                "Name": "Ninai manamae skandhanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Praise of": "On Muruga",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4237,
                "Name": "vaanor vanangum annaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "On Lakshmi-Saraswathi-Parvathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4238,
                "Name": "vandhadhellam varattum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4239,
                "Name": "Nee arul puriya vaendum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Triputa",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4240,
                "Name": "paaraamukham yaenayya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4241,
                "Name": "Sreenivasa tava charana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4242,
                "Name": "Thathvamariya tharamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Praise of": "On Ganesha",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4243,
                "Name": "malariNai thuNaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Roopakam",
                "Praise of": "On Lord Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4244,
                "Name": "Inbam enbadhilayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4245,
                "Name": "Karpagamae kadaikaN",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "On Mylapore Karpagambal",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4246,
                "Name": "Saamaja varagamana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "On Lord Rama",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4247,
                "Name": "Raamam bhajatha manujaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Praise of": "On Lord Rama",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4248,
                "Name": "Naam uravu koLLum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4249,
                "Name": "Raadhaa mukha kamala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindustani Kaapi",
                "Thalam": "Adi",
                "Praise of": "On Radha Devi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4250,
                "Name": "Undaendru urudhi koLvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4251,
                "Name": "Kamalap padha malariNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4252,
                "Name": "KaaNak kaN kODI",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "On Mylapore Kabaleeshwarar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4253,
                "Name": "Sikkal maviya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Praise of": "On Muruga",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4254,
                "Name": "Kumaran thaaL paNindhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4255,
                "Name": "Unnai thudhikka arul thaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntala Varali",
                "Thalam": "Adi",
                "Praise of": "On Lord Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4256,
                "Name": "Dharmasamvardhanith thaayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Praise of": "On Dharmasamvardhini",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4257,
                "Name": "Anbilayae nin pada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4258,
                "Name": "KadaikkaN paarvai adhu podhumae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4259,
                "Name": "ThiruvaLar mayilayin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "On Mylapore Karpagamabal",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4260,
                "Name": "parpala porpaNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4261,
                "Name": "Sree raaman ravikula soman",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NarayanaGowlai",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4262,
                "Name": "kannmanamurugach seiya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4263,
                "Name": "Piraviyadhanaar payanen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4264,
                "Name": "Sree Ganapathiyae thiruvarul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Praise of": "On Ganesha",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4265,
                "Name": "Abhayaambika ramana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "On Lord Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4266,
                "Name": "Chandra kalaavadhamsam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4267,
                "Name": "Thirukkumaranaai avadharithaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4268,
                "Name": "Padmanabhan maruga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4269,
                "Name": "Saa sabha nabhonibha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "Group": "Sabha Varnanai",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4270,
                "Name": "Gaanarasamudan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4271,
                "Name": "Mahaprabho sree",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4272,
                "Name": "Swami undan charanam charanam",
                "Type": "Varnam-Tana",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4273,
                "Name": "Karpagaambikae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4274,
                "Name": "Chitham Maghizh vithidudhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4275,
                "Name": "Paadha malarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4276,
                "Name": "Kaa vaa vaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4277,
                "Name": "Unadu dhayai yenai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Triputa",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4278,
                "Name": "Neeyallavo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4279,
                "Name": "Dasharadhaathmajam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4280,
                "Name": "Iha param ennum iru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4281,
                "Name": "Aandavanae unnai nambinaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4282,
                "Name": "Paraathpara Parameshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Praise of": "On Lord Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4283,
                "Name": "Kaameshwara Krupaakara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4284,
                "Name": "Chidambaram yena manam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4285,
                "Name": "Sindhai theLindhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4286,
                "Name": "Theril yaerinaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4287,
                "Name": "Devi Sree Meenalochani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "On Meenakshi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4288,
                "Name": "Padmanabha maam paahi paahi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4289,
                "Name": "Raamaa Idhu dharmamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4290,
                "Name": "Amba un mel bhaaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4291,
                "Name": "Sree Maadhava Vasudeva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4292,
                "Name": "Krishnanaama Bhajarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4293,
                "Name": "Naama Bhajarae Raama Naama Bhajarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4294,
                "Name": "Jayavijayee Bhava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4295,
                "Name": "Kabali, Karunai nilavu pozhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4296,
                "Name": "Gathiyudhavu maamadhu ninadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Kapi, Vasantha, Bilahari, Sowrashtram)",
                "Thalam": "Jhampai",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4297,
                "Name": "Maara janaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (YadukulaKahmbhoji, Saveri, Begada, Saraswathi, GowriManohari)",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4298,
                "Name": "Dharaathmajaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4299,
                "Name": "Eesanan paarkuvamai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Roopakam",
                "Praise of": "On Lord Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4300,
                "Name": "Manak kurangu adangavidil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4301,
                "Name": "Sree raama jaya mangalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4302,
                "Name": "KaruNai seivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "On Ganesha",
                "Composer": "Papanasam Sivan",
                "Group": "Tune based on Raghunayaka-Hamsadhwani - Tyagaraja",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4303,
                "Name": "Nijam unnai nambinaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Jhampai",
                "Composer": "Papanasam Sivan",
                "Group": "Tune based on Paridhana Michite-Bilahari",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4304,
                "Name": "Nin Charana malarae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Mishra Chapu",
                "Composer": "Papanasam Sivan",
                "Group": "Tune based on NeedhuCharanaMulae",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4305,
                "Name": "Haranae innum dayai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KarnatakaBehag",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Group": "Tune based on Hari-Naenendhu vedhakudhuraa",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4306,
                "Name": "Kaarirul oththa karutha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Group": "Tune based on Thirupugazh-Devan vadithu sorindhadho",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4307,
                "Name": "AaraNamum aagamamum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Jhampai",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4308,
                "Name": "Anbargalae aNNan thambigalae",
                "Type": "Vazhinadai Chindu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4309,
                "Name": "Punniya ayodhi nagaril",
                "Type": "Nondi Chindu",
                "Composer": "Papanasam Sivan",
                "Group": "Sree Rama Charithram - Hanuman narrating to Bharathan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 1",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1939,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4310,
                "Name": "Kosalai pudhalvanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Naatai, Kharaharapriya, Pantuvarali, Shuddha Saveri, Mukhari, Sahana, Vasantha, Thodi, Mohanam, Nadanamakriya, Khamas, Kalyani, AnandaBhairavi, Abhogi, Khambhoji, Simhendramdhyamam, Bhairavi, Arabhi, Kedaram, Navarasa Kannadaa, Manirangu, BrindavanaSaranga, Surutti, Madhyamavathi)",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Group": "Sree Rama Charithra Geetham",
                "BookTitle": "Papanasam Sivan's Sri Raama Charitra Keerthanam",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1998,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4311,
                "Name": "Amboruhapaadhamae",
                "Type": "Varnam-Tana",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4312,
                "Name": "kuvalayaakshi kushalam",
                "Type": "Varnam-Tana",
                "Ragam": "Kathanakudhoohalam",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4313,
                "Name": "Vara vallabha ramana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4314,
                "Name": "santhathamu ninne",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4315,
                "Name": "saama gaana lolae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Roopakam",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4316,
                "Name": "paramakrupaa saagari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Roopakam",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4317,
                "Name": "Sree chakra raja nilayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShivaSakthi",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4318,
                "Name": "Indha paraaku neevae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4319,
                "Name": "kamal charanaE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "AmruthaBehag",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4320,
                "Name": "parithaapamulaeka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4321,
                "Name": "Ninnu pogada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntala Varali",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4322,
                "Name": "vadharaerammaa nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4323,
                "Name": "Marivere gati naakevvaru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maalavi",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4324,
                "Name": "manasaaraganae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4325,
                "Name": "KaaraNamaeme",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Andolika",
                "Thalam": "Roopakam",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4326,
                "Name": "bhuvanathraya sammohana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4327,
                "Name": "madhuraapuri kalyani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4328,
                "Name": "mamakulaeshwaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Tisra Jathi Eka Thalam",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4329,
                "Name": "Entho moralida",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannada",
                "Thalam": "Roopakam",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4330,
                "Name": "naamoralanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Narayani",
                "Thalam": "Roopakam",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4331,
                "Name": "samaana rahithae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SaarangaTharangini",
                "Thalam": "Roopakam",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4332,
                "Name": "Karuna jooda radha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4333,
                "Name": "bhaaramaa e baaluni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanaadam",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4334,
                "Name": "varada nibhuNa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4335,
                "Name": "Mohana krishna jagat",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kathanakudhoohalam",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4336,
                "Name": "Niravadhi sukha daayaki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Roopakam",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4337,
                "Name": "nannubrova nee garudha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SenjuKhambhoji",
                "Thalam": "Roopakam",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4338,
                "Name": "kavalai ellam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4339,
                "Name": "padamalariNaiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4340,
                "Name": "Unnadiyae Gathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4341,
                "Name": "En manath thaamarai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4342,
                "Name": "kanavilum kamala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "GNB",
                "BookTitle": "Compositions of GNB (G N Balasubramaniam)",
                "BookAuthor": "G. N. Balasubramaniam",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4343,
                "Name": "Sami ninne Kori",
                "Type": "Varnam-Tana",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4344,
                "Name": "Kamalambha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4345,
                "Name": "Karunaanidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4346,
                "Name": "ambha ninnu nera nammithi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Kalyani, Gowlipantu, Naatakurinji, Chakravaham, Neelambari)",
                "Thalam": "Roopakam",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4347,
                "Name": "koniyaada tharamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4348,
                "Name": "brochuda kevarunnaaru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4349,
                "Name": "maruvaga dhaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4350,
                "Name": "Yaemani pogadudhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4351,
                "Name": "raama raama ravikula somaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bangaala",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4352,
                "Name": "nera mencha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4353,
                "Name": "naamanavini vinavaiyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Roopakam",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4354,
                "Name": "yaeminaeramu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Garudadhwani",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4355,
                "Name": "enthani naevaedu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4356,
                "Name": "Endhuku nirdhaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4357,
                "Name": "nannu karuNinchi brovu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4358,
                "Name": "Brova samayamidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4359,
                "Name": "mari mari ninne",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4360,
                "Name": "praNadhaarthi hara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4361,
                "Name": "ninnu nammina vaaDanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4362,
                "Name": "Dhurusugaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Roopakam",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4363,
                "Name": "Sree mahaa ganapathy",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4364,
                "Name": "yadukua thilaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4365,
                "Name": "Enthani ninunae",
                "Type": "Javali",
                "Ragam": "Unknown",
                "Thalam": "Chapu",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4366,
                "Name": "yevathe thaaLunuraa",
                "Type": "Javali",
                "Ragam": "Surutti",
                "Thalam": "Eka",
                "Composer": "Karur Devudu Iyer",
                "BookTitle": "Garbapurivaasar Urupadigal",
                "BookAuthor": "Karur Devudu Iyer",
                "Year of Publication": 1968,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4367,
                "Name": "Bhuddhi Devi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malahari",
                "Thalam": "Mishra Chapu",
                "Praise of": "On Ganesha",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Sthuthi Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4368,
                "Name": "Sree mangaLavANi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Roopakam",
                "Praise of": "On Saraswathi",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Sthuthi Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4369,
                "Name": "Maha bhaleshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Roopakam",
                "Praise of": "On Mahabaleshwar",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Sthuthi Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4370,
                "Name": "NaaraayaNaa naaga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Praise of": "On Vishnu-Narayana",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Sthuthi Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4371,
                "Name": "Guru naathaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pushpalatha",
                "Thalam": "Khanda Chapu",
                "Praise of": "On Guru",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Sthuthi Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4372,
                "Name": "Sree Aanjaneya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Praise of": "On Lord Hanuman",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Sthuthi Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4373,
                "Name": "Sampath pradae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4374,
                "Name": "Thappa manni sow",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maayapratheebam",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4375,
                "Name": "Sreemadh simhaasaneshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4376,
                "Name": "Sree vidya vedhya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4377,
                "Name": "Sree chakrapura vaasini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gamanashrama",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4378,
                "Name": "Sree Kanta dayithae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malavi",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4379,
                "Name": "Devi Gowri ninna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Khanda Chapu",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4380,
                "Name": "Girija devi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Veenaadhari",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4381,
                "Name": "Bhuvaneshwarya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanakalyani",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4382,
                "Name": "Mahakaali mahharagjnye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Chatusra Jathi Jhampa Thalam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4383,
                "Name": "Devi sree Mahalakshmi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariNaarayani",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4384,
                "Name": "Amba mahavaaNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SaraswathiManohari",
                "Thalam": "Khanda Chapu",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4385,
                "Name": "manOnmaNi mandhahAsini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4386,
                "Name": "sahasra seersha samyukthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaLalitha",
                "Thalam": "Khanda Jathi Jhampa Thalam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4387,
                "Name": "sahasrakara manDithaayai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4388,
                "Name": "koushumbha vasano",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Karanataka Kapi",
                "Thalam": "Mishra Chapu",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4389,
                "Name": "Rathna kanjuka dhaariNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4390,
                "Name": "Ganesha Skanda janani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagabhushani",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4391,
                "Name": "japaakusuma bhaasurae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4392,
                "Name": "Umaaninagae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4393,
                "Name": "kaathyaayani skanda janani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4394,
                "Name": "Durgaa devi duritha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kannadaa",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4395,
                "Name": "MandriNi maathanga thanayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4396,
                "Name": "Dhandini sree chandikaambae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SalagaBhairavi",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4397,
                "Name": "jayaadevi jagan maathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowdaMalhaar",
                "Thalam": "Triputa",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4398,
                "Name": "karaanguli nagOthpanna naarayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Ata",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4399,
                "Name": "sachaamara ramaavaani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4400,
                "Name": "saraseeruhaakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4401,
                "Name": "BhagaLaadevya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4402,
                "Name": "Bhaalae paripaali sow",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4403,
                "Name": "Chakresi devi sree",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakrapradeepta",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4404,
                "Name": "vijayaambikae vimalaathmikae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijayanagari",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4405,
                "Name": "panchaasya yaanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Khanda Chapu",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4406,
                "Name": "haridhraa kumkuma priyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Mishra Chapu",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4407,
                "Name": "Mahabalaathri nilayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4408,
                "Name": "jaya mahishaasuramardhini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4409,
                "Name": "madhukadaipa samharthri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4410,
                "Name": "madhuraapura naayikae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4411,
                "Name": "kaameshwari sree kaamakshi gowri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurunji",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4412,
                "Name": "yOga nidraE yOga mudraE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4413,
                "Name": "Thaayae Bhavani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4414,
                "Name": "Chandikae sree chamundeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Narayani",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4415,
                "Name": "sathi devya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4416,
                "Name": "Chakra raaja rathaarudae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4417,
                "Name": "Shrushti sthithyantha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4418,
                "Name": "annapoornaeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4419,
                "Name": "jwallajihvae jwaalaamukhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4420,
                "Name": "kaaLaraadhri swaroopini amba",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Urmika",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4421,
                "Name": "niraamayae niranjanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntala Varali",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4422,
                "Name": "rathna bhushani rathnaambharae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4423,
                "Name": "parama mangaLathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dwijavanthi",
                "Thalam": "Mishra Chapu",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4424,
                "Name": "Sree Shubhaa devi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4425,
                "Name": "sakala sampath pradae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Jhampai",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4426,
                "Name": "moola prakriti roopae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4427,
                "Name": "aaryaa devi amaresha nuthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4428,
                "Name": "parama paavanae paarvathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MeghaRanjani",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4429,
                "Name": "sree parameshwari devi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4430,
                "Name": "Bindu peeta kruthaa vaasae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Mishra Chapu",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4431,
                "Name": "chandra mandala madhyagae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4432,
                "Name": "Chidagni kunda sambhuthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4433,
                "Name": "Vindhyaachala nivaasini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NarayanaGowlai",
                "Thalam": "Triputa",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4434,
                "Name": "haragriva agastya poojithae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "UmaBharanam",
                "Thalam": "Khanda Chapu",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4435,
                "Name": "Soorya chandraagni lochanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4436,
                "Name": "Jaalandhara supeetasthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4437,
                "Name": "Sree shivae jaya vaibhavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4438,
                "Name": "Devi Daakshaayani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balahamsa",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4439,
                "Name": "Eeshwari raajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4440,
                "Name": "Nivaarana sampoojyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GuhaRanjani",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4441,
                "Name": "navaakshara manu sthuthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaratnavilasam",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4442,
                "Name": "Nava laavanya roopaadyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4443,
                "Name": "Dwwadreem sadjwala daayuthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Khanda Chapu",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4444,
                "Name": "kaamesha bhaddha maangalyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BudhaManohari",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4445,
                "Name": "Chandra rekha vibhushithae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4446,
                "Name": "charaachara jagatroopae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nayaki",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4447,
                "Name": "nithyaklinae nishkalangae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4448,
                "Name": "aparaajithae amareshanuthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannada",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4449,
                "Name": "Uraga raaja mani valayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pasupathipriya",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4450,
                "Name": "Sree lalitha jagan maathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4451,
                "Name": "maha vishnu sodari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pasupathipriya",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4452,
                "Name": "Devi sree damshtraagaraaLa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4453,
                "Name": "Vajreshi maathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4454,
                "Name": "vanhi vaasini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4455,
                "Name": "sarva mangaLa rUpADyaE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Mishra Chapu",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4456,
                "Name": "Sachidaananda vigrahae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devagandhari",
                "Thalam": "Khanda Chapu",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4457,
                "Name": "Ashtaadasha SupeeDasthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4458,
                "Name": "bhEruNdaambae bheDuvE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4459,
                "Name": "Bhairavi parameshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4460,
                "Name": "paraathparae bhaagarinuthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4461,
                "Name": "ruNDamaala lasathkanDe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhushavali",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4462,
                "Name": "bhaNDaasura vimardhini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4463,
                "Name": "purandara vinuthae puNDrekshu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4464,
                "Name": "Sugandha pushpa bhaaNa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4465,
                "Name": "Devi shiva dhoothi paradhaevathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4466,
                "Name": "vedha maathae vedha vinuthae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4467,
                "Name": "shAnkari shAdodhari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4468,
                "Name": "Simhavaahanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhavahini",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4469,
                "Name": "chadhushashti upachaaraadyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaroj",
                "Thalam": "Triputa",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4470,
                "Name": "yogini gaNasEvithE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4471,
                "Name": "vanadurgE vanaspathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vanaspathi",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4472,
                "Name": "BhadrakALi namOusthuthE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4473,
                "Name": "kadambha vanavaasini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Triputa",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4474,
                "Name": "chanDa munDa siraschEdhri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Garudadhwani",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4475,
                "Name": "maharAgny mahAlakshmi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlipanthu",
                "Thalam": "Triputa",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4476,
                "Name": "sudhAmayi sudhAnidhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amruthavarshini",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4477,
                "Name": "Sree chakra vara thaatangae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janaranjani",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4478,
                "Name": "Sree shaila brahmaraambhikae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kamala Manohari",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4479,
                "Name": "Sree raajaraaja varadhE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Roopakam",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4480,
                "Name": "Sreeamdh tripura sundari ambA",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4481,
                "Name": "Jaya mangalam nithya Shubha mangalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "On Chamundeshwari",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams - Mangalam",
                "BookTitle": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
                "BookAuthor": "Harikesanallur L. Muthiah Bhagavathar",
                "Year of Publication": 1969,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4482,
                "Name": "Sami ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4483,
                "Name": "Ra Ra Sami",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4484,
                "Name": "Ninne emtho",
                "Type": "Varnam-Tana",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4485,
                "Name": "Ninnukori",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4486,
                "Name": "Vanajaakshaa",
                "Type": "Varnam-Tana",
                "Ragam": "Varali",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4487,
                "Name": "Sami ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Narayani",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4488,
                "Name": "Nera Nammithi-Kaanada",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanada",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4489,
                "Name": "Thaani kori kenu",
                "Type": "Varnam-Pada",
                "Ragam": "Vasantha",
                "Thalam": "Jhampai",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4490,
                "Name": "Chindadeerachara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4491,
                "Name": "Parithaapamula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannada",
                "Thalam": "Jhampai",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4492,
                "Name": "Sree raama nannu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Jhampai",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4493,
                "Name": "Karunatho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MeghaRanjani",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4494,
                "Name": "Raghunaathanannu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SuraRanjani",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4495,
                "Name": "Anudinamunu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4496,
                "Name": "Raamaa ninne",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4497,
                "Name": "Nijamukharaama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4498,
                "Name": "Sadbhakthiyu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4499,
                "Name": "Parulaseva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natabhairavi",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4500,
                "Name": "Ninnu kolisi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4501,
                "Name": "Idu paraagu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NarayanaGowlai",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4502,
                "Name": "Sathathamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4503,
                "Name": "Chinthakinji",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SaraswathiManohari",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4504,
                "Name": "Samayamidhaeyani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4505,
                "Name": "Saamaja varadhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4506,
                "Name": "Paarthasaarathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4507,
                "Name": "Nee paadhamulae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kannadaa",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4508,
                "Name": "Paripaalayamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4509,
                "Name": "Neekela naayaDa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4510,
                "Name": "Sree venkatesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4511,
                "Name": "Sree raghukula nidhim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4512,
                "Name": "Sadguru swami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4513,
                "Name": "Parama paavana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4514,
                "Name": "Saraguna paalimpa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4515,
                "Name": "Nirupamaana",
                "Type": "Javali",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4516,
                "Name": "Marulukonnadira",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4517,
                "Name": "Veganeevuvaani",
                "Type": "Javali",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4518,
                "Name": "VathaninE",
                "Type": "Javali",
                "Ragam": "Kapi",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4519,
                "Name": "SaanarO",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4520,
                "Name": "SarasamulaadE",
                "Type": "Javali",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4521,
                "Name": "saaraghumera",
                "Type": "Javali",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4522,
                "Name": "Thillana-Thodi",
                "Type": "Thillana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4523,
                "Name": "Thillana-Poornachandrika",
                "Type": "Thillana",
                "Ragam": "Poornachandrika",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4524,
                "Name": "Thillana-YadukulaKhambhoji",
                "Type": "Thillana",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4525,
                "Name": "Thillana-Kapi",
                "Type": "Thillana",
                "Ragam": "Kapi",
                "Thalam": "Lakshmeesham",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "Group": "108 Thala Thillana",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4526,
                "Name": "Thillana-Sindhu Bhairavi",
                "Type": "Thillana",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "Group": "108 Thala Thillana",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4527,
                "Name": "Thillana-Naatakurinji",
                "Type": "Thillana",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "Group": "108 Thala Thillana",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4528,
                "Name": "Thillana-Hindolam",
                "Type": "Thillana",
                "Ragam": "Hindolam",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "Group": "108 Thala Thillana",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4529,
                "Name": "Thillana-Pantuvarali",
                "Type": "Thillana",
                "Ragam": "Pantuvarali",
                "Thalam": "Ragavardhani",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "Group": "72 Thala Thillana",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4530,
                "Name": "Thillana-Kapi",
                "Type": "Thillana",
                "Ragam": "Kapi",
                "Thalam": "Deshadi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "Group": "72 Thala Thillana",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4531,
                "Name": "Thillana-Kaanadaa",
                "Type": "Thillana",
                "Ragam": "Kaanada",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "Group": "72 Thala Thillana",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4532,
                "Name": "Thillana-Shankarabharanam",
                "Type": "Thillana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Deshadi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "Group": "72 Thala Thillana",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4533,
                "Name": "Thillana-Paras",
                "Type": "Thillana",
                "Ragam": "Paras",
                "Thalam": "Deshadi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "Group": "72 Thala Thillana",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4534,
                "Name": "Valliyin kalyanam",
                "Type": "KavadiChindu",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4535,
                "Name": "Saami neepai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Sarasangi, Saveri, Navaroj, Lalitha, Varali, Arabhi, Khamas, Bhupalam, Sree)",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Compositions of Ramanathapuram Sriman \"Poochi\" Srinivasa Iyengar",
                "BookAuthor": "Poochi Srinivasa Iyengar",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4536,
                "Name": "Paripaahimaam girijaapathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Composer": "Veena Subbanna",
                "BookTitle": "Four rare compositions of Veena Subbanna",
                "BookAuthor": "Veena Subbanna",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4537,
                "Name": "Swarajathi-Khambhoji",
                "Type": "SwaraJathi",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Veena Subbanna",
                "BookTitle": "Four rare compositions of Veena Subbanna",
                "BookAuthor": "Veena Subbanna",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4538,
                "Name": "Swarajathi-KarnatakaKapi",
                "Type": "SwaraJathi",
                "Ragam": "Karnataka Kapi",
                "Thalam": "Adi",
                "Composer": "Veena Subbanna",
                "BookTitle": "Four rare compositions of Veena Subbanna",
                "BookAuthor": "Veena Subbanna",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4539,
                "Name": "Paripaahi Sree chaamundeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Bhushavali, Pancharam, NadaBrahmam, ShuddhaSaveri, KarnatakaBehag, Kannada, Begada, Saveri, Shankarabharanam, Mohanam, AnandaBhairavi, Madhyamavathi)",
                "Thalam": "Adi",
                "Composer": "Veena Subbanna",
                "BookTitle": "Four rare compositions of Veena Subbanna",
                "BookAuthor": "Veena Subbanna",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4540,
                "Name": "SaameethiveLa",
                "Type": "Varnam-Tana",
                "Ragam": "Kannada",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4541,
                "Name": "thaamasinjaka",
                "Type": "Varnam-Tana",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4542,
                "Name": "Ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Ahiri",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tarangampadi Panchanadayyar",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4543,
                "Name": "neevantidaivamu",
                "Type": "Varnam-Tana",
                "Ragam": "Neelambari",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tarangampadi Panchanadayyar",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4544,
                "Name": "marasitLundEthi",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4545,
                "Name": "Daari theliyaka",
                "Type": "Varnam-Tana",
                "Ragam": "Darbar",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4546,
                "Name": "nenaruleni",
                "Type": "Varnam-Tana",
                "Ragam": "Balahamsa",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4547,
                "Name": "Vanajaksha",
                "Type": "Varnam-Tana",
                "Ragam": "Varali",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4548,
                "Name": "Sami ninnekori",
                "Type": "Varnam-Tana",
                "Ragam": "Narayani",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4549,
                "Name": "Thaani kori kenu",
                "Type": "Varnam-Pada",
                "Ragam": "Vasantha",
                "Thalam": "Jhampai",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4550,
                "Name": "Saamini rammanavae",
                "Type": "Varnam-Pada",
                "Ragam": "Kedaram",
                "Thalam": "Roopakam",
                "Composer": "Thiruvotriyur Tyagayyar",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4551,
                "Name": "parabrahmamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapinarayani",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4552,
                "Name": "Nannu brova",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavam",
                "Thalam": "Roopakam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4553,
                "Name": "Idivaarulaetiki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4554,
                "Name": "Aasamayamuna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4555,
                "Name": "naamavini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Roopakam",
                "Composer": "Garbhapurivasar",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4556,
                "Name": "Yaemi neramu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Garudadhwani",
                "Thalam": "Adi",
                "Composer": "Garbhapurivasar",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4557,
                "Name": "Teliyaga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Garbhapurivasar",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4558,
                "Name": "samayamidhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4559,
                "Name": "ninnu kolisi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4560,
                "Name": "Raama ninne",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4561,
                "Name": "parulaseva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natabhairavi",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4562,
                "Name": "nijamukha raama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4563,
                "Name": "Idu paraagu jaeya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NarayanaGowlai",
                "Thalam": "Roopakam",
                "Composer": "Ramnad \"Poochi\" Srinivasa Iyengar",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4564,
                "Name": "yemiseyuthunade",
                "Type": "Javali",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Pattabhiramiah",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4565,
                "Name": "Thillana-Sindhu Bhairavi",
                "Type": "Thillana",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Deshadi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4566,
                "Name": "palukutase",
                "Type": "Anubandham",
                "Ragam": "PhalaManjari",
                "Thalam": "Roopakam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4567,
                "Name": "naravara raghunandana",
                "Type": "Anubandham",
                "Ragam": "Manirangu",
                "Thalam": "Roopakam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
                "BookAuthor": "Patnam Subramanya Iyer, Ramnad Srinivasa Iyengar",
                "Year of Publication": 1958,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4568,
                "Name": "Anbirkum Undo",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Group": "Thirukural Tana Varnam",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4569,
                "Name": "kaNNanin karuNaiyai",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4570,
                "Name": "neelakanta devan aruL",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "L R Neelamegham Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4571,
                "Name": "Paa Maa nan mazhai thannil",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4572,
                "Name": "vetrilai paakku unakkendru",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4573,
                "Name": "Saarasaaksha krupaa",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "T V Lakshminarasimhan",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4574,
                "Name": "murugaa muthamizhisai paada",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Fiddle Krishnaswamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4575,
                "Name": "chinmayaa nanda swaroopa",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Tanjai V Venkataramaiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4576,
                "Name": "Soghamaanaen thozhiyae",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4577,
                "Name": "kaninthOdi vandhaen",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Nataraja sundaram Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4578,
                "Name": "Era Napai-Thodi",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4579,
                "Name": "Sarigaa nidhani",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "On Manali Venkatakrishna Mudali",
                "Composer": "Ramaswamy Dikshitar",
                "Group": "Swarakshara Varnam",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4580,
                "Name": "Irundhombhi illvazhavu",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4581,
                "Name": "madana roopaa ninnai naan",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4582,
                "Name": "Vara vaenum swaminaathaa",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "N Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4583,
                "Name": "Paadi maghizha innisai",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4584,
                "Name": "Vanajaakshi ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Tyagayyar",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4585,
                "Name": "ezhu pirappum theeyavai",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4586,
                "Name": "Inidhu Inidhu Inidhu kaaN",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4587,
                "Name": "vel muruganae",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "M G Gowri Kumari",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4588,
                "Name": "sedi nee pOi arindhu",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Nataraja sundaram Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4589,
                "Name": "maiyalaaginaaL",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Kunnakudi Venkatarama iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4590,
                "Name": "Swami naatha ",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4591,
                "Name": "Swaami ninne nammina",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Kuzhalisai K R Ganapathy",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4592,
                "Name": "Chalamelara",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Kunnakudi Venkatarama iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4593,
                "Name": "aravaazhi andhaNan",
                "Type": "Varnam-Tana",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4594,
                "Name": "pazhani velan",
                "Type": "Varnam-Tana",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4595,
                "Name": "Thiru murugan thEnisai",
                "Type": "Varnam-Tana",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4596,
                "Name": "thaamasinjaka",
                "Type": "Varnam-Tana",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4597,
                "Name": "nandri marappadhu",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4598,
                "Name": "kandhaa ninadharul",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4599,
                "Name": "chENudaiyor pugazh",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4600,
                "Name": "sagiyaa nenemi",
                "Type": "Varnam-Pada",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "K V Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4601,
                "Name": "maayan gopaalan",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4602,
                "Name": "Sree raaja maathangi",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Harikeshanallur Muthiah Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4603,
                "Name": "serivarindhu seermai",
                "Type": "Varnam-Tana",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4604,
                "Name": "sinamadaindhu seerazhiyum",
                "Type": "Varnam-Tana",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4605,
                "Name": "senchol ulavum",
                "Type": "Varnam-Tana",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4606,
                "Name": "swamikke sari evvarae",
                "Type": "Varnam-Tana",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4607,
                "Name": "vaan nindru ulagam",
                "Type": "Varnam-Tana",
                "Ragam": "Revagupti",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4608,
                "Name": "naaraNan paada malar",
                "Type": "Varnam-Tana",
                "Ragam": "Revagupti",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4609,
                "Name": "thaaN malar paNindhu paadum",
                "Type": "Varnam-Tana",
                "Ragam": "Revagupti",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4610,
                "Name": "pannaga shayana",
                "Type": "Varnam-Tana",
                "Ragam": "Revagupti",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4611,
                "Name": "aruLilaarkku avvulagam",
                "Type": "Varnam-Tana",
                "Ragam": "Jayanthashri",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4612,
                "Name": "Dinamum unnai thudhi seiyum",
                "Type": "Varnam-Tana",
                "Ragam": "Jayanthashri",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4613,
                "Name": "Daya jae suDagu",
                "Type": "Varnam-Tana",
                "Ragam": "Jayanthashri",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4614,
                "Name": "maha ganapathaey namasthae",
                "Type": "Varnam-Tana",
                "Ragam": "Jayanthashri",
                "Thalam": "Adi",
                "Composer": "G N Dhandapani",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4615,
                "Name": "sirappu eenum",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Seemanthini",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4616,
                "Name": "endha vELaiyum kanDa vELaiyE",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Seemanthini",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4617,
                "Name": "Deva Deva Maheshwara",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Seemanthini",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4618,
                "Name": "naDuvindri nanporuL",
                "Type": "Varnam-Tana",
                "Ragam": "Nagavarali",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4619,
                "Name": "yekan aagi anekan aagi",
                "Type": "Varnam-Tana",
                "Ragam": "Nagavarali",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4620,
                "Name": "Sree Durga Lakshmi Saraswathi",
                "Type": "Varnam-Tana",
                "Ragam": "Nagavarali",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4621,
                "Name": "purangkoori poithuyir",
                "Type": "Varnam-Tana",
                "Ragam": "Ghanta",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4622,
                "Name": "senthaamaraiyil",
                "Type": "Varnam-Tana",
                "Ragam": "Ghanta",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4623,
                "Name": "nee vega gathiyani",
                "Type": "Varnam-Tana",
                "Ragam": "Ghanta",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4624,
                "Name": "Baala krishnam Bhajeham",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4625,
                "Name": "Bhaavayaami",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4626,
                "Name": "tharuNam thaayae",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4627,
                "Name": "Gowri parameshwari",
                "Type": "Varnam-Tana",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 1",
                "BookAuthor": "Various",
                "Year of Publication": 1973,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4628,
                "Name": "payanir sol paaraatu",
                "Type": "Varnam-Tana",
                "Ragam": "Dhenuka",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4629,
                "Name": "engum niraivaai",
                "Type": "Varnam-Tana",
                "Ragam": "Dhenuka",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4630,
                "Name": "saama gaana priyae",
                "Type": "Varnam-Tana",
                "Ragam": "Dhenuka",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4631,
                "Name": "thannuyir neepinum",
                "Type": "Varnam-Tana",
                "Ragam": "Natakapriya",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4632,
                "Name": "nalla thamizh tharum iyalisai",
                "Type": "Varnam-Tana",
                "Ragam": "Natakapriya",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4633,
                "Name": "naatakapriyae narayani",
                "Type": "Varnam-Tana",
                "Ragam": "Natakapriya",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4634,
                "Name": "Iyathin neengi thelindhaarku",
                "Type": "Varnam-Tana",
                "Ragam": "Kokilapriya",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4635,
                "Name": "thanga avarkkingu ini",
                "Type": "Varnam-Tana",
                "Ragam": "Kokilapriya",
                "Thalam": "Adi",
                "Composer": "Nataraja sundaram Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4636,
                "Name": "guNa nalam koNda enthan",
                "Type": "Varnam-Tana",
                "Ragam": "Kokilapriya",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4637,
                "Name": "maadhavan avathaaram",
                "Type": "Varnam-Tana",
                "Ragam": "Kokilapriya",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4638,
                "Name": "nerunal uLanoruvan",
                "Type": "Varnam-Tana",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4639,
                "Name": "veeNai innisai aanavanae",
                "Type": "Varnam-Tana",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4640,
                "Name": "moolaadhaara ganapathiyae",
                "Type": "Varnam-Tana",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4641,
                "Name": "manaithakka maaNbuDaiyal",
                "Type": "Varnam-Tana",
                "Ragam": "Ahiri",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4642,
                "Name": "azhaguruvaagiya",
                "Type": "Varnam-Tana",
                "Ragam": "Ahiri",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4643,
                "Name": "vallamai tharuvaai murugaa",
                "Type": "Varnam-Tana",
                "Ragam": "Ahiri",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4644,
                "Name": "saahasam yaen",
                "Type": "Varnam-Tana",
                "Ragam": "Ahiri",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4645,
                "Name": "azhukkaatrin",
                "Type": "Varnam-Tana",
                "Ragam": "VasanthaBhairavi",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4646,
                "Name": "neethavaai kaatharuL vaayae",
                "Type": "Varnam-Tana",
                "Ragam": "VasanthaBhairavi",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4647,
                "Name": "thaedi nee sendru solvaai",
                "Type": "Varnam-Tana",
                "Ragam": "VasanthaBhairavi",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4648,
                "Name": "nee pada pankaja mula",
                "Type": "Varnam-Tana",
                "Ragam": "VasanthaBhairavi",
                "Thalam": "Adi",
                "Composer": "Karaikudi rajamani iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4649,
                "Name": "padaikudi koozh",
                "Type": "Varnam-Tana",
                "Ragam": "LalithaPanchamam",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4650,
                "Name": "paimpon maeniyil",
                "Type": "Varnam-Tana",
                "Ragam": "LalithaPanchamam",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4651,
                "Name": "maadhurya gaanaamrutha",
                "Type": "Varnam-Tana",
                "Ragam": "LalithaPanchamam",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4652,
                "Name": "kedu valyaan enbadhu",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4653,
                "Name": "ulagaLandha uthamanae",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4654,
                "Name": "neela megham pol maeni",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Lalgudi Fiddle Neelamegham Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4655,
                "Name": "neeyae gathi yena nambinaen",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4656,
                "Name": "paaraai en vinai theeraai",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4657,
                "Name": "sharavana bhava shanmuga",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Kanchipuram Fiddle Krishnaswamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4658,
                "Name": "maayamalawaGowlai vagaiyaai paadi",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4659,
                "Name": "Sree maathru bhutheshwari",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4660,
                "Name": "sarasaangi nee pai",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Raghavendrachariar",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4661,
                "Name": "Sree raaja rajeshwari shankari",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4662,
                "Name": "arivudaiyaar aavadharivar",
                "Type": "Varnam-Tana",
                "Ragam": "Malahari",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4663,
                "Name": "koil konDa en manak kovae",
                "Type": "Varnam-Tana",
                "Ragam": "Malahari",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4664,
                "Name": "shambu kumaaranae baalanae",
                "Type": "Varnam-Tana",
                "Ragam": "Malahari",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4665,
                "Name": "thurandhaarkum thuvaadhavarkum",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4666,
                "Name": "thendral vandhu endran",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4667,
                "Name": "pazhamudhir solai vaLar azhaganae",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Nataraja sundaram Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4668,
                "Name": "maanae moham sahiyaenae",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "N Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4669,
                "Name": "tharuNam idhuvae shanmuga",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4670,
                "Name": "iravum pagalum",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4671,
                "Name": "yedho kaaraNam ariyaena",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4672,
                "Name": "Sreedevi un kamalapaadham",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Dandayutha Deekshithar",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4673,
                "Name": "mahashae, vaamae shyamale",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4674,
                "Name": "Sarasuda ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Kothavasal Venkatrama Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4675,
                "Name": "paaththUN",
                "Type": "Varnam-Tana",
                "Ragam": "Lalitha",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4676,
                "Name": "endrum innisai mazhaiyil",
                "Type": "Varnam-Tana",
                "Ragam": "Lalitha",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4677,
                "Name": "naadha thanuvara ganesha",
                "Type": "Varnam-Tana",
                "Ragam": "Lalitha",
                "Thalam": "Adi",
                "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4678,
                "Name": "Sree maathangi rajarajeshwari",
                "Type": "Varnam-Tana",
                "Ragam": "Lalitha",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4679,
                "Name": "vanajaakshiro vibhuni",
                "Type": "Varnam-Tana",
                "Ragam": "Lalitha",
                "Thalam": "Adi",
                "Composer": "Thanjavur Adi Ponniah",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4680,
                "Name": "aganamarndhu eedhalin",
                "Type": "Varnam-Tana",
                "Ragam": "Manolayam",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4681,
                "Name": "thaemadhura isai",
                "Type": "Varnam-Tana",
                "Ragam": "Manolayam",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4682,
                "Name": "swamikkini sari evvare",
                "Type": "Varnam-Tana",
                "Ragam": "Manolayam",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4683,
                "Name": "vaaimai yenappaduvadhu",
                "Type": "Varnam-Tana",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4684,
                "Name": "paNN sumandha maeniyanae",
                "Type": "Varnam-Tana",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4685,
                "Name": "thavamae seidhun thaal Panindhaen",
                "Type": "Varnam-Tana",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4686,
                "Name": "ika para sukha dhaayini",
                "Type": "Varnam-Tana",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4687,
                "Name": "sarasija netri",
                "Type": "Varnam-Tana",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "Karaikudi rajamani iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 2",
                "BookAuthor": "Various",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4688,
                "Name": "irumai vagai therindhu",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4689,
                "Name": "kaalapaasath thaalae",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4690,
                "Name": "aanai maa muganae",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4691,
                "Name": "aadhura jana dhaarini",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4692,
                "Name": "sarasija naabha maam paahi",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Trivandram/Pallakad Parameshwara Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4693,
                "Name": "saadhu jana paripaalini",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4694,
                "Name": "palumaaru ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4695,
                "Name": "swami ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Kothavasal Venkatrama Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4696,
                "Name": "nuNNiya nool pala",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4697,
                "Name": "melaith thavamillaa",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4698,
                "Name": "naamagalae veNthaamarai naayagiyae",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Praise of": "On Saraswathi",
                "Composer": "Lalgudi Fiddle Neelamegham Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4699,
                "Name": "gaNa naathaa",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Mayuram Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4700,
                "Name": "sEkkizhaar thiruvadi thanaiyae",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Praise of": "On Sekkizhaar (Compiler of Panirendu thirumurai)",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4701,
                "Name": "ninne kori yunnaanu",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4702,
                "Name": "kanakaangi pai nee",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Thanjavur Adi Ponniah",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4703,
                "Name": "selimi kori vachchi",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4704,
                "Name": "evarunnaaruraa",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4705,
                "Name": "paramavyoma parama jyothi",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4706,
                "Name": "sinamennum sErndhAraik kolli",
                "Type": "Varnam-Tana",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4707,
                "Name": "vidai maghizhndheriya",
                "Type": "Varnam-Tana",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4708,
                "Name": "Ninaindhu ninaidhu paaduven",
                "Type": "Varnam-Tana",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4709,
                "Name": "maathava guNamaagi",
                "Type": "Varnam-Tana",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Dandayutha Deekshithar",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4710,
                "Name": "Swamikki sari evvare",
                "Type": "Varnam-Tana",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4711,
                "Name": "arankadai nindrarul",
                "Type": "Varnam-Tana",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4712,
                "Name": "anbuLLa durai enthan",
                "Type": "Varnam-Tana",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Praise of": "on Raja sir Annamalai Chettiyar",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4713,
                "Name": "thanjam pugundhEn endru sollaDi",
                "Type": "Varnam-Tana",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4714,
                "Name": "swayam varEnyam",
                "Type": "Varnam-Tana",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4715,
                "Name": "aanandha nata maadinaar",
                "Type": "Varnam-Tana",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4716,
                "Name": "payan maram",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlipanthu",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4717,
                "Name": "paadham paNindhEn endru",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlipanthu",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4718,
                "Name": "Sree raghukula thilakaa",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlipanthu",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4719,
                "Name": "ozhukkam udaimai kudimai",
                "Type": "Varnam-Tana",
                "Ragam": "Kamala Manohari",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4720,
                "Name": "aruL thara viraindhu",
                "Type": "Varnam-Tana",
                "Ragam": "Kamala Manohari",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4721,
                "Name": "Kamala manohari anbin thaayae",
                "Type": "Varnam-Tana",
                "Ragam": "Kamala Manohari",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4722,
                "Name": "neevamti saamini nEnemthu",
                "Type": "Varnam-Tana",
                "Ragam": "Kamala Manohari",
                "Thalam": "Adi",
                "Composer": "Thanjavur Adi Chinniah",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4723,
                "Name": "Sree ramachandru naathu",
                "Type": "Varnam-Tana",
                "Ragam": "Kamala Manohari",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4724,
                "Name": "Swamikki sari evvare",
                "Type": "Varnam-Tana",
                "Ragam": "Kamala Manohari",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4725,
                "Name": "sevikku uNavilladha",
                "Type": "Varnam-Tana",
                "Ragam": "MeghaRanjani",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4726,
                "Name": "sondhamum bandhamum",
                "Type": "Varnam-Tana",
                "Ragam": "MeghaRanjani",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4727,
                "Name": "maa karuNai pozhi vaanmughilE",
                "Type": "Varnam-Tana",
                "Ragam": "MeghaRanjani",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4728,
                "Name": "megharanjani gaanaamrutha varshini",
                "Type": "Varnam-Tana",
                "Ragam": "MeghaRanjani",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4729,
                "Name": "vasai enba vaiyaththaarkellam",
                "Type": "Varnam-Tana",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4730,
                "Name": "veNNira thaamarai meedhu",
                "Type": "Varnam-Tana",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Praise of": "On Saraswathi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4731,
                "Name": "Saama vedha rasikae",
                "Type": "Varnam-Tana",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4732,
                "Name": "kaalam karudhi",
                "Type": "Varnam-Tana",
                "Ragam": "Pahadi",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4733,
                "Name": "kaama moha daasanaanEnE",
                "Type": "Varnam-Tana",
                "Ragam": "Pahadi",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4734,
                "Name": "sindhai kavarndha senthil",
                "Type": "Varnam-Tana",
                "Ragam": "Pahadi",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4735,
                "Name": "eeveLa nannu rakshimchutaku",
                "Type": "Varnam-Tana",
                "Ragam": "Pahadi",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4736,
                "Name": "poruththal irappinai",
                "Type": "Varnam-Tana",
                "Ragam": "Pahadi",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4737,
                "Name": "maamayil mel yeri varum",
                "Type": "Varnam-Tana",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4738,
                "Name": "sindhai thirundhi nanmai seravE",
                "Type": "Varnam-Tana",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4739,
                "Name": "maadharasi en ariyaa pizhai thanai",
                "Type": "Varnam-Tana",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Thiruvananthapuram Ksheeraabdi Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4740,
                "Name": "jala jaakshi ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4741,
                "Name": "sarasuda ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Vummudi Chetti Venkatasamy Naidu",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4742,
                "Name": "dhayajE yutaku",
                "Type": "Varnam-Tana",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4743,
                "Name": "kadanarindhu kaalam",
                "Type": "Varnam-Tana",
                "Ragam": "Vaahini",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4744,
                "Name": "sEvadi tharuvAi varuvAi",
                "Type": "Varnam-Tana",
                "Ragam": "Vaahini",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4745,
                "Name": "paarinil nee kaameshwari",
                "Type": "Varnam-Tana",
                "Ragam": "Vaahini",
                "Thalam": "Adi",
                "Composer": "Thiruvananthapuram Ksheeraabdi Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4746,
                "Name": "thaapa saanu gruheetha",
                "Type": "Varnam-Tana",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4747,
                "Name": "karima ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Kamala Manohari",
                "Thalam": "Adi",
                "Composer": "Kothavasal Venkatrama Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 3",
                "BookAuthor": "Various",
                "Year of Publication": 1975,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4748,
                "Name": "eNenba yaenai yezhuthenba",
                "Type": "Varnam-Tana",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4749,
                "Name": "sadai virithaadiya",
                "Type": "Varnam-Tana",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4750,
                "Name": "uiyyum vagaiyae thandhenai",
                "Type": "Varnam-Tana",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4751,
                "Name": "bhayamellam neeki ennai",
                "Type": "Varnam-Tana",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Dhandayuthapani Deekshitar",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4752,
                "Name": "kaapaDutaku",
                "Type": "Varnam-Tana",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4753,
                "Name": "avaavinai aatra",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4754,
                "Name": "yedhu kobamaiyyA",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4755,
                "Name": "panneDu naaLaai unnai paaDi",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4756,
                "Name": "kayilayil inidhu vaazhum",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Dhandayuthapani Deekshitar",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4757,
                "Name": "aarendru arindhu vaadi sakhiyae",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "S Srinivasa Iyengar",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4758,
                "Name": "saadhu jana priyae",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4759,
                "Name": "iDikku thuNai",
                "Type": "Varnam-Tana",
                "Ragam": "Veenaadhari",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4760,
                "Name": "thoNDanukku kurai yedhum",
                "Type": "Varnam-Tana",
                "Ragam": "Veenaadhari",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4761,
                "Name": "aadi naadhanae azhagiya velanae",
                "Type": "Varnam-Tana",
                "Ragam": "Veenaadhari",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4762,
                "Name": "veeNa dhanDa kamanDala dhaariNi",
                "Type": "Varnam-Tana",
                "Ragam": "Veenaadhari",
                "Thalam": "Adi",
                "Praise of": "On Saraswathi",
                "Composer": "Dhandayuthapani Deekshitar",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4763,
                "Name": "varuvaanoDi vaNNa malar aaDi",
                "Type": "Varnam-Tana",
                "Ragam": "Veenaadhari",
                "Thalam": "Adi",
                "Composer": "S Srinivasa Iyengar",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4764,
                "Name": "sari evva runnaaruraa",
                "Type": "Varnam-Tana",
                "Ragam": "Veenaadhari",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4765,
                "Name": "therindha innathodu",
                "Type": "Varnam-Tana",
                "Ragam": "Suryakantham",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4766,
                "Name": "naaDuvorku aruLum naayakanae",
                "Type": "Varnam-Tana",
                "Ragam": "Suryakantham",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4767,
                "Name": "arivinuL ellam thalai enba",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4768,
                "Name": "koDupOrai nindhithal",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4769,
                "Name": "paraman karuNai ennai",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer/Veenai Narayanaswamy Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4770,
                "Name": "pongkaravu thavazh maarbhaa",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4771,
                "Name": "pizhai poruththu arul surandhu",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Delhi Fiddle Chidambaram Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4772,
                "Name": "sadaa un maNisilambhadi nambinean",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Ksheerabdhi Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4773,
                "Name": "Sree maadhavi gowri un",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Dhandayutha Deekshitar",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4774,
                "Name": "yasya gaNaathipathae",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4775,
                "Name": "naada laya swaroopam",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4776,
                "Name": "aaryaambha shiva guru",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Praise of": "On Adi Shankaracharya",
                "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4777,
                "Name": "kallaa oruvan",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4778,
                "Name": "aaDiyum paaDiyum aaval",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4779,
                "Name": "netru iravu kaathirundhu",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Arunachalam Pillai/Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4780,
                "Name": "adhi roopamaana ivar yaar",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Nataraja sundaram Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4781,
                "Name": "arumarai pugazhum",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Ksheerabdhi Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4782,
                "Name": "vElan en nAdhan",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4783,
                "Name": "mohana roopan mahaanu bhaavan",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "On Muruga",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4784,
                "Name": "nin thava mahimai",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Dhandayutha Deekshitar",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4785,
                "Name": "vanajaakshiro vibhuni",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Thanjavur Adi Ponniah",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4786,
                "Name": "ninnu kori unnaara",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Tachchur Singarachariar",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4787,
                "Name": "shrutha praayamu",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4788,
                "Name": "suratharu phala saara",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4789,
                "Name": "eNNi yaar eNNam",
                "Type": "Varnam-Tana",
                "Ragam": "Natabhairavi",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4790,
                "Name": "pazhani malai vaazh naadhanae",
                "Type": "Varnam-Tana",
                "Ragam": "Natabhairavi",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4791,
                "Name": "karuNai thavazhum",
                "Type": "Varnam-Tana",
                "Ragam": "Natabhairavi",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4792,
                "Name": "poruLaatchi potra",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4793,
                "Name": "amudhinum inimaiyaai",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "V S Gomathi Sankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4794,
                "Name": "kodhaiyE vaaraai",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Fiddle Neela megham Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4795,
                "Name": "Sree madhavi gowri",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Ksheerabdhi Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4796,
                "Name": "ennuLam kavarndha ezhil vadivElan",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Varagoor K Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4797,
                "Name": "madhuraapuri vaasini",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Keezhvelur Meenakshi Sundaram Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4798,
                "Name": "parameshwari nin madhi mugathu",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Dhandayutha Deekshitar",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4799,
                "Name": "ninnu kori unnandhuku",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Thanjavur Adi Ponniah",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4800,
                "Name": "yEpaapamu jesithinO",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4801,
                "Name": "vanitha ninnu kori",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Chennai Ponnusamy",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4802,
                "Name": "sarasagu raku sarasa",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar / Veenai Kuppaiyer",
                "Group": "Both Swaram and Sahithyam are Palindrome",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4803,
                "Name": "sarva manthra swathantra",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4804,
                "Name": "sundara deva vandanamuraa",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "H Yoganarasimham",
                "Song Language": "Telugu",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4805,
                "Name": "varadaayaki sree bruhannaayaki",
                "Type": "Varnam-Tana",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Hemavathi Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4806,
                "Name": "Indha viraga thaapam",
                "Type": "Varnam-Tana",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Chidambaram Srinivasa Iyengar",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4807,
                "Name": "vanamaala sree gopaalaa",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Kunnakudi Venkatarama iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tana Varna Kadal - Vol 4",
                "BookAuthor": "Various",
                "Year of Publication": 1976,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4808,
                "Name": "Sree shanmukha janaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Jathi Triputa thalam",
                "Praise of": "On Adi Shankaracharya",
                "Composer": "Mysore Sadashiva rao",
                "Song Language": "Sanskrit",
                "BookTitle": "Guru Keerthana Ratnam",
                "BookAuthor": "Various",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4809,
                "Name": "Sree Kaamakoti peetasthithe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "On Adi Shankaracharya",
                "Composer": "Mysore Sadashiva rao",
                "Song Language": "Sanskrit",
                "BookTitle": "Guru Keerthana Ratnam",
                "BookAuthor": "Various",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4810,
                "Name": "Shankaracharya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Chapu",
                "Praise of": "On Adi Shankaracharya",
                "Composer": "Sanyasi Sivaraamaasrami swamigal",
                "Song Language": "Sanskrit",
                "BookTitle": "Guru Keerthana Ratnam",
                "BookAuthor": "Various",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4811,
                "Name": "Vijayathaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Mishra Chapu",
                "Praise of": "On Adi Shankaracharya",
                "Composer": "Tirunelveli Retd Chief Engineer Southern Railways V Ramaiah",
                "Group": "Achaarya Pancharathnam",
                "Song Language": "Sanskrit",
                "BookTitle": "Guru Keerthana Ratnam",
                "BookAuthor": "Various",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4812,
                "Name": "aamnaayaraajya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Praise of": "On Adi Shankaracharya",
                "Composer": "Tirunelveli Retd Chief Engineer Southern Railways V Ramaiah",
                "Group": "Achaarya Pancharathnam",
                "Song Language": "Sanskrit",
                "BookTitle": "Guru Keerthana Ratnam",
                "BookAuthor": "Various",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4813,
                "Name": "Jagadaachaarya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Praise of": "On Adi Shankaracharya",
                "Composer": "Tirunelveli Retd Chief Engineer Southern Railways V Ramaiah",
                "Group": "Achaarya Pancharathnam",
                "Song Language": "Sanskrit",
                "BookTitle": "Guru Keerthana Ratnam",
                "BookAuthor": "Various",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4814,
                "Name": "aanandha vaahEna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "On Adi Shankaracharya",
                "Composer": "Tirunelveli Retd Chief Engineer Southern Railways V Ramaiah",
                "Group": "Achaarya Pancharathnam",
                "Song Language": "Sanskrit",
                "BookTitle": "Guru Keerthana Ratnam",
                "BookAuthor": "Various",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4815,
                "Name": "vandeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Praise of": "On Adi Shankaracharya",
                "Composer": "Tirunelveli Retd Chief Engineer Southern Railways V Ramaiah",
                "Group": "Achaarya Pancharathnam",
                "Song Language": "Sanskrit",
                "BookTitle": "Guru Keerthana Ratnam",
                "BookAuthor": "Various",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4816,
                "Name": "Sree Shankaracharyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "On Adi Shankaracharya",
                "Composer": "Tirunelveli Retd Chief Engineer Southern Railways V Ramaiah",
                "Group": "Achaarya Pancharathnam",
                "Song Language": "Sanskrit",
                "BookTitle": "Guru Keerthana Ratnam",
                "BookAuthor": "Various",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4817,
                "Name": "swaami neepai",
                "Type": "Varnam-Tana",
                "Ragam": "Amruthavarshini",
                "Thalam": "Adi",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4818,
                "Name": "Inthi chakka thanamu",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4819,
                "Name": "sarasijaakshi neepai",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Adi",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4820,
                "Name": "Maaruthi theevra",
                "Type": "Varnam-Tana",
                "Ragam": "Gamakakriya",
                "Thalam": "Adi",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4821,
                "Name": "marubaari korva",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4822,
                "Name": "Viriboni ninnu jesi",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4823,
                "Name": "Maguvuneepai marulukoni",
                "Type": "Varnam-Tana",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4824,
                "Name": "sarasaakresha runDanee",
                "Type": "Varnam-Ata",
                "Ragam": "Neelambari",
                "Thalam": "Ata",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4825,
                "Name": "Sree Raajaadhi Raja",
                "Type": "Varnam-Ata",
                "Ragam": "Naatai",
                "Thalam": "Ata",
                "Praise of": "On Ettayapuram Yuvaraja",
                "Composer": "Baluswami Dikshitar",
                "Group": "Baluswami Dikshitar Kanakaabhisheka Varnam",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4826,
                "Name": "Sree vishwanatham Bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Muthuswami Dikshitar",
                "Group": "Chaturdasa Ragamalika",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4827,
                "Name": "Gana naayaka ninnunE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4828,
                "Name": "saadhanam begada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4829,
                "Name": "neekae dayavachchunani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Roopakam",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4830,
                "Name": "dasharatha thanaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4831,
                "Name": "manasuna raama naama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4832,
                "Name": "marivere gathi evvaru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Khanda Chapu",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4833,
                "Name": "kanukoni sevinchchE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4834,
                "Name": "ninnu paasi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NutanaGowlai",
                "Thalam": "Roopakam",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4835,
                "Name": "neethaasoodani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rudrapriya",
                "Thalam": "Adi",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4836,
                "Name": "saraswathi ninnu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Roopakam",
                "Composer": "Veenai Varadayya",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4837,
                "Name": "Siddhi vinaayakanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tilang",
                "Thalam": "Adi",
                "Composer": "R V Krishnan (Son of Veenai Varadayya)",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4838,
                "Name": "paarinaik kaathidum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "R V Krishnan (Son of Veenai Varadayya)",
                "BookTitle": "Veenai Varadayya Isai Urupadigal",
                "BookAuthor": "Veenai Varadayya",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4839,
                "Name": "Amba Parameshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavapriya",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4840,
                "Name": "Ananda Devi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amruthavarshini",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4841,
                "Name": "Ananda Ganapathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4842,
                "Name": "Arjuna Saarathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayanthasena",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4843,
                "Name": "Bala Gopala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4844,
                "Name": "Bhama Mani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4845,
                "Name": "Bhajadavalaambareem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4846,
                "Name": "Bhajamohini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Brindavana Saranga",
                "Thalam": "Adi",
                "Praise of": "Shastha",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4847,
                "Name": "Bhaja sathatham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4848,
                "Name": "Bhama Shikhaamani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bindumalini",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4849,
                "Name": "Bhavadhaarini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Khanda Chapu",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4850,
                "Name": "Bhaavayae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yamuna Kalyani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4851,
                "Name": "Bhujanaayakam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4852,
                "Name": "Chandrasekharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4853,
                "Name": "Daamodhara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KalyanaVasantham",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4854,
                "Name": "Daasaagreshaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SalagaBhairavi",
                "Thalam": "Adi",
                "Praise of": "Hanuman",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4855,
                "Name": "Devaadhidevam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Ekam (Tisra Gathi)",
                "Praise of": "Shiva",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4856,
                "Name": "Dinakarakula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surya",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4857,
                "Name": "Divaakaraanvaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tilang",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4858,
                "Name": "DurithanivaaraNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4859,
                "Name": "Ekadantham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4860,
                "Name": "GambiraGajanaayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GambhiraNaatai",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4861,
                "Name": "Gaana maalini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4862,
                "Name": "Gauri Kaantha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chitrambari",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4863,
                "Name": "Gokula Vihaari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4864,
                "Name": "Govinda Maamava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhimplas",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4865,
                "Name": "Guha Sharavanabhava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "VandanaDhaarini",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4866,
                "Name": "Hara maanasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desaakshi",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4867,
                "Name": "He Jagajjanani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jonpuri",
                "Thalam": "Mishra Chapu",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4868,
                "Name": "Inakula Deepam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4869,
                "Name": "Jagajjanani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhageshwari",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4870,
                "Name": "Janani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4871,
                "Name": "Janani Jayapara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4872,
                "Name": "Jaya Jaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4873,
                "Name": "Kaali kalpaalini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rageshwari",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4874,
                "Name": "Kamala Karuna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhimplas",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4875,
                "Name": "Kamala pushpa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4876,
                "Name": "Kaanchi Kaamakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "IndhuPanchamam",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4877,
                "Name": "Kapisainya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Hanuman",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4878,
                "Name": "Karuna paripoorna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4879,
                "Name": "Karuna tarangini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SalagaBhairavi",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4880,
                "Name": "Kula deepam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4881,
                "Name": "Madana sukhumaaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4882,
                "Name": "Madhurapuri adhi kaarini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4883,
                "Name": "Mama upakaari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KalaSaveri",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4884,
                "Name": "Maama Sree",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4885,
                "Name": "Maanikya veenadhari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayanthashri",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4886,
                "Name": "Maarutha tanayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Hanuman",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4887,
                "Name": "Naamapraachara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Praise of": "Naaradha",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4888,
                "Name": "Nandhi vaahana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaranga",
                "Thalam": "Khanda Chapu",
                "Praise of": "Shiva",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4889,
                "Name": "Narthana Ganapathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4890,
                "Name": "Navapushpa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4891,
                "Name": "Navaraathri Raani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShivaRanjani",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4892,
                "Name": "Neela KanTam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaalikaa",
                "Thalam": "Mishra Chapu",
                "Praise of": "Shiva",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4893,
                "Name": "Neela Megha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jonpuri",
                "Thalam": "Mishra Chapu",
                "Praise of": "Krishna",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4894,
                "Name": "NeerajadhaLa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4895,
                "Name": "Para devathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Deshadi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4896,
                "Name": "Parama paavani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhaavani",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4897,
                "Name": "Paramesha Ramani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4898,
                "Name": "Parameshi Paahi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kosalam",
                "Thalam": "Khanda Chapu",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4899,
                "Name": "Parashiva kaamini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GopikaThilakam",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4900,
                "Name": "Paripoorna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4901,
                "Name": "Paartha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desh",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4902,
                "Name": "Paarvatheesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Mishra Chapu",
                "Praise of": "Shiva",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4903,
                "Name": "Pathithapaavana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natabhairavi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4904,
                "Name": "Praanapathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaDhanyasi",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4905,
                "Name": "Raghavaroopam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Padmadeepam",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4906,
                "Name": "Raghukulathilakam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Revathi",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4907,
                "Name": "Raghuvamshadeepam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhimplas",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4908,
                "Name": "Raajamaathangi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chandrakauns",
                "Thalam": "Mishra Chapu",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4909,
                "Name": "Raaja shikaamani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4910,
                "Name": "Raajendrakanyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaranga",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4911,
                "Name": "Raamadaasam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Mishra Chapu",
                "Praise of": "Hanuman",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4912,
                "Name": "Raamadhootham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malhar",
                "Thalam": "Mishra Chapu",
                "Praise of": "Hanuman",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4913,
                "Name": "Ramaneeya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanadam",
                "Thalam": "Khanda Chapu",
                "Praise of": "Murugan",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4914,
                "Name": "Sada Shiva kaamini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4915,
                "Name": "Saamajagamani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Chapu",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4916,
                "Name": "Saamajagamani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Veenaavaadhini",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4917,
                "Name": "Saamba Sadashiva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4918,
                "Name": "Shaambhavi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desh",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4919,
                "Name": "Shankaraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Praise of": "Ayyappan",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4920,
                "Name": "Saarasa dhala nayani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4921,
                "Name": "Sarasija nethram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surya",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4922,
                "Name": "Shikhivaahanaroodam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijayanagari",
                "Thalam": "Khanda Chapu",
                "Praise of": "Murugan",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4923,
                "Name": "Shivaraajadaani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShivaRanjani",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4924,
                "Name": "SreeJayanthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4925,
                "Name": "Sree Gananaatham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4926,
                "Name": "Sree Meenakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4927,
                "Name": "Sree Purasadani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Mishra Chapu",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4928,
                "Name": "Sree Raama Ramana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rathipathipriya",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4929,
                "Name": "Sukha Sowkhya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4930,
                "Name": "Sundara yaadhava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BhojaMukhari",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4931,
                "Name": "Soorya Kulaambhudhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manoharam",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4932,
                "Name": "Swarnaa sabhaapathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4933,
                "Name": "Swarna Simhaasana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4934,
                "Name": "Swethasaroja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4935,
                "Name": "Uragashayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4936,
                "Name": "Varaguna Gambhira",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4937,
                "Name": "Venkatesha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4938,
                "Name": "Vidhirasanaasani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4939,
                "Name": "Yadhava vamsha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Madhurai GS Mani",
                "Song Language": "Sanskrit",
                "BookTitle": "Rajapujita Ramyam - Stuti Gitams",
                "BookAuthor": "Madhurai GS Mani",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 4940,
                "Name": "Thudhi peru ganapathi iNaiyadi",
                "Type": "Arutpa",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4941,
                "Name": "Arpudham Arpudhame",
                "Type": "Arutpa",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4942,
                "Name": "Endhai en guruvae",
                "Type": "Arutpa",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4943,
                "Name": "Vaarum vaarum deiva vadivel",
                "Type": "Arutpa",
                "Ragam": "Ramapriya",
                "Thalam": "Adi",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4944,
                "Name": "Otri ooranai patri",
                "Type": "Arutpa",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4945,
                "Name": "Vaanathin meedhu",
                "Type": "Arutpa",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4946,
                "Name": "Thaayagi thandhayumaai",
                "Type": "Arutpa",
                "Ragam": "Hamsadhwani",
                "Thalam": "Jhampai",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4947,
                "Name": "Innum dhayavu varavillaiya",
                "Type": "Arutpa",
                "Ragam": "Bahudari",
                "Thalam": "Mishra Chapu",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4948,
                "Name": "paadarkiniya vaakaLikum",
                "Type": "Arutpa",
                "Ragam": "Pantuvarali",
                "Thalam": "Chatusra Jathi Eka Thalam (Tisra Gathi)",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4949,
                "Name": "Seivagai ariyaen",
                "Type": "Arutpa",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Khanda Jathi Jhampa Thalam",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4950,
                "Name": "Naala marundhim marundhu",
                "Type": "Arutpa",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4951,
                "Name": "Thaayilaarena nenjakam",
                "Type": "Arutpa",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4952,
                "Name": "thaNmadhiyon muga peNmaNiyae",
                "Type": "Arutpa",
                "Ragam": "Khamas",
                "Thalam": "Khanda Jathi Jhampa Thalam",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4953,
                "Name": "Anbenum pidiyul",
                "Type": "Arutpa",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4954,
                "Name": "kannae kannmaniyae",
                "Type": "Arutpa",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4955,
                "Name": "yetirendum en endraal",
                "Type": "Arutpa",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4956,
                "Name": "Idhu nalla tharuNam",
                "Type": "Arutpa",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chapu",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4957,
                "Name": "varuvaar azhaithuvaadi",
                "Type": "Arutpa",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4958,
                "Name": "Vedha porulae charanam",
                "Type": "Arutpa",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4959,
                "Name": "thaen yena inikkum",
                "Type": "Arutpa",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4960,
                "Name": "Nambinae nindran thiruvadi malarai",
                "Type": "Arutpa",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4961,
                "Name": "Sirpodhuvum porpodhuvum",
                "Type": "Arutpa",
                "Ragam": "Arabhi",
                "Thalam": "Khanda Chapu",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4962,
                "Name": "Orumaiyudan ninadhu thirumalaradi",
                "Type": "Arutpa",
                "Ragam": "Ragamalika",
                "Thalam": "Khanda Chapu",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4963,
                "Name": "neer undu pozhikindra",
                "Type": "Arutpa",
                "Ragam": "Ragamalika",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4964,
                "Name": "Pungavar pugazhumaa",
                "Type": "Arutpa",
                "Ragam": "Mangala ragamalika",
                "Thalam": "Adi",
                "Composer": "Vallalar/Music-TM Thyagarajan",
                "Song Language": "Tamil",
                "BookTitle": "Thiruvarutpa Isai Maalai",
                "BookAuthor": "Ramalinga Adigal/Vallalar",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4965,
                "Name": "Ainkaranae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4966,
                "Name": "Sakala kala vaani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Roopakam",
                "Praise of": "Saraswathi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4967,
                "Name": "Sathiyamae vellum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manohari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4968,
                "Name": "Paranjyothi darisanamae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4969,
                "Name": "Unnadimai naanallavo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4970,
                "Name": "KaNNan kuzhalosai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4971,
                "Name": "Anbae arumarundhaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4972,
                "Name": "enna punniyam seidhaeno",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desiya Ranjani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4973,
                "Name": "Thanjam endru vandha ennai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4974,
                "Name": "Endrunnai kaaNbheno",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4975,
                "Name": "Sree Venkateshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4976,
                "Name": "Endro nee uNarvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4977,
                "Name": "Ennuvadhellam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natabhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4978,
                "Name": "Adaikalamendru naan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4979,
                "Name": "Muppuram yeri seidha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4980,
                "Name": "Pazhani malai vela",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MadhangaKaamini",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4981,
                "Name": "Maname unakkeno",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nayaki",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4982,
                "Name": "Indroru pudhu naaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "JayaNarayani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4983,
                "Name": "Thaam tharikita",
                "Type": "Thillana",
                "Ragam": "Lalitha",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4984,
                "Name": "Dheem Dheem Dheem",
                "Type": "Thillana",
                "Ragam": "UdhayaRaviChandrika",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4985,
                "Name": "Dheem Dheem tana thaam",
                "Type": "Thillana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4986,
                "Name": "Dheem Thaam thanam",
                "Type": "Thillana",
                "Ragam": "Hamsadhwani",
                "Thalam": "Chatusra Jathi Jhampa Thalam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4987,
                "Name": "Anbil agappadum deivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4988,
                "Name": "Shanthi illamal sugam undo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4989,
                "Name": "Aadum perumaanae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4990,
                "Name": "En murai kaetae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4991,
                "Name": "Muruga Muruga endral",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Mishra Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4992,
                "Name": "Iruvinai yedhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4993,
                "Name": "Undran kai aaLaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhushavali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4994,
                "Name": "Nenjamae nee anjaathae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4995,
                "Name": "Naanoru siru veenai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4996,
                "Name": "Paadaaravindham adaindhen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4997,
                "Name": "Kailayang giri thanil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Khanda Jathi Matya Thalam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4998,
                "Name": "Murugan vandhenai",
                "Type": "Padam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 4999,
                "Name": "Kaathirukindren",
                "Type": "Padam",
                "Ragam": "Pushpalathika",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5000,
                "Name": "yeno avarku enmel",
                "Type": "Padam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5001,
                "Name": "Ariyaap paruva madhil",
                "Type": "Padam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5002,
                "Name": "Kanden kanavinilae",
                "Type": "Padam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5003,
                "Name": "en mugam paaradhu",
                "Type": "Padam",
                "Ragam": "Pantuvarali",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5004,
                "Name": "kanni kazhiyadha kaalathil",
                "Type": "Javali",
                "Ragam": "Hindolam",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5005,
                "Name": "Inbath thirunaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5006,
                "Name": "Sorvadaiyaadhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saravilambhi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5007,
                "Name": "enge thedukindraai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5008,
                "Name": "yethanai per",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelamani",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5009,
                "Name": "anbu seidhu vaazhvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaDhanyasi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan Keerthana Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1951,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5010,
                "Name": "Adivo alladivo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: AIR Mallik",
                "Group": "Album: Sree Venkateshwara Geethamalika",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5011,
                "Name": "Annidaa nerubari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: Sripada Pinnakapani",
                "Group": "Album: Annamaiyya PadhaheLa",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5012,
                "Name": "Annitiki Nidhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: Nookala Chinna Sathyanarayana",
                "Group": "Album: Annamaiyya Sreevaasaanubhupathi",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5013,
                "Name": "Anni vibhavamula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: D Pashupathi",
                "Group": "Album: Annamaiyya Sankeerthana vaahini",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5014,
                "Name": "AngaNaku neeve",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Tisram",
                "Composer": "Annamacharya/Music: G Nageshwara Naidu",
                "Group": "Album: Annamaiyya Antharangamlo Almelumangaa",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5015,
                "Name": "Evvari Bhaagyam entunnadho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: Nedunuri Krishnamurthy",
                "Group": "Album: Annamaiyya padasourabham",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5016,
                "Name": "Endhaaga nithra neekithe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda Chapu",
                "Composer": "Annamacharya/Music: Paarupalli Sri Ranganath",
                "Group": "Album: Annamaiyya Sankeerthana Vaibhavam",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5017,
                "Name": "Okapari Kokkapari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: Nedunuri Krishnamurthy",
                "Group": "Album: Sree Venkateshwara Pancharatnamaala - 2",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5018,
                "Name": "O bhavanaathmaja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: G Balakrishna Prasad",
                "Group": "Album: Annamaiyya VenkataadhriveNNila",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5019,
                "Name": "KaruNaanidhim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KannadaGowlai",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: Voleti Venkateshwaralu",
                "Group": "Album: Annamaiyya PadhaheLa",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5020,
                "Name": "Kadupendha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gundakriya",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: M Balamurali krishna",
                "Group": "Album: Annamaiyya Sankeerthana Ravali",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5021,
                "Name": "Galaloni Sukhame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: G Nageshwara Naidu",
                "Group": "Album: Annamaiyya Bhakthi Lahari",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5022,
                "Name": "KoNdalo nelakonna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: MS Balasubramanya Sharma",
                "Group": "Album: Sree Venkateshwara Geethamalika",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5023,
                "Name": "Korudhu naamadhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malahari",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: Nedunuri Krishnamurthy",
                "Group": "Album: Annamaiyya padasourabham",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5024,
                "Name": "Ksheerabdhi kanyakaku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurinji",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: Kadayanallur Venkatraman",
                "Group": "Album: Sree Venkateshwara Pancharatnamaala - 1",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5025,
                "Name": "Jo Achuthaanadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Khanda Chapu",
                "Composer": "Annamacharya/Music: Kadayanallur Venkatraman",
                "Group": "Album: Sree Venkateshwara Pancharatnamaala - 1",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5026,
                "Name": "Tirumala Giri raaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: G Nageshwara Naidu",
                "Group": "Album: Annamaiyya Bhakthi Lahari",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5027,
                "Name": "Deva Devoththama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: Sandhyavandhanam Sreenivasa Rao & Voleti Venkateshwaralu",
                "Group": "Album: Annamaiyya PadhaheLa",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5028,
                "Name": "Namo Namo Lakshmi Narasimha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: G Balakrishna Prasad",
                "Group": "Album: Annamaiyya Padapadmini",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5029,
                "Name": "Naarayanathe Namo namo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: M Balamurali krishna",
                "Group": "Album: HMV LP",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5030,
                "Name": "palukuthe nela thalli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Khanda Chapu",
                "Composer": "Annamacharya/Music: Nedunuri Krishnamurthy",
                "Group": "Album: Annamaiyya padasourabham",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5031,
                "Name": "polayaluka nithralu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: Paarupalli Sri Ranganath",
                "Group": "Album: Annamaiyya Sankeerthana Vaibhavam",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5032,
                "Name": "poDakaNti maiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: D Pashupathi",
                "Group": "Album: Annamaiyya VenkataadhriveNNila",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5033,
                "Name": "patalenu paanuppa parasare",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: Paarupalli Sri Ranganath",
                "Group": "Album: Annamaiyya Sankeerthana Vaibhavam",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5034,
                "Name": "manujudai putti",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Annamacharya/D Pashupathi",
                "Group": "Album: Sree Venkateshwara Pancharatnamaala - 1",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5035,
                "Name": "vaade venkataadhrimeetha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: Sripada Pinnakapani",
                "Group": "Album: Annamaiyya PadhaheLa",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5036,
                "Name": "vedakina nidhiyae vedhaarthamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Annamacharya/Music: G Balakrishna Prasad",
                "Group": "Album: Annamaiyya Padapadmini",
                "Song Language": "Telugu",
                "BookTitle": "Annamayya Sankeerthana Swaravali",
                "BookAuthor": "Annamacharya",
                "Year of Publication": 1991,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5037,
                "Name": "Chandrasekara Vanakkam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Chandrasekharendra",
                "Composer": "Girija Srinivasan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5038,
                "Name": "MahaGanapathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5039,
                "Name": "Kunjaramukhane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Chatusra Jathi Matya thalam",
                "Praise of": "Ganesha",
                "Composer": "Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5040,
                "Name": "neeye manamaghizhvodu karunai seivaaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5041,
                "Name": "paramaa vandhu arul seidhaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Revagupti",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5042,
                "Name": "kaaNa anantham kaN veNum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Swarnaangi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5043,
                "Name": "aruLpurindhiduvaaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5044,
                "Name": "Pahimaam Sri Rajarajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janaranjani",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5045,
                "Name": "Sree Shankara Guruvaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Roopakam",
                "Praise of": "Adi Shankara",
                "Composer": "Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5046,
                "Name": "Pankajaakshi pai",
                "Type": "Varnam-Pada",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Maha Vaidhyanatha Sivan",
                "Song Language": "Telugu",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5047,
                "Name": "vaeru thunai kaaNe nae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5048,
                "Name": "Gowri naayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Simhaanandanam",
                "Praise of": "Shiva",
                "Composer": "Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5049,
                "Name": "Aanandha natesa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5050,
                "Name": "Maal Maruganai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5051,
                "Name": "thudhi seidhidu maname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5052,
                "Name": "natanam seiyum paadhanaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5053,
                "Name": "Neelamayil mel varuvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5054,
                "Name": "aththi muganai",
                "Type": "Kummi",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5055,
                "Name": "neeye pizhaikka seiyaavidil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5056,
                "Name": "Inimelaayinum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Chapu",
                "Praise of": "Devi-Parvathi",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5057,
                "Name": "Sharadaa deviye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Praise of": "Sharadamba",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5058,
                "Name": "nee keladayaraadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Khanda Chapu",
                "Praise of": "Rama",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5059,
                "Name": "Thillana-Vasantha",
                "Type": "Thillana",
                "Ragam": "Vasantha",
                "Thalam": "Roopakam",
                "Praise of": "Tirunelveli Kanthimathi amman",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5060,
                "Name": "Tharunamidhu karunai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Roopakam",
                "Praise of": "Tirunelveli Kanthimathi amman",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5061,
                "Name": "Thillana-Kalyani",
                "Type": "Thillana",
                "Ragam": "Kalyani",
                "Thalam": "Simhaanandanam",
                "Praise of": "Shiva",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5062,
                "Name": "Sharanam Sharanam aiyane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Group": "From MahaVaidhyanatha Sivan's Melaragamalika",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5063,
                "Name": "yezhai naan enseiven",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5064,
                "Name": "enna seivaen natesa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Triputa",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5065,
                "Name": "annaiye ennai aandarulvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5066,
                "Name": "en manam eppadi thirindhaalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5067,
                "Name": "thirunatanam seiyum oruvanai dharisiththaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Triputa",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5068,
                "Name": "Shambho sabaapathae paahimaam",
                "Type": "Pallavi",
                "Ragam": "Arabhi",
                "Thalam": "Gopuchayathi",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5069,
                "Name": "Shambho shiva saambha paahimaam maampahi",
                "Type": "Pallavi",
                "Ragam": "Shankarabharanam",
                "Thalam": "GajaJhampai",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5070,
                "Name": "hara hara shankara shiva paahimaam karunaanandha",
                "Type": "Pallavi",
                "Ragam": "Bhairavi",
                "Thalam": "Vilohitham",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5071,
                "Name": "Shambho nataraja paahimaam",
                "Type": "Pallavi",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mridangayathi",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5072,
                "Name": "Shambho umaapathe paahimaam",
                "Type": "Pallavi",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Lakshmeesham",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5073,
                "Name": "Shambho thaandavesa paahimaam",
                "Type": "Pallavi",
                "Ragam": "Thodi",
                "Thalam": "SamaDamarugam",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5074,
                "Name": "Shankara Shiva Shankara",
                "Type": "Pallavi",
                "Ragam": "Bilahari",
                "Thalam": "Pradhaabhasekaram",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5075,
                "Name": "Aananda natana prakaasham",
                "Type": "Pallavi",
                "Ragam": "Kedaram",
                "Thalam": "Dhathaathreyam",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5076,
                "Name": "Gowri naayaka Kanaga sabhaa naayaka",
                "Type": "Pallavi",
                "Ragam": "Kalyani",
                "Thalam": "Simhaanandanam",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5077,
                "Name": "Shankara Shiva Shankara",
                "Type": "Pallavi",
                "Ragam": "Saveri",
                "Thalam": "Tisra Laghu, 2 Tisra Nadai, Mishra Chapu",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5078,
                "Name": "Muthukumara aiyyane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5079,
                "Name": "Ekkaalathilum maravene",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Ramaswamy Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5080,
                "Name": "Maha Vaidhyanatha Shivan's MelaRagamalika",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Composer": "Maha Vaidhyanatha Sivan",
                "BookTitle": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
                "BookAuthor": "M N Srinivasan",
                "Year of Publication": 2001,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5081,
                "Name": "Venkata saila vihaara",
                "Type": "Pallavi",
                "Ragam": "Kharaharapriya",
                "Thalam": "Tisra Jathi Dhuruva Thalam (Mani)",
                "Praise of": "Vishnu",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5082,
                "Name": "Kanchi kaamaakshi",
                "Type": "Pallavi",
                "Ragam": "Kalyani",
                "Thalam": "Chatusra Jathi Dhuruva Thalam (Sreekara)",
                "Praise of": "Kanchipuram Kamakshi",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5083,
                "Name": "Maamaramum Nizhalum",
                "Type": "Pallavi",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Dhuruva Thalam (Pramaana)",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5084,
                "Name": "Shivakaama sundari",
                "Type": "Pallavi",
                "Ragam": "Vachaspathi",
                "Thalam": "Mishra Jathi Dhuruva Thalam (PoorNa)",
                "Praise of": "Devi-Parvathi",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5085,
                "Name": "Then parangiri vaazh murugaa",
                "Type": "Pallavi",
                "Ragam": "Thodi",
                "Thalam": "Sankeerna Jathi Dhuruva Thalam (Bhuvana)",
                "Praise of": "Murugan",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5086,
                "Name": "Maadhava Sreedhara",
                "Type": "Pallavi",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Tisra Jathi Matya Thalam (Saara)",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5087,
                "Name": "Mandha haasa vadhana",
                "Type": "Pallavi",
                "Ragam": "Mohanam",
                "Thalam": "Chatusra Jathi Matya thalam (Sama)",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5088,
                "Name": "Sree raghuvara",
                "Type": "Pallavi",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda Jathi Matya Thalam (Udhaya)",
                "Praise of": "Rama",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5089,
                "Name": "Unadhu paadham thunaiye",
                "Type": "Pallavi",
                "Ragam": "Thodi",
                "Thalam": "Mishra Jathi Matya Thalam (udheerNa)",
                "Praise of": "Murugan",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5090,
                "Name": "Kanjadalaayathaakshi",
                "Type": "Pallavi",
                "Ragam": "Khambhoji",
                "Thalam": "Sankeerna Jathi Matya Thalam (Raava)",
                "Praise of": "Kanchipuram Kamakshi",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5091,
                "Name": "Bhajare raghuveeram",
                "Type": "Pallavi",
                "Ragam": "Chakravaham",
                "Thalam": "Tisra Jathi Roopaka Thalam (Chakra)",
                "Praise of": "Rama",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5092,
                "Name": "Mahima theliya tharamaa",
                "Type": "Pallavi",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chatusra Jathi Roopaka Thalam (Paththi)",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5093,
                "Name": "Maa Madhurai meenakshi",
                "Type": "Pallavi",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda Jathi Roopaka Thalam (Raaja)",
                "Praise of": "Madurai Meenakshi",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5094,
                "Name": "Sankaadhi sannutha",
                "Type": "Pallavi",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Jathi Roopaka Thalam (Kula)",
                "Praise of": "Murugan",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5095,
                "Name": "Niranjaname",
                "Type": "Pallavi",
                "Ragam": "Thodi",
                "Thalam": "Sankeerna Jathi Roopaka Thalam (Bindu)",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5096,
                "Name": "Eesanai thudhi maname",
                "Type": "Pallavi",
                "Ragam": "Shanmugapriya",
                "Thalam": "Tisra Jathi Jhampa Thalam (Kadamba)",
                "Praise of": "Shiva",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5097,
                "Name": "Hare Raama Govinda Muraare",
                "Type": "Pallavi",
                "Ragam": "KedaraGowlai",
                "Thalam": "Chatusra Jathi Jhampa Thalam (Madhura)",
                "Praise of": "Vishnu",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5098,
                "Name": "Sharavana Bhava guhane",
                "Type": "Pallavi",
                "Ragam": "Pantuvarali",
                "Thalam": "Khanda Jathi Jhampa Thalam (ChaNa)",
                "Praise of": "Murugan",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5099,
                "Name": "Aananda kadalaadum aiyyane",
                "Type": "Pallavi",
                "Ragam": "Bilahari",
                "Thalam": "Mishra Jathi Jhampa Thalam (Soora)",
                "Praise of": "Murugan",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5100,
                "Name": "Neerajaasanasathi",
                "Type": "Pallavi",
                "Ragam": "Madhyamavathi",
                "Thalam": "Sankeerna Jathi Jhampa Thalam (Kara)",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5101,
                "Name": "Devaadi Devane",
                "Type": "Pallavi",
                "Ragam": "Thodi",
                "Thalam": "Tisra Jathi Triputa Thalam (Sanga)",
                "Praise of": "Chidambara Natarajar",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5102,
                "Name": "Undarisanam Kidaikkumo",
                "Type": "Pallavi",
                "Ragam": "Kalyani",
                "Thalam": "Chatusra Jathi Triputa Thalam (Adi)",
                "Praise of": "Natarajar",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5103,
                "Name": "Sree Raama Jayaraama",
                "Type": "Pallavi",
                "Ragam": "Poorvikalyani",
                "Thalam": "Khanda Jathi Triputa Thalam (Dushkara)",
                "Praise of": "Rama",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5104,
                "Name": "Vadivelan adiyaark anukoolan",
                "Type": "Pallavi",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Jathi Triputa Thalam (Leela)",
                "Praise of": "Murugan",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5105,
                "Name": "Maampazhani malai mahaapathiye",
                "Type": "Pallavi",
                "Ragam": "Kharaharapriya",
                "Thalam": "Sankeerna Jathi Triputa Thalam (Bhoga)",
                "Praise of": "Pazhani Murugan",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5106,
                "Name": "Meenakshi",
                "Type": "Pallavi",
                "Ragam": "Khambhoji",
                "Thalam": "Tisra Jathi Ata Thalam (Gupta)",
                "Praise of": "Madurai Meenakshi",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5107,
                "Name": "Sharavana Bhava guhane",
                "Type": "Pallavi",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chatusra Jathi Ata Thalam (Lekha)",
                "Praise of": "Murugan",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5108,
                "Name": "Neerajakshi Kaamakshi",
                "Type": "Pallavi",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda Jathi Ata Thalam (VidhaLa)",
                "Praise of": "Kanchipuram Kamakshi",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5109,
                "Name": "Aravinda DhaLaayathaakshi",
                "Type": "Pallavi",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Jathi Ata Thalam (Loya)",
                "Praise of": "Madurai Meenakshi",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5110,
                "Name": "Raamam Bhajeham Sadaa",
                "Type": "Pallavi",
                "Ragam": "Saveri",
                "Thalam": "Sankeerna Jathi Ata Thalam (Dheera)",
                "Praise of": "Rama",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5111,
                "Name": "Sangeethaachaarya",
                "Type": "Pallavi",
                "Ragam": "Thodi",
                "Thalam": "Tisra Jathi Eka Thalam (Soodha)",
                "Praise of": "Tyagaraja Swamy",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5112,
                "Name": "Maamyilerum murugaa",
                "Type": "Pallavi",
                "Ragam": "Hindolam",
                "Thalam": "Chatusra Jathi Eka Thalam (Maana)",
                "Praise of": "Murugan",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5113,
                "Name": "Maampaalaya",
                "Type": "Pallavi",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Khanda Jathi Eka Thalam (Ratha)",
                "Praise of": "Ganesha",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5114,
                "Name": "Kaamakoti peeta vaasini",
                "Type": "Pallavi",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Jathi Eka Thalam (Raaga)",
                "Praise of": "Kanchipuram Kamakshi",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5115,
                "Name": "Purandara vitalam Bhaja",
                "Type": "Pallavi",
                "Ragam": "Khambhoji",
                "Thalam": "Sankeerna Jathi Eka Thalam (vasu)",
                "Praise of": "Vishnu",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "35 Suladi Sapta Thala Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5116,
                "Name": "Thelupavo",
                "Type": "Pallavi",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Jathi Jhampa Thalam (Tisra Nadai)",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "Nadai Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5117,
                "Name": "Paraashakthi janani",
                "Type": "Pallavi",
                "Ragam": "Saveri",
                "Thalam": "Chatusra Jathi Triputa Thalam (Khanda Nadai)",
                "Praise of": "Devi-Parvathi",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "Nadai Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5118,
                "Name": "Vadivelan",
                "Type": "Pallavi",
                "Ragam": "Shankarabharanam",
                "Thalam": "Tisra Jathi Jhampa Thalam (Mishra Nadai)",
                "Praise of": "Murugan",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "Nadai Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5119,
                "Name": "Velavane ninadhu padhame",
                "Type": "Pallavi",
                "Ragam": "Kalyani",
                "Thalam": "Tisra Jathi Jhampa Thalam (Sankeerna Nadai)",
                "Praise of": "Murugan",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "Nadai Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5120,
                "Name": "Maa Madhurai meenakshi",
                "Type": "Pallavi",
                "Ragam": "Bhairavi",
                "Thalam": "Panchanadai",
                "Praise of": "Madurai Meenakshi",
                "Composer": "Tinniyam Venkatrama iyer",
                "Group": "Nadai Pallavis",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5121,
                "Name": "Kaamakoti peeta vihaarini",
                "Type": "Pallavi",
                "Ragam": "Kalyani",
                "Thalam": "Sarabhanandana",
                "Praise of": "Kanchipuram Kamakshi",
                "Composer": "Tinniyam Venkatrama iyer",
                "BookTitle": "Pallavi Ratna Mala",
                "BookAuthor": "Tinniyam Venkatrama iyer",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5122,
                "Name": "Swaravali Varisais (Count - 12)",
                "Type": "Practice",
                "Ragam": "Various",
                "Thalam": "Adi",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5123,
                "Name": "Irattai varisai/Jantai varisai (Count - 15)",
                "Type": "Practice",
                "Ragam": "Various",
                "Thalam": "Adi",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5124,
                "Name": "Mel Sthayi Varisais (Count - 9)",
                "Type": "Practice",
                "Ragam": "Various",
                "Thalam": "Adi",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5125,
                "Name": "Dhaatu Varisais (Count - 5)",
                "Type": "Practice",
                "Ragam": "Various",
                "Thalam": "Adi",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5126,
                "Name": "Alankarams (Count - 7)",
                "Type": "Practice",
                "Ragam": "Various",
                "Thalam": "Various",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5127,
                "Name": "VaaraNa mukha",
                "Type": "Sanchari Geetham",
                "Ragam": "Arabhi",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Praise of": "Ganesha",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5128,
                "Name": "Padham tharuvaai",
                "Type": "Sanchari Geetham",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Triplicane Parthasarathy perumal",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5129,
                "Name": "yezhu malai mel",
                "Type": "Sanchari Geetham",
                "Ragam": "Shankarabharanam",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Praise of": "Tirupathi Perumal",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5130,
                "Name": "Janaki devi",
                "Type": "Sanchari Geetham",
                "Ragam": "Sree",
                "Thalam": "Jhampai",
                "Praise of": "Sita Devi",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5131,
                "Name": "thuyiluvadheno",
                "Type": "Sanchari Geetham",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Praise of": "Srirangam Perumal",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5132,
                "Name": "KaruNai kadalamudhe",
                "Type": "Sanchari Geetham",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Praise of": "Saraswathi",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5133,
                "Name": "kaatharul vaaye ganapathye",
                "Type": "Sanchari Geetham",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5134,
                "Name": "Vadivela mayil meedhe",
                "Type": "Sanchari Geetham",
                "Ragam": "Dhanyasi",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5135,
                "Name": "Sivaperumaane",
                "Type": "Sanchari Geetham",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5136,
                "Name": "Thaamarai malar",
                "Type": "Sanchari Geetham",
                "Ragam": "Mohanam",
                "Thalam": "Chatusra Jathi Dhuruva Thalam",
                "Praise of": "Vishnu",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5137,
                "Name": "Naagareega uNarvu",
                "Type": "Sanchari Geetham",
                "Ragam": "Bhupalam",
                "Thalam": "Jhampai",
                "Praise of": "Saraswathi",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5138,
                "Name": "Gananaatha nin",
                "Type": "Sanchari Geetham",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Chatusra Jathi Dhuruva Thalam",
                "Praise of": "Ganesha",
                "Composer": "Fiddle MS Subramanya Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5139,
                "Name": "Arul purivaai thaaye",
                "Type": "Sanchari Geetham",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chatusra Jathi Matya Thalam",
                "Praise of": "Saraswathi",
                "Composer": "Fiddle MS Subramanya Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5140,
                "Name": "aanai mugane malai maamagal",
                "Type": "Sanchari Geetham",
                "Ragam": "Kamala Manohari",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Praise of": "Ganesha",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swamintha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5141,
                "Name": "kavuNiyar thavame",
                "Type": "Sanchari Geetham",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Praise of": "Tirugnanasampandhar",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swamintha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5142,
                "Name": "Sivaneriye melendru",
                "Type": "Sanchari Geetham",
                "Ragam": "Nagaswarali",
                "Thalam": "Roopakam",
                "Praise of": "Tirunavukarasar",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swamintha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5143,
                "Name": "Paravi kozhu",
                "Type": "Sanchari Geetham",
                "Ragam": "Jayantasena",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Praise of": "Sundarar",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swamintha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5144,
                "Name": "VaanaLaaviya",
                "Type": "Sanchari Geetham",
                "Ragam": "Chakravaham",
                "Thalam": "Chatusra Jathi Matya Thalam",
                "Praise of": "Manikkavasagar",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swamintha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5145,
                "Name": "PaiyaravaNai metruyilum",
                "Type": "Sanchari Geetham",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "TV Lakshminarasimhan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5146,
                "Name": "kola mayilin melamarum",
                "Type": "Sanchari Geetham",
                "Ragam": "Khambhoji",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Praise of": "Murugan",
                "Composer": "TV Lakshminarasimhan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5147,
                "Name": "Viththaikiraiye un",
                "Type": "Sanchari Geetham",
                "Ragam": "Gowlai",
                "Thalam": "Chatusra Jathi Matya Thalam",
                "Praise of": "Saraswathi",
                "Composer": "TV Lakshminarasimhan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5148,
                "Name": "Gurunaatha surabodhaa",
                "Type": "Sanchari Geetham",
                "Ragam": "Bhupalam",
                "Thalam": "Roopakam",
                "Praise of": "Ganesha",
                "Composer": "Arunachala Annaavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5149,
                "Name": "annaiyum pithaavum",
                "Type": "Sanchari Geetham",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Praise of": "Parents (Father & Mother)",
                "Composer": "Arunachala Annaavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5150,
                "Name": "aalayam thozhuvadhu saalavum",
                "Type": "Sanchari Geetham",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Praise of": "Saguna Dhyanam (Idol Worship)",
                "Composer": "Arunachala Annaavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5151,
                "Name": "thaai mozhiyil",
                "Type": "Sanchari Geetham",
                "Ragam": "Dhanyasi",
                "Thalam": "Chatusra Jathi Matya Thalam",
                "Praise of": "Nanneri Vazhkai (Code of Conduct)",
                "Composer": "Arunachala Annaavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5152,
                "Name": "PooraNiye kalaa valliye",
                "Type": "Sanchari Geetham",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Praise of": "Saraswathi",
                "Composer": "Arunachala Annaavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5153,
                "Name": "eNNaamal yethanaiyo",
                "Type": "Sanchari Geetham",
                "Ragam": "Poorvikalyani",
                "Thalam": "Chatusra Jathi Dhuruva Thalam",
                "Praise of": "Annamalai Chettiyar",
                "Composer": "Arunachala Annaavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5154,
                "Name": "Murugane arutsiva pudhalvane",
                "Type": "Sanchari Geetham",
                "Ragam": "Bhairavi",
                "Thalam": "Tisra Jathi Triputa",
                "Praise of": "Murugan",
                "Composer": "Arunachala Annaavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5155,
                "Name": "Deivamadhai igazhaadhe",
                "Type": "Sanchari Geetham",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "Nanneri Vazhkai (Code of Conduct)",
                "Composer": "Arunachala Annaavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5156,
                "Name": "Ambalathil aaDum",
                "Type": "Sanchari Geetham",
                "Ragam": "Mandari",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Praise of": "Chidambara Natarajar",
                "Composer": "Sambashiva Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5157,
                "Name": "Ganapathiye",
                "Type": "Sanchari Geetham",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Roopakam",
                "Praise of": "Ganesha",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5158,
                "Name": "Varuga Muruga Valli nayaka",
                "Type": "Sanchari Geetham",
                "Ragam": "Bhupalam",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5159,
                "Name": "KalaimagaLe amudhanaiyaai",
                "Type": "Sanchari Geetham",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Praise of": "Saraswathi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5160,
                "Name": "iLamaane himagirivaazh",
                "Type": "Sanchari Geetham",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Praise of": "Devi-Parvathi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5161,
                "Name": "Jyothiyin niraive",
                "Type": "Sanchari Geetham",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5162,
                "Name": "aruL niraive aanandhame",
                "Type": "Sanchari Geetham",
                "Ragam": "Darbar",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5163,
                "Name": "saminaatha aruLvaaye",
                "Type": "Sanchari Geetham",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5164,
                "Name": "ThirumagalE",
                "Type": "Sanchari Geetham",
                "Ragam": "Sree",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Praise of": "Devi-Lakshmi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5165,
                "Name": "Mazhai mugilae amudhanaiyaai",
                "Type": "Sanchari Geetham",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5166,
                "Name": "karuNaiye vadivudaiya",
                "Type": "Sanchari Geetham",
                "Ragam": "Bhairavi",
                "Thalam": "Jhampai",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5167,
                "Name": "aruLvaaye isai arivu",
                "Type": "Sanchari Geetham",
                "Ragam": "Naatai",
                "Thalam": "Chatusra Jathi Dhuruva Thalam",
                "Praise of": "Vishnu-Matysa avatharam",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
                "Group": "Dasavathara Geetham-1",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5168,
                "Name": "unadhu padamalar",
                "Type": "Sanchari Geetham",
                "Ragam": "Saveri",
                "Thalam": "Chapu",
                "Praise of": "Vishnu-Koorma avatharam",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
                "Group": "Dasavathara Geetham-2",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5169,
                "Name": "ThirumagaLurai maNi maarbhaa",
                "Type": "Sanchari Geetham",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu-Varaha avatharam",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
                "Group": "Dasavathara Geetham-3",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5170,
                "Name": "VaramaruLum kove",
                "Type": "Sanchari Geetham",
                "Ragam": "Bhairavi",
                "Thalam": "Chatusra Jathi Matya Thalam",
                "Praise of": "Vishnu-Narasimha avatharam",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
                "Group": "Dasavathara Geetham-4",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5171,
                "Name": "kaashipa muni",
                "Type": "Sanchari Geetham",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu-Vamana avatharam",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
                "Group": "Dasavathara Geetham-5",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5172,
                "Name": "Maamazhu yendhum kaiyaa",
                "Type": "Sanchari Geetham",
                "Ragam": "Narayana Gowlai",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Parashurama avatharam",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
                "Group": "Dasavathara Geetham-6",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5173,
                "Name": "Vaazhga dasaratha raama",
                "Type": "Sanchari Geetham",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama avatharam",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
                "Group": "Dasavathara Geetham-7",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5174,
                "Name": "Thaayu rohini",
                "Type": "Sanchari Geetham",
                "Ragam": "Naatakurinji",
                "Thalam": "Mishra Chapu",
                "Praise of": "Vishnu-Balaramar avatharam",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
                "Group": "Dasavathara Geetham-8",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5175,
                "Name": "Gopaalaa Devaki baalaa",
                "Type": "Sanchari Geetham",
                "Ragam": "Reethigowlai",
                "Thalam": "Chatusra Jathi Dhuruva Thalam",
                "Praise of": "Vishnu-Krishnar avatharam",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
                "Group": "Dasavathara Geetham-9",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5176,
                "Name": "Vaazhvuru kali yuzhiyil",
                "Type": "Sanchari Geetham",
                "Ragam": "Bilahari",
                "Thalam": "Chatusra Jathi Matya Thalam",
                "Praise of": "Vishnu-Kalki/Gargiya avatharam",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
                "Group": "Dasavathara Geetham-10",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5177,
                "Name": "vaaraNa mukhane",
                "Type": "Lakshana Geetham",
                "Ragam": "Malahari",
                "Thalam": "Roopakam",
                "Praise of": "Ganesha",
                "Composer": "TV Lakshminarasimhan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5178,
                "Name": "Natana maadiya paadam",
                "Type": "Lakshana Geetham",
                "Ragam": "Arabhi",
                "Thalam": "Triputa",
                "Praise of": "Shiva",
                "Composer": "TV Lakshminarasimhan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5179,
                "Name": "Jathiswaram-Naatai",
                "Type": "Jathiswaram",
                "Ragam": "Naatai",
                "Thalam": "Tisra Jathi Ata Thalam (Gupta)",
                "Composer": "Arunachala Annaavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5180,
                "Name": "Jathiswaram-KedaraGowlai",
                "Type": "Jathiswaram",
                "Ragam": "KedaraGowlai",
                "Thalam": "Roopakam",
                "Composer": "Arunachala Annaavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5181,
                "Name": "Jathiswaram-Dhanyasi",
                "Type": "Jathiswaram",
                "Ragam": "Dhanyasi",
                "Thalam": "Roopakam",
                "Composer": "Arunachala Annaavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5182,
                "Name": "Jathiswaram-Sree",
                "Type": "Jathiswaram",
                "Ragam": "Sree",
                "Thalam": "Chatusra Jathi Matya Thalam",
                "Composer": "Arunachala Annaavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5183,
                "Name": "Pannaga shayanane",
                "Type": "Swarajathi",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "TV Lakshminarasimhan",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5184,
                "Name": "Pirai aNi muDi",
                "Type": "Prabandham",
                "Ragam": "Naatai",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Mu Arunachalam Pillai & VS Gomathi Shankara Iyer",
                "Group": "Pradoshakala Shambhu Natanam",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5185,
                "Name": "AaDiya mudhalva ambikai",
                "Type": "Prabandham",
                "Ragam": "Gowlai",
                "Thalam": "Chapu",
                "Praise of": "Shiva",
                "Composer": "Mu Arunachalam Pillai & VS Gomathi Shankara Iyer",
                "Group": "Pradoshakala Shambhu Natanam",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5186,
                "Name": "Koothaaduvaai komaane",
                "Type": "Prabandham",
                "Ragam": "Arabhi",
                "Thalam": "Ekam",
                "Praise of": "Shiva",
                "Composer": "Mu Arunachalam Pillai & VS Gomathi Shankara Iyer",
                "Group": "Pradoshakala Shambhu Natanam",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5187,
                "Name": "aaDum amabala vaaNaa thamizh isai",
                "Type": "Prabandham",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Mu Arunachalam Pillai & VS Gomathi Shankara Iyer",
                "Group": "Pradoshakala Shambhu Natanam",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5188,
                "Name": "aaDum veLLi ambalavaa",
                "Type": "Prabandham",
                "Ragam": "Sree",
                "Thalam": "Jhampai",
                "Praise of": "Shiva",
                "Composer": "Mu Arunachalam Pillai & VS Gomathi Shankara Iyer",
                "Group": "Pradoshakala Shambhu Natanam",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5189,
                "Name": "anname aravaabharanaNai",
                "Type": "Varnam-Tana",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5190,
                "Name": "sariyo nee seiyum",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5191,
                "Name": "manam irangaadhadheno",
                "Type": "Varnam-Tana",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5192,
                "Name": "karuNai kaDale kalyani",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Parvathi - Tiruvotriyur ambal",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5193,
                "Name": "alai kaDal",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5194,
                "Name": "aalilaimel kaNvaLartha",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Lyrics: Mu. Arunachalam Pillai/Music: Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5195,
                "Name": "kaadal noikku",
                "Type": "Varnam-Tana",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Lyrics: Mu. Arunachalam Pillai/Music: Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5196,
                "Name": "Devaadi devaniru",
                "Type": "Varnam-Tana",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "Lyrics: Mu. Arunachalam Pillai/Music: Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5197,
                "Name": "netriravu kaathirundhu",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Lyrics: Mu. Arunachalam Pillai/Music: Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5198,
                "Name": "aaraavamudhe",
                "Type": "Varnam-Tana",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Lyrics: Mu. Arunachalam Pillai/Music: Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5199,
                "Name": "neeye mudhalvan yaane adimai",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Lyrics: Mu. Arunachalam Pillai/Music: Tiger K Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5200,
                "Name": "vaa vaa mahaa ganapathy",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Arunachala Annaavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5201,
                "Name": "murugaa unmel moham",
                "Type": "Varnam-Tana",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Arunachala Annaavi",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5202,
                "Name": "Madhurapuri vaasini",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Madurai Meenakshi",
                "Composer": "KeezhvELUr Meenakshi Sundaram Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5203,
                "Name": "anbuLLa durai enthan",
                "Type": "Varnam-Tana",
                "Ragam": "Bhupalam",
                "Thalam": "Adi",
                "Praise of": "Tiruvannamalai Shivan",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5204,
                "Name": "ambalathaadum en aiyan",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5205,
                "Name": "amudhinum inimaiyaa",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Thamizh mozhi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5206,
                "Name": "thendral vandhu endran",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5207,
                "Name": "kola mayil paagan",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5208,
                "Name": "kaNNanin karuNaiyai",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5209,
                "Name": "pagalelaa nin padam",
                "Type": "Varnam-Tana",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5210,
                "Name": "ellam aran seyal endrennu nenjame",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5211,
                "Name": "Bhudhaththaalaagiya",
                "Type": "Varnam-Tana",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5212,
                "Name": "madhana roopaa ninnai naan",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5213,
                "Name": "Sadhguru paadhaththai",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5214,
                "Name": "ambaa ninnai vendiye",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5215,
                "Name": "vaadhugal purivadhum",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5216,
                "Name": "kaNNan thaniye vandhu",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5217,
                "Name": "soodhinai ariyaa naan",
                "Type": "Varnam-Tana",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5218,
                "Name": "naatin kalai ellaam",
                "Type": "Varnam-Tana",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5219,
                "Name": "maathae en kaadalar",
                "Type": "Varnam-Tana",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Pa Vajravelu Mudaliar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5220,
                "Name": "maal ariyaa naan mukhanum",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Pa Vajravelu Mudaliar",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5221,
                "Name": "veezhimizhalai",
                "Type": "Varnam-Tana",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5222,
                "Name": "maa madurai meen vizhiyaaL",
                "Type": "Varnam-Tana",
                "Ragam": "Malayamarutham",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Praise of": "Madurai Meenakshi",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5223,
                "Name": "vaanor paNi ponnambala vaaNane",
                "Type": "Varnam-Tana",
                "Ragam": "HariKhambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Praise of": "Chidambara Natarajar",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5224,
                "Name": "vaNDaar poonkuzhal",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Praise of": "Thirupampuram Shivan",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5225,
                "Name": "Undran paadam paNindhen",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Praise of": "Vishnu",
                "Composer": "Fiddle MS Subramanya Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5226,
                "Name": "Sree Ramachandrane",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Praise of": "Rama",
                "Composer": "Fiddle MS Subramanya Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5227,
                "Name": "naadhane parane",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Praise of": "Chidambara Natarajar",
                "Composer": "Fiddle MS Subramanya Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5228,
                "Name": "kaayaa malar meniyane kaaviri soozh",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Praise of": "Srirangam Ranganathar",
                "Composer": "Lyrics: Mu Arunachalam Pillai/Music: Fiddle MS Subramanya Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5229,
                "Name": "maaye manadhuvandharuN",
                "Type": "Varnam-Tana",
                "Ragam": "Abhogi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Praise of": "Devi-Parvathi",
                "Composer": "Mayuram Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5230,
                "Name": "thaanaagi nindra deva",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Mayuram Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
                "BookAuthor": "Various",
                "Year of Publication": 1953,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5231,
                "Name": "Kanakangi",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5232,
                "Name": "vanajakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Lalitha",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5233,
                "Name": "Panthamela naa saami",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5234,
                "Name": "Nee Vanti",
                "Type": "Varnam-Tana",
                "Ragam": "Kamala Manohari",
                "Thalam": "Adi",
                "Composer": "Chinnaih Pillai (Tanjore Quartet)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5235,
                "Name": "Saami kaane",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5236,
                "Name": "Kanakangi",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5237,
                "Name": "Naameetha",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5238,
                "Name": "soothathi",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5239,
                "Name": "Valachi vachchi",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5240,
                "Name": "saami naapai",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5241,
                "Name": "salamujese",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5242,
                "Name": "Chalamela",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5243,
                "Name": "Sarasija naabha",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5244,
                "Name": "Vanajaaksha",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Vadivelu Pillai (Tanjore Quaret)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5245,
                "Name": "samukamu",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Vadivelu Pillai (Tanjore Quaret)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5246,
                "Name": "Intha chalamu",
                "Type": "Varnam-Tana",
                "Ragam": "Surutti",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "K Ponniah Pillai",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5247,
                "Name": "KaruNaa",
                "Type": "Varnam-Tana",
                "Ragam": "Atana",
                "Thalam": "Tisra Jathi Ata Thalam (Gupta)",
                "Composer": "K Ponniah Pillai",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5248,
                "Name": "Manasu",
                "Type": "Varnam-Tana",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5249,
                "Name": "saaranganayanaro",
                "Type": "Varnam-Tana",
                "Ragam": "Saranga",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5250,
                "Name": "Saara saakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5251,
                "Name": "Maayaatheetha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Roopakam",
                "Composer": "Ponniah Pillai (Thanjavur Quatret)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5252,
                "Name": "Sree guruguha moorthy",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pinnashadjam/Dhenuka",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5253,
                "Name": "Sree Karambu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda Chapu",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5254,
                "Name": "Saarassakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShailaDesaakshi",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5255,
                "Name": "Parama paavani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5256,
                "Name": "Needhu paadhame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5257,
                "Name": "Sree Raajarajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5258,
                "Name": "Saatileni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5259,
                "Name": "Saaregu nee paadamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5260,
                "Name": "Dheena rakshaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahiri",
                "Thalam": "Roopakam",
                "Praise of": "Srirangam Ranganathar",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5261,
                "Name": "Ranganaathude",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Roopakam",
                "Praise of": "Srirangam Ranganathar",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5262,
                "Name": "SabhalachiththuDa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Mishra Chapu",
                "Praise of": "Tanjore Bruhadeeshwarar",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5263,
                "Name": "Bruhadeeshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Praise of": "Tanjore Bruhadeeshwarar",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5264,
                "Name": "ninnupaasi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Tanjore Bruhadeeshwarar",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5265,
                "Name": "kaamitha phala naayaki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Jhampai",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5266,
                "Name": "Parama Kalyani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5267,
                "Name": "Aadi parameshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5268,
                "Name": "Bhaktha paalana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5269,
                "Name": "Sree mahaadevuni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5270,
                "Name": "Ninnu saaregu nammithi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5271,
                "Name": "Sree mahaadeva manohari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5272,
                "Name": "Bruhadamba",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Jhampai",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "Group": "Composed when one of the brothers was sick",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5273,
                "Name": "ambaayanine",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5274,
                "Name": "Needhu paadhame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5275,
                "Name": "Ambaa neelambari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5276,
                "Name": "Nee sari saati",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5277,
                "Name": "MangaLa pradhampulichu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Praise of": "Madurai Meenakshi",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5278,
                "Name": "Krupa jooda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Kanchipuram Kamakshi",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5279,
                "Name": "Kaliyuga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Mishra Chapu",
                "Praise of": "Nagapatnam Nilaayathaakshi",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5280,
                "Name": "ambaa sowrambhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Praise of": "Chamundeshwari",
                "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5281,
                "Name": "KaruNa jeyave",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Pandanallur Meenakshi Sundaram Pillai",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5282,
                "Name": "kandukaanagaramuna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Pandanallur Meenakshi Sundaram Pillai",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5283,
                "Name": "tharuNam eedhamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlipanthu",
                "Thalam": "Adi",
                "Composer": "Shyama Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5284,
                "Name": "enna punniyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Mishra Chapu",
                "Composer": "Shivanam Yogi",
                "Song Language": "Tamil",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5285,
                "Name": "Perungkovil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Mishra Chapu",
                "Composer": "K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5286,
                "Name": "ippaDi en manam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Mishra Chapu",
                "Composer": "Thiruveezhimalai Swaminatha Kavirayar",
                "Song Language": "Tamil",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5287,
                "Name": "Jagadeeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Thiruvaalur Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5288,
                "Name": "Ekkaalathilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Thiruvaalur Swaminatha Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5289,
                "Name": "Idhu nalla samayam amma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Thiruvaalur Swaminatha Pillai",
                "Group": "Chaturbhaashaa Maalikai",
                "Song Language": "Tamil, Kannadam, Hindi, Sanskrit",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5290,
                "Name": "Gowri nayakaa",
                "Type": "Thillana",
                "Ragam": "Kaanada",
                "Thalam": "Simhaanandanam",
                "Composer": "Maha Vaidhyanatha Sivan",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5291,
                "Name": "Thillana-Kapi",
                "Type": "Thillana",
                "Ragam": "Kaapi",
                "Thalam": "Deshadi",
                "Composer": "Chinnaih Pillai",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5292,
                "Name": "Thillana-Bilahari",
                "Type": "Thillana",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "K Ponniah Pillai",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5293,
                "Name": "Thillana-Atana",
                "Type": "Thillana",
                "Ragam": "Atana",
                "Thalam": "Deshadi",
                "Composer": "K Ponniah Pillai",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5294,
                "Name": "Thillana-Behag",
                "Type": "Thillana",
                "Ragam": "Behag",
                "Thalam": "Mishra Chapu",
                "Composer": "K Ponniah Pillai",
                "BookTitle": "Thanjai Peruvudaiyaan Perisai",
                "BookAuthor": "K Ponniah Pillai",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5295,
                "Name": "Nee Indha maayam",
                "Type": "Varnam-Pada",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5296,
                "Name": "Swaami nee manamirangi",
                "Type": "Varnam-Tana",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5297,
                "Name": "Gajavadana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5298,
                "Name": "Sri Ganesha Sharanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tilang",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5299,
                "Name": "Kai koDuththaaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5300,
                "Name": "aruL puri ainkarane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5301,
                "Name": "sadaa shiva kumaaraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5302,
                "Name": "saraswathi dayainidhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Praise of": "Saraswathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5303,
                "Name": "vaaNi aruL purivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Saraswathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5304,
                "Name": "ulaga vaazhvilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5305,
                "Name": "thaNigai vaLar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Jhampai",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5306,
                "Name": "SharavaNa bhava guhane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5307,
                "Name": "Maadhayai nidhiyenum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5308,
                "Name": "Maal Marugaa Shanmugaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5309,
                "Name": "vandharuL vaai Murugaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5310,
                "Name": "Sharavana Bhava enum thirumandhiram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5311,
                "Name": "Mahalakshmi jagan maathaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Triputa",
                "Praise of": "Lakshmi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5312,
                "Name": "Nee irangaayenil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Lakshmi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5313,
                "Name": "Swaamikku sari evvare",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Triputa",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5314,
                "Name": "Sundara roopa goopaalanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5315,
                "Name": "Aayiram manmadhar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5316,
                "Name": "Kaadhali raadhayai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5317,
                "Name": "dayavillaiyaa dhayaaLo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5318,
                "Name": "Iniyoru gaNam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5319,
                "Name": "Ninaindhurugum aDiyaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5320,
                "Name": "Gopaalane Deivamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5321,
                "Name": "Gopaala sajjana baalaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5322,
                "Name": "swaami siridhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5323,
                "Name": "mohana muraLidharaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5324,
                "Name": "kaNNanai paNi maname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5325,
                "Name": "enadhu uLLame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5326,
                "Name": "muraLee dhara manamOhana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5327,
                "Name": "SharaNaam bhujam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5328,
                "Name": "thuNai purindharuL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5329,
                "Name": "naaraayaNa naLinaayatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5330,
                "Name": "Ksheerasaagara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Triputa",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5331,
                "Name": "Unnai ninaindhu ninaindhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5332,
                "Name": "uNdendru nambu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5333,
                "Name": "kanja malaraDi thanjamaDaindhen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5334,
                "Name": "aththaruNam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5335,
                "Name": "sakala lokha naayakane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5336,
                "Name": "Vaa Vaa Madhivadhanane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5337,
                "Name": "peraanandham kaaN",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5338,
                "Name": "kamala malaridhazh",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Triputa",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5339,
                "Name": "gathineeye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Praise of": "Srirangam Ranganathar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5340,
                "Name": "Ranganai thudhipporku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Praise of": "Srirangam Ranganathar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5341,
                "Name": "Ennai sodhanai seiyaadhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Chapu",
                "Praise of": "Srirangam Ranganathar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5342,
                "Name": "Jaya vittala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Praise of": "Pandurangan-Rakumai",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5343,
                "Name": "Indra jaalaviththaikkaaran",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Tisra Jathi Eka Thalam",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5344,
                "Name": "Neeye paaramukhamaayin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanaadham",
                "Thalam": "Adi",
                "Praise of": "Pandurangan-Rakumai",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5345,
                "Name": "karuNaanidhiye kaDaikkaN",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntala Varali",
                "Thalam": "Adi",
                "Praise of": "Pandurangan-Rakumai",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5346,
                "Name": "Bhuvana ",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Pandurangan-Rakumai",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5347,
                "Name": "unai allaal oru thurumbu asaiyumo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saramathi",
                "Thalam": "Adi",
                "Praise of": "Pandurangan-Rakumai",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5348,
                "Name": "Raamanai bhajiththaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5349,
                "Name": "Mahimai ariya tharamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5350,
                "Name": "Bhajanai seivom",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natabhairavi",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5351,
                "Name": "Chaamundeshwari sankari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "Chamundeshwari",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5352,
                "Name": "ambaa manamkanindhu unadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5353,
                "Name": "Sree Jagadhambikaiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Roopakam",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5354,
                "Name": "Parashakthi janani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5355,
                "Name": "Maathaa thuNai purivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5356,
                "Name": "amale amarargaL paNiyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntala Varali",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5357,
                "Name": "thaaye neeye thanjam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5358,
                "Name": "thaaye bhairaviye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5359,
                "Name": "Shankari dhayakari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5360,
                "Name": "paradevathe padam nambinen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Deshadi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5361,
                "Name": "unaidinam paNindhadhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5362,
                "Name": "Mahishaasura mardhini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5363,
                "Name": "Aiyaa nin adaikalame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5364,
                "Name": "Un perumaiyai yevar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5365,
                "Name": "annaamalai naadhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Triputa",
                "Praise of": "Thiruvannaamalai Annaamalaiyaan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5366,
                "Name": "O shiva permaalE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5367,
                "Name": "nin aruL iyambalaamo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5368,
                "Name": "pathipadam paNivadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5369,
                "Name": "thaayumaanavane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5370,
                "Name": "sivaperumaan krupai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5371,
                "Name": "naadhan thaaL thuNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hameer Kalyani",
                "Thalam": "Tisram",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5372,
                "Name": "Paarvathi naayakane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5373,
                "Name": "unai allaal gathi yaar ulagilae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balahamsa",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5374,
                "Name": "Piravaa varam thaarum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5375,
                "Name": "Deva devanai thruvaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5376,
                "Name": "para deivam unai andRi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5377,
                "Name": "Sundareshwarane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5378,
                "Name": "NeelakaNTaa nithya nirmala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi (Khanda Nadai)",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5379,
                "Name": "kaal maariyaadum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Deshadi",
                "Praise of": "Madurai Natarajar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5380,
                "Name": "umaiyaar paagane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chalanaatai",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5381,
                "Name": "sakala bhuvana nayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Roopakam",
                "Praise of": "Natarajar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5382,
                "Name": "aananda natamidum paadhan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Praise of": "Natarajar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5383,
                "Name": "Gnaanasabayil Thillai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Triputa",
                "Praise of": "Chidambara Natarajar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5384,
                "Name": "Aadum Deivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Natarajar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5385,
                "Name": "Chidambara naathaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Praise of": "Chidambara Natarajar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5386,
                "Name": "Kaana vendaamo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Tisra Jathi Eka Thalam",
                "Praise of": "Chidambara Natarajar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5387,
                "Name": "Kanakasabhayai kanda piragu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Roopakam",
                "Praise of": "Chidambara Natarajar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5388,
                "Name": "Adbhutha leelaigaLai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "Chidambara Natarajar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5389,
                "Name": "aanandhame paramaanandhame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Natarajar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5390,
                "Name": "Pathi avane ulagam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Praise of": "Natarajar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5391,
                "Name": "Kailaasapathiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Praise of": "Natarajar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5392,
                "Name": "Maa RamaNan umaa ramaNan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu & Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5393,
                "Name": "saarasa poompaadham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5394,
                "Name": "aarana porulE abhayamaiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "DevaGandhari",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5395,
                "Name": "engum nirandhirukkum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurinji",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5396,
                "Name": "kaapaali karunanidhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5397,
                "Name": "pollaa puliyinum pollaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5398,
                "Name": "jyothi mayamaana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurinji",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5399,
                "Name": "tiruvaDi paNiyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5400,
                "Name": "kunchithapaadham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5401,
                "Name": "deivamthanai bhajippeer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5402,
                "Name": "ellam avan seyal endrenni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Chapu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5403,
                "Name": "kadalil aminzhndhiduvom",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5404,
                "Name": "Paradevathaiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Praise of": "Thulasi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5405,
                "Name": "paaril nal vazhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Deepam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5406,
                "Name": "naadha pranava vadivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Adi",
                "Praise of": "NaadaBrahmam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5407,
                "Name": "ulaga vaazhvil oru maghizhvundo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "Mana Upadesam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5408,
                "Name": "kaatruLLa podhe thootrikkoL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5409,
                "Name": "illaadhadhi virumbhi keDugiraai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5410,
                "Name": "veenE azhiyaadhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5411,
                "Name": "kaamaththai jeikaadhavan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5412,
                "Name": "thamizh maNam kamazha vEnDum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Praise of": "Thamizh mozhi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5413,
                "Name": "sreedhara venkatesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Chapu",
                "Praise of": "Thiruvisainalloor Sridhara AiyaavaaL",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5414,
                "Name": "vaanaveLiyilE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Radio",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5415,
                "Name": "paamaalaikku iNaiunDO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Praise of": "Bharathiyar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5416,
                "Name": "thirukural yenum pudhaiyal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Praise of": "Thirukural",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5417,
                "Name": "aruL surakkum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5418,
                "Name": "thaan manam aruL surandhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Sundararai thaDuthu aaTkoLLa vandha Sivan thirukolam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5419,
                "Name": "kaNDaal kaNDadhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Praise of": "Paravai naachiyar Deiveega vanappu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5420,
                "Name": "vaazhaki yenum vaNDi",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Human Life",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5421,
                "Name": "Shaanthamilaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kannadaa",
                "Thalam": "Adi",
                "Praise of": "Those without mental peace",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5422,
                "Name": "Bhuddhar piraane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Praise of": "Bhuddha",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5423,
                "Name": "paarulagil uzhandru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Bhuddha",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5424,
                "Name": "kaNDEn vaanavar kaaNaa kaaTchi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Bhuddha",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5425,
                "Name": "murugaa muzhu madhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5426,
                "Name": "maayaavadhaara madhusudhana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Chapu",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5427,
                "Name": "natana sabhaapathiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Praise of": "Natarajar",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5428,
                "Name": "Thillana-Shanmugapriya",
                "Type": "Thillana",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5429,
                "Name": "Thillana-Hindolam",
                "Type": "Thillana",
                "Ragam": "Hindolam",
                "Thalam": "Triputa",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5430,
                "Name": "palumaaru ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5431,
                "Name": "entha kintha modi",
                "Type": "Varnam-Tana",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5432,
                "Name": "palumaaru ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5433,
                "Name": "Era naapai",
                "Type": "Varnam-Tana",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5434,
                "Name": "Evvari Bodhana",
                "Type": "Varnam-Tana",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5435,
                "Name": "pagavaari bhodha",
                "Type": "Varnam-Tana",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5436,
                "Name": "Era napai",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5437,
                "Name": "sarassninnechaala",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5438,
                "Name": "Valachi vachchi",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "Group": "Navaragamalika",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5439,
                "Name": "saami nee vegame",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5440,
                "Name": "Manasetiki",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5441,
                "Name": "ikanelanevaani",
                "Type": "Varnam-Tana",
                "Ragam": "Poornachandrika",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5442,
                "Name": "padarethi meragaa",
                "Type": "Varnam-Tana",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5443,
                "Name": "yevaremi bhodhana",
                "Type": "Varnam-Tana",
                "Ragam": "Sahana",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5444,
                "Name": "imkaachala melanaiyya",
                "Type": "Varnam-Tana",
                "Ragam": "Chakravaham",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5445,
                "Name": "sami ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Atana",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5446,
                "Name": "Nera Nammithi-Kaanada",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanada",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Ramanathapuram Poochi Srinivasa Iyengar",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5447,
                "Name": "Sree Gana Lola shrutha jana",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika (Sree, Mohanam, Thodi, Kalyani, Keeravani, Nayaki, Sahana, Vasantha, Surutti)",
                "Thalam": "Roopakam",
                "Composer": "CS Krishnaswami Ayyar",
                "Group": "Nithya Ratna Malika",
                "BookTitle": "Sri Venkateshwara Taana Varnangal",
                "BookAuthor": "C S Krishnaswamy Iyer (Patnam Subramania Iyer Disciple)",
                "Year of Publication": 1917,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5448,
                "Name": "paavana guru bhavana puraadeeshamaashrye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Roopakam",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5449,
                "Name": "Gajaanana paahi sadaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Roopakam",
                "Praise of": "Ganesha",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5450,
                "Name": "vaarijadhaLalochani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5451,
                "Name": "Shrutha jana ranjani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janaranjani",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5452,
                "Name": "Sree VenkataShailapathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kannadaa",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5453,
                "Name": "Maaruthe namousthuthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Praise of": "Hanuman",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5454,
                "Name": "Maamava sadaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5455,
                "Name": "tripura sundari naapai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amrithavarshini",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5456,
                "Name": "Kailaasa pathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5457,
                "Name": "Sree Vishwanatham Shruta",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Jhampai",
                "Praise of": "Shiva",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5458,
                "Name": "Saama gaana vinodhini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Chapu",
                "Praise of": "Devi-Parvathi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5459,
                "Name": "Entha bhaagya vanthulo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Praise of": "Lalithambal",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5460,
                "Name": "Krupajuda idhe samayamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Roopakam",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5461,
                "Name": "Vandheham bhagavantham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5462,
                "Name": "Sree Kanchi nagara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Kanchipuram Kamakshi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5463,
                "Name": "Sarvaalaya nilaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Roopakam",
                "Praise of": "Lalithambal",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5464,
                "Name": "paalinchutaku samayamidhenu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Lalithambal",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5465,
                "Name": "Poornathrayee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5466,
                "Name": "naameetha neekiganu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Roopakam",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5467,
                "Name": "pankeruha naapa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Roopakam",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5468,
                "Name": "nannu brova nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Lalithambal",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5469,
                "Name": "palukavemammaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chapu",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5470,
                "Name": "Kohlaapura nilaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5471,
                "Name": "nannu brova nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5472,
                "Name": "Vanaja nayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5473,
                "Name": "Bhujakaadhipa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Roopakam",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5474,
                "Name": "Shivaanandha lahari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Praise of": "Lalithambal",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5475,
                "Name": "Bhajanaanandaamrutha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5476,
                "Name": "Evvariki thelusu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5477,
                "Name": "vinapaDu sunnathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5478,
                "Name": "Akilandeshwari jaya jaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5479,
                "Name": "Ra Ra Muralidhara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijayanagari",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5480,
                "Name": "Vinevaa revarammaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5481,
                "Name": "Maragadha mani neela",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5482,
                "Name": "Nannu brova vEmi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Roopakam",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5483,
                "Name": "karuNanu vinavEmi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5484,
                "Name": "Thaamasa mikanaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Roopakam",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5485,
                "Name": "Jaya Jaya Sreekari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5486,
                "Name": "radhaa dhara makarandha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5487,
                "Name": "Saagara kumaari nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5488,
                "Name": "Paarthasaarathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Roopakam",
                "Praise of": "Krishna",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5489,
                "Name": "Ranga naayaki maamava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nayaki",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5490,
                "Name": "Vihaara muthaa mama hrudaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5491,
                "Name": "Nanda Nandanaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desh",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5492,
                "Name": "ramaa nannu brovaraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5493,
                "Name": "Sadaa Brahmaanandame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5494,
                "Name": "aataleeraa sannayaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5495,
                "Name": "Vyaagrapureesha jaya jaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5496,
                "Name": "aananda mukhaanunda raadhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5497,
                "Name": "Sharaja bhava maamava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5498,
                "Name": "neeketaku nammaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Chapu",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5499,
                "Name": "Shankara guruvaramaashraye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5500,
                "Name": "Sree Shaaradaambike",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Jhampai",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5501,
                "Name": "Bhakthula sangame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5502,
                "Name": "nannuLaalimbha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Lalithambal",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5503,
                "Name": "Bhaaskara maNDala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "Lalithambal",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5504,
                "Name": "Chinthayeham Sree",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5505,
                "Name": "Shaarade namousthuthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5506,
                "Name": "Dharmasamvardhini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5507,
                "Name": "Shivasudhaayathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Roopakam",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5508,
                "Name": "Aananda natana vilola",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Chapu",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5509,
                "Name": "Madhuraapuri nilaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5510,
                "Name": "Ganapathiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5511,
                "Name": "Deviyai kaNDen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5512,
                "Name": "malaipondra nam paapam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5513,
                "Name": "ThiruthaNi vaazhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5514,
                "Name": "Karpagavalli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5515,
                "Name": "iniyaagilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5516,
                "Name": "paapathil patrudhalaamo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5517,
                "Name": "unnaDimalarinai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5518,
                "Name": "paaDupaaDurasane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Praise of": "Lalithambal",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5519,
                "Name": "eppaDi aanandham koLvaarO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5520,
                "Name": "Sangeethaa nandha rasike",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Lalithambal",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5521,
                "Name": "Ennil kanindhavun",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Lalithambal",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5522,
                "Name": "Sivanai anudhinam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amrithavarshini",
                "Thalam": "Mishra Chapu",
                "Praise of": "Shiva",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5523,
                "Name": "Un Krupaiyaale",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5524,
                "Name": "Imaya malai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5525,
                "Name": "Ambaa Saraswathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Praise of": "Saraswathi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5526,
                "Name": "ennavendrazhaikil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Jhampai",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5527,
                "Name": "ennakku munnaruL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5528,
                "Name": "nee viLaiyaaDina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntala Varali",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5529,
                "Name": "IthDhiname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Lalithambal",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5530,
                "Name": "akkarai thaaNDuvadheppadiyO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5531,
                "Name": "Ennakku enna manakkavalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5532,
                "Name": "annaiye ninaithOngum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5533,
                "Name": "vaaNi aruL purivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Roopakam",
                "Praise of": "Saraswathi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5534,
                "Name": "unnai maravaadhirukka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Jhampai",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5535,
                "Name": "vaai irundhum un thaaraga naamaththai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5536,
                "Name": "Un perumaiyai endrum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5537,
                "Name": "paDathil un uruvathil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5538,
                "Name": "uyir irukkaiyil unnai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5539,
                "Name": "Bhakthi andre allaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5540,
                "Name": "Ponmalai engeyo eththanai dooramO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5541,
                "Name": "Chandiranai ponDra vadana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Mishra Chapu",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5542,
                "Name": "en meedhum kanindha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5543,
                "Name": "naan enna puNNiyam seidhEnO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5544,
                "Name": "Abhayam aruL ennaku ambikaiyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5545,
                "Name": "DakshiNaamoorthaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Jhampai",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5546,
                "Name": "Sree Visaalaakshiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5547,
                "Name": "SanjalamEn en nenjagamE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5548,
                "Name": "Ambikaiyin anbe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5549,
                "Name": "unnarulE en pon poruLelaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5550,
                "Name": "lalithE yenum un naama prayaagaiyil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5551,
                "Name": "kaNNanai kaNDaayO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5552,
                "Name": "Un anbarkkendrum aanandhame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Chapu",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5553,
                "Name": "Chenthaamarai poovE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "UdhayaRaviChandrika",
                "Thalam": "Roopakam",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5554,
                "Name": "unnaDiyE en",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Chapu",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5555,
                "Name": "sonnadhEdhO nijamE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5556,
                "Name": "innam orudharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5557,
                "Name": "Indha varam yenakkE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5558,
                "Name": "Idhu nam kaNNanadhu thiruppadham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Punnagavarali, Shogini, Behag, Chenchurutti)",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5559,
                "Name": "Pazhaniyil maghindhezhun",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Malayamarutham, Saranga, Sahana, MayamalawaGowlai, Neelambari)",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5560,
                "Name": "aaDuyirE maghizhndhaaDuyirE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (AnandaBhairavi, Bilahari, Dhanyasi, Khamas, Saranga, Kedaram, Shogini, SindhuBhairavi, Surutti)",
                "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Song Language": "Tamil",
                "BookTitle": "Sri Lalitha Dasar Keerthanaigal",
                "BookAuthor": "Sri Lalitha Dasar T G Krishna Aiyar",
                "Year of Publication": 1984,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5561,
                "Name": "Ganapathy",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Group": "Prahlada Charithra Keerthanaigal",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5562,
                "Name": "IdhikO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Mishram",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5563,
                "Name": "veDala namma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "DevaGandhari",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5564,
                "Name": "vachchenithikO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5565,
                "Name": "PrahlaadhuDu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5566,
                "Name": "sanuthenchenidhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Mishram",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5567,
                "Name": "aruthenjchi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5568,
                "Name": "Intha kObha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5569,
                "Name": "Harimeethi vairamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ghanta",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5570,
                "Name": "Asurulu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5571,
                "Name": "Hariyenthu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Mishram",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5572,
                "Name": "Vachchene",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5573,
                "Name": "chanDaamarkuDu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlipanthu",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5574,
                "Name": "IdhukO asura",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5575,
                "Name": "Haribhajane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maanji",
                "Thalam": "Mishram",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5576,
                "Name": "SharaNu dhayaambhudhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5577,
                "Name": "Chinni BaaluDu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Karnataka Devagandhari",
                "Thalam": "Khanda Laghu",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5578,
                "Name": "yemaninE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahiri",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5579,
                "Name": "Endhuku palumaaru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahiri",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5580,
                "Name": "Bhaamu jogivachche jooda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5581,
                "Name": "aruthenjchenidhukO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlipanthu",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5582,
                "Name": "Hari Bhakthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5583,
                "Name": "GajendrutithukO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5584,
                "Name": "kaavichallaDamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5585,
                "Name": "Erapaalaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5586,
                "Name": "Bhumi devi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5587,
                "Name": "yeraa ori paalaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5588,
                "Name": "Deva Deva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5589,
                "Name": "saamujesenidhukO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5590,
                "Name": "Rakkasuni KaDupu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Mishram",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5591,
                "Name": "ala amarulu veDaliri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5592,
                "Name": "veDala namma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishram",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5593,
                "Name": "Sree Narahari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Melatthu Venkatramana Shastry/Edited in Notation-B Krishnamurthi",
                "Song Language": "Telugu",
                "BookTitle": "Prahlada Charithra Keerthanas",
                "BookAuthor": "Melathur Venkatrama Shastry/B Krishnamurthi",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5594,
                "Name": "Gajavadhana bheDuvE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5595,
                "Name": "yenu dhanyaLo lakumi emtha maanyaLo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5596,
                "Name": "ninna nODi thandhya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5597,
                "Name": "guDi guDi yanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5598,
                "Name": "ludhara vairaagyavidhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5599,
                "Name": "kaNdEnaa govindhanaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5600,
                "Name": "Paraaku maaDathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5601,
                "Name": "koDu koDu koDu KoDu Rangaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5602,
                "Name": "neene anaadha bandhu kaaruNya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Mishra Chapu",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5603,
                "Name": "Raama Raama Raama Raama Raama ennirO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5604,
                "Name": "smarane ondhe saaladhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Roopakam",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5605,
                "Name": "thaaLa bhEgu thakka mELa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5606,
                "Name": "Raama naama paayasakke",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5607,
                "Name": "Jo Jo Jo Jo Jo saadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5608,
                "Name": "nanda thanaya govindathana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhimplas",
                "Thalam": "Jhampai",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5609,
                "Name": "raama mantrava japiso",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jonpuri",
                "Thalam": "Deshadi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5610,
                "Name": "thaamboolava koLLavO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5611,
                "Name": "yaare ranganaa yaare krishnanaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Deshadi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5612,
                "Name": "BhamTa naadhenu naanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Jhampai",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5613,
                "Name": "paalisemma muddu sharadhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5614,
                "Name": "venkatachala nilayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5615,
                "Name": "bhaarathi deviye nene",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5616,
                "Name": "sreenivasa ennabhittu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Roopakam",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5617,
                "Name": "andhakana dhootharike",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhageshri",
                "Thalam": "Jhampai",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5618,
                "Name": "sree kaantha ena ghishtu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Mishra Chapu",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5619,
                "Name": "eethanuva nambalu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5620,
                "Name": "siva darshana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5621,
                "Name": "bhaaro brahmaadhivandhya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5622,
                "Name": "bhaagyadha lakshmee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5623,
                "Name": "Bhanda dhella bharali",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5624,
                "Name": "Devaki nandana mukunda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5625,
                "Name": "dharma shravaNa vidhethakE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devakriya",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5626,
                "Name": "yaadhava neebhaa yadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5627,
                "Name": "manava nilisuvadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Mishra Chapu",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5628,
                "Name": "praaNanaatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Roopakam",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5629,
                "Name": "alli nOTalu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Mishra Chapu",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5630,
                "Name": "mooruthiyanu nillisO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5631,
                "Name": "Krishna moorthy kaNNa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Jhampai",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5632,
                "Name": "aaTahO dhalle",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Jhampai",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5633,
                "Name": "Govindana Dhyaana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5634,
                "Name": "Deva bandha namma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5635,
                "Name": "ikO namma swaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5636,
                "Name": "ranga koLalanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5637,
                "Name": "laali sidhaLu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5638,
                "Name": "sakala graha bhala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Jhampai",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5639,
                "Name": "yaaramaga namma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Mishra Chapu",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5640,
                "Name": "yaarige yaarumDu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5641,
                "Name": "ennakate haaisuvadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Jhampai",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5642,
                "Name": "Dhooru maaduvare rangaiyana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaroj",
                "Thalam": "Roopakam",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5643,
                "Name": "sundara mooruthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5644,
                "Name": "neenyaako ninna hangyaako",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5645,
                "Name": "jaya jaya jaya jaanaki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Mishra Chapu",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5646,
                "Name": "Sharanam BhevaaNi poraya kalyaaNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GambiraNaatai",
                "Thalam": "Roopakam",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5647,
                "Name": "kaNNaare kaNDe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Roopakam",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5648,
                "Name": "aadhadhella oLithe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5649,
                "Name": "yaake ninaghishtu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Mishra Chapu",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5650,
                "Name": "dhoobhaarathiya nODuva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Roopakam",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5651,
                "Name": "hariya nenayadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maaruva",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5652,
                "Name": "anjiki gnyaadhakaiya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5653,
                "Name": "Dhayamaato dhayamaato rangaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5654,
                "Name": "ee pariya sowbhagu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Jhampai",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5655,
                "Name": "Chikkavane ivanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanakalyani",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5656,
                "Name": "kathaa shravaNamaato",
                "Type": "Kriti/Keerthanam",
                "Ragam": "YamunaKalyani",
                "Thalam": "Roopakam",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5657,
                "Name": "harismaraNe maato",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hameer Kalyani",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5658,
                "Name": "kaNDu kaNDu nee enna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Jhampai",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5659,
                "Name": "bheeteninna paadhava bhimka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5660,
                "Name": "mella mellane bandene",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5661,
                "Name": "naanEke badavanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Jhampai",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5662,
                "Name": "aparaadhi naanalla",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pilu",
                "Thalam": "Mishra Chapu",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5663,
                "Name": "mangaLam jaya mangaLam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Mishra Chapu",
                "Composer": "Purandaradasar",
                "Group": "Mangalam",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5664,
                "Name": "karuNiga LoLagENe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Composer": "Jagannaatha Vittalar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5665,
                "Name": "yelemanave nee mareyadhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Jaya Vittalar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5666,
                "Name": "haNave ninnaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Vaadharaaja swamy",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5667,
                "Name": "neenubhet sayamaaDe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Jhampai",
                "Composer": "Keshava Daasar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5668,
                "Name": "karuNa viyaakE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Roopakam",
                "Composer": "Jaya Vittalar",
                "Song Language": "Kannada",
                "BookTitle": "Sri Purandara mani maalai",
                "BookAuthor": "Purandaradasar",
                "Year of Publication": 1941,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5669,
                "Name": "Nandan charithram aanandam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Nool Perumai",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5670,
                "Name": "ThirunaaLai pOvaan charithram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5671,
                "Name": "neesanaai pirandhaalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Khanda Chapu",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5672,
                "Name": "pazhana marungkaNaiyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Praise of": "aadhanoor seri varnanai",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5673,
                "Name": "pazhana marungkaNaiyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Praise of": "aadhanoor seri varnanai",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5674,
                "Name": "aadhanooril oru vedhiyar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaroj",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5675,
                "Name": "vaarirukkudhu thOl irukkudhu",
                "Type": "Dialogue",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Tisra Laghu",
                "Praise of": "Nandanar selling strings and animal hides for instruments",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5676,
                "Name": "vaarirukkudhu thOl irukkudhu",
                "Type": "Dialogue",
                "Ragam": "Huseni",
                "Thalam": "Tisra Laghu",
                "Praise of": "Nandanar selling strings and animal hides for instruments",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5677,
                "Name": "Nandanar: Sivane Deivam",
                "Type": "Dialogue",
                "Ragam": "Shuddha Saveri",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5678,
                "Name": "Pulayargal: seriyE sorgam yeriyE kailaasam",
                "Type": "Dialogue",
                "Ragam": "Shuddha Saveri",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5679,
                "Name": "Sivalokha naadhanai kaNDU",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5680,
                "Name": "Sendhaamarai malar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Tisra Laghu",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5681,
                "Name": "arahara Shiva Shiva",
                "Ragam": "YamunaKalyani",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5682,
                "Name": "singaaramaana paLLu paaDinaar",
                "Ragam": "Poorvikalyani",
                "Thalam": "Tisra Laghu",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5683,
                "Name": "thalam vandhu veedhi valam vandhu",
                "Ragam": "Kedaram",
                "Thalam": "Khanda Laghu",
                "Praise of": "Nandanar after reaching Thirupunkoor",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5684,
                "Name": "Sivalokha naadan thiru sannidhaanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Khanda Laghu",
                "Praise of": "Nandanar saying when he sees that Nandi is obstructing the view of Shivan",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5685,
                "Name": "vazhi maraithu irukudhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Praise of": "Nandanar saying when he sees that Nandi is obstructing the view of Shivan",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5686,
                "Name": "vazhi maraithu irukudhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Chapu",
                "Praise of": "Nandanar saying when he sees that Nandi is obstructing the view of Shivan",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5687,
                "Name": "satrE vilagi irum piLLaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Roopakam",
                "Praise of": "Thirupunkoor Shivan asking Nandi to move aside",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5688,
                "Name": "Satru vilagi irum pillai",
                "Ragam": "Hamsadhwani",
                "Thalam": "Tisra Laghu",
                "Praise of": "Thirupunkoor Shivan telling Nandi",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5689,
                "Name": "oru naaLum varaadha bhakthan",
                "Thalam": "Khanda Laghu",
                "Praise of": "Thirupunkoor Shivan telling Nandi",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5690,
                "Name": "matradhellaam porupEn",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5691,
                "Name": "kudhithaar ekkaliththaar",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "No Thalam",
                "Praise of": "Nandanar's state when he got the darshan of Thirupunkoor Shivan",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5692,
                "Name": "thadaagam ondru undaakinaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Vinayagar making a lake for Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5693,
                "Name": "naaLai pOgaamal iruppEnO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maanji",
                "Thalam": "Chapu",
                "Praise of": "Nandanar says",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5694,
                "Name": "naaLai pOgaamal iruppEnO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chapu",
                "Praise of": "Nandanar says",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5695,
                "Name": "kaaNaamal irukkalaagaadhu",
                "Type": "Kriti/Keerthanam",
                "Thalam": "Mishram",
                "Praise of": "Nandanar telling Pulayargal",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5696,
                "Name": "ellOrum vaarungaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Praise of": "Nandanar calling his people (Pulayargal)",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5697,
                "Name": "Nandanar: Sivane Deivam",
                "Type": "Dialogue",
                "Ragam": "Chenchurutti",
                "Thalam": "No Thalam",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5698,
                "Name": "Nandanar: Thillai ambalam endru solla sonnaar",
                "Type": "Dialogue",
                "Praise of": "Argument between Nandanar and Pulayargal",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5699,
                "Name": "Pulayargal: kaLLa pEchendru meLLasonnaar",
                "Type": "Dialogue",
                "Praise of": "Argument between Nandanar and Pulayargal",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5700,
                "Name": "vaarungaL vaarungaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Jhampai",
                "Praise of": "Nandanar telling Pulayargal",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5701,
                "Name": "Pulayargal: paarpaar deivamadhu palikkaadhu paraiyarai",
                "Type": "Dialogue",
                "Ragam": "Themmangu",
                "Thalam": "Adi",
                "Praise of": "Argument between Nandanar and Pulayargal",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5702,
                "Name": "Nandanar: Paarpaar paraiyar endru paaraadhu paridhipOl",
                "Type": "Dialogue",
                "Ragam": "Themmangu",
                "Thalam": "Adi",
                "Praise of": "Argument between Nandanar and Pulayargal",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5703,
                "Name": "Periya Kizhavan: Gnyaayam thaane neer sollum",
                "Type": "Dialogue",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Periya Kizhavan telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5704,
                "Name": "Periya Kizhavan: Gnyaayam thaane neer sollum",
                "Type": "Dialogue",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Praise of": "Periya Kizhavan telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5705,
                "Name": "paarpaar jaathi undE",
                "Type": "Dialogue",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Praise of": "Periya Kizhavan telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5706,
                "Name": "Chidambara darisanam kaaNaa vidil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "YamunaKalyani",
                "Thalam": "Adi",
                "Praise of": "Nandanar telling Periya Kizhavan",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5707,
                "Name": "meesai naraithu pOche kizhavaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Ekam",
                "Praise of": "Nandanar telling Periya Kizhavan",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5708,
                "Name": "periya kizhavan varugiraan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Praise of": "Arrival of Periya Kizhavan",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5709,
                "Name": "veeran iruLan kaaTTEri",
                "Thalam": "Ekam",
                "Praise of": "Gods worshipped by Pulayargal",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5710,
                "Name": "veeran iruLan kaaTTEri",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Ekam",
                "Praise of": "Gods worshipped by Pulayargal",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5711,
                "Name": "veeran iruLan kaaTTEri",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Adi",
                "Praise of": "Gods worshipped by Pulayargal",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5712,
                "Name": "Pulayargal: ellai piDaariyE",
                "Ragam": "Neelambari",
                "Thalam": "No Thalam",
                "Praise of": "Argument between Nandanar and Pulayargal",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5713,
                "Name": "Nandanar: Sollinum aDangaadha",
                "Ragam": "Neelambari",
                "Thalam": "No Thalam",
                "Praise of": "Argument between Nandanar and Pulayargal",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5714,
                "Name": "thirunaaLai pOvaar indha sErikkum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Praise of": "Periya Kizhavan telling Pulayargal the greatness of Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5715,
                "Name": "thathipuli pOlE thaaNDi gudhippaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Tisra Laghu",
                "Praise of": "Nandanar asking Pulayargal to chant Shiva naamaa",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5716,
                "Name": "ara hara shiva shiva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Ekam",
                "Praise of": "Pulayargal singing glory of Shiva Naama at Nandanar's Insistence",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5717,
                "Name": "ara hara shiva shiva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Tisra Laghu",
                "Praise of": "Pulayargal singing glory of Shiva Naama at Nandanar's Insistence",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5718,
                "Name": "paarthu pizhaiyungaL neengaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jonpuri",
                "Thalam": "Adi",
                "Praise of": "Nandanar telling Pulayargal",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5719,
                "Name": "namakkini yama bhayam yEdhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlipanthu",
                "Thalam": "Adi",
                "Praise of": "Nandanar telling Pulayargal",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5720,
                "Name": "thillai veLiyilE kalandhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "YamunaKalyani",
                "Thalam": "Adi",
                "Praise of": "Nandanar saying with tears on eyes",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5721,
                "Name": "thillai thalam endru solla thoDanginaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Praise of": "Nandanar saying with tears on eyes",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5722,
                "Name": "AiyE oru sEdhi kELum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5723,
                "Name": "sEdhi solla vandhOm nandanaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dwijavanthi",
                "Thalam": "Adi",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5724,
                "Name": "sEdhi solla vandhOm nandanaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Ekam",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5725,
                "Name": "nandanaarum vandhaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Nandanar came to see his Brahmin Landlord",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5726,
                "Name": "Nandanar: thillai ambala thalam ondru irukku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Roopakam",
                "Praise of": "Nandanar telling his Brahmin Landlord",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5727,
                "Name": "Nandanar: aaDiya paadhathai kaaNaare",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "Nandanar telling his Brahmin Landlord",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5728,
                "Name": "Nandanar: uththaaram thaarum aiyyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Ekam",
                "Praise of": "Nandanar telling his Brahmin Landlord that he wishes to go to Chidambaram",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5729,
                "Name": "Brahmin Landlord: paraiyaa nee chidambaram endru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Brahmin Landlord telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5730,
                "Name": "Brahmin Landlord: sEri mutrum shiva bhakthi paNNum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Tisra Laghu",
                "Praise of": "Brahmin Landlord telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5731,
                "Name": "Brahmin Landlord: sEri mutrum shiva bhakthi paNNum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Tisra Laghu",
                "Praise of": "Brahmin Landlord telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5732,
                "Name": "Brahmin Landlord: nandaa oru sEdhi solrEn kEL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Brahmin Landlord telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5733,
                "Name": "Brahmin Landlord: Chidambaram pOgaadhE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Praise of": "Brahmin Landlord telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5734,
                "Name": "Nandanar: Bhakthi paNNi koNDirunthaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Praise of": "Nandanar tells Brahmin Landlord",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5735,
                "Name": "Nandanar: Bhakthi paNNi koNDirunthaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Roopakam",
                "Praise of": "Nandanar tells Brahmin Landlord",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5736,
                "Name": "Nandanar: Thillai chidambarathai oru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Praise of": "Nandanar tells Brahmin Landlord",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5737,
                "Name": "Brahmin Landlord: Chidambaram darisanamaa nee adhai sindhikalaamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Praise of": "Brahmin Landlord telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5738,
                "Name": "Nandanar: pitham theLiya marundhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Praise of": "Nandanar tells Brahmin Landlord",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5739,
                "Name": "Nandanar: Chidambaram pOgaamal iruppEnO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Praise of": "Nandanar tells Brahmin Landlord",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5740,
                "Name": "Nandanar: aasai nEsaraagum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maanji",
                "Thalam": "Chapu",
                "Praise of": "Nandanar tells Brahmin Landlord",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5741,
                "Name": "Brahmin landlord: maaDu thinnum pulaiyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Adi",
                "Praise of": "Brahmin Landlord telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5742,
                "Name": "Nandanar: Gopura darisaname enthan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Nandanar singing when having the darshan of Chidambara gopuram",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5743,
                "Name": "Nandanar: maangkuyil kooviya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Ekam",
                "Praise of": "Nandanar describing Chidambaram",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5744,
                "Name": "Brahmin landlord: Nandaa unakku indha madhi thandhadhu yaaradaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Brahmin Landlord telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5745,
                "Name": "Brahmin landlord: nandanaare undhan perumai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Chapu",
                "Praise of": "Brahmin Landlord telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5746,
                "Name": "Brahmin landlord: yezhai paarpaan seidhiDum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Adi",
                "Praise of": "Brahmin Landlord telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5747,
                "Name": "Brahmin landlord: nandaa nee shiva bhakthan unnai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Chapu",
                "Praise of": "Brahmin Landlord telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5748,
                "Name": "Brahmin landlord: yaaruku thaan theriyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Praise of": "Brahmin Landlord telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5749,
                "Name": "Brahmin landlord: yaarukku ponnambalam krupai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Brahmin Landlord telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5750,
                "Name": "Nandanar: AiyyE meththakaDinam umakkaDimai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Praise of": "Brahmin Landlord asking Nandanar for Guru Upadesham, Nandanar replying to it",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5751,
                "Name": "Brahmin Landlord: chidambaram pOi nee vaarumaiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Praise of": "Brahmin Landlord telling Nandanar",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5752,
                "Name": "Nandanar: Chidambara darisanam kidaikkumO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5753,
                "Name": "Brahmin Landlord: KiDaikkum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5754,
                "Name": "GhAnTa maNi aaDudhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Chapu",
                "Praise of": "Bell Sound in Chidambaram",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5755,
                "Name": "Nandanar: mukthi aLikkum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaroj",
                "Thalam": "Chapu",
                "Praise of": "Nandanar saying \"Chidambaram vandhu darisiyaadha piravi vEEN\"",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5756,
                "Name": "Nandanar: kaaNaadha kaN enna kaNNO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Chapu",
                "Praise of": "Nandanar saying in ecstasy",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5757,
                "Name": "Nandanar: VarugalaamO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maanji",
                "Thalam": "Chapu",
                "Praise of": "Nandanar saying",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5758,
                "Name": "Nandanar: periyOr irukkumiDam thillai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Praise of": "Nandanar saying",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5759,
                "Name": "Nandanar: kanaka sabEsan sEvaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Praise of": "Nandanar saying",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5760,
                "Name": "Nandanar: varaamal iruppaarO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "Nandanar saying",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5761,
                "Name": "Nandanar: innum varakaaNEnE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Praise of": "Nandanar saying",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5762,
                "Name": "Nandanar: idhu nalla samayam aiyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Nandanar saying",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5763,
                "Name": "Nandanar: virudhaa janmam aachE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Praise of": "Nandanar saying",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5764,
                "Name": "Nandanar: sannidhi varalaamO saami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Ekam",
                "Praise of": "Nandanar saying",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5765,
                "Name": "Nandanar: kanavO ninaivO kaNDadhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Chapu",
                "Praise of": "Nandanar talks about his dream",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5766,
                "Name": "Nandanar: ambalavaaNanai thenpuliyooraanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahiri",
                "Thalam": "Mishra Chapu",
                "Praise of": "Nandanar saying",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5767,
                "Name": "kaLai yeDAmal jalam viDAmal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natabhairavi",
                "Thalam": "Roopakam",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5768,
                "Name": "thirunaaLaipOvaarukku jaya mangaLam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Praise of": "Mangalam",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5769,
                "Name": "aadhi madhyaanandanukku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Chapu",
                "Praise of": "Mangalam",
                "Composer": "Gopalakrishna Bharathi",
                "Group": "Nandanar Charithram",
                "Song Language": "Tamil",
                "BookTitle": "Gopalakrishna Bharathis Nandanar Charithram",
                "BookAuthor": "Gopalakrishna Bharathi",
                "Year of Publication": 1971,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5770,
                "Name": "Vanaspathi-Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Vanaspathi",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5771,
                "Name": "Maanavati-Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Manavathi",
                "Thalam": "Roopakam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5772,
                "Name": "Bhairavi-Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Bhairavi",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5773,
                "Name": "Kharaharapriya-Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Kharaharapriya",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5774,
                "Name": "Karnataka Kapi-Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Karnataka Kapi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5775,
                "Name": "Khaambhoji-Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Khambhoji",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5776,
                "Name": "Chenchurutti-Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Chenchurutti",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5777,
                "Name": "Khamas-Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Khamas",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5778,
                "Name": "Shankarabharanam-Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Shankarabharanam",
                "Thalam": "Sankeerna Jathi Triputa Thalam (Bhoga)",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5779,
                "Name": "Neelambari-Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Neelambari",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5780,
                "Name": "Behag-Jathiswaram",
                "Type": "Jathiswaram",
                "Ragam": "Behag",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5781,
                "Name": "Gaanaloludaina",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Dhuruva Thalam (Pramaana)",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5782,
                "Name": "Neerajaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5783,
                "Name": "maayamElaraa",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5784,
                "Name": "sarasijaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Kedaram",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5785,
                "Name": "ninne nammiyunnadhiraa",
                "Type": "Varnam-Tana",
                "Ragam": "DevaGandhari",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5786,
                "Name": "kaama shathakOTi sundara",
                "Type": "Varnam-Tana",
                "Ragam": "Jhaalavarali",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5787,
                "Name": "saami ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Poorvikalyani",
                "Thalam": "Khanda Jathi Matya Thalam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5788,
                "Name": "kaamakOTi sundaraangkuta",
                "Type": "Varnam-Tana",
                "Ragam": "Behag (Ragamalika Chittaiswarams)",
                "Thalam": "Mishra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5789,
                "Name": "mahishaasura samhaariNi",
                "Type": "Varnam-Tana",
                "Ragam": "Begada (Ragamalika Chittaiswarams)",
                "Thalam": "Sankeerna Jathi Matya Thalam (Raava)",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5790,
                "Name": "SreepathE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ganamoorthi",
                "Thalam": "Roopakam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5791,
                "Name": "Raamaabhiraama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhenuka",
                "Thalam": "Roopakam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5792,
                "Name": "Sadaa Shiva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natakapriya",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5793,
                "Name": "Gangaadhara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowri",
                "Thalam": "Roopakam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5794,
                "Name": "Raama ninnu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5795,
                "Name": "thaamasambu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5796,
                "Name": "ubhaya kaaveri ranga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gamanashrama",
                "Thalam": "Roopakam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5797,
                "Name": "Ninnu joochi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Roopakam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5798,
                "Name": "dharamukhaadhuraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5799,
                "Name": "Indha sOdhana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rishabhapriya",
                "Thalam": "Roopakam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5800,
                "Name": "yemani thelupudhuraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5801,
                "Name": "Thillana-Thodi",
                "Type": "Thillana",
                "Ragam": "Thodi",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5802,
                "Name": "Thillana-Thodi",
                "Type": "Thillana",
                "Ragam": "Thodi",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5803,
                "Name": "Thillana-Paras",
                "Type": "Thillana",
                "Ragam": "Paras",
                "Thalam": "Madhyadi",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5804,
                "Name": "Thillana-Bhairavi",
                "Type": "Thillana",
                "Ragam": "Bhairavi",
                "Thalam": "Tisra Jathi Roopaka Thalam (Chakra)",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5805,
                "Name": "Thillana-Kaanadaa",
                "Type": "Thillana",
                "Ragam": "Kaanada",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5806,
                "Name": "Thillana-Darbari Kaanadaa",
                "Type": "Thillana",
                "Ragam": "Darbari Kaanadaa",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5807,
                "Name": "Thillana-Khamas",
                "Type": "Thillana",
                "Ragam": "Khamas",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5808,
                "Name": "Thillana-Chenchurutti",
                "Type": "Thillana",
                "Ragam": "Chenchurutti",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5809,
                "Name": "Thillana-Hindustani Kapi",
                "Type": "Thillana",
                "Ragam": "Hindustani Kapi",
                "Thalam": "Mishra Chapu",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5810,
                "Name": "Thillana-Shankarabharanam",
                "Type": "Thillana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5811,
                "Name": "Thillana-Kedaram",
                "Type": "Thillana",
                "Ragam": "Kedaram",
                "Thalam": "Roopakam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5812,
                "Name": "Thillana-Begada",
                "Type": "Thillana",
                "Ragam": "Begada",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5813,
                "Name": "Thillana-Kannadaa",
                "Type": "Thillana",
                "Ragam": "Kannadaa",
                "Thalam": "Deshadi",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5814,
                "Name": "Thillana-Behag",
                "Type": "Thillana",
                "Ragam": "Behag",
                "Thalam": "Deshadi",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5815,
                "Name": "Thillana-Behag",
                "Type": "Thillana",
                "Ragam": "Behag",
                "Thalam": "Deshadi",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5816,
                "Name": "Thillana-Kalyani",
                "Type": "Thillana",
                "Ragam": "Kalyani",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5817,
                "Name": "Thillana-Purvi",
                "Type": "Thillana",
                "Ragam": "Purvi",
                "Thalam": "ChowthaaL",
                "Composer": "Veena Seshanna",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5818,
                "Name": "yenendhu pogaLalli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Mishra Chapu",
                "Composer": "Veena Seshanna",
                "Song Language": "Kannada",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5819,
                "Name": "kanavaatha ninna sannithikE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Jhampai",
                "Composer": "Veena Seshanna",
                "Song Language": "Kannada",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5820,
                "Name": "SiriyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Chatusra Jathi Triputa Thalam",
                "Composer": "Veena Seshanna",
                "Song Language": "Kannada",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5821,
                "Name": "kaapaaDapEkuthaaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Mishra Chapu",
                "Composer": "Veena Seshanna",
                "Song Language": "Kannada",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5822,
                "Name": "shaarade varadhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Praise of": "Sharadamba",
                "Composer": "Veena Seshanna",
                "Song Language": "Kannada",
                "BookTitle": "Compositions of Vainika Sikhamani Veena Seshanna",
                "BookAuthor": "Veena Seshanna",
                "Year of Publication": 1965,
                "Book Print Language": "Kannadaa"
              },
              {
                "Serial #": 5823,
                "Name": "gaNapathy kavuthuvam-Kari mukha",
                "Type": "kavuthuvam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "King Shahaji of Thanjavur",
                "BookTitle": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
                "BookAuthor": "King Shahaji of Thanjavur",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 5824,
                "Name": "Thodaya Mangalam-nathaamara raaja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Vasantha, Kalyani, Madhyamavathi)",
                "Thalam": "Khanda Chapu",
                "Composer": "King Shahaji of Thanjavur",
                "BookTitle": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
                "BookAuthor": "King Shahaji of Thanjavur",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 5825,
                "Name": "Sallaama dharu - karpoora dhavaLaanga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "King Shahaji of Thanjavur",
                "BookTitle": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
                "BookAuthor": "King Shahaji of Thanjavur",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 5826,
                "Name": "jakkiNi dharu prabandham-indhu kalaadhara",
                "Type": "Prabandham",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "King Shahaji of Thanjavur",
                "BookTitle": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
                "BookAuthor": "King Shahaji of Thanjavur",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 5827,
                "Name": "Ukthaa lakshaNa padham-thyaageshaa chinthithO",
                "Type": "Padam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "King Shahaji of Thanjavur",
                "BookTitle": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
                "BookAuthor": "King Shahaji of Thanjavur",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 5828,
                "Name": "Bhakthi padham-SharaNu dharunEndhu",
                "Type": "Padam",
                "Ragam": "Pantuvarali",
                "Thalam": "Mishra Chapu",
                "Composer": "King Shahaji of Thanjavur",
                "BookTitle": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
                "BookAuthor": "King Shahaji of Thanjavur",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 5829,
                "Name": "dheera padham-saarasa dhayaakara",
                "Type": "Padam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "King Shahaji of Thanjavur",
                "BookTitle": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
                "BookAuthor": "King Shahaji of Thanjavur",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 5830,
                "Name": "viraha padham-saarasaakshisE",
                "Type": "Padam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "King Shahaji of Thanjavur",
                "BookTitle": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
                "BookAuthor": "King Shahaji of Thanjavur",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 5831,
                "Name": "tribhaashaa padham-vaarum dhayatO",
                "Type": "Padam",
                "Ragam": "Khamas",
                "Thalam": "Mishra Chapu",
                "Composer": "King Shahaji of Thanjavur",
                "BookTitle": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
                "BookAuthor": "King Shahaji of Thanjavur",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 5832,
                "Name": "kaNDithaa lakshaNa padham-Shiva Shiva",
                "Type": "Padam",
                "Ragam": "Kaapi",
                "Thalam": "Mishra Chapu",
                "Composer": "King Shahaji of Thanjavur",
                "BookTitle": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
                "BookAuthor": "King Shahaji of Thanjavur",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 5833,
                "Name": "vairaagya padham-dhayaa thujE jaalyaanE",
                "Type": "Padam",
                "Ragam": "YamunaKalyani",
                "Thalam": "Adi",
                "Composer": "King Shahaji of Thanjavur",
                "BookTitle": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
                "BookAuthor": "King Shahaji of Thanjavur",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 5834,
                "Name": "neethi padham-Bhaavaye panchanadeesham",
                "Type": "Padam",
                "Ragam": "Dhanyasi",
                "Thalam": "Mishra Chapu",
                "Composer": "King Shahaji of Thanjavur",
                "BookTitle": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
                "BookAuthor": "King Shahaji of Thanjavur",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 5835,
                "Name": "sapta saagara sooLaadhi prabhanda leelaadharu-Sree thyaagEsha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (NarayanaGowlai, KannadaGowlai, MalawaGowlai, ReethiGowlai, PoorviGowlai, ChaayaaGowlai, KedaraGowlai)",
                "Thalam": "Thalamalika (C.Dhuruva, C. Matya, C. Roopaka, C.Jhampai, C.Triputa, C.Ata, C.Ekam)",
                "Composer": "King Shahaji of Thanjavur",
                "BookTitle": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
                "BookAuthor": "King Shahaji of Thanjavur",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 5836,
                "Name": "chaturdasha bhuvana anuraaga raagamaalikaa dharu-satata gowri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Gowri, Kalyani, Saranga, Naatai, Bhairavi, Varali, LalithaPanchamam, Ghanta, Lalitha, Madhyamavathi)",
                "Thalam": "Adi",
                "Composer": "King Shahaji of Thanjavur",
                "BookTitle": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
                "BookAuthor": "King Shahaji of Thanjavur",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 5837,
                "Name": "thyaagesha mangalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "PanchaNadai",
                "Composer": "King Shahaji of Thanjavur",
                "BookTitle": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
                "BookAuthor": "King Shahaji of Thanjavur",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 5838,
                "Name": "GaNanaatha",
                "Type": "Varnam-Tana",
                "Ragam": "DevaGandhari",
                "Thalam": "Adi",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5839,
                "Name": "sendhil vaazh",
                "Type": "Varnam-Tana",
                "Ragam": "Hameer Kalyani",
                "Thalam": "Adi",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5840,
                "Name": "dharmamaa",
                "Type": "Varnam-Tana",
                "Ragam": "Varamu",
                "Thalam": "Adi",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5841,
                "Name": "annaiyE",
                "Type": "Varnam-Tana",
                "Ragam": "Dwijavanthi",
                "Thalam": "Adi",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5842,
                "Name": "innamum",
                "Type": "Varnam-Tana",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5843,
                "Name": "angayarkaNNi",
                "Type": "Varnam-Pada",
                "Ragam": "Mishra SivaRanjani",
                "Thalam": "Adi",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5844,
                "Name": "innum",
                "Type": "Varnam-Tana",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5845,
                "Name": "Vanajaaksha",
                "Type": "Varnam-Tana",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5846,
                "Name": "vigneshwaram",
                "Type": "Pallavi",
                "Ragam": "Hameer Kalyani",
                "Thalam": "Adi (Khanda Nadai)",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5847,
                "Name": "subramanyam",
                "Type": "Pallavi",
                "Ragam": "Khambhoji",
                "Thalam": "Chatusra Jathi Roopaka Thalam (Paththi)",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5848,
                "Name": "saravaNanai",
                "Type": "Pallavi",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5849,
                "Name": "Sri Raajagopaala",
                "Type": "Pallavi",
                "Ragam": "Saveri",
                "Thalam": "Tisra Jathi Triputa Thalam (Tisra Nadai)",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5850,
                "Name": "velavaa",
                "Type": "Pallavi",
                "Ragam": "Reethigowlai",
                "Thalam": "Chatusra Jathi Roopaka Thalam (Mishra Nadai)",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5851,
                "Name": "namaami",
                "Type": "Pallavi",
                "Ragam": "Hamsadhwani",
                "Thalam": "Mishra Jathi Triputa Thalam",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5852,
                "Name": "SaravaNa",
                "Type": "Pallavi",
                "Ragam": "Kaanada",
                "Thalam": "Mishra Jathi Triputa Thalam (Tisra Nadai)",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5853,
                "Name": "vaibhavE",
                "Type": "Pallavi",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5854,
                "Name": "Shambho",
                "Type": "Pallavi",
                "Ragam": "Pantuvarali",
                "Thalam": "Mishra Triputa",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5855,
                "Name": "maa mano",
                "Type": "Pallavi",
                "Ragam": "Surutti",
                "Thalam": "Khanda Triputa",
                "Composer": "TR Subramanyam",
                "BookTitle": "Varnams & Pallavis",
                "BookAuthor": "TR Subramanyam",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 5856,
                "Name": "thiruvutrilaghu ganga varaiyil",
                "Type": "Kavadi Chindu",
                "Ragam": "HariKhambhoji",
                "Thalam": "Mishra Chapu",
                "Praise of": "Ganesha",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5857,
                "Name": "seervaLar pasunthogai",
                "Type": "Kavadi Chindu",
                "Ragam": "Chakravaham",
                "Thalam": "Khanda Laghu",
                "Praise of": "Murugan",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5858,
                "Name": "theLLu thamizhukudhavu",
                "Type": "Kavadi Chindu",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Nagar vaLam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5859,
                "Name": "chennai kuLa nagar",
                "Type": "Kavadi Chindu",
                "Ragam": "HariKhambhoji",
                "Thalam": "Tisra Laghu",
                "Praise of": "Kovil kuLam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5860,
                "Name": "ponnulavu chenni kuLa",
                "Type": "Kavadi Chindu",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Mishra Chapu",
                "Praise of": "malai vaLam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5861,
                "Name": "puLLi kalaabha mayil",
                "Type": "Kavadi Chindu",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "vaavi vaLam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5862,
                "Name": "maragatha vikachitha",
                "Type": "Kavadi Chindu",
                "Ragam": "HariKhambhoji",
                "Thalam": "Ekam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5863,
                "Name": "paadhi raathiri vELaiyil (NatRaai irangal)",
                "Type": "Kavadi Chindu",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5864,
                "Name": "aarumuga vaDivElavane (thalaiviyin ooDal)",
                "Type": "Kavadi Chindu",
                "Ragam": "Natabhairavi",
                "Thalam": "Tisra Laghu",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5865,
                "Name": "kaNNaayiram paDaitha",
                "Type": "Kavadi Chindu",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5866,
                "Name": "bhoomi mechchidum",
                "Type": "Kavadi Chindu",
                "Ragam": "HariKhambhoji",
                "Thalam": "Tisra Laghu",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5867,
                "Name": "chandhavarai vandha guha naathaa",
                "Type": "Kavadi Chindu",
                "Ragam": "HariKhambhoji",
                "Thalam": "Khanda Laghu",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5868,
                "Name": "vanna thinai maavai ",
                "Type": "Kavadi Chindu",
                "Ragam": "HariKhambhoji",
                "Thalam": "Tisra Laghu",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5869,
                "Name": "chendhil maanagaram thanil",
                "Type": "Kavadi Chindu",
                "Ragam": "Chenchurutti",
                "Thalam": "Mishra Chapu",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5870,
                "Name": "paaLaivaai kamugil",
                "Type": "Kavadi Chindu",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5871,
                "Name": "ennaDi naan petra mangai",
                "Type": "Kavadi Chindu",
                "Ragam": "HariKhambhoji",
                "Thalam": "Tisra Laghu",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5872,
                "Name": "manju nigar kundhaLa minnE",
                "Type": "Kavadi Chindu",
                "Ragam": "HariKhambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5873,
                "Name": "chendhoor vaLar muruga naathaa",
                "Type": "Kavadi Chindu",
                "Ragam": "HariKhambhoji",
                "Thalam": "Chatusra Ekam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5874,
                "Name": "bhavaNak kiriyathanuL",
                "Type": "Kavadi Chindu",
                "Ragam": "HariKhambhoji",
                "Thalam": "Ekam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5875,
                "Name": "chedhil maa nagar vaazh",
                "Type": "Kavadi Chindu",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5876,
                "Name": "kannal soozh pazhanampuDai",
                "Type": "Kavadi Chindu",
                "Ragam": "Chenchurutti",
                "Thalam": "Mishra Chapu",
                "Composer": "Chinnikulam Annamalai Reddiyar",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5877,
                "Name": "villinai oththa puruvam",
                "Type": "Kavadi Chindu",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Tisra Laghu",
                "Composer": "Mahakavi Subramanya Bharathi",
                "Song Language": "Tamil",
                "BookTitle": "Kavadi Chindu",
                "BookAuthor": "Chennikulam Annamalai Reddiyar",
                "Year of Publication": 1966,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5878,
                "Name": "BhajarE gopaalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5879,
                "Name": "smara vaaram vaaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5880,
                "Name": "broohi mukundheti",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurunji",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5881,
                "Name": "Maansa sancharare",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5882,
                "Name": "Gaayathri vanamaali",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mishra Kaapi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5883,
                "Name": "kreeTathi vanamaali",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5884,
                "Name": "Bhajare yadhu naadham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pilu",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5885,
                "Name": "bhajarE raghu veeram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Praise of": "Rama",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5886,
                "Name": "prathi vaaram vaaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Praise of": "Rama",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5887,
                "Name": "Chetha Sree Raamam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5888,
                "Name": "Pibare raama rasam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "YamunaKalyani",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5889,
                "Name": "Kelathi mama hrudayE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5890,
                "Name": "kElathi piNDaaNdE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5891,
                "Name": "sthirathaa nahi nahirE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5892,
                "Name": "nahirE nahi rangaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5893,
                "Name": "chindhaa naasthi kila",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaroj",
                "Thalam": "Adi",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5894,
                "Name": "brahmai vaahamkila",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5895,
                "Name": "sarvam brahmamayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5896,
                "Name": "thathvajeevathvam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5897,
                "Name": "poorNa pOdhOham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5898,
                "Name": "ananda poorNa pOdhOham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Khanda Chapu",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5899,
                "Name": "ananda poorNa pOdhOham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5900,
                "Name": "thunga tarange gange",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntala Varali",
                "Thalam": "Adi",
                "Composer": "Sadashiva Brahmendral",
                "Song Language": "Sanskrit",
                "BookTitle": "Sadashiva Brahmendrar Keerthanaigal",
                "BookAuthor": "Sadashiva Brahmendra",
                "Year of Publication": 1979,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5901,
                "Name": "gOvardhana giridhara gOvindA",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5902,
                "Name": "maa mahaabhaaratha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5903,
                "Name": "jaya jaya swaamin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5904,
                "Name": "Sree naaraayaNa vaahana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Roopakam",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5905,
                "Name": "jaya jaya raamanaatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Khanda Chapu",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5906,
                "Name": "matsya koorma varaaha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5907,
                "Name": "raama krishna gOvindhethi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5908,
                "Name": "sharaNam bhava karuNaa mayi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Roopakam",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5909,
                "Name": "naaraayaNa namO maadhavaaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5910,
                "Name": "mangaLaaya maamava dEva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5911,
                "Name": "avadhaaraya maameeshwaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5912,
                "Name": "Sree pathimiha nandha gOpa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5913,
                "Name": "MangaLaani thanOthu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Khanda Chapu",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5914,
                "Name": "maadhava maadhava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Roopakam",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5915,
                "Name": "yEhi mudham dhEhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5916,
                "Name": "krishNam kalaya sakhi sundaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5917,
                "Name": "kalaya yashOdE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5918,
                "Name": "dhAmOdhara thaavaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5919,
                "Name": "harimeeDe Sree harimeeDe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5920,
                "Name": "gOvinda ghataya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda Chapu",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5921,
                "Name": "aalOkayE sree bAlakrishNam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5922,
                "Name": "kalayatha gOpikaaruNyarasapoora",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5923,
                "Name": "pashyatha pashyatha bhagavantham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5924,
                "Name": "parama karuNayaa maam paalaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5925,
                "Name": "paahi paahi jagan mohana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5926,
                "Name": "kalayatha vanabhuvi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5927,
                "Name": "bandhanaan mOsaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Mishra Chapu",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5928,
                "Name": "aayaahi vrajayuvathee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5929,
                "Name": "Sree krishna thaavakamahimaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5930,
                "Name": "indra yaaga samaaramba",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5931,
                "Name": "paramapurusham anuyaama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5932,
                "Name": "jaya jaya gokula baala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5933,
                "Name": "shankE shankaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Mishra Chapu",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5934,
                "Name": "brundaavana madhoonaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5935,
                "Name": "vallavaanganaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5936,
                "Name": "sharaNam bhava krishNa samsarathaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5937,
                "Name": "pooraya mama kaamam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5938,
                "Name": "bhooyO bhooyO vaasEnjjalinaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5939,
                "Name": "vanabhuvi gOvindamiha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Mishra Chapu",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5940,
                "Name": "kalayE dEvamiha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5941,
                "Name": "baalagOpaala krishNa paahi paahi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Mishra Chapu",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5942,
                "Name": "maadhava maamava dEvaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5943,
                "Name": "gOvindamiha gOpikaananda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhageshri",
                "Thalam": "Khanda Chapu",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5944,
                "Name": "dhruvamanDala paNDitha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5945,
                "Name": "kalayatha suravanithaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5946,
                "Name": "bhaavayE sakkhi bhaavayE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahiri",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5947,
                "Name": "sree nilayam sakkhi sree",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5948,
                "Name": "mrugayatha raadhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5949,
                "Name": "sahachari samarasamiha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5950,
                "Name": "kadhaya kadhaya maadhavam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5951,
                "Name": "paahi paahi maam parama krupaaLO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5952,
                "Name": "vikshEham kadhaa gOpaala moorthim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahiri",
                "Thalam": "Mishra Chapu",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5953,
                "Name": "naadha naadha maa kalaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5954,
                "Name": "aalOkayE rukmiNi kalyaaNa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5955,
                "Name": "Jaya mangalam nithya Shubha mangalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Khanda Chapu",
                "Composer": "Narayana Theerthar/Music: Seetha Rajan",
                "Group": "Krishna Leela Tarangini",
                "Song Language": "Sanskrit",
                "BookTitle": "Krishna Leela Tarangini",
                "BookAuthor": "Narayana Theerthar",
                "Year of Publication": 1996,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5956,
                "Name": "nee indha maayam seidhaal",
                "Type": "Varnam-Pada",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5957,
                "Name": "swaami nee manamirangi",
                "Type": "Varnam-Pada",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5958,
                "Name": "swaami unnai sadaa",
                "Type": "Varnam-Pada",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5959,
                "Name": "sadaa shiva kumaaraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5960,
                "Name": "moolaadhaara moorthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5961,
                "Name": "gajavadanaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5962,
                "Name": "sree ganesha sharanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tilang",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5963,
                "Name": "kai koDuthaanaiyyaa gathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5964,
                "Name": "aruL puri ainkarane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5965,
                "Name": "tharuNamidhaiyyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShivaRanjani",
                "Thalam": "Roopakam",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5966,
                "Name": "thaNigai vaLar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Khanda Chapu",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5967,
                "Name": "jaya jaya guhaa sharanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5968,
                "Name": "muruhara muzhumadhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5969,
                "Name": "ulaga vaazhvilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5970,
                "Name": "pazhaniyappa nin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5971,
                "Name": "Maal Marugaa Shanmugaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5972,
                "Name": "vandharuLvaai murugaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5973,
                "Name": "sharavaNa bhava guhanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5974,
                "Name": "SharavaNa bhava ennum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5975,
                "Name": "maadhayai nidhiyenum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5976,
                "Name": "chitham irangaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Mishra Chapu",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5977,
                "Name": "Sree valli devasenapathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natabhairavi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5978,
                "Name": "kandhaa vandharuL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5979,
                "Name": "kadhir kaama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Tisra Triputa",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5980,
                "Name": "nekkurugi unnai paNiyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5981,
                "Name": "sOdhanai sumaikkivvELai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5982,
                "Name": "murganai bhaji manamE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jonpuri",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5983,
                "Name": "sendhil vaLar sEvalanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi (Tisra Gathi)",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5984,
                "Name": "konjam dhayai puriya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5985,
                "Name": "maalai sooTuvEn vElavaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Keeravani, Shankarabharanam, Varali, Bilahari, KedaraGowlai)",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5986,
                "Name": "aDithaalum unai viTTu aDimai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5987,
                "Name": "shivakaama sundari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5988,
                "Name": "karuNaa nidhiyE thaayE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Tisra Triputa",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5989,
                "Name": "paadha malar thuNaiyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Mishra Chapu",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5990,
                "Name": "aananda bhairavi annai un thiruvaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5991,
                "Name": "bhuvanEshwari paadam ninaindhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5992,
                "Name": "bhuvaneshwari pugazh unadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Deshadi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5993,
                "Name": "mangaLa naayaki maathaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5994,
                "Name": "uLLam irnaguvadhen un kaDanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5995,
                "Name": "Devi neeyE thuNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5996,
                "Name": "paraashakthi janani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5997,
                "Name": "dharmaambhikE thanjam neeyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Deshadi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5998,
                "Name": "thiruvaruL thara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 5999,
                "Name": "amba unaiyE nambinEn",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Deshadi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6000,
                "Name": "ambaa manamkanindhu unadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6001,
                "Name": "unai dhinam paNindhadum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6002,
                "Name": "amale amarargaL paNiyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntala Varali",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6003,
                "Name": "Maathaa thuNai purivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6004,
                "Name": "thaaye neeye thanjam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6005,
                "Name": "shankari dhayaa kari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6006,
                "Name": "Chaamundeshwari sankari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6007,
                "Name": "thaayE bhairaviyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6008,
                "Name": "sree jagadambhikaiyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Roopakam",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6009,
                "Name": "paradevathe padam nambinen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Deshadi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6010,
                "Name": "mahishaasura mardhini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6011,
                "Name": "thiruvaruL thandharuL vaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6012,
                "Name": "un perumaiyai yevar arivaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6013,
                "Name": "aaDum deivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6014,
                "Name": "sundarEshwaranE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6015,
                "Name": "mayilaapuriyil vandha mahaadEvan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Mishra Chapu",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6016,
                "Name": "nambikeTTavar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6017,
                "Name": "sarveshaa aDimaiyai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6018,
                "Name": "umaiyOr paaghanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6019,
                "Name": "pollaa puliyinum pollaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Madhyadi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6020,
                "Name": "nataraajO vijayathE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6021,
                "Name": "nataraajan un thirunaTam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6022,
                "Name": "aiyaa nin aDaikalamE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6023,
                "Name": "nin aruL iyambalaagumO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6024,
                "Name": "sakala bhuvana naayakaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6025,
                "Name": "iDadhu padam thUki aaDum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6026,
                "Name": "shivaaya namavendrE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntala Varali",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6027,
                "Name": "gnaana sabhaiyil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Mishra Chapu",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6028,
                "Name": "kunchithapaadham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6029,
                "Name": "shiva perumaan krupai vEnDum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6030,
                "Name": "Paarvathi naayakane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6031,
                "Name": "unai allaal gathiyaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balahamsa",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6032,
                "Name": "Piravaa varam thaarum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6033,
                "Name": "Deva devanai thruvaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6034,
                "Name": "paradeivam unaiandRi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6035,
                "Name": "thaayumaanavanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6036,
                "Name": "naadhan thaaL thuNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hameer Kalyani",
                "Thalam": "Tisra Ekam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6037,
                "Name": "shree mahaadeva naama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6038,
                "Name": "kaaNa vEndDaamO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6039,
                "Name": "aaNDavanai nambu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6040,
                "Name": "adhbhutha leelaigaLai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6041,
                "Name": "sundarEshwaraaneeyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6042,
                "Name": "kaal maariyaadum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Deshadi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6043,
                "Name": "karuNai puri kapaali",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6044,
                "Name": "padhari manamayarndhu varundhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6045,
                "Name": "chidambara naathaa thiruvaruL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6046,
                "Name": "garbha vaasam ariyaadha kaDavulE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6047,
                "Name": "pichchaikku vandheerO",
                "Type": "Padam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6048,
                "Name": "natana sabhaapathiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6049,
                "Name": "aananda natamidum paadhan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6050,
                "Name": "aanandhame paramaanandhame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6051,
                "Name": "gowri manOharaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6052,
                "Name": "thiruvaDi paNiyum anbar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6053,
                "Name": "pathi avanE ulagam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6054,
                "Name": "kanaka sabhayai kaNDa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindustani Kapi",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6055,
                "Name": "Thillana-Shanmugapriya",
                "Type": "Thillana",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6056,
                "Name": "mangaLam aruL sree gajamukhanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "Group": "Mangalam-Mylapore",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Malai-Part2",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 1965,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6057,
                "Name": "aabheera palla",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6058,
                "Name": "aaDaadhu asangaadhu vaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6059,
                "Name": "adhaTTi uruTTi vazhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6060,
                "Name": "adhbhuthanaam baalakanaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6061,
                "Name": "aaDi asaimdhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6062,
                "Name": "aaDi asaimdhu varugindraan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6063,
                "Name": "aDi muDi kaaNaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6064,
                "Name": "aaDinamaaTTukkum nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6065,
                "Name": "aaDinaan maadhavan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6066,
                "Name": "aaDinaan narthanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6067,
                "Name": "aaDinaan viLayaaDinaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6068,
                "Name": "aadhiyoru kaalamadhil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Khanda Ekam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6069,
                "Name": "aaDum varai avar aaDattum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6070,
                "Name": "aagama raajagopaala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Khanda Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6071,
                "Name": "agaNitha mahimaadbhutha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "SaptaRatna Krithis-2",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6072,
                "Name": "aiyan allavO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6073,
                "Name": "aiyan karuNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6074,
                "Name": "aiyan oorvalam vandhaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6075,
                "Name": "aakka poruthavarkku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6076,
                "Name": "alai paayudhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6077,
                "Name": "alaivaaipazham udhirum sOlai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6078,
                "Name": "alankaara gOpikai thaaLam pODa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6079,
                "Name": "aaLaavadhennaaLo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "SaptaRatna Krithis-6",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6080,
                "Name": "allikENikkarai thEDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6081,
                "Name": "aLLudhE manam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6082,
                "Name": "amudhanukku amudhooTTum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hameer Kalyani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6083,
                "Name": "aanamaTTum sonnEn",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6084,
                "Name": "aananda narthana ganapathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6085,
                "Name": "andhinEram nadhikkaraiyOram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6086,
                "Name": "anjanaanandham bhodhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Anjaneya SaptaRathna Krithis-4",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6087,
                "Name": "annaikkezhudhinaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6088,
                "Name": "annaiyarhaL vanam naaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6089,
                "Name": "annamE idhu enna maayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6090,
                "Name": "anRE naan sonnadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6091,
                "Name": "antharam maNandha malar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6092,
                "Name": "anuraagaja aalaapa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Khanda Jathi Matya Thalam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6093,
                "Name": "aparam na jaanaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Khanda Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6094,
                "Name": "aridhu aridhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6095,
                "Name": "arivEn arindhEn",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Tisra Triputa",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6096,
                "Name": "arooDam ondru sollaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6097,
                "Name": "aruLaLan thiramellaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6098,
                "Name": "asaindhaDum mayil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6099,
                "Name": "aasayinai paaraDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6100,
                "Name": "athi nirupama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6101,
                "Name": "avaraaha varuvaarO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6102,
                "Name": "avathaaram aanaan raaghavan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6103,
                "Name": "avathaaram shree krishNa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6104,
                "Name": "aayiram aayiram paayiram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6105,
                "Name": "aayiram sollaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6106,
                "Name": "aayiramthaan sollaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6107,
                "Name": "azhagaai dwaarakayai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6108,
                "Name": "aazhaazhi neela",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6109,
                "Name": "baala sarasa muraLi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "SaptaRatna Krithis-4",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6110,
                "Name": "bhajanaamruta paramaananda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "SaptaRatna Krithis-1",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6111,
                "Name": "bhajasva shree tripurasundari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Kamakshi Navaavarnam-2",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6112,
                "Name": "bhaktha bhaagadhEya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Anjaneya SaptaRathna Krithis-7",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6113,
                "Name": "bhakthar perumaiyE perumai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6114,
                "Name": "bhakthi paNNadhirukkalaamO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6115,
                "Name": "Bhakthi yOga sangeetha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6116,
                "Name": "bheema nadhikkarayil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6117,
                "Name": "bhuvanamOha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6118,
                "Name": "brundaavana nilayE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6119,
                "Name": "brundaavanam enbadhivayyO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurunji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6120,
                "Name": "chaamarOpachaaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6121,
                "Name": "chamathu kuTTiyaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6122,
                "Name": "chindhithavar nenjil iruppadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6123,
                "Name": "chOra chaathurya leela",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6124,
                "Name": "dhaamOdhara vaibhavam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6125,
                "Name": "dhanta thaavanam seiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6126,
                "Name": "darisanam thandhE aruLinaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6127,
                "Name": "daasharathE dayaanidhE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6128,
                "Name": "dEvi vanam vandhaaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6129,
                "Name": "dhyaanamE parama paavanamE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "RasaManjari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6130,
                "Name": "dwaarakai enbadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6131,
                "Name": "yedhaDi yedhaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6132,
                "Name": "edhir koNDazhaithu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6133,
                "Name": "yekadantha vinaayakam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Khanda Triputa",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6134,
                "Name": "yellai illaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6135,
                "Name": "ellaam gurunaathan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6136,
                "Name": "yEn ingE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6137,
                "Name": "yenakkum aruLineerO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6138,
                "Name": "yenda samarththai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6139,
                "Name": "yendha vidhamaahilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6140,
                "Name": "yendha vidhathaalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6141,
                "Name": "yendhapaDi aanaalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6142,
                "Name": "yengE yengEyaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6143,
                "Name": "yengum illaadha piLLai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6144,
                "Name": "yengum illaadhoru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6145,
                "Name": "yengum iyaRkkai maNam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika ",
                "Thalam": "Ekam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6146,
                "Name": "yenna paaraamukham amma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Tisra triputa",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6147,
                "Name": "yenna puNNiyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6148,
                "Name": "ennadhaan inbam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "DevaGandhari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6149,
                "Name": "ennadhaan kobam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6150,
                "Name": "ennadhaan sollaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6151,
                "Name": "ennadhaan sonnaalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6152,
                "Name": "ennadhaan sonnEn",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6153,
                "Name": "ennathai sollaDi pODi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6154,
                "Name": "ennaththai sonnaalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Tisra Triputa",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6155,
                "Name": "eNNippaaraDi kaNNan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6156,
                "Name": "yenO indha nishivELai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6157,
                "Name": "eppaDiththaan en uLLam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6158,
                "Name": "eTTa irundhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6159,
                "Name": "eththaikaNDu nee meenaakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6160,
                "Name": "eththanaikETTaalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6161,
                "Name": "gajamukha anujam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6162,
                "Name": "gamana sundara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6163,
                "Name": "gambheera naDaiyODu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6164,
                "Name": "gaanam krishNa gaanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6165,
                "Name": "gaNanaayakan thuNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6166,
                "Name": "gaNayaami satatham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Khanda Triputa",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6167,
                "Name": "gathi neerE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6168,
                "Name": "geetha rasikE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6169,
                "Name": "gOkula maamaNiyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6170,
                "Name": "gOkulatheruvinilE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6171,
                "Name": "gOpa kumaara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6172,
                "Name": "gOvinda gOvinda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6173,
                "Name": "gowri naatham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6174,
                "Name": "guru paadaravinda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6175,
                "Name": "haladharaanujam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Kamakshi Navaavarnam-Phala Sthuthi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6176,
                "Name": "idhu oru thiramaamO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6177,
                "Name": "idhudhaan theriyumO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6178,
                "Name": "idhuvE ennakku laksham kOTi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6179,
                "Name": "idhuvE phalan aamODi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6180,
                "Name": "iLa mukham kaaTi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6181,
                "Name": "illai illai illaiyammaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6182,
                "Name": "indha vazhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6183,
                "Name": "innamudhu anna un",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6184,
                "Name": "innum enna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6185,
                "Name": "innum mayakkudhu swaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6186,
                "Name": "innum ninaiththaalum kaNNan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6187,
                "Name": "innum varkkaaNenE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6188,
                "Name": "ippaDiyum oru piLLai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Khanda Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6189,
                "Name": "ippaDiyum oru deivamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6190,
                "Name": "iththanai parivu ennaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6191,
                "Name": "iththanaiyum pOi urakkam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6192,
                "Name": "ivandhaan ivandhaanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6193,
                "Name": "jagamengum pughazhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6194,
                "Name": "jaganmOhana sundaraanga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6195,
                "Name": "jagathu raama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6196,
                "Name": "janani tripura Sundari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kannadaa",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6197,
                "Name": "jaTaadhara shankara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "SaptaRatna Krithis-5",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6198,
                "Name": "kaDaindhaalE thayir",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6199,
                "Name": "kaadhalaahi vasamizhandhEn",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6200,
                "Name": "kadhirEgum madhiyaagum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ahiri",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6201,
                "Name": "kai varisaikaaTTaadheer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6202,
                "Name": "kaalinga narthana naTaangam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GambiraNaatai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6203,
                "Name": "kaLLamE ariyaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amrithavarshini",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6204,
                "Name": "kalyaaNa raama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanaadham",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6205,
                "Name": "kaamini kELi lOla",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6206,
                "Name": "kaaNaadha adhishaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6207,
                "Name": "kanaka sabhaa naayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6208,
                "Name": "kaaNakkiDaithadhE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6209,
                "Name": "kaNDum kaaNaadhadhu pOl",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ragamalika",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6210,
                "Name": "kaaNeerE kanjamalar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6211,
                "Name": "kaNgaLum pOdhaadhE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6212,
                "Name": "kanjikku eeDaagumaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6213,
                "Name": "kaNkaNDa deivamE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6214,
                "Name": "kaNNaa yezhundhirum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6215,
                "Name": "kaNNaithirandhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6216,
                "Name": "kaNNallavO swaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6217,
                "Name": "kaNNan piRandhadai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6218,
                "Name": "kaNNan varuhinRa nEram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kavadi Sindhu",
                "Thalam": "Tisra Gathi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6219,
                "Name": "kaNNanO kaNNan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6220,
                "Name": "kaNNaazhi alai parava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Khanda Ekam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6221,
                "Name": "kathaya kathaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6222,
                "Name": "kaayaambhoo vaNNarE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6223,
                "Name": "kiDaikkumO kiDaikkaadhO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6224,
                "Name": "koDukka kaNNan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6225,
                "Name": "koDuththu vaiththa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6226,
                "Name": "koLLaadha aasai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6227,
                "Name": "konjum mazhalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6228,
                "Name": "kOTi janmaani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6229,
                "Name": "kshaNamEva gaNya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6230,
                "Name": "kshaNathinil aahaadhO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6231,
                "Name": "kungiliyam vaangaliyO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "sama",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6232,
                "Name": "kunRin meliTTa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6233,
                "Name": "kuzhalE nee enna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6234,
                "Name": "kuzhaloodhi manamellaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6235,
                "Name": "laali raajagopaala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6236,
                "Name": "lOla gOpabaala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6237,
                "Name": "madhananga mOhana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6238,
                "Name": "maadhava Hrdi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "SaptaRatna Krithis-3",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6239,
                "Name": "maadhavanai munn niruththi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Khanda Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6240,
                "Name": "Madhura Madhura",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6241,
                "Name": "mahaashaya hrudaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6242,
                "Name": "malaiyai thookinaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6243,
                "Name": "manadharindhO allaadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6244,
                "Name": "manadhukkiniya gOpaala charithram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6245,
                "Name": "manamE arivODum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6246,
                "Name": "mandhaakini karai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6247,
                "Name": "maanE avarthaanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6248,
                "Name": "maNi noopura dhaari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6249,
                "Name": "manjanamaaDa nee vaaraai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6250,
                "Name": "manRuLaaDi mahizhndE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6251,
                "Name": "manRuLaaDum paraman",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6252,
                "Name": "marakatha maNimaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6253,
                "Name": "mathuraapuri nagaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6254,
                "Name": "mithilaadhipa suthaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6255,
                "Name": "mumadha vEzha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6256,
                "Name": "mun seidha thavapayanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6257,
                "Name": "mundhi varum isaiyil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6258,
                "Name": "muththukrishNa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6259,
                "Name": "naadha muraLi gaana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hameer Kalyani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6260,
                "Name": "naDa naDa naDa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6261,
                "Name": "naDayai paaraDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6262,
                "Name": "Thillana-SindhuBhairavi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6263,
                "Name": "naaga naTanamaaDum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6264,
                "Name": "nalladhalla endRu sollaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6265,
                "Name": "nallathanamaai solli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6266,
                "Name": "naama sukha suthaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SiddhasEna",
                "Thalam": "Ekam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6267,
                "Name": "naan enna thavam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6268,
                "Name": "nanda gOpaala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6269,
                "Name": "nanda sukumaara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbaar",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6270,
                "Name": "nanRaaha iru thaayE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6271,
                "Name": "nathajana kalpavallee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Kamakshi Navaavarnam-9",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6272,
                "Name": "naTavara tharuNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KannadaGowlai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6273,
                "Name": "nee seidha upakaaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "UmaBharanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6274,
                "Name": "needhaan mechchi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6275,
                "Name": "neela lOhitha ramaNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balahamsa",
                "Thalam": "Khanda Dhuruvam",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Kamakshi Navaavarnam-5",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6276,
                "Name": "neela malar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6277,
                "Name": "neela vaanam thanil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6278,
                "Name": "neela vaNNa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6279,
                "Name": "neelavaanam pOllilangi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maanji",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6280,
                "Name": "neeradha sama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayanthashri",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6281,
                "Name": "neeyE kaNkoNDu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6282,
                "Name": "nEram konjamum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6283,
                "Name": "nillaamal pOhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "UdayaRavichandrika",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6284,
                "Name": "ninRa nilaikaNDu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6285,
                "Name": "ninRaan thanai pOi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6286,
                "Name": "ninRandha mayilondRu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Khanda Ekam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6287,
                "Name": "nindRingunnaruL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6288,
                "Name": "niravadhikka bhuvana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6289,
                "Name": "Om namO bhagavathE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6290,
                "Name": "ooraan oruvan kuzhlODum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6291,
                "Name": "oyyaarakkaNNan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maalavam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6292,
                "Name": "oyyaaramaaha oyyaaramaaha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6293,
                "Name": "pachchai iLam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6294,
                "Name": "paada sEvana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Deeparam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6295,
                "Name": "padmaavathi ramaNam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6296,
                "Name": "padmini vallabha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6297,
                "Name": "paal vaDiyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6298,
                "Name": "paanDuranga viTTala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ChayaTarangini",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6299,
                "Name": "paravaasudEvam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6300,
                "Name": "paarthasaarathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6301,
                "Name": "paarvai ondRE pOdhumE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6302,
                "Name": "pathitha paavanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6303,
                "Name": "paTTaabhishekam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6304,
                "Name": "pavana kumaara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Anjaneya SaptaRathna Krithis-1",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6305,
                "Name": "pazhamO pazhamO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6306,
                "Name": "pesaadhE pOngaLaaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6307,
                "Name": "pesakELeerO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6308,
                "Name": "pODi pODi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6309,
                "Name": "pOdhum pOdhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6310,
                "Name": "pOhaviTTu puram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Tisra Triputa",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6311,
                "Name": "pollaadha piLLai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6312,
                "Name": "pooNool kalyaaNam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6313,
                "Name": "poruLaai iruppEnO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6314,
                "Name": "pOTTu vaiyEDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6315,
                "Name": "praNavaakaaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6316,
                "Name": "praNavOpadesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kavadi Sindhu",
                "Thalam": "Tisra Gathi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6317,
                "Name": "prasanna gOpaala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dwijaavanthi",
                "Thalam": "Mishra Ata",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6318,
                "Name": "prEmaswaroopa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6319,
                "Name": "pullaai piravi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6320,
                "Name": "puthra kaamayaagam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6321,
                "Name": "raadhE krishNam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6322,
                "Name": "raadhikaa praaNa naayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntala Varali",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6323,
                "Name": "raaga rasaananda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamsaKeeravani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6324,
                "Name": "raghukulOththama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6325,
                "Name": "raaja raaja gOpaala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maanji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6326,
                "Name": "raaja raaja gOpaala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Khanda Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6327,
                "Name": "Raaja Raajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6328,
                "Name": "raajaadhi raaja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6329,
                "Name": "raajeeva nayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbaar",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6330,
                "Name": "raaman perumai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6331,
                "Name": "ramaNeeya yamunaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6332,
                "Name": "raamaayaNamE dhanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6333,
                "Name": "ranganaatham anisham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GambiraNaatai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6334,
                "Name": "raasa kEli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6335,
                "Name": "raasa maNDalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6336,
                "Name": "raasalOkha vaibhOga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6337,
                "Name": "rasika mahOththama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6338,
                "Name": "rukmiNi kalyaaNa mangaLam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6339,
                "Name": "sadaanandamayee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "sankeerana Matyam",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Kamakshi Navaavarnam-6",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6340,
                "Name": "sakala lokha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Kamakshi Navaavarnam-7",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6341,
                "Name": "saamam mudhal vEdhangaLai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurunji",
                "Thalam": "Khanda Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6342,
                "Name": "santhatham aham sEvE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desaakshi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Kamakshi Navaavarnam-1",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6343,
                "Name": "sarasa guNa ratna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6344,
                "Name": "sarasijabhava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6345,
                "Name": "sarva jeeva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Kamakshi Navaavarnam-3",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6346,
                "Name": "sathvaguNa virachithaanga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "RasaManjari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Anjaneya SaptaRathna Krithis-5",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6347,
                "Name": "sathyam param",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6348,
                "Name": "sEnaapathE namOusthuthE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6349,
                "Name": "Shankari Shree",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Kamakshi Navaavarnam-8",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6350,
                "Name": "sharaNam gOpa baalaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6351,
                "Name": "sharaNam yaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6352,
                "Name": "shathakOTi manmathaakaara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6353,
                "Name": "sEdhi ellaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6354,
                "Name": "seidha thavam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6355,
                "Name": "sendRa samaththu pOl",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6356,
                "Name": "sendRu vaa nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6357,
                "Name": "shenShiva jaTaadhara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6358,
                "Name": "solla kiDaiththadhE bhaagyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6359,
                "Name": "solla varam tharuvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6360,
                "Name": "sollavallEnO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6361,
                "Name": "solli kaLLippOm",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntala Varali",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6362,
                "Name": "solli koDuththadhaarO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6363,
                "Name": "solli muDiyumaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6364,
                "Name": "sollinilE varum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6365,
                "Name": "sollithaan tharavENumO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6366,
                "Name": "sollitherivadillayE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6367,
                "Name": "sonnadhaikkEL ayyanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6368,
                "Name": "sonnadhaikkEL kaNNaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6369,
                "Name": "sonnaal mangaLam tharumE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6370,
                "Name": "sonnaalozhiya manam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6371,
                "Name": "Shree Chakra maathangi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6372,
                "Name": "shree ganeshwara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Kamakshi Navaavarnam-Ganesha Kruthi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6373,
                "Name": "shree gOvinda naama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6374,
                "Name": "shree raaghava dhootham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Anjaneya SaptaRathna Krithis-2",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6375,
                "Name": "shree raama jayamE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6376,
                "Name": "shree shiva naayikE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "LalithaGandharvam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6377,
                "Name": "shree Vigna rajam bhajE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GambiraNaatai",
                "Thalam": "Khanda Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6378,
                "Name": "Shreenivaasa chinthayeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6379,
                "Name": "shuzhandRu suzhandRaaDum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6380,
                "Name": "sogasukaara krishNam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6381,
                "Name": "sundara nandakumaara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "SaptaRatna Krithis-7",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6382,
                "Name": "sundara naTaraajam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6383,
                "Name": "sundareshwaraya jayE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kannadaa",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6384,
                "Name": "swaagatham Krishnaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6385,
                "Name": "swaagatham kushalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6386,
                "Name": "swaami showriraaja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6387,
                "Name": "swaami umakku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6388,
                "Name": "Thillana-puRaneermai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "puraneermai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6389,
                "Name": "Thillana-Surutti",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6390,
                "Name": "thanakku niharillaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6391,
                "Name": "thaNDavam aaDuhiraanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6392,
                "Name": "thannanthaniyaaha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6393,
                "Name": "tharuNa maamuhil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6394,
                "Name": "thavamondRum ariyaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6395,
                "Name": "thaayE un dhaamOdhara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6396,
                "Name": "thayE yashodaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6397,
                "Name": "thEDariya selvamE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6398,
                "Name": "thEDi aruLa vandhaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6399,
                "Name": "thEDikkaNDEnE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6400,
                "Name": "thEjOmaya raadhikaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Khanda Triputa",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6401,
                "Name": "thEril vandhaanO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6402,
                "Name": "therODum veedhiyilE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6403,
                "Name": "thiruvaDi malarO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6404,
                "Name": "thONuvadillaiyaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Khanda Triputa",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6405,
                "Name": "too too too too",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6406,
                "Name": "thuDukkuthanam ellaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6407,
                "Name": "thuLLi viLaiyaaDinaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kannadaa",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6408,
                "Name": "thyaagaraaja paramEsha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6409,
                "Name": "udhaja gOpa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "UmaBharanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6410,
                "Name": "umaa maheshwaraathmajam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "UmaBharanam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6411,
                "Name": "unnilum enakkoru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6412,
                "Name": "uruhaadha manam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6413,
                "Name": "uyndhadhu uyndhaden",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6414,
                "Name": "vaachakamum maRandhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6415,
                "Name": "vaaDi thOzhi annamE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Khanda Ata",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6416,
                "Name": "vaahiNi naayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Anjaneya SaptaRathna Krithis-6",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6417,
                "Name": "vaiyam aLandhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6418,
                "Name": "vallaree samaanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malavi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6419,
                "Name": "vanamaali swaagatham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6420,
                "Name": "vaanchhasi yadi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Kamakshi Navaavarnam-Dhyana Kruthi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6421,
                "Name": "vandhadhuvum pOnadhuvum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Khanda Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6422,
                "Name": "vandhapOdhum varaadhapOdhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6423,
                "Name": "vandhaar kuru puNya bhoomi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6424,
                "Name": "vandhaarODi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6425,
                "Name": "vandhavarai vaarum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6426,
                "Name": "vandhE aruLa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6427,
                "Name": "vandhE kavilOkha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6428,
                "Name": "vandhE nanda soonum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KannaDamaaruvam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6429,
                "Name": "vandhE pirandhaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6430,
                "Name": "vandhE pirandhaan kaNNan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6431,
                "Name": "vandhE vaalmiki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6432,
                "Name": "vandhu kETpaar illaiyO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6433,
                "Name": "vandhu nindRaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6434,
                "Name": "vaangum enakku irukkai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6435,
                "Name": "vaNNam thigazh",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6436,
                "Name": "varamondRu thandharuLvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6437,
                "Name": "vaari vihaari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntala Varali",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6438,
                "Name": "varisai koNarndhu vandaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6439,
                "Name": "varNikka thiranillayE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naayaki",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6440,
                "Name": "vassudEvaaya namO namasthE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6441,
                "Name": "veekshitOham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Anjaneya SaptaRathna Krithis-3",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6442,
                "Name": "veLLi nilavinilE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6443,
                "Name": "vENu gaana ramaNa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6444,
                "Name": "vEREdhum vahai illayO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6445,
                "Name": "viDindhadhu pODi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6446,
                "Name": "vijayasE vijayasakhE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6447,
                "Name": "vijayathE gOvinda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6448,
                "Name": "virudhondRu aahudhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6449,
                "Name": "vishamakaara kaNNan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Chatusra Ekam",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6450,
                "Name": "vishathi vishathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6451,
                "Name": "viTa samavara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6452,
                "Name": "viththaaram pEsi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6453,
                "Name": "yamunaa thaTa vilaasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "kunthaLashreekaNTi",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6454,
                "Name": "yaamuneeya theera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Khanda Triputa",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6455,
                "Name": "yarenna sonnaalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6456,
                "Name": "yaarum iyalaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Khanda Chapu",
                "Composer": "Oothukaadu Venkata Kavi",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6457,
                "Name": "yOga yOgEshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Khanda Triputa",
                "Composer": "Oothukaadu Venkata Kavi",
                "Group": "Kamakshi Navaavarnam-4",
                "BookTitle": "Oothukadu VenkataKavi Compositions",
                "BookAuthor": "Oothukadu Venkatasubbiyer",
                "Year of Publication": "Not available",
                "Book Print Language": "Not available"
              },
              {
                "Serial #": 6458,
                "Name": "animaa mayil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6459,
                "Name": "kalaimagaLe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6460,
                "Name": "saarathiyaai varuvaayE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "VandhanaDhaarini",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6461,
                "Name": "karaiyEra vENDaamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "RasikaRanjani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6462,
                "Name": "vEleDuththa kaiyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6463,
                "Name": "iniyEdhu kavalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GambiraNaatai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6464,
                "Name": "arangaaa aravaNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6465,
                "Name": "aaruyirgeLukkellaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6466,
                "Name": "sathya sEvaiyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BhogaVasantham",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6467,
                "Name": "pazham nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Triputa",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6468,
                "Name": "suDar vaDi vElaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6469,
                "Name": "kaalakaalan kayilai naadhan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6470,
                "Name": "kaDaikaNNilaagilum paaraai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6471,
                "Name": "konji konji vaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6472,
                "Name": "yaaro avan yaaro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6473,
                "Name": "vElanai ninaithirundhaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6474,
                "Name": "kanindhuLamurugi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6475,
                "Name": "indha varamaruLa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6476,
                "Name": "endraniDar kaNDapinnum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6477,
                "Name": "yenindha viLaiyaaTTaiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6478,
                "Name": "Undran thanipperumai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rukmaambhari",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6479,
                "Name": "aaruyir annai nin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6480,
                "Name": "vElaippiDitha un",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6481,
                "Name": "ellaam umadhu seyal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6482,
                "Name": "gurupara guhanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6483,
                "Name": "kaNNaa karuneela mugil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6484,
                "Name": "unnai marandhu naan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6485,
                "Name": "yendrO ariyEnE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6486,
                "Name": "un thiru naamam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6487,
                "Name": "yEzhai mEl iththanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6488,
                "Name": "karuthilennaaLum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6489,
                "Name": "sahiyE ini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6490,
                "Name": "veNNei uNNumen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6491,
                "Name": "urangiDuvaai endre",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6492,
                "Name": "virivaanagam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amrithavarshini",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6493,
                "Name": "yEnO yenai",
                "Type": "Javali",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6494,
                "Name": "enna dhaan nee",
                "Type": "Padam",
                "Ragam": "Surutti",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6495,
                "Name": "eppaDi sahippEnaDi",
                "Type": "Padam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6496,
                "Name": "indha vidham eppaDi nee",
                "Type": "Padam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6497,
                "Name": "idhu thaanO",
                "Type": "Padam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6498,
                "Name": "sakala kalaa valli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/Music: Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6499,
                "Name": "BaalasubramaNyanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan/Music: Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6500,
                "Name": "anjaadhE nenjE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Khanda Chapu",
                "Composer": "Periyasamy Turan/Music: Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6501,
                "Name": "naam bhajanai seiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Khanda Triputa",
                "Composer": "Periyasamy Turan/Music: Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6502,
                "Name": "Anbe sivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Music: Chitoor Subramanya Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1949,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6503,
                "Name": "gaNanaathanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6504,
                "Name": "pazham nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Triputa",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6505,
                "Name": "kundRuruva vElvaangi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6506,
                "Name": "kaala kaalan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6507,
                "Name": "murugaa murugaa endraal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6508,
                "Name": "konji konji vaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6509,
                "Name": "veNNei uNNumen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6510,
                "Name": "thaayE tripura sundari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Khanda Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6511,
                "Name": "yeththanai dharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6512,
                "Name": "vEleDuththa kaiyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6513,
                "Name": "aaruyirgeLukkellaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6514,
                "Name": "kazhanigaL soozh thiru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6515,
                "Name": "sathya sEvaiyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BhogaVasantham",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6516,
                "Name": "thaayinai kaanaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6517,
                "Name": "indha varma aruLa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6518,
                "Name": "engE theDuginraai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6519,
                "Name": "karuththil ennaaLum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6520,
                "Name": "unaiyanDri vEroru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6521,
                "Name": "pazhani malai thanil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6522,
                "Name": "gurupara guhanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6523,
                "Name": "yEdhEdhO yeNNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6524,
                "Name": "appaa unnai maravEnE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6525,
                "Name": "enmanam pOlE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbaar",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6526,
                "Name": "saarathiyaai varuvaayE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "VandhanaDhaarini",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6527,
                "Name": "iniyEdhu kavalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GambiraNaatai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6528,
                "Name": "murugaa yenadhaaruyirE",
                "Type": "Varnam-Tana",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6529,
                "Name": "Thillana-UdayaRaviChandrika",
                "Type": "Thillana",
                "Ragam": "UdayaRavichandrika",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6530,
                "Name": "indha veeDundran",
                "Type": "Kriti/Keerthanam",
                "Ragam": "DevaGandhari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6531,
                "Name": "kalaiyinbamE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6532,
                "Name": "pizhai ninaindhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6533,
                "Name": "shanthamillamal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6534,
                "Name": "aaDum perumaanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6535,
                "Name": "kai koDuppaayE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6536,
                "Name": "manamirangaadhadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6537,
                "Name": "endrO nee uNarvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6538,
                "Name": "kailayangiri thanil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Khanda Matyam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6539,
                "Name": "namakkoru kurai yEdhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6540,
                "Name": "enna puNNiyam seidhEnO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "DesiyaRanjani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6541,
                "Name": "appanirukkumpodhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6542,
                "Name": "endrenai naaDiyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vilaasini",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6543,
                "Name": "saamagaanapriyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6544,
                "Name": "yaaro avan yaaro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6545,
                "Name": "paarellaam aruLaalE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6546,
                "Name": "arangaaa aravaNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6547,
                "Name": "kaaNbhadhendrO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pilu",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6548,
                "Name": "sahiyE ini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6549,
                "Name": "urangiDuvaai endre",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6550,
                "Name": "kaNNan kuzhalOsai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6551,
                "Name": "kaNDEn kanavinilE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6552,
                "Name": "thanjamendru vandha yenai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Isai Mani Manjari",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1970,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6553,
                "Name": "ainkaranE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6554,
                "Name": "animaa mayil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6555,
                "Name": "harihara sudhanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6556,
                "Name": "kaDaikkaNNilaagilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6557,
                "Name": "sathiyamE vellum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manohari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6558,
                "Name": "vENu gaana amudhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6559,
                "Name": "sirithu purameriththa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6560,
                "Name": "indroru pudhunaaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayanarayani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6561,
                "Name": "endrO ariyEnE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6562,
                "Name": "Ekaambara naathaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6563,
                "Name": "muruganaDi manamuruga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6564,
                "Name": "vElavaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6565,
                "Name": "karuNaiyin avathaaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6566,
                "Name": "karaiyEra vENDaamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "RasikaRanjani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6567,
                "Name": "veera vaazhvE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6568,
                "Name": "raajaraajEshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6569,
                "Name": "yenakkoru vazhiyumE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6570,
                "Name": "muruganaDi sErndha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6571,
                "Name": "pazhani nindra paraman",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6572,
                "Name": "nenjamE nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6573,
                "Name": "suDar vaDi vElaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6574,
                "Name": "evvuyirukkum anbu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "RagaVinodini",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6575,
                "Name": "sEvaDi malarvaaDa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "RaviChandrika",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6576,
                "Name": "vetriaruLum vElaip patRi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6577,
                "Name": "muruganendru varuvaanO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6578,
                "Name": "sendhil vaLar guhanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6579,
                "Name": "dEva dEva mahaadEva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pushpalathika",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6580,
                "Name": "eNNiDumbhOdhE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6581,
                "Name": "marutheeswaranE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6582,
                "Name": "aanDavanE sharaNam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6583,
                "Name": "vEl muruganai ninaivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6584,
                "Name": "ennenna viLaiyaaDal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6585,
                "Name": "picchai yeDukka vandha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Mishra Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6586,
                "Name": "azhagudeivamaaga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kavadi Sindhu",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6587,
                "Name": "aranumaagi hariyumaagi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kavadi Sindhu",
                "Thalam": "Mishra Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6588,
                "Name": "sirumalarE unai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6589,
                "Name": "paadhaaravindham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6590,
                "Name": "sumaiperidhendrE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kannadaa",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6591,
                "Name": "thillayil aaDum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6592,
                "Name": "aDaikalamenDru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6593,
                "Name": "sendhil vaDivElanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6594,
                "Name": "Sree Venkateshwaraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6595,
                "Name": "endrunaikaaNbhEnO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6596,
                "Name": "koNDal maNivaNNaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6597,
                "Name": "kaliyugavaradhan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Brindavana Saranga",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Murugan Arul Mani Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1972,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6598,
                "Name": "ainkaranillamal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6599,
                "Name": "aadi mandiram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Roopakam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6600,
                "Name": "maamadhurai meenakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Chapu",
                "Praise of": "Madurai Meenakshi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6601,
                "Name": "unaiyanDRi thuNaikaaNEn",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Khanda Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6602,
                "Name": "endru varumO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6603,
                "Name": "puNNiyam orukODi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6604,
                "Name": "thanjam yena vandhenai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6605,
                "Name": "sakala kalaa vaaNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Roopakam",
                "Praise of": "Saraswathi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6606,
                "Name": "sodhanai seidhadhu pOdhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6607,
                "Name": "mangaLa vinaayakanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Chapu",
                "Praise of": "Ganesha",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6608,
                "Name": "aadhi shankarar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Chapu",
                "Praise of": "Adi Sankara",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6609,
                "Name": "vazhikaaTuvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kannadaa",
                "Thalam": "Adi",
                "Praise of": "VaLLalaar",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6610,
                "Name": "hari inbaa naamam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6611,
                "Name": "yezhaimEl ithanai kObam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6612,
                "Name": "muraLi dhara gOpaalaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6613,
                "Name": "paramEshwari ambaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Jhampai",
                "Praise of": "Devi-Parvathi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6614,
                "Name": "pichchai yeDukka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Mishra Chapu",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6615,
                "Name": "annapooraNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Adi",
                "Praise of": "Kanchi Kamakshi Annapoorani",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6616,
                "Name": "yengum niraivaana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Mishra Chapu",
                "Praise of": "Devi-Parvathi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6617,
                "Name": "sollith theriya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Chapu",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6618,
                "Name": "thiruvaDi thozhugindREn",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6619,
                "Name": "oppumai illaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6620,
                "Name": "yenindha viLaiyaaTTaiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6621,
                "Name": "vElum mayilum thuNaiyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6622,
                "Name": "sindhanai seimanamE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6623,
                "Name": "naTanamaaDinaar naadhan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Jhampai",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6624,
                "Name": "karpagavalli ambaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Mylapore Karpagambal",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6625,
                "Name": "BaalasubramaNyanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6626,
                "Name": "naama bhajani sei manamE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Khanda Triputa",
                "Praise of": "Devi-Parvathi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6627,
                "Name": "jaya sree raamakrishNa dEvaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "Ramakrishna Paramahamsa",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6628,
                "Name": "annai shaaradhaa maNi dEvi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Saradambaal",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6629,
                "Name": "yethanai naaL yenguvadhO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6630,
                "Name": "nandha gOpaalanai",
                "Type": "Varnam-Pada",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6631,
                "Name": "innamum umadhu nenjam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6632,
                "Name": "kaathirukkindREn",
                "Type": "Padam",
                "Ragam": "Pushpalathika",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6633,
                "Name": "yenO avarkkenmEl",
                "Type": "Padam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Jhampai",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6634,
                "Name": "ariyaap paruvamadhil",
                "Type": "Padam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6635,
                "Name": "endru nin thErvarum",
                "Type": "Padam",
                "Ragam": "Vilaasini",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6636,
                "Name": "veeN pazhi sumakka vENDaam",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6637,
                "Name": "kanni kazhiyaadha kaalathil",
                "Type": "Javali",
                "Ragam": "Hindolam",
                "Thalam": "Adi (Tisra Gathi)",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6638,
                "Name": "yEnO yenai marandhEnO",
                "Type": "Javali",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6639,
                "Name": "sharavaNabhava mandhiram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Navamani Isai Malai",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1980,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6640,
                "Name": "kalaimagaLe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Praise of": "Saraswathi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6641,
                "Name": "murugan thuNai varuvaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6642,
                "Name": "unnaDimai naanallavO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6643,
                "Name": "tharuNamidhu thaanaiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Jhampai",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6644,
                "Name": "vElavanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Jhampai",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6645,
                "Name": "indha Or udhaviyaagilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6646,
                "Name": "endraniDar kaNDapinnum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Khanda Chapu",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6647,
                "Name": "sharaNam sharaNam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6648,
                "Name": "kaalanai veezhthiya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6649,
                "Name": "ini indha vaazhvil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6650,
                "Name": "yEn padaithai yenakE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadabrahmam",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6651,
                "Name": "anjEl endrE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6652,
                "Name": "idhuvO nin karuNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malahari",
                "Thalam": "Chapu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6653,
                "Name": "kanavilum naan maravEn",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6654,
                "Name": "vaazhvinil thunbam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6655,
                "Name": "eNNuvadhellaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natabhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6656,
                "Name": "manamE unakkEnO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nayaki",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6657,
                "Name": "innamum avar manam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6658,
                "Name": "ulagariyaa endru uvagai paDaadhE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6659,
                "Name": "naanoru siru veeNai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6660,
                "Name": "manadhu kalangaadhE nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6661,
                "Name": "muruganE yenai aaNDaruL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6662,
                "Name": "ariyaamai iruL pOkki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Khanda Chapu",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6663,
                "Name": "kOzhikoDi uyartha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti (KavadiSindhu)",
                "Thalam": "Mishra Chapu",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6664,
                "Name": "engu naan selvEn aiyyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dwijaavanthi",
                "Thalam": "Khanda Chapu",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6665,
                "Name": "enna sugam kaNDaai manamE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Chatusra jathi Matya Thalam",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6666,
                "Name": "un naamam un pEchchu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6667,
                "Name": "naaTkaLai yavamaai naan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6668,
                "Name": "ellamumadhu seyal endraal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6669,
                "Name": "sakala kalaa naayaki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Praise of": "Saraswathi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6670,
                "Name": "vElaippiDitha un kaalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6671,
                "Name": "hari naamam bhajanai seivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Chapu",
                "Praise of": "Vishnu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6672,
                "Name": "aDaikkalam aiyaa naan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GambiraNaatai",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6673,
                "Name": "saravaNa poigaiyilE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6674,
                "Name": "yeththanai naaL thaan yEnguvadhO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6675,
                "Name": "yaarO avar yaarO endhan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6676,
                "Name": "yaariDam murai solluvEn",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6677,
                "Name": "un thiru naamam endranukku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6678,
                "Name": "ini enna kurai yenakkE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhenuka",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6679,
                "Name": "eppaDi sahippEnaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6680,
                "Name": "jagaththinai paDaitha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6681,
                "Name": "enna pizhai enniDam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6682,
                "Name": "aiyan thiruvaDi kaatchi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6683,
                "Name": "vaLLi malai kaaTinilE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pilu",
                "Thalam": "Tisra Nadai",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6684,
                "Name": "unnai marandhu naan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6685,
                "Name": "aaruyir annai nin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Devi-Parvathi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6686,
                "Name": "kuzhandhai paruvamudhal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6687,
                "Name": "oozhil andru paraman",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kavadi Sindhu",
                "Thalam": "Mishra Chapu",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6688,
                "Name": "pichchai yeDukka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Mishra Chapu",
                "Praise of": "Shiva",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6689,
                "Name": "jyothiswaroopini uyar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jyothiswaroopini",
                "Thalam": "Khanda Ekam",
                "Praise of": "Devi-Parvathi",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6690,
                "Name": "param poruLe unadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Praise of": "ParaBrahmam",
                "Composer": "Periyasamy Turan",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6691,
                "Name": "thEnendru mozhi pEsum",
                "Type": "Poem",
                "Praise of": "Murugan",
                "Composer": "Periyasamy Turan",
                "Group": "Pazhani Murugan thudhi",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6692,
                "Name": "azhagu vaDivaai vandha",
                "Type": "Poem",
                "Praise of": "Devi-Parvathi",
                "Composer": "Periyasamy Turan",
                "Group": "Thiruverkaadu Devi Karumaari amman thudhi",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6693,
                "Name": "karpagamE karpagamaai",
                "Type": "Poem",
                "Praise of": "Mylapore Karpagambal",
                "Composer": "Periyasamy Turan",
                "Group": "Mylai Karpagambal thudhi",
                "Song Language": "Tamil",
                "BookTitle": "Periyasamy Turan - Keerthanai Amudam",
                "BookAuthor": "Periyasamy Turan",
                "Year of Publication": 1974,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6694,
                "Name": "Evvari Bodhana",
                "Type": "Varnam-Tana",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6695,
                "Name": "rAmuni kOri",
                "Type": "Varnam-Tana",
                "Ragam": "Abhogi",
                "Thalam": "Khanda Jathi Triputa",
                "Composer": "Srinivasa Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6696,
                "Name": "vallamai tharuvAi",
                "Type": "Varnam-Tana",
                "Ragam": "Ahiri",
                "Thalam": "Adi",
                "Composer": "K Muthukumaraswamy, Varahur",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6697,
                "Name": "ninnE kOri",
                "Type": "Varnam-Tana",
                "Ragam": "Ahiri",
                "Thalam": "Ata",
                "Composer": "Tarangampadi Panchanada Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6698,
                "Name": "saami nee pai",
                "Type": "Varnam-Tana",
                "Ragam": "Amruthavarshini",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6699,
                "Name": "kOri sEvimparE",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6700,
                "Name": "ninnu nammi naa",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6701,
                "Name": "pantha mEla naa saami",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Ponnaiyya",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6702,
                "Name": "paavana suguna",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6703,
                "Name": "raa raa sami",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6704,
                "Name": "saare gunidu",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Subbarama Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6705,
                "Name": "saami nee pai",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Ata",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6706,
                "Name": "sami nee rammaa",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Ata",
                "Composer": "Shyama Shastry",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6707,
                "Name": "mahishaa sura",
                "Type": "Varnam-Tana",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6708,
                "Name": "nee daya raadhaa",
                "Type": "Varnam-Tana",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6709,
                "Name": "annamE",
                "Type": "Varnam-Tana",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Tiger Varadacharya",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6710,
                "Name": "paapa jaati maaruni",
                "Type": "Varnam-Tana",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Venai Krishnamachary",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6711,
                "Name": "sarasija mukhi",
                "Type": "Varnam-Tana",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Thiruvotri Veenai Kuppaiyer / Pallavi Dorasamy",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6712,
                "Name": "swaami unnai sadaa",
                "Type": "Varnam-Tana",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6713,
                "Name": "O maani baari",
                "Type": "Varnam-Tana",
                "Ragam": "Arabhi",
                "Thalam": "Ata",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6714,
                "Name": "ninne enthO",
                "Type": "Varnam-Tana",
                "Ragam": "Arabhi",
                "Thalam": "Tisra Jathi Dhruva Thalam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6715,
                "Name": "taama sincha kathaLa",
                "Type": "Varnam-Tana",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "PS Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6716,
                "Name": "nela tharO ee marulu",
                "Type": "Varnam-Tana",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6717,
                "Name": "karuNa karuDa",
                "Type": "Varnam-Tana",
                "Ragam": "Atana",
                "Thalam": "Tisra Jathi Ata Thalam (Gupta)",
                "Composer": "Ponniah Pillai",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6718,
                "Name": "saami ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Atana",
                "Thalam": "Ata",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6719,
                "Name": "manasu thelisi yuNTi",
                "Type": "Varnam-Tana",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6720,
                "Name": "nenarulE",
                "Type": "Varnam-Tana",
                "Ragam": "Balahamsa",
                "Thalam": "Ata",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6721,
                "Name": "sree raajaadhi raaja",
                "Type": "Varnam-Tana",
                "Ragam": "Balahamsa",
                "Thalam": "Ata",
                "Composer": "Subbarama Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6722,
                "Name": "ajanani nannu",
                "Type": "Varnam-Tana",
                "Ragam": "Bhowli",
                "Thalam": "Ata",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6723,
                "Name": "dayaa nidhE",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Shyama Shastry",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6724,
                "Name": "Intha chalamu",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6725,
                "Name": "mazhalai chollaal",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachary",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6726,
                "Name": "neerajaaNa",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "PS Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6727,
                "Name": "marachiTLuNDE",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Ata",
                "Composer": "PS Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6728,
                "Name": "Saraswathi",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Ramaswamy Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6729,
                "Name": "ummaiyE endRum ninaindhu",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6730,
                "Name": "vanajaksha",
                "Type": "Varnam-Tana",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6731,
                "Name": "ennuLLam kavarndha",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "K Muthukumarasamy, Varahur",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6732,
                "Name": "ninnu kOri",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer/CS Ponniah",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6733,
                "Name": "sarasa gura",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar/Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6734,
                "Name": "sree maadhavi gowri",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Ksheerabdi Shastry anandhai",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6735,
                "Name": "vanitha ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Ponnusamy",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6736,
                "Name": "yEmi sEthunE",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Ata",
                "Composer": "Patnam Subramanya Iyer/CS Ponniah",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6737,
                "Name": "Viriboni",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Ata",
                "Composer": "Pachhaimiriam Adiyappa",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6738,
                "Name": "nee saaTi dora",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6739,
                "Name": "saami nannelu kOra",
                "Type": "Varnam-Tana",
                "Ragam": "Bhupalam",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6740,
                "Name": "intha chauka",
                "Type": "Varnam-Tana",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6741,
                "Name": "yaadhu seivEn",
                "Type": "Varnam-Tana",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "CS Nataraja Sundaram Pillai",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6742,
                "Name": "nenarunchi",
                "Type": "Varnam-Tana",
                "Ragam": "Bilahari",
                "Thalam": "Ata",
                "Composer": "Sonti Venkatasubbayya",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6743,
                "Name": "Jalajakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6744,
                "Name": "maadha rasi en ariyaa pizhai",
                "Type": "Varnam-Tana",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Ksheerabdi Shastry anandhai",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6745,
                "Name": "saami nannelu kOra",
                "Type": "Varnam-Tana",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6746,
                "Name": "sara sUDa",
                "Type": "Varnam-Tana",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Ummadichetti Venkatasamy Naidu",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6747,
                "Name": "ingkala mEla",
                "Type": "Varnam-Tana",
                "Ragam": "Chakravaham",
                "Thalam": "Ata",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6748,
                "Name": "shree raajaadhi raaja mahaaraaja",
                "Type": "Varnam-Tana",
                "Ragam": "Chalanaatai",
                "Thalam": "Ata",
                "Composer": "Balaswamy Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6749,
                "Name": "Eemaguva bOdhi",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6750,
                "Name": "vana jaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Thyagaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6751,
                "Name": "vara vENum",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "N Koteeswara Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6752,
                "Name": "nena runchi",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Ata",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6753,
                "Name": "chalamela",
                "Type": "Varnam-Tana",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Thyagaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6754,
                "Name": "mudhar paar moovaril",
                "Type": "Varnam-Tana",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachary",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6755,
                "Name": "intha mODi",
                "Type": "Varnam-Tana",
                "Ragam": "Darbar",
                "Thalam": "Ata",
                "Composer": "Subbarama Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6756,
                "Name": "palu maaru ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Devamanohari",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6757,
                "Name": "ninne entho vEDi",
                "Type": "Varnam-Tana",
                "Ragam": "Devamanohari",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6758,
                "Name": "shree meenakshi maam paahi",
                "Type": "Varnam-Tana",
                "Ragam": "Gamakakriya",
                "Thalam": "Adi",
                "Composer": "Vedantha Bhagavathar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6759,
                "Name": "ninnu kOri",
                "Type": "Varnam-Tana",
                "Ragam": "Gamakakriya",
                "Thalam": "Ata",
                "Composer": "Sonti Venkatasubbayya",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6760,
                "Name": "chelimi kOri",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6761,
                "Name": "gaNanaathaa nin ponnaDi",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Mayuram Vishwanatha Shastry",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6762,
                "Name": "kanakaangi pai",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Ponniah Pillai (Tanjore Quartet)",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6763,
                "Name": "ninnE kOri",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6764,
                "Name": "rammani pilachE",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6765,
                "Name": "aapalumaaru",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Ata",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6766,
                "Name": "paadham paNindhE",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlipanthu",
                "Thalam": "Adi",
                "Composer": "K Muthukumaraswamy, Varagur",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6767,
                "Name": "mayilE poom pozhi",
                "Type": "Varnam-Tana",
                "Ragam": "Guhamanohari",
                "Thalam": "Adi",
                "Composer": "NS Ramachandran",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6768,
                "Name": "Jalajakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Manambuchaavadi Venkatasubbaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6769,
                "Name": "paga vaari bO",
                "Type": "Varnam-Tana",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6770,
                "Name": "Sri Subramanya",
                "Type": "Varnam-Tana",
                "Ragam": "Hamsadhwani",
                "Thalam": "Roopakam",
                "Composer": "Ramaswamy Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6771,
                "Name": "gnaana maargam",
                "Type": "Varnam-Tana",
                "Ragam": "Hamsanadam",
                "Thalam": "Adi",
                "Composer": "Vedanayakam Pillai",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6772,
                "Name": "jaalamEla",
                "Type": "Varnam-Tana",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6773,
                "Name": "chalamelara",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6774,
                "Name": "Maal Marugan",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "TM Thyagarajan",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6775,
                "Name": "Maal Marugan",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Tanjore Shankara Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6776,
                "Name": "sakala kalaa vaaNi",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "GN Balasubrmanian",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6777,
                "Name": "swaaminaathaa",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "K Muthukumaraswamy, Varagur",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6778,
                "Name": "saami ninne kOri",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6779,
                "Name": "rammana",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Ata",
                "Composer": "Ramaswamy Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6780,
                "Name": "valachi vachchi",
                "Type": "Varnam-Tana",
                "Ragam": "HindolaVasantham",
                "Thalam": "Roopakam",
                "Composer": "Ramaswamy Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6781,
                "Name": "eemaayalaaTi",
                "Type": "Varnam-Tana",
                "Ragam": "Huseni",
                "Thalam": "Roopakam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6782,
                "Name": "sarasija nEtRi",
                "Type": "Varnam-Tana",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "Karaikudi Rajamani Iyengar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6783,
                "Name": "shringaara janaranjani",
                "Type": "Varnam-Tana",
                "Ragam": "Janaranjani",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6784,
                "Name": "arullilaarku avvulagam",
                "Type": "Varnam-Tana",
                "Ragam": "Jayanthashri",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/VS GomathyShankara Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6785,
                "Name": "mahaa gaNapathe namasthE",
                "Type": "Varnam-Tana",
                "Ragam": "Jayanthashri",
                "Thalam": "Adi",
                "Composer": "Veenai GN Dandapani",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6786,
                "Name": "sharaNagatha vatsalE",
                "Type": "Varnam-Tana",
                "Ragam": "Kathanakuthuhalam",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6787,
                "Name": "kuvalayaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Kathanakuthuhalam",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6788,
                "Name": "nivera",
                "Type": "Varnam-Tana",
                "Ragam": "Kathanakuthuhalam",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6789,
                "Name": "chinnaanu",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Thiruvitriyur Thyagaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6790,
                "Name": "chirugu bOni",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6791,
                "Name": "dharmasamvardhani",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6792,
                "Name": "kamalaambha ninnu",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Ramaswamy Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6793,
                "Name": "saamiyai azhaithu vaaDi",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Sivanandam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6794,
                "Name": "sarOjaaksha ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6795,
                "Name": "vanajaakshirO",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Poochi Srinivasa Iyengar/Mullaiveetu Rangaswamy Nattuvanar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6796,
                "Name": "vanajakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Ata",
                "Composer": "Pallavi Gopala Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6797,
                "Name": "nivE gathi yani",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Tisra Jathi Matya Thalam (Saara)",
                "Composer": "Shyama Shastry",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6798,
                "Name": "garima ninnE",
                "Type": "Varnam-Tana",
                "Ragam": "KamalaManohari",
                "Thalam": "Adi",
                "Composer": "Kottavasal Venkatrama Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6799,
                "Name": "neevamTi saami",
                "Type": "Varnam-Tana",
                "Ragam": "KamalaManohari",
                "Thalam": "Adi",
                "Composer": "Chinayya Pillai",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6800,
                "Name": "Sree kamalambhaa",
                "Type": "Varnam-Tana",
                "Ragam": "KamalaManohari",
                "Thalam": "Adi",
                "Composer": "Ramaswamy Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6801,
                "Name": "shree raama chandra",
                "Type": "Varnam-Tana",
                "Ragam": "KamalaManohari",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6802,
                "Name": "samini",
                "Type": "Varnam-Pada",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6803,
                "Name": "entha ninnE",
                "Type": "Varnam-Pada",
                "Ragam": "Khamas",
                "Thalam": "Tisra jathi Eka Thalam",
                "Composer": "Subbarama Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6804,
                "Name": "pankajaakshi pai",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Maha Vaidhyanatha Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6805,
                "Name": "saamiki nEnE ramanTi",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6806,
                "Name": "saarasaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6807,
                "Name": "tharuNi ninnu",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Fiddle Ponnusamy",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6808,
                "Name": "intha jaalamu",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Ata",
                "Composer": "Pallavi GopalaIyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6809,
                "Name": "sarasija naabha",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Ata",
                "Composer": "Swathi Thirunal/Vadivelu",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6810,
                "Name": "kamalaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Jhampai",
                "Composer": "Kunnakudi Krishna Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6811,
                "Name": "Ninnekori",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Thyagaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6812,
                "Name": "sarasuda",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "Veenai Krishnamachary",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6813,
                "Name": "nera nammithi",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanada",
                "Thalam": "Ata",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6814,
                "Name": "alukanela",
                "Type": "Varnam-Tana",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6815,
                "Name": "anthamizh vaDa mozhi",
                "Type": "Varnam-Tana",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Composer": "Nerur Krishnamachari",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6816,
                "Name": "saamidhi",
                "Type": "Varnam-Tana",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6817,
                "Name": "sadaya valachi",
                "Type": "Varnam-Tana",
                "Ragam": "Kaapi",
                "Thalam": "Roopakam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6818,
                "Name": "Kaamaakshi nee paadha",
                "Type": "Varnam-Tana",
                "Ragam": "KarnatakaKapi",
                "Thalam": "Adi",
                "Composer": "Ramaswamy Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6819,
                "Name": "Sree kaanchi",
                "Type": "Varnam-Tana",
                "Ragam": "Kasiramakriya/Pantuvarali",
                "Thalam": "Ata",
                "Composer": "Sonti Venkatasubayya",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6820,
                "Name": "nee sari",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Govindasamy ayya",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6821,
                "Name": "saami daya jooda",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Thyagaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6822,
                "Name": "saami naa pai",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Ata",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6823,
                "Name": "viriboni",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Jhampai",
                "Composer": "Dharmapuri Subbarayar/Rudrapatnam Venkataramayya",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6824,
                "Name": "sarOjaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Vadivelu",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6825,
                "Name": "sami ni rammanavE",
                "Type": "Varnam-Tana",
                "Ragam": "Kedaram",
                "Thalam": "Roopakam",
                "Composer": "Thiruvotriyur Thyagaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6826,
                "Name": "muddugumma",
                "Type": "Varnam-Tana",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Veenai Krishnamachary",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6827,
                "Name": "hE kaaLi neevE gati",
                "Type": "Varnam-Tana",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Composer": "Anantakrishnaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6828,
                "Name": "chala mela",
                "Type": "Varnam-Tana",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6829,
                "Name": "Sree krishna naatha",
                "Type": "Varnam-Tana",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6830,
                "Name": "guNa nalam koNda enthan",
                "Type": "Varnam-Tana",
                "Ragam": "Kokilapriya",
                "Thalam": "Adi",
                "Composer": "K Muthukumaraswamy, Varagur",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6831,
                "Name": "Ninnu kori",
                "Type": "Varnam-Tana",
                "Ragam": "Lalithaa",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6832,
                "Name": "vanajaakshirO",
                "Type": "Varnam-Tana",
                "Ragam": "Lalithaa",
                "Thalam": "Adi",
                "Composer": "Ponniah (Tanjore Quartet)",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6833,
                "Name": "paim pon mEni",
                "Type": "Varnam-Tana",
                "Ragam": "Lalithapanchamam",
                "Thalam": "Adi",
                "Composer": "K Muthukumarasamy, varagur",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6834,
                "Name": "saraguNa nanEla",
                "Type": "Varnam-Tana",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Thyagaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6835,
                "Name": "intha viraga taapam",
                "Type": "Varnam-Tana",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Srinivasa Iyengar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6836,
                "Name": "saami ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6837,
                "Name": "vanajaaksha nammi naanu",
                "Type": "Varnam-Tana",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharya",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6838,
                "Name": "chaala valachi",
                "Type": "Varnam-Tana",
                "Ragam": "Manirangu",
                "Thalam": "Tisra Ekam",
                "Composer": "Pallavi Seshaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6839,
                "Name": "maayaamaaLava gowlai",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachary",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6840,
                "Name": "ninnE chaala nammithi",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6841,
                "Name": "ninne kOri",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6842,
                "Name": "sarasaangi neepai",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Raghavendrachariaar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6843,
                "Name": "sarasija naabha",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6844,
                "Name": "saravaNabhava shaNmugaa",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Fiddle Krishnaswamy Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6845,
                "Name": "sree raajaraajeshwari",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6846,
                "Name": "ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Pallavi Gopalaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6847,
                "Name": "ninnu kori",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6848,
                "Name": "sarighaadhaani",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Govindasaami",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6849,
                "Name": "paga joopEvEla",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Ata",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6850,
                "Name": "sarasijaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Ata",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6851,
                "Name": "era naapai",
                "Type": "Varnam-Tana",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6852,
                "Name": "saami nannelu kOra",
                "Type": "Varnam-Tana",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6853,
                "Name": "aamadiraakshi neepai",
                "Type": "Varnam-Tana",
                "Ragam": "Mukhari",
                "Thalam": "Ata",
                "Composer": "Thiruvotriyur Thyagaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6854,
                "Name": "maguva neepai",
                "Type": "Varnam-Tana",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6855,
                "Name": "saakEtha nagara",
                "Type": "Varnam-Tana",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Srinivasa Aiyyaa",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6856,
                "Name": "saami nee pai",
                "Type": "Varnam-Tana",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6857,
                "Name": "aamaguva",
                "Type": "Varnam-Tana",
                "Ragam": "NarayanaGowlai",
                "Thalam": "Ata",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6858,
                "Name": "saami ninne kOri",
                "Type": "Varnam-Tana",
                "Ragam": "Narayani",
                "Thalam": "Ata",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6859,
                "Name": "manjuLa vENi",
                "Type": "Varnam-Tana",
                "Ragam": "Natabhairavi",
                "Thalam": "Adi",
                "Composer": "Veenai Krishnamachary",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6860,
                "Name": "palumaaru",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6861,
                "Name": "sarasija naabha",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Palakadu Parameshwara Bhagavathar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6862,
                "Name": "maruvai yunnadhi",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Ata",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6863,
                "Name": "samukamukanu",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Ata",
                "Composer": "Vadivel Pillai",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6864,
                "Name": "shree naatha",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Ata",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6865,
                "Name": "chalamela",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Kuppuswamy",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6866,
                "Name": "sarasaa ninnE",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6867,
                "Name": "inthapara",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Ata",
                "Composer": "Thiruvotriyur Thayagaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6868,
                "Name": "intha",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Ata",
                "Composer": "koovanasamy Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6869,
                "Name": "saami neeve",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Ata",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6870,
                "Name": "saami gaanEni",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Ata",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6871,
                "Name": "sarasijaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Nayaki",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6872,
                "Name": "neevanTi daivamu",
                "Type": "Varnam-Tana",
                "Ragam": "Neelambari",
                "Thalam": "Ata",
                "Composer": "Tarangampadi Panchanadayyar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6873,
                "Name": "eevEla nannu",
                "Type": "Varnam-Tana",
                "Ragam": "Paadi",
                "Thalam": "Adi",
                "Composer": "Pathamadai Krishnaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6874,
                "Name": "sami ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Shatkaala Narasaiyya",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6875,
                "Name": "saami neethOdi dEvEna",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6876,
                "Name": "chalamu",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Ata",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6877,
                "Name": "Cheliya",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Ata",
                "Composer": "Thiruvotriyur Thyagaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6878,
                "Name": "madhava",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Ata",
                "Composer": "Sri Adappayyaa",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6879,
                "Name": "taapa saanugruheeta",
                "Type": "Varnam-Tana",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Pallavi Subbiah Bhagavathar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6880,
                "Name": "egaanEree",
                "Type": "Varnam-Tana",
                "Ragam": "Poornachandrika",
                "Thalam": "Ata",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6881,
                "Name": "shree raaja",
                "Type": "Varnam-Tana",
                "Ragam": "Poornachandrika",
                "Thalam": "Ata",
                "Composer": "Subbarama Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6882,
                "Name": "elananne",
                "Type": "Varnam-Tana",
                "Ragam": "Poornachandrika",
                "Thalam": "Roopakam",
                "Composer": "Ramaswamy Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6883,
                "Name": "pankajaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6884,
                "Name": "SaamikientanO",
                "Type": "Varnam-Tana",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6885,
                "Name": "sarivaari",
                "Type": "Varnam-Tana",
                "Ragam": "Poorvikalyani",
                "Thalam": "Ata",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6886,
                "Name": "saarasaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Jathi Roopaka Thalam (Kula)",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6887,
                "Name": "valachi yunna",
                "Type": "Varnam-Tana",
                "Ragam": "Pushpalathika",
                "Thalam": "Adi",
                "Composer": "Vainika Sri Kalahasthi Venkataswami",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6888,
                "Name": "raagamaalikaa varnamu",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "P Sambamoorthy",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6889,
                "Name": "intha kOpa",
                "Type": "Varnam-Tana",
                "Ragam": "GhanaRagamalika",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6890,
                "Name": "sami ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6891,
                "Name": "chera raava",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Group": "Nakshathra Ragamalika",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6892,
                "Name": "valachi vachchi",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "Group": "Navaragamalika",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6893,
                "Name": "saami ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika",
                "Thalam": "Triputa",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6894,
                "Name": "saakEtha nagara",
                "Type": "Varnam-Tana",
                "Ragam": "Ragaswarali",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6895,
                "Name": "ambOruhaa",
                "Type": "Varnam-Tana",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanyam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6896,
                "Name": "neeve nunnu",
                "Type": "Varnam-Tana",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6897,
                "Name": "saami vanajaaksha",
                "Type": "Varnam-Tana",
                "Ragam": "Reethigowlai",
                "Thalam": "Ata",
                "Composer": "Thiruvotriyur Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6898,
                "Name": "vanajaakshaa ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Reethigowlai",
                "Thalam": "Ata",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6899,
                "Name": "karuNimpa",
                "Type": "Varnam-Tana",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Thyagaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6900,
                "Name": "evarEmi bOdha",
                "Type": "Varnam-Tana",
                "Ragam": "Sahana",
                "Thalam": "Ata",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6901,
                "Name": "vaarijaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Sahana",
                "Thalam": "Ata",
                "Composer": "Subbarama Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6902,
                "Name": "Sami ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6903,
                "Name": "Chalamela",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Ata",
                "Composer": "Swathi Thirunal/Vadivelu",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6904,
                "Name": "niluparaani mOha",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Ata",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6905,
                "Name": "raarapu",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Ata",
                "Composer": "Ramaswamy Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6906,
                "Name": "Elana",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6907,
                "Name": "gajaananaaya",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Composer": "Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6908,
                "Name": "Vanaja",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6909,
                "Name": "intha mODi",
                "Type": "Varnam-Tana",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Thyagaiyer/Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6910,
                "Name": "ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6911,
                "Name": "saaranga",
                "Type": "Varnam-Tana",
                "Ragam": "Saranga",
                "Thalam": "Roopakam",
                "Composer": "Vadivelu",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6912,
                "Name": "naamana vini vinu",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Ata",
                "Composer": "Shyama Shastry",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6913,
                "Name": "valachi vachchi",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Ata",
                "Composer": "Ponniah Pillai",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6914,
                "Name": "sadaa un maNi chilamba",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtri",
                "Thalam": "Adi",
                "Composer": "Ksheerabdi Shastry Anandai",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6915,
                "Name": "iravum pagalum",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachariar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6916,
                "Name": "maanE mOgam sakhiyE",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "N Koteeswara Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6917,
                "Name": "Sarasuda",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Kottavasal Venkatrama Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6918,
                "Name": "kalki ninnE",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Ata",
                "Composer": "Thiruvotriyur Thyagaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6919,
                "Name": "mana sEti",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Ata",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6920,
                "Name": "neevE dikkani",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Ata",
                "Composer": "Tirunelveli Vengu Bhagavathar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6921,
                "Name": "vanajakshi ninnE",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Ata",
                "Composer": "Swathi Thirunal/Vadivelu",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6922,
                "Name": "pankajaakshi pai nenarunchi",
                "Type": "Varnam-Tana",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Veenai Krishnamachary",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6923,
                "Name": "pada pangkEjaata",
                "Type": "Varnam-Tana",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6924,
                "Name": "endu kinda mODi jEsE",
                "Type": "Varnam-Tana",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6925,
                "Name": "saami ninnE",
                "Type": "Varnam-Tana",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Garbhaurivasa",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6926,
                "Name": "iTu jaagu",
                "Type": "Varnam-Tana",
                "Ragam": "Sree",
                "Thalam": "Ata",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6927,
                "Name": "vaarijaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Sree",
                "Thalam": "Ata",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6928,
                "Name": "saami nee pada",
                "Type": "Varnam-Tana",
                "Ragam": "Sree",
                "Thalam": "Mishra Jathi Triputa Thalam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6929,
                "Name": "saami ninne",
                "Type": "Varnam-Tana",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6930,
                "Name": "Sakhiye",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6931,
                "Name": "Sree raaja maathangi",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6932,
                "Name": "mamataarE",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Ata",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6933,
                "Name": "dEva dEva mahEshwara",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddhaseemanthini",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6934,
                "Name": "saami ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "ShuddhaThodi",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6935,
                "Name": "gaNanaayakaa",
                "Type": "Varnam-Tana",
                "Ragam": "Sunadavinodini",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "NS Ramachandran",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6936,
                "Name": "enthO prEmathO",
                "Type": "Varnam-Tana",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6937,
                "Name": "saamini",
                "Type": "Varnam-Tana",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Pallavi Seshaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6938,
                "Name": "intha chalamu",
                "Type": "Varnam-Tana",
                "Ragam": "Surutti",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "K Ponniah Pillai",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6939,
                "Name": "chandra vadana",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "AZHAGIRISAMI BHAGAVATAR",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6940,
                "Name": "daani saaTipOTi",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Sivanandam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6941,
                "Name": "Era napai",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6942,
                "Name": "mOhalAhiri",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Sivanandam",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6943,
                "Name": "Roopamu juchi",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6944,
                "Name": "saami ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6945,
                "Name": "saara saaksha krupa",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "TV Lakshminarasimhan",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6946,
                "Name": "sari gaani",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Ramaswamy Dikshitar",
                "Group": "SVARASTANA VARNAM (IN PRAISE OF MANALI CHINNAYYA MUDALIYAR)",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6947,
                "Name": "Soga manE en",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "K Ponniah Pillai",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6948,
                "Name": "kanakaangi",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Ata",
                "Composer": "Pallavi Gopala Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6949,
                "Name": "vanajaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Ata",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6950,
                "Name": "veera vasantatya",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Chatusra Jathi Jhampa Thalam (Madhura)",
                "Composer": "Ramaswamy Dikshitar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6951,
                "Name": "sarasudu neeve yani",
                "Type": "Varnam-Tana",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "Pandanainallur mInAkshi sundarampillai",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6952,
                "Name": "paarinil nee kaameshwari",
                "Type": "Varnam-Tana",
                "Ragam": "Vaahini",
                "Thalam": "Adi",
                "Composer": "Ksheerabdi Shastry Anandai",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6953,
                "Name": "aarendru arindhu",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "S Srinivasa Iyengar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6954,
                "Name": "thanjam neeye dayai puri",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "TM Thyagarajan",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6955,
                "Name": "tharuNi ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "NS Ramachandran",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6956,
                "Name": "vana maalaa sree gOpaalaa",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Venkatrama Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6957,
                "Name": "viragamu vasamalla",
                "Type": "Varnam-Tana",
                "Ragam": "Vamsavathi",
                "Thalam": "Adi",
                "Composer": "Muthukumara Pulavar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6958,
                "Name": "vanajaaksha",
                "Type": "Varnam-Tana",
                "Ragam": "Varali",
                "Thalam": "Ata",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6959,
                "Name": "ninnu kori",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Tachchur Singarachariar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6960,
                "Name": "vanajaakshirO",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Ponniah (Tanjore Quartet)",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6961,
                "Name": "nee paadapankajamu",
                "Type": "Varnam-Tana",
                "Ragam": "VasanthaBhairavi",
                "Thalam": "Adi",
                "Composer": "Rajamani Iyengar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6962,
                "Name": "daani kOri",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Poochi Srinivasa Iyengar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6963,
                "Name": "varuvaanODi",
                "Type": "Varnam-Tana",
                "Ragam": "Vinadari",
                "Thalam": "Adi",
                "Composer": "S Srinivasa Iyengar",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6964,
                "Name": "sami ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Adi",
                "Composer": "Vadivelu",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6965,
                "Name": "kamalaakshi ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Ata",
                "Composer": "Veenai Kuppaiyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6966,
                "Name": "pada rEti mEra",
                "Type": "Varnam-Tana",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Ata",
                "Composer": "Patnam Subramanya Iyer",
                "BookTitle": "VARNAMS - A COMPILATION",
                "BookAuthor": "Various",
                "Year of Publication": 2021,
                "Book Print Language": "English"
              },
              {
                "Serial #": 6967,
                "Name": "Gananaayakane Sharanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6968,
                "Name": "paaror paravum paranae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6969,
                "Name": "singam enbadhu uNamaiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6970,
                "Name": "yenO innum thayakkam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natabhairavi",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6971,
                "Name": "kaliyuga prathyaksha deivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Mishra Chapu",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6972,
                "Name": "kadikai naadhan kamala paadhan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Roopakam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6973,
                "Name": "naan vanangum deivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6974,
                "Name": "sadaanandha sathya roopane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Roopakam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6975,
                "Name": "kaarthi maadhathil un kaatchi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6976,
                "Name": "gathi undhan paadham allaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6977,
                "Name": "kadaikkan nOkki kaapadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Roopakam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6978,
                "Name": "kadithOdi vaa kaakave ennai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6979,
                "Name": "veror thunai ariyaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6980,
                "Name": "maadhavar kaithozhum malar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6981,
                "Name": "vinnor thozhum deva deva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Jhampai",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6982,
                "Name": "vazhiyodhuvaai aazhi yaendhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6983,
                "Name": "kadikai nilaya karunai illayaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6984,
                "Name": "karunaa nidhi allavo nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6985,
                "Name": "nenjamE nee sendra vidham enna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6986,
                "Name": "laksham kaNgaLum pOdhumO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6987,
                "Name": "paramanai thudhippaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6988,
                "Name": "narahariye arul tharuvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6989,
                "Name": "thiru kadigai yena oru ganam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6990,
                "Name": "engum niraindha porule",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6991,
                "Name": "giri mEvi vaLar karuNai kaDale",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6992,
                "Name": "yen paDaiththaai singaa ennai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6993,
                "Name": "siththam nilaithida uththama vazhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Roopakam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6994,
                "Name": "thirukadigaiyai naadi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6995,
                "Name": "vidamudaiya aravil kiDandhOnE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "RaviChandrika",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6996,
                "Name": "sanchalm theerthida",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6997,
                "Name": "kalai vaLartha uLLathil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapanthuvarali",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6998,
                "Name": "thirumaamagaL maNavaaLanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ChithaRanjani",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 6999,
                "Name": "dheena jana paripaalaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Brindavani",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7000,
                "Name": "Sre yog narasinganE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Mishra Chapu",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7001,
                "Name": "maadhavan ennum maanidhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7002,
                "Name": "chitha kurai theerka idhu samayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Khanda Chapu",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7003,
                "Name": "kadigai giri naadhanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7004,
                "Name": "mananam seiyya maname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Roopakam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7005,
                "Name": "naadhane unnai nambi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7006,
                "Name": "kadigaiyil vaLar naadhaa varuga",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Group": "Kadigai Naadhan Varavaerpu",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7007,
                "Name": "anburuvamaana narasimham",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Group": "Akshara maalikai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7008,
                "Name": "siththam orumithu urugi",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Group": "Singanin Thingal Maalai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7009,
                "Name": "aayiramaam naamam solli",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Group": "Sapta Vaara Sthuthi",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7010,
                "Name": "vidamudaiya varavaNai mEl",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Kadigai Giri Dashakam",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7011,
                "Name": "paNDu orenamaagi",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Group": "Thiru Kadigai Padhigam",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7012,
                "Name": "naamangal palapalavaam",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Group": "Singan Sirappu",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7013,
                "Name": "allal ennum maabhaaram azhuthi",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Group": "Bhagavan Kai KoDuthu aruliyadhu",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7014,
                "Name": "sakkarai kaiyanE",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Group": "varumai theera vENDudhal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7015,
                "Name": "SangamuDan sakkaramum",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Group": "Yoga Narasimha sevai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7016,
                "Name": "anban narasingan malai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Roopakam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Group": "Thiru Kadigai malayin Magimai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7017,
                "Name": "ella idarum kedumaaru",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Yoga Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Group": "Manadhirku Idhobhadesam",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7018,
                "Name": "karuNaa giri sri kadigeswari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kadanakuthuhalam",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7019,
                "Name": "amruthavalliyai kandu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7020,
                "Name": "kavalaiyai neekka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7021,
                "Name": "adaravalithennai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Mishra Chapu",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7022,
                "Name": "thaaye nee thunai varuvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7023,
                "Name": "paarkadal vandhudhiththa paavai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapanthuvarali",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7024,
                "Name": "viduthalai aLithiDa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7025,
                "Name": "anjEl endru ennai aaLamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7026,
                "Name": "madhuraanthaka nagar vaasiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7027,
                "Name": "vedanaikku uLLAkki sodhanai seivadhu",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7028,
                "Name": "varuvaai viraivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Triputa",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7029,
                "Name": "kamala nivaasiniye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7030,
                "Name": "anba narasingam maghizh ",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Khanda Chapu",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7031,
                "Name": "silai aanadhaal karunai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7032,
                "Name": "seeraarum chindhai maghizh",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Khanda Chapu",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Group": "Laali song (Lullaby)",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7033,
                "Name": "paNDandru devarudan",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Devi Malai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7034,
                "Name": "vaNNamalar nangaiyena",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Group": "Amruthavalli Thingal Malai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7035,
                "Name": "paarkadalil vadhudhithaai",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Amruthavalli Paamalai / Sapta vaara sthuthi",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7036,
                "Name": "kamala meedhin amarndhaai",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Sthuthi",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7037,
                "Name": "anudhinamum",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Group": "Lakshmi Sthuthi",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7038,
                "Name": "Unjal aaDinaal devi unjal aaDinaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Roopakam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7039,
                "Name": "mangaiye unakku jeya mangalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7040,
                "Name": "valli raaman",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7041,
                "Name": "kanavinil koDanda paaniyaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7042,
                "Name": "Mohana punnagaiyaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7043,
                "Name": "Chitham kuLirndhiDa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Mishra Chapu",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7044,
                "Name": "dina manikkUlam thannil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7045,
                "Name": "pattabhi raaman sevaiyai paarka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7046,
                "Name": "thiru maadhu uraiyum thiru maarbhaa",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Group": "ThiruvaLLoor Thirupadigam",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7047,
                "Name": "thiru maamagaludan ",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7048,
                "Name": "kaLithODi vandhaayO kaNNaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7049,
                "Name": "ennavendru yeDuthu sonnEan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7050,
                "Name": "endha vidham endru yeNNiDuvaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7051,
                "Name": "viNNOr thozhum paramane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7052,
                "Name": "kaNNanai kaaN manamE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7053,
                "Name": "karam thanil kOL kONDU",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7054,
                "Name": "gopura darisanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7055,
                "Name": "kaNNaa kaDal nira vaNNaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7056,
                "Name": "kuzhandhai vaDivaana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7057,
                "Name": "kaDalaaDi iDar theera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7058,
                "Name": "kaNNaniDam sollaDi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MohanaKalyani",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7059,
                "Name": "nandha kumaarane vaa vaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShivaRanjani",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7060,
                "Name": "unakkindha manamaagumaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7061,
                "Name": "kaathiDa vandhanaiyO kaNNaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7062,
                "Name": "vandhaan vandhaan kaNNan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SunadaRanjani",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7063,
                "Name": "uthaman padham panivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Karambanoor",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7064,
                "Name": "vaDivazhaghiya nambi anbil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7065,
                "Name": "thanjai maamaNi koyil koNDa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Revagupti",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Thanjore",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7066,
                "Name": "uraiyoor thanil nindravaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Uraiyoor",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7067,
                "Name": "arangamaanagar amarndha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MeghaRanjani",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Srirangam",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7068,
                "Name": "vayavaali maNavaaLanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Jhampai",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Thiruvali",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7069,
                "Name": "aaNDaLakkum ayyan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Thiruaadhanoor",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7070,
                "Name": "aravaNai paLLi kONDA",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Mishra Chapu",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Appakudam",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7071,
                "Name": "pirandhaal ninapadhi pirakka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Uppili (Thirunageswaram)",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7072,
                "Name": "aran chaapam theerndha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Thirukandiyoor",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7073,
                "Name": "veNchuDar perumaalE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Thalaichangkadu (Naanmadiyam)",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7074,
                "Name": "aru maakaDale",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Thirusirupuliyoor",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7075,
                "Name": "saarangapaaNiyaE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Kumbakonam (Aravamudhan)",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7076,
                "Name": "saarakshetram thannil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Thirucherai (Sara Kshetram)",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7077,
                "Name": "abhaayam illaa ubhaayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7078,
                "Name": "alaiyaadhiru maname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7079,
                "Name": "shanthi illayel angu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai (Meaning same as Tyagaraja's Shanthamu lekha)",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7080,
                "Name": "aridhu aridhu maname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7081,
                "Name": "thirumagal naathaa thiruvarul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7082,
                "Name": "vinnagar uraindhidum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Thirunangur (Thiruvaikunda Vinnagaram)",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7083,
                "Name": "sowriraajanai kaNNDu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Mishra Chapu",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Thirukannapuram (Sowrirajan)",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7084,
                "Name": "aaraar unnai kaaNbar",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Ashrita Vatsalam",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7085,
                "Name": "arindhu arindhe unnai aDaivarO (Ninda Sthuthi)",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7086,
                "Name": "theeraadha vinai anaithum",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7087,
                "Name": "appa kudaththaan",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Thiru Thillaithaana perumaal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7088,
                "Name": "bhakthar manak koyil",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Vishnu",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai - Pandaripuram (Pandurangan)",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7089,
                "Name": "aagamangal koNDoLithu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu-Dasavatharam",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Divya Desa Thirumaalgal sevai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7090,
                "Name": "anbargaLukaagi ambalathil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "KM Soundaryavalli",
                "Group": "Shiva Parthi Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7091,
                "Name": "sannidhikke varadha sankalpam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "KM Soundaryavalli",
                "Group": "Shiva Parthi Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7092,
                "Name": "paalithu arulvaai kapali",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Mishra Chapu",
                "Praise of": "Shiva",
                "Composer": "KM Soundaryavalli",
                "Group": "Shiva Parthi Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7093,
                "Name": "murugaa yena oru kaal solla",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "KM Soundaryavalli",
                "Group": "Murugan Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7094,
                "Name": "velaayudha dharanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "KM Soundaryavalli",
                "Group": "Murugan Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7095,
                "Name": "umayavaL maindhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "KM Soundaryavalli",
                "Group": "Murugan Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7096,
                "Name": "kandhaa karunai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "KM Soundaryavalli",
                "Group": "Murugan Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7097,
                "Name": "oLi migundha maNiyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "KM Soundaryavalli",
                "Group": "Murugan Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7098,
                "Name": "aaru veedudaiya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "KM Soundaryavalli",
                "Group": "Murugan Keerthanaigal (Thiru Murugan thudhi)",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7099,
                "Name": "sankaranin thudhi paaduvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Adi Sankara",
                "Composer": "KM Soundaryavalli",
                "Group": "Adishankarar Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7100,
                "Name": "shankaraachaarya nin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Kanchi Periyava",
                "Composer": "KM Soundaryavalli",
                "Group": "Kanchi Periyava Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7101,
                "Name": "aadhi guruvaaga vandhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Praise of": "Adi Sankara",
                "Composer": "KM Soundaryavalli",
                "Group": "Adishankarar Padigam",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7102,
                "Name": "anjanai baalaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chandrika",
                "Thalam": "Adi",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Group": "Hanuman Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7103,
                "Name": "thanjamendru unnai",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Group": "Anjaneyar Thudhi",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7104,
                "Name": "kaariyin maganaaga kaliyil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7105,
                "Name": "aadi poorathinil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Andal",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7106,
                "Name": "bhudhatharase arulvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Roopakam",
                "Praise of": "Bhoothathalwar",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7107,
                "Name": "poigai thanil vandha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Adi",
                "Praise of": "Poigai Alwar",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7108,
                "Name": "oLIyaai ulagil oLirum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7109,
                "Name": "yadhi padhiye ahobila",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "Ahobila Mutt",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7110,
                "Name": "bhaagyamaai vandhavaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7111,
                "Name": "Guru kattaaksham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7112,
                "Name": "kandhanum kannanum ondre",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Krishna-Murugan",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7113,
                "Name": "paaroor paravum mani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7114,
                "Name": "maasil vatsa vamsam",
                "Type": "Kriti/Keerthanam",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7115,
                "Name": "vaazhvaruL thamizh maraiyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Idhara Keerthanaigal",
                "Song Language": "Tamil",
                "BookTitle": "Amarasoundaryam (Tamil Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7116,
                "Name": "vinaayakaa ekanaayakaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Roopakam",
                "Praise of": "Ganesha",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7117,
                "Name": "palumaaru ne veTithi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7118,
                "Name": "Indraprasthapura naathaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7119,
                "Name": "sanchalamu dheera sathathamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7120,
                "Name": "jaya vinaayaka bhava nivaaraka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Triputa",
                "Praise of": "Ganesha",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7121,
                "Name": "vinaayakam vighnaharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7122,
                "Name": "aga jaamukhaabja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7123,
                "Name": "saraguna paalimpa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7124,
                "Name": "Yoga nrusimha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Praise of": "Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7125,
                "Name": "ardha gaatra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Roopakam",
                "Praise of": "Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7126,
                "Name": "sri ramaa manohara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7127,
                "Name": "paridaanamivva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Roopakam",
                "Praise of": "Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7128,
                "Name": "nee maatalemaayenuraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Praise of": "Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7129,
                "Name": "varaghaTikaadri vihaara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Praise of": "Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7130,
                "Name": "nera nammithi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Praise of": "Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7131,
                "Name": "kalimaanavulanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7132,
                "Name": "Sri nrusimha neeku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7133,
                "Name": "ghatighaatri vaasam",
                "Type": "Kriti/Keerthanam",
                "Praise of": "Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7134,
                "Name": "amruta pula pradavalli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Narasimhar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7135,
                "Name": "keerthanane viNti",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Roopakam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7136,
                "Name": "sangeetha rasaahlaadini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7137,
                "Name": "pankeruha vaasini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Roopakam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7138,
                "Name": "impugaa ninnu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Chapu",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7139,
                "Name": "paripaalimpave",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7140,
                "Name": "manchi daiva merugane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7141,
                "Name": "alasi solasi vacchina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "DevaGandhari",
                "Thalam": "Roopakam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7142,
                "Name": "ammaa vinavammaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7143,
                "Name": "aadarimpave saadaramuna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amruthavarshini",
                "Thalam": "Roopakam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7144,
                "Name": "dharmavathi ninne kori",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7145,
                "Name": "challaga naatho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapanthuvarali",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7146,
                "Name": "talli sree amruthavalli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7147,
                "Name": "talli sree amruthavalli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7148,
                "Name": "sree mani sathi mani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Roopakam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7149,
                "Name": "mudhdhulu kulikaE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7150,
                "Name": "kOri vinuthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7151,
                "Name": "vande sadhaa janani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7152,
                "Name": "sree minchu simhaachala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7153,
                "Name": "raaga thaalaadhi ranjani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Thalamalika",
                "Praise of": "Amruthavalli Thayar Lakshmi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7154,
                "Name": "gaadhiju yaagamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7155,
                "Name": "raa raa raajeeva nayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7156,
                "Name": "nirupama dordaNDamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7157,
                "Name": "vaariruha nayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7158,
                "Name": "varithaapa mulanu theerchi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7159,
                "Name": "okapari thOshimpa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7160,
                "Name": "yetha vuna thaerchithivo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7161,
                "Name": "satsangha mosangumaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowri Manohari",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7162,
                "Name": "yediraa needhayaleduraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7163,
                "Name": "kausthubha manimaya haara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7164,
                "Name": "raamaa ninne namminaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7165,
                "Name": "aadarincharaa raamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7166,
                "Name": "nimishamOrva galana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7167,
                "Name": "akshaya phalameeyave",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7168,
                "Name": "raamaa raghuvamsha jaladhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7169,
                "Name": "mechakaanga sree ranga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7170,
                "Name": "maanasa sadanamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7171,
                "Name": "sangeetha rasaasvaadana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7172,
                "Name": "gatakalmashu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7173,
                "Name": "ravikula jalanidhi soma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7174,
                "Name": "seethaapathi neeve gathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desh",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7175,
                "Name": "shathakoti maara roopamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7176,
                "Name": "yelaagu thaaLuduraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7177,
                "Name": "indheevarashyaamaa raamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7178,
                "Name": "chelimithO neeve",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Triputa",
                "Praise of": "Rama",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7179,
                "Name": "ninne nammi naanura",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7180,
                "Name": "sativere varura",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7181,
                "Name": "ashtami brothuna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7182,
                "Name": "alasata dheeraga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7183,
                "Name": "atukulavale nenchi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7184,
                "Name": "nanda yashoda nandana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7185,
                "Name": "vande sadaa maadhavam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowri Manohari",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7186,
                "Name": "kanukoNtiraa krishna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7187,
                "Name": "yemaina ninanDinaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7188,
                "Name": "nera menchaka nannu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Triputa",
                "Praise of": "Sathyanarayanar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7189,
                "Name": "sathyanaaraayana swami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Praise of": "Sathyanarayanar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7190,
                "Name": "sathyamaina devudevvade",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Praise of": "Sathyanarayanar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7191,
                "Name": "deva devam jagatprabhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Praise of": "Sathyanarayanar",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7192,
                "Name": "bhaagavathaagresarulatho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7193,
                "Name": "ennagaanu nee paadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7194,
                "Name": "kalumuvagu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Khanda Chapu",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7195,
                "Name": "tridasheshvara sannutha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7196,
                "Name": "nigama vinutha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7197,
                "Name": "deva deva mahaanubhaava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chandrakouns",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7198,
                "Name": "Jnaana bhikshamosagi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7199,
                "Name": "abhaya pradaana deeksha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7200,
                "Name": "kaamakoti sundaraanguni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mandari",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7201,
                "Name": "karuna chooda manau raadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7202,
                "Name": "hari naama smarane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7203,
                "Name": "kori vachchina vaari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7204,
                "Name": "yelaa nee daya raadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddhaseemanthini",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7205,
                "Name": "saundarya jita maara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mandari",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7206,
                "Name": "paramaaNu vulayanthu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7207,
                "Name": "andarivale",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7208,
                "Name": "varadaa ninne",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7209,
                "Name": "daridaapu lekha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaishnava Kshetra Krithis",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7210,
                "Name": "neela komaLa divya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Ayyappan",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7211,
                "Name": "maayaamoha bhayaadula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7212,
                "Name": "vega thODI deve",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7213,
                "Name": "saambhashivaa jaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7214,
                "Name": "amba madamba tri jagadamba",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Chapu",
                "Praise of": "Parvathi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7215,
                "Name": "amba dayajudave",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7216,
                "Name": "trilochana kudumbini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Mishra Chapu",
                "Praise of": "Parvathi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7217,
                "Name": "umaa bhavaani sarvaani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7218,
                "Name": "aarogyam bosagi kaavavae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7219,
                "Name": "hari sanikaad yaksha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Khanda Chapu",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7220,
                "Name": "kaavave sri raamudu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7221,
                "Name": "ranjana aga bhanjana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7222,
                "Name": "pradakshina namaskara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malahari",
                "Thalam": "Roopakam",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7223,
                "Name": "kaapinaayaka nee kripa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7224,
                "Name": "shokha roga bhayamula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7225,
                "Name": "nava vyaakarana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7226,
                "Name": "pingalaaksha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7227,
                "Name": "kolu vichchi kaavave",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7228,
                "Name": "sri yoga anjaneyaswami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Roopakam",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7229,
                "Name": "pavana tanaya ninnu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7230,
                "Name": "mannimpave hanuma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Roopakam",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7231,
                "Name": "parimita magu moorthy",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Roopakam",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7232,
                "Name": "neevale kaaryamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7233,
                "Name": "vara dayaasaagara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Khanda Chapu",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7234,
                "Name": "sangeetha shaastra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Roopakam",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7235,
                "Name": "srutajana paridosha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Roopakam",
                "Praise of": "Hanuman",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7236,
                "Name": "bhajare guru naatham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Adi Sankara",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7237,
                "Name": "vande sada shankaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Praise of": "Adi Sankara",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7238,
                "Name": "paalaya pari paalaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Praise of": "Adi Sankara",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7239,
                "Name": "Shivaguru tanayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Praise of": "Adi Sankara",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7240,
                "Name": "bhava taapa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "Adi Sankara",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7241,
                "Name": "mangalamaNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Roopakam",
                "Praise of": "Kanchi Periyava",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7242,
                "Name": "jaya jaya sankara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Praise of": "Kanchi Periyava",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7243,
                "Name": "padaambujamunu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Roopakam",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7244,
                "Name": "guru padasmaraname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7245,
                "Name": "dinakara vamsha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7246,
                "Name": "gaanaamrutamunu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7247,
                "Name": "varakavi sri tyagaraja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7248,
                "Name": "geethaa shaastra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7249,
                "Name": "kalinarulanugaava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7250,
                "Name": "ambu janaabhuni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7251,
                "Name": "paamara hitamuga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7252,
                "Name": "swaami paadapooja seya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Roopakam",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7253,
                "Name": "guru pada smaraname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7254,
                "Name": "naamasudhaarasa paanamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7255,
                "Name": "tyaagaguru nee saati",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Roopakam",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7256,
                "Name": "sadguru sri tyaagavibhuni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Roopakam",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7257,
                "Name": "nammivachchina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7258,
                "Name": "bhajana seyave manasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Roopakam",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7259,
                "Name": "navarasayuta bhaavamuto",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Roopakam",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7260,
                "Name": "gaanasudhaarasa paanamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7261,
                "Name": "samaanu levarayyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7262,
                "Name": "naada sudhaa rasa paanamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7263,
                "Name": "atade dhanyuduraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7264,
                "Name": "paluvidha keerthana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7265,
                "Name": "pancharatnamula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Jhampai",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7266,
                "Name": "raaga ratna maalikache raamuni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7267,
                "Name": "needaya naapai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7268,
                "Name": "kritini chesene",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Roopakam",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7269,
                "Name": "arthamune narulu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7270,
                "Name": "pedalalo kucheludavu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7271,
                "Name": "eemaata laadina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7272,
                "Name": "raamabhakthi saamraajya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Roopakam",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7273,
                "Name": "Sri tyaagaraajaswaamini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Praise of": "Tyagaraja Swami",
                "Composer": "KM Soundaryavalli",
                "Group": "Sri Tyagaraja Guru Stuti Keerthanaigal",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7274,
                "Name": "charamule charanamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Group": "Vaggeyakara Vandanam",
                "Song Language": "Telugu",
                "BookTitle": "Soundaryavallari (Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7275,
                "Name": "Sahaja maarg nilayai thedu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7276,
                "Name": "panamirukkavaendaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7277,
                "Name": "gurupadham thunai namkke",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7278,
                "Name": "sol seyal sindhanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7279,
                "Name": "aasaiyenum paasam aravaendum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7280,
                "Name": "santhatham sindhithiru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7281,
                "Name": "paadiya padi manampadiyume",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chandrika",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7282,
                "Name": "maravaanilai vara vaendum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapanthuvarali",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7283,
                "Name": "malar solaiyaik kandu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7284,
                "Name": "kasindhurugum nilai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7285,
                "Name": "deivanilayai tharum deivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7286,
                "Name": "Guruvin arul irukka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7287,
                "Name": "arulozhi manadil paravidum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SunadaRanjani",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7288,
                "Name": "kavalaigal marakkum marundhirukku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7289,
                "Name": "anbu karam kondu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7290,
                "Name": "guruvarul yenum kani kidaithadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7291,
                "Name": "Vazhikaatiyaai vandhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7292,
                "Name": "angingenaadhavidhan engum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7293,
                "Name": "parindhu nin pugazh",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7294,
                "Name": "maargamum neeye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7295,
                "Name": "chitham theLindhida vazhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7296,
                "Name": "thaayaaga neeyaagi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Khanda Chapu",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7297,
                "Name": "vara venum guruve",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7298,
                "Name": "iraiyunarvu perudhal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7299,
                "Name": "sahaja maarga vidhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7300,
                "Name": "manadhinul irukkum guruvai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7301,
                "Name": "undhan karunaikkor ellai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Tisra Ekam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7302,
                "Name": "anbumigu gurvin aksharamalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7303,
                "Name": "anbumigu guruvin kakaaramaalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Ekam (Khanda Jathi)",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7304,
                "Name": "kadhiravan yezhum munnam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Mishra Chapu",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7305,
                "Name": "maaster bhodhanaigal maravadhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7306,
                "Name": "kolaata maaduvom",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7307,
                "Name": "paarinil sirandha vazhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Tisra Ekam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7308,
                "Name": "Anbu miga kondu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Tamil",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7309,
                "Name": "sahaja maargame mahasulabha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7310,
                "Name": "Sahaja maarga vidhula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7311,
                "Name": "Sree paarthasaarathi guro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7312,
                "Name": "Bhavasaagrambhulo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupalam",
                "Thalam": "Khanda Chapu",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7313,
                "Name": "Raagamutho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7314,
                "Name": "Neelo veluge",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7315,
                "Name": "Abhyaasigaa cherave",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7316,
                "Name": "Manchi Samayamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7317,
                "Name": "Guru pada Chintaname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Brindavani",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7318,
                "Name": "Sathyabhagu Lakshyamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Revathi",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7319,
                "Name": "Bhaavanaa param paralaku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7320,
                "Name": "Aalamsimpaga ",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7321,
                "Name": "Vaachaama gocharame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7322,
                "Name": "Naadani brahmasevu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KalyanaVasantham",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7323,
                "Name": "Vairulani Virodhinchake",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7324,
                "Name": "Guru pada paraagamaina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7325,
                "Name": "manadigadaa sahaja maargamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7326,
                "Name": "Bhaaramu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Roopakam",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7327,
                "Name": "apaaramaina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShivaRanjani",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7328,
                "Name": "Guru padame balamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7329,
                "Name": "Prabalamaina daari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7330,
                "Name": "Ramma gurumoorthey",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7331,
                "Name": "gruhame thapovanamuga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7332,
                "Name": "antharaamalo vinthaga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7333,
                "Name": "gurudevuni ganave",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7334,
                "Name": "apaayamuleni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sunadavinodini",
                "Thalam": "Adi",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7335,
                "Name": "Udayamuna dhyaanimpa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Khanda Chapu",
                "Composer": "KM Soundaryavalli",
                "Song Language": "Telugu",
                "BookTitle": "Sahaja Marg (Tamil & Telugu Lyrical Compositions)",
                "BookAuthor": "KM Soundaryavalli",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7336,
                "Name": "mayam yedho swami",
                "Type": "Varnam-Pada",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7337,
                "Name": "neeye pedhai mugam",
                "Type": "Varnam-Pada",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7338,
                "Name": "ganapathiye karunanidhiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7339,
                "Name": "Sharavana bhava guhane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7340,
                "Name": "thaNikesanai panindhu paada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7341,
                "Name": "thiruparam kundra vela",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7342,
                "Name": "neeye sharan shanmuga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7343,
                "Name": "baala subramanya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7344,
                "Name": "vaani arul purivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Saraswathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7345,
                "Name": "saraswathi dhayai nidhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Praise of": "Saraswathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7346,
                "Name": "Mahalakshmi jagan matha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Praise of": "Lakshmi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7347,
                "Name": "nee irangayenil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Lakshmi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7348,
                "Name": "Durga Lakshmi Saraswathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Praise of": "Durga/Lakshmi/Saraswathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7349,
                "Name": "akilanda nayaki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7350,
                "Name": "ennalavil siridhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7351,
                "Name": "enna seidhaalum endhan thunai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7352,
                "Name": "karpagavalliyin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Praise of": "Mylapore Karpagambal",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7353,
                "Name": "kaanthimathi annai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Mishra Chapu",
                "Praise of": "Tirunelveli Kanthiamman",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7354,
                "Name": "Kaamakshi Gowri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "Kanchi Kamakshi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7355,
                "Name": "thava charanau mama charanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Praise of": "Chamundeshwari",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7356,
                "Name": "devi nin vadivazhagai parugi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7357,
                "Name": "paradevathe padam nambinen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7358,
                "Name": "yaarammaa nee kadaikkan paaramaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7359,
                "Name": "anjael endre aaNDarulvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7360,
                "Name": "adimai ennai aaNDavanE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7361,
                "Name": "aadiya paadhathai darisikha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7362,
                "Name": "aaNDavan anbe shakthi tharum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShivaRanjani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7363,
                "Name": "idaiyaraadhu un malaradi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7364,
                "Name": "unai nambi yemaandhaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7365,
                "Name": "unnai bhajikka varam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7366,
                "Name": "ayyan thiruvadiyai vidamaatean",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7367,
                "Name": "kalpa kaambikaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Praise of": "Mylapore Kapaleeshwarar",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7368,
                "Name": "kaamaanthakaa nin kazhalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7369,
                "Name": "kapaali irukka veeN",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Khanda Jathi Jhampa Thalam",
                "Praise of": "Mylapore Kapaleeshwarar",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7370,
                "Name": "thannai bhajikkalaagaadhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Mylapore Kapaleeshwarar",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7371,
                "Name": "Dhikku verillai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Praise of": "Mylapore Kapaleeshwarar",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7372,
                "Name": "nambum anbaraik kaatharul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7373,
                "Name": "pada malarinai paNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7374,
                "Name": "padam nambinen shankara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7375,
                "Name": "padhi padham panivadhu nam kadan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7376,
                "Name": "paras paaNi pashupathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7377,
                "Name": "paaraamugam thagumO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7378,
                "Name": "baalanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7379,
                "Name": "visaalakshi naayakan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7380,
                "Name": "soundarya veLLam thanil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Mylapore Kapaleeshwarar",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7381,
                "Name": "avaang manasa Gocharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7382,
                "Name": "kailaasa vaasa sarvesa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7383,
                "Name": "Sree vishwanatham bhaje",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7384,
                "Name": "karpagavalli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7385,
                "Name": "bhajanai seivom vaareer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natabhairavi",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 3",
                "BookAuthor": "Papanasam Sivan/Rukmini Ramani",
                "Year of Publication": 1983,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7386,
                "Name": "Intha parakela",
                "Type": "Varnam-Tana",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Calcutta KS Krishnamurthy",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Calcutta KS Krishnamurthy",
                "BookAuthor": "KN Shashikiran, S. Sowmya",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7387,
                "Name": "Ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Malavi",
                "Thalam": "Adi",
                "Composer": "Calcutta KS Krishnamurthy",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Calcutta KS Krishnamurthy",
                "BookAuthor": "KN Shashikiran, S. Sowmya",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7388,
                "Name": "Saranagata vatsale",
                "Type": "Varnam-Tana",
                "Ragam": "Kadanakuthuhalam",
                "Thalam": "Adi",
                "Composer": "Calcutta KS Krishnamurthy",
                "Song Language": "Sanskrit",
                "BookTitle": "Compositions of Calcutta KS Krishnamurthy",
                "BookAuthor": "KN Shashikiran, S. Sowmya",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7389,
                "Name": "Mandara giridhara",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Calcutta KS Krishnamurthy",
                "Group": "Graha Swara Bheda Varnam",
                "Song Language": "Sanskrit",
                "BookTitle": "Compositions of Calcutta KS Krishnamurthy",
                "BookAuthor": "KN Shashikiran, S. Sowmya",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7390,
                "Name": "sakala vidya varidhim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Calcutta KS Krishnamurthy",
                "Song Language": "Sanskrit",
                "BookTitle": "Compositions of Calcutta KS Krishnamurthy",
                "BookAuthor": "KN Shashikiran, S. Sowmya",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7391,
                "Name": "nijamugani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShivaRanjani",
                "Thalam": "Adi",
                "Composer": "Calcutta KS Krishnamurthy",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Calcutta KS Krishnamurthy",
                "BookAuthor": "KN Shashikiran, S. Sowmya",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7392,
                "Name": "Nee sannidhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Calcutta KS Krishnamurthy",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Calcutta KS Krishnamurthy",
                "BookAuthor": "KN Shashikiran, S. Sowmya",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7393,
                "Name": "namida davayunchi",
                "Type": "Varnam-Tana",
                "Ragam": "Janaranjani",
                "Thalam": "Adi",
                "Composer": "Calcutta KS Krishnamurthy",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Calcutta KS Krishnamurthy",
                "BookAuthor": "KN Shashikiran, S. Sowmya",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7394,
                "Name": "Thillana-Bhimplas",
                "Type": "Thillana",
                "Ragam": "Bhimplas",
                "Thalam": "Khanda Chapu",
                "Composer": "Calcutta KS Krishnamurthy",
                "Song Language": "Sanskrit",
                "BookTitle": "Compositions of Calcutta KS Krishnamurthy",
                "BookAuthor": "KN Shashikiran, S. Sowmya",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7395,
                "Name": "Thillana-Hamsanandi",
                "Type": "Thillana",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Calcutta KS Krishnamurthy",
                "Song Language": "Sanskrit",
                "BookTitle": "Compositions of Calcutta KS Krishnamurthy",
                "BookAuthor": "KN Shashikiran, S. Sowmya",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7396,
                "Name": "Thillana-BrindavanaSaranga",
                "Type": "Thillana",
                "Ragam": "BrindavanaSaranga",
                "Thalam": "Adi",
                "Composer": "Calcutta KS Krishnamurthy",
                "Song Language": "Sanskrit",
                "BookTitle": "Compositions of Calcutta KS Krishnamurthy",
                "BookAuthor": "KN Shashikiran, S. Sowmya",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7397,
                "Name": "kavave karuna rasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Composer": "Calcutta KS Krishnamurthy",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Calcutta KS Krishnamurthy",
                "BookAuthor": "KN Shashikiran, S. Sowmya",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7398,
                "Name": "Sari gada ninnu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapanthuvarali",
                "Thalam": "Adi",
                "Composer": "Calcutta KS Krishnamurthy",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Calcutta KS Krishnamurthy",
                "BookAuthor": "KN Shashikiran, S. Sowmya",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7399,
                "Name": "sarinike galada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Composer": "Calcutta KS Krishnamurthy",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Calcutta KS Krishnamurthy",
                "BookAuthor": "KN Shashikiran, S. Sowmya",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7400,
                "Name": "muruganai tirumal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Calcutta KS Krishnamurthy",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Calcutta KS Krishnamurthy",
                "BookAuthor": "KN Shashikiran, S. Sowmya",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7401,
                "Name": "aaraNaporuLe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Praise of": "Ganesha",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7402,
                "Name": "saarasadaLanayane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7403,
                "Name": "kandaa kumaraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7404,
                "Name": "Kaa maal maruga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7405,
                "Name": "velavane guhane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7406,
                "Name": "thandanaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7407,
                "Name": "kaiyudan kootivaadi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natabhairavi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7408,
                "Name": "Sharavana bhava guhane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7409,
                "Name": "Dheerashikhamaniye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "UdhayaRaviChandrika",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7410,
                "Name": "Sharavana bhava guhane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malkauns",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7411,
                "Name": "periyoralikum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7412,
                "Name": "santhatham kandanathanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Deshadi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7413,
                "Name": "Padandhanile",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7414,
                "Name": "Sharavana Bhavaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "DayaaRanjani",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7415,
                "Name": "Kaavaai karuna nidhiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7416,
                "Name": "Soorasamhaara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7417,
                "Name": "Aiyyane pugazh",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7418,
                "Name": "kaividalaagaadaiyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7419,
                "Name": "guhane unakku gathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapanthuvarali",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7420,
                "Name": "shanmugan paadham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7421,
                "Name": "maamayileri nee vaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7422,
                "Name": "mayilvaahana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amruthavarshini",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7423,
                "Name": "varam thandharul vaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7424,
                "Name": "Kaarthikeyanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7425,
                "Name": "kadhirkaamadevar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7426,
                "Name": "kaantham izhukkudhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7427,
                "Name": "kaNdean kaNdean",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7428,
                "Name": "sivanukku iLaiya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7429,
                "Name": "kaantham endru orukani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maanji",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7430,
                "Name": "kaanthamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desiya Thodi",
                "Thalam": "Adi (Tisra Gathi)",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7431,
                "Name": "uLLathinidhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7432,
                "Name": "aarumugam oru saami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desiya Chenchurutti",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7433,
                "Name": "akila jagathaanandha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Ekam (Khanda Jathi)",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7434,
                "Name": "kadhirakaama kandhane gathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7435,
                "Name": "Maamayil veera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desiya Thodi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7436,
                "Name": "Chindhaiyaen",
                "Type": "Padam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7437,
                "Name": "varugiraaro",
                "Type": "Padam",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7438,
                "Name": "netru varaenendru",
                "Type": "Padam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7439,
                "Name": "yenaiyya umakkindha",
                "Type": "Padam",
                "Ragam": "Kalyani",
                "Thalam": "Triputa",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7440,
                "Name": "azhaithu vaadi",
                "Type": "Padam",
                "Ragam": "Darbar",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7441,
                "Name": "sevalane sendhoor",
                "Type": "Padam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7442,
                "Name": "vaa vaa velmurugaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desiya Thodi",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7443,
                "Name": "raajaadhi raaja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desiya Thodi",
                "Thalam": "Ekam (Tisra Gathi)",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7444,
                "Name": "puLLimayileri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7445,
                "Name": "Anjel! Anjel!",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7446,
                "Name": "Jaalame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7447,
                "Name": "thanjam endru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7448,
                "Name": "ummai orukaalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7449,
                "Name": "Aadumayile",
                "Type": "Kriti/Keerthanam",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7450,
                "Name": "nenje muruganai naadu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desiya Thodi",
                "Thalam": "Khanda Chapu",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7451,
                "Name": "arunmigave",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Huseni, Desh)",
                "Thalam": "Adi (Tisra Gathi)",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai - Uyir yezhuthu varga maalika",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7452,
                "Name": "kundrakkudiyil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7453,
                "Name": "thiruvadithaamarai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yaman",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7454,
                "Name": "Kolamayil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindustani Behag",
                "Thalam": "Ekam",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7455,
                "Name": "kumaradeva kadavuLe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7456,
                "Name": "vadivelaa undanaiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7457,
                "Name": "Singaarane Shanmugane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7458,
                "Name": "vaLLImaNavaaLaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7459,
                "Name": "vada pazhaniyil vaazhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Adi (Tisra Gathi)",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7460,
                "Name": "paaraalum punnyanukku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalitha",
                "Thalam": "Jhampai",
                "Praise of": "Murugan",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Murugan Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7461,
                "Name": "sirparane harane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Shiva Shakthi Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7462,
                "Name": "anudina maheshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Roopakam",
                "Praise of": "Parvathi",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Shiva Shakthi Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7463,
                "Name": "Maname nee mayangaadhae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Gnana Maargam",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7464,
                "Name": "adakkamuLor miga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Gnana Maargam",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7465,
                "Name": "arindhiduvaai nangu idhai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Gnana Maargam",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7466,
                "Name": "ellaam sri hari mayame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Sri Hari Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7467,
                "Name": "Devaadhi Devar pugazh",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi (Tisra Gathi)",
                "Praise of": "Krishna",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Sri Hari Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7468,
                "Name": "Raaghavanai paniye maname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Sri Hari Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7469,
                "Name": "Dheenanai dhayai purindhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desh",
                "Thalam": "Roopakam",
                "Praise of": "Krishna",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Sri Hari Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7470,
                "Name": "sampathe sugamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Sri Hari Madura Keerthanai",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7471,
                "Name": "pralayabhayodhiyil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Mayuram TR Vishwanatha Shastry",
                "Group": "Sri Hari Madura Keerthanai - Dasavathara Ragamalika based on Jayadeva's \"Pralayabha yothi jale\"",
                "Song Language": "Tamil",
                "BookTitle": "Murugan Madura Keerthanai",
                "BookAuthor": "Mayuram TR Viswanatha Shastry",
                "Year of Publication": 1940,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7472,
                "Name": "Sri Chandrasekara yatheendram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Triputa",
                "Praise of": "Sri Chasekarendra Saraswathi Swami of Kanchi Kamakoti Mutt",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7473,
                "Name": "Srimadh Abhinava Vidyaa theertha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Sri Abhinava Vidya theertha swamigal of Sringeri Mutt",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7474,
                "Name": "Sankara gurvara mahimaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Praise of": "Sri Narasimha Bharathi Swamigal of Sringeri Mutt",
                "Composer": "Sri Vaidhyanatha Shastry/Sri Nemam Nataraja Bhagavathar",
                "Group": "Sri Sadguru Stotra Ratna maalika (Sri Sringeri Jagad guru mahaa sannidhanam Sri Sri Sachchidaananda Shivaabhinava Narasimha Bhaarathi Swamigal thudhi)",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7475,
                "Name": "Thyagaraja guru naatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Roopakam",
                "Praise of": "Tyagaraja Swami",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7476,
                "Name": "Spurathuthe roopam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Sri Sacchidananda Shivabhinava Swamigal of Sringeri Mutt/Sri Nemam Natarja Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7477,
                "Name": "Gananaayaka vaarana mukham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sunadavinodini",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7478,
                "Name": "Mayile poompozhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GuhaManohari",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7479,
                "Name": "tharuNi ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "NS Ramachandran",
                "Song Language": "Telugu",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7480,
                "Name": "padarethi mera kaadhuraa",
                "Type": "Varnam-Tana",
                "Ragam": "DevaGandhari",
                "Thalam": "Ata",
                "Composer": "NS Ramachandran",
                "Song Language": "Telugu",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7481,
                "Name": "Sri maha ganapathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Jhampai",
                "Praise of": "Ganesha",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7482,
                "Name": "Saraswathi namousthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Roopakam",
                "Praise of": "Saraswathi",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7483,
                "Name": "Aanantha natana sundara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7484,
                "Name": "Sri lalithe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Roopakam",
                "Praise of": "Parvathi",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7485,
                "Name": "kumaara guha paahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7486,
                "Name": "kalpakaambike janani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijayavasantham",
                "Thalam": "Roopakam",
                "Praise of": "Mylai Karpagambal",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7487,
                "Name": "Sri Shaaradaambike namasthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Khanda Triputa",
                "Praise of": "Sringeri Sharadambal",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7488,
                "Name": "Mahaa deva manohari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Roopakam",
                "Praise of": "Parvathi",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7489,
                "Name": "karunaa nidhi neeye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7490,
                "Name": "indha bhaaramaa paalimpa nee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ManoRanjani",
                "Thalam": "Adi",
                "Composer": "NS Ramachandran",
                "Song Language": "Telugu",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7491,
                "Name": "kumaravadi velane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7492,
                "Name": "Dina naatha kula kala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suryakantham",
                "Thalam": "Adi",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7493,
                "Name": "japaakusuma Bhaasuraam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "PrathapaVarali",
                "Thalam": "Roopakam",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7494,
                "Name": "vageeshwari ini nee arulpuriya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Adi",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7495,
                "Name": "Sri jaanaki manohara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7496,
                "Name": "vaarana mukhane thunai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Roopakam",
                "Praise of": "Ganesha",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7497,
                "Name": "neerajaakshi neeve gathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Praise of": "Parvathi",
                "Composer": "NS Ramachandran",
                "Song Language": "Telugu",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7498,
                "Name": "innamum dhayavu varaadaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7499,
                "Name": "yemo dhaya raadhu neeku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kalanidhi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "NS Ramachandran",
                "Song Language": "Telugu",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7500,
                "Name": "bruhan naayakeem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Triputa",
                "Praise of": "Tanjai Bruhadeeshwari",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7501,
                "Name": "Chidambaram endroru dharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Natarajar",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7502,
                "Name": "akilaanda naayaki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7503,
                "Name": "Chandra sootamaNe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SinduRamakriya",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7504,
                "Name": "SharavaNa bhava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7505,
                "Name": "mahaa tripura sundari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7506,
                "Name": "Sri Raghukula tilaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "DevaGandhari",
                "Thalam": "Adi",
                "Praise of": "Rama",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7507,
                "Name": "kamalaalaye paahi kamale",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Praise of": "Kamalambha",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7508,
                "Name": "Sri shaarade",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Praise of": "Sringeri Sharadambal",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7509,
                "Name": "chandra kalaadhara maam paripaalaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7510,
                "Name": "vinaayakam bhaja maanasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi Manohari",
                "Thalam": "Roopakam",
                "Praise of": "Ganesha",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7511,
                "Name": "Sri Raaja Rajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7512,
                "Name": "Karunaa jala nidhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Roopakam",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7513,
                "Name": "lalithaambhike",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Praise of": "Lalithambal",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7514,
                "Name": "kamalaayadaakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "Kamalambha",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7515,
                "Name": "sadhaya naapai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Roopakam",
                "Praise of": "Rama",
                "Composer": "NS Ramachandran",
                "Song Language": "Telugu",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7516,
                "Name": "vaachaama gochara vaibhavam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShadvidaMargini",
                "Thalam": "Khanda Triputa",
                "Praise of": "Murugan",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7517,
                "Name": "karunaapayo nidhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda Chapu",
                "Praise of": "Parvathi",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7518,
                "Name": "veru thunai illaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7519,
                "Name": "maname ninai adhu dhiname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7520,
                "Name": "guhane sharavana bhava shanmuga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7521,
                "Name": "kandhanai ninaindhu manam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanadam",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7522,
                "Name": "Thillana-Dayavathi",
                "Type": "Thillana",
                "Ragam": "Dayaavathi",
                "Thalam": "Khanda Triputa",
                "Composer": "NS Ramachandran",
                "Song Language": "Sanskrit",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7523,
                "Name": "poompozhil meviya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Praise of": "Krishna",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7524,
                "Name": "ambalam aadiya deivame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "Natarajar",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Sangeetha Pushpanjali - Part 1",
                "BookAuthor": "NS Ramachandran",
                "Year of Publication": 2003,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7525,
                "Name": "Mallari",
                "Type": "Mallari",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7526,
                "Name": "ThoDayamangalam",
                "Type": "ThodayaMangalam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7527,
                "Name": "Ranga Anjali",
                "Ragam": "Ragamalika",
                "Thalam": "Thalamalika",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7528,
                "Name": "Jathiswaram-Abhogi",
                "Type": "Jathiswaram",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7529,
                "Name": "Jathiswaram-Varali",
                "Type": "Jathiswaram",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7530,
                "Name": "Jathiswaram-Charukesi",
                "Type": "Jathiswaram",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7531,
                "Name": "Jathiswaram-SriRanjani",
                "Type": "Jathiswaram",
                "Ragam": "SriRanjani",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7532,
                "Name": "Jathiswaram-Shuddha Dhanyasi",
                "Type": "Jathiswaram",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Tisra Ekam",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7533,
                "Name": "Jathiswaram-Shuddha Saveri",
                "Type": "Jathiswaram",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7534,
                "Name": "Jathiswaram-Begada",
                "Type": "Jathiswaram",
                "Ragam": "Begada",
                "Thalam": "Mishra Chapu",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7535,
                "Name": "Jathiswaram-PoorviKalyani",
                "Type": "Jathiswaram",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7536,
                "Name": "Jathiswaram-Rasali",
                "Type": "Jathiswaram",
                "Ragam": "Rasali",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7537,
                "Name": "Jathiswaram-Lathangi",
                "Type": "Jathiswaram",
                "Ragam": "Lathangi",
                "Thalam": "Tisra Ekam",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7538,
                "Name": "Devi thaayae",
                "Type": "Shabdam",
                "Ragam": "Ragamalika",
                "Thalam": "Mishra Chapu",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7539,
                "Name": "Maayae Maayan",
                "Type": "Varnam-Pada",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7540,
                "Name": "engum niraindha",
                "Type": "Varnam-Pada",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7541,
                "Name": "Amba akilandeshwari",
                "Type": "Varnam-Pada",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7542,
                "Name": "Thirumalai vaasa",
                "Type": "Varnam-Pada",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7543,
                "Name": "kOlamayil vaahanane",
                "Type": "Varnam-Pada",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7544,
                "Name": "aadhaaram neeye",
                "Type": "Varnam-Pada",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7545,
                "Name": "Durge Durge",
                "Type": "Padam",
                "Ragam": "Revathi",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7546,
                "Name": "krishnaa radhaa krishnaa",
                "Type": "Padam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7547,
                "Name": "varuga varuga vendru",
                "Type": "Padam",
                "Ragam": "Sumanesa Ranjani",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7548,
                "Name": "kaNNaa manivaNNa",
                "Type": "Padam",
                "Ragam": "Khamas",
                "Thalam": "Tisra Ekam",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7549,
                "Name": "eeswara pateeswara",
                "Type": "Padam",
                "Ragam": "Amruthavarshini",
                "Thalam": "Tisra Ekam",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7550,
                "Name": "Thillana-Simhendramadhyamam",
                "Type": "Thillana",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7551,
                "Name": "Thillana-Kannadaa",
                "Type": "Thillana",
                "Ragam": "Kannadaa",
                "Thalam": "Khanda Ekam",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7552,
                "Name": "Thillana-Revathi",
                "Type": "Thillana",
                "Ragam": "Revathi",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7553,
                "Name": "Thillana-Valachi",
                "Type": "Thillana",
                "Ragam": "Valachi",
                "Thalam": "Mishra Chapu",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7554,
                "Name": "Thillana-Mohanam",
                "Type": "Thillana",
                "Ragam": "Mohanam",
                "Thalam": "Tisra Ekam",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7555,
                "Name": "Thillana-Amruthavarshini",
                "Type": "Thillana",
                "Ragam": "Amruthavarshini",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7556,
                "Name": "Thillana-Chandrakauns",
                "Type": "Thillana",
                "Ragam": "Chandrakauns",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7557,
                "Name": "Thillana-Saraswathi Manohari",
                "Type": "Thillana",
                "Ragam": "Saraswathi Manohari",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7558,
                "Name": "Thillana-SindhuBhairavi",
                "Type": "Thillana",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7559,
                "Name": "Thillana-BrindavanaSaranga",
                "Type": "Thillana",
                "Ragam": "BrindavanaSaranga",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7560,
                "Name": "Thillana-Karnataka Devagandhari",
                "Type": "Thillana",
                "Ragam": "Karnataka Devagandhari",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7561,
                "Name": "Thillana-Hamsanadam",
                "Type": "Thillana",
                "Ragam": "Hamsanadam",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7562,
                "Name": "Thillana-Hindolam",
                "Type": "Thillana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Madurai N Krishnan/ S Rajeshwari",
                "Song Language": "Tamil",
                "BookTitle": "Madura Maargam (Compositions for Dance)",
                "BookAuthor": "Madurai N Krishnan/ S Rajeshwari",
                "Year of Publication": 1995,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7563,
                "Name": "Ninnai sharanadaindean",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kanakangi",
                "Thalam": "Mishra Chapu",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7564,
                "Name": "Pahi Pahi Parameshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rathnangi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7565,
                "Name": "Gana Roopini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ganamoorthi",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7566,
                "Name": "Paraathpari Parameshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vanaspathi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7567,
                "Name": "Manavathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manavathi",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7568,
                "Name": "Mayilerum Vadivelane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tanarupi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7569,
                "Name": "Pahi Parvavatha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Senavathi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7570,
                "Name": "Para Devathe Pahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hanumathodi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7571,
                "Name": "Gopaala maavadhu govarvardana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhenuka",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7572,
                "Name": "Sri Venkata Ramanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natakapriya",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7573,
                "Name": "Pankaja naabhamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokilapriya",
                "Thalam": "Mishra Chapu",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7574,
                "Name": "Saraseeruhaasana priye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Roopavathi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7575,
                "Name": "Sadaa Shivam Sankaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gayakapriya",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7576,
                "Name": "Madhuraa puri vaasini maamava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7577,
                "Name": "Shambho Mahadevam Shankaram Bhajami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7578,
                "Name": "Nithya nandakaram gopalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7579,
                "Name": "Paahimaam Shivakaameshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suryakantham",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7580,
                "Name": "Shiva kaameshwarim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hatakamberi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7581,
                "Name": "Nee dayai purivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jhankaradhwani",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7582,
                "Name": "Saravana bhavam upaasmake",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natabhairavi",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7583,
                "Name": "Vaani varamarul keervaani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Khanda Chapu",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7584,
                "Name": "Rangaraajam Bhajaamyaham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7585,
                "Name": "Kadaikkan paaramma gowri manohari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowri Manohari",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7586,
                "Name": "Mahalakshmi Namousthuthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varunapriya",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7587,
                "Name": "Neerajaakshi vimale nirmale",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MaraRanjani",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7588,
                "Name": "Thulaseema jaga jananeem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7589,
                "Name": "Kalayaami thava paadhukaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Khanda Chapu",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7590,
                "Name": "Saara saaksham sadaa Bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7591,
                "Name": "Shree Chakra peeta nilaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Mishra Chapu",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7592,
                "Name": "Dhaakshaayanee Dharmaambike",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NagaNandhini",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7593,
                "Name": "Vandhe Sadaaa shree padmanaabham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yagapriya",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7594,
                "Name": "Ragavardhini raajeeva lochane amba",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragavardhini",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7595,
                "Name": "Gaangeya bhushani subhashini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gangeyabhushani",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7596,
                "Name": "Maha ganapathim manasaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vagadeeshwari",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7597,
                "Name": "Kalaadharee Kaamakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sulini",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7598,
                "Name": "Bhaavayaami sathatham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ChalaNaatai",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7599,
                "Name": "Maamava Sadaa Devi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Salagam",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7600,
                "Name": "Venu gaana ramanam smaraami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jhalarnavam",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7601,
                "Name": "Kalayaami thava charanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jhalavarali",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7602,
                "Name": "Namaamyaham nandakumaaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaneetam",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7603,
                "Name": "Paavani Paahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pavani",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7604,
                "Name": "Raamachandran upaasmake",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Raghupriya",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7605,
                "Name": "Kaamakoti peetavaasini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gavambhodhi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7606,
                "Name": "Aanandha natana prakaashini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavapriya",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7607,
                "Name": "Devanaatham upaasmake",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapanthuvarali",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7608,
                "Name": "Kadambavana chaarineem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShadvidaMargini",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7609,
                "Name": "Varadharaajam bhajaamyaham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suvarnangi",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7610,
                "Name": "Bhaje Shyaamalam Shree Baalakrishnam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Divyamani",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7611,
                "Name": "Anna poorneshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhavalambari",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7612,
                "Name": "Sangeetha rasike Shyaamalaambike",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Namanarayani",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7613,
                "Name": "Sakala kala roopinim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7614,
                "Name": "Smaraamyaham aanjaneyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7615,
                "Name": "Natanamaadum pathiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gamanashrama",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7616,
                "Name": "Jagadeeshwaree rakshayamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vishwambari",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7617,
                "Name": "Chakra Raja Nilaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shyamalangi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7618,
                "Name": "Devi Meenalochani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7619,
                "Name": "Maamavathu sree",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7620,
                "Name": "Himagiri thanaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7621,
                "Name": "Dhaakshaayanee Sri Dharmavathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7622,
                "Name": "Kanakasaila vihaarini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neethimathi",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7623,
                "Name": "Kalaavathi kaanthaamani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kanthamani",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7624,
                "Name": "Chandrasekaram Sada Bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rishabhapriya",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7625,
                "Name": "Shaarade Samrakshathumaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7626,
                "Name": "Para Devathe paalayamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7627,
                "Name": "Nithya Kalyani paahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7628,
                "Name": "Vumaiyandri ulagedhamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chithrambari",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7629,
                "Name": "Maamava Sri Raghuraama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sucharitra",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7630,
                "Name": "Paataleeswaram bhajaame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jyothiswaroopini",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7631,
                "Name": "Chidambara pureeshwareem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhathuvardini",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7632,
                "Name": "Sadhaa Sivakumaaram Bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NasikaBhushani",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7633,
                "Name": "Raghu nandana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kosalam",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7634,
                "Name": "Rama Nama rasikapriye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rasikapriya",
                "Thalam": "Roopakam",
                "Composer": "Cuddalore M Subramaniam",
                "Group": "72 melakartha raga compositions by Cuddalore M Subramaniam",
                "Song Language": "Tamil",
                "BookTitle": "Krithis in 72 Melakartha Ragas",
                "BookAuthor": "Cuddalore M Subramaniam",
                "Year of Publication": 1982,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7635,
                "Name": "Amba Vindhyaachalavaasini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7636,
                "Name": "Amba Sree Raaja rajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BhogaVasantham",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7637,
                "Name": "Ambukaajshi Paahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SimhaVahini",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7638,
                "Name": "Umaam namaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7639,
                "Name": "O Jagadamba",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Narayana Deshakshi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7640,
                "Name": "Kamalambike Maamava Sada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Khanda Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7641,
                "Name": "Kaamaakshi Paahi maam Meenaakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShivaKhambhoji",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7642,
                "Name": "Kaamaakshi Paahi maam haraardhaangi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janaranjani",
                "Thalam": "Roopakam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7643,
                "Name": "Kaamaakshim Varalakshmeem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Roopakam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7644,
                "Name": "Kameshwareem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7645,
                "Name": "Gajaananam Ganapathim Bhaje",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Chatusra jathi Matya Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7646,
                "Name": "Gowri Manohari paahi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowri Manohari",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7647,
                "Name": "Gam Ganapathe Namasthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Durvanki",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7648,
                "Name": "Gam Ganapathe Sadaa Namasthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7649,
                "Name": "Geethapriyaa yogineem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Geethapriya",
                "Thalam": "Roopakam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7650,
                "Name": "Chaamundeshwari Paalaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rishabhapriya",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7651,
                "Name": "Chintaya Sadaa VaaraNavadanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sindhuramakriya",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7652,
                "Name": "Chintayami Jagadamba",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7653,
                "Name": "Chintayami Santhatham Moola",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhenuka",
                "Thalam": "Tisra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7654,
                "Name": "Chintayami Satatam hasthivadanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "VeeraVasantham",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7655,
                "Name": "Jaganmohinim Haramohinim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7656,
                "Name": "Jagatpathe Dakshinaamoorthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suryakantham",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7657,
                "Name": "Devi Sree Meenakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7658,
                "Name": "Durgadevi samrakshmaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7659,
                "Name": "Naagalinga maheshwaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Tisra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7660,
                "Name": "Naadaroopineem vandeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokilapanhamam",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7661,
                "Name": "Neelakantam Mahadevam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gamakakriya",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7662,
                "Name": "Padmanabham Bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7663,
                "Name": "Paripaahi maam mahaadevi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7664,
                "Name": "Paripaahi maam Sreevaralakshmi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapanthuvarali",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7665,
                "Name": "paripaahi maam sada paradevathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7666,
                "Name": "Paripaahi maam siddhi vinaayaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7667,
                "Name": "Pahi maam varalakshmi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gundakriya",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7668,
                "Name": "pahi maam sri pancha maatanga vadana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Supradeepam",
                "Thalam": "Sankeerna Jathi Triputa Thalam (Bhoga)",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7669,
                "Name": "Paahi maam Sree parameshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindola Darbar",
                "Thalam": "Khanda Jathi Roopaka Thalam (Raaja)",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7670,
                "Name": "Paahi maam Sree Raajarajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayavardhani",
                "Thalam": "Khanda Jathi Triputa Thalam (Dushkara)",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7671,
                "Name": "paahi maam satatam jagajanani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HindolaDesika",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7672,
                "Name": "Baalakrishnam Bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelaveni",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7673,
                "Name": "bale bruhat shrushti moole",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7674,
                "Name": "Bhaja Govindam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhupala Panchamam",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7675,
                "Name": "bhajare re bhaja manasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhanuchandrika",
                "Thalam": "Roopakam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7676,
                "Name": "bhajare re manasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poornachandrika",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7677,
                "Name": "bhuvaneshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhuvanagandhari",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7678,
                "Name": "bhuvaneshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MargaHindolam",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7679,
                "Name": "bhooribhaagyalahari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "prataapavarali",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7680,
                "Name": "bruhannayaki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nayaki",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7681,
                "Name": "bhairavam bhaavaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavam",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7682,
                "Name": "brahmaandavalaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7683,
                "Name": "manonmani maamavathu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "UdayaRavichandrika",
                "Thalam": "Chatusra Jathi Dhuruva Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7684,
                "Name": "mahaaganapathim bhaje",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amrithavahini",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7685,
                "Name": "Maha tripura sundari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Tisra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7686,
                "Name": "mahishaasuramardani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Namanarayani",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7687,
                "Name": "maatangakanyaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaThodi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7688,
                "Name": "maanayat manjula vaag vilaasam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokilapriya",
                "Thalam": "Tisra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7689,
                "Name": "mrutyunjayam bhaavaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragavardhini",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7690,
                "Name": "raajarajeshwareem ashraye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HindolaVasantham",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7691,
                "Name": "raajarajeshwareen bhaavayeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balachandrika",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7692,
                "Name": "Lambodhara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NarayanaGowlai",
                "Thalam": "Mishra Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7693,
                "Name": "Vande Jagadambike",
                "Type": "Kriti/Keerthanam",
                "Ragam": "VasanthaBhairavi",
                "Thalam": "Khanda Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7694,
                "Name": "Vandeham Shive",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kadanakuthuhalam",
                "Thalam": "Chatusra jathi Matya Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7695,
                "Name": "vandeham sadaa shaaradam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamsanaTani",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7696,
                "Name": "vaamadevam bhaavayeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vagadeeshwari",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7697,
                "Name": "vijayataam sri rajarajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vishwambari",
                "Thalam": "Mishra Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7698,
                "Name": "Vindhyaachalanivaasini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7699,
                "Name": "vimalaambike pari paahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijayavasantham",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7700,
                "Name": "Shankari sadaananda lahari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malavi",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7701,
                "Name": "Shiva Shiva Shiva Bho Mahadeva Shambho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7702,
                "Name": "Shree Gayathridevi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gamanashrama",
                "Thalam": "Chatusra Jathi Dhuruva Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7703,
                "Name": "Shree guru Dakshinamurthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavapriya",
                "Thalam": "Chatusra jathi Matya Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7704,
                "Name": "Shree Chakra poorvasineem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaLalitha",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7705,
                "Name": "Shree Chanmundeshwari Devi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7706,
                "Name": "Shree chamundeshwareem bhajare",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShadvidaMargini",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7707,
                "Name": "Shree chamundeshwareem bhaje ham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7708,
                "Name": "Shree Jaalandhara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GambhiraNaatai",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7709,
                "Name": "Shree Dakshinaamoorthey namousthuthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7710,
                "Name": "Shree naagalingam upaasmake",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7711,
                "Name": "Shree Mahaganapathim Bhajeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7712,
                "Name": "Sari mahaa tripura sundareem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BinduMalini",
                "Thalam": "Chatusra jathi Matya Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7713,
                "Name": "Shree Ranganaatha paahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KalyanaVasantham",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7714,
                "Name": "Shree raajarajeshwari ashrayami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalitha",
                "Thalam": "Roopakam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7715,
                "Name": "Shree raajarajeshwari shree vageeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Garudadhwani",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7716,
                "Name": "Shree raajarajeshwareem sada bhaje",
                "Type": "Kriti/Keerthanam",
                "Ragam": "DevaGandhari",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7717,
                "Name": "Shree lalithaam maha tripurasundareem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagadhwani",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7718,
                "Name": "Shree lalithe sree kanta hasahithe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7719,
                "Name": "Shree lingaraaja namousthuthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amrithavarshini",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7720,
                "Name": "Shree vidyaamodini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokilabhaashini",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7721,
                "Name": "Shree vidhyaam lalithaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NadaBrahmam",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7722,
                "Name": "Shree vidye paahi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalithapanchamam",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7723,
                "Name": "Sadaa Shivam Upaasmake",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7724,
                "Name": "Saraswathim bhagavathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsavinodini",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7725,
                "Name": "Saamodam Chintayaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7726,
                "Name": "Sugnaanadaayineem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSalavi",
                "Thalam": "Adi",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7727,
                "Name": "suranandineem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suranandini",
                "Thalam": "Triputa",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7728,
                "Name": "Suvarnaangi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suvarnangi",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7729,
                "Name": "Swaaminaatha paalayamaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7730,
                "Name": "Hatakeshwaram bhaje",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hatakamberi",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7731,
                "Name": "Ksheera saagara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Chatusra Jathi Dhuruva Thalam",
                "Composer": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Song Language": "English",
                "BookTitle": "Maharaja Jayachamaraja Wodeyar Compositions",
                "BookAuthor": "Mysore Maharaja Jayachamaraja Wodeyar",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7732,
                "Name": "Sharavana bhavane shanmugane",
                "Type": "Varnam-Tana",
                "Ragam": "Arabhi",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7733,
                "Name": "Saraswathi thaye sharanam",
                "Type": "Varnam-Tana",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7734,
                "Name": "Karunai neeye ",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7735,
                "Name": "Sree Ranganatha Thiruvadi sharanam",
                "Type": "Varnam-Tana",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7736,
                "Name": "Paarvathi Naayakaa",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Mishra Jathi Triputa Thalam",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7737,
                "Name": "Sree venkataachalapathaye",
                "Type": "Varnam-Tana",
                "Ragam": "Amruthavarshini",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7738,
                "Name": "paadaaravindam adaiye",
                "Type": "Varnam-Tana",
                "Ragam": "Neethimathi",
                "Thalam": "Sankeerna Jathi Triputa Thalam (Bhoga)",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7739,
                "Name": "thiruvenkatamutayaanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7740,
                "Name": "aanandha nata raajan thillaiyil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7741,
                "Name": "annaiye meenakshiye arul vaaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7742,
                "Name": "kanchi kaamaakshi karunai seivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7743,
                "Name": "Sree venkatesam sharanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7744,
                "Name": "jyothiswaroopini paripoorani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jyothiswaroopini",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7745,
                "Name": "thaaye dayai purindharul vaaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7746,
                "Name": "ninne nammithi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7747,
                "Name": "maa madurai meenakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7748,
                "Name": "Vandharul seivaai karpaga valli",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika (Andolika, Amruthavarshini, Hamsadhwani, Abhogi, Hindolam, Shuddha Saveri, Shuddha Dhanyasi, Mohanam, Madhyamavathi)",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Group": "Navaragamalika - Tana Varnam",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7749,
                "Name": "vandarul seivaai karpaga valli",
                "Type": "Varnam-Pada",
                "Ragam": "Ragamalika (Andolika, Amruthavarshini, Hamsadhwani, Abhogi, Hindolam, Shuddha Saveri, Shuddha Dhanyasi, Mohanam, Madhyamavathi)",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Group": "Navaragamalika - Pada Varnam",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7750,
                "Name": "maa madurai meenakshi",
                "Type": "Varnam-Pada",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7751,
                "Name": "Sree nivaasaa sharanam",
                "Type": "Varnam-Tana",
                "Ragam": "Saranga",
                "Thalam": "Adi (Khanda Gathi)",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7752,
                "Name": "Karunai seivaai karpagame",
                "Type": "Varnam-Tana",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi (Mishra Gathi)",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7753,
                "Name": "abiraamiye neeye endhan thaai",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi (Khanda Gathi)",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7754,
                "Name": "thaaye dayai purindharul vaaye",
                "Type": "Varnam-Tana",
                "Ragam": "Saranga",
                "Thalam": "Adi (Khanda Gathi)",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7755,
                "Name": "Sree guru saarva bowma",
                "Type": "Varnam-Tana",
                "Ragam": "Nalinakanthi",
                "Thalam": "Mishra Jathi Triputa Thalam",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7756,
                "Name": "Om ennum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7757,
                "Name": "Manthra balam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanadam",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7758,
                "Name": "sakala kalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Group": "Ashta Ragamalika",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7759,
                "Name": "Neeye Thunai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7760,
                "Name": "Bhuvana Giri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7761,
                "Name": "Kaana iru kann",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7762,
                "Name": "Yethanai daram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7763,
                "Name": "Maadhavaamadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7764,
                "Name": "Swaaminaathanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ranjani",
                "Thalam": "Mishra Chapu",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7765,
                "Name": "Sadaa Shivanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7766,
                "Name": "Karunai kadal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Mishra Chapu",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7767,
                "Name": "Kuramagal valli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7768,
                "Name": "Kannan varu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varamu/Shuddha Hindolam",
                "Thalam": "Khanda Chapu",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7769,
                "Name": "Vel murugan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Khanda Chapu",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7770,
                "Name": "unaiyindri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavani",
                "Thalam": "Khanda Chapu",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7771,
                "Name": "Jagadeeshwaree",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jagadeeshwari",
                "Thalam": "Khanda Chapu",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7772,
                "Name": "Kaarthyaayani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Karthyayani",
                "Thalam": "Khanda Chapu",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7773,
                "Name": "Thunbam theerkum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tilang",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7774,
                "Name": "kanchi munivarin",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tilang",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7775,
                "Name": "yezhumalai meedhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7776,
                "Name": "Annai meenakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desh",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7777,
                "Name": "karunai deivame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7778,
                "Name": "Kalaivaani thaaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rageshri",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7779,
                "Name": "enna punniyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapanthuvarali",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7780,
                "Name": "Sree raghavendra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tilang",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7781,
                "Name": "kanne en",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BrindavanaSaranga",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7782,
                "Name": "ninnai allaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7783,
                "Name": "aagaaya malaiyil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KalyanaVasantham",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7784,
                "Name": "Thillana-Kapi",
                "Type": "Thillana",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7785,
                "Name": "Thillana-SumanesaRanjani",
                "Type": "Thillana",
                "Ragam": "Sumanesa Ranjani/Madhukauns",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7786,
                "Name": "Thillana-Revathi",
                "Type": "Thillana",
                "Ragam": "Revathi",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7787,
                "Name": "Thillana-Amruthavarshini",
                "Type": "Thillana",
                "Ragam": "Amruthavarshini",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7788,
                "Name": "Thillana-BrindavanaSaranga",
                "Type": "Thillana",
                "Ragam": "BrindavanaSaranga",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7789,
                "Name": "Thillana-Chandrakauns",
                "Type": "Thillana",
                "Ragam": "Chandrakauns",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7790,
                "Name": "Thillana-Vasantha",
                "Type": "Thillana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7791,
                "Name": "Thillana-Varali",
                "Type": "Thillana",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7792,
                "Name": "Thillana-Madhuvanthi",
                "Type": "Thillana",
                "Ragam": "Madhuvanthi",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7793,
                "Name": "Thillana-Dwijavanthi",
                "Type": "Thillana",
                "Ragam": "Dwijavanthi",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7794,
                "Name": "Thillana-Hamsanandhi, Hindolam",
                "Type": "Thillana",
                "Ragam": "Ragamalika(Hamsanandi, Hindolam)",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7795,
                "Name": "Thillana-Behag (Adi parashakthi)",
                "Type": "Thillana",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7796,
                "Name": "Thillana-Shuddha Dhanyasi",
                "Type": "Thillana",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Mishra Chapu",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7797,
                "Name": "Thillana-Hamsanandi",
                "Type": "Thillana",
                "Ragam": "Hamsanandi",
                "Thalam": "Mishra Chapu",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7798,
                "Name": "Thillana-Lalitha",
                "Type": "Thillana",
                "Ragam": "Lalitha",
                "Thalam": "Sankeerna Chapu",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7799,
                "Name": "Thillana-Kaanadaa",
                "Type": "Thillana",
                "Ragam": "Kaanada",
                "Thalam": "Mishra Chapu (Tisra Nadai)",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7800,
                "Name": "Thillana-Behag (Yenai aaLum)",
                "Type": "Thillana",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7801,
                "Name": "Thillana-Desh",
                "Type": "Thillana",
                "Ragam": "Desh",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7802,
                "Name": "Thillana-Surutti",
                "Type": "Thillana",
                "Ragam": "Surutti",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7803,
                "Name": "Thillana-KarnaRanjani",
                "Type": "Thillana",
                "Ragam": "KarnaRanjani",
                "Thalam": "Adi (2 kalai)",
                "Composer": "Madurai T Srinivasan",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Madurai T Srinivasan",
                "BookAuthor": "Madurai T Srinivasan",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7804,
                "Name": "AppO pusippeppaDiyO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7805,
                "Name": "aiyaaraa ennai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7806,
                "Name": "arumai perumai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7807,
                "Name": "arivai thandhidu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7808,
                "Name": "aasaikkuLLaagaadhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7809,
                "Name": "innavidhame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7810,
                "Name": "uLLamthanil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7811,
                "Name": "yeththanaithaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7812,
                "Name": "yeLiyenai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Adi",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7813,
                "Name": "ennathukudhavi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Adi",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7814,
                "Name": "enna vidham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7815,
                "Name": "kaaNakaNNaayiram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7816,
                "Name": "neeye gathiallaamal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7817,
                "Name": "paruvam paarkka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7818,
                "Name": "podhum podhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Punnagavarali",
                "Thalam": "Adi",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7819,
                "Name": "abhimaanam vaitharul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7820,
                "Name": "meiyendriraadhiru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7821,
                "Name": "varmamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7822,
                "Name": "vidhi illaarkku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7823,
                "Name": "somavaara darisanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Tamil",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7824,
                "Name": "amba nannu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Deshadi",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7825,
                "Name": "paraakela",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7826,
                "Name": "indha paraakaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7827,
                "Name": "Bhajanseyave",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7828,
                "Name": "mahimateliya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7829,
                "Name": "Sharanu Sharanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Composer": "Annai Ayya Brothers",
                "Song Language": "Telugu",
                "BookTitle": "Compositions of Anai Ayya Brothers",
                "BookAuthor": "T Vishwanathan, T Sankaran",
                "Year of Publication": 1990,
                "Book Print Language": "English"
              },
              {
                "Serial #": 7830,
                "Name": "Tiruvum Kalviyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7831,
                "Name": "Poyyadha Mozhiyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7832,
                "Name": "Punmalai iravellaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Revagupti",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7833,
                "Name": "Marulodu Mayai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7834,
                "Name": "Pozhudu Vidindadu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7835,
                "Name": "Naraimanakkum Kondrai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7836,
                "Name": "Orumaiyin Ulagellam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chandrajyothi",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7837,
                "Name": "Durguna Mayai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7838,
                "Name": "Kallaya Manangalum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Khanda Chapu",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7839,
                "Name": "Thiruvongu Punniya Seyal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7840,
                "Name": "Madham Pidithavarellaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7841,
                "Name": "Palkattum Olivanna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Khanda Chapu",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7842,
                "Name": "Panniru Kanmalar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7843,
                "Name": "Vanam Enge",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7844,
                "Name": "Nin iru thaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7845,
                "Name": "Nallavar Perum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Mishra Chapu",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7846,
                "Name": "Panninul Isaiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Khanda Chapu",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7847,
                "Name": "Naavinaal Unnai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7848,
                "Name": "Tundada Manivilakke",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7849,
                "Name": "Endai Piran",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7850,
                "Name": "Alangarikkindrom",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Mishra Chapu",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7851,
                "Name": "Pozhudhu Vidindadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7852,
                "Name": "Eththikkum En Ullam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7853,
                "Name": "Kaayellam Kaniyena",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7854,
                "Name": "Arulaaramudhe Sharanam Sharanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanadam",
                "Thalam": "Mishra Chapu",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7855,
                "Name": "Varum Varum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Ekam (Tisra Gathi)",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7856,
                "Name": "Arulvilakke Arutchudare",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Roopakam",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7857,
                "Name": "Anjadhe Nenje",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7858,
                "Name": "Thodukkavo Nalla",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7859,
                "Name": "Arampazhukkum tharuve",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7860,
                "Name": "ArutJyothi Deivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7861,
                "Name": "Jaadhiyum Madhamum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Vallalar/PS Narayanaswamy",
                "Song Language": "Tamil",
                "BookTitle": "Vallalar-Thiruartupa-PSN Mama Tuned",
                "BookAuthor": "Vallalar/PS Narayanaswamy",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 7862,
                "Name": "Jathiswaram-Hamsanandi",
                "Type": "Jathiswaram",
                "Ragam": "Hamsanandi",
                "Thalam": "Roopakam",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7863,
                "Name": "Jathiswaram-Shankarabharanam",
                "Type": "Jathiswaram",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7864,
                "Name": "Jathiswaram-Kalyani",
                "Type": "Jathiswaram",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7865,
                "Name": "Jathiswaram-Vachaspathi",
                "Type": "Jathiswaram",
                "Ragam": "Vachaspathi",
                "Thalam": "Roopakam",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7866,
                "Name": "Jathiswaram-Mohanam",
                "Type": "Jathiswaram",
                "Ragam": "Mohanam",
                "Thalam": "Roopakam",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7867,
                "Name": "Jathiswaram-Kaanada",
                "Type": "Jathiswaram",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7868,
                "Name": "Jathiswaram-Thodi",
                "Type": "Jathiswaram",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7869,
                "Name": "Jathiswaram-Khamas",
                "Type": "Jathiswaram",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7870,
                "Name": "Jathiswaram-Ragamalika",
                "Type": "Jathiswaram",
                "Ragam": "Ragamalika (Keeravani, Mohanam, Thodi, Poorvikalyani)",
                "Thalam": "Mishra Chapu",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7871,
                "Name": "annayai maravEnaDi",
                "Type": "Varnam-Pada",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7872,
                "Name": "karuNai nee seiyya",
                "Type": "Varnam-Pada",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7873,
                "Name": "vElanai varachchollaDi",
                "Type": "Varnam-Pada",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7874,
                "Name": "saamiyai varachchollaDi",
                "Type": "Varnam-Pada",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7875,
                "Name": "aadhi sivanai kaanave",
                "Type": "Varnam-Pada",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7876,
                "Name": "maa moham thaane",
                "Type": "Varnam-Pada",
                "Ragam": "Naatakurinji",
                "Thalam": "Roopakam",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7877,
                "Name": "sakhiye indha jaalam",
                "Type": "Varnam-Pada",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7878,
                "Name": "Moha maahinaen",
                "Type": "Varnam-Pada",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7879,
                "Name": "saamiyai azhaithodi",
                "Type": "Varnam-Pada",
                "Ragam": "Ragamalika (Thodi, Mohanam, Vasantha, DevaManohari, Shankarabharanam, Saranga, Kaanada, Arabhi, Bhairavi)",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7880,
                "Name": "aranginilE vandhu aaDinaaL",
                "Type": "Padam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7881,
                "Name": "meenatchi thaaye",
                "Type": "Padam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7882,
                "Name": "muthamizh solaiyile",
                "Type": "Padam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7883,
                "Name": "kandhan yenakke sondhamadi",
                "Type": "Padam",
                "Ragam": "DarbariKaanada",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7884,
                "Name": "arpudha sirpiyaDi",
                "Type": "Padam",
                "Ragam": "Ragamalika",
                "Thalam": "Roopakam",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7885,
                "Name": "paarthu koNDE irundhaal",
                "Type": "Padam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7886,
                "Name": "yen indha varmam",
                "Type": "Padam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7887,
                "Name": "yaaraDi indha vaasalil",
                "Type": "Padam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Roopakam",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7888,
                "Name": "ulagam pugazhum",
                "Type": "Padam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7889,
                "Name": "Thillana-Hindolam",
                "Type": "Thillana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7890,
                "Name": "Thillana-Kaanadaa",
                "Type": "Thillana",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7891,
                "Name": "Thillana-Hamsanandi",
                "Type": "Thillana",
                "Ragam": "Hamsanandi",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7892,
                "Name": "Thillana-Kapi",
                "Type": "Thillana",
                "Ragam": "Kaapi",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7893,
                "Name": "Thillana-Hindolam",
                "Type": "Thillana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7894,
                "Name": "Thillana-Shankarabharanam",
                "Type": "Thillana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7895,
                "Name": "Thillana-Hindolam",
                "Type": "Thillana",
                "Ragam": "Hindolam",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7896,
                "Name": "Thillana-Kaanadaa",
                "Type": "Thillana",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7897,
                "Name": "Thillana-Ragamalika",
                "Type": "Thillana",
                "Ragam": "Ragamalika (Hindolam, Mohanam, Vasantha, Kaanada, Kalyani)",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7898,
                "Name": "aNNal gaandhi pugazh maalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowri Manohari",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7899,
                "Name": "karthar yesuvin pugazh maalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7900,
                "Name": "Dasavathaara paadal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Shankarabharanam, Kaanada, Vasantha, Poorvikalyani, Atana, Bhageshri, Kalyani, Arabhi, Hindolam, Mohanam, Surutti)",
                "Thalam": "Adi",
                "Composer": "KN Dhandayudapani Pillai",
                "Song Language": "Tamil",
                "BookTitle": "AaDalisai Amudham",
                "BookAuthor": "KN Dhandayudapani Pillai",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7901,
                "Name": "Aiyyane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7902,
                "Name": "Endhuku eebhoga singaaram",
                "Type": "Javali",
                "Ragam": "Bhageshri",
                "Thalam": "Adi",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7903,
                "Name": "kanikarambhuna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kanthamani",
                "Thalam": "Deshadi",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7904,
                "Name": "kowthuka mahotsava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Mishra Chapu",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7905,
                "Name": "Kowsaleya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowri Manohari",
                "Thalam": "Adi",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7906,
                "Name": "thaaradhipaanana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Khanda Chapu",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7907,
                "Name": "Thyaagaraaja mahaaguro",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Roopakam",
                "Praise of": "Tyagaraja Swami",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7908,
                "Name": "thraahimaam trayambake",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Khanda Chapu",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7909,
                "Name": "naaraayana pankaja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dwijavanthi",
                "Thalam": "Khanda Jathi Matya Thalam",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7910,
                "Name": "padmanaabha paripaalaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7911,
                "Name": "paraathpara thava paadaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7912,
                "Name": "paalayamaam parameshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7913,
                "Name": "pranowmi sathatham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Mishra Chapu",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7914,
                "Name": "mahaadeva mahaneeya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapanthuvarali",
                "Thalam": "Adi",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7915,
                "Name": "mahishaanthi moorthim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7916,
                "Name": "maamava maragatha varNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7917,
                "Name": "maaruthiyai thozhum maname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7918,
                "Name": "Ranganaatha naayaki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7919,
                "Name": "shaaradaambike",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7920,
                "Name": "sundarabaahu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "VV Srivatsava",
                "BookTitle": "Sri Sutanjali - Compositions of VV Srivatsava",
                "BookAuthor": "VV Srivatsava",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7921,
                "Name": "swaami naan undan",
                "Type": "Varnam-Pada",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7922,
                "Name": "unnai ninaindhu",
                "Type": "Varnam-Pada",
                "Ragam": "DevaManohari",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7923,
                "Name": "Sri Vaathaapi ganapathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7924,
                "Name": "Vigna vinaayaka moorthikku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7925,
                "Name": "Shaarade veenaavadhana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "DevaGandhari",
                "Thalam": "Adi",
                "Praise of": "Saraswathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7926,
                "Name": "Saraswathi annaiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Saraswathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7927,
                "Name": "aru mugaa adimaiyai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7928,
                "Name": "ullamirangi vandharul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7929,
                "Name": "un padham nambina",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7930,
                "Name": "sendhil aanDavan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7931,
                "Name": "dandapani padam maravaadhavarkku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7932,
                "Name": "Thandai thaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "DevaManohari",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7933,
                "Name": "pari paalanai puri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7934,
                "Name": "mayil vaahanaa valli manamohana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7935,
                "Name": "mayil vaahane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7936,
                "Name": "vandarulvaai thogai mayil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7937,
                "Name": "valli kaanthaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7938,
                "Name": "koovi azhaikka koodaathaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7939,
                "Name": "akilaandeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7940,
                "Name": "indha paaraamugamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Roopakam",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7941,
                "Name": "unai allaal vere gathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7942,
                "Name": "yenadhu manam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7943,
                "Name": "ennai kaatharulvadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7944,
                "Name": "en manam oosalaada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7945,
                "Name": "karunai varumo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7946,
                "Name": "kalpaga maathaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7947,
                "Name": "kalpagaambike",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7948,
                "Name": "kalpagaambike",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7949,
                "Name": "kalpagaambikai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7950,
                "Name": "kalpagaambikaiyin kazhal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7951,
                "Name": "kalpagaambikaiyundu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Khanda Jathi Jhampa Thalam",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7952,
                "Name": "Kalyaani karunaanidhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7953,
                "Name": "kanindharul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi (Tisra Gathi)",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7954,
                "Name": "kaakka unakkirakkam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7955,
                "Name": "gomathithaaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7956,
                "Name": "thanjam neeyethaaye endhan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7957,
                "Name": "thaamasamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7958,
                "Name": "thiruvarul purivaiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7959,
                "Name": "devi undhan sevadiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7960,
                "Name": "nanganallur",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7961,
                "Name": "nambinadhu kutramaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7962,
                "Name": "neelaambari jagadeeswari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7963,
                "Name": "nenje kalpaga annai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddhaseemanthini",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7964,
                "Name": "paahi meenalochani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7965,
                "Name": "mangala naayaki",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kannadaa",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7966,
                "Name": "Maathaa innum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7967,
                "Name": "naanoru vilayaatu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kannadaa",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7968,
                "Name": "adimai mel kopama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7969,
                "Name": "appanum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7970,
                "Name": "arut thivilai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7971,
                "Name": "alavilaadha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7972,
                "Name": "unnai ninaithaale",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7973,
                "Name": "kapaaleesane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7974,
                "Name": "karunaakarane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7975,
                "Name": "karpaga manohara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malayamarutham",
                "Thalam": "Khanda Jathi Jhampa Thalam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7976,
                "Name": "Shankara dayaakaraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Khanda Chapu",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7977,
                "Name": "Shambho umaapathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7978,
                "Name": "Shambho Sadashiva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Saveri",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7979,
                "Name": "Charana pankajam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Mishra Chapu",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7980,
                "Name": "siridhenum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda Jathi Jhampa Thalam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7981,
                "Name": "dayaaparan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7982,
                "Name": "naama gosham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7983,
                "Name": "Naamadhyaanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7984,
                "Name": "nithyaanandha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7985,
                "Name": "paadapaada thigattaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7986,
                "Name": "paadi alluththe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7987,
                "Name": "paadhame thunai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7988,
                "Name": "manamenum malaraal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7989,
                "Name": "mahadeva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddhaseemanthini",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7990,
                "Name": "veru thunaiyedhundhan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7991,
                "Name": "sree shailapathaye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7992,
                "Name": "saama gaana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7993,
                "Name": "saamba shivaa endre",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7994,
                "Name": "oru varam thandarul",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Devi-Lakshmi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7995,
                "Name": "maa padhaam bhoruham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Praise of": "Devi-Lakshmi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7996,
                "Name": "varalakshmi neeye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SaalagaBhairavi",
                "Thalam": "Adi",
                "Praise of": "Devi-Lakshmi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7997,
                "Name": "naagaraajanaana sesha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7998,
                "Name": "naamam ondre podhaadho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 7999,
                "Name": "sree padmanabhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8000,
                "Name": "sree hare narayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8001,
                "Name": "illai endra sol",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi (Tisra Gathi)",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8002,
                "Name": "dayanidhe jaanaki pathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8003,
                "Name": "raamachandran malaradi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8004,
                "Name": "raamanaama amrutha paaname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8005,
                "Name": "sree raama bhadraa raghu veera",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saaraamathi",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8006,
                "Name": "janak kumaari manalan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Mishra Chapu",
                "Praise of": "Vishnu-Rama",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8007,
                "Name": "adimayin ulla kudisaiyil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8008,
                "Name": "guruvaayoor appa kuzhandhaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8009,
                "Name": "baalakrishnan paadamalar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8010,
                "Name": "baala paahi vasudeva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8011,
                "Name": "venkataramanaa un thiru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu-Venkatramanan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8012,
                "Name": "venkataramanaa pankaja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Venkatramanan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8013,
                "Name": "anudinam unnai bhajani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Praise of": "General Deity",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8014,
                "Name": "aandavanneeye sodhanai seidhaal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Praise of": "General Deity",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8015,
                "Name": "saadhu janangalai paalanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Praise of": "General Deity",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8016,
                "Name": "pavana thanaya raama dhootha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Anjaneya/Hanuman",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8017,
                "Name": "vaayu kumaaran sree hanumaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Anjaneya/Hanuman",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8018,
                "Name": "jaya veera hanumanthaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Praise of": "Anjaneya/Hanuman",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8019,
                "Name": "grahangalin anugraha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Navagraha",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8020,
                "Name": "sangeetha thirimoorthigalai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Praise of": "Carnatic Trinity",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8021,
                "Name": "dheem thana",
                "Type": "Thillana",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 4",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8022,
                "Name": "thanjam endraale nenjam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8023,
                "Name": "thudikaiyanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8024,
                "Name": "swaami eetharamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8025,
                "Name": "thirumaalin sodariye",
                "Type": "Virutham",
                "Praise of": "Devi-Karumari",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8026,
                "Name": "karumari karumari",
                "Type": "Virutham",
                "Praise of": "Devi-Karumari",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8027,
                "Name": "ambaa un paadam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GambhiraNaatai",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8028,
                "Name": "un paadham alladhu veru thunaiyilen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8029,
                "Name": "unai allal gathi yaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8030,
                "Name": "kamalambha annaiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Devi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8031,
                "Name": "yennukakkinnum irakkamillai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8032,
                "Name": "O siva param porule",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8033,
                "Name": "kapaali karunai nidhi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8034,
                "Name": "shankara purahara sadashiva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8035,
                "Name": "thunai neeye arul thaaraai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8036,
                "Name": "deivam thunai ariveer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8037,
                "Name": "bhajanai seivaai maname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8038,
                "Name": "verillai neeye gathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8039,
                "Name": "sada shiva bhajaname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8040,
                "Name": "Viswanaathaa ennai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "DevaGandhari",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8041,
                "Name": "indra jaala viddhai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "Vittobha",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8042,
                "Name": "ennai sodhanai seiyaadhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Praise of": "General Deity",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8043,
                "Name": "kadalil amizhndhiduvom",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Praise of": "General Deity",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8044,
                "Name": "kaaNa virumbum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Praise of": "General Deity",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8045,
                "Name": "maname ganamum maravaathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhimplas",
                "Thalam": "Adi",
                "Praise of": "General Deity",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8046,
                "Name": "deva devan paadham paNivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "General Deity",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8047,
                "Name": "saarasa poom paadham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Praise of": "General Deity",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8048,
                "Name": "paarulagil uzhandru nondhen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "General Deity",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8049,
                "Name": "bhumiyil maaniDa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Praise of": "General Deity",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8050,
                "Name": "jothi mayamaana lingam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurunji",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8051,
                "Name": "paramapurushan pada dhyaanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Praise of": "General Deity",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8052,
                "Name": "ninaindhurugum adiyaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8053,
                "Name": "unakkum yenakkum enna porutham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8054,
                "Name": "karunaa nidhiye kadaikkann",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8055,
                "Name": "bhuvana pathi prabho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8056,
                "Name": "sakala lokha naayakane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8057,
                "Name": "swaami sharanam raamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8058,
                "Name": "ennaalum indha bhaagyam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8059,
                "Name": "kannan maazhigaikke marupadi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8060,
                "Name": "Gopaalane deivammam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8061,
                "Name": "gopaalaa sajjana paalaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8062,
                "Name": "maadhava puviyadhil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8063,
                "Name": "maayaavadhaara madhusoodhanaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8064,
                "Name": "mohana krishnaa memudham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8065,
                "Name": "vaa vaa madhivadhanane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8066,
                "Name": "sree hare krishnaa para vaasudev",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8067,
                "Name": "swaami siridhum dhayai vallayaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8068,
                "Name": "thamizh maNam kamazha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Praise of": "Thamizh Language",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8069,
                "Name": "paa maalaiku inai undo Subramanya bhaarathiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Praise of": "Subramanya Bharathi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8070,
                "Name": "gaandhiyai pol oru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Praise of": "Gandhi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8071,
                "Name": "jaya bherigai kottuvom",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Independence Day",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 5",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8072,
                "Name": "vaaneem saraswathi",
                "Type": "Varnam-Tana",
                "Ragam": "GambhiraNaatai",
                "Thalam": "Adi",
                "Praise of": "Saraswathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8073,
                "Name": "vaarana vadhanam vigna naashakam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8074,
                "Name": "vandhenai arul thaa vaarana mukhane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8075,
                "Name": "vandheham vandheham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8076,
                "Name": "gajavadhanaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gowlai",
                "Thalam": "Roopakam",
                "Praise of": "Ganesha",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8077,
                "Name": "mahaadeva sutam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8078,
                "Name": "vara kaLa koduva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Roopakam",
                "Praise of": "Lakshmi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8079,
                "Name": "nannu maravakura seethapathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kosalam",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8080,
                "Name": "eppudu nee daya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8081,
                "Name": "namma bhaarathe namma shaarathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Saraswathi-Sharadamba",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8082,
                "Name": "shri venkatesham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8083,
                "Name": "daralO ninnuvinaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8084,
                "Name": "venkatesham namaami anisham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MohanaKalyani",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8085,
                "Name": "shri ramaNam sadaa bhaavayeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kadanakuthuhalam",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8086,
                "Name": "paalayashumaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8087,
                "Name": "namaamyaham shri sathya narayanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nalinakanthi",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Sathyanarayana",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8088,
                "Name": "yaadavakula devam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8089,
                "Name": "muruga muruga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dwijavanthi",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8090,
                "Name": "indha kashta memi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malavi",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8091,
                "Name": "sharanamanusu nammidhinaiyyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8092,
                "Name": "vandhenai arul thaa vel murugane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sunadavinodini",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8093,
                "Name": "ninne veDu konnanu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8094,
                "Name": "paahi jagath vallabhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8095,
                "Name": "gopaala baalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Mishra Chapu",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8096,
                "Name": "umaapathi vinutha shrirangapathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8097,
                "Name": "paahimaam shri mahalakshmi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Amruthavarshini",
                "Thalam": "Adi",
                "Praise of": "Lakshmi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8098,
                "Name": "dayai purivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8099,
                "Name": "maa jaanaki ramana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8100,
                "Name": "parameshwara manohari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavani",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8101,
                "Name": "deva kumaari deva manohari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "DevaManohari",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8102,
                "Name": "karuNa ennaDu kalgunO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8103,
                "Name": "anbu vellame arumuga deivame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapanthuvarali",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8104,
                "Name": "pugal yedhaiyya ennaku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8105,
                "Name": "kuzhal oodhum en baalakrishnan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhageshri",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8106,
                "Name": "bhaagyavathi neene hari manohari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Praise of": "Lakshmi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8107,
                "Name": "illai ithdharaniyil eesanai pol",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanadam",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8108,
                "Name": "kaavave bangaaru kaamaakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ManoRanjani",
                "Thalam": "Adi",
                "Praise of": "Parvathi-Kamakshi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8109,
                "Name": "venkatesha vara giri vaasaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janaranjani",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8110,
                "Name": "jaakelaraa janakajaa raamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8111,
                "Name": "abhaagi naanalla ninna daya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8112,
                "Name": "bhajaamyaham sathatham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamsaKalyani",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8113,
                "Name": "vaaneem saraswatheem vandeham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GambhiraNaatai",
                "Thalam": "Adi",
                "Praise of": "Saraswathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8114,
                "Name": "shiva shakthi ninnu vinaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShivaShakthi",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8115,
                "Name": "parimala paarijaatha poojitha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8116,
                "Name": "vande shri vishwanaatham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nagaswarali",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8117,
                "Name": "vaasanai ninai maname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KarnaRanjani",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8118,
                "Name": "kaaranamemammaa karuna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarangatharangini",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8119,
                "Name": "paraathpara yemi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Garudadhwani",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8120,
                "Name": "sangeetha kurunee",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8121,
                "Name": "thaaraka naama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8122,
                "Name": "paarammaa hokoNa yamunaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yadukula Kambhoji",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8123,
                "Name": "vindaigaL pala seiyyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8124,
                "Name": "namipe namma narayanaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8125,
                "Name": "pathitha paavana shri pathi deva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8126,
                "Name": "sannuthaanga shri raghupathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8127,
                "Name": "kaaruNya moorthy",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8128,
                "Name": "endha bhaaramu deva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jayanthashri",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8129,
                "Name": "dayathO nannelu kora",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8130,
                "Name": "bhajana seyu vaaralaghu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8131,
                "Name": "saama gaana priya shankara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shuddha Dhanyasi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8132,
                "Name": "chitrambalanai sindhanai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8133,
                "Name": "umaa vallabham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8134,
                "Name": "innamum nee yennai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8135,
                "Name": "bandhaa krishnaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desh",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8136,
                "Name": "krishnana nodathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaranga",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "Song Language": "Kannadaa",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8137,
                "Name": "kaividal aagaathu murugaiyya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jonpuri",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8138,
                "Name": "jayathi jayathi jaya shankara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Niroshta",
                "Thalam": "Adi (Tisra Gathi)",
                "Praise of": "Shiva",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8139,
                "Name": "hare raama chandra prabho",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pahadi",
                "Thalam": "Mishra Chapu",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8140,
                "Name": "hari dhyaaname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8141,
                "Name": "bhajare re sriman naaraayanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8142,
                "Name": "paahimaam shri chaamundeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Panchamanohari - DevaManohari, GowriManohari, EshaManohari, RamaManohari, KamalaManohari)",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8143,
                "Name": "Thillana-Nagaswarali",
                "Type": "Thillana",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8144,
                "Name": "Thillana-Hamsavinodini",
                "Type": "Thillana",
                "Ragam": "Hamsavinodini",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Ashok Madhav",
                "BookTitle": "Gana Prabhavam",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2004,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8145,
                "Name": "bhajami manasa sankara sutham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sindhuramakriya",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8146,
                "Name": "pranavaakaara ganapathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8147,
                "Name": "bhajamaanasa sathatham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kanthamani",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8148,
                "Name": "sadaa nanda mayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8149,
                "Name": "vandhisuve govinda ninna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ravichandrika",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8150,
                "Name": "saaranga gathaa paaNim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8151,
                "Name": "parashambhavi amba parameshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsavinodini",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8152,
                "Name": "jaanaki vallabham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8153,
                "Name": "raamachandra raa raa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8154,
                "Name": "neekamala paadha mula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8155,
                "Name": "shri vaikunta pathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Mishra Chapu",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8156,
                "Name": "dhyaanam seidhu unadhadi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Mishra Chapu",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8157,
                "Name": "indha maayaajaalam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8158,
                "Name": "kaarunya moorthy",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8159,
                "Name": "chaamundeshwari shankari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarangamalhaar",
                "Thalam": "Adi",
                "Praise of": "Parvathi-Chamundeshwari",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8160,
                "Name": "Shakthi Shankaran eendra Subramanya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShivaRanjani",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8161,
                "Name": "yezhumalai aiyyane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijayanagari",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8162,
                "Name": "lakshmi ramanaaya naaraayanaaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8163,
                "Name": "maathe jagan mohini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8164,
                "Name": "shri mahalakshmi varadhe namousthuthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Praise of": "Lakshmi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8165,
                "Name": "ilai mEl thuyilum govindan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sumanesa Ranjani/Madhukauns",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8166,
                "Name": "raavamma nannu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pashupathipriya",
                "Thalam": "Roopakam",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8167,
                "Name": "yaadhava kula",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8168,
                "Name": "kanavilum ninaivilum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "DevaManohari",
                "Thalam": "Mishra Chapu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8169,
                "Name": "lambodhara shanmukha janani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi (Tisra Gathi)",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8170,
                "Name": "sriman narayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navarasa Kannadaa",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8171,
                "Name": "thirumaal marugaa ",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhushavali",
                "Thalam": "Roopakam",
                "Praise of": "Murugan",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8172,
                "Name": "nee vara solla maattaayaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8173,
                "Name": "bhaaramaa unakku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KalyanaVasantham",
                "Thalam": "Mishra Chapu",
                "Praise of": "Murugan",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8174,
                "Name": "iNaiyaar unakku undO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8175,
                "Name": "maadhurya bhaashini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsaangi",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8176,
                "Name": "entha puNNiya maaDidhiyO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rathipathipriya",
                "Thalam": "Adi",
                "Praise of": "Yashoda",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8177,
                "Name": "un thiruvadiye aDaindhen kandhaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowdaMalhaar",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8178,
                "Name": "varam thandharulvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vandanadhaarini",
                "Thalam": "Adi",
                "Praise of": "Lakshmi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8179,
                "Name": "paahimaam shri jaganmohini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jaganmohini",
                "Thalam": "Roopakam",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8180,
                "Name": "naaraayanam bhaja bhaja maanasa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8181,
                "Name": "neeve maathanamu neeve",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8182,
                "Name": "meenakshi sri jaganmaathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bahudari",
                "Thalam": "Roopakam",
                "Praise of": "Parvathi-Meenakshi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8183,
                "Name": "neeve puvilo pratyaksha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Mishra Chapu",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8184,
                "Name": "venu gopala paarayyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8185,
                "Name": "jagadeeshwara maam paahi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Khanda Triputa",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8186,
                "Name": "neerajaaksha raamaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ranjani",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8187,
                "Name": "arul tharuvaai shri mahalakshmi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Praise of": "Lakshmi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8188,
                "Name": "ninna paadaaravinda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShivaRanjani",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8189,
                "Name": "hari naaraayanam bhaja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vaasanthi",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8190,
                "Name": "sarasijadala nethri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Praise of": "Saraswathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8191,
                "Name": "lalithaambikaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lalitha",
                "Thalam": "Mishra Chapu",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8192,
                "Name": "parameshwara manohari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Khanda Chapu",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8193,
                "Name": "rakshamaam chaayaaranjani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chaayaaranjani",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8194,
                "Name": "paradevathe parashakthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vijayanagari",
                "Thalam": "Roopakam",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8195,
                "Name": "bhajeham parashakthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Guhamanohari",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8196,
                "Name": "pancha nadeeswara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Praise of": "Parvathi-Dharmasamvardini",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8197,
                "Name": "endha neramum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Karnataka Shuddhasaveri",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8198,
                "Name": "sharanamthaLa padmathaLa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "YamunaKalyani",
                "Thalam": "Roopakam",
                "Praise of": "Raghavendra Swamy",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8199,
                "Name": "hari naama hari naama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8200,
                "Name": "sharanam sharanam guruvaayoorappa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tilang",
                "Thalam": "Roopakam",
                "Praise of": "Guruvayurappan",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8201,
                "Name": "hari narayana hari narayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8202,
                "Name": "yetakaalanu yethi naaTya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chandrakauns",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8203,
                "Name": "neeve maathalli naaraayaNi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NadaBrahmam",
                "Thalam": "Mishra Chapu",
                "Praise of": "Lakshmi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8204,
                "Name": "enna nee kaaya bhekO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Durga",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8205,
                "Name": "naaraayanam namaami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8206,
                "Name": "sooryakulamoorthim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surya",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8207,
                "Name": "sathatha bhagavan naama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "BrindavanaSaranga",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8208,
                "Name": "namaami madhuraapuri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shakthiroopini",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8209,
                "Name": "jaanaki kaanthaa jagan naathaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhuvanthi",
                "Thalam": "Roopakam",
                "Praise of": "Vishnu-Rama",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8210,
                "Name": "raama govinda naaraayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurunji",
                "Thalam": "Adi",
                "Praise of": "Vishnu",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8211,
                "Name": "unnai nambinen murugaiyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Khanda Chapu",
                "Praise of": "Murugan",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8212,
                "Name": "nidham unnai thozhuven",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika (Neelambari, Chitrambari, Bhageshwari, GowriManohari, Shuddha Saveri)",
                "Thalam": "Adi",
                "Praise of": "Parvathi",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8213,
                "Name": "mangala daayakam maanitha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Ashok Madhav",
                "BookTitle": "Sangeeta Swaranjali",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 2008,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8214,
                "Name": "kanakamaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "kanakangi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8215,
                "Name": "shakthi hastham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rathnangi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8216,
                "Name": "Nada Sudhakaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ganamoorthy",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8217,
                "Name": "vandeham sada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vanaspathi",
                "Thalam": "Mishra Chapu",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8218,
                "Name": "Sannutha Sri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manavathi",
                "Thalam": "Roopakam",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8219,
                "Name": "Bhuvaneshwarim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Tanarupi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8220,
                "Name": "Surapathim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Senavathi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8221,
                "Name": "paramadayala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hanumathodi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8222,
                "Name": "dayakari gowri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhenuka",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8223,
                "Name": "vasudeva sutam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natakapriya",
                "Thalam": "Roopakam",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8224,
                "Name": "sadashiva kumaraya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokilapriya",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8225,
                "Name": "nee padame gati",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rupavathi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8226,
                "Name": "gana lola murali",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gayakapriya",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8227,
                "Name": "Kamala nayanam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vakulabharanam",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8228,
                "Name": "ambayuvathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8229,
                "Name": "varadaraja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Roopakam",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8230,
                "Name": "varade himadri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suryakantham",
                "Thalam": "Roopakam",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8231,
                "Name": "maheshwaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hatakamberi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8232,
                "Name": "Shankara maha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jhankaradhwani",
                "Thalam": "Mishra Chapu",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8233,
                "Name": "Maam dehi vara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Natabhairavi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8234,
                "Name": "Shambhu Shankara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8235,
                "Name": "Sharanagathi endru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8236,
                "Name": "sarojamukhim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "GowriManohari",
                "Thalam": "Khanda Chapu",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8237,
                "Name": "karuna sagara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Varunapriya",
                "Thalam": "Mishra Chapu",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8238,
                "Name": "Shiva Shakthi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mararanjani",
                "Thalam": "Mishra Chapu",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8239,
                "Name": "Tamasam Endhuku",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Charukesi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8240,
                "Name": "sarasadala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sarasangi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8241,
                "Name": "Karomi Smaranam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8242,
                "Name": "Shambho Shankara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8243,
                "Name": "Paramathma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NagaNandhini",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8244,
                "Name": "Pavana murthim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Yagapriya",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8245,
                "Name": "Chandrasekaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragavardhini",
                "Thalam": "Mishra Chapu",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8246,
                "Name": "Kamakoti viharini",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gangeyabhushani",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8247,
                "Name": "Vagadishwaridevi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vagadeeshwari",
                "Thalam": "Roopakam",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8248,
                "Name": "Raja Rajeshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sulini",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8249,
                "Name": "Chanchala deveem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chalanattai",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8250,
                "Name": "Dehi tava pada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Salagam",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8251,
                "Name": "Narayana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jhalarnavam",
                "Thalam": "Roopakam",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8252,
                "Name": "Shankara Kumaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jhalavarali",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8253,
                "Name": "Smarami Sridharam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaneetham",
                "Thalam": "Roopakam",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8254,
                "Name": "Parama pavani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pavani",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8255,
                "Name": "Tyagaraja Swaminam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Raghupriya",
                "Thalam": "Roopakam",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8256,
                "Name": "Ninna pada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gavambhodhi",
                "Thalam": "Roopakam",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8257,
                "Name": "Bhuvaneshwari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavapriya",
                "Thalam": "Roopakam",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8258,
                "Name": "Shumbha Sura",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapanthuvarali",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8259,
                "Name": "Sharanam tava",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShadvidaMargini",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8260,
                "Name": "Vandeham jagat",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Suwarnangi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8261,
                "Name": "pannagendra shayinam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Divyamani",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8262,
                "Name": "Dhavalambari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhavalambari",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8263,
                "Name": "Shankari mate",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Namanarayani",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8264,
                "Name": "Devi ninadu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8265,
                "Name": "Ma ramana Rama",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Mishra Chapu",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8266,
                "Name": "Gana maduva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gamanashrama",
                "Thalam": "Roopakam",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8267,
                "Name": "Shailaraja",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vishwambari",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8268,
                "Name": "Krishnam vande",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shyamalangi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8269,
                "Name": "Uma maindane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8270,
                "Name": "Smarami Raghu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Khanda Chapu",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8271,
                "Name": "Somabimba vadani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8272,
                "Name": "Sharade visalakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dharmavathi",
                "Thalam": "Khanda Chapu",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8273,
                "Name": "Paradevathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Neethimathi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8274,
                "Name": "mangala prada",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kanthamani",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8275,
                "Name": "vande sadashivam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rishabhapriya",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8276,
                "Name": "Mamava meenakshi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8277,
                "Name": "varamdehi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8278,
                "Name": "Narahari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8279,
                "Name": "Chintayami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chithrambari",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8280,
                "Name": "nameeda dayaleda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sucharithra",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8281,
                "Name": "Adi nayakim",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jyothiswaroopini",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8282,
                "Name": "namami anisham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhathuvardani",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8283,
                "Name": "narasimha devi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nasikabhushani",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8284,
                "Name": "Rama chandram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kosalam",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8285,
                "Name": "Smaramyaham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Rasikapriya",
                "Thalam": "Adi",
                "Composer": "Ashok Madhav",
                "Group": "72 melakartha raga compositions by Dr Ashok Madhav",
                "BookTitle": "Melakarta Ganamalika",
                "BookAuthor": "Ashok R Madhav",
                "Year of Publication": 1997,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8286,
                "Name": "thanjam endrale",
                "Type": "Varnam-Tana",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8287,
                "Name": "neeyae pedai mukham",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8288,
                "Name": "swami undhan sharanam charanam",
                "Type": "Varnam-Tana",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8289,
                "Name": "swami unnai sadaa",
                "Type": "Varnam-Tana",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8290,
                "Name": "unnai ninaindhen ullam",
                "Type": "Varnam-Pada",
                "Ragam": "DevaManohari",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8291,
                "Name": "nee indha maayam seidhaal",
                "Type": "Varnam-Pada",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8292,
                "Name": "maayam yedo swaami",
                "Type": "Varnam-Pada",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8293,
                "Name": "swaami naan undan",
                "Type": "Varnam-Pada",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8294,
                "Name": "swaami nee manam irangi",
                "Type": "Varnam-Pada",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8295,
                "Name": "un padam nambinaen",
                "Type": "Varnam-Tana",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8296,
                "Name": "kandayya undhan paadam",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Roopakam",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8297,
                "Name": "maadhumai bhaaganin",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8298,
                "Name": "vaaNi saraswathi thaaye",
                "Type": "Varnam-Tana",
                "Ragam": "Saraswathi",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8299,
                "Name": "ambigaiye unnai",
                "Type": "Varnam-Pada",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8300,
                "Name": "anbe en aaruyir",
                "Type": "Varnam-Pada",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8301,
                "Name": "annai kalpagam",
                "Type": "Varnam-Pada",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8302,
                "Name": "ammaa bhuvana mohini",
                "Type": "Varnam-Pada",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8303,
                "Name": "unnai ninaindhu",
                "Type": "Varnam-Pada",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8304,
                "Name": "ainkaranai paNi",
                "Type": "Varnam-Pada",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8305,
                "Name": "ninnai naan nambi vandhen",
                "Type": "Varnam-Pada",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8306,
                "Name": "naan mukhane naal veda saarane",
                "Type": "Varnam-Pada",
                "Ragam": "Ragamalika (Bhariavi Mala - Nata Bhairavi, Bhairavi, Saalaga Bhairavi, Ananda Bhairavi, Vasantha Bhairavi, Sindhu Bhairavi)",
                "Thalam": "Adi",
                "Praise of": "Brahma",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8307,
                "Name": "tha dheem thaka tha dheem",
                "Type": "Varnam-Pada",
                "Ragam": "Ragamalika (Sapta Vithanga natanam - Bilahari, Keeravani, Malayamarutham, Vaagadeeswari, Lathangi, Kharaharapriya, Panthuvarali, Abhogi, Kalyani, Brindavana Saranga)",
                "Thalam": "Adi",
                "Praise of": "Nataraja",
                "Composer": "Dr Rukmini Ramani",
                "Group": "Sapta Vithanga Natanam",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8308,
                "Name": "aanandamaai",
                "Type": "Varnam-Pada",
                "Ragam": "Ragamalika (Bhowli, Bilahari, Shuddha Dhanyasi, Ananda Bhairavi, Khamas, Hindolam, Kaanadaa, Mishra SivaRanjani, Brindavana Saranga)",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Group": "Kannagi kaaviyam",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8309,
                "Name": "dheem dheem thirana",
                "Type": "Thillana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8310,
                "Name": "Thillana-Valachi",
                "Type": "Thillana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8311,
                "Name": "Thillana-Surya",
                "Type": "Thillana",
                "Ragam": "Surya",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8312,
                "Name": "Thillana-Komalangi",
                "Type": "Thillana",
                "Ragam": "Komalaangi",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8313,
                "Name": "Thillana-BrindavanaSaranga",
                "Type": "Thillana",
                "Ragam": "BrindavanaSaranga",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8314,
                "Name": "Thillana-Khamas",
                "Type": "Thillana",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8315,
                "Name": "Thillana-Revathi",
                "Type": "Thillana",
                "Ragam": "Revathi",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8316,
                "Name": "Thillana-Mohanam",
                "Type": "Thillana",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8317,
                "Name": "Thillana-Shanmugapriya",
                "Type": "Thillana",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8318,
                "Name": "Thillana-Behag",
                "Type": "Thillana",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnamalai - 23 Varnams & 10 Thillanas",
                "BookAuthor": "Papanasam Sivan / Dr Rukmini Ramani",
                "Year of Publication": "Not available",
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8319,
                "Name": "vaa ganapathiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8320,
                "Name": "anbudan ambikai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8321,
                "Name": "soppana vaazhvil maghindhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8322,
                "Name": "kumaran thiruvadi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "YamunaKalyani",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8323,
                "Name": "muruganai nee anudhinamum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8324,
                "Name": "aanandha natam aadum aiyyan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaDhanyasi",
                "Thalam": "Roopakam",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8325,
                "Name": "kaaNa aaval kondu ongum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Group": "Mahathama Udangar Cinema Song",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8326,
                "Name": "Shankaranai paNi maname",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8327,
                "Name": "pOvOm vaareer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Praise of": "Vishnu",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8328,
                "Name": "siridhum kavalai padaadhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8329,
                "Name": "ninaindhurugum adiyaar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8330,
                "Name": "maname nee eesan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8331,
                "Name": "raajan maharaajan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Praise of": "Shiva",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8332,
                "Name": "ennai vittodaadhedaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8333,
                "Name": "yendhai gOvindhan vandhaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8334,
                "Name": "kannan madhura idhazhai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhimplas",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8335,
                "Name": "karuNaa nidhiyae",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Praise of": "Pandurangan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8336,
                "Name": "krishnaa mukundaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Navaroj",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8337,
                "Name": "kulakkoDi thazhaikka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Adi",
                "Praise of": "vishnu",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8338,
                "Name": "kOlam yamunai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desh",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8339,
                "Name": "sarvalokha sharanyasree",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nadanamakriya",
                "Thalam": "Adi",
                "Praise of": "vishnu",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8340,
                "Name": "saanandaandh tharanga",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "Song Language": "sanksrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8341,
                "Name": "sodanai thagaadhu aiyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8342,
                "Name": "Je pandareesaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sohini",
                "Thalam": "Adi",
                "Praise of": "Pandurangan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8343,
                "Name": "Paanduranga prabhuve",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Ad",
                "Praise of": "Pandurangan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8344,
                "Name": "bhaagavadhothama je thukaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Praise of": "Tukaram",
                "Composer": "Papanasam Sivan",
                "Song Language": "sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8345,
                "Name": "purakkave avadharithaan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8346,
                "Name": "peraanandham kaaN",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Praise of": "vishnu",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8347,
                "Name": "pOvOm vaareer",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Roopakam",
                "Praise of": "Pandurangan",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8348,
                "Name": "yadhukula thilaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HamirKalyani",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8349,
                "Name": "yamunaa dheereleela",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Krishna",
                "Composer": "Papanasam Sivan",
                "Song Language": "sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8350,
                "Name": "Shri raama bhadraa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Praise of": "Vishnu-Rama",
                "Composer": "Papanasam Sivan",
                "Song Language": "sanskrit",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8351,
                "Name": "annaiyin kaalil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Praise of": "Bharatha Matha",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8352,
                "Name": "gaandhijiyaai vandhu avadharithu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Praise of": "Mahatma Gandhi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8353,
                "Name": "kaimaaru seivadhundO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Praise of": "Mahatma Gandhi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8354,
                "Name": "thaayin manikodi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SalagaBhairavi",
                "Thalam": "Adi",
                "Praise of": "national flag",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8355,
                "Name": "desa sevai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Praise of": "Mother Land-Bhaarath",
                "Composer": "Papanasam Sivan",
                "Group": "Thyaga Bhumi Cinema Song",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8356,
                "Name": "nam naadu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Praise of": "Mother Land-Bhaarath",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8357,
                "Name": "bhaaratha punniya bhumi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Praise of": "Mother Land-Bhaarath",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8358,
                "Name": "bhaaratha maatha paripoorna",
                "Type": "Kriti/Keerthanam",
                "Ragam": "NataBhairavi",
                "Thalam": "Adi",
                "Praise of": "Bharatha Matha",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8359,
                "Name": "mahaathmaa oruvare",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Praise of": "Mahatma Gandhi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8360,
                "Name": "maathaa eendrameiyaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Praise of": "Mahatma Gandhi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8361,
                "Name": "annaiyum thandhaiyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Praise of": "Mother-Father",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8362,
                "Name": "aaDuvom vaarungal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8363,
                "Name": "ellaam avan seyal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8364,
                "Name": "ellaam brhama mayam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "behag",
                "Thalam": "Adi",
                "Praise of": "Parabrahmam",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8365,
                "Name": "dhyaaname yenadhu manadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8366,
                "Name": "vaana inbam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhimplas",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8367,
                "Name": "maaniDa janmam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8368,
                "Name": "pizhai porutharuLvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Mishra Chapu",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8369,
                "Name": "oho en thozhargaaL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8370,
                "Name": "vanDaar kuzhal",
                "Type": "Virutham",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8371,
                "Name": "un uruvam",
                "Type": "Virutham",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 6",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2018,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8372,
                "Name": "annaiyum thandhaiyum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8373,
                "Name": "ayan mudhal",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8374,
                "Name": "anjaneya murthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8375,
                "Name": "aanandame para",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8376,
                "Name": "ingum angum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8377,
                "Name": "illadhadhai virumbi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawa Gowlai",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8378,
                "Name": "un azhagai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Madhyamavathi",
                "Thalam": "Tisra jathi Eka Thalam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8379,
                "Name": "ulaginil yengenum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8380,
                "Name": "ulaga vaazhviloru",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8381,
                "Name": "engum nirai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Roopakam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8382,
                "Name": "enna seivaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8383,
                "Name": "yedhumariyaen",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8384,
                "Name": "kadalil amizhndhiduvom",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8385,
                "Name": "kadhiravan udhayang",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8386,
                "Name": "kaNDAL kaNDadhum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8387,
                "Name": "kandaen vaanar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8388,
                "Name": "karunAnidhiyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8389,
                "Name": "Giridhara gOpaalaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8390,
                "Name": "gOpalaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8391,
                "Name": "kailaasapathiyE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8392,
                "Name": "shankari dayakari",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8393,
                "Name": "jaya jaya bhuvanapathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhimplas",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8394,
                "Name": "thandhadhE aanandham",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8395,
                "Name": "thaalO thaalO",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8396,
                "Name": "thaan manamaruL",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8397,
                "Name": "thirukuraLenum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8398,
                "Name": "devaki varabaalaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kurunji",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8399,
                "Name": "deviyai poojai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8400,
                "Name": "neeye thunai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Jonpuri",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8401,
                "Name": "neelakaNDaa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8402,
                "Name": "brundavanathil",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8403,
                "Name": "bhajanai seivaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "YamunaKalyani",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8404,
                "Name": "paadhimadhiye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8405,
                "Name": "piravaa varam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Desh",
                "Thalam": "Ekam",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8406,
                "Name": "pizhai porutharuLvaai",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8407,
                "Name": "bhorpandhar",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Balahamsa",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8408,
                "Name": "mahaathmaa oruvare",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Manirangu",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8409,
                "Name": "yadhukula thilaka",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MishraKaapi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8410,
                "Name": "vadhaname chandra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8411,
                "Name": "vaLLalai paadum",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chenchurutti",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8412,
                "Name": "vaa vaa ambuli",
                "Type": "Kriti/Keerthanam",
                "Ragam": "ShivaRanjani",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8413,
                "Name": "veeNe azhiyaadhe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8414,
                "Name": "sangeethame",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8415,
                "Name": "sadhwaguna bhodhan",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saaraamathi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8416,
                "Name": "sree vaikuntapathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ragamalika",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8417,
                "Name": "oru pizhaiyum",
                "Type": "Virutham",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8418,
                "Name": "kaDimadhir Gopuram",
                "Type": "Virutham",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8419,
                "Name": "thiru kayilai malai",
                "Type": "Virutham",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8420,
                "Name": "thiruvLarum aiyaaru",
                "Type": "Virutham",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8421,
                "Name": "dhaarithriya harapanchakam",
                "Type": "Virutham",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8422,
                "Name": "theruLum pugazhum",
                "Type": "Virutham",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8423,
                "Name": "bhaarathi vaani",
                "Type": "Virutham",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8424,
                "Name": "thiruvOga muraiyum",
                "Type": "Virutham",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8425,
                "Name": "ponnira sadai",
                "Type": "Virutham",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8426,
                "Name": "devi mahaa devan",
                "Type": "Virutham",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8427,
                "Name": "pavaLamaal varaiyai",
                "Type": "Virutham",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8428,
                "Name": "navarthna mizhaitha",
                "Type": "Virutham",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Papanasam Sivan Keerthana Maalai-Part 7",
                "BookAuthor": "Papanasam Sivan",
                "Year of Publication": 2007,
                "Book Print Language": "Tamizh"
              },
              {
                "Serial #": 8429,
                "Name": "Jathiswaram-Kanakangi",
                "Type": "Jathiswaram",
                "Ragam": "Kanakangi",
                "Thalam": "Adi",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8430,
                "Name": "Jathiswaram-Rathnangi",
                "Type": "Jathiswaram",
                "Ragam": "Rathnangi",
                "Thalam": "Adi",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8431,
                "Name": "Jathiswaram-Ganamurti",
                "Type": "Jathiswaram",
                "Ragam": "Ganamurti",
                "Thalam": "Adi",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8432,
                "Name": "Jathiswaram-Vanaspathi",
                "Type": "Jathiswaram",
                "Ragam": "Vanaspathi",
                "Thalam": "Adi",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8433,
                "Name": "Jathiswaram-Manavathi",
                "Type": "Jathiswaram",
                "Ragam": "Manavathi",
                "Thalam": "Adi",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8434,
                "Name": "Jathiswaram-Tanarupi",
                "Type": "Jathiswaram",
                "Ragam": "Tanarupi",
                "Thalam": "Adi",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8435,
                "Name": "Jathiswaram-Senavathi",
                "Type": "Jathiswaram",
                "Ragam": "Senavathi",
                "Thalam": "Mishra Chapu",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8436,
                "Name": "Jathiswaram-Thodi",
                "Type": "Jathiswaram",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8437,
                "Name": "Jathiswaram-Dhenuka",
                "Type": "Jathiswaram",
                "Ragam": "Dhenuka",
                "Thalam": "Mishra Chapu",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8438,
                "Name": "Jathiswaram-Natakapriya",
                "Type": "Jathiswaram",
                "Ragam": "Natakapriya",
                "Thalam": "Mishra Chapu",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8439,
                "Name": "Jathiswaram-Kokilapriya",
                "Type": "Jathiswaram",
                "Ragam": "Kokilapriya",
                "Thalam": "Mishra Chapu",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8440,
                "Name": "Jathiswaram-Roopavathi",
                "Type": "Jathiswaram",
                "Ragam": "Roopavathi",
                "Thalam": "Mishra Chapu",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8441,
                "Name": "Jathiswaram-Gayakapriya",
                "Type": "Jathiswaram",
                "Ragam": "Gayakapriya",
                "Thalam": "Chatusrajathi Roopaka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8442,
                "Name": "Jathiswaram-Vakulabharanam",
                "Type": "Jathiswaram",
                "Ragam": "Vakulabharanam",
                "Thalam": "Chatusrajathi Roopaka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8443,
                "Name": "Jathiswaram-Mayamalawagowlai",
                "Type": "Jathiswaram",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Chatusrajathi Roopaka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8444,
                "Name": "Jathiswaram-Chakravaham",
                "Type": "Jathiswaram",
                "Ragam": "Chakravaham",
                "Thalam": "Chatusrajathi Roopaka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8445,
                "Name": "Jathiswaram-Suryakantham",
                "Type": "Jathiswaram",
                "Ragam": "Suryakantham",
                "Thalam": "Chatusrajathi Roopaka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8446,
                "Name": "Jathiswaram-Hatakambheri",
                "Type": "Jathiswaram",
                "Ragam": "Hatakambheri",
                "Thalam": "Chatusrajathi Roopaka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8447,
                "Name": "Jathiswaram-Jhankaradhwani",
                "Type": "Jathiswaram",
                "Ragam": "Jhankaradhwani",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8448,
                "Name": "Jathiswaram-NataBhairavi",
                "Type": "Jathiswaram",
                "Ragam": "NataBhairavi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8449,
                "Name": "Jathiswaram-Keeravani",
                "Type": "Jathiswaram",
                "Ragam": "Keeravani",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8450,
                "Name": "Jathiswaram-Kharaharapriya",
                "Type": "Jathiswaram",
                "Ragam": "Kharaharapriya",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8451,
                "Name": "Jathiswaram-GowriManohari",
                "Type": "Jathiswaram",
                "Ragam": "GowriManohari",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8452,
                "Name": "Jathiswaram-Varunapriya",
                "Type": "Jathiswaram",
                "Ragam": "Varunapriya",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8453,
                "Name": "Jathiswaram-MaraRanjani",
                "Type": "Jathiswaram",
                "Ragam": "MaraRanjani",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8454,
                "Name": "Jathiswaram-Charukesi",
                "Type": "Jathiswaram",
                "Ragam": "Charukesi",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8455,
                "Name": "Jathiswaram-Sarasangi",
                "Type": "Jathiswaram",
                "Ragam": "Sarasangi",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8456,
                "Name": "Jathiswaram-HariKhambhoji",
                "Type": "Jathiswaram",
                "Ragam": "HariKhambhoji",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8457,
                "Name": "Jathiswaram-Shankarabharanam",
                "Type": "Jathiswaram",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8458,
                "Name": "Jathiswaram-Naganandhini",
                "Type": "Jathiswaram",
                "Ragam": "Naganandhini",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8459,
                "Name": "Jathiswaram-Yagapriya",
                "Type": "Jathiswaram",
                "Ragam": "Yagapriya",
                "Thalam": "Sankeerna Jathi Eka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8460,
                "Name": "Jathiswaram-Ragavardhini",
                "Type": "Jathiswaram",
                "Ragam": "Ragavardhini",
                "Thalam": "Sankeerna Jathi Eka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8461,
                "Name": "Jathiswaram-Gangeyabhushani",
                "Type": "Jathiswaram",
                "Ragam": "Gangeyabhushani",
                "Thalam": "Sankeerna Jathi Eka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8462,
                "Name": "Jathiswaram-Vagadeeshwari",
                "Type": "Jathiswaram",
                "Ragam": "Vagadeeshwari",
                "Thalam": "Sankeerna Jathi Eka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8463,
                "Name": "Jathiswaram-Soolini",
                "Type": "Jathiswaram",
                "Ragam": "Soolini",
                "Thalam": "Sankeerna Jathi Eka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8464,
                "Name": "Jathiswaram-Chalanaatai",
                "Type": "Jathiswaram",
                "Ragam": "Chalanaatai",
                "Thalam": "Sankeerna Jathi Eka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8465,
                "Name": "Jathiswaram-Salagam",
                "Type": "Jathiswaram",
                "Ragam": "Salagam",
                "Thalam": "Tisra Jathi Ata Thalam (Gupta)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8466,
                "Name": "Jathiswaram-Jalaarnavam",
                "Type": "Jathiswaram",
                "Ragam": "Jalaarnavam",
                "Thalam": "Tisra Jathi Ata Thalam (Gupta)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8467,
                "Name": "Jathiswaram-Jaalavarali",
                "Type": "Jathiswaram",
                "Ragam": "Jaalavarali",
                "Thalam": "Tisra Jathi Ata Thalam (Gupta)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8468,
                "Name": "Jathiswaram-Navaneetham",
                "Type": "Jathiswaram",
                "Ragam": "Navaneetham",
                "Thalam": "Tisra Jathi Ata Thalam (Gupta)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8469,
                "Name": "Jathiswaram-Pavani",
                "Type": "Jathiswaram",
                "Ragam": "Pavani",
                "Thalam": "Tisra Jathi Ata Thalam (Gupta)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8470,
                "Name": "Jathiswaram-Raghupriya",
                "Type": "Jathiswaram",
                "Ragam": "Raghupriya",
                "Thalam": "Tisra Jathi Dhuruva Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8471,
                "Name": "Jathiswaram-Gavambhodhi",
                "Type": "Jathiswaram",
                "Ragam": "Gavambhodhi",
                "Thalam": "Khanda Jathi Roopaka Thalam (Raaja)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8472,
                "Name": "Jathiswaram-Bhavapriya",
                "Type": "Jathiswaram",
                "Ragam": "Bhavapriya",
                "Thalam": "Khanda Jathi Roopaka Thalam (Raaja)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8473,
                "Name": "Jathiswaram-Shubhapantuvarali",
                "Type": "Jathiswaram",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Khanda Jathi Roopaka Thalam (Raaja)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8474,
                "Name": "Jathiswaram-Shadvidhamaargini",
                "Type": "Jathiswaram",
                "Ragam": "Shadvidhamaargini",
                "Thalam": "Khanda Jathi Roopaka Thalam (Raaja)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8475,
                "Name": "Jathiswaram-Suvarnaangi",
                "Type": "Jathiswaram",
                "Ragam": "Suvarnaangi",
                "Thalam": "Khanda Jathi Roopaka Thalam (Raaja)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8476,
                "Name": "Jathiswaram-Divyamani",
                "Type": "Jathiswaram",
                "Ragam": "Divyamani",
                "Thalam": "Khanda Jathi Roopaka Thalam (Raaja)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8477,
                "Name": "Jathiswaram-Dhavalambari",
                "Type": "Jathiswaram",
                "Ragam": "Dhavalambari",
                "Thalam": "Tisra Jathi Matya Thalam (Saara)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8478,
                "Name": "Jathiswaram-Namanarayani",
                "Type": "Jathiswaram",
                "Ragam": "Namanarayani",
                "Thalam": "Tisra Jathi Matya Thalam (Saara)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8479,
                "Name": "Jathiswaram-Kamavardhini/Pantuvarali",
                "Type": "Jathiswaram",
                "Ragam": "Kamavardhini/Pantuvarali",
                "Thalam": "Khanda Jathi Roopaka Thalam (Raaja)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8480,
                "Name": "Jathiswaram-Ramapriya",
                "Type": "Jathiswaram",
                "Ragam": "Ramapriya",
                "Thalam": "Tisra Jathi Matya Thalam (Saara)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8481,
                "Name": "Jathiswaram-Gamanashrama",
                "Type": "Jathiswaram",
                "Ragam": "Gamanashrama",
                "Thalam": "Tisra Jathi Matya Thalam (Saara)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8482,
                "Name": "Jathiswaram-Vishvambari",
                "Type": "Jathiswaram",
                "Ragam": "Vishvambari",
                "Thalam": "Tisra Jathi Matya Thalam (Saara)",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8483,
                "Name": "Jathiswaram-ShyamaLanghi",
                "Type": "Jathiswaram",
                "Ragam": "ShyamaLanghi",
                "Thalam": "Tisra Jathi Dhuruva Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8484,
                "Name": "Jathiswaram-Shanmugapriya",
                "Type": "Jathiswaram",
                "Ragam": "Shanmugapriya",
                "Thalam": "Tisra Jathi Dhuruva Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8485,
                "Name": "Jathiswaram-Simhendramadhyamam",
                "Type": "Jathiswaram",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Tisra Jathi Dhuruva Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8486,
                "Name": "Jathiswaram-Hemavathi",
                "Type": "Jathiswaram",
                "Ragam": "Hemavathi",
                "Thalam": "Tisra Jathi Dhuruva Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8487,
                "Name": "Jathiswaram-Dharmavathi",
                "Type": "Jathiswaram",
                "Ragam": "Dharmavathi",
                "Thalam": "Tisra Jathi Dhuruva Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8488,
                "Name": "Jathiswaram-Neethimathi",
                "Type": "Jathiswaram",
                "Ragam": "Neethimathi",
                "Thalam": "Tisra Jathi Dhuruva Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8489,
                "Name": "Jathiswaram-Kanthamani",
                "Type": "Jathiswaram",
                "Ragam": "Kanthamani",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8490,
                "Name": "Jathiswaram-Rishabhapriya",
                "Type": "Jathiswaram",
                "Ragam": "Rishabhapriya",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8491,
                "Name": "Jathiswaram-Lathangi",
                "Type": "Jathiswaram",
                "Ragam": "Lathangi",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8492,
                "Name": "Jathiswaram-Vachaspathi",
                "Type": "Jathiswaram",
                "Ragam": "Vachaspathi",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8493,
                "Name": "Jathiswaram-Kalyani",
                "Type": "Jathiswaram",
                "Ragam": "Kalyani",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8494,
                "Name": "Jathiswaram-Chithrambari",
                "Type": "Jathiswaram",
                "Ragam": "Chithrambari",
                "Thalam": "Khanda Jathi Eka Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8495,
                "Name": "Jathiswaram-Sucharithra",
                "Type": "Jathiswaram",
                "Ragam": "Sucharithra",
                "Thalam": "Sankeerna Jathi Jhumpa Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8496,
                "Name": "Jathiswaram-Jyothiswaroopini",
                "Type": "Jathiswaram",
                "Ragam": "Jyothiswaroopini",
                "Thalam": "Sankeerna Jathi Jhumpa Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8497,
                "Name": "Jathiswaram-Dhathuvardhani",
                "Type": "Jathiswaram",
                "Ragam": "Dhathuvardhani",
                "Thalam": "Sankeerna Jathi Jhumpa Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8498,
                "Name": "Jathiswaram-Nasikabhushani",
                "Type": "Jathiswaram",
                "Ragam": "Nasikabhushani",
                "Thalam": "Sankeerna Jathi Jhumpa Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8499,
                "Name": "Jathiswaram-Kosalam",
                "Type": "Jathiswaram",
                "Ragam": "Kosalam",
                "Thalam": "Sankeerna Jathi Jhumpa Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8500,
                "Name": "Jathiswaram-Rasikapriya",
                "Type": "Jathiswaram",
                "Ragam": "Rasikapriya",
                "Thalam": "Sankeerna Jathi Jhumpa Thalam",
                "Composer": "Madurai R Muralidharan",
                "BookTitle": "Nritta Lahari (Jathiswarams in 72 Melakartha Ragams)",
                "BookAuthor": "Madurai R Muralidharan",
                "Year of Publication": 2014,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8501,
                "Name": "apaduuruku",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Pattabhiramayya",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8502,
                "Name": "balimi yela",
                "Type": "Javali",
                "Ragam": "Thodi",
                "Thalam": "adi",
                "Composer": "Pattabhiramayya",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8503,
                "Name": "yemi seyudunate",
                "Type": "Javali",
                "Ragam": "Paras",
                "Thalam": "adi",
                "Composer": "Pattabhiramayya",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8504,
                "Name": "eelaaguna",
                "Type": "Javali",
                "Ragam": "Darbar",
                "Thalam": "Roopakam",
                "Composer": "Pattabhiramayya",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8505,
                "Name": "Mohamella",
                "Type": "Javali",
                "Ragam": "Mohanam",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Pattabhiramayya",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8506,
                "Name": "nannu chulakan jese",
                "Type": "Javali",
                "Ragam": "Vasantha",
                "Thalam": "Mishra Chapu",
                "Composer": "Pattabhiramayya",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8507,
                "Name": "neelaalana Chalu",
                "Type": "Javali",
                "Ragam": "Dhanyasi",
                "Thalam": "Roopakam",
                "Composer": "Pattabhiramayya",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8508,
                "Name": "paripovalera",
                "Type": "Javali",
                "Ragam": "Bilahari",
                "Thalam": "Roopakam",
                "Composer": "Pattabhiramayya",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8509,
                "Name": "raaraa namminaaraa",
                "Type": "Javali",
                "Ragam": "Kapi",
                "Thalam": "Mishra Chapu",
                "Composer": "Pattabhiramayya",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8510,
                "Name": "taarumaaru",
                "Type": "Javali",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Pattabhiramayya",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8511,
                "Name": "telisevagalella",
                "Type": "Javali",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Pattabhiramayya",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8512,
                "Name": "adi neepai",
                "Type": "Javali",
                "Ragam": "YamunaKalyani",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8513,
                "Name": "yemandunae",
                "Type": "Javali",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8514,
                "Name": "yeraa raaraa",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8515,
                "Name": "yeraatagunataraa",
                "Type": "Javali",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8516,
                "Name": "idi neeku",
                "Type": "Javali",
                "Ragam": "Begada",
                "Thalam": "Mishra Chapu",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8517,
                "Name": "kommarO",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8518,
                "Name": "vaaremi",
                "Type": "Javali",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8519,
                "Name": "vaega neevu",
                "Type": "Javali",
                "Ragam": "Surutti",
                "Thalam": "Roopakam",
                "Composer": "Dharmapuri Subbarayar",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8520,
                "Name": "kopametula",
                "Type": "Javali",
                "Ragam": "KedaraGowlai",
                "Thalam": "Roopakam",
                "Composer": "Thanjavur Chinnayya",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8521,
                "Name": "muttavadduraa",
                "Type": "Javali",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Thanjavur Chinnayya",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8522,
                "Name": "vegatayegadee",
                "Type": "Javali",
                "Ragam": "Chenchurutti",
                "Thalam": "Chatusrajathi eka thalam",
                "Composer": "Thanjavur Chinnayya",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8523,
                "Name": "bhaayaraani",
                "Type": "Javali",
                "Ragam": "HindustaniKapi",
                "Thalam": "Roopakam",
                "Composer": "Thirupathi Narayanaswami Naidu",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8524,
                "Name": "telisenura",
                "Type": "Javali",
                "Ragam": "Saveri",
                "Thalam": "Roopakam",
                "Composer": "Thirupathi Narayanaswami Naidu",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8525,
                "Name": "ithu saahasamulu",
                "Type": "Javali",
                "Ragam": "Saindhavi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8526,
                "Name": "apuDu manasu",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Roopakam",
                "Composer": "Patnam Subramanyaiyer",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8527,
                "Name": "samayamidhe",
                "Type": "Javali",
                "Ragam": "Behag",
                "Thalam": "Roopakam",
                "Composer": "Patnam Subramanyaiyer",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8528,
                "Name": "kalagannatu",
                "Type": "Javali",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Poochi Ramanathapuram Srinivasa Iyengar",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8529,
                "Name": "yekaamini",
                "Type": "Javali",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Bangalore Chandrasekara Shastri",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8530,
                "Name": "nannu vidanaaduta",
                "Type": "Javali",
                "Ragam": "Paras",
                "Thalam": "Ekam",
                "Composer": "Venkata subbarayar",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8531,
                "Name": "vanitaro",
                "Type": "Javali",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Dasu SreeRamulu",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8532,
                "Name": "vagala vayyaari",
                "Type": "Javali",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Patrayani Seetharama Shastry",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8533,
                "Name": "chalamelara",
                "Type": "Javali",
                "Ragam": "Naatakurinji",
                "Thalam": "Roopakam",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8534,
                "Name": "Charumathi",
                "Type": "Javali",
                "Ragam": "Kaanada",
                "Thalam": "Mishra Chapu",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8535,
                "Name": "Yela raaDaayane",
                "Type": "Javali",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8536,
                "Name": "yemi memu",
                "Type": "Javali",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8537,
                "Name": "iddari pondela",
                "Type": "Javali",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8538,
                "Name": "intirovaani",
                "Type": "Javali",
                "Ragam": "Behag",
                "Thalam": "Tisra jathi Eka Thalam",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8539,
                "Name": "jaaNatanamu",
                "Type": "Javali",
                "Ragam": "HindustaniKapi",
                "Thalam": "Viloma Chapu",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8540,
                "Name": "kulamulona",
                "Type": "Javali",
                "Ragam": "SindhuBhairavi",
                "Thalam": "Roopakam",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8541,
                "Name": "madhuraanagarilo",
                "Type": "Javali",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8542,
                "Name": "maavallagaadamma",
                "Type": "Javali",
                "Ragam": "Maand",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8543,
                "Name": "maayalaaDi",
                "Type": "Javali",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8544,
                "Name": "maeragaadhu",
                "Type": "Javali",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8545,
                "Name": "mosajesane",
                "Type": "Javali",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8546,
                "Name": "muttaraadaTe",
                "Type": "Javali",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8547,
                "Name": "ninu gaanaka",
                "Type": "Javali",
                "Ragam": "HamirKalyani",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8548,
                "Name": "neerajaakshi",
                "Type": "Javali",
                "Ragam": "DesiyaThodi",
                "Thalam": "Roopakam",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8549,
                "Name": "sarasuni",
                "Type": "Javali",
                "Ragam": "Khamas",
                "Thalam": "Chatusra jathi eka thalam",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8550,
                "Name": "sariyemi",
                "Type": "Javali",
                "Ragam": "Atana",
                "Thalam": "Roopakam",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8551,
                "Name": "vaddani nenaNtini",
                "Type": "Javali",
                "Ragam": "HindustaniKapi",
                "Thalam": "Roopakam",
                "Composer": "Unknown",
                "BookTitle": "Bunch of Javalis",
                "BookAuthor": "Pappu Venugopala Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8552,
                "Name": "aadinadhu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kalyani",
                "Thalam": "Mishra Chapu",
                "Composer": "MuthuThandavar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8553,
                "Name": "aaraNaporulE",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Roopakam",
                "Composer": "Mayuram Vishwanatha Shastry",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8554,
                "Name": "Athishaya",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8555,
                "Name": "baale brihashristhithimoole",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Mysore Jayachamaraja Wodeyar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8556,
                "Name": "Brovu Brovu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Mishra Chapu",
                "Composer": "Shyama Shastry",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8557,
                "Name": "Chandrasekaram",
                "Type": "Kriti/Keerthanam",
                "Ragam": "MargaHindolam",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8558,
                "Name": "Chandrasekaharam ashraye",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Keeravani",
                "Thalam": "Roopakam",
                "Composer": "Dr V Raghavan",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8559,
                "Name": "Dasharathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kokilapriya",
                "Thalam": "Adi",
                "Composer": "Tyagaraja Swami",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8560,
                "Name": "Dayaleni",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Nayaki",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Tyagaraja Swami",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8561,
                "Name": "Devadideva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Roopakam",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8562,
                "Name": "Devijagajjanani",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8563,
                "Name": "Devi pavane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8564,
                "Name": "Enthani vinavinthura",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Urmika",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Pallavi Seshaiyer",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8565,
                "Name": "Gajamukhane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Khanda Chapu",
                "Praise of": "Ganesha",
                "Composer": "Vyasarayar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8566,
                "Name": "Ganapathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Janaranjani",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8567,
                "Name": "Geethichakra",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kannadaa",
                "Thalam": "Mishra Chapu",
                "Composer": "Muthuswamy Dikshitar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8568,
                "Name": "Guruvaayurappa",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8569,
                "Name": "Hare Paripaahimaam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharya",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8570,
                "Name": "Japa kusuma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8571,
                "Name": "Kannatandri",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Narayani",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8572,
                "Name": "Kathashravanamado",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8573,
                "Name": "Maha Ganapathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kaanada",
                "Thalam": "Roopakam",
                "Praise of": "Ganesha",
                "Composer": "Koteeswara Iyer",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8574,
                "Name": "Marachithivemo",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharya",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8575,
                "Name": "Mathangi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Shuddhananda Bharathi",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8576,
                "Name": "Maayamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Shyama Shastry",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8577,
                "Name": "neekeladayaradu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kadanakuthuhalam",
                "Thalam": "Adi",
                "Composer": "Mysore Vasudevacharya",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8578,
                "Name": "neekelanayeda",
                "Type": "Kriti/Keerthanam",
                "Ragam": "DevaManohari",
                "Thalam": "Adi",
                "Composer": "Poochi Ramanathapuram Srinivasa Iyengar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8579,
                "Name": "palimpavamma",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Shyama Shastry",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8580,
                "Name": "purushothamudavivu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Revagupthi",
                "Thalam": "Khanda Chapu",
                "Composer": "Annamacharya",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8581,
                "Name": "Sacchamara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsanandi",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8582,
                "Name": "Sadanandamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Annamacharya",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8583,
                "Name": "Saraswatheem",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Hamsavinodini",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Praise of": "Saraswathi",
                "Composer": "Mysore Jayachamaraja Wodeyar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8584,
                "Name": "Saraswathi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi",
                "Thalam": "Roopakam",
                "Praise of": "Saraswathi",
                "Composer": "NS Ramachandran",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8585,
                "Name": "Sarvantaryami",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Tyagaraja Swami",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8586,
                "Name": "Sarvavighnaharana",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Spencer R Venugopal",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8587,
                "Name": "Shankaradayakara",
                "Type": "Kriti/Keerthanam",
                "Ragam": "HariKhambhoji",
                "Thalam": "Khanda Chapu",
                "Composer": "Papanasam Sivan",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8588,
                "Name": "Shankarininne",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Pantuvarali",
                "Thalam": "Mishra Chapu",
                "Composer": "Mysore Vasudevacharya",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8589,
                "Name": "Sharavana bhava guha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "Tanjavur Shankara Iyer",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8590,
                "Name": "Shivayanamavendre",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Composer": "Papansam Sivan",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8591,
                "Name": "Shri Gayatridevi",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Gamanashrama",
                "Thalam": "Dhuruva Thalam",
                "Composer": "Mysore Jayachamaraja Wodeyar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8592,
                "Name": "Shri Guru Dakshinamurthe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhavapriya",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Mysore Jayachamaraja Wodeyar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8593,
                "Name": "Shri Vasudeva",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Ramapriya",
                "Thalam": "Khanda Chapu",
                "Composer": "Mysore Vasudevacharya",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8594,
                "Name": "Shri Mahaganapathe",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Malahari",
                "Thalam": "Roopakam",
                "Praise of": "Ganesha",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8595,
                "Name": "Sthree veshamu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8596,
                "Name": "Tappulanniyu",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Bhowli",
                "Thalam": "Roopakam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8597,
                "Name": "Thathatha thadhimitha",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Thodi",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Purandaradasar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8598,
                "Name": "Thungemangala",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8599,
                "Name": "Varum idar ellam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Praise of": "Murugan",
                "Composer": "NS Chidambaram",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8600,
                "Name": "Velane",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Shubhapantuvarali",
                "Thalam": "Mishra Chapu",
                "Praise of": "Murugan",
                "Composer": "Koteeswara Iyer",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8601,
                "Name": "Vinayakam",
                "Type": "Kriti/Keerthanam",
                "Ragam": "Saraswathi Manohari",
                "Thalam": "Roopakam",
                "Praise of": "Ganesha",
                "Composer": "NS Ramachandran",
                "BookTitle": "Athishaya Vara Prasadini",
                "BookAuthor": "Maragatham Ramaswamy",
                "Year of Publication": 2022,
                "Book Print Language": "English"
              },
              {
                "Serial #": 8602,
                "Name": "aanaimugane un paadhame",
                "Type": "Varnam-Tana",
                "Ragam": "Kanakangi",
                "Thalam": "Adi",
                "Praise of": "Ganesha",
                "Composer": "TK GovindaRao",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8603,
                "Name": "karunai seivaai paragathi",
                "Type": "Varnam-Tana",
                "Ragam": "Rathnangi",
                "Thalam": "Adi",
                "Composer": "TK GovindaRao",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8604,
                "Name": "Shri gaanamurthe",
                "Type": "Varnam-Tana",
                "Ragam": "Ganamurti",
                "Thalam": "Adi",
                "Composer": "TK GovindaRao",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8605,
                "Name": "vanaspathidaLEna",
                "Type": "Varnam-Tana",
                "Ragam": "Vanaspathi",
                "Thalam": "Adi",
                "Composer": "TK GovindaRao",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8606,
                "Name": "Varaveda puraana",
                "Type": "Varnam-Tana",
                "Ragam": "Manavathi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar",
                "Song Language": "Kannada",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8607,
                "Name": "taanaroopiyai",
                "Type": "Varnam-Tana",
                "Ragam": "Tanarupi",
                "Thalam": "Adi",
                "Composer": "TK GovindaRao",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8608,
                "Name": "senaapathi valli naayaka",
                "Type": "Varnam-Tana",
                "Ragam": "Senavathi",
                "Thalam": "Adi",
                "Composer": "TK GovindaRao",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8609,
                "Name": "Era napai",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8610,
                "Name": "kannanin karunai",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8611,
                "Name": "karuna kataakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Annaswami Shastry",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8612,
                "Name": "sogamaanaen thozhiye",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8613,
                "Name": "Sami ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8614,
                "Name": "vetrilai paaku unakkendru",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachari",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8615,
                "Name": "Thaamasinchiku",
                "Type": "Varnam-Tana",
                "Ragam": "Asaveri",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8616,
                "Name": "madana roopaa",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8617,
                "Name": "nikila lokha naayaki",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "TK GovindaRao",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8618,
                "Name": "Vanajaakshi ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Tyagayya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8619,
                "Name": "varavaenum swaminaatha",
                "Type": "Varnam-Tana",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8620,
                "Name": "guna saagaraa kumara",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8621,
                "Name": "kaaman kaNaigaL",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Chitravina N Ravikiran",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8622,
                "Name": "maal marugan murugan",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Thanjavur Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8623,
                "Name": "manasu thelisi neevu",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8624,
                "Name": "sami ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Hindolam",
                "Thalam": "Adi",
                "Composer": "Poochi Ramanathapuram Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8625,
                "Name": "sariga madana janakuni",
                "Type": "Varnam-Tana",
                "Ragam": "VasanthaBhairavi",
                "Thalam": "Adi",
                "Composer": "Chitravina N Ravikiran",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8626,
                "Name": "mayamalawagowlai",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachari",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8627,
                "Name": "sarasijanaabha muraare",
                "Type": "Varnam-Tana",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8628,
                "Name": "aananda natanam aadinaar",
                "Type": "Varnam-Tana",
                "Ragam": "Bhowli",
                "Thalam": "Adi",
                "Composer": "Pathamadi Krishnayyar",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8629,
                "Name": "gananaathaa nin ponnadi",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Mayuram Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8630,
                "Name": "kanakaangipai",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8631,
                "Name": "chelimi kori",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Tyagayya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8632,
                "Name": "jagadeesha paatali",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8633,
                "Name": "ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "TK GovindaRao",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8634,
                "Name": "ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlai",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8635,
                "Name": "paadham paNindhenendru",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlipanthu",
                "Thalam": "Adi",
                "Composer": "Varagur Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8636,
                "Name": "Shri raghukula thilaka",
                "Type": "Varnam-Tana",
                "Ragam": "Gowlipanthu",
                "Thalam": "Adi",
                "Composer": "Pathamadi Krishnayyar",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8637,
                "Name": "Devi meenalochani",
                "Type": "Varnam-Tana",
                "Ragam": "Jaganmohini",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8638,
                "Name": "Kamalamanohari",
                "Type": "Varnam-Tana",
                "Ragam": "KamalaManohari",
                "Thalam": "Adi",
                "Composer": "Varagur Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8639,
                "Name": "neevanti saamini",
                "Type": "Varnam-Tana",
                "Ragam": "KamalaManohari",
                "Thalam": "Adi",
                "Composer": "Thanjai Chinayya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8640,
                "Name": "Shri raamachandra",
                "Type": "Varnam-Tana",
                "Ragam": "KamalaManohari",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8641,
                "Name": "vanajaakshiro",
                "Type": "Varnam-Tana",
                "Ragam": "Lalitha",
                "Thalam": "Adi",
                "Composer": "Thanjavur Ponnaiyya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8642,
                "Name": "sevikku unavu",
                "Type": "Varnam-Tana",
                "Ragam": "MeghaRanjani",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8643,
                "Name": "megharanjani ghaanaamruta",
                "Type": "Varnam-Tana",
                "Ragam": "MeghaRanjani",
                "Thalam": "Adi",
                "Composer": "Pathamadi Krishnayyar",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8644,
                "Name": "kaalam karudhi iurppar",
                "Type": "Varnam-Tana",
                "Ragam": "Paadi",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8645,
                "Name": "kaamamOha daasanaanEnE",
                "Type": "Varnam-Tana",
                "Ragam": "Paadi",
                "Thalam": "Adi",
                "Composer": "Pathamadi Krishnayyar",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8646,
                "Name": "veNNira thaamarai",
                "Type": "Varnam-Tana",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Varagur Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8647,
                "Name": "saamaveda raasike",
                "Type": "Varnam-Tana",
                "Ragam": "Paras",
                "Thalam": "Adi",
                "Composer": "Pathamadi Krishnayyar",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8648,
                "Name": "svayam varENyam",
                "Type": "Varnam-Tana",
                "Ragam": "Revagupthi",
                "Thalam": "Adi",
                "Composer": "Pallavi Subbayya Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8649,
                "Name": "iravum pagalum",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachari",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8650,
                "Name": "maanE moham sahiyenE",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8651,
                "Name": "sarasuda",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8652,
                "Name": "thendral vandhu",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8653,
                "Name": "Jalajakshi ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8654,
                "Name": "nenarunchi",
                "Type": "Varnam-Tana",
                "Ragam": "Chakravaham",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8655,
                "Name": "yeN enba yEnai yezhuthenba",
                "Type": "Varnam-Tana",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8656,
                "Name": "uiyyum vagayE",
                "Type": "Varnam-Tana",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Varagur Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8657,
                "Name": "avaavinai aatra aruppin",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8658,
                "Name": "tharuNi ninnekori",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "NS Ramachandran",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8659,
                "Name": "valachiyunna naatho",
                "Type": "Varnam-Tana",
                "Ragam": "Valachi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8660,
                "Name": "arivinuL ellaam thalaiyenba",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Adi",
                "Composer": "Thiruvalluvar/VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8661,
                "Name": "athiroopamaana ivar",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "SS Nataraja Sundaram Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8662,
                "Name": "arumarai pugazhum",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Anantha Ksheerabhi Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8663,
                "Name": "Chiruthapraayamu",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8664,
                "Name": "netriravu kaathirundhu",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Tiger Varadachari/M Arunachalam Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8665,
                "Name": "Ninnukori",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Tachchur Singarachariar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8666,
                "Name": "surataru",
                "Type": "Varnam-Tana",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Pallavi Subbayya Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8667,
                "Name": "amudhinum inimaiyaa",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Tiger Varadachari",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8668,
                "Name": "Ninnukori",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8669,
                "Name": "maduraapuri vaasini",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "KeezhveLoor Meenakshisundaram Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8670,
                "Name": "vanitha ninnekori",
                "Type": "Varnam-Tana",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8671,
                "Name": "ambaa ninnai vENDiyE",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8672,
                "Name": "cheluvudani kori",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8673,
                "Name": "panthamela",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8674,
                "Name": "raa raa saami krupajuda",
                "Type": "Varnam-Tana",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Poochi Ramanathapuram Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8675,
                "Name": "Era napai",
                "Type": "Varnam-Tana",
                "Ragam": "Mukhari",
                "Thalam": "Adi",
                "Composer": "Pallavi Doraiswamy Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8676,
                "Name": "chalamela jese",
                "Type": "Varnam-Tana",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8677,
                "Name": "ninne nammi naanu",
                "Type": "Varnam-Tana",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8678,
                "Name": "Shri krishna naathaa",
                "Type": "Varnam-Tana",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8679,
                "Name": "varamaruLvaai",
                "Type": "Varnam-Tana",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Charumathi Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8680,
                "Name": "maathe en kaadalar",
                "Type": "Varnam-Tana",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Vajravelu Mudaliar",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8681,
                "Name": "ninne nammi naanu",
                "Type": "Varnam-Tana",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8682,
                "Name": "shri shaarade varade",
                "Type": "Varnam-Tana",
                "Ragam": "Abheri",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8683,
                "Name": "Evvari Bodhana",
                "Type": "Varnam-Tana",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8684,
                "Name": "kaadhal noikku",
                "Type": "Varnam-Tana",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8685,
                "Name": "thanjam endraale nenjam",
                "Type": "Varnam-Tana",
                "Ragam": "Abhogi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8686,
                "Name": "sarasijalochani",
                "Type": "Varnam-Tana",
                "Ragam": "Balamanjari",
                "Thalam": "Adi",
                "Composer": "Spencer R Venugopal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8687,
                "Name": "Tsaala mela",
                "Type": "Varnam-Tana",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8688,
                "Name": "mudharpaar moovaril",
                "Type": "Varnam-Tana",
                "Ragam": "Darbar",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachari",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8689,
                "Name": "devamanohari unnaye",
                "Type": "Varnam-Tana",
                "Ragam": "DevaManohari",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachari",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8690,
                "Name": "palumaru",
                "Type": "Varnam-Tana",
                "Ragam": "DevaManohari",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8691,
                "Name": "kannanai mani vannanai",
                "Type": "Varnam-Tana",
                "Ragam": "Huseni",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8692,
                "Name": "kannan thaniye vandhu",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8693,
                "Name": "kaamaakshi nee paadhamule",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "Venkatakrishna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8694,
                "Name": "ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Kaanada",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8695,
                "Name": "devaadhidevani",
                "Type": "Varnam-Tana",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8696,
                "Name": "inka dayaraadhaa",
                "Type": "Varnam-Tana",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Chitravina N Ravikiran",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8697,
                "Name": "Kanjadalayathakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Kapi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8698,
                "Name": "aaraavamudhe",
                "Type": "Varnam-Tana",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8699,
                "Name": "murugaa yenadhu aaruyire",
                "Type": "Varnam-Tana",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Periyasamy Turan/Sivaramakrishna iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8700,
                "Name": "saraguna nannela",
                "Type": "Varnam-Tana",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Tyagayya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8701,
                "Name": "aadhi vinaayakam",
                "Type": "Varnam-Tana",
                "Ragam": "Reethigowlai",
                "Thalam": "Adi",
                "Composer": "Chitravina N Ravikiran",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8702,
                "Name": "saarasadalanayana",
                "Type": "Varnam-Tana",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8703,
                "Name": "endukintamodi",
                "Type": "Varnam-Tana",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8704,
                "Name": "kachchi yuvaranga",
                "Type": "Varnam-Tana",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Annaswami Shastry",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8705,
                "Name": "Sami ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Sree",
                "Thalam": "Adi",
                "Composer": "Karur Devudu Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8706,
                "Name": "paradevate varade",
                "Type": "Varnam-Tana",
                "Ragam": "ShuddhaDhanyasi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8707,
                "Name": "sri raajamaathangi",
                "Type": "Varnam-Tana",
                "Ragam": "ShuddhaDhanyasi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8708,
                "Name": "Devi Gowrimanohari",
                "Type": "Varnam-Tana",
                "Ragam": "GowriManohari",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8709,
                "Name": "saarasaaksha ninnu",
                "Type": "Varnam-Tana",
                "Ragam": "Simhavahini",
                "Thalam": "Adi",
                "Composer": "Chitravina N Ravikiran",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8710,
                "Name": "mahishaasura mardini",
                "Type": "Varnam-Tana",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8711,
                "Name": "needayaraadhaa",
                "Type": "Varnam-Tana",
                "Ragam": "Andolika",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8712,
                "Name": "Entho premato",
                "Type": "Varnam-Tana",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "Lalgudi G Jayaraman",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8713,
                "Name": "neerajaakshi kaamaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Bahudari",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8714,
                "Name": "paahimaam sri",
                "Type": "Varnam-Tana",
                "Ragam": "Balahamsa",
                "Thalam": "Adi",
                "Composer": "TK GovindaRao",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8715,
                "Name": "mayile poompozhilurai",
                "Type": "Varnam-Tana",
                "Ragam": "Guhamanohari",
                "Thalam": "Adi",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8716,
                "Name": "chalamela",
                "Type": "Varnam-Tana",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8717,
                "Name": "ela nee daya raadhu",
                "Type": "Varnam-Tana",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8718,
                "Name": "alaikadal thuyilvoy",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8719,
                "Name": "saarasaakshi ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8720,
                "Name": "taruni ninnu",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Fiddle Ponnuswamy",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8721,
                "Name": "vaadhugaL purivadhum",
                "Type": "Varnam-Tana",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8722,
                "Name": "kOlamayil paagan",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8723,
                "Name": "naadhaa naavalar paaDum",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8724,
                "Name": "neeye mudhalvan yaane adimai",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8725,
                "Name": "sami dayajuda",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Tyagayya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8726,
                "Name": "saami neeye gathi",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8727,
                "Name": "saarasaaksha ninnu",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Subbarama Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8728,
                "Name": "viriboni",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Adi",
                "Composer": "Veenai Varadayya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8729,
                "Name": "e maaya jesitivo",
                "Type": "Varnam-Tana",
                "Ragam": "Malavi",
                "Thalam": "Adi",
                "Composer": "Spencer R Venugopal",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8730,
                "Name": "aalilai mel",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8731,
                "Name": "mohanavalli en maadhu",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachari",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8732,
                "Name": "ninne korinadiraa",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Pallavi Doraiswamy Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8733,
                "Name": "ninnu kori",
                "Type": "Varnam-Tana",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Poochi Ramanathapuram Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8734,
                "Name": "chalamela",
                "Type": "Varnam-Tana",
                "Ragam": "NarayanaGowlai",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8735,
                "Name": "saarasa ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8736,
                "Name": "ninne nammiyunna",
                "Type": "Varnam-Tana",
                "Ragam": "YadukulaKhambhoji",
                "Thalam": "Adi",
                "Composer": "Chitravina N Ravikiran",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8737,
                "Name": "bhudhathaalaagiya punidha",
                "Type": "Varnam-Tana",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8738,
                "Name": "Karunimpa",
                "Type": "Varnam-Tana",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8739,
                "Name": "manamirangaadhadhenO",
                "Type": "Varnam-Tana",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8740,
                "Name": "thendral veesa",
                "Type": "Varnam-Tana",
                "Ragam": "Sahana",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachari",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8741,
                "Name": "cheliyarone",
                "Type": "Varnam-Tana",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8742,
                "Name": "entho prematho",
                "Type": "Varnam-Tana",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8743,
                "Name": "neepaadame gathiyani",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Pazhani Subramanya Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8744,
                "Name": "sami ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8745,
                "Name": "nelatharo",
                "Type": "Varnam-Tana",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8746,
                "Name": "soodhinai ariyaa",
                "Type": "Varnam-Tana",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8747,
                "Name": "anname",
                "Type": "Varnam-Tana",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8748,
                "Name": "sarasijamukhiro",
                "Type": "Varnam-Tana",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Pallavi Doraiswamy Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8749,
                "Name": "swaami unnai sadaa",
                "Type": "Varnam-Tana",
                "Ragam": "Arabhi",
                "Thalam": "Adi",
                "Composer": "Papansam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8750,
                "Name": "Vanajaksha ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Behag",
                "Thalam": "Adi",
                "Composer": "TR Subramanyam",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8751,
                "Name": "dayaanidhe maamava",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Shyama Shastry",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8752,
                "Name": "intha tsaalamu jesite",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8753,
                "Name": "mazhalai sollaal azhaikirEn",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachari",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8754,
                "Name": "nerajaanavani",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8755,
                "Name": "sariyo nee seiyum thaamadham",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8756,
                "Name": "sadhguru paadhathai",
                "Type": "Varnam-Tana",
                "Ragam": "Begada",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8757,
                "Name": "arul paar aiyaa",
                "Type": "Varnam-Tana",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Koteeswara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8758,
                "Name": "inta chauka",
                "Type": "Varnam-Tana",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8759,
                "Name": "pagal ellaam nin padham",
                "Type": "Varnam-Tana",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8760,
                "Name": "jalakaaksha",
                "Type": "Varnam-Tana",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8761,
                "Name": "pagavaari bodhana vini",
                "Type": "Varnam-Tana",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8762,
                "Name": "shrungaara jana ranjana",
                "Type": "Varnam-Tana",
                "Ragam": "Janaranjani",
                "Thalam": "Adi",
                "Composer": "Madhraananda Dasa",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8763,
                "Name": "kuvalayaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Kathanakuthuhalam",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8764,
                "Name": "neeve rakshakudavu",
                "Type": "Varnam-Tana",
                "Ragam": "Kathanakuthuhalam",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8765,
                "Name": "andhami vadamozhi aandhiram",
                "Type": "Varnam-Tana",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachari",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8766,
                "Name": "saami idi velagaadura",
                "Type": "Varnam-Tana",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8767,
                "Name": "saami nee paadamula",
                "Type": "Varnam-Tana",
                "Ragam": "Kedaram",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8768,
                "Name": "entana vedi",
                "Type": "Varnam-Tana",
                "Ragam": "Navaroj",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8769,
                "Name": "maguva neepai",
                "Type": "Varnam-Tana",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Veenai Varadayya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8770,
                "Name": "saaketha nagara",
                "Type": "Varnam-Tana",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8771,
                "Name": "saami neepai",
                "Type": "Varnam-Tana",
                "Ragam": "Nagaswarali",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8772,
                "Name": "devi dayaapari",
                "Type": "Varnam-Tana",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8773,
                "Name": "sri mahaabalashaila",
                "Type": "Varnam-Tana",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8774,
                "Name": "innamum sodhanaiyo",
                "Type": "Varnam-Tana",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "TR Subramanyam",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8775,
                "Name": "nera janau",
                "Type": "Varnam-Tana",
                "Ragam": "Sama",
                "Thalam": "Adi",
                "Composer": "Kothavasal Venkataramayyar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8776,
                "Name": "aanai maamugane",
                "Type": "Varnam-Tana",
                "Ragam": "GambhiraNaatai",
                "Thalam": "Adi",
                "Composer": "Varagur Muthukumaraswamy",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8777,
                "Name": "aathurajanadhaarini",
                "Type": "Varnam-Tana",
                "Ragam": "GambhiraNaatai",
                "Thalam": "Adi",
                "Composer": "Pallavi Subbayya Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8778,
                "Name": "saadhujanaparipaalini",
                "Type": "Varnam-Tana",
                "Ragam": "GambhiraNaatai",
                "Thalam": "Adi",
                "Composer": "Pathamadi Krishnayyar",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8779,
                "Name": "e naadhamulo",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8780,
                "Name": "jaya jaya hari",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Purandaradasar/TK Govindarao",
                "Song Language": "Kannada",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8781,
                "Name": "sarasijanabha",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Palakkadu Parameswara Bhagavathar",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8782,
                "Name": "Sami ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8783,
                "Name": "kaamashathakOti sundara",
                "Type": "Varnam-Tana",
                "Ragam": "Jhalavarali",
                "Thalam": "Adi",
                "Composer": "Veenai Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8784,
                "Name": "tOyajaakshirO",
                "Type": "Varnam-Tana",
                "Ragam": "Varali",
                "Thalam": "Adi",
                "Composer": "KaarvET nagar Govindasamayya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8785,
                "Name": "ellaam haran seyal",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8786,
                "Name": "maal ariyaa naanmukhanum",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Arunachalam Pillai/Vajravelu Mudaliar",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8787,
                "Name": "neeye pedhai mukham",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8788,
                "Name": "sami ninnenammina",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanyaiyer/Shatkala Narasayya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8789,
                "Name": "sami ni todi",
                "Type": "Varnam-Tana",
                "Ragam": "Pantuvarali",
                "Thalam": "Adi",
                "Composer": "Poochi Ramanathapuram Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8790,
                "Name": "Vanajaksha ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Mandari",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8791,
                "Name": "pankajakshi ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "KalyanaKrishna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8792,
                "Name": "veyuru tholi pangan",
                "Type": "Varnam-Tana",
                "Ragam": "Poorvikalyani",
                "Thalam": "Adi",
                "Composer": "Gnanasambandar/AS Panchapakesa Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8793,
                "Name": "murugaa un mel",
                "Type": "Varnam-Tana",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Arunachala Annavi",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8794,
                "Name": "padapankejaatamulan",
                "Type": "Varnam-Tana",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8795,
                "Name": "jaalamela karuna",
                "Type": "Varnam-Tana",
                "Ragam": "Hemavathi",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8796,
                "Name": "needu charanamule",
                "Type": "Varnam-Tana",
                "Ragam": "Vijayanagari",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8797,
                "Name": "ninnukori",
                "Type": "Varnam-Tana",
                "Ragam": "Dharmavathi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8798,
                "Name": "amboruha paadame",
                "Type": "Varnam-Tana",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Composer": "GN Balasubramanian",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8799,
                "Name": "neeraja lochani",
                "Type": "Varnam-Tana",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8800,
                "Name": "neeve nannu",
                "Type": "Varnam-Tana",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8801,
                "Name": "sami ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Ranjani",
                "Thalam": "Adi",
                "Composer": "MD Ramanathan",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8802,
                "Name": "Aimkara hrimkara",
                "Type": "Varnam-Tana",
                "Ragam": "Omkara Swaroopini",
                "Thalam": "Adi",
                "Composer": "AdiShankara/TK Govindarao",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8803,
                "Name": "Sri kumaarasodaram",
                "Type": "Varnam-Tana",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8804,
                "Name": "sri poornathrayeesham",
                "Type": "Varnam-Tana",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Composer": "TK GovindaRao",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8805,
                "Name": "sarasiruhasanapriye",
                "Type": "Varnam-Tana",
                "Ragam": "Lathangi",
                "Thalam": "Adi",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8806,
                "Name": "ninnane paaduve",
                "Type": "Varnam-Tana",
                "Ragam": "Vachaspathi",
                "Thalam": "Adi",
                "Composer": "Purandaradasar/TK Govindarao",
                "Song Language": "Kannada",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8807,
                "Name": "ambalathaadum en",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "VS Gomathi Shankara Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8808,
                "Name": "karuNaikkadalE",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Tiger Varadachariar",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8809,
                "Name": "sarojaaksha raama",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8810,
                "Name": "vanajaakshiro",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Poochi Ramanathapuram Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8811,
                "Name": "vaa vaa maha ganapathi",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Arunachala Annavi",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8812,
                "Name": "sendhil vaazh",
                "Type": "Varnam-Tana",
                "Ragam": "HamirKalyani",
                "Thalam": "Adi",
                "Composer": "TR Subramanyam",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8813,
                "Name": "Intha modi",
                "Type": "Varnam-Tana",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Thiruvotriyur Thyagayya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8814,
                "Name": "ninne kori",
                "Type": "Varnam-Tana",
                "Ragam": "Saranga",
                "Thalam": "Adi",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8815,
                "Name": "gananaayaka vaaranamukha",
                "Type": "Varnam-Tana",
                "Ragam": "Sunadavinodini",
                "Thalam": "Adi",
                "Composer": "NS Ramachandran",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8816,
                "Name": "sami neepai",
                "Type": "Varnam-Tana",
                "Ragam": "Amrithavarshini",
                "Thalam": "Adi",
                "Composer": "Veenai Varadayya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8817,
                "Name": "gnaana maargam naane",
                "Type": "Varnam-Tana",
                "Ragam": "Hamsanadam",
                "Thalam": "Adi",
                "Composer": "TM Thyagarajan/Vedanayagam Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8818,
                "Name": "kanakangi",
                "Type": "Varnam-Ata",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Pallavi Gopalaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8819,
                "Name": "naamida dayanunchi",
                "Type": "Varnam-Ata",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8820,
                "Name": "thaanaagi nindra devaa",
                "Type": "Varnam-Ata",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Mayuram Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8821,
                "Name": "sri shubha hamsagamana",
                "Type": "Varnam-Ata",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8822,
                "Name": "vanajakshi ninnu",
                "Type": "Varnam-Ata",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8823,
                "Name": "nenarunchi",
                "Type": "Varnam-Ata",
                "Ragam": "Dhanyasi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8824,
                "Name": "naadhane parane",
                "Type": "Varnam-Ata",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Thirupampuram N Swaminatha Pillai/M. Arunachala Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8825,
                "Name": "janani",
                "Type": "Varnam-Ata",
                "Ragam": "Bhowli",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8826,
                "Name": "palumaaru",
                "Type": "Varnam-Ata",
                "Ragam": "Gowlai",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8827,
                "Name": "kaliki ninne",
                "Type": "Varnam-Ata",
                "Ragam": "Saveri",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8828,
                "Name": "manasEthiki",
                "Type": "Varnam-Ata",
                "Ragam": "Saveri",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8829,
                "Name": "Vanajaaksha",
                "Type": "Varnam-Ata",
                "Ragam": "Saveri",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8830,
                "Name": "Inka chalamela",
                "Type": "Varnam-Ata",
                "Ragam": "Chakravaham",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8831,
                "Name": "Maa Madurai meen vizhiyaaL",
                "Type": "Varnam-Ata",
                "Ragam": "Malayamarutham",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Thirupampuram N Swaminatha Pillai/M. Arunachala Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8832,
                "Name": "Valachi vacchi",
                "Type": "Varnam-Ata",
                "Ragam": "Sowrashtram",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Thanjai Ponniah",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8833,
                "Name": "asaindha thozhum",
                "Type": "Varnam-Ata",
                "Ragam": "Vasantha",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Nerur Srinivasachari",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8834,
                "Name": "yemi sethune",
                "Type": "Varnam-Ata",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8835,
                "Name": "sri raamachandrane",
                "Type": "Varnam-Ata",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Thirupampuram N Swaminatha Pillai/M. Arunachala Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8836,
                "Name": "thogai maamayil",
                "Type": "Varnam-Ata",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Thanjai Ponniah",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8837,
                "Name": "Viriboni",
                "Type": "Varnam-Ata",
                "Ragam": "Bhairavi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Pachchaimiriyam Adiyappa",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8838,
                "Name": "saami neepai",
                "Type": "Varnam-Ata",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8839,
                "Name": "maanchi panatiraaninnu",
                "Type": "Varnam-Ata",
                "Ragam": "Hindolam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8840,
                "Name": "maaye manamuvandharul",
                "Type": "Varnam-Ata",
                "Ragam": "Abhogi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Mayuram Vishwanatha Shastry",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8841,
                "Name": "intha modiyelara",
                "Type": "Varnam-Ata",
                "Ragam": "Darbar",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Subbarama Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8842,
                "Name": "nera nammithi",
                "Type": "Varnam-Ata",
                "Ragam": "Kaanada",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Poochi Ramanathapuram Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8843,
                "Name": "madiraakshi",
                "Type": "Varnam-Ata",
                "Ragam": "Mukhari",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8844,
                "Name": "vanajaaksha",
                "Type": "Varnam-Ata",
                "Ragam": "Reethigowlai",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8845,
                "Name": "ithu jaagu jesedi",
                "Type": "Varnam-Ata",
                "Ragam": "Sree",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8846,
                "Name": "vaanOr paNi",
                "Type": "Varnam-Ata",
                "Ragam": "HariKhambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Thirupampuram N Swaminatha Pillai/M. Arunachala Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8847,
                "Name": "intha chalamuseya",
                "Type": "Varnam-Ata",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Pallavi Gopalaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8848,
                "Name": "kaayaa malarmeniyane",
                "Type": "Varnam-Ata",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Thirupampuram N Swaminatha Pillai/M. Arunachala Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8849,
                "Name": "sri mahaganapathe",
                "Type": "Varnam-Ata",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8850,
                "Name": "sri rangavaasa",
                "Type": "Varnam-Ata",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Nerur Srinivasachari",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8851,
                "Name": "sarasijanaabha ninnu",
                "Type": "Varnam-Ata",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8852,
                "Name": "saami naapai",
                "Type": "Varnam-Ata",
                "Ragam": "KedaraGowlai",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8853,
                "Name": "sarasijaakshi",
                "Type": "Varnam-Ata",
                "Ragam": "Mohanam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8854,
                "Name": "Sami ninne kori",
                "Type": "Varnam-Ata",
                "Ragam": "Narayani",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Poochi Ramanathapuram Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8855,
                "Name": "maguva ninne",
                "Type": "Varnam-Ata",
                "Ragam": "NarayanaGowlai",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8856,
                "Name": "Intha parakela",
                "Type": "Varnam-Ata",
                "Ragam": "Naatakurinji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8857,
                "Name": "saamigaane ninnu juchi",
                "Type": "Varnam-Ata",
                "Ragam": "Naatakurinji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8858,
                "Name": "saamini vega rammanave",
                "Type": "Varnam-Ata",
                "Ragam": "Naatakurinji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8859,
                "Name": "Evaremi Bodhana",
                "Type": "Varnam-Ata",
                "Ragam": "Sahana",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8860,
                "Name": "maguvaro nedu",
                "Type": "Varnam-Ata",
                "Ragam": "Sahana",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8861,
                "Name": "vaarijaakshi",
                "Type": "Varnam-Ata",
                "Ragam": "Sahana",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Subbarama Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8862,
                "Name": "kamalaakshi ninne",
                "Type": "Varnam-Ata",
                "Ragam": "YadukulaKhambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8863,
                "Name": "atram idhu thaan",
                "Type": "Varnam-Ata",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Nerur Srinivasachari",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8864,
                "Name": "Chalamela",
                "Type": "Varnam-Ata",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8865,
                "Name": "endha neramum saamiyai",
                "Type": "Varnam-Ata",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Thanjai Ponniah",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8866,
                "Name": "indhu mukhi",
                "Type": "Varnam-Ata",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Manipravalam",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8867,
                "Name": "niluparaani",
                "Type": "Varnam-Ata",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8868,
                "Name": "raaraapusEyaka",
                "Type": "Varnam-Ata",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Ramaswami Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8869,
                "Name": "undhan paadha",
                "Type": "Varnam-Ata",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Madurai Subramanya Iyer",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8870,
                "Name": "O manini maarubaariki",
                "Type": "Varnam-Ata",
                "Ragam": "Arabhi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8871,
                "Name": "intha priyamuganunna",
                "Type": "Varnam-Ata",
                "Ragam": "Begada",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Thiruvarur Ayyasami Nattuvanar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8872,
                "Name": "marachitlundedi",
                "Type": "Varnam-Ata",
                "Ragam": "Begada",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8873,
                "Name": "nenarunchi",
                "Type": "Varnam-Ata",
                "Ragam": "Bilahari",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8874,
                "Name": "Ninne nammi",
                "Type": "Varnam-Ata",
                "Ragam": "Devagandhari",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8875,
                "Name": "Padaredi",
                "Type": "Varnam-Ata",
                "Ragam": "Devagandhari",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "NS Ramachandran",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8876,
                "Name": "aanai mukhane en annale",
                "Type": "Varnam-Ata",
                "Ragam": "Hamsadhwani",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Koorainaadu Natesa Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8877,
                "Name": "chaala ninne",
                "Type": "Varnam-Ata",
                "Ragam": "Kedaram",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tiger Varadachariar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8878,
                "Name": "sarasijaakshi",
                "Type": "Varnam-Ata",
                "Ragam": "Kedaram",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8879,
                "Name": "Ikanelane",
                "Type": "Varnam-Ata",
                "Ragam": "Poornachandrika",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8880,
                "Name": "vaNDaar poonkuzhal",
                "Type": "Varnam-Ata",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Thirupampuram N Swaminatha Pillai/M. Arunachala Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8881,
                "Name": "srinaatha ninnu naadu",
                "Type": "Varnam-Ata",
                "Ragam": "Naatai",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8882,
                "Name": "Sri raajaadhi raaja",
                "Type": "Varnam-Ata",
                "Ragam": "Naatai",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Baluswami Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8883,
                "Name": "samukhamu",
                "Type": "Varnam-Ata",
                "Ragam": "Naatai",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Thanjai Vadivelu",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8884,
                "Name": "Chalamu jesedi",
                "Type": "Varnam-Ata",
                "Ragam": "Pantuvarali",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Thanjai Ponniah",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8885,
                "Name": "cheliyapai",
                "Type": "Varnam-Ata",
                "Ragam": "Pantuvarali",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Veenai Kuppaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8886,
                "Name": "Sri Kaanchi kaamakoti",
                "Type": "Varnam-Ata",
                "Ragam": "Pantuvarali",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Subbarama Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8887,
                "Name": "vizhimizhalai",
                "Type": "Varnam-Ata",
                "Ragam": "Simhendramadhyamam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Thirupampuram N Swaminatha Pillai/M. Arunachala Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8888,
                "Name": "Vandanamanorinchita",
                "Type": "Varnam-Ata",
                "Ragam": "Vachaspathi",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tiger Varadachariar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8889,
                "Name": "Saarassakshi",
                "Type": "Varnam-Ata",
                "Ragam": "Kalyani",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8890,
                "Name": "Sudati ninne",
                "Type": "Varnam-Ata",
                "Ragam": "Kalyani",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8891,
                "Name": "Vanajaakshi ninne",
                "Type": "Varnam-Ata",
                "Ragam": "Kalyani",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Pallavi Gopalaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8892,
                "Name": "Gaanaloludaina",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Khanda Jathi Dhuruva Thalam",
                "Composer": "Veenai Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8893,
                "Name": "Kopam koLvadhEnO",
                "Type": "Varnam-Tana",
                "Ragam": "Thodi",
                "Thalam": "Tisra jathi Eka Thalam",
                "Composer": "K Ponniah Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8894,
                "Name": "neerajaakshi vinavamma",
                "Type": "Varnam-Tana",
                "Ragam": "Saveri",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Veenai Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8895,
                "Name": "naa manavini",
                "Type": "Varnam-Tana",
                "Ragam": "Sowrashtram",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "Shyama Shastry",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8896,
                "Name": "raamuni kori",
                "Type": "Varnam-Tana",
                "Ragam": "Abhogi",
                "Thalam": "Khanda Jathi Triputa Thalam",
                "Composer": "Poochi Ramanathapuram Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8897,
                "Name": "kamalaakshi ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Manirangu",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Tiger Varadachariar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8898,
                "Name": "naayaki maaluru",
                "Type": "Varnam-Tana",
                "Ragam": "Nayaki",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Nerur Srinivasachari",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8899,
                "Name": "Ninnentho",
                "Type": "Varnam-Tana",
                "Ragam": "DevaManohari",
                "Thalam": "Khanda Jathi triputa Thalam",
                "Composer": "Poochi Ramanathapuram Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8900,
                "Name": "dayajudavammaa",
                "Type": "Varnam-Tana",
                "Ragam": "Sree",
                "Thalam": "Tisra Jathi Ata Thalam (Gupta)",
                "Composer": "Cuddalore M Subramaniam",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8901,
                "Name": "maayamelara",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Khanda Jathi triputa Thalam",
                "Composer": "Veenai Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8902,
                "Name": "saarasaakshiro",
                "Type": "Varnam-Tana",
                "Ragam": "Naatakurinji",
                "Thalam": "Mishra Chapu",
                "Composer": "Thanjavur Ponnaiyya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8903,
                "Name": "Sri venugopala",
                "Type": "Varnam-Tana",
                "Ragam": "Sahana",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "TK GovindaRao",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8904,
                "Name": "intha chalamuseya",
                "Type": "Varnam-Tana",
                "Ragam": "Surutti",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "K Ponniah Pillai",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8905,
                "Name": "viriboni",
                "Type": "Varnam-Tana",
                "Ragam": "KedaraGowlai",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Rudrapatnam Venkataramayya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8906,
                "Name": "karunakaruda",
                "Type": "Varnam-Tana",
                "Ragam": "Atana",
                "Thalam": "Tisra Jathi Ata Thalam (Gupta)",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8907,
                "Name": "saami neepai",
                "Type": "Varnam-Tana",
                "Ragam": "Neelambari",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Chitravina N Ravikiran",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8908,
                "Name": "paavaai nee paaraai",
                "Type": "Varnam-Tana",
                "Ragam": "Varali",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Thanjai Ponniah",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8909,
                "Name": "Vanajaaksha",
                "Type": "Varnam-Tana",
                "Ragam": "Varali",
                "Thalam": "Chatusra Jathi Ata Thalam",
                "Composer": "Poochi Ramanathapuram Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8910,
                "Name": "Sami ninne",
                "Type": "Varnam-Tana",
                "Ragam": "Poorvikalyani",
                "Thalam": "Khanda Jathi Matya Thalam",
                "Composer": "Veenai Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8911,
                "Name": "Saarasaakshi",
                "Type": "Varnam-Tana",
                "Ragam": "Poorvikalyani",
                "Thalam": "Mishra Jathi Roopaka Thalam (Kula)",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8912,
                "Name": "Sarasamutho",
                "Type": "Varnam-Tana",
                "Ragam": "Shanmugapriya",
                "Thalam": "Tisra Jathi Dhuruva Thalam",
                "Composer": "Mudikondan Venkataramayyar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8913,
                "Name": "Neeve Gathiyani",
                "Type": "Varnam-Tana",
                "Ragam": "Kalyani",
                "Thalam": "Tisra Jathi Matya Thalam (Saara)",
                "Composer": "Shyama Shastry",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8914,
                "Name": "Saaranganayanaro",
                "Type": "Varnam-Tana",
                "Ragam": "Saranga",
                "Thalam": "Roopakam",
                "Composer": "Thanjai Vadivelu",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8915,
                "Name": "Danike Tagu",
                "Type": "Varnam-Pada",
                "Ragam": "Thodi",
                "Thalam": "Roopakam",
                "Composer": "Thanjai Sivanandam",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8916,
                "Name": "Dani Samajendra",
                "Type": "Varnam-Pada",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8917,
                "Name": "Dani saati",
                "Type": "Varnam-Pada",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Thanjai Sivanandam",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8918,
                "Name": "intha kopamelara",
                "Type": "Varnam-Pada",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Pandanalloor Meenakshi Sundaram Pillai",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8919,
                "Name": "Mogalaagiri",
                "Type": "Varnam-Pada",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Thanjai Sivanandam",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8920,
                "Name": "Roopamu juchi",
                "Type": "Varnam-Chauka",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Muthuswamy Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8921,
                "Name": "Saraguna gaavumu",
                "Type": "Varnam-Pada",
                "Ragam": "Thodi",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8922,
                "Name": "Saridisaavaasa",
                "Type": "Varnam-Stava",
                "Ragam": "Thodi",
                "Thalam": "Mishra Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8923,
                "Name": "Saadhuvibhaat",
                "Type": "Varnam-Pada",
                "Ragam": "Bhupalam",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8924,
                "Name": "E maguva",
                "Type": "Varnam-Pada",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Mysore Sadashiva Rao",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8925,
                "Name": "Haa hanta Vaanchitam",
                "Type": "Varnam-Pada",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8926,
                "Name": "nee indha maayam seidhaal",
                "Type": "Varnam-Pada",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8927,
                "Name": "Saami naapai",
                "Type": "Varnam-Pada",
                "Ragam": "Dhanyasi",
                "Thalam": "Adi",
                "Composer": "Thanjai Ponniah",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8928,
                "Name": "Saa paramavivaashaa",
                "Type": "Varnam-Pada",
                "Ragam": "Ghanta",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8929,
                "Name": "Om pranava vimala",
                "Type": "Varnam-Pada",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Krishnamurthy Pillai",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8930,
                "Name": "Maayam yedho swaami",
                "Type": "Varnam-Pada",
                "Ragam": "Mayamalawagowlai",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8931,
                "Name": "yela nee vaani",
                "Type": "Varnam-Chauka",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Tiger Varadachariar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8932,
                "Name": "Saaveriha tanuja",
                "Type": "Varnam-Stava",
                "Ragam": "Saveri",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8933,
                "Name": "Malayamaruthamuche",
                "Type": "Varnam-Pada",
                "Ragam": "Malayamarutham",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8934,
                "Name": "paramakula",
                "Type": "Varnam-Pada",
                "Ragam": "Sowrashtram",
                "Thalam": "Roopakam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8935,
                "Name": "Daani korikenu",
                "Type": "Varnam-Pada",
                "Ragam": "Vasantha",
                "Thalam": "Mishra Jathi Jhampa Thalam",
                "Composer": "Poochi Ramanathapuram Srinivasa Iyengar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8936,
                "Name": "ninnai naan nambi vandhen",
                "Type": "Varnam-Pada",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8937,
                "Name": "chapalasampada",
                "Type": "Varnam-Pada",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8938,
                "Name": "Neesati",
                "Type": "Varnma-Pada",
                "Ragam": "Bhairavi",
                "Thalam": "Roopakam",
                "Composer": "Thanjai Ponniah",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8939,
                "Name": "Saamini Rammanave",
                "Type": "Varnam-Pada",
                "Ragam": "Bhairavi",
                "Thalam": "Adi",
                "Composer": "Thanjai Ponniah",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8940,
                "Name": "Chalamu jesite ika",
                "Type": "Varnam-Pada",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Thanjai Ponniah",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8941,
                "Name": "pankajalochanira",
                "Type": "Varnam-Pada",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8942,
                "Name": "Saamini Rammanave",
                "Type": "Varnam-Chauka",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Subbarama Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8943,
                "Name": "saarekunitu",
                "Type": "Varnam-Chauka",
                "Ragam": "Ananda Bhairavi",
                "Thalam": "Adi",
                "Composer": "Subbarama Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8944,
                "Name": "rammanave",
                "Type": "Varnam-Chauka",
                "Ragam": "Hindolam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Ramaswami Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8945,
                "Name": "Valachi vacchi",
                "Type": "Varnam-Chauka",
                "Ragam": "HindolaVasantham",
                "Thalam": "Roopakam",
                "Composer": "Ramaswami Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8946,
                "Name": "unnai ninaindhu ennai",
                "Type": "Varnam-Pada",
                "Ragam": "Keeravani",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8947,
                "Name": "ninne nera",
                "Type": "Varnam-Pada",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8948,
                "Name": "Raamaa Ee vELa",
                "Type": "Varnam-Pada",
                "Ragam": "Kharaharapriya",
                "Thalam": "Adi",
                "Composer": "Thenmatham Sri Narasimhachari",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8949,
                "Name": "Sri Raajagopala",
                "Type": "Varnam-Pada",
                "Ragam": "Abhogi",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Walajapet Venkatramana Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8950,
                "Name": "unnai ninaindhu uLLam",
                "Type": "Varnam-Pada",
                "Ragam": "DevaManohari",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8951,
                "Name": "sarasaa ninnu ippuDu",
                "Type": "Varnam-Pada",
                "Ragam": "Kapi",
                "Thalam": "Tisra jathi Eka Thalam",
                "Composer": "Thanjavur Ponnaiyya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8952,
                "Name": "sumasaayaka",
                "Type": "Varnam-Pada",
                "Ragam": "Kapi",
                "Thalam": "Tisra jathi Eka Thalam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8953,
                "Name": "saadaraamiha bhaje",
                "Type": "Varnam-Stava",
                "Ragam": "Madhyamavathi",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8954,
                "Name": "chaala valachiyunnadiraa",
                "Type": "Varnam-Pada",
                "Ragam": "Manirangu",
                "Thalam": "Tisra jathi Eka Thalam",
                "Composer": "Pallavi Seshaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8955,
                "Name": "valachiyunna naapai",
                "Type": "Varnam-Pada",
                "Ragam": "Pushpalathika",
                "Thalam": "Adi",
                "Composer": "Akili Kalahasthi Venkatasami",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8956,
                "Name": "ehi manmathakOTi sundara",
                "Type": "Varnam-Pada",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Tiger Varadachariar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8957,
                "Name": "Sami ninne kori",
                "Type": "Varnam-Chauka",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Ramaswami Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8958,
                "Name": "swaami nee manam irangi",
                "Type": "Varnam-Pada",
                "Ragam": "SriRanjani",
                "Thalam": "Adi",
                "Composer": "Papansam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8959,
                "Name": "Sri raajaadhi raaja",
                "Type": "Varnam-Pada",
                "Ragam": "Balahamsa",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Subbarama Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8960,
                "Name": "Enthani ne delupudura",
                "Type": "Varnam-Pada",
                "Ragam": "Khamas",
                "Thalam": "Tisra jathi Eka Thalam",
                "Composer": "Subbarama Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8961,
                "Name": "samini rammanave",
                "Type": "Varnam-Pada",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Thanjavur Ponnaiyya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8962,
                "Name": "Saa vaamaa rushaa",
                "Type": "Varnam-Pada",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8963,
                "Name": "kamalaakshi ninne",
                "Type": "Varnam-Pada",
                "Ragam": "Khambhoji",
                "Thalam": "Misra Jathi Jhampa Thalam",
                "Composer": "Kundrakkudi Krishnaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8964,
                "Name": "pankajaakshipai",
                "Type": "Varnam-Pada",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Maha Vaidhyanatha Sivan",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8965,
                "Name": "Sarasijanabha",
                "Type": "Varnam-Pada",
                "Ragam": "Khambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Swathi Thirunal/Thanjavur Vadivelu",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8966,
                "Name": "Saroojaakshiro",
                "Type": "Varnam-Pada",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8967,
                "Name": "Saarasasama",
                "Type": "Varnam-Pada",
                "Ragam": "Khambhoji",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8968,
                "Name": "dayajudavayyaa",
                "Type": "Varnam-Pada",
                "Ragam": "Kuntalavarali",
                "Thalam": "Adi",
                "Composer": "Chitravina N Ravikiran",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8969,
                "Name": "Ainkaranai paNivaai",
                "Type": "Varnam-Pada",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8970,
                "Name": "vaarijaakshi",
                "Type": "Varnam-Pada",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8971,
                "Name": "Chalamela",
                "Type": "Varnam-Pada",
                "Ragam": "Naatakurinji",
                "Thalam": "Adi",
                "Composer": "Srirangam Mullaiveetu Rangasami Nattuvanar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8972,
                "Name": "punnagai azhagil",
                "Type": "Varnam-Pada",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Chitravina N Ravikiran",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8973,
                "Name": "saami entani",
                "Type": "Varnam-Chauka",
                "Ragam": "Surutti",
                "Thalam": "Tisra jathi Eka Thalam",
                "Composer": "Subbarama Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8974,
                "Name": "Saamini Thodi",
                "Type": "Varnam-Pada",
                "Ragam": "Surutti",
                "Thalam": "Adi",
                "Composer": "Pallavi Seshaiyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8975,
                "Name": "Chaalanammina",
                "Type": "Varnam-Chauka",
                "Ragam": "YadukulaKhambhoji",
                "Thalam": "Adi",
                "Composer": "Panchapakesa Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8976,
                "Name": "Sri raajeevaakshaadi",
                "Type": "Varnam-Pada",
                "Ragam": "YadukulaKhambhoji",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Subbarama Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8977,
                "Name": "saami ninne nammitiraa",
                "Type": "Varnam-Pada",
                "Ragam": "YadukulaKhambhoji",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8978,
                "Name": "Chalamela",
                "Type": "Varnam-Pada",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8979,
                "Name": "indhu mukhi",
                "Type": "Varnam-Pada",
                "Ragam": "Shankarabharanam",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Manipravalam",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8980,
                "Name": "manavikaikonarada",
                "Type": "Varnam-Pada",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Thanjavur Ponnaiyya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8981,
                "Name": "SaarasaakshuDa",
                "Type": "Varnam-Pada",
                "Ragam": "Shankarabharanam",
                "Thalam": "Adi",
                "Composer": "Thanjavur Ponnaiyya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8982,
                "Name": "Vanajaakshi vaani",
                "Type": "Varnam-Pada",
                "Ragam": "Shankarabharanam",
                "Thalam": "Tisra jathi Eka Thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8983,
                "Name": "Karuna judave",
                "Type": "Varnam-Pada",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8984,
                "Name": "Sri mahaajaasrutha",
                "Type": "Varnam-Pada",
                "Ragam": "Atana",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Subbarama Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8985,
                "Name": "Sri Raajaraaja",
                "Type": "Varnam-Pada",
                "Ragam": "Atana",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Subbarama Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8986,
                "Name": "Sarasijanaabha",
                "Type": "Varnam-Pada",
                "Ragam": "Atana",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8987,
                "Name": "Sami ninne kori",
                "Type": "Varnam-Pada",
                "Ragam": "Atana",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8988,
                "Name": "Raamaa vaakhilaripu",
                "Type": "Varnam-Pada",
                "Ragam": "Begada",
                "Thalam": "Mishra Chapu",
                "Composer": "Swathi Thirunal",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8989,
                "Name": "Swaami undran charanam sharanam",
                "Type": "Varnam-Chauka",
                "Ragam": "Bilahari",
                "Thalam": "Adi",
                "Composer": "Papanasam Sivan",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8990,
                "Name": "annai kalpagam anbudan",
                "Type": "Varnam-Pada",
                "Ragam": "Hamsadhwani",
                "Thalam": "Adi",
                "Composer": "Dr Rukmini Ramani",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8991,
                "Name": "alugaanelaraa",
                "Type": "Varnam-Chauka",
                "Ragam": "Kannadaa",
                "Thalam": "Adi",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8992,
                "Name": "E varaahametula",
                "Type": "Varnam-Chauka",
                "Ragam": "Neelambari",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Unknown",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8993,
                "Name": "saarasa shara sundara",
                "Type": "Varnam-Pada",
                "Ragam": "Neelambari",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8994,
                "Name": "Ela nannechevu",
                "Type": "Varnam-Chauka",
                "Ragam": "Poornachandrika",
                "Thalam": "Roopakam",
                "Composer": "Ramaswami Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8995,
                "Name": "paalayamaam deva",
                "Type": "Varnam-Pada",
                "Ragam": "Poornachandrika",
                "Thalam": "Adi (Tisra Gathi)",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8996,
                "Name": "jagadeesha srijaane",
                "Type": "Varnam-Pada",
                "Ragam": "ShuddhaSaveri",
                "Thalam": "Tisra Jathi Triputa Thalam",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8997,
                "Name": "bhalire bhalire",
                "Type": "Varnam-Pada",
                "Ragam": "Naatai",
                "Thalam": "Adi",
                "Composer": "Purandaradasar/TK Govindarao",
                "Song Language": "Kannada",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8998,
                "Name": "Chaalamujesina",
                "Type": "Varnam-Pada",
                "Ragam": "Ramapriya",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 8999,
                "Name": "Ninnukori",
                "Type": "Varnam-Pada",
                "Ragam": "Poorvikalyani",
                "Thalam": "Khanda Jathi Ata Thalam",
                "Composer": "Sonti Venkatasubbayya",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9000,
                "Name": "Omkaara pranava",
                "Type": "Varnam-Pada",
                "Ragam": "Shanmugapriya",
                "Thalam": "Adi",
                "Composer": "Dr M Balamurali Krishna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9001,
                "Name": "Sarasijaakshudu",
                "Type": "Varnam-Pada",
                "Ragam": "Kalyani",
                "Thalam": "Tisra jathi Eka Thalam",
                "Composer": "Tanjore Quartet",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9002,
                "Name": "saaturaa kaamini deva",
                "Type": "Varnam-Pada",
                "Ragam": "Kalyani",
                "Thalam": "Adi",
                "Composer": "Swathi Thirunal",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9003,
                "Name": "veLLai thaamarai poovil",
                "Type": "Varnam-Pada",
                "Ragam": "Rasikapriya",
                "Thalam": "Adi",
                "Composer": "Subramanya Bharathi/TK GovindaRao",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9004,
                "Name": "Ragamalika varnamunu",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika-AstaRagamalika/DinaRagamalika",
                "Thalam": "Adi",
                "Composer": "Professor P Sambamurthy",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9005,
                "Name": "Intha kOpamelara",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika-GhanaRagamalika",
                "Thalam": "Adi",
                "Composer": "Kalahasthi Venkatasami Raju",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9006,
                "Name": "kaamakOti sundaraa",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika",
                "Thalam": "Misra Jathi Triputa Thalam",
                "Composer": "Veenai Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9007,
                "Name": "Mahishasura",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika",
                "Thalam": "Sankeerna Jathi Matya Thalam",
                "Composer": "Veenai Seshanna",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9008,
                "Name": "sree sanai saga bhogikkum sri",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika-Dasaragamalika",
                "Thalam": "Adi",
                "Composer": "Nerur Srinivasachari",
                "Song Language": "Tamil",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9009,
                "Name": "Valachi vacchi",
                "Type": "Varnam-Tana",
                "Ragam": "Ragamalika-Navaragamalika",
                "Thalam": "Adi",
                "Composer": "Patnam Subramanya Iyer",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9010,
                "Name": "sannutaangi sri",
                "Type": "Varnam-Daru",
                "Ragam": "Vasantha",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9011,
                "Name": "samikki sari evvare",
                "Type": "Varnam-Daru",
                "Ragam": "Vasantha",
                "Thalam": "Roopakam",
                "Composer": "Baluswami Dikshitar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9012,
                "Name": "sri raajarajeshwari",
                "Type": "Varnam-Daru",
                "Ragam": "Kapi",
                "Thalam": "Mishra Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Kannada",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9013,
                "Name": "Mathe Malayadwaja",
                "Type": "Varnam-Daru",
                "Ragam": "Khamas",
                "Thalam": "Adi",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Kannada",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9014,
                "Name": "sariga danipai",
                "Type": "Varnam-Daru",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Govindasami",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9015,
                "Name": "balumosamayyenuraa",
                "Type": "Varnam-Daru",
                "Ragam": "Sahana",
                "Thalam": "Khanda Chapu",
                "Composer": "Harikesanallur Muthiah Bhagavathar",
                "Song Language": "Telugu",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              },
              {
                "Serial #": 9016,
                "Name": "Mohanaanga muralidhara",
                "Type": "Varnam-Daru",
                "Ragam": "Mohanam",
                "Thalam": "Adi",
                "Composer": "Charumathi Ramachandran",
                "Group": "Grahabheda Daru Varnam",
                "Song Language": "Sanskrit",
                "BookTitle": "Varnasagaram",
                "BookAuthor": "TK Govinda Rao",
                "Year of Publication": "Not available",
                "Book Print Language": "English"
              }
            ]
         
  const columns = [
    {accessor: 'Serial',Label:'Serial'},
    {accessor: 'Name'  , Label: ' Name'},   
    {accessor: 'Ragam'  , Label: 'Ragam'},
    {accessor: 'Type'  ,Label: 'Type'},
    { accessor: 'Composer'  , Label: 'Composer'},
    {accessor: 'Group' , Label: 'Group'},
    {accessor: 'BookTitle' ,  Label: 'BookTitle'},
    {accessor: 'Thalam'  , Label: 'Thalam'},
    { accessor: 'Language'  , Label: 'Language' },
    { accessor: 'Praise of'  ,Label: 'Praise of' },
    { accessor: 'BookAuthor'  ,  Label: 'Author' },
    { accessor: 'Publication'  ,Label: 'Publication Year'},
    { accessor: 'PrintLanguage' , Label: 'Print Language'},

  ]  

  return (
      <Table data={data} columns={columns}/>
  )

}

export default DataTableComponent