import React from 'react'
import { useState, useMemo } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const ContactForm = () => {
    const navigate = useNavigate();
  const [formStatus, setFormStatus] = React.useState('Send')
  const [message, setMessage] = useState()
 const [bodytext ,setBodytext] = useState()
 const [showContactPopup, setShowContactPopup] = useState(false);
 const [contactDetails , setContactDetails] = useState();


  const onSubmit = (e) => {
    e.preventDefault()
    setFormStatus('Submitting...')
    const { name, email,phone, message } = e.target.elements
    let confom = {
      name: name.value,
      email: email.value,
      phone:phone.value,
      message: message.value,
    }
    var maillist = [];
    maillist =  ['carnaticmusiccompositions@gmail.com','carnaticmusicbooknotations@gmail.com'];
    maillist.push(confom.email);
    try{
        axios.post('/api/sendmail/', {
          to: confom.email,
          text: `Dear Admin , <br /> Here is the request from <strong>${confom.name} </strong>: <br /> <strong>Request :  ${confom.message} </strong><br />
          <strong> Contact Details </strong> Name: ${confom.name} , Email : ${confom.email } ,Phone :  ${confom.phone}`,
           subject: `Message Received from    ${confom.name}`
           })

          
         } catch (error) {
           if(confom == null){
               setMessage({
                   message:
                     'Contact details are not updated',
                   style: { backgroundColor: 'red' },
                 })
                 alert(
                   'Please update Contact details'
                 )
               setShowContactPopup(true);
           }
           else{
            
           setMessage({
             message:
               'Error Sending email to customer. Please contact Admin.',
             style: { backgroundColor: 'red' },
           })
           alert(
             'Error Sending email to customer. Try again. if it fails again, Please contact Admin.'
           )
         }
       }
         alert("Email sent to admin! We will reach you shortly");
         setMessage({
           message: 'Email sent to customer!',
           style: { backgroundColor: 'lightseagreen' },
         })
         
         setContactDetails(null)
         navigate('/')
       }

       return (
        <div>
        
<div className="row addspace3">
    <div className="col-md-1">
    </div>
    <div className="col-md-10">
        <hr />
    </div>
    <div className="col-md-1">
    </div>
    </div>
    <div className="whyBicy">

        <div className="whybicyContainer">
        <div className="container mt-5 wrapper">
          <h2 className="mb-3">Contact Us</h2>
          <form onSubmit={onSubmit} >
            
            <div >
              <label className="form-label" htmlFor="name">
                Name
              </label>
              <input className="form-control textbox" type="text" id="name" required  style={{
                     padding: '4px',
                     borderRadius: '30px',
                     margin: '3px',
                     width: '50%',
                     height: '50px'

                   }} />
            </div>
            <div >
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <input className="form-control textbox" type="email" id="email" required style={{
                     padding: '4px',
                     borderRadius: '30px',
                     margin: '3px',
                     width: '50%',
                     height: '50px'

                   }} />
            </div>
            <div className="mb-2">
              <label className="form-label" htmlFor="phone">
                Phone
              </label>
              <input className="form-control textbox" type="phone" id="phone" required style={{
                     padding: '4px',
                     borderRadius: '30px',
                     margin: '3px',
                     width: '50%',
                     height: '50px'

                   }} />
            </div>
            <div className="mb-2">
              <label className="form-label" htmlFor="Questions">
                Message
              </label>
              <textarea className="form-control textbox" id="message" required style={{
                     padding: '4px',
                     borderRadius: '30px',
                     margin: '3px',
                     width: '50%',
                     height: '50px'

                   }} />
            </div>
           
            <div className='mb-6'>
            <button
                     style={{
                     padding: '4px',
                     borderRadius: '30px',
                     margin: '3px',
                     width: '200px',
                     height: '50px'

                   }}
                   type="submit"
                 >
                  
                Submit Request
                 
            </button></div>
          </form>
        </div><br /> <br/><br />
        </div>
        </div>
        </div>
      );
    }    
     
  export default ContactForm
        

