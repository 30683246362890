import logo from './logo.svg';
import './App.css';
import DataTableComponent  from './components/datatable.component'
import ContactForm from "./components/ContactForm";
import AboutUs from "./components/AboutUs";
import Header from './components/Header';
import Nav from './components/Nav';
import Footer from './components/Footer';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'bootstrap/dist/css/bootstrap.min.css';

import './styles/common.css';
import './styles/main.css';
import './styles/nav-mobile.css';
import './styles/styles.css';
import './styles/screen.css';


function App() {
return(
 
  <BrowserRouter>
    
   <Nav />
      <Routes>    
       
          <Route path="/" element={<DataTableComponent />} />
          <Route path="/ContactForm.html" element={<ContactForm />}  />
          <Route path="/AboutUs.html" element={<AboutUs />} />

      </Routes>
      <Footer />
    </BrowserRouter>);

    
}

export default App;
