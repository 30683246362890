

const Footer = ()=>{
    return (
        <>
      <footer className="footer row bw-footer">
    <section className="footer-footer">
        <div className="row container-fluid col-sm-12 addspaceftr bw-footer-top">
            <div className="col-sm-9  nav menu">
                    <ul>
                    <li className="top">  <a href="/">Find Song</a></li> 
                     <li className="top">  <a href="/AboutUs.html">About us</a></li>
  
                     <li className="top hidecontact"><a href="/ContactForm.html">Contact us</a></li>
                    </ul>
            </div>
            <div className="footer__top__middle col-sm-3 l2">
                <div className="footer__top__middle__icons">
                        <ul className="social-links">
                            <li>
                            <a className="sociallinks" href="https://www.facebook.com/BicyInsurance " target="_blank"><img src="/images/slices/facebook.png"/></a>
                                <a className="sociallinks" href="https://twitter.com/BicyInsurance " target="_blank"><img src="/images/slices/twitter.png"/></a>
                                <a className="sociallinks" href="https://www.instagram.com/bicyinsurance/" target="_blank"><img src="/images/slices/instagram.png"/></a>

                            </li>

                        </ul>

                </div>
            </div>
        </div>

        <div className="row container-fluid col-sm-12 addspaceftr bw-footer-bottom">
        <p>Disclaimer-The information provided in this website is for general informational purposes only.<br/> Any request for notation that violates the copyright of the original work will not be fulfilled by this website</p>
            <br />
            <p>
                <br />
                <br />
                
            </p>
        </div>
    </section>
</footer>



    
     
    
       </>)
    }
    
    export default Footer