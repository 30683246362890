import { useState, useMemo, useEffect, useRef } from 'react'
import { sortRows, filterRows, paginateRows } from './helpers'
import { Pagination } from './Pagination'
import { useNavigate } from 'react-router-dom'
import React from 'react'


import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import axios from 'axios'
import FormLabel from 'react-bootstrap/FormLabel';
import ReactDOM from "react-dom/client";
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";


export const Table = ({ columns, data }) => {
  const [activePage, setActivePage] = useState(1)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState({ order: 'asc', orderBy: 'id' })
  const rowsPerPage = 15
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [buttonText, setButtonText] = useState([]);
  const path = require('path');
  const [lyricschecked, setLyricsChecked] = useState(false); 
  const [notationschecked, setNotationsChecked] = useState(false); 


  const [SelectedRow, setSelectedRow] = useState([]);
  const [Selectedformat, setSelectedFormat] = useState([]);
  const [formStatus, setFormStatus] = React.useState('Send')
  const[songDetails,setSongdetails]= useState();
  const [contactDetails, setContactDetails] = useState();
  const [showerrormessage,setshowerrormessage] = useState();
  const [message, setMessage] = useState()
  const [bodytext, setBodytext] = useState()
  const filteredRows = useMemo(() => filterRows(data, filters), [data, filters])
  const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
  const calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage)
  const ref = useRef();
  const count = filteredRows.length
  const totalPages = Math.ceil(count / rowsPerPage)
  const [songlist ,setSonglist] =useState([]);
  const typelist = [];
  const composerlist = [];
  const [ragamList, setRagamlist]= useState([]);
  var optionslist = [];
  var mailbody='';
  const sortaccessors = ['Name', 'Ragam', 'Type', 'Composer','BookTitle','Group'];
  const navigate = useNavigate();


  
  const onSubmit = (e) => {
    e.preventDefault()
    if(!lyricschecked && !notationschecked)
    setshowerrormessage(true)
    else{
    try {
    var format='';
    if(lyricschecked && notationschecked)
    format ="Requested Lyrics and Notations"
    else
    if(notationschecked)
    format ="Requested Notations"
    else
    if(lyricschecked)
    format ="Requested Lyrics"
     mailbody= `Dear Admin , <br/>  <strong>${contactDetails.name} </strong>  
     <strong> ${format} of <br/></strong>
      Name: ${SelectedRow.Name}, <br/>   
      Ragam:${SelectedRow.Ragam},<br/> 
     Type: ${SelectedRow.Type},<br/> 
     Composer:${SelectedRow.Composer},<br/> 
    Group:${SelectedRow.Group},<br/> 
    Booktitle:${SelectedRow.BookTitle},<br/> 
    Thalam:${SelectedRow.Thalam},<br/> 
     Language:${SelectedRow.Language},<br/> 
     Praise of: ${SelectedRow.Praise},<br/> 
     Book Author${SelectedRow.Author},<br/> 
     Publication : ${SelectedRow.Publication},<br/> 
     Language: ${SelectedRow.Language}<br/> 
 
     Suggestions : ${contactDetails.suggestions} <br />
     <strong>Contact Details:</strong> Email : ${contactDetails.email} , Phone : ${contactDetails.phone} `
     var maillist = '';
     maillist =  'carnaticmusiccompositions@gmail.com'

      axios.post('/api/sendmail/', {
        to: contactDetails.email,     
        text:mailbody,       
        subject: `Song Request from ${contactDetails.email}` 
       
      })
      console.log(mailbody)

    } 
    catch (error) {
      if (contactDetails === null) {
        setMessage({
          message:
            'Contact details are not updated',
          style: { backgroundColor: 'red' },
        })
        alert(
          'Please update Contact details'
        )
        setShowContactPopup(true);
      }
      else {

        setMessage({
          message:
            'Error Sending email to customer. Please contact Admin.',
          style: { backgroundColor: 'red' },
        })
        alert(
          'Error Sending email to customer. Try again. if it fails again, Please contact Admin.'
        )
      }
    }

    setMessage({
      message: 'Email sent to customer!',

      style: { backgroundColor: 'lightseagreen' },
    })
    setShowContactPopup(false)
    setContactDetails(null)
  }
}


//
  const handleSearch = (e, accessor) => {
    setActivePage(1)
    try {
      if (e != null && accessor!=null) {      
        
        if (typeof e === 'string') {
          setFilters((prevFilters) => ({
            ...prevFilters,
            [accessor]: e,
          }))
          data.map(({accessor,Name,index }) => {
            console.log(accessor)
            console.log(Name)
            if ((accessor.includes(e) || accessor===e) && !songlist.includes(Name))
            {
              songlist.push({ value: Name, label: Name });
            }
          });
          data.map(({Ragam,accessor, index }) => {
            if ((accessor.includes(e) || accessor===e) && !ragamList.includes(Ragam)) {
              ragamList.push({ value: Ragam, label: Ragam });
            }
          });
        }
        else{
          if (e[0].value!=null && typeof e[0].value === 'string') {
            setFilters((prevFilters) => ({
              ...prevFilters,
              [accessor]: e[0].value,
            }))
            data.map(({accessor,Name,Serial }) => {
              if ((accessor.includes(e[0].value || accessor===e[0].value)) && !songlist.includes(Name))
              {
                songlist.push({ value: Name, label: Name });
              }
            });
            data.map(({Ragam,accessor, Serial }) => {
              if ((accessor.includes(e[0].value || accessor===e[0].value)) && !ragamList.includes(Ragam))
               {
                ragamList.push({ value: Ragam, label: Ragam });
              }
            });
          }
        }
      }
       else {
        setFilters((prevFilters) => {
          const updatedFilters = { ...prevFilters }
          delete updatedFilters[accessor]

          return updatedFilters
        })
      }
    }
  

    catch (error) {
      console.log(error);
    }
  }
  

  useEffect(() => {
    data.map(({ Name, index }) => {
      if (!songlist.includes(Name)) {
        songlist.push({ value: Name, label: Name });
      }
    });
    data.map(({ Ragam, index }) => {
      if (!ragamList.includes(Ragam)) {
        ragamList.push({ value: Ragam, label: Ragam });
      }
    });
    // data.map(({ Type , index }) => {
    //   if (!typelist.includes(Type)) {
    //     typelist.push({ value: Type, label: Type });
    //   }
    // });

    // data.map(({ Composer , index }) => {
    //   if (!composerlist.includes(Composer)) {
    //     composerlist.push({ value: Composer, label: Composer });
    //   }
    // });

  }, [])
  const handleSelectedRow = (content) => {
    setSelectedRow(content);
    setBodytext(`Please provide details of the Song ${content["Name"]} of Ragam  ${content["Ragam"]}`)
    setShowContactPopup(true);
  };
  const handleEmptyRequest = () => {
    setShowContactPopup(true);
    setBodytext(`Please provide the details of`);
  };
  const handleformat =()=>{
    if(lyricschecked && notationschecked)
    setSelectedFormat("Requested :  Lyrics and Notations")
    else
    if(notationschecked)
    setSelectedFormat("Requested :  Notations")
    else
    if(lyricschecked)
    setSelectedFormat("Requested :  Lyrics")
  
  }

  const handleRequest = (content) => {

    setBodytext(content);
  };
  const handleSort = (accessor) => {
    setActivePage(1)
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
  }

  const clearAll = () => {
    setSort({ order: 'asc', orderBy: 'id' })
    setActivePage(1)
    setFilters({})
    
  }


  return (
    <>

<div className='wrapper2'>

<div className='tablesdiv'>
 <div className='firsttable'>
        <table className="table tablefixed table-striped w-auto firsttablediv">
          <thead >{!showContactPopup &&
          <tr className='first'>
             <th style={{paddingLeft:'50px' , paddingRight:'10px'}}>Search</th> 
              {columns.map((column) => {

                optionslist = null
                if (column.accessor === "Name") { optionslist = songlist }
                if (column.accessor === "Ragam") { optionslist = ragamList }
                if (column.accessor === "Composer") { optionslist = composerlist }
                if (column.accessor === "Type") { optionslist = typelist }
                if (column.accessor === "Group") { optionslist = typelist }
                if (column.accessor === "BookTitle") { optionslist = typelist }
             

                if (sortaccessors.includes(column.accessor)) {
               
                 
                   if(column.accessor==='Name' || column.accessor==='Ragam'){
                    return (
                    <th >
                      <Typeahead
                        allowNew
                        options={optionslist}
                        placeholder={column.Label}
                        style={{
                          borderColor: 'blue',width:'20%'
                        }}
                        ref={ref}
                        id='basic-typeahead-single'
                        className='typeheadtext'
                        
                        onChange={(e) => { handleSearch(e, column.accessor) }}
                         onInputChange={(e) => { handleSearch(e, column.accessor) }}

                      />

                    </th> )}
                    else{
                      return (
                        <th >
                           <input className="form-control textbox typeheadtext" type="text"                                                   
                           placeholder={column.Label}                     
                           
                             style={{borderColor:'transperent', outline: 'none', position: 'relative'}}
                            
                            onChange={(e) => { handleSearch(e.target.value, column.accessor) }}
        
                          />
    
                        </th> )
                    }                  
                  
                  
                }
                if(column.accessor==='Thalam'){
                  return(
                 <th><button className='contactButton' onClick={() => window.location.reload(false) }>Clear</button> </th>
                 
                  )
                 }              
                 
              }
           )}
            </tr>}</thead>
            </table>
            </div>

<div className='tableFixHead'>

          <table style={{width:"100%" , border:"1"}} className="tablefixed">
            <thead>
            <tr className="secondtable" style={ {backgroundColor:"beige"} }>
              <th></th>
              {columns.map((column) => {
                const sortIcon = () => {
                  if (column.accessor === sort.orderBy) {
                    if (sort.order === 'asc') {
                      return '⬆️'
                    }
                    return '⬇️'
                  } else {
                    return '️↕️'
                  }
                }
                if (sortaccessors.includes(column.accessor)) {
                  return (

                    <th key={column.accessor}>
                    {column.Label}
                      <button className='contactButton' onClick={() => handleSort(column.accessor)}>{sortIcon()}</button>
                    </th>
                  )
                }
                else {
                  if(column.accessor!= 'Serial'){
                  return (
                    <th key={column.accessor}>
                      {column.Label}
                      <button className='contactButton'></button>
                   </th>)}
                }
              })}

            </tr>
           
          </thead>
          <tbody style={{paddingTop:'50px'}}>
            {calculatedRows.map((row) => {
                
              return (
             
                <tr key={row.id}>
                  <td className="sticky_col_body" key={row.id}>
                    <button style={{
                      padding: '1px',
                      borderRadius: '30px',
                      margin: '1px',
                      width: '40%',
                      backgroundColor:'beige',
                      color:'brown',
                      border:0
                    }}
                      onClick={() => {
                        handleSelectedRow(row)
                      }}

                    >
                      Contact Us
                    </button>{' '}
                  </td>

                  {columns.map((column) => {
                    if (column.format) {

                      return <td key={column.accessor}>{column.format(row[column.accessor])}</td>

                    }
                    if(column.accessor!='Serial'){
                    return <td key={column.accessor}>{row[column.accessor]}</td>}


                  })}
                </tr>
              )
            })}
          </tbody>
        </table></div>
</div>

<div className='tablesdivmobile' style={{overflow:'scroll'}}>
 <div>
        <table className="table table-striped w-auto">
          <thead>{!showContactPopup &&
          <tr className='firsttable' style={{zIndex:'4000', backgroundColor:'white'}}>
             <th style={{paddingLeft:'30px'}}>Search</th> 
              {columns.map((column) => {

                optionslist = null
                if (column.accessor === "Name") { optionslist = songlist }
                if (column.accessor === "Ragam") { optionslist = ragamList }
                if (column.accessor === "Composer") { optionslist = composerlist }
                if (column.accessor === "Type") { optionslist = typelist }
                if (column.accessor === "Group") { optionslist = typelist }
                if (column.accessor === "BookTitle") { optionslist = typelist }
             
                if (sortaccessors.includes(column.accessor)) {

                  return (
                   
                    <th >
                      <Typeahead
                        allowNew
                        options={optionslist}
                        placeholder={column.Label}
                        style={{
                          borderColor: 'blue',
                        }}
                        ref={ref}
                        id='basic-typeahead-single'
                        className='typeheadtext'
                        
                        onChange={(e) => { handleSearch(e, column.accessor) }}
                        onInputChange={(e) => { handleSearch(e, column.accessor) }}

                      />

                    </th>
                    
                  )
                  
                }
                if(column.accessor==='Thalam'){
                  return(
                 <th><button className='contactButton' onClick={() => window.location.reload(false) }>Clear</button> </th>
                 
                  )
                 }              
                 
              }
           )}
            </tr>}
           
            <tr className="secondtablemobile" style={ {backgroundColor:"beige"} }>
              <th></th>
              {columns.map((column) => {
                const sortIcon = () => {
                  if (column.accessor === sort.orderBy) {
                    if (sort.order === 'asc') {
                      return '⬆️'
                    }
                    return '⬇️'
                  } else {
                    return '️↕️'
                  }
                }
                if (sortaccessors.includes(column.accessor)) {
                  return (

                    <th key={column.accessor}>
                    {column.Label}
                      <button className='contactButton' onClick={() => handleSort(column.accessor)}>{sortIcon()}</button>
                    </th>
                  )
                }
                else {
                  if(column.accessor!= 'Serial'){
                  return (
                    <th key={column.accessor}>
                      {column.Label}
                      <button className='contactButton'></button>
                   </th>)}
                }
              })}

            </tr>
           
          </thead>
          <tbody></tbody>
          <tbody>
            {calculatedRows.map((row) => {
              return (
             
                <tr key={row.id}>
                  <td className="sticky_col_body" key={row.id}>
                    <button style={{
                      padding: '1px',
                      borderRadius: '30px',
                      margin: '1px',
                      width: '40%',
                      backgroundColor:'beige',
                      color:'brown',
                      border:0
                    }}
                      onClick={() => {
                        handleSelectedRow(row)
                      }}

                    >
                      Contact Us
                    </button>{' '}
                  </td>

                  {columns.map((column) => {
                    if (column.format) {

                      return <td key={column.accessor}>{column.format(row[column.accessor])}</td>

                    }
                    if(column.accessor!='Serial'){
                      return <td key={column.accessor}>{row[column.accessor]}</td>}

                  })}
                </tr>
              )
            })}
          </tbody>
        </table></div>
</div>
         <div id="pagination" className='row' >

        {count > 0 ? (
          <Pagination 
            activePage={activePage}
            count={count}
            rowsPerPage={rowsPerPage}
            totalPages={totalPages}
            setActivePage={setActivePage}
          />
        ) : (
          <p>No data found</p>
        )}
        
        <p></p> 

         
       </div>  
        <Modal className='modal'
        style={{
          zIndex:9999
        }}
          show={showContactPopup}
          onHide={() => {
            setShowContactPopup(false);
          }}
        >

          <Modal.Header>
            <Modal.Title><h1>Contact US</h1></Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={onSubmit} >
            
            <div >
              <label className="form-label" htmlFor="name">
                Name
              </label>
              <input className="form-control textbox" type="text" id="name" required  style={{
                     padding: '4px',
                     borderRadius: '30px',
                     margin: '3px',
                     height: '50px',
                     width:'100%'
                   }} 
                   placeholder='name'
                
                   onChange={(e) =>
                     setContactDetails({
                       ...contactDetails,
                       name: e.target.value,
                     })
                   }/>
            </div>
            <div >
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <input className="form-control textbox" type="email" id="email" required style={{
                     padding: '4px',
                     borderRadius: '30px',
                     margin: '3px',
                     width: '100%',
                     height: '50px'

                   }}
                   placeholder='Email'
                   onChange={(e) =>
                     setContactDetails({
                       ...contactDetails,
                       email: e.target.value,
                     })
                   } />
            </div>
            <div className="mb-2">
              <label className="form-label" htmlFor="phone">
                Phone
              </label>
              <input className="form-control textbox" type="phone" id="phone" required style={{
                     padding: '4px',
                     borderRadius: '30px',
                     margin: '3px',
                     height: '50px',
                     width:'100%'

                   }} 
                   placeholder='Phone'
                   onChange={(e) =>
                     setContactDetails({
                       ...contactDetails,
                       phone: e.target.value,
                     })
                   }/>
            </div>
         
             <Form.Group controlId='text' className='mb-2'>
              <Form.Label>Request Details</Form.Label>
              <Form.Group className='row'>
                                    <Form.Check 
                                      type='checkbox'
                                      label="Requesting for Song Notations"
                                      name='radio'    

                                      onChange={() =>
                                        setLyricsChecked(!lyricschecked)}/>
                                       <Form.Check
                                      type='checkbox'
                                      label="Requesting for Song Lyrics"
                                      name='radio'
                                      onChange={() =>
                                        setNotationsChecked(!notationschecked)}/>
                                        {showerrormessage &&
                                        <label style={{color:'red'}}>!Please select either  Lyrics or Notations or both</label>}
                                      </Form.Group>
              <Form.Control
                as='textarea'
                required
                rows={5}
                width='100%'

                defaultValue={bodytext}
                onChange={(e) =>
                  handleRequest(e.target.value)
                }

              />
            </Form.Group>
            <div className="mb-2">
              <label className="form-label" htmlFor="Questions">
                Message
              </label>
              <textarea className="form-control textbox" id="message" required style={{
                     padding: '4px',
                     borderRadius: '30px',
                     margin: '3px',
                     height: '50px'

                   }}
                   placeholder='Comments / Suggestions'
                   onChange={(e) =>
                     setContactDetails({
                       ...contactDetails,
                       suggestions: e.target.value,
                     })
                   } />
            </div>
           
            <div className='mb-6'>
            <button
                     style={{
                     padding: '4px',
                     borderRadius: '30px',
                     margin: '3px',
                     width: '200px',
                     height: '50px'

                   }}
                   type="submit"
                 >
                  
                Send Request
                 
            </button></div>
          </Form>
          </Modal.Body>
        </Modal>
        <div>
         
        </div>
        </div>
    </>
  )
}
